import React from "react";
import { Col, Row } from "antd";
import classnames from "classnames";
import SummarySection from "../SummarySection/SummarySection";
import { Title } from "../../antd-ovveride";
import styles from "../FrameworkSummary/FrameworkSummary.module.less";
import { revertAdapterOptions } from "../../helpers/adapterOptions";
import List from "../List/List";
import frameworkFlow from "../../data/frameworkFlow";

const IntroductionSummary = ({ framework, className, ...props }) => (
  <SummarySection
    className={classnames(className)}
    title="Introduction"
    {...props}
  >
    <Row>
      <Col span={9}>
        <Title level={5} className={styles.summary__subtitle}>
          Alignment with standards and principles
        </Title>
      </Col>
      <Col span={11} offset={4}>
        <Title level={5} className={styles.summary__subtitle}>
          Framework coverage
        </Title>
      </Col>
    </Row>
    <Row>
      <Col span={9}>
        <List
          items={[
            ...revertAdapterOptions(framework.principles),
            ...revertAdapterOptions(framework.other_standards),
            framework.standard,
          ]}
        />
      </Col>
      <Col span={11} offset={4}>
        <List items={frameworkFlow} />
      </Col>
    </Row>
  </SummarySection>
);

export default IntroductionSummary;
