import React from "react";
import { Row, Col, Typography, Space } from "antd";

import AuditorReportsTable from "./AuditorReportsTable";

import styles from "./AuditorReportsPage.module.less";

const { Title, Text } = Typography;

const AuditorReportsPage = ({ reports, fetching }) => (
  <>
    <Row justify="space-between" className={styles.header}>
      <Col>
        <Space direction="vertical" size={20}>
          <Space size={8} align="center">
            <Title level={2} style={{ marginBottom: 0 }}>
              EU Taxonomy
            </Title>
          </Space>
          <Text>Review company reports & verify them on the blockchain.</Text>
        </Space>
      </Col>
    </Row>
    <AuditorReportsTable reports={reports} fetching={fetching} />
  </>
);

export default AuditorReportsPage;
