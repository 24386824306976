import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import styles from "./BackTo.module.less";

const BackTo = ({ onClick, to, children, ...restLinkProps }) => {
  const btn = (
    <Button
      onClick={onClick}
      type="text"
      size="small"
      icon={<LeftCircleOutlined />}
      className={styles.btn}
    >
      {children}
    </Button>
  );

  if (to) {
    return (
      <Link to={to} {...restLinkProps}>
        {btn}
      </Link>
    );
  }
  return btn;
};

export default BackTo;
