import React from "react";
import { useMutation, useQuery } from "urql";
import { AttachFileProvider } from "@evercityecosystem/evercity-ui";
import { useOutletContext, useParams } from "react-router-dom";
import ProjectIdeaNoteAssessment from "./ProjectIdeaNoteAssessment";
import { getDocsQuery, impactIndicatorsQuery } from "../../api/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error500 from "../errors/Error500";
import {
  createDocMutation,
  deleteDocMutation,
  updateDocMutation,
  uploadFileBase64,
} from "../../api/mutations";
import { useCurrentUser } from "../../hooks/user";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const ProjectIdeaNoteAssessmentConnected = () => {
  const [, deleteDoc] = useMutation(deleteDocMutation);
  const [, updateDocExec] = useMutation(updateDocMutation);
  const [, uploadFile] = useMutation(uploadFileBase64);
  const [, createDocExec] = useMutation(createDocMutation);

  const [userState] = useCurrentUser();

  const { openDiscussionDrawer } = useOutletContext();

  const [recentDocsResponse] = useQuery({
    query: getDocsQuery,
    requestPolicy: "network-only",
  });

  const recentDocs = recentDocsResponse.data?.docs;

  const [impactIndicatorsResponse] = useQuery({
    query: impactIndicatorsQuery,
  });

  const impactIndicators = impactIndicatorsResponse.data?.impact_indicators;

  const createDoc = ({ name, url }) =>
    createDocExec({
      user_id: userState.data.id,
      name,
      url,
      mimetype: "",
    });

  const updateDoc = (id, changes) =>
    updateDocExec({
      id,
      set: changes,
    });

  const { pinId } = useParams();

  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.PIN,
    entityId: pinId,
    group: MESSENGER_GROUPS.PIN.VIABILITY,
  });

  if (impactIndicatorsResponse.error || threadsResponse.error) {
    return <Error500 />;
  }

  if (impactIndicatorsResponse.fetching || threadsResponse.fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  return (
    <AttachFileProvider
      context={{
        deleteDoc,
        updateDoc,
        recentDocs,
        uploadFile,
        createDoc,
      }}
    >
      <ProjectIdeaNoteAssessment
        threads={threadsResponse.data}
        impactIndicators={impactIndicators}
        onOpenDiscussionsDrawer={openDiscussionDrawer}
      />
    </AttachFileProvider>
  );
};

export default ProjectIdeaNoteAssessmentConnected;
