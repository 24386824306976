import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Tag } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Space, Text } from "@antd-ovveride";
import styles from "./AttachedFileRow.module.less";

import { FILE_ERRORS } from "../AttachFileDrawer/types";
import AttachedFileRowBase from "./AttachedFileRowBase";
import { useCurrentUser } from "../../hooks/user";

const DateCell = ({ date }) => (
  <Text ellipsis size="small" type="secondary">
    {date && format(new Date(date), "dd.MM.yyyy")}
  </Text>
);
DateCell.propTypes = {
  date: PropTypes.string,
};

const ErrorCell = ({ message }) => (
  <Space size={5} align="center">
    <ExclamationCircleOutlined className={styles.errorIcon} />
    <Text type="danger">{message}</Text>
  </Space>
);
ErrorCell.propTypes = {
  message: PropTypes.string,
};

// TODO: что-то он выглядит лишним, подумать как от него избавится
const AttachedFileRow = ({
  name,
  link,
  createdDate,
  type,
  userId,
  selected,
  onSelectedChange,
  error,
  onDelete,
  onChangeName,
  onRetry,
}) => {
  const [editable, setEditable] = useState(false);
  const [userState] = useCurrentUser();

  const onChangeNameHandler = (newName) => {
    if (newName.length && newName !== name) {
      onChangeName(newName);
    }
    setEditable(false);
  };

  const actions = {
    download: {
      type: "download",
      label: "Download",
      onClick: () => {
        window.open(link);
      },
      icon: <DownloadOutlined />,
    },
    edit: {
      type: "edit",
      label: "Edit",
      onClick: () => setEditable(true),
      icon: <EditOutlined />,
    },
    delete: {
      type: "delete",
      label: "Delete",
      onClick: onDelete,
      disabled: userState.data?.id === userId,
      icon: <DeleteOutlined />,
      theme: "danger",
    },
    retry: {
      type: "retry",
      label: "Retry",
      onClick: onRetry,
      icon: <ReloadOutlined />,
    },
  };

  switch (type) {
    case "default":
      return (
        <AttachedFileRowBase
          name={name}
          link={link}
          disabledSelection={false}
          lastColumn={createdDate && <DateCell date={createdDate} />}
          selected={selected}
          onSelectedChange={onSelectedChange}
          actions={[actions.download, actions.edit, actions.delete]}
          editable={editable}
          onChangeName={onChangeNameHandler}
        />
      );
    case "loading":
      return <AttachedFileRowBase name={name} disabledSelection loading />;
    case "error":
      return (
        <AttachedFileRowBase
          name={name}
          hideSelection
          lastColumn={<ErrorCell message={error?.message} />}
          actions={
            error?.type === FILE_ERRORS.NETWORK
              ? [actions.retry, actions.delete]
              : []
          }
        />
      );
    case "added":
      return (
        <AttachedFileRowBase
          name={name}
          disabledSelection
          selected
          lastColumn={
            <Space>
              <Tag color="success">Added</Tag>
              {createdDate && <DateCell date={createdDate} />}
            </Space>
          }
          editable={editable}
          actions={[actions.download, actions.edit, actions.delete]}
          onChangeName={onChangeNameHandler}
        />
      );
    default:
      return null;
  }
};

AttachedFileRow.propTypes = {
  type: PropTypes.oneOf(["default", "loading", "error", "added"]),
  name: PropTypes.string.isRequired,
  createdDate: PropTypes.string,
  selected: PropTypes.bool,
  onSelectedChange: PropTypes.func,
};
AttachedFileRow.defaultProps = {
  type: "default",
};

export default AttachedFileRow;
