import React from "react";
import { Space as BaseSpace } from "antd";
import classnames from "classnames";
import styles from "./Space.module.less";

const Space = ({ className, block, justify, ...rest }) => (
  <BaseSpace
    className={classnames(
      {
        [styles["space--block"]]: block,
        [styles[`justify--${justify}`]]: justify,
      },
      className
    )}
    {...rest}
  />
);

export default Space;
