import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./ProductMap.module.less";
import MapWidget from "../MapWidget/MapWidget";

const ProductMap = ({ zoom, lng, lat, size }) => (
  <div className={cs([styles.productMap, styles[`productMap--${size}`]])}>
    <MapWidget
      initialLat={lat}
      initialLng={lng}
      initialZoom={zoom}
      satelliteView
    />
  </div>
);

ProductMap.propTypes = {
  size: PropTypes.oneOf(["large", "default"]),
};

export default ProductMap;
