export const toEverUSD = (amount) => (amount || 0) * 10 ** 9;

export const fromEverUSD = (amount) => (amount || 0) / 10 ** 9;

export const toPercent = (amount) => (amount || 0) / 10 ** 3;

export const toBlockchainPercent = (amount) => (amount || 0) * 10 ** 3;

export const fromBondDays = (value, timeStep) => value / timeStep;

export const toBondDays = (value, timeStep) => value * timeStep;

export const toMln = (n) => Number((n / 1e6).toFixed(2));
export const toThousands = (n) => Number((n / 1e3).toFixed(2));

export const numberToRoundedString = (n) => {
  if (n >= 1e9) {
    return `${(n / 1e9).toFixed(1)}B`;
  }
  if (n >= 1e6) {
    return `${(n / 1e6).toFixed(1)}M`;
  }
  if (n >= 1e4) {
    return `${(n / 1e3).toFixed(1)}K`;
  }
  return n;
};
