import React, { useMemo } from "react";
import { useQuery } from "urql";

import AuditorReportsPage from "./AuditorReportsPage";

import { getReportsQuery } from "../../api/queries";

const AuditorReportsPageConnected = () => {
  const [reportsResponse] = useQuery({
    query: getReportsQuery,
    variables: { where: { status: { _eq: "review" } } },
  });

  const reports = useMemo(
    () => reportsResponse.data?.eu_taxonomy_reports,
    [reportsResponse.data]
  );

  return (
    <AuditorReportsPage reports={reports} fecthing={reportsResponse.fetching} />
  );
};

export default AuditorReportsPageConnected;
