import React from "react";
import { node } from "prop-types";
import { Space } from "@antd-ovveride";

const ProductVerticalSpace = ({ children }) => (
  <Space block size={40} direction="vertical">
    {children}
  </Space>
);

ProductVerticalSpace.propTypes = {
  children: node.isRequired,
};

export default ProductVerticalSpace;
