import React, { useState, useRef } from "react";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { Portal } from "react-portal";
import ReportResultConnected from "./ReportResultConnected";

const generateCanvas = async (node) => {
  const canvas = await html2canvas(node, {
    width: node.offsetWidth,
    height: node.offsetHeight,
    x: 0,
    y: 0,
    scale: window.devicePixelRatio || 1,
  });
  return { canvas, node };
};

const addCanvasToDocFactory = (doc) => (canvas, index) => {
  const pdfPageSize = {
    w: 297,
    h: 210,
  };
  const origTableSize = {
    w: canvas.node.offsetWidth,
    h: canvas.node.offsetHeight,
  };

  const scaledTableSize = {
    w: pdfPageSize.w,
    h: (pdfPageSize.w / origTableSize.w) * origTableSize.h,
  };
  const tableImage = canvas.canvas.toDataURL("image/jpeg");

  if (index > 0) {
    doc.addPage();
  }
  doc.addImage(tableImage, "JPEG", 0, 0, scaledTableSize.w, scaledTableSize.h);
  let usedPdfHeight = pdfPageSize.h;
  while (usedPdfHeight < scaledTableSize.h) {
    doc.addPage();
    doc.addImage(
      tableImage,
      "JPEG",
      0,
      -usedPdfHeight,
      scaledTableSize.w,
      scaledTableSize.h
    );
    usedPdfHeight += pdfPageSize.h;
  }
};

const generatePdf = async (tabelsNodes) => {
  const pdfPageSize = {
    w: 297,
    h: 210,
  };
  const doc = new JsPDF({
    orientation: "landscape",
  });

  const canvasesPromises = Object.values(tabelsNodes).map(generateCanvas);
  const tablesCanvases = await Promise.all(canvasesPromises);

  const addCanvasToDoc = addCanvasToDocFactory(doc);
  tablesCanvases.forEach(addCanvasToDoc);

  // set pagination
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(9);
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    doc.text(
      `${doc.internal.getCurrentPageInfo().pageNumber}/${pageCount}`,
      pdfPageSize.w - 3.5,
      pdfPageSize.h - 3.5,
      {
        align: "right",
      }
    );
  }
  return doc;
};

const defaultState = {
  generation: false,
  download: true,
};

const GenerateReportResult = ({ children, reportId, onComplete, onError }) => {
  const [state, setState] = useState(defaultState);
  const tablesRefs = useRef({});

  const onLoad = async () => {
    try {
      const doc = await generatePdf(tablesRefs.current);
      if (state.download) {
        doc.save("report.pdf");
      }
      onComplete(doc, state.download);
    } catch (err) {
      console.error(err);
      onError();
    }
    setState(defaultState);
  };

  return (
    <>
      {state.generation && (
        <Portal>
          <ReportResultConnected
            onLoad={onLoad}
            reportId={reportId}
            ref={tablesRefs}
          />
        </Portal>
      )}
      {children({
        loading: state.generation,
        generate: (download) => setState({ generation: true, download }),
      })}
    </>
  );
};

export default GenerateReportResult;
