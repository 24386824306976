import React from "react";
import { Typography, Space } from "antd";
import styles from "./HeadlinePoint.module.less";

const { Title } = Typography;

const HeadlinePoint = ({ text }) => {
  const [, number, name] = text.match(/(^\d+)\.?\s(.*)/) || [];
  return (
    <Space size={10} className={styles.container}>
      <span className={styles.index}>
        <Title level={5} className={styles.noMargin}>
          {number}
        </Title>
      </span>
      <Title level={5} className={styles.noMargin}>
        {name}
      </Title>
    </Space>
  );
};

export default HeadlinePoint;
