import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useQuery } from "urql";
import { debtFullInfoQuery } from "../../api/queries";
import LkLayout from "../LkLayout/LkLayout";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";

const DebtContext = () => {
  const { slug } = useParams();

  const [debtResult] = useQuery({
    query: debtFullInfoQuery,
    variables: { slug },
  });

  if (debtResult.fetching)
    return (
      <LkLayout>
        <LoadingSpinner full size="large" />
      </LkLayout>
    );

  const debt = debtResult.data?.bonds[0];
  if (debtResult.error || !debt) {
    return <Error404 />;
  }

  return <Outlet context={{ debt }} />;
};

export default DebtContext;
