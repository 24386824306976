import cs from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./ProductPrimaryBtn.module.less";

const ProductPrimaryBtn = ({ className, children, to, ...props }) => (
  <Link
    to={to}
    className={cs([styles.productPrimaryBtn, className])}
    {...props}
  >
    {children}
  </Link>
);

export default ProductPrimaryBtn;
