import React, { useMemo } from "react";
import { Card, Row, Col, Typography } from "antd";

import cs from "classnames";
import useBreakpoint from "../../../hooks/useBreakpoint";
import styles from "./OverviewHeader.module.less";

import Informer from "./Informer";
import Statistics from "./Statistics";
import TaxonomyAligment from "../../Charts/TaxonomyAligment";
import { thousandFormatter } from "../../../helpers/thousandFormatter";
import ScreeningOverviewConnected from "./ScreeningOverview/ScreeningOverviewConnected";
import reportCurrencyParameters from "../../../data/reportCurrencyParameters";
import { decimalToPercents } from "../../../helpers/percents";

const { Title } = Typography;

const informerDataByParamFactory = (report) => (paramName, displayName) => {
  const alignmentKey = `${paramName}_alignment`;
  const {
    currency,
    [paramName]: value,
    [alignmentKey]: alignmentValue,
  } = report;

  const percent = decimalToPercents(alignmentValue / value);

  return {
    name: displayName,
    sum: `${thousandFormatter(value)} ${currency}`,
    value: percent,
  };
};

const buildReportData = (fn) =>
  Object.entries(reportCurrencyParameters).map(([paramKey, paramDisplay]) =>
    fn(paramKey, paramDisplay)
  );

const getStatisticData = (report) => [
  {
    title: "Country",
    value: report.company.country,
  },
  {
    title: "LEI",
    value: report.company.lei,
  },
  {
    title: "Company number",
    value: report.company.company_number,
  },
  {
    title: "Number of employees",
    value: report.company.employee_num,
  },
  {
    title: "Subject to NFRD / CSRD",
    value: report.company.nfrd_csrd ? "Yes" : "No",
  },
  {
    title: "Reporting year",
    value: report.reporting_year,
  },
];

const InformerContent = ({ data }) => (
  <Row>
    {data.map(({ name, value, sum }) => (
      <Col span={8} key={name}>
        <Informer name={name} sum={sum} value={value} />
      </Col>
    ))}
  </Row>
);

const OverviewHeader = ({ report }) => {
  const statisticsData = useMemo(() => getStatisticData(report), [report]);

  const informerData = useMemo(
    () => buildReportData(informerDataByParamFactory(report)),
    [report]
  );

  const { xl } = useBreakpoint();

  return (
    <header
      className={cs(styles.rows, {
        [styles.grid]: xl,
      })}
    >
      <Card className={styles.company}>
        <Statistics data={statisticsData} />
      </Card>

      <Card className={styles.info}>
        <InformerContent data={informerData} />
      </Card>

      <Card className={styles.taxonomy}>
        <Title level={4} align="center">
          Taxonomy alignment
        </Title>
        <TaxonomyAligment
          report={report}
          finParamNames={Object.keys(reportCurrencyParameters)}
        />
      </Card>
      <Card className={styles.overview}>
        <ScreeningOverviewConnected
          finParamNames={Object.keys(reportCurrencyParameters)}
        />
      </Card>
    </header>
  );
};

export default OverviewHeader;
