import React from "react";
import { Button, Typography, Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as ConfirmIcon } from "../../assets/icons/confirm.svg";
import styles from "./FrameworkModal.module.less";

const FrameworkModal = ({
  setIsModalVisible,
  isModalVisible,
  onSave,
  onGenerate,
}) => (
  <Modal
    centered
    visible={isModalVisible}
    width={600}
    onCancel={() => setIsModalVisible(false)}
    modalRender={() => (
      <div className={styles.modal}>
        <div className={styles.flex} style={{ marginBottom: 40 }}>
          <ConfirmIcon />
          <Typography.Text className={styles.modalTitle}>
            Your framework is created
          </Typography.Text>
        </div>

        <div className={styles.flex}>
          <Button
            onClick={onGenerate}
            type="primary"
            size="large"
            icon={<DownloadOutlined />}
          >
            Download DOC
          </Button>
          <Button onClick={onSave} size="large">
            Save and close
          </Button>
        </div>
      </div>
    )}
  />
);

export default FrameworkModal;
