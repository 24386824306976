import React from "react";
import { Space } from "antd";

const PointsContainer = ({ children }) => (
  <Space size={30} direction="vertical" style={{ display: "flex" }}>
    {children}
  </Space>
);

export default PointsContainer;
