import React, { useMemo } from "react";
import { Table, Button } from "antd";
import cs from "classnames";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Badge, PieChartOutlined } from "@evercityecosystem/evercity-ui";
import { ReactComponent as PdfIcon } from "../../assets/pdf-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/excel-icon.svg";
import StatusTag from "../StatusTag/StatusTag";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import styles from "./ReportsTable.module.less";
import Progress from "../Progress/Progress";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Avatar from "../../antd-ovveride/Avatar/Avatar";
import SelectManager from "../SelectManager/SelectManager";
import { REPORT_STATUSES, getStatusText } from "../../helpers/reportStatuses";

const { Column } = Table;

const getStatus = (issues, status) => {
  if (issues && issues.open > 0) {
    return REPORT_STATUSES.issue;
  }

  if (issues && issues.submitted > 0) {
    return REPORT_STATUSES.review;
  }
  return status;
};

const formatReport = (report) => ({
  id: report.id,
  company_name: report.company?.name,
  companyId: report.company.id,
  currency: report.currency,
  reporting_year: report.reporting_year,
  turnover: report.turnover,
  capex: report.capex,
  opex: report.opex,
  turnover_alignment: report.turnover_alignment,
  capex_alignment: report.capex_alignment,
  opex_alignment: report.opex_alignment,
  status: getStatus(report.issues, report.status),
  manager: report.manager,
  consultant: report.company?.company_consultants?.[0]?.manager,
  client: report.manager,
  issues: report.issues,
});

const getBadge = (issues) => {
  if (issues && issues.open > 0) {
    return (
      <Badge style={{ color: "#FF4D4F" }} type="outlined" count={issues.open} />
    );
  }

  if (issues && issues.submitted > 0) {
    return (
      <Badge
        style={{ color: "#FAAD14" }}
        type="outlined"
        count={issues.submitted}
      />
    );
  }
  return null;
};

const ReportsTable = ({
  reports,
  loadingReports,
  onDeleteRow,
  onDownloadPdf,
  onDownloadExcel,
  onDownloadCharts,
  managers,
  company,
  selectManager,
  sampleReport,
}) => {
  const navigate = useNavigate();

  const rows = useMemo(() => {
    let result = reports.map(formatReport);
    if (sampleReport) {
      result = [formatReport(sampleReport), ...result];
    }
    return result;
  }, [reports, sampleReport]);

  const actions = useMemo(() => {
    let result = [
      {
        type: "download_pdf",
        label: "Download pdf",
        icon: <PdfIcon />,
        onClick: onDownloadPdf,
      },
      {
        type: "download_excel",
        label: "Download excel",
        icon: <ExcelIcon />,
        onClick: (report) => onDownloadExcel(report),
      },
      {
        type: "download_charts",
        label: "Download charts",
        icon: <PieChartOutlined />,
        onClick: onDownloadCharts,
      },
    ];

    if (company.type === "client") {
      result = [
        {
          type: "delete",
          label: "Delete",
          icon: <DeleteOutlined />,
          onClick: onDeleteRow,
        },
        ...result,
      ];
    }

    if (company.country === "Slovenija") {
      result.push({
        type: "download_excel_sl",
        label: "Download excel (SL)",
        icon: <ExcelIcon />,
        onClick: (report) => onDownloadExcel(report, "sl"),
      });
    }

    return result;
  }, [company, onDownloadExcel, onDownloadCharts, onDownloadPdf, onDeleteRow]);

  const onRowEvents = (row) => ({
    onClick: () => {
      if (
        row.status === REPORT_STATUSES.draft ||
        row.status === REPORT_STATUSES.sample
      ) {
        navigate(`/company/taxonomy/report/${row.id}`);
      } else {
        navigate(`/company/taxonomy/report/${row.id}/result`);
      }
    },
  });

  const statusRender = (status, { issues }) => (
    <StatusTag
      type={status}
      text={getStatusText(status)}
      badge={getBadge(issues)}
    />
  );

  const progressRenderFactory = (paramName) => (report) => {
    const alignmentKeyByParamName = `${paramName}_alignment`;
    const {
      [alignmentKeyByParamName]: alignmentValue,
      [paramName]: currencyValue,
      currency,
      status,
    } = report;
    const isDraft = status === REPORT_STATUSES.draft;
    return (
      <Progress
        part={isDraft ? 0 : alignmentValue}
        total={currencyValue}
        currency={currency}
        disabled={isDraft}
      />
    );
  };

  const actionsRender = (text, record) => {
    const bindedActions = actions.map(({ onClick, ...rest }) => ({
      ...rest,
      onClick: (...args) => onClick(record, ...args),
    }));
    const { id } = record;
    if (loadingReports.includes(id)) {
      return <LoadingSpinner noStyle size="small" />;
    }
    return (
      <ActionsDropdown actions={bindedActions}>
        <Button size="small" icon={<MoreOutlined />} />
      </ActionsDropdown>
    );
  };

  const managerRender = () => {
    if (company.type === "client") {
      return (
        <Column
          className={cs(styles.col)}
          title="Manager"
          dataIndex="client"
          key="client"
          render={(client, report) => (
            <SelectManager
              manager={client}
              managers={managers}
              onSelect={(userId) => {
                selectManager(
                  {
                    reportId: report.id,
                    userId,
                  },
                  "client"
                );
              }}
            />
          )}
        />
      );
    }

    if (company.type === "consultant") {
      return (
        <Column
          className={cs(styles.col)}
          title="Manager"
          dataIndex="consultant"
          key="consultant"
          render={(consultant, report) => (
            <SelectManager
              manager={consultant}
              managers={managers}
              onSelect={(userId) => {
                selectManager(
                  {
                    consultantId: company.id,
                    clientId: report.companyId,
                    userId,
                  },
                  "consultant"
                );
              }}
            />
          )}
        />
      );
    }

    return null;
  };

  const userRender = () => {
    if (company.type === "client") {
      return (
        <Column
          className={cs(styles.col)}
          title="Consultant"
          dataIndex="consultant"
          key="consultant"
          render={(consultant) => (
            <Avatar showName name={consultant?.display_name} />
          )}
        />
      );
    }

    if (company.type === "consultant") {
      return (
        <Column
          className={cs(styles.col)}
          title="Client"
          dataIndex="client"
          key="company"
          render={(client) => <Avatar showName name={client?.display_name} />}
        />
      );
    }

    return null;
  };

  const addKeyFromId = (comps) =>
    comps?.map((c) => ({
      ...c,
      key: c.id,
    }));

  return (
    <Table
      dataSource={addKeyFromId(rows)}
      pagination={false}
      className={styles.table}
      onRow={onRowEvents}
    >
      <Column
        className={cs([styles.col, styles["col--companyName"]])}
        title="Company Name"
        dataIndex="company_name"
        key="company_name"
      />
      <Column
        className={styles.col}
        title="Year"
        dataIndex="reporting_year"
        key="reporting_year"
      />
      <Column
        className={cs(styles.col, styles["col--progressBar"])}
        title="Turnover"
        key="turnover"
        render={progressRenderFactory("turnover")}
      />
      <Column
        className={cs(styles.col, styles["col--progressBar"])}
        title="CapEx"
        key="capex"
        render={progressRenderFactory("capex")}
      />
      <Column
        className={cs(styles.col, styles["col--progressBar"])}
        title="OpEx"
        key="opex"
        render={progressRenderFactory("opex")}
      />
      <Column
        className={cs(styles.col, styles["col--status"])}
        title="Status"
        dataIndex="status"
        key="status"
        render={statusRender}
      />
      {managerRender()}
      {userRender()}
      <Column
        className={cs([styles.col, styles["col--actions"]])}
        title="Actions"
        key="Actions"
        render={actionsRender}
      />
    </Table>
  );
};

export default ReportsTable;
