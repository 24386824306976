import React from "react";
import { Input } from "antd";
import classnames from "classnames";
import styles from "./TextArea.module.less";

const TextArea = ({ className, roundCorners, ...props }) => (
  <Input.TextArea
    {...props}
    className={classnames(
      styles.textarea,
      { [styles["textarea-roundCorners"]]: roundCorners },
      className
    )}
  />
);

export default TextArea;
