export const REPORT_STATUSES = {
  draft: "draft",
  completed: "completed",
  sample: "sample",
  rejected: "rejected",
  issue: "issue",
  review: "review",
  ready: "ready",
};

export const getStatusText = (status) => {
  switch (status) {
    case REPORT_STATUSES.issue:
      return REPORT_STATUSES.review;
    case REPORT_STATUSES.completed:
      return REPORT_STATUSES.ready;
    default:
      return status;
  }
};
