import React from "react";
import { Space, Tag } from "@evercityecosystem/evercity-ui";

const ProjectBreadCrumb = ({ status, name }) => (
  <Space size={12}>
    {name || "New project"}
    <Tag>{status}</Tag>
  </Space>
);
export default ProjectBreadCrumb;
