import React from "react";
import { Typography } from "antd";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./Link.module.less";

const { Link: BaseLink } = Typography;

const ExternalLink = React.forwardRef(
  ({ noMargin, size, className, type = "default", ...linkProps }, ref) => (
    <BaseLink
      {...linkProps}
      target="_blank"
      type={type}
      className={cs(
        className,
        noMargin && styles["link--noMargin"],
        styles[`link--size-${size}`],
        styles[`link--theme-${type}`]
      )}
      ref={ref}
    />
  )
);

ExternalLink.propTypes = {
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf([
    "extra-large",
    "large",
    "default",
    "small",
    "extra-small",
  ]),
};

export default ExternalLink;
