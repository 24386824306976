import React, { useState, useMemo } from "react";
import { Button, Drawer, Tabs, Typography } from "antd";
import {
  CloseCircleOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { Space } from "@antd-ovveride";

import styles from "./AttachFileDrawer.module.less";
import UploadTab from "./UploadTab";
import FromCloudTab from "./FromCloudTab";
import RecentFilesTabConnected from "./RecentFilesTabConnected";

const { TabPane } = Tabs;
const { Text } = Typography;

// docs = [
//   {
//     uid: string,
//     data: {
//       docId: string,
//       url: string,
//       name: string,
//     },
//     type: "external" | "file" | "recent",
//     createdDate: Date,
//     status: 'uploading' | 'done' | 'error'
//     error: {
//       type: '',
//       message: ''
//     }
//   }
// ]

const DrawerFooter = ({ count, onAttach, loading }) => (
  <Space align="end" block direction="vertical">
    <Button
      data-cy="btn-save-attach"
      type="primary"
      size="large"
      disabled={!count}
      onClick={onAttach}
      loading={loading}
    >
      <Space size={12} align="center">
        Attach
        {!!count && <span className={styles.counter}>{count}</span>}
      </Space>
    </Button>
  </Space>
);

const AttachFileDrawer = ({
  isVisible,
  attachedFilesIds,
  handleClose,
  onAttach,
}) => {
  const [docs, setDocs] = useState([]);
  const [selectedDocsIds, setSelectedDocs] = useState([]);
  const loading = useMemo(
    () => docs.some((d) => d.status === "uploading"),
    [docs]
  );

  const onSelectChangeDoc = (uid) => {
    if (!docs.find((d) => d.uid === uid)) return;
    if (selectedDocsIds.includes(uid)) {
      setSelectedDocs((sf) => sf.filter((fileUid) => fileUid !== uid));
    } else {
      setSelectedDocs((sf) => [...sf, uid]);
    }
  };

  const onClose = () => {
    setDocs([]);
    setSelectedDocs([]);
    handleClose();
  };

  const onAttachHandler = () => {
    const selectedDocs = selectedDocsIds.map(
      (selectedFileUid) => docs.find((f) => f.uid === selectedFileUid)?.data
    );
    onAttach(selectedDocs);
    onClose();
  };

  const deleteDoc = (id) => {
    setDocs((currentDocs) => currentDocs.filter((d) => d.uid !== id));
    setSelectedDocs((currnetSelectedDocsIds) =>
      currnetSelectedDocsIds.filter((selectedId) => selectedId !== id)
    );
  };

  const retryDocLoading = (id) => {
    console.log(`Retry doc: ${id}`);
  };

  const createDoc = (doc, selectOnCreate = true) => {
    setDocs([...docs, doc]);
    if (
      selectOnCreate &&
      doc.status === "done" &&
      !selectedDocsIds.includes(doc.uid)
    ) {
      setSelectedDocs([...selectedDocsIds, doc.uid]);
    }
  };
  // TODO: принимать вторым аргументом док, а не список файлов и использовать lodash merge
  // хочется конечно выделить какие данные можно обновлять, а какие нет, но не супер критично
  const updateDoc = (uid, updatedFields) => {
    const docIndex = docs.findIndex((d) => d.uid === uid);
    if (docIndex === -1) return;
    const doc = docs[docIndex];
    setDocs([
      ...docs.slice(0, docIndex),
      {
        ...doc,
        data: {
          ...doc.data,
          name: "name" in updatedFields ? updatedFields.name : doc.data.name,
          docId:
            "docId" in updatedFields ? updatedFields.docId : doc.data.docId,
          url: "url" in updatedFields ? updatedFields.url : doc.data.url,
        },
        status: "status" in updatedFields ? updatedFields.status : doc.status,
        error: "error" in updatedFields ? updatedFields.error : doc.error,
      },
      ...docs.slice(docIndex + 1),
    ]);
  };

  return (
    <Drawer
      data-cy="drawer-attach"
      closeIcon={<CloseCircleOutlined className={styles.close} />}
      footer={
        <DrawerFooter
          count={selectedDocsIds.length}
          onAttach={onAttachHandler}
          loading={loading}
        />
      }
      onClose={onClose}
      placement="right"
      title="Add a file"
      visible={isVisible}
      width={480}
      headerStyle={{ borderBottom: 0 }}
      className={styles.drawer}
      keyboard={false}
      destroyOnClose
    >
      <Text type="secondary" className={styles.intro}>
        Use our built-in cloud to&nbsp;store your important files or&nbsp;upload
        it&nbsp;from your&nbsp;PC or&nbsp;cloud storage.
      </Text>

      <Tabs defaultActiveKey="1" destroyInactiveTabPane>
        <TabPane
          tab={
            <span>
              <UploadOutlined /> Upload files
            </span>
          }
          key="1"
        >
          <UploadTab
            docs={docs}
            selectedDocsIds={selectedDocsIds}
            attachedFilesIds={attachedFilesIds}
            onCreateDoc={createDoc}
            onDeleteDoc={deleteDoc}
            onRetryDoc={retryDocLoading}
            onSelectChangeDoc={onSelectChangeDoc}
            onUpdateDoc={updateDoc}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <CloudUploadOutlined /> From cloud
            </span>
          }
          key="2"
        >
          <FromCloudTab
            docs={docs}
            selectedDocsIds={selectedDocsIds}
            attachedFilesIds={attachedFilesIds}
            onCreateDoc={createDoc}
            onDeleteDoc={deleteDoc}
            onRetryDoc={retryDocLoading}
            onSelectChangeDoc={onSelectChangeDoc}
            onUpdateDoc={updateDoc}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FieldTimeOutlined />
              File storage
            </span>
          }
          key="3"
        >
          <RecentFilesTabConnected
            docs={docs}
            selectedDocsIds={selectedDocsIds}
            attachedFilesIds={attachedFilesIds}
            onCreateDoc={createDoc}
            onDeleteDoc={deleteDoc}
            onRetryDoc={retryDocLoading}
            onSelectChangeDoc={onSelectChangeDoc}
            onUpdateDoc={updateDoc}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default AttachFileDrawer;
