import React, { useMemo, useState } from "react";
import classnames from "classnames";
import DescriptionPoint from "./DescriptionPoint";
import styles from "./DescriptionPoint.module.less";
import flatToNested from "../../helpers/flatToNested";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const DescriptionPointTree = ({ points }) => {
  const [expanded, setExpanded] = useState(false);

  const pointsTree = useMemo(
    () =>
      flatToNested(points, {
        nestedProperty: "nested_points",
        parentProperty: "parent_id",
      }),
    [points]
  );

  const onEllipsis = () => {
    setExpanded(false);
  };

  if (pointsTree.length === 0) {
    return null;
  }

  return (
    <>
      {expanded ? (
        <>
          {pointsTree.map((point) => (
            <DescriptionPoint point={point} key={point.id} />
          ))}
          <span
            tabIndex="-1"
            role="button"
            onKeyDown={onKeyDownDecorator(onEllipsis)}
            className={classnames("ant-typography-expand", styles.symbol)}
            onClick={onEllipsis}
          >
            Hide description.
          </span>
        </>
      ) : (
        <DescriptionPoint
          showSuffix={
            pointsTree.length > 1 || pointsTree[0]?.nested_points?.length > 0
          }
          ellipsis
          point={pointsTree[0]}
          onExpand={() => {
            setExpanded(true);
          }}
        />
      )}
    </>
  );
};

export default DescriptionPointTree;
