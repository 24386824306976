import React from "react";
import { useOutletContext } from "react-router-dom";
import ProjectPage from "./ProjectPage";

import { useCurrentUser } from "../../hooks/user";

const ProjectPageConnected = () => {
  const [{ data: userInfo }] = useCurrentUser();
  const { project } = useOutletContext();

  return <ProjectPage project={project} currentUser={userInfo} />;
};

export default ProjectPageConnected;
