import { gql } from "urql";
import {
  reportFieldsFragment,
  companyFieldsFragment,
  activitiesFieldsFragment,
  activityReportFieldsFragment,
  unitFieldsFragment,
  unitPointDecisionFragment,
  pointsFieldsFragment,
  reportPointFragment,
  reportPointDecisionFragment,
  activitiesReportsFieldsExtendedFragment,
  userFragment,
  frameworkFragment,
  introductionFrameworkFragment,
  processForProjectFrameworkFragment,
  managementOfProceedFrameworkFragment,
  useOfProceedsFrameworkFragment,
  reportingFrameworkFragment,
  externalReviewFrameworkFragment,
  updatesFrameworkFragment,
  excludedCategoriesFrameworkFragment,
  categoryFrameworkFragment,
  goalFragment,
  debtCardsPreviewFragment,
  projectCardsPreviewFragment,
  commodityCardsPreviewFragment,
  categoryFragment,
  docFragment,
  reportAttachmentsFragment,
  unitAttachmentsFragment,
  naceCodeFragment,
  projectFragment,
  pinFragment,
  messageFragment,
} from "./fragments";

export const getReportQuery = gql`
  ${reportFieldsFragment}
  ${reportAttachmentsFragment}
  ${companyFieldsFragment}
  query getReportByPk($id: uuid!) {
    eu_taxonomy_reports_by_pk(id: $id) {
      ...reportFields
      company {
        ...companyFields
      }
      report_attachments {
        ...reportAttachments
      }
    }
  }
`;

export const getCompanyByPkQuery = gql`
  ${companyFieldsFragment}
  query getCompanyByPk($id: uuid!) {
    companies_by_pk(id: $id) {
      ...companyFields
    }
  }
`;
export const getReportsQuery = gql`
  ${reportFieldsFragment}
  ${companyFieldsFragment}
  query getReports($where: eu_taxonomy_reports_bool_exp) {
    eu_taxonomy_reports(where: $where) {
      ...reportFields
      company {
        ...companyFields
      }
      activities_reports {
        units {
          id
        }
      }
    }
  }
`;

export const getActivitiesExtendedQuery = gql`
  ${activitiesFieldsFragment}
  ${naceCodeFragment}
  query getActivitiesExtended {
    eu_taxonomy_activities(order_by: { category: { order: asc }, order: asc }) {
      ...activitiesFields
      category {
        id
        name
        order
      }
      activities_codes {
        id
        nace_code {
          ...naceCode
        }
      }
    }
  }
`;

export const getActivitiesReportsQuery = gql`
  ${activitiesReportsFieldsExtendedFragment}

  query getActivitiesReports($reportId: uuid!) {
    eu_taxonomy_activities_reports(
      where: { report_id: { _eq: $reportId } }
      order_by: { created_at: desc }
    ) {
      ...activitiesReportsFieldsExtended
    }
  }
`;

export const getActivityReportByPkQuery = gql`
  ${activityReportFieldsFragment}
  ${activitiesFieldsFragment}
  ${reportFieldsFragment}
  ${companyFieldsFragment}
  ${naceCodeFragment}
  ${pointsFieldsFragment}
  query getActivityReportByPk($id: uuid!) {
    eu_taxonomy_activities_reports_by_pk(id: $id) {
      ...activityReportFields
      activity {
        ...activitiesFields
        activities_codes {
          id
          nace_code {
            ...naceCode
          }
        }
        points(
          where: { type: { _eq: description }, rule: { _eq: text } }
          order_by: { order: asc }
        ) {
          ...pointsFields
        }
      }
      report {
        ...reportFields
        company {
          ...companyFields
        }
      }
    }
  }
`;

export const actReportWithDescriptionQuery = gql`
  ${activityReportFieldsFragment}
  ${activitiesFieldsFragment}
  ${naceCodeFragment}
  query actReportWithDescription($actReportId: uuid!) {
    eu_taxonomy_activities_reports_by_pk(id: $actReportId) {
      ...activityReportFields
      activity {
        ...activitiesFields
        activities_codes {
          id
          nace_code {
            ...naceCode
          }
        }
        category {
          id
          name
          order
        }
      }
    }
    eu_taxonomy_points(
      where: {
        rule: { _eq: text }
        type: { _eq: description }
        activity: { activities_reports: { id: { _eq: $actReportId } } }
      }
    ) {
      id
      text
    }
  }
`;

export const getActivityReportWithReportAndSumsQuery = gql`
  ${activityReportFieldsFragment}
  ${reportFieldsFragment}
  query getActivityReportWithReportAndSums(
    $id: uuid!
    $withSums: Boolean = true
  ) {
    eu_taxonomy_activities_reports_by_pk(id: $id) {
      ...activityReportFields
      report {
        ...reportFields
        activities_reports_aggregate @include(if: $withSums) {
          aggregate {
            sum {
              turnover
            }
            sum {
              capex
            }
            sum {
              opex
            }
          }
        }
      }
    }
  }
`;

export const getUnitByPkQuery = gql`
  ${unitFieldsFragment}
  query getUnitByPk($id: uuid!) {
    eu_taxonomy_units_by_pk(id: $id) {
      ...unitFields
      activities_report {
        activity_id
      }
    }
  }
`;

export const getUnitsByReportQuery = gql`
  ${unitFieldsFragment}
  query getUnitsByReport($reportId: uuid!) {
    eu_taxonomy_units(
      where: { activities_report: { report_id: { _eq: $reportId } } }
    ) {
      ...unitFields
    }
  }
`;

export const getUnitsByReportWithSumQuery = gql`
  ${unitFieldsFragment}
  query getUnitsByReport($reportId: uuid!) {
    eu_taxonomy_units(
      where: { activities_report: { report_id: { _eq: $reportId } } }
    ) {
      ...unitFields
      activities_report {
        activity {
          adaptation_type
          mitigation_type
        }
      }
    }
    eu_taxonomy_units_aggregate(
      where: { activities_report: { report_id: { _eq: $reportId } } }
    ) {
      aggregate {
        sum {
          opex
          capex
          turnover
        }
      }
    }
  }
`;

export const getUnitWithSumsOfChildrenQuery = gql`
  ${unitFieldsFragment}
  ${unitAttachmentsFragment}
  query getUnitWithSumsOfChildren($id: uuid!, $withSums: Boolean = true) {
    eu_taxonomy_units_by_pk(id: $id) {
      ...unitFields
      unit_attachments {
        ...unitAttachments
      }
    }
    eu_taxonomy_units_aggregate(where: { parent_id: { _eq: $id } })
      @include(if: $withSums) {
      aggregate {
        sum {
          turnover
        }
        sum {
          opex
        }
        sum {
          capex
        }
      }
    }
  }
`;

export const getPointsByActivityQuery = gql`
  ${unitPointDecisionFragment}
  ${pointsFieldsFragment}
  query getPointsByActivity(
    $type: eu_taxonomy_point_types_enum!
    $activityId: Int!
    $unitId: uuid!
    $objective: eu_taxonomy_objectives_enum!
  ) {
    eu_taxonomy_points(
      where: {
        type: { _eq: $type }
        activity_id: { _eq: $activityId }
        objective: { _eq: $objective }
      }
      order_by: { order: asc }
    ) {
      ...pointsFields
      unit_point_decisions(where: { unit_id: { _eq: $unitId } }) {
        ...unitPointDecision
      }
    }
  }
`;

export const getReportPoints = gql`
  ${reportPointDecisionFragment}
  ${reportPointFragment}

  query getReportPoints(
    $type: String
    $reportId: uuid!
    $MSSType: eu_taxonomy_mss_types_enum
  ) {
    eu_taxonomy_report_points(
      order_by: { order: asc }
      where: {
        _and: [
          { type: { _eq: $type } }
          {
            _or: [
              { mss_subtype: { _is_null: true } }
              { mss_subtype: { _eq: $MSSType } }
            ]
          }
        ]
      }
    ) {
      ...reportPoint
      report_point_decisions(where: { report_id: { _eq: $reportId } }) {
        ...reportPointDecision
      }
    }
  }
`;

export const getInitialAndAllMSSTypes = gql`
  query getInitialAndAllMSSTypes($reportId: uuid!) {
    eu_taxonomy_mss_types {
      key
      title
    }

    eu_taxonomy_reports_by_pk(id: $reportId) {
      mss_type
    }
  }
`;

export const getReportProblems = gql`
  query ReportProblems($reportId: uuid!) {
    eu_taxonomy_unit_point_decision(
      where: {
        _and: {
          decision: { _eq: no }
          unit: { activities_report: { report_id: { _eq: $reportId } } }
        }
      }
      distinct_on: point_id
    ) {
      id
      point {
        id
        type
        text
        activity_id
      }
    }
  }
`;

export const getActivitiesDescriptionsQuery = gql`
  query getActivitiesDescriptions {
    eu_taxonomy_points(
      where: { rule: { _eq: text }, type: { _eq: description } }
    ) {
      id
      activity_id
      text
      point_hints {
        overridden_label
        item {
          label
          description
          item_links {
            link {
              label
              URL
            }
          }
        }
      }
      point_links {
        link {
          id
          label
          URL
        }
      }
    }
  }
`;

export const userQuery = gql`
  ${userFragment}

  query UserByPK($id: uuid!) {
    users_by_pk(id: $id) {
      ...userFields
    }
  }
`;

export const reportResultPdfQuery = gql`
  ${reportFieldsFragment}
  ${companyFieldsFragment}
  ${activityReportFieldsFragment}
  ${activitiesFieldsFragment}
  ${naceCodeFragment}
  ${unitFieldsFragment}

  query report_result_pdf($id: uuid!) {
    eu_taxonomy_reports_by_pk(id: $id) {
      ...reportFields
      company {
        ...companyFields
      }
    }
    eu_taxonomy_activities_reports(where: { report_id: { _eq: $id } }) {
      ...activityReportFields
      units {
        ...unitFields
      }
      activity {
        ...activitiesFields
        points(where: { type: { _eq: dnsh } }) {
          id
          type
          subtype
          text
        }
      }
      activities_reports_codes {
        code_id
        activity_report_id
        nace_code {
          ...naceCode
        }
      }
    }
  }
`;

export const debtCardsPreviewQuery = gql`
  ${debtCardsPreviewFragment}
  query debt_cards_preview($where: bonds_bool_exp) {
    bonds(order_by: { issuance_date: desc }, where: $where) {
      ...debtCardsPreview
    }
  }
`;

export const projectCardsPreviewQuery = gql`
  ${projectCardsPreviewFragment}
  query project_cards_preview($where: projects_bool_exp) {
    projects(order_by: { created_at: desc }, where: $where) {
      ...projectCardsPreview
      project_categories {
        category {
          title
          id
        }
      }
    }
  }
`;

export const commodityCardsPreviewQuery = gql`
  ${commodityCardsPreviewFragment}
  query commodity_cards_preview($where: commodities_bool_exp) {
    commodities(order_by: { created_at: desc }, where: $where) {
      ...commodityCardsPreview
      company {
        name
      }
      project {
        project_categories {
          category {
            title
            id
          }
        }
      }
    }
  }
`;

export const debtFiltersQuery = gql`
  ${goalFragment}
  query debt_filters {
    bond_types {
      name
    }
    bond_states {
      name
    }
    goals {
      ...goal
    }
  }
`;

export const commoditiesFiltersQuery = gql`
  ${goalFragment}
  query commodities_filters {
    commodity_states {
      name
      description
    }
    commodity_types {
      name
    }
    goals {
      ...goal
    }
  }
`;

export const projectFiltersQuery = gql`
  ${goalFragment}
  ${categoryFragment}
  query project_filters {
    project_states {
      name
    }
    categories {
      id
      title
    }
    bond_types {
      name
    }
    commodity_types {
      name
    }
    goals {
      ...goal
    }
  }
`;

// TODO: переделать, пусть вкладки сами запрашивают необходимую информацию
export const debtFullInfoQuery = gql`
  ${debtCardsPreviewFragment}
  ${docFragment}
  query bonds_by_slug($slug: String!) {
    bonds(where: { slug: { _eq: $slug } }) {
      ...debtCardsPreview
      bond_standard {
        id
        title
      }
      # bond_docs {
      #   bond_id
      #   doc_id
      #   attachment_type
      #   doc {
      #     ...doc
      #   }
      # }
      bond_projects {
        project_id
        bond_id
        project {
          id
          country
          name
          location
          state
          slug
          project_categories {
            category {
              title
              id
            }
          }
          projects_goals {
            goal_id
            project_id
          }
          bond_projects {
            project_id
            bond_id
            bond {
              id
              type
            }
          }
          company {
            id
            logo
          }
          commodities {
            id
            type
          }
        }
      }
      description
      bond_units_base_price
      company {
        id
        location
        website
        establishment_year
        summary
      }
    }
  }
`;

export const projectFullInfoQuery = gql`
  ${projectFragment}
  query projects_by_slug($slug: String!) {
    projects(where: { slug: { _eq: $slug } }) {
      ...project
    }
  }
`;

export const commodityFullInfoQuery = gql`
  ${commodityCardsPreviewFragment}
  ${docFragment}
  query commodities_by_slug($slug: String!) {
    commodities(where: { slug: { _eq: $slug } }) {
      ...commodityCardsPreview
      social_benefits
      enviromental_benefits
      registry_number
      verifier
      validator
      # commodity_docs {
      #   commodity_id
      #   doc_id
      #   attachment_type
      #   doc {
      #     ...doc
      #   }
      # }
      company {
        location
        name
        logo
        summary
        website
        establishment_year
      }
      project {
        challenge
        solution
        long_term_impact
        location
        project_categories {
          category {
            title
            id
          }
        }
        roadmap(order_by: { order: asc }) {
          id
          date
          description
          order
        }
        projects_goals {
          goal_id
        }
      }
    }
  }
`;

export const mapProjectPopupQuery = gql`
  ${projectCardsPreviewFragment}
  query projects_by_pk($id: uuid!) {
    projects_by_pk(id: $id) {
      ...projectCardsPreview
      location
    }
  }
`;

export const getDocsQuery = gql`
  ${docFragment}
  query getDocs {
    docs {
      ...doc
    }
  }
`;

export const frameworkQuery = gql`
  ${frameworkFragment}
  ${introductionFrameworkFragment}
  ${processForProjectFrameworkFragment}
  ${managementOfProceedFrameworkFragment}
  ${useOfProceedsFrameworkFragment}
  ${reportingFrameworkFragment}
  ${externalReviewFrameworkFragment}
  ${updatesFrameworkFragment}

  query getFramework($id: uuid!) {
    frameworks_by_pk(id: $id) {
      ...framework
    }
  }
`;

export const frameworksQuery = gql`
  query getFrameworks($companyId: uuid!) {
    frameworks(where: { company_id: { _eq: $companyId } }) {
      id
      name
      created_at
      standard
      status
    }
  }
`;

export const frameworkExcludedCategoriesQuery = gql`
  ${excludedCategoriesFrameworkFragment}
  query getExcludedCategories {
    excluded_categories {
      id
      name
    }
  }
`;

export const frameworkCategoriesQuery = gql`
  ${categoryFrameworkFragment}
  query getCategories {
    framework_categories {
      ...category
    }
  }
`;

export const goalsQuery = gql`
  ${goalFragment}
  query getGoals {
    goals(order_by: { id: asc }) {
      ...goal
    }
  }
`;

export const usersQuery = gql`
  query getUsers($companyId: uuid!) {
    users(where: { company_id: { _eq: $companyId } }) {
      id
      display_name
      company_role
      account {
        email
      }
    }
  }
`;

export const projectDocsQuery = gql`
  ${docFragment}
  query getProjectDocs($projectId: uuid!) {
    project_docs(where: { project_id: { _eq: $projectId } }) {
      doc_id
      project_id
      doc {
        ...doc
      }
    }
  }
`;

export const pinQuery = gql`
  ${pinFragment}
  query getPin($id: uuid!) {
    commodity_PINs_by_pk(id: $id) {
      ...pin
    }
  }
`;

export const impactIndicatorsQuery = gql`
  query impactIndicators {
    impact_indicators {
      id
      name
    }
  }
`;

export const pinsQuery = gql`
  query getPins {
    commodity_PINs {
      id
      created_at
      company_id
      status
      data
      __typename
    }
  }
`;

export const projectQuery = gql`
  ${projectFragment}
  query projectById($id: uuid!) {
    projects_by_pk(id: $id) {
      ...project
    }
  }
`;

export const myProjectsQuery = gql`
  query myProjects($companyId: uuid!) {
    projects(where: { company_id: { _eq: $companyId } }) {
      id
      slug
      name
      region
      state
      project_categories {
        category {
          title
        }
      }
    }
  }
`;

export const threadsQuery = gql`
  query threads($config: jsonb!) {
    messenger_threads(where: { config: { _contains: $config } }) {
      id
      config
      thread_state {
        name
        id
      }
      topic
      __typename
    }
  }
`;

export const threadStatesQuery = gql`
  query threadStatesQuery($config: jsonb!) {
    messenger_thread_states {
      name
      id
      threads(
        order_by: { created_at: desc }
        where: { config: { _contains: $config } }
      ) {
        id
      }
    }
  }
`;

export const messagesByThreadQuery = gql`
  ${messageFragment}
  query messagesByThreadQuery($id: uuid!) {
    messenger_messages(where: { thread_id: { _eq: $id } }) {
      ...message
      author {
        avatar_url
        display_name
        id
      }
    }
  }
`;

export const threadQuery = gql`
  query thread($id: uuid!) {
    messenger_threads_by_pk(id: $id) {
      id
      created_by
      config
      topic
      thread_state {
        id
        name
      }
    }
  }
`;
