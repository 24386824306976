import { useMutation } from "urql";
import { useParams } from "react-router-dom";
import { updateFrameworkMutation } from "../../api/mutations";

const useUpdateFramework = () => {
  const [, _updateFramework] = useMutation(updateFrameworkMutation);
  const { frameworkId } = useParams();

  const updateFramework = (field) => (value) => {
    _updateFramework({
      id: frameworkId,
      data: {
        [field]: value,
      },
    });
  };

  return { updateFramework };
};

export default useUpdateFramework;
