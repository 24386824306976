import React, { useMemo } from "react";
import { useQuery } from "urql";
import groupBy from "lodash.groupby";
import { useParams } from "react-router-dom";
import ScreeningOverview from "./ScreeningOverview";
import {
  getActivitiesReportsQuery,
  getReportProblems,
} from "../../../../api/queries";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";

const ScreeningOverviewConnected = (props) => {
  const { reportId } = useParams();
  const [actReportsResponse] = useQuery({
    query: getActivitiesReportsQuery,
    variables: { reportId },
  });
  const [problemsResponse] = useQuery({
    query: getReportProblems,
    variables: { reportId },
  });

  const actReports = useMemo(
    () => actReportsResponse.data?.eu_taxonomy_activities_reports,
    [actReportsResponse.data]
  );
  const problems = useMemo(
    () => problemsResponse.data?.eu_taxonomy_unit_point_decision,
    [problemsResponse.data]
  );

  const fetching = actReportsResponse.fetching || problemsResponse.fetching;

  const actReportsWithProblems = useMemo(() => {
    if (!actReports || !problems) return;

    return actReports.map((actReport) => ({
      ...actReport,
      problems: groupBy(
        problems.filter(
          (problem) => problem.point.activity_id === actReport.activity.id
        ),
        (problem) => problem.point.type
      ),
    }));
  }, [actReports, problems]);

  if (fetching) {
    return <LoadingSpinner />;
  }

  return <ScreeningOverview {...props} actReports={actReportsWithProblems} />;
};

export default ScreeningOverviewConnected;
