import { useQuery } from "urql";
import { useMemo } from "react";
import { getPointsByActivityQuery } from "../../api/queries";

const mapUnitPointsToPoints = (unitPoints) =>
  unitPoints?.map((unitPoint) => ({
    id: unitPoint.id,
    rule: unitPoint.rule,
    text: unitPoint.text,
    decision: unitPoint.unit_point_decisions[0]?.decision,
    decision_id: unitPoint.unit_point_decisions[0]?.id,
    parent_id: unitPoint.parent_id,
    point_subtype: unitPoint.point_subtype,
    activity_id: unitPoint.activity_id,
    point_hints: unitPoint.point_hints,
    point_links: unitPoint.point_links,
    show_on_decision: unitPoint.show_on_decision,
    changes_activity_type: unitPoint.changes_activity_type,
    docs: unitPoint.unit_point_decisions[0]?.unit_decision_attachments.map(
      (a) => a.doc
    ),
  }));

const usePointsWithDecision = (
  { pointsType, activityId, unitId, objective },
  options
) => {
  const [pointsResponse] = useQuery({
    query: getPointsByActivityQuery,
    variables: { type: pointsType, activityId, unitId, objective },
    requestPolicy: "network-only",
    ...(options || {}),
  });

  const unitPoints = pointsResponse.data?.eu_taxonomy_points;
  const points = useMemo(() => mapUnitPointsToPoints(unitPoints), [unitPoints]);

  return { ...pointsResponse, data: points };
};

export default usePointsWithDecision;
