import React, { useEffect } from "react";
import {
  Form,
  Radio,
  Space,
  Text,
  ThreadProvider,
  useThreads,
} from "@evercityecosystem/evercity-ui";
import cs from "classnames";
import styles from "./ConditionalPoint.module.less";

const { DiscussedItem: FormItem } = Form;
const { Group: RadioGroup, Button: RadioButton } = Radio;

const ConditionalPoint = ({
  form,
  text,
  decision,
  id,
  children,
  discussions,
  "data-topic": dataTopic,
}) => {
  const threads = useThreads(discussions);
  useEffect(() => {
    form.setFieldsValue({
      [`point-${id}`]: decision,
    });
  }, [form, decision, id]);

  return (
    <ThreadProvider
      openCount={discussions?.open?.count || 0}
      submittedCount={discussions?.submitted?.count || 0}
    >
      <Space size={8} direction="vertical" data-topic={dataTopic}>
        <Text size="large">
          {text} {threads}
        </Text>
        <Space size={20}>
          <FormItem
            getValueFromEvent={(value) => value.id}
            getValueProps={(value) => ({ value: { id: value } })}
            name={`point-${id}`}
            rules={[{ required: true, message: "Please select an option" }]}
            style={{ marginBottom: 0 }}
            className={styles.errorMessageAbsolute}
          >
            <RadioGroup direction="horizontal">
              <RadioButton
                value="yes"
                className={cs(
                  styles.radioButton,
                  styles["radioButton--neutral"]
                )}
              >
                Yes
              </RadioButton>
              <RadioButton
                value="no"
                className={cs(
                  styles.radioButton,
                  styles["radioButton--neutral"]
                )}
              >
                No
              </RadioButton>
            </RadioGroup>
          </FormItem>
        </Space>
        <div className={styles.children}>
          <Space direction="vertical" size={30} style={{ display: "flex" }}>
            {children}
          </Space>
        </div>
      </Space>
    </ThreadProvider>
  );
};

export default ConditionalPoint;
