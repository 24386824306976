import React from "react";
import { Space } from "@antd-ovveride";
import { Typography } from "antd";

import ProductMainInfoList from "../../Product/ProductMainInfoList";
import ProductTwoColLayout from "../../Product/ProductTwoColLayout";
import FileCard from "../../FileCard/FileCard";
import RoadMap from "../../Product/RoadMap";
import ProductVerticalSpace from "../../Product/ProductVerticalSpace";
import ProductContentSection from "../../Product/ProductContentSection";
import Tag from "../../ListingTags/Tag";
import ProductCompanyInfo from "../../Product/ProductCompanyInfo";
import ProductMap from "../../Product/ProductMap";
import uppercaseFirstLetter from "../../../helpers/uppercaseFirstLetter";

const { Title } = Typography;

const getCertificationDetails = (commodity) => [
  {
    key: "Standard",
    value: commodity.commodity_standard?.title,
  },
  {
    key: "Project ID",
    value: commodity.registry_number,
  },
  {
    key: "Type",
    value: commodity.type,
  },
  {
    key: "Status",
    value: commodity?.commodity_state?.description,
  },
  {
    key: "Credit start/end",
    value: commodity.vintage,
  },
  {
    key: "Validator",
    value: commodity.validator,
  },
  {
    key: "Verifier",
    value: commodity.verifier,
  },
];

const getSustainabilityDetails = (commodity) => {
  const categories = commodity.project.project_categories.map(
    ({ category }) => category.title
  );
  const pddDocs = commodity.commodity_docs?.filter(
    (cd) => cd.attachment_type === "PDD"
  );

  return [
    {
      key: "Category",
      value: (
        <Space size={5} wrap>
          {categories?.map((cateogry) => (
            <Tag type="category" text={cateogry} key={cateogry} />
          ))}
        </Space>
      ),
    },
    {
      key: "Standard",
      value: uppercaseFirstLetter(commodity.commodity_standard?.title),
    },
    !!pddDocs?.length && {
      key: "PDD",
      value: (
        <Space block size={10} wrap>
          {pddDocs.map((cd) => (
            <FileCard
              url={cd.doc.url}
              fileName={cd.doc.name}
              title={cd.attachment_type}
              description={cd.doc.description}
              status="draft"
            />
          ))}
        </Space>
      ),
    },
    // {
    //   key: "Report",
    //   value: (
    //     <FileCard
    //       url=""
    //       description="Standard: CBS + GBP"
    //       fileName="file"
    //       title="Standard: CBS + GBP "
    //       status="verified"
    //     />
    //   ),
    // },
  ];
};

const OverviewTabContent = React.forwardRef(({ commodity }, ref) => (
  <ProductTwoColLayout>
    <Space block size={20} direction="vertical">
      <Title level={2} style={{ margin: 0 }}>
        {commodity.company.name || "name"}
      </Title>
      <ProductVerticalSpace>
        <ProductCompanyInfo
          establishmentYear={commodity.company.establishment_year}
          location={commodity.company.location}
          website={commodity.company.website}
        />
        <div ref={ref}>
          <ProductMap
            zoom={commodity.project.location.zoom}
            lng={commodity.project.location.longitude}
            lat={commodity.project.location.latitude}
          />
        </div>
        <ProductContentSection
          body={commodity.project.challenge}
          title="Challenge"
        />
        <ProductContentSection
          body={commodity.project.solution}
          title="Solution"
        />
        <ProductContentSection
          body={commodity.project.long_term_impact}
          title="Long-term impact"
        />
        <ProductContentSection
          body={<RoadMap list={commodity.project.roadmap} />}
          title="Project roadmap"
        />
      </ProductVerticalSpace>
    </Space>

    <aside>
      <ProductMainInfoList
        redirect="request_access"
        locked={false}
        items={getCertificationDetails(commodity)}
        title="Certification Details"
      />
      <ProductMainInfoList
        items={getSustainabilityDetails(commodity)}
        title="Sustainability details"
      />
    </aside>
  </ProductTwoColLayout>
));

export default OverviewTabContent;
