const calcProgressBySchema = (data, schema) => {
  if (!data || !schema) {
    return 0;
  }
  /* eslint-disable no-param-reassign */
  const total = Object.values(schema).reduce(
    ([totalFields, totalErrors], step) => {
      totalFields += Object.keys(step.describe().keys).length;
      totalErrors +=
        step.validate(data, {
          abortEarly: false,
          allowUnknown: true,
        }).error?.details?.length || 0;
      return [totalFields, totalErrors];
    },
    [0, 0]
  );
  /* eslint-disable no-param-reassign */

  const [totalFields, totalErrors] = total;

  const totalDone = totalFields - totalErrors;
  return Math.trunc((totalDone / totalFields) * 100);
};

export default calcProgressBySchema;
