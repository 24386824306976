import { RECORD_TYPE } from "../ActivitiesTable/types";

const getNameByRecord = (record) => {
  switch (record.keyType) {
    case RECORD_TYPE.ACTIVITY:
      return record.name;
    default:
      return record.name.name;
  }
};

export const nameSorter = (recordA, recordB) => {
  const nameA = getNameByRecord(recordA);
  const nameB = getNameByRecord(recordB);

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const activityTypeSorter = (recordA, recordB) => {
  const { activityType: activityTypeA } = recordA;
  const { activityType: activityTypeB } = recordB;
  if (activityTypeA < activityTypeB) {
    return -1;
  }
  if (activityTypeA > activityTypeB) {
    return 1;
  }
  return 0;
};

export const objectiveTypeSorter = (recordA, recordB) => {
  const { objective: objectiveTypeA } = recordA;
  const { objective: objectiveTypeB } = recordB;
  if (objectiveTypeA < objectiveTypeB) {
    return -1;
  }
  if (objectiveTypeA > objectiveTypeB) {
    return 1;
  }
  return 0;
};

const getFinParamByRecord = (record, param) => {
  switch (record.keyType) {
    case RECORD_TYPE.ACTIVITY:
      return record[param].total;
    default:
      return record[param];
  }
};

export const finStatsSorter = (param) => (recordA, recordB) => {
  const finParamA = getFinParamByRecord(recordA, param);
  const finParamB = getFinParamByRecord(recordB, param);

  return finParamA - finParamB;
};

export const statusSorter = (recordA, recordB) => {
  const { status: statusTypeA } = recordA;
  const { status: statusTypeB } = recordB;
  if (statusTypeA < statusTypeB) {
    return -1;
  }
  if (statusTypeA > statusTypeB) {
    return 1;
  }
  return 0;
};
