import React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import ProjectIdeaNoteHeader from "./ProjectIdeaNoteHeader";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { pinQuery } from "../../api/queries";
import { completePinMutation } from "../../api/mutations";

const ProjectIdeaNoteHeaderConnected = () => {
  const { pinId } = useParams();
  const [{ data: threads, fetching: loadingThreads }] = useGetThreads({
    entityId: pinId,
    entity: "pin",
  });
  const [, completePin] = useMutation(completePinMutation);

  const [{ data, fetching: loadingPin }] = useQuery({
    query: pinQuery,
    variables: {
      id: pinId,
    },
  });

  const pin = data?.commodity_PINs_by_pk;

  const onCreatePin = () => {
    completePin({
      id: pinId,
    });
  };

  return (
    <ProjectIdeaNoteHeader
      loading={loadingThreads || loadingPin}
      threads={threads}
      pin={pin}
      onCreatePin={onCreatePin}
    />
  );
};

export default ProjectIdeaNoteHeaderConnected;
