import cn from "classnames";
import React from "react";
import { Card, Space, Typography } from "antd";
import { node, string } from "prop-types";

import styles from "./FormCard.module.less";

const FormCard = ({ children, className, title, subtitle }) => (
  <Card className={cn(styles.card, className)}>
    {(title || subtitle) && (
      <Space direction="vertical" size={40} className={styles.header}>
        <Space direction="vertical">
          {title && (
            <Typography.Title level={2} className={styles.title}>
              {title}
            </Typography.Title>
          )}
          {subtitle && (
            <Typography.Text className={styles.subtitle}>
              {subtitle}
            </Typography.Text>
          )}
        </Space>
      </Space>
    )}

    {children && <div>{children}</div>}
  </Card>
);

FormCard.propTypes = {
  children: node.isRequired,
  className: string,
  subtitle: string,
  title: string,
};

FormCard.defaultProps = {
  className: null,
  subtitle: null,
  title: null,
};

export default FormCard;
