import React, { useEffect } from "react";
import { Space, Radio, Form } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { func } from "prop-types";
import { Select } from "@antd-ovveride";
import groupBy from "lodash.groupby";

import GoalsFilter from "./GoalsFilter";

import styles from "./Filters.module.less";

// const { Search } = Input;

const SelectFilter = ({ label, ...selectProps }) => (
  <Select
    {...selectProps}
    bordered={false}
    placeholder={label}
    className={styles.selectFilter}
    dropdownMatchSelectWidth={false}
    allowClear
    dropdownClassName={styles.dropdownMenu}
  />
);

const Filters = ({
  handleSetView,
  activeView,
  allFilters,
  selectedFiltersValues,
  onFiltersChange,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedFiltersValues) {
      form.resetFields();
    } else {
      form.setFieldsValue(selectedFiltersValues);
    }
  }, [selectedFiltersValues, form]);

  if (!allFilters.data) return null;

  const {
    goals: [goalsFilter] = [],
    sort: [sortFilter] = [],
    inlineFilters,
  } = groupBy(allFilters.data, (f) =>
    ["goals", "sort"].includes(f.type) ? f.type : "inlineFilters"
  );

  return (
    <Form
      className={styles.filters}
      onValuesChange={(values, allValues) => onFiltersChange(allValues)}
      initialValues={selectedFiltersValues}
      form={form}
    >
      <header className={styles.header}>
        {goalsFilter && (
          <Form.Item noStyle name={goalsFilter.id}>
            <GoalsFilter goals={goalsFilter} />
          </Form.Item>
        )}
      </header>
      <footer className={styles.footer}>
        <div className={styles.footerLeft}>
          <Space size={30}>
            {sortFilter && (
              <Form.Item noStyle name={sortFilter.id}>
                <SelectFilter
                  label={sortFilter.label}
                  options={sortFilter.options.map((o) => ({
                    label: o.label.replace(/_/g, " "),
                    value: o.value,
                  }))}
                />
              </Form.Item>
            )}
            {inlineFilters?.length > 0 &&
              inlineFilters.map((inlineFilter) => (
                <Form.Item noStyle name={inlineFilter.id} key={inlineFilter.id}>
                  <SelectFilter
                    label={inlineFilter.label}
                    options={inlineFilter.options.map((o) => ({
                      value: o,
                      label: o.replace(/_/g, " "),
                    }))}
                  />
                </Form.Item>
              ))}
          </Space>
        </div>
        <div className={styles.footerRight}>
          <Space size={16}>
            {/* <Search */}
            {/*   placeholder="Search by issuer name" */}
            {/*   onSearch={(e) => console.log(e)} */}
            {/*   style={{ width: 329 }} */}
            {/* /> */}
            <Radio.Group
              className={styles.buttons}
              value={activeView}
              onChange={(e) => handleSetView(e.target.value)}
            >
              <Radio.Button value="cards" className={styles.button}>
                <AppstoreOutlined />
              </Radio.Button>
              <Radio.Button value="table" className={styles.button}>
                <BarsOutlined />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </div>
      </footer>
    </Form>
  );
};

Filters.propTypes = {
  handleSetView: func,
};

export default Filters;
