import React, { useRef, useState } from "react";
import classnames from "classnames";
import styles from "./Image.module.less";

const LazyImage = ({ src, className, alt, ...props }) => {
  const ref = useRef();
  const [isPreview, setIsPreview] = useState(true);

  const handleLoad = (e) => {
    if (e.target.complete && isPreview) {
      setIsPreview(false);
    }
  };

  return (
    <div className={styles.container}>
      <img
        ref={ref}
        src={src?.blurred_url}
        alt={`${alt}--blured`}
        className={classnames(
          styles.image,
          isPreview && styles["image--preview"],
          styles.image__blurred,
          className
        )}
        {...props}
      />
      <img
        onLoad={handleLoad}
        className={classnames(styles.image, className)}
        src={src?.source_url}
        alt={alt}
      />
    </div>
  );
};

export default LazyImage;
