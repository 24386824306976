import React from "react";
import {
  AppstoreOutlined,
  DesktopOutlined,
  EuroOutlined,
  SearchOutlined,
  ShopOutlined,
  TagsOutlined,
} from "@ant-design/icons";

const checkboxes = {
  sme: [
    {
      value: "Structuring",
      label: "Structuring",
      description: "Getting started with sustainable finance.",
      icon: <AppstoreOutlined />,
    },
    {
      value: "Issuance",
      label: "Issuance",
      description: "Digital issuance of green debt and carbon instruments.",
      icon: <TagsOutlined />,
    },
    {
      value: "Marketplace",
      label: "Marketplace",
      description: "Making your projects and assets visible to stakeholders.",
      icon: <ShopOutlined />,
    },
    {
      value: "Monitoring & Reporting",
      label: "Monitoring & Reporting",
      description:
        "Evaluate sustainability risks, track performance and prepare reports.",
      icon: <DesktopOutlined />,
    },
    {
      value: "Other",
      label: "Other",
      specifiable: true,
      inputProps: {
        placeholder: "Please specify",
        triggerChange: "onChange",
      },
    },
  ],
  corporation: [
    {
      value: "Structuring",
      label: "Structuring",
      description: "Getting started with sustainable finance.",
      icon: <AppstoreOutlined />,
    },
    {
      value: "Issuance",
      label: "Issuance",
      description: "Digital issuance of green debt and carbon instruments.",
      icon: <TagsOutlined />,
    },
    {
      value: "Investment",
      label: "Investment",
      description: "Getting access to high quality sustainable finance assets.",
      icon: <EuroOutlined />,
    },
    {
      value: "Marketplace",
      label: "Marketplace",
      description: "Making your projects and assets visible to stakeholders.",
      icon: <ShopOutlined />,
    },
    {
      value: "Monitoring & Reporting",
      label: "Monitoring & Reporting",
      description:
        "Evaluate sustainability risks, track performance and prepare reports.",
      icon: <DesktopOutlined />,
    },
    {
      value: "Other",
      label: "Other",
      specifiable: true,
      inputProps: {
        placeholder: "Please specify",
        triggerChange: "onChange",
      },
    },
  ],
  institutional_investor: [
    {
      value: "Structuring",
      label: "Structuring",
      description: "Preparing green debt frameworks.",
      icon: <AppstoreOutlined />,
    },
    {
      value: "Investment",
      label: "Investment",
      description: "Getting access to high quality sustainable finance assets.",
      icon: <EuroOutlined />,
    },
    {
      value: "Monitoring & Reporting",
      label: "Monitoring & Reporting",
      description:
        "Evaluate sustainability risks, track performance and receive reports.",
      icon: <DesktopOutlined />,
    },
  ],

  retail_investor: [
    {
      value: "Investment",
      label: "Investment",
      description: "Getting access to high quality sustainable finance assets.",
      icon: <EuroOutlined />,
    },
    {
      value: "Monitoring & Reporting",
      label: "Monitoring & Reporting",
      description:
        "Evaluate sustainability risks, track performance and prepare reports.",
      icon: <DesktopOutlined />,
    },
  ],

  service_provider: [
    {
      value: "Structuring",
      label: "Structuring",
      description:
        "Preparing green frameworks or carbon documents for your clients.",
      icon: <AppstoreOutlined />,
    },
    {
      value: "Finding new clients through the platform",
      label: "Finding new clients through the platform",
      description:
        "Providing third party audit, document verification or other service.",
      icon: <SearchOutlined />,
    },
    {
      value: "Monitoring & Reporting",
      label: "Monitoring & Reporting",
      description:
        "Evaluate sustainability risks, track performance and prepare reports for your clients.",
      icon: <DesktopOutlined />,
    },
  ],
};

export default checkboxes;
