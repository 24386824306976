import React from "react";
import { useMobileOrientation } from "react-device-detect";
import MobileStopperLandscape from "./MobileStopperLandscape";
import MobileStopperPortrait from "./MobileStopperPortrait";

const MobileStopper = () => {
  const { isLandscape } = useMobileOrientation();
  return isLandscape ? <MobileStopperLandscape /> : <MobileStopperPortrait />;
};

export default MobileStopper;
