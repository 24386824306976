import { useQuery } from "urql";
import { frameworkExcludedCategoriesQuery } from "../../api/queries";

const useGetExcludedCategories = () => {
  const [response] = useQuery({
    query: frameworkExcludedCategoriesQuery,
  });

  const excludedCategories = response.data?.excluded_categories;

  return { excludedCategories, ...response };
};

export default useGetExcludedCategories;
