import React from "react";
import { Layout } from "@evercityecosystem/evercity-ui";
import { bool, oneOf } from "prop-types";

import PublicFooter from "../PublicFooter/PublicFooter";

const { Footer, Content, Header } = Layout;

const PublicLayout = ({ children, footer, variant, actions }) => (
  <Layout variant={variant}>
    <Header actions={actions} />
    <Content>{children}</Content>
    {footer && (
      <Footer>
        <PublicFooter />
      </Footer>
    )}
  </Layout>
);

PublicLayout.propTypes = {
  footer: bool,
  variant: oneOf(["dialog"]),
};

PublicLayout.defaultProps = {
  footer: true,
  variant: null,
};

export default PublicLayout;
