import React from "react";
import { Outlet } from "react-router-dom";
import LkLayout from "../LkLayout/LkLayout";
import ProjectFlowHeader from "./ProjectFlowHeader";

const ProjectFlow = () => (
  <LkLayout header={{ content: <ProjectFlowHeader /> }}>
    <Outlet />
  </LkLayout>
);

export default ProjectFlow;
