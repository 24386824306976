import React from "react";
import { Col, Row, Table } from "antd";
import classnames from "classnames";
import SummarySection from "../SummarySection/SummarySection";
import { Text, Title } from "../../antd-ovveride";
import styles from "./UseOfProceed.module.less";
import summaryStyles from "../FrameworkSummary/FrameworkSummary.module.less";
import GoalsList from "../GoalsList/GoalsList";
import List from "../List/List";

const columns = [
  {
    title: "Green category",
    dataIndex: "example_category",
    render: (category) => <Text>{category.name}</Text>,
    width: 140,
    fixed: true,
  },
  {
    title: "Examples of eligible green projects",
    dataIndex: "project_examples",
    width: 200,
    fixed: true,
    render: (projects) => (
      <ul>
        {projects?.map((project) => (
          <li>
            <Text>{project}</Text>
          </li>
        ))}
      </ul>
    ),
  },
  {
    title: "Eligibility criteria",
    fixed: true,
    render: () => <Text>CBI sector criteria</Text>,
    width: 180,
  },
  {
    title: "Impact indicators",
    dataIndex: "impact_indicators",
    fixed: true,
    width: 200,
    render: (indicators) => (
      <ul className={styles.impactIndicators}>
        {indicators?.map((indicator) => (
          <li>
            <Text>{indicator}</Text>
          </li>
        ))}
      </ul>
    ),
  },
  {
    title: "SDGs",
    fixed: true,
    width: 70,
    dataIndex: "goals",
    render: (goals) => (
      <GoalsList goals={goals.map((item) => item.goal)} image />
    ),
  },
];

const UseOfProceedSummary = ({ framework, className, ...props }) => (
  <SummarySection
    className={classnames(className, styles.proceedSummary)}
    title="Use of proceeds"
    {...props}
  >
    <Table
      className={styles.tableProceed}
      columns={columns}
      pagination={false}
      dataSource={framework.example_categories}
    />
    <Title level={5} className={summaryStyles.summary__subtitle}>
      Excluded categories
    </Title>
    <List
      items={framework.excluded_categories.map(
        (item) => item.excluded_category.name
      )}
    />
    <Row className={styles.excludedCategory}>
      <Col span={9}>
        <Title level={5} className={summaryStyles.summary__subtitle}>
          Intended contribution to SDGs
        </Title>
        <GoalsList
          image
          goals={framework.example_categories.reduce(
            (goals, category) => [
              ...goals,
              ...category.goals.map((item) => item.goal),
            ],
            []
          )}
        />
      </Col>
      <Col span={11} offset={4}>
        <Title level={5} className={summaryStyles.summary__subtitle}>
          Eligibility of financing and refinancing{" "}
        </Title>
        <List
          items={
            framework.finance_refinance === "Financing or refinancing"
              ? ["Financing", "Refinancing"]
              : [framework.finance_refinance]
          }
        />
      </Col>
    </Row>
  </SummarySection>
);

export default UseOfProceedSummary;
