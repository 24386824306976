import { pinQuery } from "../../api/queries";

const pinLoader =
  (client) =>
  async ({ params }) => {
    const isNewProject = params.pinId === "_";

    if (isNewProject) {
      return {};
    }

    const cachedData = client.readQuery(pinQuery, {
      id: params.pinId,
    });

    if (cachedData !== null) {
      return cachedData?.data?.commodity_PINs_by_pk;
    }

    const response = await client
      .query(pinQuery, {
        id: params.pinId,
      })
      .toPromise();

    return response?.data?.commodity_PINs_by_pk;
  };

export default pinLoader;
