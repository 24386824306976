import React from "react";
import { Text, Title, Space } from "@antd-ovveride";
import ActivityCardConnected from "./ActivityCardConnected";
import CodesTree from "./CodesTree";
import styles from "./SelectNaceCodeDrawerContent.module.less";

const SelectNaceCodeDrawerContent = ({
  activity,
  isSelected,
  isAdded,
  initialCheckedCodes,
  sessionCheckedCodes,
  onSelectedCodesChange,
}) => (
  <Space block direction="vertical" size={40} className={styles.container}>
    <ActivityCardConnected
      activity={activity}
      isSelected={isSelected}
      isAdded={isAdded}
      initialCheckedCodes={initialCheckedCodes}
    />
    <Space size={0} block direction="vertical">
      <Title noMargin level={3}>
        Select NACE code
      </Title>
      <Space block direction="vertical" size={20}>
        <Text type="secondary" size="large">
          {activity?.codes?.length
            ? "Please select NACE code that correspond with your company operations within the selected activity."
            : "There are no NACE codes available for this activity. Please proceed further."}
        </Text>
        {activity?.codes?.length > 0 && (
          <CodesTree
            selectedCodes={sessionCheckedCodes}
            codes={activity.codes}
            onChange={onSelectedCodesChange}
          />
        )}
      </Space>
    </Space>
  </Space>
);

export default SelectNaceCodeDrawerContent;
