import React from "react";
import {
  Title,
  Input,
  Form,
  Space,
  Paragraph,
  Select,
  TextArea,
  DragAndDropPicture,
  ContentLayout,
  Text,
  AttachFileInput,
  RoadMap,
  Card,
  Button,
  Help,
  Avatar,
} from "@evercityecosystem/evercity-ui";
import { useRouteLoaderData } from "react-router-dom";
import styles from "./ProjectInfoPage.module.less";
import ProjectMap from "./ProjectMap";

const ProjectInfoPage = () => {
  const project = useRouteLoaderData("project");

  return (
    <ContentLayout.Aside>
      <Form initialValues={project} layout="vertical">
        <Space size={40} direction="vertical" block>
          <Space direction="vertical" block>
            <Title level={2} size="large" block>
              Introduce your project
            </Title>
            <Paragraph size="extra-large" type="secondary">
              Gain trust by describing your project in a structured way and
              making it visible for stakeholders.
            </Paragraph>
          </Space>
          <Space direction="vertical" block>
            <Title level={3}>Basic information</Title>
            <Paragraph size="large" type="secondary">
              Write a clear, brief title and subtitle to help investors quickly
              understand your project.
            </Paragraph>
            <Space block size={20} equalGrow>
              <Form.Item name="name" label="Project name">
                <Input placeholder="Forest restoration at West Kalimantan" />
              </Form.Item>
              <Form.Item name="category" label="Project category">
                <Select placeholder="Select" />
              </Form.Item>
            </Space>
            <Form.Item name="description" label="Brief description">
              <TextArea placeholder="Provide a brief project description" />
            </Form.Item>
          </Space>
          <Space direction="vertical" block>
            <Title level={3}>Project image</Title>
            <Paragraph size="large" type="secondary">
              Add an image that will visually present your project on the
              platform.
            </Paragraph>
            <Form.Item>
              <DragAndDropPicture />
            </Form.Item>
          </Space>
          <Space direction="vertical" block>
            <Title level={3}>Project location</Title>
            <Paragraph size="large" type="secondary">
              Enter project location details to raise transparency, receive risk
              and impact insights.
            </Paragraph>
            <Space block equalGrow size={20}>
              <Form.Item name="country" label="Country">
                <Select placeholder="Select your country" />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input placeholder="Type in your address" />
              </Form.Item>
            </Space>
            <Text size="large" type="secondary">
              Geographical coordinates
            </Text>
            <Form.List name="coordinates" initialValue={[{}]}>
              {(coordinates) =>
                coordinates.map(({ name, ...props }) => (
                  <Space size={20} block equalGrow>
                    <Form.Item
                      {...props}
                      name={[name, "latitude"]}
                      label="Latitude"
                    >
                      <Input placeholder="41.40338" />
                    </Form.Item>
                    <Form.Item
                      {...props}
                      name={[name, "address"]}
                      label="Address"
                    >
                      <Input placeholder="2.17403" />
                    </Form.Item>
                    <Form.Item
                      {...props}
                      name={[name, "file"]}
                      label="Upload from file"
                    >
                      <AttachFileInput />
                    </Form.Item>
                  </Space>
                ))
              }
            </Form.List>
            <ProjectMap />
          </Space>
          <Space direction="vertical" block>
            <Title level={3}>Project overview</Title>
            <Paragraph size="large" type="secondary">
              Tell us more about your project: what it consists of, what
              challenge it solves and how.
            </Paragraph>
            <Form.Item
              name="challenge"
              label={
                <Help title="What is the concrete local challenge that you are solving? How is it connected to global challenges? Please be brief and specific.">
                  Challenge
                </Help>
              }
            >
              <TextArea placeholder="What is the concrete local challenge that you are solving? How is it connected to global challenges? Please be brief and specific." />
            </Form.Item>
            <Form.Item
              name="solution"
              label={
                <Help title="How are you solving the above-mentioned problem? What technology or method is used? Is there anything unique about it?">
                  Solution
                </Help>
              }
            >
              <TextArea placeholder="How are you solving the above-mentioned problem? What technology or method is used? Is there anything unique about it?" />
            </Form.Item>
            <Form.Item
              name="team"
              label={
                <Help title="Who is on your team and why will you succeed?">
                  Team
                </Help>
              }
            >
              <TextArea placeholder="Who is on your team and why will you succeed?" />
            </Form.Item>
            <Form.Item
              name="details"
              label={
                <Help title="Provide a detailed overview for your key stakeholders.">
                  Project details
                </Help>
              }
            >
              <TextArea placeholder="Provide a detailed overview for your key stakeholders." />
            </Form.Item>
          </Space>
          <Space direction="vertical" block>
            <Title level={3}>Project roadmap</Title>
            <Paragraph size="large" type="secondary">
              Share you roadmap by adding important milestones.
            </Paragraph>
            <Form.Item name="roadmap">
              <RoadMap
                className={styles.roadmap}
                defaultValue={[
                  {
                    stepNumber: 1,
                    title: "Idea",
                  },
                  {
                    stepNumber: 2,
                    title: "Feasibility",
                  },
                  {
                    stepNumber: 3,
                    title: "Business plan",
                  },
                  {
                    stepNumber: 4,
                    title: "Fundrasing",
                  },
                  {
                    stepNumber: 5,
                    title: "Implementation",
                  },
                  {
                    stepNumber: 6,
                    title: "Operation",
                  },
                ]}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" block className={styles.companyInfo}>
            <Title level={3}>Company info</Title>
            <Card.Info
              title={
                <Space>
                  <Avatar /> Helioz GmbH
                </Space>
              }
            >
              <Space direction="vertical" size={40} block>
                <Space direction="vertical" block>
                  <Text size="title" type="secondary" weight="bold">
                    Main information
                  </Text>
                  <Space size={20} block equalGrow>
                    <Form.Item label="Headquarters address">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Registration number">
                      <Input />
                    </Form.Item>
                  </Space>
                  <Space size={20} block equalGrow>
                    <Form.Item label="Year established">
                      <Select />
                    </Form.Item>
                    <Form.Item label="Number of employees">
                      <Input />
                    </Form.Item>
                  </Space>
                  <Form.Item label="About">
                    <TextArea placeholder="Briefly describe your company, its mission, business, experience and achievements." />
                  </Form.Item>
                </Space>
                <Space direction="vertical" block>
                  <Text size="title" weight="bold" type="secondary">
                    Contacts
                  </Text>
                  <Form.Item label="Company website">
                    <Input />
                  </Form.Item>
                  <Space size={20} block equalGrow>
                    <Form.Item label="Email">
                      <Input placeholder="Company@gmail.com" />
                    </Form.Item>
                    <Form.Item label="Phone">
                      <Input placeholder="+1 999 999 99 99" />
                    </Form.Item>
                  </Space>
                </Space>
                <Space size={16} block>
                  <Button type="primary">Save</Button>
                  <Button>Delete</Button>
                </Space>
              </Space>
            </Card.Info>
          </Space>
        </Space>
        <Space size={16}>
          <Button>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Next step
          </Button>
        </Space>
      </Form>
    </ContentLayout.Aside>
  );
};

export default ProjectInfoPage;
