import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Space, Button } from "antd";
import { FileTextOutlined, DownloadOutlined } from "@ant-design/icons";
import cs from "classnames";
import { Text } from "@antd-ovveride";
import styles from "./FileCard.module.less";
import TooltipWithOverflow from "../TooltipWithOverflow/TooltipWithOverflow";

const SpaceFlex = ({ style, ...props }) => (
  <Space {...props} style={{ ...style, display: "flex" }} />
);

const FileIcon = ({ status, vertical }) => (
  <SpaceFlex
    size={vertical ? 14 : 0}
    direction={vertical ? "horizontal" : "vertical"}
    align="center"
  >
    <FileTextOutlined className={styles.fileIcon} />
    <Text
      size="extra-small"
      className={cs(styles.status, styles[`status--${status}`])}
    >
      {status}
    </Text>
  </SpaceFlex>
);

const ButtonDownload = ({ fileName, url, vertical }) => (
  <Button
    type="link"
    icon={<DownloadOutlined />}
    download={fileName}
    target="_blank"
    href={url}
    className={styles.downloadBtn}
  >
    {vertical ? "Download" : null}
  </Button>
);

const Info = ({ title, description, vertical }) => (
  <SpaceFlex wrap={false} size={3} direction="vertical">
    <Text
      strong={vertical}
      className={styles.textOverflow}
      style={{ margin: 0 }}
    >
      <TooltipWithOverflow visible title={description}>
        {title}
      </TooltipWithOverflow>
    </Text>
    <Text
      strong={vertical}
      type="secondary"
      className={styles.textOverflow}
      style={{ margin: 0 }}
    >
      <TooltipWithOverflow visible title={description}>
        {description}
      </TooltipWithOverflow>
    </Text>
  </SpaceFlex>
);

const FileCard = ({ url, fileName, title, description, status, vertical }) => (
  <>
    {vertical ? (
      <Space
        align="start"
        className={cs(styles.fileCard, styles[`fileCard--vertical`])}
        direction="vertical"
        size={25}
      >
        <FileIcon status={status} vertical={vertical} />
        <Info title={title} description={description} />

        <footer className={styles.cardFooter}>
          <ButtonDownload fileName={fileName} url={url} vertical={vertical} />
        </footer>
      </Space>
    ) : (
      <Row align="middle" wrap={false} className={styles.fileCard}>
        <Col flex="0 0 auto">
          <FileIcon status={status} />
        </Col>
        <Col flex="auto" className={styles.middleCol}>
          <Info title={title} description={description} />
        </Col>

        <Col flex="0 0 auto">
          <ButtonDownload fileName={fileName} url={url} />
        </Col>
      </Row>
    )}
  </>
);

FileCard.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(["verified", "draft"]),
};

export default FileCard;
