import { useMemo } from "react";

const getCountThreads = (threads) =>
  threads?.open?.length || threads?.submitted?.length;

const getStatusTab = (threads) => {
  if (threads?.open?.length > 0) {
    return "error";
  }

  if (threads?.submitted?.length > 0) {
    return "warning";
  }
};

const useThreadAdaptForTabs = (threads, loading) =>
  useMemo(() => {
    if (!threads || loading) {
      return;
    }

    return Object.entries(threads).reduce((data, [group, threadsInGroup]) => {
      const result = { ...data };
      result[group] = {
        count: getCountThreads(threadsInGroup),
        status: getStatusTab(threadsInGroup),
      };
      return result;
    }, {});
  }, [threads, loading]);

export default useThreadAdaptForTabs;
