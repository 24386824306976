import React from "react";
import {
  FileOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Tag, Typography } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import styles from "./FileTag.module.less";

const { Text } = Typography;

const TooltipInner = ({ fileName }) => (
  <div className={styles.tooltip}>
    <Text style={{ width: "auto" }}>{fileName}</Text>
    <CopyToClipboard text={fileName}>
      <CopyOutlined className={styles.copyIcon} />
    </CopyToClipboard>
  </div>
);

const FileTag = ({ name, onClose, link }) => (
  <Tag
    className={styles.fileTag}
    icon={<FileOutlined className={styles.colorIcon} />}
    closable
    closeIcon={<CloseCircleOutlined className={styles.close} />}
    onClose={onClose}
    onClick={() => {
      window.open(link);
    }}
  >
    <Text
      className={styles.fileName}
      ellipsis={{ tooltip: <TooltipInner fileName={name} /> }}
    >
      {name}
    </Text>
  </Tag>
);

export default FileTag;
