import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useSubscription } from "urql";
import ReportFlowHeader from "./ReportFlowHeader";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";
import { getUnitsByReportQuery } from "../../api/queries";
import { threadsSubscription } from "../../api/subscriptions";

const ReportFlowHeaderConnected = () => {
  const { reportId } = useParams();
  const isNewReport = reportId === "_";

  const [{ data: threads, fetching: loadingReportThreads }] = useGetThreads(
    {
      entityId: reportId,
      entity: MESSENGER_ENTITIES.TAXONOMY_REPORT,
    },
    {
      pause: isNewReport,
    }
  );

  const [{ data: unitsResponse, fetching: loadingUnits }] = useQuery({
    query: getUnitsByReportQuery,
    variables: {
      reportId,
    },
    pause: isNewReport,
  });

  const [{ data: unitsThreadsResponse, fetching: loadingUnitsThreads }] =
    useSubscription({
      query: threadsSubscription,
      variables: {
        config: {
          entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
        },
      },
      pause: !unitsResponse,
    });

  const activitiesThread = useMemo(
    () =>
      unitsResponse?.eu_taxonomy_units?.reduce((acc, unit) => {
        const result = { ...acc };
        if (!unitsThreadsResponse) {
          return result;
        }
        const unitsThreads = unitsThreadsResponse.messenger_threads.filter(
          (thread) => thread.config.entityId === unit.id
        );
        unitsThreads.forEach((thread) => {
          result[thread.thread_state.name] = [
            ...(result[thread.thread_state.name] || []),
            thread,
          ];
        });
        return result;
      }, {}) || {},
    [unitsResponse, unitsThreadsResponse]
  );

  return (
    <ReportFlowHeader
      loading={loadingUnits || loadingReportThreads || loadingUnitsThreads}
      isNewReport={isNewReport}
      threads={{
        ...threads,
        [MESSENGER_GROUPS.TAXONOMY_REPORT.ACTIVITIES]: activitiesThread,
      }}
    />
  );
};

export default ReportFlowHeaderConnected;
