import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Text } from "../../antd-ovveride";
import styles from "./List.module.less";

const List = ({ items }) => (
  <ul className={styles.list}>
    {items.map((item) => (
      <li className={styles.list__item}>
        <Text size="large">
          <CheckOutlined /> {item}
        </Text>
      </li>
    ))}
  </ul>
);

export default List;
