import React, { useMemo } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { useQuery, useMutation } from "urql";
import { notification, Typography } from "antd";
import differenceBy from "lodash.differenceby";
import UnitInfoPage from "./UnitInfoPage";
import {
  getActivityReportWithReportAndSumsQuery,
  getUnitWithSumsOfChildrenQuery,
} from "../../api/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  attachDocToUnitMutation,
  createUnitMutation,
  unattachDocFromUnitMutation,
  updateUnitMutation,
} from "../../api/mutations";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const { Title } = Typography;

const formValuesToQueryFields = (formValues) => ({
  name: formValues.name,
  description: formValues.description,
  turnover: formValues.turnover,
  capex: formValues.capex,
  opex: formValues.opex,
  type: formValues.type,
});

const UnitInfoPageConnected = () => {
  const { onFinish, onPrevStep } = useOutletContext();
  const { reportId, activityReportId, activityId, unitParentId, unitId } =
    useParams();
  const navigate = useNavigate();
  const isNewUnit = unitId === "_";
  const hasParent = unitParentId !== "_";

  const [activityReportResponse] = useQuery({
    query: getActivityReportWithReportAndSumsQuery,
    variables: { id: activityReportId, withSums: !hasParent },
    requestPolicy: "network-only",
  });
  const activityReport =
    activityReportResponse.data?.eu_taxonomy_activities_reports_by_pk;

  const report = activityReport?.report;

  const sumsOfActivitiesForUnit =
    report?.activities_reports_aggregate?.aggregate?.sum;

  const [unitResponse] = useQuery({
    query: getUnitWithSumsOfChildrenQuery,
    variables: { id: unitId },
    pause: isNewUnit,
    requestPolicy: "network-only",
  });
  const unit = useMemo(
    () => (isNewUnit ? {} : unitResponse.data?.eu_taxonomy_units_by_pk),
    [isNewUnit, unitResponse.data]
  );
  const sumsOfChildren =
    !isNewUnit && unitResponse.data?.eu_taxonomy_units_aggregate.aggregate.sum;

  const attachedDocs = useMemo(
    () => unit?.unit_attachments?.map((at) => docToAttachedFile(at.doc)) || [],
    [unit]
  );

  const [parentUnitResponse] = useQuery({
    query: getUnitWithSumsOfChildrenQuery,
    variables: { id: unitParentId, withSums: true },
    pause: !hasParent,
    requestPolicy: "network-only",
  });

  const [threadsResponse] = useGetThreads(
    {
      entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
      entityId: unitId,
      group: MESSENGER_GROUPS.TAXONOMY_UNIT.INFO,
    },
    {
      pause: isNewUnit,
    }
  );

  const parentUnit = parentUnitResponse.data?.eu_taxonomy_units_by_pk;
  const sumsOfSiblingsForSubunit =
    parentUnitResponse.data?.eu_taxonomy_units_aggregate.aggregate.sum;

  const [createUnitResponse, createUnit] = useMutation(createUnitMutation);
  const [, updateUnit] = useMutation(updateUnitMutation);

  const [attachDocResponse, attachDoc] = useMutation(attachDocToUnitMutation);
  const [unattachDocResponse, unattachDoc] = useMutation(
    unattachDocFromUnitMutation
  );

  const fetching =
    unitResponse.fetching ||
    activityReportResponse.fetching ||
    parentUnitResponse.fetching ||
    threadsResponse.fetching;
  const error =
    unitResponse.error ||
    activityReportResponse.error ||
    parentUnitResponse.error ||
    threadsResponse.error;

  const loading =
    createUnitResponse.fetching ||
    attachDocResponse.fetching ||
    unattachDocResponse.fecthing;

  const attachDocs = async (id, docs) => {
    const attachDocPromises = docs.map((d) =>
      attachDoc({ doc_id: d.docId, unit_id: id })
    );
    const responses = await Promise.all(attachDocPromises);
    if (responses.some((r) => r.error)) {
      throw new Error("Attach files error");
    }
  };

  const unattachDocs = async (id, docs) => {
    const promises = docs.map((d) =>
      unattachDoc({ doc_id: d.docId, unit_id: id })
    );
    const responses = await Promise.all(promises);
    if (responses.some((r) => r.error)) {
      throw new Error("Unattach files error");
    }
  };

  const onValuesChange = async ({ docs = [] }) => {
    if (unit.id) {
      const removedFiles = differenceBy(attachedDocs, docs, "docId");
      const addedFiles = differenceBy(docs, attachedDocs, "docId");
      if (addedFiles.length) {
        try {
          await attachDocs(unit.id, addedFiles);
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Error attach files.",
            description: "Please try again later.",
          });
        }
      }
      if (removedFiles.length) {
        try {
          await unattachDocs(unit.id, removedFiles);
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Error unattach files.",
            description: "Please try again later.",
          });
        }
      }
    }
  };

  const submitNewUnit = async (queryFields, docs) => {
    const { mitigation_type: mitigationType, adaptation_type: adaptationType } =
      activityReport?.activity;
    const defaultObjective = mitigationType ? "mitigation" : "adaptation";
    const defaultActivityType = mitigationType || adaptationType;

    const unitRes = await createUnit({
      unit: {
        ...queryFields,
        activity_report_id: activityReportId,
        parent_id: hasParent ? unitParentId : undefined,
        objective: defaultObjective,
        activity_type: defaultActivityType,
      },
    });

    if (unitRes.error) {
      throw new Error("Create unit error");
    }

    const createdUnit = unitRes.data.insert_eu_taxonomy_units_one;

    if (docs?.length) {
      await attachDocs(createdUnit.id, docs);
    }

    return createdUnit;
  };

  const onFinishHandler = async ({ docs, ...unitFields }) => {
    const queryFields = formValuesToQueryFields(unitFields);

    if (isNewUnit) {
      try {
        const createdUnit = await submitNewUnit(queryFields, docs);
        navigate(
          `/company/taxonomy/unit/${reportId}/${activityReportId}/${activityId}/${unitParentId}/${createdUnit.id}`,
          {
            state: {
              next: true,
            },
          }
        );
      } catch (err) {
        notification.error({
          message: "Creation error",
          description:
            "An error has occured while creating unit. Please try again later.",
        });
      }
    } else {
      await updateUnit({
        unitId,
        changes: queryFields,
      });
      onFinish();
    }
  };

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }
  if (error) {
    return <Title level={3}>Not Found</Title>;
  }

  return (
    <UnitInfoPage
      threads={threadsResponse.data}
      onFinish={onFinishHandler}
      hideThreadsActions={isNewUnit}
      onBackClick={onPrevStep}
      report={report}
      loading={loading}
      actReport={activityReport}
      unit={unit}
      parentUnit={parentUnit}
      sumsOfSiblingsForSubunit={sumsOfSiblingsForSubunit}
      sumsOfChildren={sumsOfChildren}
      sumsOfActivitiesForUnit={sumsOfActivitiesForUnit}
      onValuesChange={onValuesChange}
      attachedDocs={attachedDocs}
    />
  );
};

export default UnitInfoPageConnected;
