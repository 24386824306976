import React from "react";
import FinStatsBase from "./FinStatsBase";

const CarbonCreditFinStats = ({ carbonCredit }) => (
  <FinStatsBase
    stats={[
      {
        key: "Price",
        value:
          carbonCredit.price === 0
            ? "N/A"
            : `${carbonCredit.price} ${carbonCredit.currency}/t`,
      },
      {
        key: "Project Start",
        value: carbonCredit.creditStart,
      },
      {
        key: "Standard",
        value: carbonCredit.commodity_standard?.short_title.toUpperCase(),
      },
    ]}
  />
);

export default CarbonCreditFinStats;
