import React, { useMemo } from "react";
import cs from "classnames";
import { Text } from "../../../antd-ovveride";
import styles from "./GradientLegend.module.less";

const GradientLegend = ({ layer }) => {
  const { legend } = layer;

  const gradientStyle = useMemo(() => {
    const maxValue = legend.options[legend.options.length - 1].value;
    const minValue = legend.options[0].value;
    return legend.options.reduce((styleString, option, index) => {
      const optionValueInRange = (
        ((option.value - minValue) / (maxValue - minValue)) *
        100
      ).toFixed(1);
      const optionStyle = `${option.color} ${optionValueInRange}%`;
      return `${styleString}${index > 0 ? ", " : ""}${optionStyle}`;
    }, "");
  }, [legend.options]);

  return (
    <div className={styles.gradientLegend}>
      <Text
        size="small"
        className={cs(styles.boundaryValue, styles["boundaryValue--left"])}
      >
        {legend.minText}
      </Text>
      <div className={styles.lineContainer}>
        <div
          className={styles.line}
          style={{
            background: `linear-gradient(to right, ${gradientStyle})`,
          }}
        >
          <div className={styles.lineScale}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={styles.lineScaleValues}>
          <Text size="small">low</Text>
          <Text size="small">medium</Text>
          <Text size="small">high</Text>
        </div>
      </div>
      <Text
        size="small"
        className={cs(styles.boundaryValue, styles["boundaryValue--right"])}
      >
        {legend.maxText}
      </Text>
    </div>
  );
};

export default GradientLegend;
