import { useMemo } from "react";
import cloneDeep from "lodash.clonedeep";

const makeUnitsTree = (units) => {
  if (!units) return;
  const unitsClone = cloneDeep(units);
  const unitsWithSubunits = unitsClone.map((u) => ({ ...u, subunits: [] }));

  unitsWithSubunits.forEach((unit) => {
    if (unit.parent_id) {
      const parent = unitsWithSubunits.find((p) => p.id === unit.parent_id);
      if (parent) {
        parent.subunits.push(unit);
      }
    }
  });

  return unitsWithSubunits.filter((point) => !point.parent_id);
};

const mergeUnitsToActivitiesReports = (activitiesReports, unitsTree) => {
  if (!activitiesReports || !unitsTree) return;
  return activitiesReports.map((actReport) => {
    const reportUnits = unitsTree.filter(
      (unit) => unit.activity_report_id === actReport.id
    );
    return {
      ...actReport,
      units: reportUnits,
    };
  });
};
// TODO переделать: вообще можно было бы и просто хелпер сделать и запихивать в один useMemo
const useActReportsWithUnits = (actReports, units) => {
  const unitsTree = useMemo(() => makeUnitsTree(units), [units]);
  const actReportsWithUnits = useMemo(
    () => mergeUnitsToActivitiesReports(actReports, unitsTree),
    [actReports, unitsTree]
  );

  return actReportsWithUnits;
};

export default useActReportsWithUnits;
