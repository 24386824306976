import React, { useMemo } from "react";
import { CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { Button } from "@evercityecosystem/evercity-ui";
import cs from "classnames";
import PropTypes from "prop-types";
import { Space, Text, Paragraph } from "@antd-ovveride";
import styles from "./ActivityCard.module.less";
import CodesList from "../Codes/CodesList";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const { Title } = Typography;

const STATUSES = {
  selected: "selected",
  added: "added",
  notAvailable: "notAvailable",
  default: "default",
};

const CardBtn = ({ status, onClick }) => {
  switch (status) {
    case STATUSES.selected:
      return (
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          className={cs(styles.btn, styles["btn--success"])}
          onClick={onClick}
        >
          Activity added
        </Button>
      );

    case STATUSES.added:
      return (
        <Button
          type="ghost"
          icon={<CheckCircleOutlined />}
          className={styles.btn}
          disabled
        >
          Activity added
        </Button>
      );

    case STATUSES.notAvailable:
      return (
        <Button type="ghost" className={cs(styles.btn)} disabled>
          Not available
        </Button>
      );

    case STATUSES.default:
    default:
      return (
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          className={styles.btn}
          onClick={onClick}
        >
          Add activity
        </Button>
      );
  }
};

CardBtn.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
};

const ActivityCard = ({
  isSelected,
  isAdded,
  inBlackList,
  activityName,
  codes,
  onBtnClick,
  onCardClick,
  displayBtn,
  description,
}) => {
  const naceCodesDivisions = useMemo(
    () => codes.filter((nCode) => nCode.type === "division"),
    [codes]
  );

  const status = useMemo(() => {
    if (inBlackList) {
      return STATUSES.notAvailable;
    }
    if (isAdded) {
      return STATUSES.added;
    }
    if (isSelected) {
      return STATUSES.selected;
    }
    return STATUSES.default;
  }, [isSelected, isAdded, inBlackList]);

  return (
    <div
      className={cs(styles.container, styles[`container--status-${status}`])}
      onClick={onCardClick}
      onKeyDown={onKeyDownDecorator(onCardClick)}
      tabIndex="-1"
      role="button"
    >
      <Space block size={10} direction="vertical">
        <Title level={4} className={styles.title} alt={activityName}>
          {activityName}
        </Title>
        <Space size={4} align="center">
          <Text strong size="small">
            NACE DIVISIONS:
          </Text>
          {[STATUSES.added, STATUSES.selected].includes(status) ? (
            <CodesList maxCount={3} codes={codes} />
          ) : (
            <Text strong size="small" type="secondary">
              {naceCodesDivisions.map((code) => code.code).join("; ")}
            </Text>
          )}
        </Space>
        <Paragraph
          type="secondary"
          size="large"
          ellipsis={
            displayBtn
              ? { rows: 2 }
              : {
                  rows: 2,
                  expandable: true,
                  symbol: <div>Read more.</div>,
                  onExpand: (e) => {
                    e.stopPropagation();
                  },
                }
          }
        >
          {description}
        </Paragraph>
        {displayBtn && (
          <CardBtn
            status={status}
            onClick={(e) => {
              e.stopPropagation();
              onBtnClick(e);
            }}
          />
        )}
      </Space>
    </div>
  );
};

export default ActivityCard;
