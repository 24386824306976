import React from "react";
import { format } from "date-fns";
import { Space } from "@antd-ovveride";
import { Typography } from "antd";

import ProductMainInfoList from "../../Product/ProductMainInfoList";
import ProductTwoColLayout from "../../Product/ProductTwoColLayout";
import FileCard from "../../FileCard/FileCard";
import { toMln } from "../../../helpers/converters";
import ProductVerticalSpace from "../../Product/ProductVerticalSpace";
import Tag from "../../ListingTags/Tag";
import ProductCompanyInfo from "../../Product/ProductCompanyInfo";
import ProductContentSection from "../../Product/ProductContentSection";
import ProductMap from "../../Product/ProductMap";
import uppercaseFirstLetter from "../../../helpers/uppercaseFirstLetter";

const { Title } = Typography;

const getFinancialDetails = (debt) => [
  {
    key: "ISIN number",
    value: debt.isin,
  },
  {
    key: "Volume",
    value: `${toMln(debt.volume)}M ${debt.currency}`,
  },
  {
    key: "Interest",
    value: `${debt.interest_rate_base_value?.toFixed(1)}%`,
  },
  {
    key: "Currency",
    value: debt.currency,
  },
  {
    key: "Issuance date",
    value: format(new Date(debt.issuance_date), "dd.MM.yyyy"),
  },
  {
    key: "Maturity date",
    value: format(new Date(debt.maturity_date), "dd.MM.yyyy"),
  },
];

const getSustainabilityDetails = (debt) => {
  const categories = debt.bond_projects.map(({ project }) =>
    project.project_categories.map(({ category }) => category.title)
  );
  const frameworkDoc = debt.bond_docs?.find(
    (bd) => bd.attachment_type === "Framework"
  );

  return [
    {
      key: "Standards and principles",
      value: uppercaseFirstLetter(debt.bond_standard?.title),
    },
    !!frameworkDoc && {
      key: "Framework",
      value: (
        <FileCard
          url={frameworkDoc.doc.url}
          fileName={frameworkDoc.doc.name}
          title={frameworkDoc.attachment_type}
          description={frameworkDoc.doc.description}
          status="draft"
        />
      ),
    },
    {
      key: "Categories",
      value: (
        <Space size={5} wrap>
          {categories?.map((cateogry) => (
            <Tag type="category" text={cateogry} key={cateogry} />
          ))}
        </Space>
      ),
    },
  ];
};

const OverviewTabContent = React.forwardRef(({ debt }, mapRef) => (
  <ProductTwoColLayout>
    <div>
      <Space block size={20} direction="vertical">
        <Title level={2}>{debt.company.name}</Title>
        <ProductVerticalSpace>
          <ProductCompanyInfo
            establishmentYear={debt.company.establishment_year}
            location={debt.company.location}
            website={debt.company.website}
          />
          {debt.bond_projects[0] && (
            <div ref={mapRef}>
              <ProductMap
                zoom={debt.bond_projects[0].project.location.zoom}
                lng={debt.bond_projects[0].project.location.longitude}
                lat={debt.bond_projects[0].project.location.latitude}
              />
            </div>
          )}
          <ProductContentSection
            title="Company summary"
            body={debt.company.summary}
          />
        </ProductVerticalSpace>
      </Space>
    </div>

    <aside>
      <ProductMainInfoList
        items={getFinancialDetails(debt)}
        title="Financial details"
      />
      <ProductMainInfoList
        items={getSustainabilityDetails(debt)}
        title="Sustainability details"
      />
    </aside>
  </ProductTwoColLayout>
));

export default OverviewTabContent;
