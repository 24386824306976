import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "antd";

import { FileOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { Space, Text } from "@antd-ovveride";
import styles from "./AttachedFileRow.module.less";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import DotsActionsIcon from "../ActivitiesTableOld/DotsActionsIcon/DotsActionsIcon";

const AttachedFileRowBase = ({
  name,
  disabledSelection,
  hideSelection,
  selected,
  onSelectedChange,
  actions = [],
  lastColumn,
  loading,
  editable,
  onChangeName,
}) => (
  <div className={styles.container}>
    <div className={styles.leftPart}>
      <Checkbox
        checked={selected}
        onChange={(e) => {
          onSelectedChange?.(e.target.checked);
          e.stopPropagation();
        }}
        disabled={disabledSelection || hideSelection}
        className={hideSelection && styles.hidden}
      />
      <FileOutlined className={styles.fileIcon} />
      <Text
        ellipsis={{ tooltip: name }}
        size="small"
        className={styles.fileName}
        editable={{
          editing: editable,
          enterIcon: null,
          icon: <span />,
          tooltip: false,
          autoSize: false,
          onChange: onChangeName,
        }}
      >
        {name}
      </Text>
    </div>
    <div className={styles.rightPart}>
      <Space size={10}>
        {lastColumn && <div className={styles.lastColumn}>{lastColumn}</div>}

        <div className={styles.actionsWrapper}>
          {loading && (
            <div className={styles.loadingSpinner}>
              <Loading3QuartersOutlined spin />
            </div>
          )}
          <div className={loading || !actions.length ? styles.hidden : ""}>
            <ActionsDropdown
              actions={actions}
              className={styles.actionsDots}
              disabled={loading}
            >
              <DotsActionsIcon />
            </ActionsDropdown>
          </div>
        </div>
      </Space>
    </div>
  </div>
);

AttachedFileRowBase.propTypes = {
  name: PropTypes.string.isRequired,
  disabledSelection: PropTypes.bool,
  hideSelection: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectedChange: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.object),
  lastColumn: PropTypes.element,
  loading: PropTypes.bool,
};

export default AttachedFileRowBase;
