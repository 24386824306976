import React from "react";

import LockOverlay from "../../LockOverlay/LockOverlay";

const ImpactTabContent = () => (
  <LockOverlay>
    <div>Impact tab content</div>
  </LockOverlay>
);

export default ImpactTabContent;
