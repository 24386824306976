import React, { useEffect, useState } from "react";
import AutosizeInput from "react-input-autosize";
import { EditOutlined } from "@ant-design/icons";
import classnames from "classnames";
import styles from "./FrameworkTitle.module.less";

const FrameworkTitle = ({
  defaultValue,
  name,
  inputPlaceholder,
  onChange,
  onBlur,
}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = ({ target }) => {
    setValue(target.value);
    if (onChange) {
      onChange(target.value);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <AutosizeInput
        onBlur={onBlur}
        value={value}
        onChange={handleChange}
        name={name}
        className={classnames(styles.inputTittle)}
        placeholder={inputPlaceholder}
      />
      <EditOutlined className={styles.editIcon} />
    </label>
  );
};

export default FrameworkTitle;
