import React, { useState, useRef } from "react";
import { useMutation } from "urql";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import SubstantialContributionPage from "./SubstantialContributionPage";
import { validateUnitPointsMutation } from "../../api/mutations";
import UnitNotAlignedModalSC from "../NotAlignedModal/UnitNotAlignedModalSC";
import UnitAlignedModalSC from "../AlignedModal/UnitAlignedModalSC";
import WarningProvider from "../WarningModal/WarningProvider";
import warnings from "../../data/warnings";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const SubstantialContributionPageConnected = () => {
  const { onFinish: onStepFinish, onPrevStep } = useOutletContext();
  const { activityReportId, reportId, activityId, unitParentId, unitId } =
    useParams();
  const navigate = useNavigate();
  const containerRef = useRef();
  const isSubunit = unitParentId !== "_";
  const [resultModal, setResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [validateStepAlignedResponse, validateStepAligned] = useMutation(
    validateUnitPointsMutation
  );

  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
    entityId: unitId,
    group: MESSENGER_GROUPS.TAXONOMY_UNIT.SUBSTANTIAL_CONTRIBUTION,
  });

  const finishHandler = async ({ points }) => {
    const response = await validateStepAligned({
      unitId,
      points_type: "substantial_contribution",
    });
    const status = response.data?.validate_unit_points?.status;
    const pointWithSomeDecision = points.find(
      (point) => point.decision === "some"
    );

    if (pointWithSomeDecision) {
      setResultMessage(
        warnings.doubleActivities[pointWithSomeDecision.activity_id]?.[status]
      );
    }
    setResultModal(status);
  };

  const notAlignedModalMethods = {
    onSave: () => {
      onStepFinish();
    },
    onRetry: () => {
      setResultModal(false);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    onAddSubunit: () => {
      setResultModal(false);
      navigate(
        `/company/taxonomy/unit/${reportId}/${activityReportId}/${activityId}/${unitId}/_`
      );
    },
  };

  const warningModalMethods = {
    onRetry: () => {
      navigate(
        `/company/taxonomy/unit/${reportId}/${activityReportId}/${activityId}/${unitParentId}/${unitId}`
      );
    },
  };

  const alignedModalMethods = {
    ...warningModalMethods,
    onOk: () => {
      setResultModal(false);
      onStepFinish();
    },
  };

  const sending = validateStepAlignedResponse.fetching;
  return (
    <div ref={containerRef}>
      <WarningProvider
        title="Improve your result"
        methods={warningModalMethods}
      >
        <SubstantialContributionPage
          threads={threadsResponse.data}
          onFinish={finishHandler}
          sending={sending}
          onBackClick={onPrevStep}
        />
      </WarningProvider>
      <UnitNotAlignedModalSC
        {...notAlignedModalMethods}
        message={resultMessage}
        visible={resultModal === "not_aligned"}
        isSubunit={isSubunit}
      />
      <UnitAlignedModalSC
        {...alignedModalMethods}
        isSubunit={isSubunit}
        message={resultMessage}
        visible={resultModal === "aligned"}
      />
    </div>
  );
};

export default SubstantialContributionPageConnected;
