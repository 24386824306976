import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import UseOfProceed from "./UseOfProceed";
import useGetExcludedCategories from "../../hooks/framework/useGetExcludedCategories";
import useGetCategories from "../../hooks/framework/useGetCategories";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import useSetRelation from "../../hooks/useSetRelation";
import {
  deleteRelationFrameworkExampleCategoriesInfoMutation,
  deleteRelationFrameworkExampleCategoriesMutation,
  deleteRelationFrameworkExcludedCategoriesMutation,
  setRelationFrameworkExampleCategoriesInfoMutation,
  setRelationFrameworkExampleCategoriesMutation,
  setRelationFrameworkExcludedCategoriesMutation,
  updateExampleCategoryFrameworkMutation,
} from "../../api/mutations";
import { goalsQuery } from "../../api/queries";
import useModal from "../../hooks/useModal";

const UseOfProceedConnected = () => {
  const { framework, ...props } = useOutletContext();
  const { frameworkId } = useParams();

  const [isEdit, setIsEdit] = useState(false);

  const { excludedCategories, fetching: loadingExcludedCategories } =
    useGetExcludedCategories();
  const { categories, fetching: loadingCategories } = useGetCategories(
    framework?.example_categories?.map(
      (example) => example.example_category.id
    ),
    isEdit
  );

  const [response] = useQuery({
    query: goalsQuery,
  });

  const { show, Modal, close } = useModal({});

  const {
    actions: [setExcludedCategory],
  } = useSetRelation(
    setRelationFrameworkExcludedCategoriesMutation,
    deleteRelationFrameworkExcludedCategoriesMutation,
    frameworkId
  );

  const {
    actions: [setCategory],
    response: [setCategoryResponse],
  } = useSetRelation(
    setRelationFrameworkExampleCategoriesMutation,
    deleteRelationFrameworkExampleCategoriesMutation,
    frameworkId
  );
  const [categoryId, setCategoryId] = useState();

  useEffect(() => {
    if (setCategoryResponse.data) {
      setCategoryId(setCategoryResponse.data.insert_example_categories_one.id);
      setIsEdit(true);
    }
  }, [setCategoryResponse]);

  const defaultCategory = useMemo(() => {
    if (categoryId) {
      return framework.example_categories.find(
        (category) => category.id === categoryId
      );
    }
  }, [framework, categoryId]);
  const [, updateCategory] = useMutation(
    updateExampleCategoryFrameworkMutation
  );

  const {
    actions: [setInfo],
  } = useSetRelation(
    setRelationFrameworkExampleCategoriesInfoMutation,
    deleteRelationFrameworkExampleCategoriesInfoMutation,
    categoryId
  );

  return loadingExcludedCategories || loadingCategories ? (
    <LoadingSpinner size="large" full />
  ) : (
    <UseOfProceed
      show={show}
      Modal={Modal}
      close={close}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      updateCategory={updateCategory}
      defaultCategory={defaultCategory}
      setInfo={setInfo}
      categoryId={categoryId}
      setCategoryId={setCategoryId}
      goals={response?.data?.goals}
      categories={categories}
      setCategory={setCategory}
      framework={framework}
      excludedCategories={excludedCategories}
      setExcludedCategory={setExcludedCategory}
      {...props}
    />
  );
};

export default UseOfProceedConnected;
