import React from "react";
import AttachedListConnected from "../AttachedList/AttachedListConnected";
import FromCloudFormConnected from "./FromCloudFormConnected";
import styles from "./FromCloudTab.module.less";

const FromCloudTab = ({
  docs,
  selectedDocsIds,
  onCreateDoc,
  onDeleteDoc,
  onRetryDoc,
  onUpdateDoc,
  onSelectChangeDoc,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <FromCloudFormConnected onCreateDoc={onCreateDoc} />
    </div>
    {!!docs.length && (
      <AttachedListConnected
        docs={docs}
        onDeleteDoc={onDeleteDoc}
        onRetryDoc={onRetryDoc}
        onSelectChangeDoc={onSelectChangeDoc}
        selectedDocs={selectedDocsIds}
        onUpdateDoc={onUpdateDoc}
      />
    )}
  </div>
);

export default FromCloudTab;
