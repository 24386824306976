import React, { useEffect, useMemo, useState } from "react";
import { Space } from "@antd-ovveride";
import { LAYERS, CATEGORIES } from "../../data/mapLayers";
import CategoriesSwitcher from "./CategoriesSwitcher";
import LayersControllersCollapse from "./LayersControllersCollapse";
import {
  rangeFilterToMapboxFilters,
  selectFilterToMapboxFilters,
  checkboxGroupRangeFilterToMapboxFilters,
} from "../../helpers/mapFiltersTransformation";

const tranformFilterToMapboxFilters = (filter, values) => {
  let transformMethod;
  switch (filter.type) {
    case "range":
      transformMethod = rangeFilterToMapboxFilters;
      break;
    case "select":
      transformMethod = selectFilterToMapboxFilters;
      break;
    case "checkboxGroupRange":
      transformMethod = checkboxGroupRangeFilterToMapboxFilters;
      break;
    default:
      transformMethod = () => undefined;
  }
  return transformMethod(filter, values);
};

const defaultFilters = LAYERS.map((layer) => ({
  layerId: layer.id,
  values: Object.fromEntries(
    layer.filters?.map((filter) => [
      filter.mapboxFieldKey,
      filter.initialValues,
    ]) || []
  ),
}));

const LayersControllers = ({ mapInstance, onSelectedLayersChange }) => {
  const [visibleLayers, setVisibleLayers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0].id);
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);

  const layers = useMemo(
    () => LAYERS.filter((l) => l.group.category.id === selectedCategory),
    [selectedCategory]
  );

  useEffect(() => {
    if (!mapInstance) return;
    LAYERS.forEach((layer) => {
      const layerInfo = mapInstance.getLayer(layer.mapboxId);
      if (!layerInfo) return;
      const visibilityProperty = visibleLayers.includes(layer.id)
        ? "visible"
        : "none";
      mapInstance.setLayoutProperty(
        layer.mapboxId,
        "visibility",
        visibilityProperty
      );
    });
  }, [visibleLayers, mapInstance]);

  useEffect(() => {
    onSelectedLayersChange(visibleLayers);
  }, [visibleLayers, onSelectedLayersChange]);

  const toggleLayer = (layerId) => {
    if (visibleLayers.includes(layerId)) {
      const clearedLayers = visibleLayers.filter((layer) => layer !== layerId);
      setVisibleLayers(clearedLayers);
    } else {
      setVisibleLayers((ls) => [...ls, layerId]);
    }
  };

  const onLayerFiltersChange = (layerId, values) => {
    setSelectedFilters((filters) => [
      ...filters.filter((f) => f.layerId !== layerId),
      {
        layerId,
        values,
      },
    ]);
  };

  useEffect(() => {
    const setLayerFilter = ({ layerId, values }) => {
      const layer = LAYERS.find((l) => l.id === layerId);
      if (!layer.mapboxId) return;
      const mbFilters = Object.entries(values).reduce(
        (result, [filterName, filterValues]) => {
          const filter = layer.filters.find(
            (f) => f.mapboxFieldKey === filterName
          );
          const mapboxFilter = tranformFilterToMapboxFilters(
            filter,
            filterValues
          );
          if (mapboxFilter) {
            return [...result, mapboxFilter];
          }
          return result;
        },
        []
      );
      const mapboxLayerFilter =
        mbFilters.length === 0 ? null : ["all", ...mbFilters];
      mapInstance?.setFilter(layer.mapboxId, mapboxLayerFilter);
    };
    selectedFilters.forEach(setLayerFilter);
  }, [selectedFilters, mapInstance, visibleLayers]);

  return (
    <Space block direction="vertical" size={30}>
      <CategoriesSwitcher
        categories={CATEGORIES}
        selectedCategory={selectedCategory}
        onChange={setSelectedCategory}
      />

      <LayersControllersCollapse
        key={selectedCategory}
        layers={layers}
        visibleLayers={visibleLayers}
        onLayerChange={toggleLayer}
        onLayerFiltersChange={onLayerFiltersChange}
        selectedFilters={selectedFilters}
      />
    </Space>
  );
};

export default LayersControllers;
