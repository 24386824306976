import { useQuery } from "urql";
import { useCurrentUser } from "./index";
import { usersQuery } from "../../api/queries";

const useGetUsers = () => {
  const [currentUserState] = useCurrentUser();
  const { company_id: companyId, id: userId } = currentUserState.data;
  const [response] = useQuery({
    query: usersQuery,
    variables: { companyId, userId },
  });
  return {
    users: response.data?.users || [],
  };
};

export default useGetUsers;
