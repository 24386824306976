import React from "react";
import { NavigationTabs } from "@evercityecosystem/evercity-ui";
import { MESSENGER_GROUPS } from "../../data/messenger";
import useThreadAdaptForTabs from "../../hooks/messenger/useThreadAdapteForTabs";

const tabs = [
  {
    label: "Company info",
    path: "",
    key: MESSENGER_GROUPS.TAXONOMY_REPORT.INFO,
  },
  {
    label: "Activities",
    path: "activities",
    key: MESSENGER_GROUPS.TAXONOMY_REPORT.ACTIVITIES,
  },
  {
    label: "MSS",
    path: "mss",
    key: MESSENGER_GROUPS.TAXONOMY_REPORT.MSS,
  },
  {
    label: "Result",
    path: "result",
    key: MESSENGER_GROUPS.TAXONOMY_REPORT.RESULT,
  },
];

const ReportFlowHeader = ({ threads, isNewReport, loading }) => {
  const threadsAdapted = useThreadAdaptForTabs(threads, loading);
  const items = NavigationTabs.useFillStatus({
    tabs: tabs.map((tab, index) => ({
      ...tab,
      disabled: index !== 0 && isNewReport,
    })),
    data: threadsAdapted,
  });

  return (
    <>
      <NavigationTabs items={items} stochastic />
    </>
  );
};

export default ReportFlowHeader;
