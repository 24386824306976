import React from "react";
import { Row, Col, Typography } from "antd";

import Marker from "./Marker";

import styles from "./Tooltip.module.less";

const { Text } = Typography;

const CustomPieTooltipLayout = ({ headerList, summary }) => (
  <div className={styles.tooltip}>
    <header className={styles.header}>
      <ul className={styles.list}>
        {headerList.map((item) => (
          <li className={styles.listItem} key={item.name}>
            <Marker color={item.color} />
            <Text className={styles.title}>{item.name}</Text>
          </li>
        ))}
      </ul>
    </header>

    <div className={styles.body}>
      <Row className={styles.row}>
        <Col span={24} align="left" style={{ paddingLeft: 24 }}>
          <Text>{summary}</Text>
        </Col>
      </Row>
    </div>
  </div>
);

export default CustomPieTooltipLayout;
