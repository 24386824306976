import React, { useContext, useMemo } from "react";
import { useOutletContext } from "react-router-dom";

export const GroupContext = React.createContext();
const withThreadsHoc =
  (Component, getTopic) =>
  ({ threads, property, name, topic, hideDisscussion = false, ...props }) => {
    const group = useContext(GroupContext);
    const { onOpenDiscussionsDrawer, discussionDrawerState } =
      useOutletContext();

    const threadProperty = useMemo(() => name || property, [name, property]);
    return (
      <Component
        {...props}
        name={name}
        data-topic={topic || getTopic?.({ property, name, ...props })}
        discussions={{
          hideDisscussion,
          open: {
            count: threads?.open?.filter(
              (thread) => thread.config.property === threadProperty
            ).length,
            active:
              discussionDrawerState?.property === threadProperty &&
              discussionDrawerState?.status === "open",
          },
          submitted: {
            count: threads?.submitted?.filter(
              (thread) => thread.config.property === threadProperty
            ).length,
            active:
              discussionDrawerState?.property === threadProperty &&
              discussionDrawerState?.status === "submitted",
          },
          onClickThread: (event, status) => {
            onOpenDiscussionsDrawer({
              status: status === "default" ? "open" : status,
              group,
              property: threadProperty,
              topic: topic || getTopic?.({ property, name, ...props }),
              trigger: {
                event,
                source: "flow",
              },
            });
          },
        }}
      />
    );
  };

export default withThreadsHoc;
