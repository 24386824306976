import React, { useMemo } from "react";
import groupBy from "lodash.groupby";
import SinglePieChart from "../../Charts/SinglePieChart";
import { activitiesColorsList } from "./data/colorsList";

const SubstantialContribution = ({ units, totalValue, paramName }) => {
  const groupedUnitsByObjective = useMemo(
    () => groupBy(units, (unit) => unit.objective),
    [units]
  );

  const pieData = useMemo(
    () =>
      Object.entries(groupedUnitsByObjective).map(([objective, items]) => {
        const sumByParameter = items.reduce(
          (sum, unit) => sum + unit[paramName],
          0
        );
        const percent = (sumByParameter / totalValue) * 100;

        return {
          name: objective,
          value: percent,
        };
      }),
    [groupedUnitsByObjective, totalValue, paramName]
  );

  return (
    <SinglePieChart
      colorsList={activitiesColorsList}
      data={pieData}
      legend
      title="Substantial contribution"
    />
  );
};

export default SubstantialContribution;
