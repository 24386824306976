import React from "react";
import { Modal as ModalConfirm } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TeamTable from "./TeamTable";
import { useCurrentUser } from "../../hooks/user";
import useRemoveUser from "../../hooks/user/useRemoveUser";
import useGetUsers from "../../hooks/user/useGetUsers";

const TeamTableConnected = () => {
  const [{ data: currentUser }] = useCurrentUser();
  const { remove } = useRemoveUser();
  const { users } = useGetUsers();
  const onRemove = (id) => {
    ModalConfirm.confirm({
      title: "Delete user",
      icon: <ExclamationCircleOutlined />,
      content:
        "You’re going to delete this user, this action cannot be canceled. Do you want to continue?",
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      onOk: async (closeConfirm) => {
        await remove({
          id,
        });
        closeConfirm();
      },
    });
  };
  return (
    <TeamTable onRemove={onRemove} users={users} currentUser={currentUser} />
  );
};

export default TeamTableConnected;
