import React from "react";
import { TextArea, CheckboxGroup } from "@antd-ovveride";
import { Form, Radio } from "antd";
import styles from "./Introduction.module.less";
import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import InlineNote from "../InlineNote/InlineNote";
import PhrasesSeparator from "../PhrasesSeparator/PhrasesSeparator";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import CheckboxGroupSpecify from "../CheckboxGroupSpecify/CheckboxGroupSpecify";
import SwitchUnability from "../SwitchUnability/SwitchUnability";

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Item: FormItem } = Form;
const Introduction = ({ updateFramework, framework, title }) => (
  <>
    {title}
    <FrameworkSection title="Company overview" number="1.1">
      <FrameworkQuestion title="Describe your company" number="1.1.1">
        <InlineNote bodyPaddingBottom={10}>
          <b>Please make sure that the following information is covered:</b>
          <ul className={styles.inlineNoteList}>
            <li>
              The company’s scope of activities / products / services and the
              part of the value chain that it takes
            </li>
            <li>Geography of the company’s operations</li>
            <li>The number of employees</li>
            <li>Type of ownership structure</li>
            <li>Credit rating</li>
            <li>History in the capital market</li>
            <li>Company type (listed / private)</li>
          </ul>
        </InlineNote>
        <FormItem
          initialValue={framework.comp_desc}
          name="comp_desc"
          rules={[
            {
              required: true,
              message: "Field is required",
            },
          ]}
        >
          <TextArea
            roundCorners
            autoSize
            showCount
            defaultValue={framework.comp_desc}
            maxLength={1000}
            onBlur={adapterDOMEvent(updateFramework("comp_desc"))}
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.1.2"
        title="What are your company’s long-term sustainability goals and KPIs?"
        description="Please indicate green KPIs. For example: achieve carbon neutrality by 2050, etc."
      >
        <FormItem
          name="comp_sust_goals"
          initialValue={framework.comp_sust_goals}
          rules={[
            {
              required: true,
              message: "Field is required",
            },
          ]}
        >
          <PhrasesSeparator
            defaultValue={framework.comp_sust_goals}
            onChange={updateFramework("comp_sust_goals")}
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.1.3"
        title="What are the recent, current and future initiatives which demonstrate your progress on enviromental and social objectives?"
      >
        <FormItem
          name="comp_sust_example"
          initialValue={framework.comp_sust_example}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <TextArea
            roundCorners
            autoSize
            showCount
            maxLength={1000}
            defaultValue={framework.comp_sust_example}
            onBlur={adapterDOMEvent(updateFramework("comp_sust_example"))}
          />
        </FormItem>
      </FrameworkQuestion>
      <FrameworkQuestion
        number="1.1.4"
        title="Does your company have any exposure / involvement in fossil fuel industry?"
      >
        <FormItem
          name="comp_fossil_fuel_unavailability"
          initialValue={framework.comp_fossil_fuel_unavailability}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <SwitchUnability
            defaultValue={framework.comp_fossil_fuel_unavailability}
            onChange={updateFramework("comp_fossil_fuel_unavailability")}
          >
            <FrameworkQuestion description="Please specify">
              <FormItem
                name="comp_fossil_fuel"
                initialValue={framework.comp_fossil_fuel}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <TextArea
                  roundCorners
                  autoSize
                  showCount
                  maxLength={1000}
                  defaultValue={framework.comp_fossil_fuel}
                  onBlur={adapterDOMEvent(updateFramework("comp_fossil_fuel"))}
                />
              </FormItem>
            </FrameworkQuestion>
          </SwitchUnability>
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>

    <FrameworkSection title="Framework overview" number="1.2">
      <FrameworkQuestion
        number="1.2.1"
        title="What are the green objectives of your Framework?"
        description="Please specific goals that you wish to achieve by issuing a green
          bond. For example: greenhouse gas emissions reduction, increase in
          low-carbon assets, etc."
      >
        <FormItem
          name="goals"
          initialValue={framework.goals}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <PhrasesSeparator
            defaultValue={framework.goals}
            onChange={updateFramework("goals")}
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.2.2"
        title="Which principles is the framework aligned with?"
      >
        <FormItem
          name="principles"
          initialValue={framework.principles}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <CheckboxGroup
            defaultValue={framework.principles}
            onChange={updateFramework("principles")}
            options={[
              {
                name: "Green Bond Principles",
              },
              {
                name: "Green Loan Principles",
              },
            ]}
            direction="horizontal"
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.2.3"
        title="Will pre-issuance and post-issuance verification be obtained according to the rules of the CBI standard?"
      >
        <FormItem
          name="issuance"
          initialValue={framework.issuance}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <RadioGroup
            defaultValue={framework.issuance}
            onChange={adapterDOMEvent(updateFramework("issuance"))}
          >
            <RadioButton value="Yes">Yes</RadioButton>
            <RadioButton value="No">No</RadioButton>
          </RadioGroup>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.2.4"
        title="Does the framework follow any other standards?"
      >
        <FormItem
          name="other_standards_unavailability"
          initialValue={framework.other_standards_unavailability}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <SwitchUnability
            defaultValue={framework.other_standards_unavailability}
            onChange={updateFramework("other_standards_unavailability")}
          >
            <FormItem
              name="other_standards"
              initialValue={framework.other_standards}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <CheckboxGroupSpecify
                defaultValue={framework.other_standards}
                onChange={updateFramework("other_standards")}
                options={[
                  {
                    name: "ASEAN Green Bond Standard",
                  },
                  {
                    name: "EU Green Bond Standard",
                  },
                  {
                    name: "Other (specify)",
                    specifiable: true,
                  },
                ]}
              />
            </FormItem>
          </SwitchUnability>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="1.2.5"
        title="Which types of financial instruments does this framework include?"
      >
        <FormItem
          name="instrument_types"
          initialValue={framework.instrument_types}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <CheckboxGroupSpecify
            defaultValue={framework.instrument_types}
            onChange={updateFramework("instrument_types")}
            options={[
              {
                name: "Standard Use of Proceeds Bonds",
              },
              {
                name: "Revenue Bonds",
              },
              {
                name: "Project Bonds",
              },
              {
                name: "Securitised Bonds",
              },
              {
                name: "Sukuks",
              },
              {
                name: "Loans",
              },
              {
                name: "Covered Bonds",
              },
              {
                name: "Other (specify)",
                capitalize: true,
                specifiable: true,
              },
            ]}
          />
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
  </>
);

export default Introduction;
