import React from "react";
import UnitNotAlignedModal from "./UnitNotAlignedModal";

const UnitNotAlignedModalSC = ({ isSubunit, message, ...rest }) => (
  <UnitNotAlignedModal
    {...rest}
    isSubunit={isSubunit}
    content={
      isSubunit ? (
        <div>
          <p>
            Unfortunately, your subunit failed to meet some of the technical
            screening criteria for the selected activity and is not aligned with
            the Taxonomy.
          </p>
          <span>Please proceed using one of the following options:</span>
          <ol>
            <li>Retry: change some parameters and screen the subunit again;</li>
            <li>
              Save and proceed: save the results and continue by screening other
              units and subunits.
            </li>
          </ol>
          <p>You will be able to return to this subunit later.</p>
          {message && <p>{message}</p>}
        </div>
      ) : (
        <div>
          <p>
            Unfortunately, your unit failed to meet some of the technical
            screening criteria for the selected activity and environmental
            objective and is not aligned with the Taxonomy.
          </p>
          <span>Please proceed using one of the following options:</span>
          <ol>
            <li>
              Retry: screen the unit according to another environmental
              objective or change some parameters in the unit and screen it
              again;
            </li>
            <li>
              Add subunit: identify and screen a smaller part within the unit
              that is more likely to comply with the activity criteria;
            </li>
            <li>
              Save and proceed: save the results and continue by screening other
              units.
            </li>
          </ol>
          <p>You will be able to return to this unit later.</p>
          {message && <p>{message}</p>}
        </div>
      )
    }
  />
);

export default UnitNotAlignedModalSC;
