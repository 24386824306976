import React from "react";
import { Statistic, Row, Col } from "antd";

const Statistics = ({ data }) => (
  <Row gutter={[30, 30]}>
    {data.map(({ title, value }) => (
      <Col span={12} key={title}>
        <Statistic groupSeparator="" title={title} value={value} />
      </Col>
    ))}
  </Row>
);

export default Statistics;
