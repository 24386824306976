import React from "react";

import { Form } from "antd";
import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import CheckboxGroupSpecify from "../CheckboxGroupSpecify/CheckboxGroupSpecify";
import CheckboxGroupPublish from "../CheckboxGroupPublish/CheckboxGroupPublish";
import SwitchUnability from "../SwitchUnability/SwitchUnability";

const { Item: FormItem } = Form;

const FrameworkUpdates = ({ framework, updateFramework, title }) => (
  <>
    {title}
    <FrameworkSection number="7">
      <FrameworkQuestion
        number="7.1"
        title="What will result in the updates to the framework?"
      >
        <FormItem
          name="framework_updates"
          initialValue={framework.framework_updates}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <CheckboxGroupSpecify
            defaultValue={framework.framework_updates}
            onChange={updateFramework("framework_updates")}
            options={[
              {
                name: "Feedback and input from external parties",
              },
              {
                name: "Changes in eligible green categories",
              },
              {
                name: "Other (specify)",
                specifiable: true,
              },
            ]}
          />
        </FormItem>
      </FrameworkQuestion>
      <FrameworkQuestion
        number="7.2"
        title="Will the new versions of the framework be aligned with some other guidelines / standards in the future?"
      >
        <FormItem
          name="new_standards_unavailability"
          initialValue={framework.new_standards_unavailability}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <SwitchUnability
            defaultValue={framework.new_standards_unavailability}
            onChange={updateFramework("new_standards_unavailability")}
          >
            <FormItem
              name="future_standards"
              initialValue={framework.future_standards}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <CheckboxGroupSpecify
                defaultValue={framework.future_standards}
                onChange={updateFramework("future_standards")}
                options={[
                  {
                    name: "EU Green Bond Standard",
                  },
                  {
                    name: "Other (specify)",
                    specifiable: true,
                  },
                ]}
              />
            </FormItem>
          </SwitchUnability>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="7.3"
        title="Where will all the updates be made available?"
      >
        <FormItem
          name="framework_updates_published"
          initialValue={framework.framework_updates_published}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <CheckboxGroupPublish
            onChange={updateFramework("framework_updates_published")}
            defaultValue={framework.framework_updates_published}
          />
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
  </>
);
export default FrameworkUpdates;
