import React from "react";
import { useOutletContext } from "react-router-dom";
import CommodityPage from "./CommodityPage";
import transformCommodity from "../../helpers/transformCommodity";
import { useCurrentUser } from "../../hooks/user";

const CommodityPageConnected = () => {
  const [{ data: userInfo }] = useCurrentUser();
  const { commodity } = useOutletContext();

  return (
    <CommodityPage
      commodity={transformCommodity(commodity)}
      currentUser={userInfo}
    />
  );
};

export default CommodityPageConnected;
