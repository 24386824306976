import React from "react";
import { Table as AntTable, Space } from "antd";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import SDGList from "../SDGList/SDGList";
import Tag from "../ListingTags/Tag";
import styles from "./CatalogTable.module.less";

import { thousandFormatter } from "../../helpers/thousandFormatter";

const columns = {
  project: [
    {
      title: "Project name",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <Link className={styles.title} to={link.href}>
          {link.name}
        </Link>
      ),
    },
    {
      title: "Asset type",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => (
        <Space size={5} wrap>
          {tags?.map((tag) => (
            <Tag type="assetType" text={tag} />
          ))}
        </Space>
      ),
    },
    {
      title: "Location",
      dataIndex: "country",
      key: "country",
      render: (text) => <span className={styles.gray}>{text}</span>,
    },
    {
      title: "SDGs",
      dataIndex: "projects_goals",
      key: "projects_goals",
      render: (items) => <SDGList items={items} />,
    },
    {
      title: "Category",
      dataIndex: ["project_categories"],
      key: "project_categories",
      render: (items) => (
        <Space size={5} wrap>
          {items?.map(({ category }) =>
            category.title ? (
              <Tag type="category" text={category.title} key={category.id} />
            ) : null
          )}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (items) => (
        <Space size={5} wrap>
          {items?.split(" ").map((status) => (
            <Tag text={status} type="status" />
          ))}
        </Space>
      ),
    },
  ],

  debt: [
    {
      title: "Issuer",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <Link className={styles.title} to={link.href}>
          {link.name}
        </Link>
      ),
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      key: "isin",
      render: (text) => <span className={styles.gray}>{text}</span>,
    },
    {
      title: "Asset type",
      dataIndex: "type",
      key: "type",
      render: (items) => (
        <Space size={5} wrap>
          {items?.split(" ").map((item) => (
            <Tag type="assetType" text={item} />
          ))}
        </Space>
      ),
    },
    {
      title: "Location",
      dataIndex: ["company", "country"],
      key: ["company", "country"],
      render: (text) => <span className={styles.gray}>{text}</span>,
    },
    {
      title: "SDGs",
      dataIndex: "bond_goals",
      key: "bond_goals",
      render: (items) => (
        <>
          <SDGList items={items} />
        </>
      ),
    },
    {
      title: "Issuence/maturity date",
      dataIndex: "period",
      key: "period",
    },
    {
      title: "Interest,%",
      dataIndex: "interestRateBaseValue",
      key: "interestRateBaseValue",
    },
    {
      title: "Volume, M",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (items) => (
        <Space size={5} wrap>
          {items?.split(" ").map((state) => (
            <Tag text={state} type="status" />
          ))}
        </Space>
      ),
    },
  ],

  commodity: [
    {
      title: "Company",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <Link className={styles.title} to={link.href}>
          {link.name}
        </Link>
      ),
    },
    {
      title: "№ in registry",
      dataIndex: "registry_number",
      key: "registry_number",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag type="assetType" text={type} />,
    },
    {
      title: "Project name",
      dataIndex: ["project", "name"],
      key: ["project", "name"],
    },
    {
      title: "SDGs",
      dataIndex: ["project", "projects_goals"],
      key: ["project", "projects_goals"],
      render: (items) => (
        <>
          <SDGList items={items} />
        </>
      ),
    },
    {
      title: "Standard",
      dataIndex: ["commodity_standard", "title"],
      key: ["commodity_standard", "title"],
    },
    {
      title: "Category",
      dataIndex: ["project", "project_categories"],
      key: ["project", "project_categories"],
      render: (items) => (
        <Space size={5} wrap>
          {items?.map(({ category }) => (
            <Tag type="category" text={category.title} key={category.id} />
          ))}
        </Space>
      ),
    },
    {
      title: "Country",
      dataIndex: ["company", "country"],
      key: ["company", "country"],
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      key: "vintage",
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (items) => (
        <Space size={5} wrap>
          {items?.split(" ").map((state) => (
            <Tag text={state} type="status" />
          ))}
        </Space>
      ),
    },
  ],
};

const dateFormatter = (day) => format(new Date(day), "dd.MM.yy");

const transformData = (data, type) => {
  switch (type) {
    case "debt":
      return data.map((card) => {
        const volume = `${thousandFormatter(card.volume)} ${card.currency}`;
        const period = `${dateFormatter(card.issuance_date)} / ${dateFormatter(
          card.maturity_date
        )}`;
        const interestRateBaseValue = card.interest_rate_base_value.toFixed(1);
        const link = {
          href: `/listing/debt/${card.slug}`,
          name: card.company.name,
        };
        return {
          ...card,
          link,
          volume,
          period,
          interestRateBaseValue,
        };
      });

    case "commodity":
      return data.map((card) => {
        const volume = thousandFormatter(card.volume);
        const link = {
          href: `/listing/commodity/${card.slug}`,
          name: card.company.name,
        };
        return {
          ...card,
          volume,
          link,
        };
      });

    case "project":
      return data.map((card) => {
        const volume = thousandFormatter(card.volume);
        const link = { href: `/listing/project/${card.slug}`, name: card.name };
        const tags = [
          ...card.bond_projects?.map(({ bond }) => bond.type),
          ...card.commodities?.map((item) => item.type),
        ];
        return {
          ...card,
          volume,
          link,
          tags,
        };
      });

    default:
      return data;
  }
};

const CatalogTable = ({ data, type }) => (
  <AntTable
    className={styles.table}
    pagination={false}
    dataSource={transformData(data, type)}
    columns={columns[type]}
  />
);

export default CatalogTable;
