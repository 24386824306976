import React from "react";
import { Button, Modal } from "antd";
import cs from "classnames";
import Text from "antd/lib/typography/Text";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import styles from "./WarningModal.module.less";
import { Title } from "../../antd-ovveride";

const WarningModal = ({
  title,
  footer,
  content,
  wrapClassName,
  onRetry,
  ...rest
}) => (
  <Modal
    {...rest}
    wrapClassName={cs(styles.container, wrapClassName)}
    title={
      <div className={styles.headerContent}>
        <ExclamationCircleOutlined />
        <Title level={2} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </div>
    }
    closeIcon={<CloseCircleOutlined />}
    footer={
      <Button
        data-cy="btn-retry"
        onClick={onRetry}
        size="large"
        type="primary"
        icon={<RedoOutlined />}
      >
        Retry
      </Button>
    }
  >
    <Text className={styles.message} size="large">
      {content}
    </Text>
  </Modal>
);

export default WarningModal;
