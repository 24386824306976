import React from "react";
import UnitNotAlignedModal from "./UnitNotAlignedModal";

const UnitNotAlignedModalDNSH = ({ isSubunit, ...rest }) => (
  <UnitNotAlignedModal
    {...rest}
    isSubunit={isSubunit}
    content={
      isSubunit ? (
        <div>
          <p>
            Unfortunately, your subunit failed to meet some of the DNSH criteria
            for the selected activity and is not aligned with the Taxonomy.
          </p>
          <span>Please proceed using one of the following options:</span>
          <ol>
            <li>Retry: change some parameters and screen the subunit again;</li>
            <li>
              Save and proceed: save the results and continue by screening other
              units and subunits.
            </li>
          </ol>
          You will be able to return to this subunit later.
        </div>
      ) : (
        <div>
          <p>
            Unfortunately, your unit failed to meet some of the DNSH criteria
            for the selected activity and is not aligned with the Taxonomy.
          </p>
          <span>Please proceed using one of the following options:</span>
          <ol>
            <li>Retry: change some parameters and screen the unit again;</li>

            <li>
              Add subunit: identify and screen a smaller part within the unit
              that is more likely to comply with the activity criteria;
            </li>

            <li>
              Save and proceed: save the results and continue by screening other
              units.
            </li>
          </ol>
          You will be able to return to this unit later.
        </div>
      )
    }
  />
);

export default UnitNotAlignedModalDNSH;
