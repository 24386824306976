import React from "react";
import { Row, Col } from "antd";
import { Text, Space } from "@antd-ovveride";
import ProductCompanyLink from "./ProductCompanyLink";

import styles from "./ProductCompanyInfo.module.less";

const ProductCompanyInfo = ({ location, website, establishmentYear }) => (
  <Row className={styles.grid}>
    <Col>
      <Space direction="vertical" size={2}>
        <Text size="large" type="secondary">
          Headquarters
        </Text>
        <Text size="extra-large">{location}</Text>
      </Space>
    </Col>
    <Col>
      <Space direction="vertical" size={2}>
        <Text size="large" type="secondary">
          Company website
        </Text>
        <ProductCompanyLink url={website} />
      </Space>
    </Col>
    <Col>
      <Space direction="vertical" size={2}>
        <Text size="large" type="secondary">
          Year established
        </Text>
        <Text size="extra-large">{establishmentYear}</Text>
      </Space>
    </Col>
  </Row>
);

export default ProductCompanyInfo;
