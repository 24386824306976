import React, { useState } from "react";
import { Checkbox } from "antd";
import Input from "../Input/Input";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";

const capitalizeStr = (str) =>
  str.replace(/(^|\s)\S/g, (char) => char.toUpperCase());

const CheckboxGroupOption = ({
  fromServer,
  option,
  defaultValue,
  onChange,
  defaultSpecific,
  onChangeSpecific,
}) => {
  const [isSpecifyOpen, setIsSpecifyOpen] = useState(
    defaultValue?.includes(option.value)
  );

  const handleOpenSpecify = (value) => {
    setIsSpecifyOpen(value);
  };

  return (
    <>
      <Checkbox
        onChange={adapterDOMEvent((checked) => {
          if (option.specifiable) {
            handleOpenSpecify(checked);
          }

          if (fromServer && !option.specifiable) {
            onChange(option.value, !checked);
          }
        }, "checkbox")}
        key={option.value || option}
        value={option.value}
      >
        {option.label}
      </Checkbox>
      {option.specifiable && isSpecifyOpen && (
        <Input
          roundCorners
          defaultValue={defaultSpecific && defaultSpecific[option.value]}
          onBlur={adapterDOMEvent((specificValue) =>
            onChangeSpecific(
              option.value,
              option.capitalize ? capitalizeStr(specificValue) : specificValue
            )
          )}
        />
      )}
    </>
  );
};

export default CheckboxGroupOption;
