import React, { useEffect, useMemo } from "react";
import { Radio, Space, Form } from "@evercityecosystem/evercity-ui";
import cs from "classnames";

import AttachFileInput from "../AttachFileInput/AttachFileInput";
import FilesTagsListInput from "../FilesTagsListInput/FilesTagsListInput";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";
import styles from "./BooleanPoint.module.less";

const { Group: RadioGroup, Button: RadioButton } = Radio;

/*
  AttachFileInput вызывает onChange([doc1,doc2,doc3])
  родительский компонент PointsForm перехватывает изменения в форме и вызывает колбек на изменение в файлах
  компонент FilesTagsListInput привязан к тому же value что и AttachFileInput и может управлять им

  на самом деле все сложнее, потому что AttachFileInput вызывает onChange добавляет сразу несолько файлов
  а файлы аттачатся по одному и после аттача кеш мутации обновляет value по одному файлу,
  т.е когда AttachFileInput в первый раз вызывает onChange([doc1,doc2,doc3]), где doc1, doc2, doc3 - новые файлы.
  То value сначала обновится до [doc1,doc2,doc3], потом после первой законченной мутации -> [doc1], потом после второй мутации
  value = [doc1,doc2], потом value=[doc1,doc2,doc3]. AttachFileInput на изменение value из вне не вызывает onChange

  следить за props.attachments и обновлять value нужно, потому-что query на получение point-ов(с аттачами) может обновится - если удалится док
  можно попробовать запрещать удалять приаттаченнеы доки, только аннатачить, но это полумера
*/
const BooleanPoint = ({
  form,
  attachments = [],
  pointId,
  validate,
  decision,
  ...props
}) => {
  const formattedAttachments = useMemo(
    () => attachments.map(docToAttachedFile),
    [attachments]
  );

  useEffect(() => {
    form.setFieldsValue({
      [`point-file-${pointId}`]: formattedAttachments,
    });
  }, [form, formattedAttachments, pointId]);

  useEffect(() => {
    form.setFieldsValue({
      [`point-${pointId}`]: decision,
    });
  }, [form, decision, pointId]);

  return (
    <Space size={10} direction="vertical" {...props}>
      <Space size={20}>
        <Form.DiscussedItem
          name={`point-${pointId}`}
          rules={
            validate && [{ required: true, message: "Please select an option" }]
          }
          style={{ marginBottom: 0 }}
          getValueFromEvent={(value) => value.id}
          getValueProps={(value) => ({ value: { id: value } })}
        >
          <RadioGroup direction="horizontal">
            <RadioButton
              className={cs(styles.radioButton, styles["radioButton--success"])}
              value="yes"
            >
              Yes
            </RadioButton>
            <RadioButton
              className={cs(
                styles.radioButton,
                styles["radioButton--rejected"]
              )}
              value="no"
            >
              No
            </RadioButton>
            <RadioButton
              className={cs(styles.radioButton, styles["radioButton--neutral"])}
              value="non_applicable"
            >
              Non applicable
            </RadioButton>
          </RadioGroup>
        </Form.DiscussedItem>
        <Form.DiscussedItem noStyle name={`point-file-${pointId}`}>
          <AttachFileInput disabled={!decision} />
        </Form.DiscussedItem>
      </Space>
      <Form.DiscussedItem noStyle name={`point-file-${pointId}`}>
        <FilesTagsListInput />
      </Form.DiscussedItem>
    </Space>
  );
};

export default BooleanPoint;
