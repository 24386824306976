import React, { useMemo } from "react";
import { Tree, Typography } from "antd";
import cloneDeep from "lodash.clonedeep";
import { Text } from "@antd-ovveride";
import styles from "./CodesTree.module.less";

const { Title } = Typography;

const useTree = (
  flatArray,
  parentIdKey = "parentId",
  childrenKey = "children"
) => {
  const getItem = (treeArray, itemId) => {
    const point = treeArray.find((p) => p.id === itemId);
    if (point) return point;
    const children = treeArray.map((p) => p[childrenKey] || []).flat();
    if (children.length) {
      return getItem(children, itemId);
    }
  };
  const treeArray = useMemo(() => {
    if (!flatArray) return;
    const clonedArray = cloneDeep(flatArray).map((item) => ({
      ...item,
      [childrenKey]: [],
    }));
    clonedArray.forEach((item) => {
      if (item[parentIdKey]) {
        const parent = clonedArray.find((p) => p.id === item[parentIdKey]);
        if (parent) {
          parent[childrenKey].push(item);
        }
      }
    });
    return clonedArray.filter((item) => {
      const parent = clonedArray.find((p) => p.id === item[parentIdKey]);
      return !parent;
    });
  }, [flatArray, parentIdKey, childrenKey]);

  return [treeArray, getItem];
};

const CodeTitle = ({ code }) => {
  switch (code.type) {
    case "class":
      return (
        <Text size="small" className={styles.classTitle}>
          {code.code} - {code.description}
        </Text>
      );
    case "group":
      return (
        <Text strong size="small" className={styles.groupTitle}>
          Group&nbsp;{code.code} - {code.description}
        </Text>
      );
    case "division":
      return (
        <Title level={5} className={styles.divisionTitle}>
          Division&nbsp;{code.code}. {code.description}
        </Title>
      );
    default:
    case "section":
      return null;
  }
};

const codesTreeToTreeProp = (nodes) =>
  nodes.map((node) => ({
    title: <CodeTitle code={node} />,
    key: node.id,
    children: codesTreeToTreeProp(node.children),
    checkable: node.type === "class",
  }));

const getFirstChildrenIds = (items) =>
  items[0] ? [items[0].id, ...getFirstChildrenIds(items[0].children)] : [];

const CodesTree = ({ codes, selectedCodes, onChange }) => {
  const displayedCodes = useMemo(
    () => codes.filter((c) => c.type !== "section"),
    [codes]
  );
  const [codesTree] = useTree(displayedCodes, "parentId", "children");
  const treeData = useMemo(() => codesTreeToTreeProp(codesTree), [codesTree]);

  const defaultExpandedKeys = useMemo(
    () => getFirstChildrenIds(codesTree),
    [codesTree]
  );

  return (
    <Tree
      treeData={treeData}
      onCheck={onChange}
      checkable
      selectable={false}
      className={styles.codesTree}
      checkedKeys={selectedCodes}
      defaultExpandedKeys={defaultExpandedKeys}
    />
  );
};

export default CodesTree;
