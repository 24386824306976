import React from "react";
import { Progress as AntProgress } from "antd";
import { Text, Space } from "@antd-ovveride";
import cs from "classnames";
import { decimalToPercents } from "../../helpers/percents";

import styles from "./Progress.module.less";
import { thousandFormatter } from "../../helpers/thousandFormatter";

const Progress = ({
  part,
  total,
  disabled,
  responsive,
  formatter = thousandFormatter,
}) => {
  if (part === undefined) return null;
  const proportion = total > 0 ? part / total : 0;
  const percent = decimalToPercents(proportion);
  const displayedTotal = formatter(total);
  return (
    <Space
      block
      direction="vertical"
      size={0}
      className={cs(
        styles.container,
        (disabled || !displayedTotal) && styles["container--empty"],
        responsive && styles["container--responsive"]
      )}
    >
      <AntProgress
        percent={proportion * 100}
        showInfo={false}
        size="small"
        strokeLinecap="square"
        className={styles.line}
      />
      <div className={styles.stats}>
        <Text size="small" className={styles.percent}>
          {displayedTotal ? percent : "0%"}
        </Text>
        <Text size="small" className={styles.text}>
          {displayedTotal}
        </Text>
      </div>
    </Space>
  );
};

export default Progress;
