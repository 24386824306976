import React from "react";
import { Space } from "@antd-ovveride";
import ExpandIcon from "./ExpandIcon";
import tableStyles from "./ActivitiesTable.module.less"; // TODO: переделать

const ExpandedCellWrapper = ({
  expanded,
  hidden,
  children,
  onExpandIconClick,
}) => (
  <Space size={0}>
    <div className={tableStyles.columnIndent} />
    <Space size={10} align="top">
      <div style={{ zIndex: 0, position: "relative" }}>
        <ExpandIcon
          hidden={hidden}
          expanded={expanded}
          onClick={onExpandIconClick}
        />
      </div>
      <div style={{ zIndex: 10, position: "relative" }}>{children}</div>
    </Space>
  </Space>
);

export default ExpandedCellWrapper;
