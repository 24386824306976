import React from "react";
import { Space, Form, Input, Text } from "@evercityecosystem/evercity-ui";
import styles from "./PointsGroup.module.less";

const { Item: FormItem } = Form;

const allOfValidator = async (_, value) => {
  if (value !== "yes") {
    throw new Error("All nested points should be positive");
  }
};
const oneOfValidator = async (_, value) => {
  if (value !== "yes") {
    throw new Error("At least one nested points should be positive");
  }
};
const twoOfValidator = async (_, value) => {
  if (value !== "yes") {
    throw new Error("At least two neste points should be positive");
  }
};

const validators = {
  all_of: allOfValidator,
  one_of: oneOfValidator,
  two_of: twoOfValidator,
};

const PointsGroup = ({ point, text, children, showError, initialValue }) => (
  <>
    {!!text && <Text size="large">{text}</Text>}
    <FormItem
      name={`point-${point.id}`}
      style={{ marginBottom: 0 }}
      dependencies={point.nested_points?.map((p) => `point-${p.id}-answer`)}
      className={styles.formItem}
      initialValue={initialValue}
      rules={
        showError &&
        point.nested_points?.length && [
          () => ({
            validator: validators[point.rule],
          }),
        ]
      }
    >
      <Input />
    </FormItem>
    <div className={styles.children}>
      <Space direction="vertical" size={30} style={{ display: "flex" }}>
        {children}
      </Space>
    </div>
  </>
);

export default PointsGroup;
