import React from "react";
import { Typography } from "antd";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./Text.module.less";

const { Text: BaseText } = Typography;

const Text = ({
  noMargin,
  block,
  size,
  className,
  color,
  capitalize,
  ...textProps
}) => (
  <BaseText
    className={cs(
      className,
      noMargin && styles["text--noMargin"],
      block && styles["text--block"],
      styles[`text--size-${size}`],
      styles[`text--color-${color}`],
      { [styles[`text--capitalize`]]: capitalize }
    )}
    {...textProps}
  />
);

Text.propTypes = {
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf([
    "extra-large",
    "large",
    "default",
    "small",
    "extra-small",
  ]),
};

export default Text;
