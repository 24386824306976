import React, { useState, useMemo } from "react";
import { Table } from "antd";

import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";

import {
  activityTypeSorter,
  finStatsSorter,
  nameSorter,
  objectiveTypeSorter,
} from "../ActivitiesPageTable/sorts";
import {
  renderAcitivtyTypeCell,
  renderCodeCell,
  renderFinParamCell,
  renderNameCell,
  renderObjectiveCell,
} from "../ActivitiesPageTable/renderCells";

import buildTableRowsData from "./buildTableRowsData";
import styles from "./ResultsActivitiesTable.module.less";
import { renderColorCell } from "./renderCells";

const { Column } = Table;

const ResultsActivitiesTable = ({ actReports, reportCurrency }) => {
  const [expandedRows, setExpandedRow] = useState([]);
  const tableRows = useMemo(() => buildTableRowsData(actReports), [actReports]);

  return (
    <ActivitiesTable
      tableRows={tableRows}
      expandedRows={expandedRows}
      onExpandedRowsChange={setExpandedRow}
    >
      <Column
        dataIndex="color"
        key="color"
        render={renderColorCell}
        customIndents
        className={styles.colorColumn}
      />
      <Column
        title="Activity name"
        dataIndex="name"
        key="name"
        render={renderNameCell(expandedRows, setExpandedRow)}
        sorter={nameSorter}
        customIndents
        className={styles.remainedWidthColumn}
      />
      <Column
        title="NACE code"
        dataIndex="code"
        key="code"
        render={renderCodeCell}
      />
      <Column
        title="Activity type"
        dataIndex="activityType"
        key="activityType"
        render={renderAcitivtyTypeCell}
        sorter={activityTypeSorter}
      />
      <Column
        title="Objective"
        dataIndex="objective"
        key="objective"
        render={renderObjectiveCell}
        sorter={objectiveTypeSorter}
      />
      <Column
        title={
          <span>
            <strong>Turnover</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="turnover"
        key="turnover"
        render={renderFinParamCell}
        sorter={finStatsSorter("turnover")}
      />
      <Column
        title={
          <span>
            <strong>CapEx</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="capex"
        key="capex"
        render={renderFinParamCell}
        sorter={finStatsSorter("capex")}
      />
      <Column
        title={
          <span>
            <strong>OpEx</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="opex"
        key="opex"
        render={renderFinParamCell}
        sorter={finStatsSorter("opex")}
      />
    </ActivitiesTable>
  );
};

export default ResultsActivitiesTable;
