import React from "react";
import cs from "classnames";

import { Button, Typography } from "antd";
import { ReactComponent as NotFoundIcon } from "../../../assets/icons/notFound.svg";
import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";
import styles from "./MobileStopperLandscape.module.less";
import { Text } from "../../../antd-ovveride";

const { Title } = Typography;

const MobileStopperLandscape = () => (
  <div className={cs(styles.container)}>
    <div className={styles.notFoundIcon}>
      <NotFoundIcon />
    </div>
    <div className={styles.content}>
      <div className={styles.logo}>
        <LogoIcon />
      </div>
      <Title level={3} style={{ margin: 0 }}>
        Oooops!
      </Title>
      <Text size="large" type="secondary" className={styles.textBlock}>
        To use the service, you must use the desktop version
      </Text>
      <Button
        type="primary"
        size="large"
        style={{ display: "inline-flex" }}
        href="https://www.evercity.io/"
      >
        Visit site
      </Button>
    </div>
  </div>
);

export default MobileStopperLandscape;
