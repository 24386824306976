import { useMutation } from "urql";
import { removeUserMutation } from "../../api/mutations";

const useRemoveUser = () => {
  const [, remove] = useMutation(removeUserMutation);
  return {
    remove,
  };
};

export default useRemoveUser;
