import { useSearchParams } from "react-router-dom";
import { useQuery } from "urql";
import { threadQuery } from "../../api/queries";

const useThread = () => {
  const [searchParams] = useSearchParams();
  const threadId = searchParams.get("threadId");

  return useQuery({
    query: threadQuery,
    variables: {
      id: threadId,
    },
    pause: !threadId,
  });
};

export default useThread;
