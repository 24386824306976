import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ProjectIdeaNoteBenefits from "./ProjectIdeaNoteBenefits";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error500 from "../errors/Error500";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const ProjectIdeaNoteBenefitsConnected = () => {
  const { openDiscussionDrawer } = useOutletContext();

  const { pinId } = useParams();

  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.PIN,
    entityId: pinId,
    group: MESSENGER_GROUPS.PIN.BENEFITS,
  });

  if (threadsResponse.fetching) {
    return <LoadingSpinner size="large" full />;
  }

  if (threadsResponse.error) {
    return <Error500 />;
  }

  return (
    <ProjectIdeaNoteBenefits
      onOpenDiscussionsDrawer={openDiscussionDrawer}
      threads={threadsResponse.data}
    />
  );
};

export default ProjectIdeaNoteBenefitsConnected;
