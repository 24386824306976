import React from "react";
import AlignedModal from "./AlignedModal";

const UnitAlignedModalDNSH = ({ isSubunit, ...rest }) => (
  <AlignedModal {...rest} title="Aligned">
    Congratulations! Your {isSubunit ? "subunit" : "unit"} is aligned with the
    EU Taxonomy.
  </AlignedModal>
);

export default UnitAlignedModalDNSH;
