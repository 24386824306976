import React, { useMemo } from "react";
import uniqueId from "lodash.uniqueid";
import { FILE_TYPES } from "./types";
import AttachedListConnected from "../AttachedList/AttachedListConnected";

const formatDoc = (doc) => {
  const date = new Date();
  date.setSeconds(
    date.getSeconds() - Math.floor(Math.random() * 200 * 86400) - 86400
  );
  return {
    uid: uniqueId("recent-file-"),
    data: {
      docId: doc.id,
      url: doc.url,
      name: doc.name,
      userId: doc.user_id,
    },
    type: FILE_TYPES.RECENT,
    createdDate: doc.created_at,
    status: "done",
  };
};

const RecentFilesTab = ({
  docs,
  selectedDocsIds,
  attachedFilesIds,
  recentDocs,
  onCreateDoc,
  onDeleteDoc,
  onRetryDoc,
  onSelectChangeDoc,
  onUpdateDoc,
}) => {
  const sessionDocs = useMemo(
    () => docs.filter((d) => d.status === "done"),
    [docs]
  );
  const formattedRecentDocs = useMemo(
    () => recentDocs.map(formatDoc),
    [recentDocs]
  );

  const commonDocsList = useMemo(
    () =>
      formattedRecentDocs.reduce((mergedDocs, recentDoc) => {
        const sessionDoc = sessionDocs.find(
          (sd) => sd.data.docId === recentDoc.data.docId
        );
        return [...mergedDocs, sessionDoc || recentDoc];
      }, []),
    [formattedRecentDocs, sessionDocs]
  );

  const onSelect = (uid) => {
    const doc = sessionDocs.find((d) => d.uid === uid);
    if (!doc) {
      const recentDoc = formattedRecentDocs.find((d) => d.uid === uid);
      onCreateDoc(recentDoc);
    } else {
      onSelectChangeDoc(uid);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <AttachedListConnected
        docs={commonDocsList}
        attachedFilesIds={attachedFilesIds}
        onDeleteDoc={onDeleteDoc}
        onRetryDoc={onRetryDoc}
        onSelectChangeDoc={onSelect}
        selectedDocs={selectedDocsIds}
        onUpdateDoc={onUpdateDoc}
      />
    </div>
  );
};

export default RecentFilesTab;
