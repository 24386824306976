import { cacheExchange } from "@urql/exchange-graphcache";
import { gql } from "urql";
import {
  getReportsQuery,
  getActivitiesReportsQuery,
  getUnitsByReportQuery,
  userQuery,
  frameworkQuery,
  frameworksQuery,
  projectDocsQuery,
  pinsQuery,
  threadsQuery,
  messagesByThreadQuery,
} from "../../api/queries";
import {
  reportPointFragment,
  reportPointDecisionFragment,
  pointsFieldsFragment,
  unitPointDecisionFragment,
  unitDecisionAttachmentFragment,
  reportDecisionAttachmentFragment,
  exampleCategoryFrameworkFragment,
  frameworkFragment,
  reportFieldsFragment,
} from "../../api/fragments";

const clientCache = cacheExchange({
  updates: {
    Mutation: {
      delete_eu_taxonomy_reports_by_pk(result, { id }, cache) {
        cache.invalidate({
          __typename: "eu_taxonomy_reports",
          id,
        });
      },
      insert_eu_taxonomy_reports_one(
        { insert_eu_taxonomy_reports_one: newReport },
        args,
        cache
      ) {
        cache.updateQuery(
          {
            query: getReportsQuery,
            variables: {
              where: {
                status: {
                  _neq: "sample",
                },
                _or: [{ company_id: { _eq: args.object.company_id } }],
              },
            },
          },
          (data) => {
            data?.eu_taxonomy_reports?.push(newReport);
            return data;
          }
        );
      },
      insert_eu_taxonomy_activities_reports(
        {
          insert_eu_taxonomy_activities_reports: {
            returning: newActivitisReports,
          },
        },
        args,
        cache
      ) {
        newActivitisReports.forEach((activityReport) => {
          cache.updateQuery(
            {
              query: getActivitiesReportsQuery,
              variables: { reportId: activityReport.report_id },
            },
            (data) => {
              data.eu_taxonomy_activities_reports.unshift(activityReport);
              return data;
            }
          );
        });
        // cache.updateQuery({ query: getActivitiesWithReport }, (data) => {
        //   // eslint-disable-next-line no-param-reassign
        //   const newActivities = data.eu_taxonomy_activities.map((activity) => {
        //     const actReport = activitiesReports.find(
        //       (ar) => ar.activity_id === activity.id
        //     );
        //     const activityContainReport = !!activity.activities_reports.find(
        //       (r) => r.id === actReport.report_id
        //     );
        //     console.log({
        //       activityContainReport,
        //       actReport: !!actReport,
        //     });
        //     if (actReport && !activityContainReport) {
        //       return {
        //         ...activity,
        //         activities_reports: [
        //           ...activity.activities_reports,
        //           actReport.id,
        //         ],
        //       };
        //     }
        //     return activity;
        //   });
        //   return {
        //     ...data,
        //     eu_taxonomy_activities: newActivities,
        //   };
        // });
      },
      delete_eu_taxonomy_activities_reports_by_pk(result, args, cache) {
        cache.updateQuery(
          {
            query: getActivitiesReportsQuery,
            variables: {
              reportId:
                result.delete_eu_taxonomy_activities_reports_by_pk.report_id,
            },
          },
          (data) => ({
            ...data,
            eu_taxonomy_activities_reports: {
              ...data.eu_taxonomy_activities_reports.filter(
                (r) => r.id !== args.id
              ),
            },
          })
        );
        // удаляет полностю полу в корне
        // cache
        //   .inspectFields("Query")
        //   .filter((q) => q.fieldName === "eu_taxonomy_activities_reports")
        //   .forEach((query) => {
        //     cache.invalidate("Query", query.fieldName, {
        //       ...query.arguments,
        //     });
        //   });
      },
      delete_eu_taxonomy_units_by_pk(result, args, cache) {
        cache.updateQuery(
          {
            query: getUnitsByReportQuery,
            variables: {
              reportId:
                result.delete_eu_taxonomy_units_by_pk.activities_report
                  .report_id,
            },
          },
          (data) => ({
            ...data,
            eu_taxonomy_units: {
              ...data.eu_taxonomy_units.filter((r) => r.id !== args.id),
            },
          })
        );
      },
      // Report decisions
      insert_eu_taxonomy_report_point_decision_one(result, args, cache) {
        const point = cache.readFragment(reportPointFragment, {
          id: args.object.report_point_id,
        });
        cache.writeFragment(
          gql`
            ${reportPointDecisionFragment}
            ${reportPointFragment}
            fragment _ on eu_taxonomy_report_points {
              ...reportPoint
              report_point_decisions(where: { report_id: { _eq: $reportId } }) {
                ...reportPointDecision
              }
            }
          `,
          {
            ...point,
            report_point_decisions: [
              result.insert_eu_taxonomy_report_point_decision_one,
            ],
          },
          {
            reportId: args.object.report_id,
          }
        );
      },
      insert_eu_taxonomy_report_decision_attachments_one(result, args, cache) {
        const decisions = cache.readFragment(reportPointDecisionFragment, {
          id: args.object.report_decision_id,
        });
        cache.writeFragment(
          gql`
            ${reportDecisionAttachmentFragment}
            fragment _ on eu_taxonomy_report_point_decision {
              decision
              id
              report_decision_attachments {
                ...reportDecisionAttachment
              }
            }
          `,
          {
            ...decisions,
            report_decision_attachments: [
              ...decisions.report_decision_attachments,
              result.insert_eu_taxonomy_report_decision_attachments_one,
            ],
          }
        );
      },
      delete_eu_taxonomy_report_decision_attachments_by_pk(
        result,
        args,
        cache
      ) {
        const decision = cache.readFragment(reportPointDecisionFragment, {
          id: args.report_decision_id,
        });
        const filteredAttachments = decision.report_decision_attachments.filter(
          (at) =>
            at.doc_id !== args.doc_id ||
            at.report_decision_id !== args.report_decision_id
        );
        cache.writeFragment(
          gql`
            ${reportDecisionAttachmentFragment}
            fragment _ on eu_taxonomy_report_point_decision {
              decision
              id
              report_decision_attachments {
                ...reportDecisionAttachment
              }
            }
          `,
          {
            ...decision,
            report_decision_attachments: [...filteredAttachments],
          }
        );
      },
      // Unit decisions
      insert_eu_taxonomy_unit_point_decision_one(result, args, cache) {
        const point = cache.readFragment(pointsFieldsFragment, {
          id: args.object.point_id,
        });
        cache.writeFragment(
          gql`
            ${pointsFieldsFragment}
            ${unitPointDecisionFragment}
            fragment _ on eu_taxonomy_points {
              ...pointsFields
              unit_point_decisions(where: { unit_id: { _eq: $unitId } }) {
                ...unitPointDecision
              }
            }
          `,
          {
            ...point,
            unit_point_decisions: [
              result.insert_eu_taxonomy_unit_point_decision_one,
            ],
          },
          {
            unitId: args.object.unit_id,
          }
        );
      },
      insert_eu_taxonomy_unit_decision_attachments_one(result, args, cache) {
        const decisions = cache.readFragment(unitPointDecisionFragment, {
          id: args.object.unit_point_decision_id,
        });
        cache.writeFragment(
          gql`
            ${unitDecisionAttachmentFragment}
            fragment _ on eu_taxonomy_unit_point_decision {
              decision
              id
              unit_decision_attachments {
                ...unitDecisionAttachment
              }
            }
          `,
          {
            ...decisions,
            unit_decision_attachments: [
              ...decisions.unit_decision_attachments,
              result.insert_eu_taxonomy_unit_decision_attachments_one,
            ],
          }
        );
      },
      delete_eu_taxonomy_unit_decision_attachments_by_pk(result, args, cache) {
        const decisions = cache.readFragment(unitPointDecisionFragment, {
          id: args.unit_point_decision_id,
        });
        const filteredAttachments = decisions.unit_decision_attachments.filter(
          (at) =>
            at.doc_id !== args.doc_id ||
            at.unit_point_decision_id !== args.unit_point_decision_id
        );
        cache.writeFragment(
          gql`
            ${unitDecisionAttachmentFragment}
            fragment _ on eu_taxonomy_unit_point_decision {
              decision
              id
              unit_decision_attachments {
                ...unitDecisionAttachment
              }
            }
          `,
          {
            ...decisions,
            unit_decision_attachments: [...filteredAttachments],
          }
        );
      },
      login(result, _args, cache) {
        cache.updateQuery(
          {
            query: userQuery,
            variables: {
              id: result.login.user.id,
            },
          },
          (data) => ({
            ...data,
            users_by_pk: result.login.user,
          })
        );

        cache.invalidate("Query", "eu_taxonomy_reports", {
          where: { status: { _eq: "review" } },
        });
      },
      // Docs
      delete_docs_by_pk(result, _args, cache) {
        cache.invalidate({
          __typename: "docs",
          id: _args.id,
        });
      },
      // Report attachments
      insert_eu_taxonomy_report_attachments_one(result, args, cache) {
        const reportFragment = gql`
          fragment _ on eu_taxonomy_reports {
            id
            report_attachments {
              doc_id
              report_id
            }
          }
        `;
        const report = cache.readFragment(reportFragment, {
          id: args.object.report_id,
        });
        if (!report) return;
        cache.writeFragment(reportFragment, {
          ...report,
          report_attachments: [
            ...report.report_attachments,
            result.insert_eu_taxonomy_report_attachments_one,
          ],
        });
      },
      delete_eu_taxonomy_report_attachments_by_pk(result, args, cache) {
        const reportFragment = gql`
          fragment _ on eu_taxonomy_reports {
            id
            report_attachments {
              doc_id
              report_id
            }
          }
        `;
        const report = cache.readFragment(reportFragment, {
          id: args.report_id,
        });
        cache.writeFragment(reportFragment, {
          ...report,
          report_attachments: report.report_attachments.filter(
            (at) => at.doc_id !== args.doc_id || at.report_id !== args.report_id
          ),
        });
      },
      // Unit attachments
      insert_eu_taxonomy_unit_attachments_one(result, args, cache) {
        const unitFragment = gql`
          fragment _ on eu_taxonomy_units {
            id
            unit_attachments {
              doc_id
              unit_id
            }
          }
        `;
        const unit = cache.readFragment(unitFragment, {
          id: args.object.unit_id,
        });
        // может не быть если сразу зашли на создание нового юнита(никто еще юниты не запрашивал)
        if (!unit) return;

        cache.writeFragment(unitFragment, {
          ...unit,
          unit_attachments: [
            ...unit.unit_attachments,
            result.insert_eu_taxonomy_unit_attachments_one,
          ],
        });
      },
      delete_eu_taxonomy_unit_attachments_by_pk(result, args, cache) {
        const unitFragment = gql`
          fragment _ on eu_taxonomy_units {
            id
            unit_attachments {
              doc_id
              unit_id
            }
          }
        `;
        const unit = cache.readFragment(unitFragment, {
          id: args.unit_id,
        });
        cache.writeFragment(unitFragment, {
          ...unit,
          unit_attachments: unit.unit_attachments.filter(
            (at) => at.doc_id !== args.doc_id || at.unit_id !== args.unit_id
          ),
        });
      },
      update_frameworks_by_pk(result, _args, cache) {
        cache.updateQuery(
          {
            query: frameworkQuery,
            variables: {
              id: result.update_frameworks_by_pk.id,
            },
          },
          (data) => ({
            ...result.update_frameworks_by_pk,
            ...data,
          })
        );
      },
      insert_frameworks_one(result, args, cache) {
        cache.updateQuery(
          {
            query: frameworksQuery,
            variables: {
              companyId: args.object.company_id,
            },
          },
          (data) => {
            data?.frameworks.push(result.insert_frameworks_one);
            return data;
          }
        );
      },
      delete_frameworks_by_pk(result, _args, cache) {
        cache.invalidate({
          __typename: "frameworks",
          id: result.delete_frameworks_by_pk.id,
        });
      },
      insert_example_categories_one(result, args, cache) {
        const framework = cache.readFragment(frameworkFragment, {
          id: args.object.framework_id,
        });
        cache.writeFragment(frameworkFragment, {
          ...framework,
          example_categories: [
            ...framework.example_categories,
            result.insert_example_categories_one,
          ],
        });
      },
      delete_example_categories_by_pk(result, args, cache) {
        cache.invalidate({
          id: args.id,
          __typename: "example_categories",
        });
      },
      insert_framework_excluded_categories_one(result, args, cache) {
        const framework = cache.readFragment(frameworkFragment, {
          id: args.object.framework_id,
        });

        cache.writeFragment(frameworkFragment, {
          ...framework,
          excluded_categories: [
            ...framework.excluded_categories,
            result.insert_framework_excluded_categories_one,
          ],
        });
      },
      delete_framework_excluded_categories_by_pk(result, args, cache) {
        cache.invalidate({
          id: args.excluded_category_id,
          __typename: "excluded_categories",
        });
      },
      insert_example_category_goals(result, _args, cache) {
        const exampleCategory = cache.readFragment(
          exampleCategoryFrameworkFragment,
          {
            id: _args.objects[0].example_category_id,
          }
        );
        cache.writeFragment(exampleCategoryFrameworkFragment, {
          ...exampleCategory,
          goals: result.insert_example_category_goals.returning,
        });
      },
      insert_eu_taxonomy_activities_reports_codes(result, args, cache) {
        const actReportFragment = gql`
          fragment _ on eu_taxonomy_activities_reports {
            id
            activities_reports_codes {
              code_id
              activity_report_id
            }
          }
        `;

        const addedActReportCodes =
          result.insert_eu_taxonomy_activities_reports_codes.returning;
        if (!addedActReportCodes.length) return;

        const actReportId = addedActReportCodes[0]?.activity_report_id;
        const actReport = cache.readFragment(actReportFragment, {
          id: actReportId,
        });
        cache.writeFragment(actReportFragment, {
          id: actReportId,
          activities_reports_codes: [
            ...actReport.activities_reports_codes,
            ...addedActReportCodes,
          ],
        });
      },
      delete_eu_taxonomy_activities_reports_codes(result, args, cache) {
        const actReportFragment = gql`
          fragment _ on eu_taxonomy_activities_reports {
            id
            activities_reports_codes {
              code_id
              activity_report_id
            }
          }
        `;
        const removedActReportCodes =
          result.delete_eu_taxonomy_activities_reports_codes.returning;
        if (!removedActReportCodes.length) return;

        const actReportId = removedActReportCodes[0].activity_report_id;
        const actReport = cache.readFragment(actReportFragment, {
          id: actReportId,
        });

        const actReportCodesEqual = (a) => (b) =>
          a.code_id === b.code_id &&
          a.activity_report_id === b.activity_report_id;

        cache.writeFragment(actReportFragment, {
          id: actReportId,
          activities_reports_codes: actReport.activities_reports_codes.filter(
            (actReportCode) =>
              removedActReportCodes.findIndex(
                actReportCodesEqual(actReportCode)
              ) === -1
          ),
        });
      },
      delete_users_by_pk(result, args, cache) {
        cache.invalidate({
          id: result.delete_users_by_pk.id,
          __typename: "users",
        });
      },
      update_eu_taxonomy_reports_by_pk(result, args, cache) {
        cache.writeFragment(reportFieldsFragment, {
          ...result.update_eu_taxonomy_reports_by_pk,
        });
      },
      insert_project_docs(result, args, cache) {
        const projectId = args.objects[0].project_id;
        const attachedFiles = result.insert_project_docs.returning;
        cache.updateQuery(
          {
            query: projectDocsQuery,
            variables: {
              projectId,
            },
          },
          (data) => {
            data.project_docs?.push(...attachedFiles);
            return data;
          }
        );
      },
      delete_project_docs_by_pk(result, args, cache) {
        cache.invalidate({
          __typename: "project_docs",
          project_id: args.project_id,
          doc_id: args.doc_id,
        });
      },
      delete_commodity_PINs_by_pk(result, _args, cache) {
        cache.invalidate({
          __typename: "commodity_PINs",
          id: result.delete_commodity_PINs_by_pk.id,
        });
      },
      insert_commodity_PINs_one(result, args, cache) {
        cache.updateQuery(
          {
            query: pinsQuery,
            variables: {
              companyId: args.object.company_id,
            },
          },
          (data) => {
            data?.commodity_PINs.push(result.insert_commodity_PINs_one);
            return data;
          }
        );
      },
      delete_messenger_threads_by_pk(result, args, cache) {
        cache.invalidate({
          __typename: "messenger_threads",
          id: result.delete_messenger_threads_by_pk.id,
        });
      },
      insert_messenger_threads_one(result, args, cache) {
        cache.updateQuery(
          {
            query: threadsQuery,
            variables: {
              config: {
                entity: args.object.config.entity,
                entityId: args.object.config.entityId,
              },
            },
          },
          (data) => {
            data?.messenger_threads.push(result.insert_messenger_threads_one);
            return data;
          }
        );
      },
      insert_messenger_messages_one(result, args, cache) {
        cache.updateQuery(
          {
            query: messagesByThreadQuery,
            variables: {
              id: result.insert_messenger_messages_one.thread_id,
            },
          },
          (data) => {
            data?.messenger_messages.push(result.insert_messenger_messages_one);
            return data;
          }
        );
      },
      delete_messenger_messages_by_pk(result, args, cache) {
        cache.invalidate({
          __typename: "messenger_messages",
          id: result.delete_messenger_messages_by_pk.id,
        });
      },
    },
  },
  keys: {
    eu_taxonomy_point_subtypes: (data) => data.name,
    bond_types: (data) => data.name,
    bond_states: (data) => data.name,
    project_states: (data) => data.name,
    commodity_types: (data) => data.name,
    project_goals: (data) =>
      `{"project":${data.project_id}, "goal":${data.goal_id}}`,
    bond_goals: (data) => `{"bond":${data.bond_id}, "goal":${data.goal_id}}`,
    bond_projects: (data) =>
      `{"bond":${data.bond_id}, "project":${data.project_id}}`,
    project_categories: (data) =>
      `{"project_id": ${data.project_id},"category_id": ${data.category_id}}`,
    project_docs: (data) =>
      `{"project_id": ${data.project_id},"doc_id": ${data.doc_id}}`,
    bond_docs: (data) =>
      `{"project_id": ${data.bond_id},"doc_id": ${data.doc_id}}`,
    commodity_docs: (data) =>
      `{"commodity_id": ${data.commodity_id},"doc_id": ${data.doc_id}}`,
    project_impact_indicators: (data) =>
      `{"project_id": ${data.project_id},"impact_indicator_id": ${data.impact_indicator_id}}`,
    activities_reports_codes: (data) =>
      `{"code_id": ${data.code_id},"activity_report_id": ${data.activity_report_id}}`,
    unit_decision_attachments: (data) =>
      `{"doc_id": ${data.doc_id},"unit_point_decision_id": ${data.unit_point_decision_id}}`,
    report_decision_attachments: (data) =>
      `{"doc_id": ${data.doc_id},"report_decision_id": ${data.report_decision_id}}`,
    eu_taxonomy_report_attachments: (data) =>
      `{"doc_id": ${data.doc_id},"report_id": ${data.report_id}}`,
    eu_taxonomy_unit_attachments: (data) =>
      `{"doc_id": ${data.doc_id},"unit_id": ${data.unit_id}}`,
    eu_taxonomy_activities_reports_codes: (data) =>
      `{"activity_report_id": ${data.activity_report_id},"code_id": ${data.code_id}}`,
  },
});
export default clientCache;
