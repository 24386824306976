import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { useEffect } from "react";
import { createFrameworkMutation } from "../../api/mutations";
import { useCurrentUser } from "../user";

const useCreateFramework = () => {
  const navigate = useNavigate();
  const [currentUserState] = useCurrentUser();
  const { company_id: companyId } = currentUserState.data;
  const [{ data }, _createFramework] = useMutation(createFrameworkMutation);

  useEffect(() => {
    if (data) {
      const frameworkId = data.insert_frameworks_one.id;
      navigate(`/company/frameworks/${frameworkId}`);
    }
  }, [data, navigate]);

  const createFramework = (standard) => {
    _createFramework({
      standard,
      companyId,
    });
  };

  return { createFramework };
};

export default useCreateFramework;
