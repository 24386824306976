import React from "react";
import { Text } from "@antd-ovveride";
import classnames from "classnames";
import styles from "./FrameworkQuestion.module.less";

const FrameworkQuestion = ({
  number,
  title,
  children,
  description,
  border = null,
}) => (
  <div
    className={classnames(
      styles.container,
      border && styles[`container__border--${border}`]
    )}
  >
    <Text className={styles.container__title}>
      {number} {title}
    </Text>
    {description && (
      <Text
        type="secondary"
        size="large"
        className={styles.container__description}
      >
        {description}
      </Text>
    )}
    <div
      className={classnames(
        styles.container__content,
        description && styles["container__content--describe"]
      )}
    >
      {children}
    </div>
  </div>
);

export default FrameworkQuestion;
