import React from "react";
import { NavigationTabs } from "@evercityecosystem/evercity-ui";
import { MESSENGER_GROUPS } from "../../data/messenger";
import useThreadAdaptForTabs from "../../hooks/messenger/useThreadAdapteForTabs";

const tabs = [
  {
    label: "Unit info",
    path: "",
    key: MESSENGER_GROUPS.TAXONOMY_UNIT.INFO,
  },
  {
    label: "Substantial Contribution",
    path: "substantial_contribution",
    key: MESSENGER_GROUPS.TAXONOMY_UNIT.SUBSTANTIAL_CONTRIBUTION,
  },
  {
    label: "DNSH",
    path: "dnsh",
    key: MESSENGER_GROUPS.TAXONOMY_UNIT.DNSH,
  },
];

const UnitFlowHeader = ({ threads, isNewUnit, loading }) => {
  const threadsAdapted = useThreadAdaptForTabs(threads, loading);
  const items = NavigationTabs.useFillStatus({
    tabs: tabs.map((tab, index) => ({
      ...tab,
      disabled: index !== 0 && isNewUnit,
    })),
    data: threadsAdapted,
  });

  return (
    <>
      <NavigationTabs items={items} stochastic />
    </>
  );
};

export default UnitFlowHeader;
