import React, { useEffect, useMemo } from "react";
import { useMutation, useQuery } from "urql";
import { Button, notification, Tooltip } from "antd";
import {
  CloseSquareOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import differenceBy from "lodash.differenceby";
import styles from "./DocumentTabContent.module.less";
import LockOverlay from "../../LockOverlay/LockOverlay";
import AttachFileInput from "../../AttachFileInput/AttachFileInput";
import {
  attachDocsToProjectMutation,
  unAttachDocFromProjectMutation,
} from "../../../api/mutations";
import { Table, Column, Text } from "../../../antd-ovveride";
import { projectDocsQuery } from "../../../api/queries";
import { docToAttachedFile } from "../../../helpers/docToAttachedFile";

const DocumentTabContent = ({ locked = true, project }) => {
  const [attachResponse, attachDocs] = useMutation(attachDocsToProjectMutation);
  const [unAttachResponse, unAttach] = useMutation(
    unAttachDocFromProjectMutation
  );
  const [{ data }] = useQuery({
    query: projectDocsQuery,
    variables: { projectId: project.id },
    pause: !project.id,
  });

  const projectDocs = useMemo(
    () => data?.project_docs?.map((at) => docToAttachedFile(at.doc)) || [],
    [data]
  );

  useEffect(() => {
    if (attachResponse.error) {
      notification.error({
        message: "Error attach files.",
        description: "Please try again later.",
      });
    }
    if (unAttachResponse.error) {
      notification.error({
        message: "Error unattach files.",
        description: "Please try again later.",
      });
    }
  }, [attachResponse.error, unAttachResponse.error]);

  const onChangeAttachments = (docs) => {
    const addedFiles = differenceBy(docs, projectDocs, "docId");
    if (addedFiles.length) {
      const attachments = addedFiles.map((doc) => ({
        doc_id: doc.docId,
        project_id: project.id,
      }));
      attachDocs({ attachments });
    }
  };

  const unAttachDoc = (docId) => {
    unAttach({
      docId,
      projectId: project.id,
    });
  };

  return (
    <LockOverlay isLock={locked}>
      <AttachFileInput
        value={projectDocs}
        onChange={onChangeAttachments}
        className={styles.uploadButton}
      />
      <Table dataSource={projectDocs}>
        <Column
          title="Name"
          dataIndex="name"
          render={(name) => (
            <Text>
              <FileTextOutlined /> {name}
            </Text>
          )}
        />
        <Column title="Format" dataIndex="format" />
        <Column
          width={200}
          title="Actions"
          render={(doc) => (
            <div className={styles.actions}>
              <Tooltip title="Download">
                <Button
                  href={doc.url}
                  type="link"
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  type="text"
                  onClick={() => unAttachDoc(doc.docId)}
                  icon={<CloseSquareOutlined />}
                />
              </Tooltip>
            </div>
          )}
        />
      </Table>
    </LockOverlay>
  );
};

export default DocumentTabContent;
