import React, { useMemo } from "react";
import { Space, Divider } from "antd";
import { Link } from "react-router-dom";
import styles from "./CardbonCreditCard.module.less";
import CardLayout from "./CardLayout";
import CardHeader from "./CardHeader";
import ContentWrap from "./ContentWrap";
import FixedContentHeader from "./FixedContentHeader";
import ListingTags from "../ListingTags/ListingTags";
import ListingProgress from "../ListingProgress/ListingProgress";
import CarbonCreditFinStats from "./CarbonCreditFinStats";
import { thousandFormatter } from "../../helpers/thousandFormatter";
import transformCommodity from "../../helpers/transformCommodity";

const CardbonCreditCard = ({ data, ...layoutProps }) => {
  const commodity = useMemo(() => transformCommodity(data), [data]);
  return (
    <Link to={`/listing/commodity/${commodity.slug}`}>
      <CardLayout {...layoutProps}>
        <div className={styles.carbonCreditCard}>
          <Space direction="vertical" size={15}>
            <CardHeader
              image={commodity.project.image}
              sdgList={commodity.project.projects_goals}
              logo={commodity.company.logo}
            />
            <ContentWrap>
              <Space direction="vertical" size={12}>
                <FixedContentHeader
                  title={commodity.project.name}
                  country={commodity.project.country}
                  description={commodity.project.description}
                  type="commodity"
                />
                <ListingTags
                  assetTypes={[{ id: 0, text: commodity.type }]}
                  status={commodity?.commodity_state?.name?.replace("_", " ")}
                  categories={commodity.project.project_categories.map(
                    (pc) => ({
                      id: pc.category.id,
                      text: pc.category.title,
                    })
                  )}
                />
              </Space>
            </ContentWrap>
          </Space>
          <ContentWrap>
            <Space
              direction="vertical"
              size={11}
              style={{ display: "flex", marginTop: 30 }}
            >
              {commodity?.commodity_state?.name?.toLowerCase() === "issued" ? (
                <ListingProgress
                  total={commodity.volume}
                  part={commodity.bought}
                  message={`${thousandFormatter(
                    commodity.volume - commodity.bought
                  )} t available of ${thousandFormatter(commodity.volume)} t`}
                />
              ) : (
                // переделать
                <Divider style={{ marginTop: 0, marginBottom: 9 }} />
              )}

              <CarbonCreditFinStats carbonCredit={commodity} />
            </Space>
          </ContentWrap>
        </div>
      </CardLayout>
    </Link>
  );
};

export default CardbonCreditCard;
