import React, { useMemo } from "react";
import { useQuery } from "urql";
import { useParams } from "react-router-dom";
import {
  getActivityReportByPkQuery,
  getUnitByPkQuery,
} from "../../api/queries";

import UnitFlow from "./UnitFlow";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import { REPORT_STATUSES } from "../../helpers/reportStatuses";
import sampleCompany from "../../helpers/sampleCompany";
import Error404 from "../errors/Error404";
import { useCurrentUser } from "../../hooks/user";
import useThread from "../../hooks/messenger/useThread";

const UnitFlowConnected = () => {
  const [currentUserState] = useCurrentUser();
  const { company } = currentUserState;

  const { activityReportId, reportId, activityId, unitId, unitParentId } =
    useParams();

  const isNewUnit = unitId === "_";
  const isSubunit = unitParentId !== "_";

  const [activityReportResponse] = useQuery({
    query: getActivityReportByPkQuery,
    variables: { id: activityReportId },
  });
  const actReport =
    activityReportResponse.data?.eu_taxonomy_activities_reports_by_pk;

  const originCompany = actReport?.report?.company;

  const companyOfReport = useMemo(() => {
    if (actReport?.report.status === REPORT_STATUSES.sample) {
      return {
        id: originCompany.id,
        ...sampleCompany,
      };
    }
    return originCompany;
  }, [originCompany, actReport?.report]);

  const [unitResponse] = useQuery({
    query: getUnitByPkQuery,
    variables: { id: unitId },
    pause: isNewUnit,
  });
  const unit = !isNewUnit && unitResponse.data?.eu_taxonomy_units_by_pk;

  const [parentUnitResponse] = useQuery({
    query: getUnitByPkQuery,
    variables: { id: unitParentId },
    pause: !isSubunit,
  });
  const parentUnit = parentUnitResponse.data?.eu_taxonomy_units_by_pk;

  const [{ data: threadResponse }] = useThread();

  const openedDiscussionThread = threadResponse?.messenger_threads_by_pk;

  const fetching = unitResponse.fetching || activityReportResponse.fetching;
  const error =
    unitResponse.error ||
    activityReportResponse.error ||
    actReport?.activity?.id !== activityId * 1 ||
    actReport?.report?.id !== reportId ||
    (companyOfReport.id !== company.id &&
      !company?.clients
        ?.map((client) => client.id)
        .includes(companyOfReport.id));

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }
  if (error) {
    return <Error404 />;
  }

  return (
    <UnitFlow
      actReport={actReport}
      company={companyOfReport}
      unit={unit}
      parentUnit={parentUnit}
      openedDiscussionThread={openedDiscussionThread}
    />
  );
};

export default UnitFlowConnected;
