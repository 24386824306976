import React, { useMemo, useEffect } from "react";
import {
  Form,
  Radio,
  Input,
  Space,
  Row,
  Col,
  Button,
  Modal,
  TextArea,
  ContentLayout,
  InputNumber,
} from "@evercityecosystem/evercity-ui";
import { useNavigate, useParams } from "react-router-dom";
import FormItemsGroup from "../FormItemsGroup/FormItemsGroup";

import styles from "./UnitInfoPage.module.less";
import {
  thousandFormatter,
  thousandParser,
} from "../../helpers/thousandFormatter";
import unitTypes from "../../helpers/unitTypes";
import InfoButton from "../InfoButton/InfoButton";
import reportCurrencyParameters from "../../data/reportCurrencyParameters";
import AttachFileInput from "../AttachFileInput/AttachFileInput";
import FilesTagsListInput from "../FilesTagsListInput/FilesTagsListInput";
import useBreakpoint from "../../hooks/useBreakpoint";
import FormItemWithDiscussions from "../FormItemWithDiscussions/FormItemWithDiscussions";
import { GroupContext } from "../../helpers/withThreadsHoc";
import { MESSENGER_GROUPS } from "../../data/messenger";

const { Group: RadioGroup } = Radio;

const inputNumberStyle = { width: "100%", padding: "0 6px" };

const formatUnitFormValues = (unit) => ({
  name: unit.name,
  description: unit.description,
  turnover: unit.turnover,
  capex: unit.capex,
  opex: unit.opex,
  type: unit.type,
});

const getUnitMaxValueFactory =
  (report, sumsOfActivitiesForUnit, unit) => (paramName) => {
    const { [paramName]: totalCurrency } = report;
    const { [paramName]: usedCurrency } = sumsOfActivitiesForUnit;
    const unitCurrencyValue = unit?.[paramName] || 0;
    const remainder = totalCurrency - usedCurrency + unitCurrencyValue;
    return Math.floor(remainder);
  };

const getSubunitMaxValueFactory =
  (parentUnit, sumsOfSiblingsForSubunit, unit) => (paramName) => {
    const { [paramName]: usedCurrency } = sumsOfSiblingsForSubunit;
    const { [paramName]: totalCurrency } = parentUnit;
    const unitCurrencyValue = unit?.[paramName] || 0;

    const remainder = totalCurrency - usedCurrency + unitCurrencyValue;

    return Math.floor(remainder);
  };

const getUnitMinValueFactory = (sumsOfChildren) => (paramName) => {
  if (!sumsOfChildren) return 0;
  const { [paramName]: usedCurrency } = sumsOfChildren;
  return Math.floor(usedCurrency);
};

const getValidationRulesFactory =
  (maxValues, minValues, unitType) => (paramName) => {
    const { [paramName]: minValue } = minValues;
    const { [paramName]: maxValue } = maxValues;

    return [
      {
        required: true,
        message: `Please enter your ${unitType} annual turnover`,
      },
      {
        max: maxValue,
        type: "number",
        message: `Should not exceed ${thousandFormatter(maxValue)}`,
      },
      {
        min: minValue,
        type: "number",
        message: `Should be more than the sum of all added subunits (${thousandFormatter(
          minValue
        )})`,
      },
    ];
  };

const generateAllParameters = (genFunc) => ({
  turnover: genFunc("turnover"),
  capex: genFunc("capex"),
  opex: genFunc("opex"),
});

// TODO: рефакторинг подсчета ограничений, вынести их
const UnitInfoPage = ({
  onFinish,
  onBackClick,
  actReport,
  report,
  unit,
  parentUnit,
  sumsOfSiblingsForSubunit,
  sumsOfChildren,
  sumsOfActivitiesForUnit,
  onValuesChange,
  threads,
  attachedDocs,
  hideThreadsActions,
}) => {
  const { reportId, activityReportId, activityId, unitParentId } = useParams();
  const navigate = useNavigate();
  const { lg } = useBreakpoint();
  const isSubunit = !!parentUnit;
  const isNewUnit = !unit;
  const unitType = isSubunit ? "subunit" : "unit";

  const initialValues = useMemo(
    () =>
      !!actReport && {
        type: "division",
        currency: report.currency,
        ...(unit && formatUnitFormValues(unit)),
        docs: attachedDocs,
      },
    [actReport, unit, report, attachedDocs]
  );

  const getMaxValue = isSubunit
    ? getSubunitMaxValueFactory(parentUnit, sumsOfSiblingsForSubunit, unit)
    : getUnitMaxValueFactory(report, sumsOfActivitiesForUnit, unit);

  const MAX_CURRENCY_VALUES = generateAllParameters(getMaxValue);

  const getMinValue = getUnitMinValueFactory(sumsOfChildren);

  const MIN_CURRENCY_VALUES = generateAllParameters(getMinValue);

  const getValidationRules = getValidationRulesFactory(
    MAX_CURRENCY_VALUES,
    MIN_CURRENCY_VALUES,
    unitType,
    isSubunit
  );

  const VALIDATION_RULES = generateAllParameters(getValidationRules);

  const invalidParameters = Object.entries(MAX_CURRENCY_VALUES)
    .filter(([, value]) => value === 0)
    .map(([key]) => key);

  useEffect(() => {
    if (!!invalidParameters.length && isNewUnit) {
      Modal.destroyAll();
      const invalidParametersDisplay = invalidParameters
        .map((p) => reportCurrencyParameters[p])
        .join(", ");

      Modal.confirm({
        title: `Cannot add new ${unitType} because maximum ${invalidParametersDisplay} limit is reached`,
        content: `Please increase ${invalidParametersDisplay} of your ${
          isSubunit ? "parent unit" : "company"
        }`,
        okText: `Update ${isSubunit ? "parent unit" : "company"}`,
        cancelText: "Cancel",
        onOk: () => {
          if (isSubunit) {
            navigate(
              `/company/taxonomy/unit/${reportId}/${activityReportId}/${activityId}/_/${unitParentId}`
            );
          } else {
            navigate(`/company/taxonomy/report/${reportId}`);
          }
        },
        onCancel: () => {
          navigate(`/company/taxonomy/report/${reportId}/activities`);
        },
      });
    }
  }, [
    invalidParameters,
    isSubunit,
    isNewUnit,
    unitType,
    reportId,
    navigate,
    activityReportId,
    activityId,
    unitParentId,
  ]);

  const [Layout, layoutProps] = useMemo(() => {
    const component = lg ? ContentLayout.Aside : ContentLayout;
    const props = lg ? null : { gaps: "off" };
    return [component, props];
  }, [lg]);

  return (
    <GroupContext.Provider value={MESSENGER_GROUPS.TAXONOMY_UNIT.INFO}>
      <Layout {...layoutProps}>
        <Form
          layout="vertical"
          size="large"
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
          initialValues={initialValues}
          onFinish={onFinish}
          key={unit.id || "new"}
          onValuesChange={onValuesChange}
        >
          <Space direction="vertical" size={30} style={{ display: "flex" }}>
            <FormItemsGroup
              title="Basics"
              subtitle={`Please fill in the basic information about the ${unitType}.`}
              entryVideoStartTime={isSubunit ? 301 : 198}
              note={
                <span>
                  Please create and screen a business {unitType} (division,
                  asset, investment plan) that falls into the scope of the
                  selected activity.
                  <br />
                  <i>
                    Examples:
                    <br /> - division: manufacturing department, fleet of
                    vessels;
                    <br /> - asset: power plant, low-carbon ship;
                    <br /> - investment plan: modernization plan for Factory A.
                  </i>
                </span>
              }
            >
              <FormItemWithDiscussions
                threads={threads}
                hideDisscussion={hideThreadsActions}
                name="name"
                tooltip={{
                  title: `Please give your ${unitType} a short name (not more than 3-4 words) which will be used for your own analytics and auditor’s checks. `,
                  icon: <InfoButton />,
                }}
                label={isSubunit ? "Subunit name" : "Unit name"}
                rules={[
                  {
                    required: true,
                    message: `Please enter the ${unitType} name`,
                  },
                ]}
              >
                <Input data-cy="unit-info-name" />
              </FormItemWithDiscussions>
              <FormItemWithDiscussions
                threads={threads}
                hideDisscussion={hideThreadsActions}
                name="type"
                tooltip={{
                  title: `Please identify which of the following types your ${unitType} belongs to.`,
                  icon: <InfoButton />,
                }}
                label="Type"
                getValueFromEvent={(value) => value.id}
                getValueProps={(value) => ({ value: { id: value } })}
                rules={[
                  {
                    required: true,
                    message: `Please check your ${unitType} type`,
                  },
                ]}
              >
                <RadioGroup data-cy="unit-info-type" direction="horizontal">
                  {Object.entries(unitTypes).map(([type, typeDisplay]) => (
                    <Radio value={type} key={type}>
                      {typeDisplay}
                    </Radio>
                  ))}
                </RadioGroup>
              </FormItemWithDiscussions>
            </FormItemsGroup>
            <FormItemWithDiscussions
              threads={threads}
              hideDisscussion={hideThreadsActions}
              name="description"
              tooltip={{
                title: `Please briefly describe your ${unitType}. This information is provided for your convenience and for auditor’s checks and will not be reflected in the final report.`,
                icon: <InfoButton />,
              }}
              label="Description"
              rules={[
                {
                  required: true,
                  message: `Please enter your ${unitType} description`,
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                data-cy="unit-info-description"
              />
            </FormItemWithDiscussions>
            <FormItemsGroup
              title="Financials"
              subtitle={`Please fill in financial information about the ${unitType}. Make sure your calculation of Turnover, CapEx and OpEx is in line with the Taxonomy requirements.`}
            >
              <Row gutter={30} align="bottom">
                <Col span={8}>
                  <FormItemWithDiscussions
                    threads={threads}
                    hideDisscussion={hideThreadsActions}
                    name="currency"
                    label="Currency"
                  >
                    <Input disabled />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
              <Row gutter={30} align="bottom">
                <Col span={8}>
                  <FormItemWithDiscussions
                    threads={threads}
                    hideDisscussion={hideThreadsActions}
                    name="turnover"
                    tooltip={{
                      title:
                        "Turnover covers amounts derived from the sale of products and the provision of services after deducting sales rebates, value added tax and other taxes directly linked to turnover.",
                      icon: <InfoButton />,
                    }}
                    label="Annual Turnover"
                    rules={VALIDATION_RULES.turnover}
                  >
                    <InputNumber
                      min={0}
                      data-cy="unit-info-turnover"
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="capex"
                    tooltip={{
                      title:
                        "CapEx covers additions to tangible and intangible assets during the financial year considered before depreciation, amortisation and any re-measurements, including those resulting from revaluations and impairments.",
                      icon: <InfoButton />,
                    }}
                    label="Capital Expenditures"
                    rules={VALIDATION_RULES.capex}
                  >
                    <InputNumber
                      min={0}
                      data-cy="unit-info-capex"
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8}>
                  <FormItemWithDiscussions
                    threads={threads}
                    hideDisscussion={hideThreadsActions}
                    name="opex"
                    tooltip={{
                      title:
                        "OpEx covers direct non-capitalised costs relating to research and development, renovation measures, short-term lease, maintenance and other direct expenditures relating to the day-to-day servicing of assets of property, plant and equipment that are necessary to ensure the continued and effective use of such assets.",
                      icon: <InfoButton />,
                    }}
                    label="Operating Expenditures"
                    rules={VALIDATION_RULES.opex}
                  >
                    <InputNumber
                      min={0}
                      data-cy="unit-info-opex"
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24}>
                  <FormItemWithDiscussions
                    threads={threads}
                    hideDisscussion={hideThreadsActions}
                    label="Upload supporting file"
                    name="docs"
                  >
                    <AttachFileInput />
                  </FormItemWithDiscussions>
                  <FormItemWithDiscussions
                    threads={threads}
                    hideDisscussion={hideThreadsActions}
                    name="docs"
                    noStyle
                  >
                    <FilesTagsListInput />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
            </FormItemsGroup>
          </Space>
          <Space className={styles.submitWrapper} size={12}>
            <Button onClick={onBackClick} size="large">
              Cancel
            </Button>
            <Button
              data-cy="btn-next-step"
              type="primary"
              htmlType="submit"
              size="large"
              className={styles.submitBtn}
            >
              Next Step
            </Button>
          </Space>
        </Form>
      </Layout>
    </GroupContext.Provider>
  );
};

export default UnitInfoPage;
