import React, { useMemo, useRef } from "react";
import { Space } from "@antd-ovveride";
import useUrlState from "@ahooksjs/use-url-state";
import {
  InfoCircleOutlined,
  FileTextOutlined,
  CloudOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { useResponsive } from "ahooks";
import uppercaseFirstLetter from "../../helpers/uppercaseFirstLetter";

import BackTo from "../BackTo/BackTo";
import SDGList from "../SDGList/SDGList";
import ListingProgress from "../ListingProgress/ListingProgress";
import FileCard from "../FileCard/FileCard";
import { thousandFormatter } from "../../helpers/thousandFormatter";
import PublicLayoutContainer from "../PublicLayoutContainer/PublicLayoutContainer";
import ProductTwoColLayout from "../Product/ProductTwoColLayout";
import ProductHeader from "../Product/ProductHeader";
import ProductMainInfoList from "../Product/ProductMainInfoList";
import ProductPrimaryBtn from "../Product/ProductPrimaryBtn";
import ProductDivider from "../Product/ProductDivider";

import OverviewTabContent from "./TabsContent/OverviewTabContent";
import DocumentsTabContent from "./TabsContent/DocumentsTabContent";
import ImpactTabContent from "./TabsContent/ImpactTabContent";
import ReportingTabContent from "./TabsContent/ReportingTabContent";
import ProductTabSwitcher from "../Product/ProductTabSwitcher";
import LkLayout from "../LkLayout/LkLayout";

const makeContentTabs = ({ commodity, mapRef, currentUser }) => [
  {
    title: "Overview",
    icon: <InfoCircleOutlined />,
    component: (
      <OverviewTabContent
        currentUser={currentUser}
        commodity={commodity}
        ref={mapRef}
      />
    ),
  },
  {
    title: "Documents",
    icon: <FileTextOutlined />,
    component: <DocumentsTabContent commodity={commodity} />,
  },
  {
    title: "Risks & Impact",
    icon: <CloudOutlined />,
    component: (
      <ImpactTabContent currentUser={currentUser} commodity={commodity} />
    ),
  },
  {
    title: "Reporting",
    icon: <BarsOutlined />,
    component: <ReportingTabContent commodity={commodity} />,
  },
];

const getMainInfoList = (commodity) => [
  {
    key: "Asset Type",
    value: uppercaseFirstLetter(commodity.type),
  },
  {
    key: "SDGs",
    value: <SDGList items={commodity.project.projects_goals} />,
  },
  {
    key: "Standard",
    value: uppercaseFirstLetter(commodity.commodity_standard?.title),
  },
  {
    key: "Credit Start",
    value: commodity.creditStart,
  },
  {
    key: "Credit End",
    value: commodity.creditEnd,
  },
  {
    key: "Price",
    value:
      commodity.price === 0
        ? "N/A"
        : `${commodity.price} ${commodity.currency}/t`,
  },
];

const CommodityPage = ({ commodity, currentUser }) => {
  const { md } = useResponsive();
  const mapRef = useRef();
  const tabs = useMemo(
    () => makeContentTabs({ commodity, mapRef, currentUser }),
    [commodity, currentUser]
  );
  const [activeTabKey, setActiveTabKey] = useUrlState(
    { tab: tabs[0].title },
    { navigateMode: "replace" }
  );

  const scrollToMap = () => {
    setActiveTabKey({ tab: tabs[0].title });
    setTimeout(() => {
      mapRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        scrollIntoViewOptions: {},
      });
    }, 0);
  };

  const sendMail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const link = document.createElement("a");
    link.href = "mailto:elizavetaromanova@gmail.com";
    link.click();
  };

  return (
    <LkLayout>
      <PublicLayoutContainer margins>
        <Space block direction="vertical" size={md ? 50 : 30}>
          <BackTo to="/listing/?section=commodity">Back to all</BackTo>
          <ProductTwoColLayout>
            <>
              <ProductHeader
                image={commodity.project.image}
                companyName={commodity.project.name}
                companyLogo={commodity.company.logo}
                country={commodity.project.country}
                description={commodity.project.description}
                assetTypes={[{ id: 1, text: commodity.type }]}
                state={commodity.state?.replace("_", " ")}
                categories={commodity.project.project_categories.map((pc) => ({
                  id: pc.category.id,
                  text: pc.category.title,
                }))}
                onLocationClick={scrollToMap}
              />
              <Space block size={10} wrap>
                {commodity.commodity_docs?.map((cd) => (
                  <FileCard
                    url={cd.doc.url}
                    fileName={cd.doc.name}
                    title={cd.attachment_type}
                    description={cd.doc.description}
                    status="draft"
                  />
                ))}
              </Space>
            </>
            <>
              <ProductMainInfoList
                items={getMainInfoList(commodity)}
                redirect="request-access"
              />
              <Space block size={20} direction="vertical">
                {commodity?.commodity_state?.name?.toLowerCase() ===
                  "issued" && (
                  <ListingProgress
                    total={commodity.volume}
                    part={commodity.bought}
                    message={`${thousandFormatter(
                      commodity.volume - commodity.bought
                    )} t available of ${thousandFormatter(commodity.volume)} t`}
                  />
                )}
                <ProductPrimaryBtn onClick={sendMail} data-cy="btn-buy">
                  Buy
                </ProductPrimaryBtn>
              </Space>
            </>
          </ProductTwoColLayout>
        </Space>
        <ProductDivider />
        <ProductTabSwitcher
          tabs={tabs}
          activeTabKey={activeTabKey.tab}
          onTabChange={(tab) => setActiveTabKey({ tab })}
        />
      </PublicLayoutContainer>
    </LkLayout>
  );
};

export default CommodityPage;
