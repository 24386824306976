import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../FrameworksPage/Frameworks.module.less";
import IntroductionSummary from "../Introduction/IntroductionSummary";
import ExternalReviewSummary from "../ExternalReview/ExternalReviewSummary";
import UseOfProceedSummary from "../UseOfProceed/UseOfProceedSummary";
import ProcessForProjectSummary from "../ProcessForProject/ProcessForProjectSummary";
import ManagementOfProceedsSummary from "../ManagementOfProceeds/ManagementOfProceedsSummary";
import ReportingFrameworkSummary from "../ReportingFramework/ReportingFrameworkSummary";
import useModal from "../../hooks/useModal";
import NavLink from "../../antd-ovveride/Link/NavLink";

const steps = [
  IntroductionSummary,
  ExternalReviewSummary,
  UseOfProceedSummary,
  ProcessForProjectSummary,
  ManagementOfProceedsSummary,
  ReportingFrameworkSummary,
];
const FrameworkSummary = ({ framework }) => {
  const { Modal } = useModal({ defaultVisible: true });
  const navigate = useNavigate();

  return (
    <Modal
      width={1028}
      onCancel={() => navigate("/company/frameworks")}
      title="Summary preview"
      okButton={
        <NavLink
          to={`/company/frameworks/${framework.id}`}
          size="large"
          type="button"
        >
          Edit
        </NavLink>
      }
    >
      {steps.map((step) =>
        step({ framework, className: styles.summary__section })
      )}
    </Modal>
  );
};

export default FrameworkSummary;
