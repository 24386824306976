import React from "react";
import { Modal, Button, Typography } from "antd";
import cs from "classnames";
import { Text } from "@antd-ovveride";
import styles from "../NotAlignedModal/NotAlignedModal.module.less";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success-circle-check.svg";

const { Title } = Typography;

// TODO: Объединить с NotAlignedModal
const AlignedModal = ({
  wrapClassName,
  onOk,
  children,
  title,
  onRetry,
  withRetry,
  ...rest
}) => (
  <Modal
    {...rest}
    title={
      <div className={styles.headerContent}>
        <SuccessIcon />
        <Title level={2} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </div>
    }
    wrapClassName={cs(styles.container, wrapClassName)}
    closable={false}
    footer={[
      withRetry && (
        <Button data-cy="btn-retry" key="retry" onClick={onRetry} size="large">
          Retry
        </Button>
      ),
      <Button
        data-cy="btn-next-step"
        onClick={onOk}
        key="next-step"
        type="primary"
        size="large"
      >
        Next step
      </Button>,
    ]}
  >
    <Text className={styles.message} size="large">
      {children}
    </Text>
  </Modal>
);

export default AlignedModal;
