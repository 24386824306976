import React, { useEffect } from "react";
import { useQuery } from "urql";
import { Skeleton, Typography, List, Button } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { Text } from "@antd-ovveride";
import { Link } from "react-router-dom";
import { LinearInterpolator } from "react-map-gl";
import { useResponsive } from "ahooks";
import { mapProjectPopupQuery } from "../../api/queries";
import concatProjectTypes from "../../helpers/concatProjectTypes";
import SDGList from "../SDGList/SDGList";
import ListingTags from "../ListingTags/ListingTags";

import styles from "./ProjectPopup.module.less";

const { Title } = Typography;

const getItems = (project) => {
  const indicators = project.project_impact_indicators?.map((indicator) => ({
    key: `${indicator.impact_indicator.name}${
      indicator.impact_indicator.measure
        ? `, ${indicator.impact_indicator.measure}`
        : ""
    }`,
    value: indicator.temp_text_value,
  }));
  return [
    { key: "Location", value: project.company.location },
    { key: "SDG", value: <SDGList items={project.projects_goals} /> },
    ...indicators,
  ];
};

const ProjectPopup = ({ feature, onClose, setViewport }) => {
  const { md } = useResponsive();
  const [projectResult] = useQuery({
    query: mapProjectPopupQuery,
    variables: { id: feature.properties.id },
  });
  const project = projectResult.data?.projects_by_pk;

  useEffect(() => {
    if (project && md) {
      setViewport({
        longitude: project.location.longitude,
        latitude: project.location.latitude,
        zoom: project.location.zoom,
        transitionDuration: 1000,
        transitionInterpolator: new LinearInterpolator(),
      });
    }
  }, [setViewport, project, md]);

  const previewImg =
    typeof project?.image === "string"
      ? project?.image
      : project?.image?.source_url;

  return (
    <div className={styles.container}>
      <CloseCircleFilled className={styles.close} onClick={onClose} />

      <img className={styles.preview} alt={project?.name} src={previewImg} />
      <div className={styles.content}>
        {project ? (
          <>
            <Title
              level={3}
              style={{ marginBottom: 0 }}
              className={styles.title}
            >
              {project.name}
            </Title>

            <Text className={styles.description}>{project.description}</Text>

            <List
              itemLayout="horizontal"
              dataSource={getItems(project)}
              renderItem={(item) => (
                <>
                  {item && (
                    <List.Item className={styles.listItem}>
                      <Text type="secondary" size="small">
                        {item.key}
                      </Text>
                      <div>
                        {typeof item.value === "string" ? (
                          <Text size="small" className={styles.itemValue}>
                            {item.value}
                          </Text>
                        ) : (
                          item.value
                        )}
                      </div>
                    </List.Item>
                  )}
                </>
              )}
            />
            <div className={styles.tags}>
              <ListingTags
                status={project?.state?.replace("_", " ")}
                assetTypes={concatProjectTypes(project)}
                categories={project.project_categories.map((pc) => ({
                  id: pc.category.id,
                  text: pc.category.title,
                }))}
              />
            </div>
            <Link to={`/listing/project/${project.slug}`} target="_blank">
              <Button
                size="large"
                type="primary"
                block
                className={styles.button}
              >
                Project profile
              </Button>
            </Link>
          </>
        ) : (
          <Skeleton active />
        )}
      </div>
    </div>
  );
};

export default ProjectPopup;
