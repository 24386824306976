import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "urql";
import { notification } from "antd";
import differenceBy from "lodash.differenceby";

import { Space } from "@evercityecosystem/evercity-ui";
import PointsForm from "../PointsForm/PointsForm";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";
import MSSTypeForm from "./MSSTypeForm";

import { getReportPoints } from "../../api/queries";
import {
  attachDocToReportPointDecisionMutation,
  unattachDocFromReportPointDecisionMutation,
  upsertReportPointDecisionMutation,
} from "../../api/mutations";

const mapReportPointsToPoints = (reportPoitns) =>
  reportPoitns?.map((reportPoint) => ({
    id: reportPoint.id,
    rule: reportPoint.rule,
    text: reportPoint.text,
    decision: reportPoint.report_point_decisions[0]?.decision,
    decision_id: reportPoint.report_point_decisions[0]?.id,
    parent_id: reportPoint.parent_id,
    point_subtype: null,
    point_group: reportPoint.point_mss_criteria?.title || null,
    docs: reportPoint.report_point_decisions[0]?.report_decision_attachments.map(
      (a) => a.doc
    ),
  }));

const ReportPointsFormConnected = ({
  reportId,
  threads,
  onSubmit,
  sending,
  submitText,
  cancelText,
  onCancel,
  pointsType,
}) => {
  const [MSSType, setMSSType] = useState("eu");

  const [pointsResponse] = useQuery({
    query: getReportPoints,
    requestPolicy: "network-only",
    variables: { type: pointsType, reportId, MSSType },
  });

  const reportPoints = useMemo(
    () => pointsResponse.data?.eu_taxonomy_report_points,
    [pointsResponse.data]
  );

  const points = useMemo(
    () => mapReportPointsToPoints(reportPoints) || [],
    [reportPoints]
  );

  const [upsertResponse, upsertReportPointDecision] = useMutation(
    upsertReportPointDecisionMutation
  );

  const [
    attachDocToReportPointDecisionResponse,
    attachDocToReportPointDecision,
  ] = useMutation(attachDocToReportPointDecisionMutation);
  const [
    unattachDocFromReportPointDecisionResponse,
    unattachDocFromReportPointDecision,
  ] = useMutation(unattachDocFromReportPointDecisionMutation);

  const onPointChangeHandler = async (point, value) => {
    // опирается на то что кеш обновит points
    await upsertReportPointDecision({
      report_id: reportId,
      decision: value,
      report_point_id: point.id,
    });
  };

  const handleMSSType = useCallback(
    (type) => {
      if (type) {
        setMSSType(type);
      }
    },
    [setMSSType]
  );

  const unattachFiles = async (point, files) => {
    const promises = files.map((file) =>
      unattachDocFromReportPointDecision({
        doc_id: file.docId,
        report_decision_id: point.decision_id,
      })
    );
    try {
      const responses = await Promise.all(promises);
      const error = responses.some((response) => !!response.error);
      if (error) {
        throw new Error("Unattach error");
      }
    } catch (err) {
      notification.error({
        message: "Files unattach error",
        description: "Please try again later.",
      });
    }
  };

  const attachFiles = async (point, files) => {
    const promises = files.map((file) =>
      attachDocToReportPointDecision({
        doc_id: file.docId,
        report_decision_id: point.decision_id,
      })
    );
    try {
      const responses = await Promise.all(promises);
      const error = responses.some((response) => !!response.error);
      if (error) {
        throw new Error("Attach error");
      }
    } catch (err) {
      notification.error({
        message: "Files attach error",
        description: "Please try again later.",
      });
    }
  };

  const onPointFilesChange = (point, fileList) => {
    if (!point.decision_id) {
      notification.error({
        message: "Files attach error",
        description: "Please try again later.",
      });
      return;
    }
    const exsistsFiles = point.docs.map(docToAttachedFile);

    const removedFiles = differenceBy(exsistsFiles, fileList, "docId");
    const addedFiles = differenceBy(fileList, exsistsFiles, "docId");

    if (addedFiles.length) {
      attachFiles(point, addedFiles);
    }
    if (removedFiles.length) {
      unattachFiles(point, removedFiles);
    }
  };

  const sendingMutation =
    upsertResponse.fetching ||
    attachDocToReportPointDecisionResponse.fetching ||
    unattachDocFromReportPointDecisionResponse.fetching;

  const { fetching } = pointsResponse;

  if (fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  return (
    <Space block direction="vertical" size={16}>
      <MSSTypeForm
        threads={threads}
        reportId={reportId}
        onChange={handleMSSType}
      />
      <PointsForm
        grouped
        threads={threads}
        points={points}
        sending={sendingMutation || sending}
        onPointChange={onPointChangeHandler}
        onPointFilesChange={onPointFilesChange}
        onSubmit={() => onSubmit(points)}
        submitText={submitText}
        cancelText={cancelText}
        onCancel={onCancel}
      />
    </Space>
  );
};

export default ReportPointsFormConnected;
