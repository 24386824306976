import React, { useMemo, useRef } from "react";
import { Typography } from "antd";
import { Space } from "@antd-ovveride";
import useUrlState from "@ahooksjs/use-url-state";
import {
  FileTextOutlined,
  InfoCircleOutlined,
  CloudOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { useResponsive } from "ahooks";

import PublicLayoutContainer from "../PublicLayoutContainer/PublicLayoutContainer";
import BackTo from "../BackTo/BackTo";
import ProductHeader from "../Product/ProductHeader";
import concatProjectTypes from "../../helpers/concatProjectTypes";
import ProductTwoColLayout from "../Product/ProductTwoColLayout";
import ProductMainInfoList from "../Product/ProductMainInfoList";
import ProductPrimaryBtn from "../Product/ProductPrimaryBtn";
import ProductDivider from "../Product/ProductDivider";
import ProductTabSwitcher from "../Product/ProductTabSwitcher";
import OverviewTabContent from "./TabsContent/OverviewTabContent";
import DocumentTabContent from "./TabsContent/DocumentTabContent";
import ImpactTabContent from "./TabsContent/ImpactTabContent";
import ReportingTabContent from "./TabsContent/ReportingTabContent";
import SDGList from "../SDGList/SDGList";
import uppercaseFirstLetter from "../../helpers/uppercaseFirstLetter";
import LkLayout from "../LkLayout/LkLayout";

const { Title } = Typography;

const getMainInfoList = (project) => {
  const indicators = project.project_impact_indicators?.map((indicator) => ({
    key: `${indicator.impact_indicator.name}${
      indicator.impact_indicator.measure
        ? `, ${indicator.impact_indicator.measure}`
        : ""
    }`,
    value: uppercaseFirstLetter(indicator.temp_text_value),
  }));

  return [
    {
      key: "Region",
      value: project?.project_region?.description,
    },
    {
      key: "SDGs",
      value: <SDGList items={project.projects_goals} />,
    },
    ...indicators,
  ];
};

const getContentTabs = ({ project, mapRef, currentUser }) => [
  {
    title: "Overview",
    icon: <InfoCircleOutlined />,
    component: <OverviewTabContent project={project} ref={mapRef} />,
  },
  {
    title: "Documents",
    icon: <FileTextOutlined />,
    component: (
      <DocumentTabContent
        project={project}
        locked={currentUser?.company?.id !== project.company.id}
      />
    ),
  },
  {
    title: "Risks & Impact",
    icon: <CloudOutlined />,
    component: <ImpactTabContent project={project} />,
  },
  {
    title: "Reporting",
    icon: <BarsOutlined />,
    component: <ReportingTabContent project={project} />,
  },
];

const ProjectPage = ({ project, currentUser }) => {
  const assetTypes = useMemo(() => concatProjectTypes(project), [project]);
  const { md } = useResponsive();
  const mapRef = useRef();
  const tabs = useMemo(
    () => getContentTabs({ project, mapRef, currentUser }),
    [project, currentUser]
  );
  const [activeTabKey, setActiveTabKey] = useUrlState(
    { tab: tabs[0].title },
    { navigateMode: "replace" }
  );

  const scrollToMap = () => {
    setActiveTabKey({ tab: tabs[0].title });
    setTimeout(() => {
      mapRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        scrollIntoViewOptions: {},
      });
    }, 0);
  };

  const sendMail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const link = document.createElement("a");
    link.href = "mailto:elizavetaromanova@gmail.com";
    link.click();
  };

  return (
    <LkLayout>
      <PublicLayoutContainer margins>
        <Space block direction="vertical" size={md ? 50 : 30}>
          <BackTo to="/listing/?section=project">Back to all</BackTo>
          <ProductTwoColLayout>
            {/* TODO: подумать как переделать */}
            <ProductHeader
              image={project.image}
              companyName={project.name}
              companyLogo={project.company.logo}
              country={project.company.country}
              description={project.description}
              assetTypes={assetTypes}
              state={project.state?.replace("_", " ")}
              categories={project.project_categories.map((pc) => ({
                id: pc.category.id,
                text: pc.category.title,
              }))}
              onLocationClick={scrollToMap}
            />
            <>
              <Space block direction="vertical">
                <Title level={2} style={{ marginBottom: 0 }}>
                  Impact highlights
                </Title>
                <Space block size={md ? 80 : 40} direction="vertical">
                  <ProductMainInfoList items={getMainInfoList(project)} />
                  <ProductPrimaryBtn onClick={sendMail}>
                    Contact
                  </ProductPrimaryBtn>
                </Space>
              </Space>
            </>
          </ProductTwoColLayout>
        </Space>
        <ProductDivider />
        <ProductTabSwitcher
          tabs={tabs}
          activeTabKey={activeTabKey.tab}
          onTabChange={(tab) => setActiveTabKey({ tab })}
        />
      </PublicLayoutContainer>
    </LkLayout>
  );
};
export default ProjectPage;
