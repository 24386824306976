import React from "react";
import {
  Table,
  Link,
  PlusCircleOutlined,
  Tag,
  ShareAltOutlined,
  CopyOutlined,
  DeleteOutlined,
  Space,
  BellOutlined,
  CommentOutlined,
  UserOutlined,
  Badge,
} from "@evercityecosystem/evercity-ui";
import { useNavigate } from "react-router-dom";
import styles from "./ProjectsTable.module.less";

const ProjectsTable = ({ projects }) => {
  const navigate = useNavigate();
  return (
    <Table
      rowClassName={() => styles.row}
      onRow={({ id }) => ({
        onClick: () => {
          if (window.getSelection().toString().length === 0) {
            navigate(`projects/${id}/info`);
          }
        },
      })}
      dataSource={projects}
      emptyText={
        <Link type="btn" to="projects/_/info" icon={<PlusCircleOutlined />}>
          New project
        </Link>
      }
    >
      <Table.Column title="Project name" dataIndex="name" />
      <Table.Column title="Category" />
      <Table.Column title="Location" dataIndex="region" />
      <Table.Column title="Financing" />
      <Table.Column title="Manager" />
      <Table.Column
        title="Project status"
        dataIndex="state"
        render={(state) => <Tag>{state}</Tag>}
      />
      <Table.Column
        title="Action"
        render={() => (
          <Space>
            <Table.Actions
              actions={[
                {
                  label: "Share",
                  icon: <ShareAltOutlined />,
                  key: "share",
                },
                {
                  label: "Duplicate",
                  key: "duplicate",
                  icon: <CopyOutlined />,
                },
                {
                  label: "Delete",
                  danger: true,
                  key: "delete",
                  icon: <DeleteOutlined />,
                },
              ]}
            />
            <Table.Actions
              icon={
                <Badge status="error" dot>
                  <BellOutlined />
                </Badge>
              }
              actions={[
                {
                  label: "Requests",
                  icon: (
                    <Badge status="error" dot>
                      <UserOutlined />
                    </Badge>
                  ),
                  key: "requests",
                },
                {
                  label: "Comments",
                  key: "comments",
                  icon: (
                    <Badge status="error" dot>
                      <CommentOutlined />
                    </Badge>
                  ),
                },
                {
                  label: "All",
                  key: "all",
                  icon: <BellOutlined />,
                },
              ]}
            />
          </Space>
        )}
      />
    </Table>
  );
};

export default ProjectsTable;
