import React from "react";
import { Table } from "antd";
import cs from "classnames";

import { ReactComponent as HorizontalBraceIcon } from "../../../assets/icons/horizontal-brace.svg";
import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";

import reportFinParams from "../../../data/reportCurrencyParameters";
import { thousandFormatter } from "../../../helpers/thousandFormatter";

import styles from "./ReportResultByParameter.module.less";
import { decimalToPercents } from "../../../helpers/percents";

/*
  Разбиваем таблицу в pdf:
  Вычисляем высоту каждой строки
  Вычисляем высоту шапки
  Вычисляем высоту страницы pdf в пикселях(переводим см из размера пдф в пиксели пропорционально ширине таблицы, зная что таблица будет занимать 100% ширины)
  Для каждой страницы(цикл пока остались не заполенные строки):
    создаем страницу(набор строк)
    Вычисляем дозволенную высоту строк:
        Если первая страница:
          pdf высота в px
          - отступ от таблицы до топа контейнера(тайтлы/дескрипшены сверху)
          - шапка
          - фикс отступ снизу(отступ у pdf)
        Для остальных страниц:
          pdf высота в px
          - фикс отступ сверху(оступ у pdf)
          - высота шапки
          - фикс отступ снизу(отступ у pdf)

    перебираем строки до тех пор пока высота страницы не превышает дозволенного pdf
    сохраняем оставшиеся строки
    Следующий шаг цикла
  Итого имеем несколько страниц для одного фин показателя(наборов стрк)
  Рендерим страницы
    для первой - рендерим с тайтлом + шапка + набор строк
    для остальных - рендерим с шапокй + набор строк

  Вызываем колбек onload
  Совмещаем все страницы всех фин показателей
  Создаем общий image
  Вставляем в pdf каждую страницу, на каждом шаге поднимая image на высоту pdf вверх
*/

const getActReportCodes = (actReport) => ({
  NACE: actReport.activities_reports_codes.map((code) => code.nace_code.code),
  SBI: actReport.activities_reports_codes.map(
    (code) => code.nace_code.sbi_code
  ),
});

const getAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportAlignedValue = actReport[alignedKey];
  const actReportAlignedProportionByTotal = actReportAlignedValue / reportValue;

  const { activity } = actReport;
  const codes = getActReportCodes(actReport);

  return {
    name: activity.name,
    type: "activity",
    codes: codes.NACE,
    finValue: actReportAlignedValue,
    finValueProportion: actReportAlignedProportionByTotal,
    sc_criteria_1: actReportAlignedProportionByTotal,
    sc_criteria_2: "",
    sc_criteria_3: "",
    sc_criteria_4: "",
    sc_criteria_5: "",
    sc_criteria_6: "",
    dnsh_criteria_1: "",
    dnsh_criteria_2: Math.round(Math.random()), // fake
    dnsh_criteria_3: Math.round(Math.random()), // fake
    dnsh_criteria_4: Math.round(Math.random()), // fake
    dnsh_criteria_5: Math.round(Math.random()), // fake
    dnsh_criteria_6: Math.round(Math.random()), // fake
    mss: true,
    aligned_fin_value: actReportAlignedProportionByTotal,
    aligned_fin_value_prev_year: "",
    category_enabling: activity.type === "enabling" ? "E" : "",
    category_transitional: activity.type === "transitional" ? "T" : "",
  };
};

const getNotAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportValue = actReport[finParamName];
  const actReportAlignedValue = actReport[alignedKey];

  const actReportNotAlignedValue = actReportValue - actReportAlignedValue;
  const actReportNotAlignedProportionByTotal =
    actReportNotAlignedValue / reportValue;

  const { activity } = actReport;
  const codes = getActReportCodes(actReport);

  return {
    name: activity.name,
    type: "activity",
    codes: codes.NACE,
    finValue: actReportNotAlignedValue,
    finValueProportion: actReportNotAlignedProportionByTotal,
    category_enabling: activity.type === "enabling" ? "E" : "",
    category_transitional: activity.type === "transitional" ? "T" : "",
  };
};

const getTableData = (report, actReports, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const eligibleKey = `${finParamName}_eligibility`;
  const reportTotalValue = report[finParamName];

  const reportEligibleValue = report[eligibleKey];
  const reportEligibleProportion = reportEligibleValue / reportTotalValue;

  const reportNotEligibleValue = reportTotalValue - reportEligibleValue;
  const reportNotEligibleProportion = 1 - reportEligibleProportion;

  const reportAlignedValue = report[alignedKey];
  const reportAlignedPercent = reportAlignedValue / reportTotalValue;

  const reportNotAlignedValue = reportEligibleValue - reportAlignedValue;
  const reportNotAlignedProportion = reportNotAlignedValue / reportTotalValue;

  const eligibleActReports = actReports.filter((ar) => ar[finParamName] > 0);

  const alignedActReports = eligibleActReports.filter(
    (ar) => ar[alignedKey] > 0
  );
  const alignedActReportsTableRows = alignedActReports.map((actReport) =>
    getAlignedActReportTableData(report, actReport, finParamName)
  );

  // по turnover: если у активности turnover_alignment не равен ее turnover
  // значит у нее есть not-aligned часть и ее нужно вывести
  const notAlignedActReports = eligibleActReports.filter(
    (actReport) => actReport[alignedKey] < actReport[finParamName]
  );
  const notAlignedActReportsTableRows = notAlignedActReports.map((actReport) =>
    getNotAlignedActReportTableData(report, actReport, finParamName)
  );

  const tableData = [
    // ELIGIBLE
    {
      name: "A. TAXONOMY-ELIGIBLE ACTIVITIES",
      type: "category",
    },
    // ALIGNED
    {
      name: "A.1. Environmentally sustainable activities (Taxonomy-aligned)",
      type: "subcategory",
    },
    ...alignedActReportsTableRows,
    // ALIGNED - SUM
    {
      name: "Turnover of environmentally sustainable activities (Taxonomy-aligned) (A.1.)",
      type: "sum",
      codes: "",
      finValue: reportAlignedValue,
      finValueProportion: reportAlignedPercent,
      sc_criteria_1: reportAlignedPercent,
      aligned_fin_value: reportAlignedPercent,
      aligned_fin_value_prev_year: "",
    },
    // NOT-ALIGNED
    {
      name: "A.2 Taxonomy-Eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)",
      type: "subcategory",
    },
    ...notAlignedActReportsTableRows,
    // NOT ALIGNED - SUM
    {
      name: "Turnover of Taxonomy-eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)",
      type: "sum",
      codes: "",
      finValue: reportNotAlignedValue,
      finValueProportion: reportNotAlignedProportion,
    },
    // ELIGIBLE - SUM
    {
      name: "Total (A.1 + A.2)",
      type: "sum",
      codes: "",
      finValue: reportEligibleValue,
      finValueProportion: reportEligibleProportion,
    },
    {
      name: "B. Taxonomy-non-eligible activities",
      type: "subcategory",
    },
    {
      name: "Turnover of Taxonomy-non-eligible activities (B)",
      type: "sum",
      codes: "",
      finValue: reportNotEligibleValue,
      finValueProportion: reportNotEligibleProportion,
    },
    {
      name: "Total (A + B)",
      type: "sum",
      codes: "",
      finValue: reportTotalValue,
      finValueProportion: 1,
    },
  ];
  return tableData.map((td, index) => ({
    ...td,
    key: index,
  }));
};

const rowWithSingleValue = (row) =>
  ["category", "subcategory"].includes(row.type);

const HeaderCell = ({ children, measure, horizontal, theme }) => (
  <div
    className={cs(
      styles.headerCell,
      horizontal && styles["headerCell--horizontal"],
      theme && styles[`headerCell--theme-${theme}`]
    )}
  >
    <span className={styles.headerCell__title}>{children}</span>
    <span className={styles.headerCell__measure}>{measure}</span>
  </div>
);

const renderNameCell = (text, row) => {
  const isFullWidthCell = rowWithSingleValue(row);
  const classNameByType =
    {
      category: "categroyCell",
      subcategory: "subcategoryCell",
      activity: "itemNameCell",
      sum: "subcategoryCell",
    }[row.type] || "itemNameCell";

  return {
    children: (
      <div
        className={cs(
          styles[classNameByType],
          isFullWidthCell && styles.fullWidthCell
        )}
      >
        {text}
      </div>
    ),
    props: isFullWidthCell
      ? {
          colSpan: 1000,
        }
      : {},
  };
};

const textTransformations = {
  percent: (text) => (text ? decimalToPercents(text).slice(0, -1) : 0),
  bool: (text) => (text ? `Y` : "N/A"),
  codes: (text) => (text ? text.join("; ") : ""),
  currency_mln: (text) => {
    if (!text) return "0";
    const roundedValue = Number((text / 1e6).toFixed(3));
    return thousandFormatter(roundedValue);
  },
  default: (text) => text,
};

const renderValueCell =
  (textTransformMethod = textTransformations.default, modificator) =>
  (text) =>
    (
      <div
        className={cs(styles.valueCell, styles[`valueCell--${modificator}`])}
      >
        {textTransformMethod(text)}
      </div>
    );

const renderContent = (contentType) => (text, row, index) => {
  const contentTypeToRenderMethods = {
    codes: renderValueCell(textTransformations.codes, "small"),
    finValue: renderValueCell(textTransformations.currency_mln),
    finValueProportion: renderValueCell(textTransformations.percent),
    sc_criteria: renderValueCell(textTransformations.percent, "theme-1"),
    dnsh_criteria: renderValueCell(textTransformations.bool, "theme-2"),
    bool: renderValueCell(textTransformations.bool),
    proportion: renderValueCell(textTransformations.percent),
    text: renderValueCell(),
  };

  const cellRenderMethod =
    contentTypeToRenderMethods[contentType] || contentTypeToRenderMethods.text;

  const content = cellRenderMethod(text, row, index);
  let props = {};
  if (rowWithSingleValue(row)) {
    props = {
      colSpan: 0,
    };
  }
  return {
    children: content,
    props,
  };
};

const getColumns = (finParamDisplayName, currency) => {
  const columns = [
    {
      title: "",
      children: [
        {
          title: () => (
            <span className={styles.headerMainCell}>Economic activities</span>
          ),
          dataIndex: "name",
          key: "name",
          render: renderNameCell,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => <HeaderCell>code(s)&nbsp;({index})</HeaderCell>,
          dataIndex: "codes",
          key: "codes",
          render: renderContent("codes"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure={`${currency}, mln`}>
              Absolute {finParamDisplayName}&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "finValue",
          key: "finValue",
          render: renderContent("finValue"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%">
              Proportion of {finParamDisplayName}&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "finValueProportion",
          key: "finValueProportion",
          render: renderContent("finValueProportion"),
        },
      ],
    },
    {
      title: (
        <div className={styles.headerCellWithBrace}>
          Substantial contribution criteria
          <HorizontalBraceIcon />
        </div>
      ),
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Climate change mitigation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_1",
          key: "sc_criteria_1",
          render: renderContent("sc_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Climate change adaptation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_2",
          key: "sc_criteria_2",
          render: renderContent("sc_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Water and marine resourses&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_3",
          key: "sc_criteria_3",
          render: renderContent("sc_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Circular economy&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_4",
          key: "sc_criteria_4",
          render: renderContent("sc_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Pollution&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_5",
          key: "sc_criteria_5",
          render: renderContent("sc_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="%" theme="1">
              Biodiversity and ecosystems&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "sc_criteria_6",
          key: "sc_criteria_6",
          render: renderContent("sc_criteria"),
        },
      ],
    },
    {
      title: (
        <div className={styles.headerCellWithBrace}>
          DNSH criteria (‘Does Not Significantly Harm’)
          <HorizontalBraceIcon />
        </div>
      ),
      children: [
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Climate change mitigation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_1",
          key: "dnsh_criteria_1",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Climate change adaptation&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_2",
          key: "dnsh_criteria_2",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Water and marine resourses&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_3",
          key: "dnsh_criteria_3",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Circular economy&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_4",
          key: "dnsh_criteria_4",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Pollution&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_5",
          key: "dnsh_criteria_5",
          render: renderContent("dnsh_criteria"),
        },
        {
          title: (index) => (
            <HeaderCell measure="Y/N" theme="2">
              Biodiversity and ecosystems&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "dnsh_criteria_6",
          key: "dnsh_criteria_6",
          render: renderContent("dnsh_criteria"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="Y/N">
              Minimum saveguards&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "mss",
          key: "mss",
          render: renderContent("bool"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%" horizontal>
              Taxonomy-aligned proportion of turnover, year N&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "aligned_fin_value",
          key: "aligned_fin_value",
          render: renderContent("proportion"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="%" horizontal>
              Taxonomy-aligned proportion of turnover, year N-1&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "aligned_fin_value_prev_year",
          key: "aligned_fin_value_prev_year",
          render: renderContent("proportion"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="E" horizontal>
              Category (enabling activity)&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "category_enabling",
          key: "category_enabling",
          render: renderContent("text"),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: (index) => (
            <HeaderCell measure="T" horizontal>
              Category (transitional activity)&nbsp;({index})
            </HeaderCell>
          ),
          dataIndex: "category_transitional",
          key: "category_transitional",
          render: renderContent("text"),
        },
      ],
    },
  ];
  let counter = 0;
  return columns.map((col) => ({
    ...col,
    children: col.children.map((child) => {
      counter += 1;
      return {
        ...child,
        title: child.title(counter),
      };
    }),
  }));
};

const ReportResultByParameter = ({
  finParamName,
  paginationIndex,
  paginationTotal,
  actReports,
  report,
  company,
}) => {
  const finParamDisplayName = reportFinParams[finParamName];
  const columns = getColumns(finParamDisplayName, "EUR");
  const tableData = getTableData(report, actReports, finParamName);
  return (
    <div className={styles.page}>
      <p className={styles.pagination}>
        {paginationIndex}/{paginationTotal}
      </p>
      <div className={styles.pageTitleWrapper}>
        <h1 className={styles.pageTitle}>Taxonomy Report</h1>
        <div className={styles.logo}>
          <LogoIcon />
        </div>
      </div>

      <p className={styles.description}>
        Proportion of {finParamDisplayName} from products or services associated
        with Taxonomy-aligned economic activities
      </p>
      <div className={styles.twoColsList}>
        <div className={styles.companyInfoKey}>Company</div>
        <div className={styles.companyInfoValue}>{company.name}</div>
        <div className={styles.companyInfoKey}>Year (N)</div>
        <div className={styles.companyInfoValue}>{report.reporting_year}</div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default ReportResultByParameter;
