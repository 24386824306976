import React from "react";
import { useOutletContext } from "react-router-dom";
import ReportingFramework from "./ReportingFramework";

const ReportingFrameworkConnected = () => {
  const props = useOutletContext();
  return <ReportingFramework {...props} />;
};

export default ReportingFrameworkConnected;
