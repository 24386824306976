import { useQuery } from "urql";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { frameworkQuery } from "../../api/queries";
import checkEmpty from "../../helpers/checkEmpty";

const check = (field, framework) => {
  if (Array.isArray(field)) {
    return field.every((item) => check(item, framework));
  }

  if (typeof field === "object") {
    return Object.keys(field).every((item) =>
      check(field[item][framework[item]], framework)
    );
  }
  // базовый случай выход из рекурсии true - прогресс
  return checkEmpty(framework, field);
};

const useGetFramework = () => {
  const { frameworkId } = useParams();
  const [{ data, ...rest }] = useQuery({
    query: frameworkQuery,
    variables: { id: frameworkId },
  });

  const framework = data?.frameworks_by_pk;

  const progress = useMemo(() => {
    const frameworkFields = {
      0: [
        "comp_desc",
        "comp_sust_goals",
        "comp_sust_example",
        {
          comp_fossil_fuel_unavailability: {
            false: "comp_fossil_fuel",
            true: "comp_fossil_fuel_unavailability",
          },
        },
        "goals",
        "principles",
        "issuance",
        {
          other_standards_unavailability: {
            false: "other_standards",
            true: "other_standards_unavailability",
          },
        },
        "instrument_types",
      ],
      1: ["finance_refinance", "excluded_categories"],
      2: [
        "asset_select_frequency",
        "eligible_assess_depart",
        "eligible_approve_depart",
        "monitoring_depart",
      ],
      3: [
        "manage_proceeds_approach",
        "tracking_proceeds_approach",
        "track_proceeds_depart",
        "track_update_frequency",
        "lookback_assets",
        "lookback_expend",
        "lookback_projects",
        "lookback_invest",
        {
          allocation_at_issuance: {
            No: "allocation_time",
            Yes: "allocation_at_issuance",
          },
        },
        "manage_unallocated_proceeds",
      ],
      4: [
        "allocation_report_frequency",
        "allocation_report_detail",
        {
          impact_report_unavailability: {
            false: {
              impact_report_time: {
                "As long as any green bonds are outstanding": [
                  "impact_report_frequency",
                  "impact_report_time",
                  "impact_report_detail",
                ],
                "At least once": ["impact_report_time", "impact_report_detail"],
              },
            },
            true: "impact_report_unavailability",
          },
        },
        "report_published",
      ],
      5: [
        {
          issuance: {
            No: {
              external_review_unavailability: {
                false: ["external_reviewer", "external_review_published"],
                true: "external_review_unavailability",
              },
            },
            Yes: [
              "preissue_verifier",
              "preissue_published",
              "postissue_verifier",
              "postissue_report_published",
              {
                certification_unavailability: {
                  false: "certification_published",
                  true: "certification_unavailability",
                },
              },
            ],
          },
        },
      ],
      6: [
        "framework_updates",
        {
          new_standards_unavailability: {
            false: "future_standards",
            true: "new_standards_unavailability",
          },
        },
        "framework_updates_published",
      ],
    };
    if (framework) {
      return Object.entries(frameworkFields).reduce(
        (_progress, [step, fields]) => {
          const full = fields.length;
          const done = fields.filter((field) => check(field, framework)).length;
          return { ..._progress, [step]: Math.trunc((done / full) * 100) };
        },
        {}
      );
    }
  }, [framework]);

  return { framework, progress, ...rest };
};

export default useGetFramework;
