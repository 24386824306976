import React from "react";
import { Tooltip } from "antd";
import styles from "./SDGItem.module.less";
import SDGs from "../../data/SDGs";

const SDGTooltip = ({ sdg }) => (
  <div className={styles.sdgTooltip}>
    <span style={{ backgroundColor: sdg.color }}>{sdg.icon}</span>
    <span>{sdg.title}</span>
  </div>
);

const SDGItem = ({ sdgId }) => {
  const sdg = SDGs.find((item) => item.id === sdgId);
  return (
    <Tooltip title={<SDGTooltip sdg={sdg} />} placement="top">
      <div className={styles.sdgItem} style={{ backgroundColor: sdg.color }}>
        <span>{sdg.number}</span>
      </div>
    </Tooltip>
  );
};

export default SDGItem;
