import React, { useState, useRef, useMemo } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import isEqual from "lodash.isequal";
import { useUpdateEffect } from "ahooks";
import AttachFileDrawer from "../AttachFileDrawer/AttachFileDrawer";

// value: [{doc_id, url, name}]
// valueDuplicate нужны чтобы управлять состоянием инпута
const INITIAL_VALUE = [];
const AttachFileInput = ({
  value = INITIAL_VALUE,
  onChange,
  disabled,
  text,
  className,
}) => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState(value);
  const attachedFilesIds = useMemo(() => value?.map((d) => d.docId), [value]);
  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  useUpdateEffect(() => {
    setAttachedFiles((currentFiles) =>
      !isEqual(value, currentFiles) ? value : currentFiles
    );
  }, [value]);

  useUpdateEffect(() => {
    onChangeRef.current?.(attachedFiles);
  }, [attachedFiles]);

  const handleOpenDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onAttach = (files) => {
    setAttachedFiles((atFiles) => [...atFiles, ...files]);
  };
  return (
    <div className={className}>
      <Button
        data-cy="btn-attach"
        icon={<UploadOutlined />}
        type="dashed"
        onClick={handleOpenDrawer}
        disabled={disabled}
      >
        {text || "Attach file"}
      </Button>

      <AttachFileDrawer
        isVisible={isDrawerVisible}
        handleClose={handleCloseDrawer}
        onAttach={onAttach}
        attachedFilesIds={attachedFilesIds}
      />
    </div>
  );
};

export default AttachFileInput;
