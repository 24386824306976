import React, { useMemo } from "react";
import { node } from "prop-types";
import {
  Layout,
  BookOutlined,
  ExternalLink,
} from "@evercityecosystem/evercity-ui";
import { useCurrentUser } from "../../hooks/user";
import ScrollToThisOnRedirect from "./ScrollToThisOnRedirect";
import { ReactComponent as ListIcon } from "../../assets/sidebar/list.svg";
import { ReactComponent as HomeIcon } from "../../assets/sidebar/home.svg";
import { ReactComponent as CardIcon } from "../../assets/sidebar/card.svg";
import { ReactComponent as FileIcon } from "../../assets/sidebar/file.svg";
import { ReactComponent as FolderIcon } from "../../assets/sidebar/folder.svg";
import { ReactComponent as MapIcon } from "../../assets/sidebar/map.svg";
import AppHeader from "./AppHeader";

import logoCompact from "./assets/logo.svg";

const logoCompactElement = (
  <img
    alt="logo"
    src={logoCompact}
    style={{
      maxWidth: 38,
      maxHeight: 38,
      objectFit: "contain",
      objectPosition: "left center",
      transform: "translateX(-12px)",
    }}
  />
);

const ITEMS = [
  {
    name: "Listing",
    icon: <ListIcon />,
    to: "/listing",
  },
  {
    name: "Dashboard",
    icon: <HomeIcon />,
    to: "/dashboard",
    disabled: true,
  },
  {
    name: "Assets",
    icon: <CardIcon />,
    to: "/assets",
    disabled: true,
  },
  {
    name: "EU Taxonomy",
    icon: <FileIcon />,
    checkDisabled: ({ companyType, constraints }) =>
      companyType === "investor" || constraints.includes("Taxonomy"),
    to: "/company/taxonomy",
  },
  {
    name: "Frameworks",
    icon: <FolderIcon />,
    checkDisabled: ({ companyType, constraints }) =>
      companyType === "investor" || constraints.includes("Framework"),
    to: "/company/frameworks",
  },
  {
    name: "PIN",
    icon: <BookOutlined />,
    to: "/company/pins",
  },
  {
    name: "ESG map",
    icon: <MapIcon />,
    to: "/map",
  },
];

const LkLayout = ({ children, header }) => {
  const [userState] = useCurrentUser();
  const userIsAuth = !userState.error && !!userState?.data?.id;

  const menu = useMemo(() => {
    const companyType = userState?.company?.type;
    const config = userState?.company?.settings;
    const constraints = config?.restrictedServices || [];

    return ITEMS.map((item) => ({
      ...item,
      disabled:
        item.disabled || item.checkDisabled?.({ companyType, constraints }),
    })).filter((item) => !item.disabled);
  }, [userState]);

  return (
    <Layout variant={userIsAuth ? "personal" : "public"}>
      {userIsAuth && (
        <Layout.Sider items={menu} siderLogoCollapsed={logoCompactElement} />
      )}
      <AppHeader {...header} />
      <Layout variant="internal">
        <ScrollToThisOnRedirect />
        <Layout.Content>{children}</Layout.Content>
        <Layout.Footer brand="GDP">
          <ExternalLink href="https://green-digital-platform.s3.eu-central-1.amazonaws.com/user_agreement.pdf">
            Terms of use
          </ExternalLink>
          {/* <ExternalLink href="">Cookies Policy</ExternalLink>
          <ExternalLink href="https://storage.googleapis.com/evercity-public/Privacy%20Policy.pdf">
            Privacy Policy
          </ExternalLink>
          <ExternalLink href="https://storage.googleapis.com/evercity-public/Terms%20of%20service.pdf">
            Terms of Use
          </ExternalLink> */}
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};

LkLayout.propTypes = {
  children: node.isRequired,
};

export default LkLayout;
