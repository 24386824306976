import React, { useState } from "react";
import { BankOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Space,
  Select,
} from "@evercityecosystem/evercity-ui";
import { useMutation } from "urql";

import { updateMarketingDemoAccountMutation } from "../../api/mutations";

import checkboxes from "./checkboxes";

const options = [
  {
    label: "SME",
    description:
      "a company with <€50M turnover that implements sustainable projects",
    value: "sme",
  },
  {
    label: "Large Enterprise",
    description:
      "a company with >€50M turnover that implements sustainable projects",
    value: "corporation",
  },
  {
    label: "Institutional investor",
    description:
      "a company with >€50M turnover that implements sustainable projects",
    value: "institutional_investor",
  },
  {
    label: "Retail investor",
    description:
      "an individual who wants to invest in / buy sustainable finance assets",
    value: "retail_investor",
  },
  {
    label: "Service provider",
    description: "including auditors, consultants, underwriters",
    value: "service_provider",
  },
];

const UserInfoForm = ({ user, handleSetStep }) => {
  const [describesGroup, setDescribesGroup] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [form] = Form.useForm();

  const [{ fetching }, updateMarketingDemo] = useMutation(
    updateMarketingDemoAccountMutation
  );

  const submit = async ({ products, companyName, describes }) => {
    setDirtyForm(true);
    try {
      await form.validateFields();
    } catch {
      return;
    }
    const interests = products?.map((product) => {
      if (product.id === "Other") {
        return product.specific;
      }
      return product.id;
    });
    const variables = {
      id: user.id,
      company_name: companyName,
      product_interests: `{${interests?.join(",")}}`,
      company_type: describes,
    };

    updateMarketingDemo(variables).then((result) => {
      if (result.error) {
        return console.error("Oh no!", result.error);
      }
      handleSetStep(2);
    });
  };

  const onError = (errorInfo) => {
    console.error("Submit error: ", errorInfo);
  };

  const handleDescribesChange = (value) => {
    setDescribesGroup(value);
    form.setFieldsValue({
      products: [],
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={submit}
      onFinishFailed={onError}
    >
      <Form.Item
        name="companyName"
        shouldUpdate
        rules={
          dirtyForm && [
            { required: true, message: "Required field" },
            { min: 3, message: "must be at least 3 characters" },
          ]
        }
      >
        <Input
          roundCorners="medium"
          placeholder="Company name"
          prefix={<BankOutlined />}
          size="large"
        />
      </Form.Item>

      <Form.Item name="describes" shouldUpdate label="What best describes you?">
        <Select
          roundCorners
          onChange={handleDescribesChange}
          placeholder="Select"
          size="large"
          options={options}
        />
      </Form.Item>

      {describesGroup && (
        <Form.Item
          label="What products are you interested in?"
          name="products"
          shouldUpdate
        >
          <Checkbox.Group
            options={checkboxes[describesGroup]}
            optionType="card"
          />
        </Form.Item>
      )}

      <Space block justifyContent="space-between">
        <Button
          roundCorners
          icon={<LeftOutlined />}
          type="text"
          onClick={() => handleSetStep((step) => step - 1)}
        >
          Go back
        </Button>
        <Button
          roundCorners
          disabled={fetching}
          htmlType="submit"
          loading={fetching}
          type="primary"
        >
          Submit
        </Button>
      </Space>
    </Form>
  );
};

export default UserInfoForm;
