import { projectQuery } from "../../../api/queries";

const projectLoader =
  (client) =>
  async ({ params }) => {
    const isNewProject = params.id === "_";

    if (isNewProject) {
      return {};
    }

    const cachedData = client.readQuery(projectQuery, {
      id: params.id,
    });

    if (cachedData !== null) {
      return cachedData?.data?.projects_by_pk;
    }

    const response = await client
      .query(projectQuery, {
        id: params.id,
      })
      .toPromise();

    return response?.data?.projects_by_pk;
  };

export default projectLoader;
