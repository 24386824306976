import React from "react";
import { Typography } from "antd";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./Paragraph.module.less";

const { Paragraph: BaseParagraph } = Typography;

const Paragraph = ({ noMargin, block, size, className, ...paragprahProps }) => (
  <BaseParagraph
    {...paragprahProps}
    className={cs(
      className,
      noMargin && styles["paragraph--noMargin"],
      block && styles["paragraph--block"],
      styles[`paragraph--size-${size}`]
    )}
  />
);

Paragraph.propTypes = {
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf([
    "extra-large",
    "large",
    "default",
    "small",
    "extra-small",
  ]),
};

export default Paragraph;
