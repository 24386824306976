import React from "react";
import { Col, Row } from "antd";
import classnames from "classnames";
import { DownloadOutlined } from "@ant-design/icons";
import { Text, Title } from "@antd-ovveride";
import SummarySection from "../SummarySection/SummarySection";
import styles from "../FrameworkSummary/FrameworkSummary.module.less";

const ExternalReviewSummary = ({ framework, className, ...props }) => (
  <SummarySection
    className={classnames(className)}
    title="External review"
    {...props}
  >
    {framework.issuance === "Yes" && (
      <Row>
        <Col span={12}>
          <Title level={5} className={styles.summary__subtitle}>
            Pre-issuance verification
          </Title>
          <Text type="secondary" block size="large">
            Reviewer
          </Text>
          <Text size="large">{framework.preissue_verifier}</Text>
          <Text className={styles.summary__download} block size="large">
            <DownloadOutlined /> Download Document
          </Text>
        </Col>
        <Col span={12}>
          <Title className={styles.summary__subtitle} level={5}>
            Post-issuance verification
          </Title>
          <Text type="secondary" block size="large">
            Reviewer
          </Text>
          <Text size="large">{framework.postissue_verifier}</Text>
          <Text className={styles.summary__download} block size="large">
            <DownloadOutlined /> Download Document
          </Text>
        </Col>
      </Row>
    )}
    {framework.issuance === "No" && (
      <>
        <Title level={5} className={styles.summary__subtitle}>
          External review
        </Title>
        <Text type="secondary" block size="large">
          Reviewer
        </Text>
        <Text size="large">{framework.external_reviewer}</Text>
        <Text className={styles.summary__download} block size="large">
          <DownloadOutlined /> Download Document
        </Text>
      </>
    )}
  </SummarySection>
);

export default ExternalReviewSummary;
