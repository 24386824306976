import { useEffect, useState, useMemo } from "react";
import { useQuery } from "urql";
import Fuse from "fuse.js";
import { useParams } from "react-router-dom";

import {
  getActivitiesExtendedQuery,
  getActivitiesReportsQuery,
} from "../../api/queries";

import useDebounce from "../../hooks/useDebounce";
import { formatActivitiesResponse } from "./helpers";

export const useActivitiesSearch = (activities, value) => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const dotlessValue = value.replace(/\./g, "");
  const debouncedSearchValue = useDebounce(dotlessValue, 200);

  const fuse = useMemo(
    () =>
      new Fuse(activities, {
        keys: ["name", "codes.code", "codes.sbi", "dotlessCode"],
        threshold: 0,
        ignoreLocation: true,
        getFn: (obj, path) => {
          // allow to search NACE without dots or particular dots for better UX
          if (path?.[0] === "dotlessCode") {
            return Fuse?.config
              ?.getFn(obj, "codes.code")
              ?.map((item) => item.replace(/\./g, ""));
          }

          return Fuse.config.getFn(obj, path);
        },
      }),
    [activities]
  );

  useEffect(() => {
    if (dotlessValue) {
      setLoading(true);
    }
  }, [dotlessValue]);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setLoading(false);
      setResult([]);
    }

    const searchResult = fuse
      .search(debouncedSearchValue)
      .map((item) => item.item);

    setLoading(false);
    setResult(searchResult);
  }, [debouncedSearchValue, fuse]);

  return [result, loading];
};

export const useAddedActivities = () => {
  const { reportId } = useParams();
  const [response, ...rest] = useQuery({
    query: getActivitiesReportsQuery,
    variables: { reportId },
    requestPolicy: "network-only",
  });

  const actReports = useMemo(
    () => response.data?.eu_taxonomy_activities_reports || [],
    [response.data]
  );

  const [addedActivities, addedNaceCodes] = useMemo(
    () =>
      actReports.reduce(
        (resp, actReport) => {
          const activities = [...resp[0], actReport.activity_id];
          const naceCodes = {
            ...resp[1],
            [actReport.activity_id]: actReport.activities_reports_codes.map(
              (code) => code.nace_code.id
            ),
          };
          return [activities, naceCodes];
        },
        [[], {}]
      ),
    [actReports]
  );
  return [
    {
      ...response,
      data: {
        activities: addedActivities,
        naceCodes: addedNaceCodes,
      },
    },
    ...rest,
  ];
};

export const useActivitiesQuery = () => {
  const [addedActivitiesResponse] = useAddedActivities();
  const [activititesResponse] = useQuery({
    query: getActivitiesExtendedQuery,
    requestPolicy: "network-only",
  });
  const activities = useMemo(
    () => activititesResponse.data?.eu_taxonomy_activities,
    [activititesResponse.data]
  );

  const formattedActivities = useMemo(
    () => formatActivitiesResponse(activities) || [],
    [activities]
  );

  const fetching =
    activititesResponse.fetching || addedActivitiesResponse.fetching;

  const error = activititesResponse.error || addedActivitiesResponse.error;

  return [
    {
      ...activititesResponse,
      data: {
        activities: formattedActivities,
        addedActivities: addedActivitiesResponse.data.activities,
        addedCodes: addedActivitiesResponse.data.naceCodes,
      },
      fetching,
      error,
    },
  ];
};
