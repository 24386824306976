import { useQuery } from "urql";
import { useMemo } from "react";
import { getReportsQuery } from "../../api/queries";
import { useCurrentUser } from "../user";
import sampleCompany from "../../helpers/sampleCompany";

const setSampleCompany = (report) => ({
  ...report,
  company: {
    id: report.company.id,
    ...sampleCompany,
  },
});

const useSampleReport = () => {
  const [{ company }] = useCurrentUser();
  const [{ data, error, fetching }] = useQuery({
    query: getReportsQuery,
    pause: !company,
    variables: {
      where: {
        company_id: { _eq: company.id },
        status: {
          _eq: "sample",
        },
      },
    },
  });

  const sampleReport = useMemo(
    () =>
      data?.eu_taxonomy_reports?.[0] &&
      setSampleCompany(data.eu_taxonomy_reports[0]),
    [data]
  );

  return { sampleReport, fetching, error };
};

export default useSampleReport;
