import React from "react";
import { Text } from "@antd-ovveride";
import { toMln } from "../../../helpers/converters";
import Progress from "../../Progress/Progress";
import { RECORD_TYPE } from "../../ActivitiesTable/types";
import styles from "./FinParamCell.module.less";

const ActivityFinParamCell = ({ part, total }) => (
  <Progress part={part} total={total} formatter={toMln} responsive />
);

const UnitFinParamCell = ({ value }) => (
  <Text size="small" block style={{ textAlign: "right" }}>
    {value !== "N/A" ? toMln(value) : value}
  </Text>
);

const FinParamCell = ({ value, record }) => {
  let component;
  switch (record.keyType) {
    case RECORD_TYPE.ACTIVITY:
      component = (
        <ActivityFinParamCell part={value.part} total={value.total} />
      );
      break;
    default:
      component = <UnitFinParamCell value={value} />;
  }
  return <div className={styles.container}>{component}</div>;
};

export default FinParamCell;
