import React from "react";
import {
  Section,
  TextArea,
  Checkbox,
  Input,
  Form,
  Select,
  DatePicker,
  Title,
  Question,
  Button,
} from "@evercityecosystem/evercity-ui";
import { PlusOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import RisksTable from "./RisksTable";
import { projectStatuses } from "../../data/PIN";
import ProjectParticipantDetails from "./ProjectParticipantDetails";
import QuestionWithThreads, {
  GroupContext,
} from "../QuestionWithThreads/QuestionWithThreads";

const ProjectIdeaNoteDetails = ({ onOpenDiscussionsDrawer, threads }) => {
  const { withoutThreshold } = useOutletContext();
  return (
    <GroupContext.Provider value="details">
      <Title level={3} size="middle">
        2. Project details
      </Title>
      <Section number="2.1" title="Project info and business model">
        <QuestionWithThreads
          optional={withoutThreshold}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          threads={threads}
          property="details_description"
          number="2.1.1"
          title="Detailed Project Description"
          description={
            <>
              Provide a comprehensive project overview. You may use the
              following structure:
              <ul>
                <li>
                  A summary description of the technologies and activities to be
                  implemented,
                </li>
                <li>The location of the project,</li>
                <li>
                  An explanation of how the project is expected to generate GHG
                  emission reductions or removals,
                </li>
                <li>
                  A brief description of the scenario that would occur in the
                  absence of the project,
                </li>
                <li>
                  An estimate of annual average and total GHG emission
                  reductions and removals,
                </li>
                <li>
                  A brief description of other environmental, social and
                  economic benefits generated by the project.
                </li>
              </ul>
            </>
          }
        >
          <Form.DiscussedItem
            name="details_description"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={1700} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          threads={threads}
          property="details_innovation"
          optional
          number="2.1.2"
          title="Innovation"
          description="Briefly highlight any innovative technology / project components."
        >
          <Form.DiscussedItem
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
            name="details_innovation"
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <Section number="2.1.3" title="Financing">
          <QuestionWithThreads
            optional={withoutThreshold}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            threads={threads}
            property="details_sources_of_financing"
            title="a) Sources of Finance"
            description="How do you plan to finance the Project?"
          >
            <Form.DiscussedItem
              name="details_sources_of_financing"
              rules={[
                { required: !withoutThreshold, message: "Field is required" },
              ]}
            >
              <Checkbox.Group
                options={[
                  {
                    value: "Grant",
                    label: "Grant",
                  },
                  {
                    value: "Loan",
                    label: "Loan",
                  },
                  {
                    label: "Equity investment",
                    value: "Equity investment",
                  },
                  {
                    label: "Personal funds",
                    value: "Personal funds",
                  },
                  {
                    label: "Public funding",
                    value: "Public funding",
                  },
                  {
                    label: "Carbon forwards",
                    value: "Carbon forwards",
                  },
                  {
                    label: "Other (please specify)",
                    value: "Other (please specify)",
                    specifiable: true,
                  },
                ]}
              />
            </Form.DiscussedItem>
          </QuestionWithThreads>
          <QuestionWithThreads
            threads={threads}
            optional={withoutThreshold}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            property="details_funding_status"
            title="b) Funding status"
            description="You are welcome to share any information on funding you have secured for the project."
          >
            <Form.DiscussedItem
              rules={[
                { required: !withoutThreshold, message: "Field is required" },
              ]}
              name="details_funding_status"
              validateTrigger="onBlur"
              trigger="onBlur"
              valuePropName="defaultValue"
            >
              <TextArea size="large" roundCorners wordLimit={150} showCount />
            </Form.DiscussedItem>
          </QuestionWithThreads>
        </Section>
        <Question
          optional={withoutThreshold}
          number="2.1.4"
          title="Risks"
          description={
            <>
              <p>
                Identify in the table below risks and uncertainties that may
                cause the Project to not perform as planned and/or may
                substantially affect the GHG Reductions expected to be achieved
                by the Project.
              </p>
              <p>
                Describe what actions will be taken to monitor and reduce the
                specific Project risk and uncertainty.
              </p>
            </>
          }
        >
          <RisksTable
            threads={threads}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          />
        </Question>
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_assumptions"
          number="2.1.5"
          title="Assumptions"
          description="Disclose any key project assumptions that are important to the Project. This may include assumptions around regulation and additionality, maturity of technology, commercial potential, financing availability, price, corresponding adjustments or commitment from national parties."
        >
          <Form.DiscussedItem
            name="details_assumptions"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
      <Section number="2.2" title="Project Owner details">
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_name"
          number="2.2.1"
          title="Primary contact name and title"
          description="Name and title of the representative."
        >
          <Form.DiscussedItem
            name="details_owner_name"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_company"
          number="2.2.2"
          title="Company"
          description="Name of organization."
        >
          <Form.DiscussedItem
            name="details_owner_company"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_address"
          number="2.2.3"
          title="Address"
          description="Address of organization."
        >
          <Form.DiscussedItem
            name="details_owner_address"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_phone"
          number="2.2.4"
          title="Phone"
          description="Phone of organization."
        >
          <Form.DiscussedItem
            name="details_owner_phone"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_email"
          number="2.2.5"
          title="Email"
          description="E-mail of organization."
        >
          <Form.DiscussedItem
            name="details_owner_email"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" type="email" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          optional={withoutThreshold}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_website"
          number="2.2.6"
          title="Website"
          description="Please include the website of your organization."
        >
          <Form.DiscussedItem
            name="details_owner_website"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_description"
          number="2.2.7"
          title="Brief description of organization"
          description="Please provide brief description of organization."
        >
          <Form.DiscussedItem
            name="details_owner_description"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_experience_market"
          number="2.2.8"
          title="Experience with carbon markets"
          description="Describe experience related to the carbon markets."
        >
          <Form.DiscussedItem
            name="details_owner_experience_market"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_owner_experience_project"
          number="2.2.9"
          title="Experience with this project type"
          description="Describe experience related to the proposed activity."
        >
          <Form.DiscussedItem
            name="details_owner_experience_project"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
      <Section number="2.3" title="Project Developer details">
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          optional
          property="details_developer_name"
          number="2.3.1"
          title="Primary contact name and title"
          description="Name and title of the representative."
        >
          <Form.DiscussedItem
            name="details_developer_name"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_company"
          optional
          number="2.3.2"
          title="Company"
          description="Name of organization."
        >
          <Form.DiscussedItem
            name="details_developer_company"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_address"
          optional
          number="2.3.3"
          title="Address"
          description="Address of organization."
        >
          <Form.DiscussedItem
            name="details_developer_address"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_phone"
          optional
          number="2.3.4"
          title="Phone"
          description="Phone of organization."
        >
          <Form.DiscussedItem
            name="details_developer_phone"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_email"
          optional
          number="2.3.5"
          title="Email"
          description="Email of organization."
        >
          <Form.DiscussedItem
            name="details_developer_email"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" type="email" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_website"
          optional
          number="2.3.6"
          title="Website"
          description="Please include the website of your organization."
        >
          <Form.DiscussedItem
            name="details_developer_website"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_description"
          optional
          number="2.3.7"
          title="Brief description of organization"
          description="Please provide brief description of organization."
        >
          <Form.DiscussedItem
            name="details_developer_description"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_experience_market"
          optional
          number="2.3.8"
          title="Experience with carbon markets"
          description="Describe experience related to the carbon markets."
        >
          <Form.DiscussedItem
            name="details_developer_experience_market"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_developer_experience_project"
          optional
          number="2.3.9"
          title="Experience with this project type"
          description="Describe experience related to the proposed activity."
        >
          <Form.DiscussedItem
            name="details_developer_experience_project"
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={500} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
      <Section number="2.4" title="Project Participant(s) details">
        <Form.List name="details_participants">
          {(participants, { add, remove }) => (
            <>
              {participants.map((props) => (
                <ProjectParticipantDetails
                  {...props}
                  onDelete={remove}
                  onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
                  threads={threads}
                />
              ))}
              <Form.DiscussedItem>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add participant
                </Button>
              </Form.DiscussedItem>
            </>
          )}
        </Form.List>
      </Section>
      <Section number="2.5" title="Location">
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_location"
          number="2.5.1"
          title="Project location"
        >
          <Form.DiscussedItem
            name="details_location"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_coordinates"
          number="2.5.2"
          title="Coordinates"
        >
          <Form.DiscussedItem
            name="details_coordinates"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            validateTrigger="onBlur"
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <Input size="large" roundCorners="large" />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
      <Section number="2.6" title="Expected timeline">
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_status"
          number="2.6.1"
          title="Current status of the project."
        >
          <Form.DiscussedItem
            name="details_status"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <Select
              size="large"
              roundCorners
              options={projectStatuses.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_start_date"
          number="2.6.2"
          title="Expected project start date."
        >
          <Form.DiscussedItem
            name="details_start_date"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <DatePicker size="large" roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_delivery_date"
          number="2.6.3"
          title="Expected first year of offset delivery."
        >
          <Form.DiscussedItem
            name="details_delivery_date"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <DatePicker size="large" roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          optional={withoutThreshold}
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="details_end_date"
          number="2.6.4"
          title="Expected project end date."
        >
          <Form.DiscussedItem
            name="details_end_date"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
          >
            <DatePicker size="large" roundCorners />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
    </GroupContext.Provider>
  );
};

export default ProjectIdeaNoteDetails;
