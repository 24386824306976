import React from "react";
import { Card, Typography } from "antd";
import { FileAddOutlined, LockFilled } from "@ant-design/icons";
import classNames from "classnames";
import styles from "./FrameworkTemplates.module.less";

const FrameworksTemplate = ({ title, image, lock, className }) => (
  <Card
    bordered={false}
    className={classNames(styles.list__item, className)}
    cover={<img src={image} alt={title} />}
  >
    <Card.Meta title={title} />
    {lock ? (
      <div className={classNames(styles.hover, styles["hover--lock"])}>
        <LockFilled
          className={classNames(
            styles.hover__text,
            styles["hover__text--highlite"],
            styles["hover__text--icon"]
          )}
        />
      </div>
    ) : (
      <div className={classNames(styles.hover, styles["hover--unlock"])}>
        <FileAddOutlined
          className={classNames(
            styles.hover__text,
            styles["hover__text--icon"]
          )}
        />
        <Typography.Text className={styles.hover__text}>
          Create framework
        </Typography.Text>
      </div>
    )}
  </Card>
);

export default FrameworksTemplate;
