import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Typography } from "antd";
import { Space, Text } from "@antd-ovveride";
import { ContentLayout } from "@evercityecosystem/evercity-ui";
import UnitPointsFormConnected from "../UnitPointsForm/UnitPointsFormConnected";
import ContentTwoColsLayout from "../ContentTwoColsLayout/ContentTwoColsLayout";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";
import useBreakpoint from "../../hooks/useBreakpoint";
import { MESSENGER_GROUPS } from "../../data/messenger";
import { GroupContext } from "../../helpers/withThreadsHoc";

const { Title } = Typography;

const DNSHPage = ({ threads }) => {
  const { onFinish, sending, onPrevStep, unit } = useOutletContext();
  const { activityId, unitParentId } = useParams();
  const isSubunit = unitParentId !== "_";
  const { lg } = useBreakpoint();

  return (
    <GroupContext.Provider value={MESSENGER_GROUPS.TAXONOMY_UNIT.DNSH}>
      <ContentLayout gaps="off">
        <Space direction="vertical" size={30} block>
          <div>
            <Title level={2}>
              <Space align="center" size={8}>
                Confirm DNSH {isSubunit && <WatchTutorialBtn startTime={316} />}
              </Space>
            </Title>
            <Text type="secondary" size="large" noMargin>
              Please confirm that your activity does no significant harm to
              other five environmental objectives.
            </Text>
          </div>
          <ContentTwoColsLayout aside={lg}>
            <UnitPointsFormConnected
              sending={sending}
              threads={threads}
              onSubmit={onFinish}
              submitText="Next Step"
              onCancel={onPrevStep}
              cancelText="Back"
              activityId={activityId}
              unit={unit}
              pointsType="dnsh"
            />
          </ContentTwoColsLayout>
        </Space>
      </ContentLayout>
    </GroupContext.Provider>
  );
};

export default DNSHPage;
