import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useQuery } from "urql";
import { commodityFullInfoQuery } from "../../api/queries";
import LkLayout from "../LkLayout/LkLayout";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";

const CommodityContext = () => {
  const { slug } = useParams();
  const [commodityResult] = useQuery({
    query: commodityFullInfoQuery,
    variables: { slug },
  });

  if (commodityResult.fetching)
    return (
      <LkLayout>
        <LoadingSpinner full size="large" />
      </LkLayout>
    );

  const commodity = commodityResult.data?.commodities[0];
  if (commodityResult.error || !commodity) {
    return <Error404 />;
  }
  return <Outlet context={{ commodity }} />;
};

export default CommodityContext;
