import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Text,
  Modal,
  notification,
  Row,
} from "@evercityecosystem/evercity-ui";
import { CloseCircleOutlined } from "@ant-design/icons";
import cs from "classnames";
import { useMutation } from "urql";
import { useParams } from "react-router-dom";
import styles from "./AddActivityDrawer.module.less";
import AddActivityDrawerContentConnected from "./AddActivityDrawerContentConnected";
import { createActivityReportMutation } from "../../api/mutations";

const OkButton = ({ sending, onClick, count }) => (
  <Button
    data-cy="btn-done-activity"
    type="primary"
    disabled={!count}
    className={styles.submitBtn}
    onClick={onClick}
    loading={sending}
    icon={!!count && <div className={styles.submitCountIcon}>{count}</div>}
  >
    Done
  </Button>
);

const AddActivityDrawer = ({ className, visible, onClose }) => {
  const { reportId } = useParams();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState({});

  const [addActivitiesResponse, addActivities] = useMutation(
    createActivityReportMutation
  );

  const onSubmit = async () => {
    try {
      const response = await addActivities({
        objects: selectedActivities.map((activityId) => {
          const activityCodes = selectedCodes[activityId] || [];
          return {
            activity_id: activityId,
            report_id: reportId,
            activities_reports_codes: {
              data: activityCodes.map((codeId) => ({
                code_id: codeId,
              })),
            },
          };
        }),
      });
      if (response.error) {
        throw new Error("Add activities error");
      }
    } catch (err) {
      notification.error({
        message: "Error add activities",
        description: "Please try again later.",
      });
    }
    onClose();
  };

  const onCloseSafe = () => {
    if (selectedActivities.length) {
      Modal.confirm({
        title: "Unsaved changes",
        cancelText: "Cancel",
        okText: "Discard",
        content: "You have added activities. Do you want to discard them?",
        onOk: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!visible) {
      setSelectedActivities([]);
      setSelectedCodes({});
    }
  }, [visible]);

  return (
    <Drawer
      data-cy="drawer-add-activity"
      className={cs(className, styles.drawer)}
      width={580}
      mask={false}
      visible={visible}
      title={<Text className={styles.drawer__title}>Add new activities</Text>}
      placement="right"
      footer={
        <Row justify="end">
          <OkButton
            sending={addActivitiesResponse.fetching}
            onClick={onSubmit}
            count={selectedActivities.length}
          />
        </Row>
      }
      closeIcon={<CloseCircleOutlined />}
      destroyOnClose
      onClose={onCloseSafe}
    >
      <AddActivityDrawerContentConnected
        onComplete={onClose}
        onChangeSelectedActivities={setSelectedActivities}
        onChangeSelectedCodes={setSelectedCodes}
        selectedActivities={selectedActivities}
        selectedCodes={selectedCodes}
      />
    </Drawer>
  );
};

export default AddActivityDrawer;
