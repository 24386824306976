import React from "react";
import { oneOf, object } from "prop-types";
import BondCard from "./BondCard";
import CardbonCreditCard from "./CardbonCreditCard";
import ProjectCard from "./ProjectCard";

const CatalogCardSwitcher = ({ type, data, ...layoutProps }) => {
  const ComponentByType = {
    debt: BondCard,
    commodity: CardbonCreditCard,
    project: ProjectCard,
  }[type];
  if (!ComponentByType) return null;

  return <ComponentByType data={data} {...layoutProps} type={type} />;
};

CatalogCardSwitcher.propTypes = {
  type: oneOf(["debt", "commodity", "project"]),
  data: object,
};

export default CatalogCardSwitcher;
