import React, { useMemo } from "react";
import { Collapse, Typography } from "antd";
import { Space } from "@antd-ovveride";
import groupBy from "lodash.groupby";
import styles from "./LayersControllersCollapse.module.less";
import MapLayer from "../MapLayer/MapLayer";

const { Title } = Typography;
const LayersControllersCollapse = ({
  layers,
  visibleLayers,
  onLayerChange,
  onLayerFiltersChange,
  selectedFilters,
}) => {
  const layersByGroup = useMemo(
    () =>
      Object.entries(groupBy(layers, (l) => l.group.id)).map(
        ([, groupLayers]) => ({
          layers: groupLayers,
          group: groupLayers[0].group,
        })
      ),
    [layers]
  );

  return (
    <Collapse
      className={styles.collapseContainer}
      ghost
      defaultActiveKey={layersByGroup.map((lg) => lg.group.id)}
    >
      {layersByGroup.map(({ layers: layersGroup, group }) => (
        <Collapse.Panel
          header={
            <Title
              level={4}
              style={{ marginBottom: 0 }}
              className={styles.panelTitle}
            >
              {group.label}
            </Title>
          }
          key={group.id}
          className={styles.panel}
        >
          <Space block direction="vertical" size={20}>
            {layersGroup.map((layer) => (
              <MapLayer
                key={layer.id}
                layer={layer}
                onChange={onLayerChange}
                checked={visibleLayers.includes(layer.id)}
                onFiltersChange={(values) =>
                  onLayerFiltersChange(layer.id, values)
                }
                selectedFilters={
                  selectedFilters.find((sf) => sf.layerId === layer.id)?.values
                }
              />
            ))}
          </Space>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default LayersControllersCollapse;
