import React from "react";
import { useOutletContext } from "react-router-dom";
import FrameworkUpdates from "./FrameworkUpdates";

const FrameworkUpdatesConnected = () => {
  const props = useOutletContext();
  return <FrameworkUpdates {...props} />;
};

export default FrameworkUpdatesConnected;
