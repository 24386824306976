import React, { useEffect } from "react";
import { Form, Input } from "antd";
import RangeFilter from "./filters/RangeFilter";
import SelectFilter from "./filters/SelectFilter";
import CheckboxGroupFilter from "./filters/CheckboxGroupFilter";

const FilterSwitcher = ({ filter, value, onChange }) => {
  switch (filter.type) {
    case "range":
      return <RangeFilter filter={filter} value={value} onChange={onChange} />;
    case "select":
      return <SelectFilter filter={filter} value={value} onChange={onChange} />;
    case "checkboxGroupRange":
      return (
        <CheckboxGroupFilter
          filter={filter}
          value={value}
          onChange={onChange}
        />
      );

    default:
      return <Input />;
  }
};

const MapLayerFilters = ({ filters, onChange, selectedFilters }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(selectedFilters);
  }, [selectedFilters, form]);
  return (
    <Form
      layout="vertical"
      size="small"
      form={form}
      onValuesChange={(_, allValues) => onChange(allValues)}
      initialValues={selectedFilters}
    >
      {filters.map((filter) => (
        <Form.Item
          noStyle
          key={filter.mapboxFieldKey}
          name={filter.mapboxFieldKey}
        >
          <FilterSwitcher filter={filter} />
        </Form.Item>
      ))}
    </Form>
  );
};

export default MapLayerFilters;
