import React from "react";
import { useParams } from "react-router-dom";
import MSSPage from "./MSSPage";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const MSSPageConnected = () => {
  const { reportId } = useParams();
  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_REPORT,
    entityId: reportId,
    group: MESSENGER_GROUPS.TAXONOMY_REPORT.MSS,
  });
  return <MSSPage threads={threadsResponse.data} />;
};

export default MSSPageConnected;
