import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ProjectIdeaNoteDetails from "./ProjectIdeaNoteDetails";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const ProjectIdeaNoteDetailsConnected = () => {
  const { openDiscussionDrawer } = useOutletContext();
  const { pinId } = useParams();

  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.PIN,
    entityId: pinId,
    group: MESSENGER_GROUPS.PIN.DETAILS,
  });

  if (threadsResponse.fetching) {
    return <LoadingSpinner size="large" full />;
  }

  if (threadsResponse.error) {
    return <Error404 />;
  }

  return (
    <ProjectIdeaNoteDetails
      onOpenDiscussionsDrawer={openDiscussionDrawer}
      threads={threadsResponse.data}
    />
  );
};

export default ProjectIdeaNoteDetailsConnected;
