import React from "react";
import {
  convertDefToComponents,
  convertTextToDefinition,
} from "../../helpers/pointToComponents";
import { Paragraph } from "../../antd-ovveride";
import styles from "./DescriptionPoint.module.less";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const DescriptionPoint = ({ point, ellipsis, onExpand, showSuffix }) => {
  let transformedText = point.text;

  if (point?.point_links?.length) {
    transformedText = convertTextToDefinition(
      point.point_links,
      transformedText,
      "link"
    );
  }

  if (point?.point_hints?.length) {
    transformedText = convertTextToDefinition(
      point.point_hints,
      transformedText,
      "hint"
    );
  }

  const textComponents = convertDefToComponents(transformedText);
  return (
    <>
      <Paragraph
        size="large"
        ellipsis={
          ellipsis && {
            rows: 5,
            suffix: showSuffix && (
              <span
                tabIndex="-1"
                role="button"
                onKeyDown={onKeyDownDecorator(onExpand)}
                className="ant-typography-expand"
                onClick={onExpand}
              >
                Read more.
              </span>
            ),
            expandable: !showSuffix,
            onExpand,
            symbol: <span>Read more.</span>,
          }
        }
      >
        {textComponents}
      </Paragraph>
      {!ellipsis && point.nested_points?.length > 0 && (
        <div className={styles.children}>
          <>
            {point.nested_points?.map((subpoint) => (
              <DescriptionPoint point={subpoint} key={subpoint.id} />
            ))}
          </>
        </div>
      )}
    </>
  );
};

export default DescriptionPoint;
