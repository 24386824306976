import reportCurrencyParameters from "../../../../data/reportCurrencyParameters";
import { activitiesColorsList, grayColor } from "../data/colorsList";

export const getActReportAlignmentCurrencyParams = (actReport, paramName) => {
  const alignmentKey = `${paramName}_alignment`;
  const { [paramName]: reportTotalCurrency, currency } = actReport.report;
  const { [alignmentKey]: actReportAlignmentCurrency } = actReport;

  return {
    proportion: actReportAlignmentCurrency / reportTotalCurrency,
    currencyValue: actReportAlignmentCurrency,
    currency,
  };
};

export const getActReportEligibilityCurrencyParams = (actReport, paramName) => {
  const { [paramName]: reportTotalCurrency, currency } = actReport.report;
  const { [paramName]: actReportEligibilityCurrency } = actReport;

  return {
    proportion: actReportEligibilityCurrency / reportTotalCurrency,
    currencyValue: actReportEligibilityCurrency,
    currency,
  };
};

export const getActReportCurrencyParams = (
  actReport,
  paramName,
  showAligned
) => {
  if (showAligned) {
    return getActReportAlignmentCurrencyParams(actReport, paramName);
  }
  return getActReportEligibilityCurrencyParams(actReport, paramName);
};

export const getReportRestCurrencyParams = (report, paramName, showAligned) => {
  const reportKey = showAligned
    ? `${paramName}_alignment`
    : `${paramName}_eligibility`;

  const {
    [reportKey]: totalUsedCurrnencyPart,
    [paramName]: totalReportCurrency,
    currency,
  } = report;

  return {
    proportion: 1 - totalUsedCurrnencyPart / totalReportCurrency,
    currencyValue: totalReportCurrency - totalUsedCurrnencyPart,
    currency,
  };
};

export const checkAlignedActReport = (actReport, finParams) =>
  finParams.some((paramName) => actReport[`${paramName}_alignment`] > 0);

export const checkEligibleActReport = (actReport, finParams) =>
  finParams.some((paramName) => actReport[paramName] > 0);

export const checkActReportHasFinParamValue = (
  actReport,
  finParams,
  showAligned
) => {
  const checkMethod = showAligned
    ? checkAlignedActReport
    : checkEligibleActReport;
  return checkMethod(actReport, finParams);
};

export const actReportsToChartItems = (actReports, showAligned) => {
  const filteredActReports = actReports.filter((actReport) =>
    checkEligibleActReport(actReport, Object.keys(reportCurrencyParameters))
  );

  const actReportPieces = filteredActReports.map((actReport, index) => ({
    actReport,
    report: actReport.report,
    name: actReport.activity.name,
    color: activitiesColorsList[index % activitiesColorsList.length],
    problems: actReport.problems,
  }));

  const restPiece = {
    name: showAligned ? "non-aligned" : "non-eligible",
    isFake: true,
    color: grayColor,
    report: actReports[0].report,
  };

  return [...actReportPieces, restPiece];
};

export const addCurrencyToChartItems = (chartItems, paramName, showAligned) =>
  chartItems.map((item) => {
    let currencyInfo;
    if (item.isFake) {
      currencyInfo = getReportRestCurrencyParams(
        item.report,
        paramName,
        showAligned
      );
    } else {
      currencyInfo = getActReportCurrencyParams(
        item.actReport,
        paramName,
        showAligned
      );
    }
    return {
      ...item,
      ...currencyInfo,
    };
  });

export const getChartData = (actReports, finParamNames, showAligned) => {
  const commonChartItems = actReportsToChartItems(actReports, showAligned);
  return finParamNames.map((paramName) => {
    const displayName = reportCurrencyParameters[paramName];
    const singlePieChartItemsWithCurrency = addCurrencyToChartItems(
      commonChartItems,
      paramName,
      showAligned
    );

    return {
      name: displayName,
      values: singlePieChartItemsWithCurrency,
    };
  });
};

export const filterChartItemsByFinParams = (
  chartItems,
  finParams,
  showAligned
) =>
  chartItems.filter((item) => {
    if (item.isFake) return true;
    return checkActReportHasFinParamValue(
      item.actReport,
      finParams,
      showAligned
    );
  });

export const getLegend = (actReports, finParamNames, showAligned) => {
  const commonChartItems = actReportsToChartItems(actReports, showAligned);

  const filteredChartItems = filterChartItemsByFinParams(
    commonChartItems,
    finParamNames,
    showAligned
  );
  let resultedChartItems;
  if (finParamNames.length === 1) {
    resultedChartItems = addCurrencyToChartItems(
      filteredChartItems,
      finParamNames[0],
      showAligned
    );
  } else {
    resultedChartItems = filteredChartItems;
  }

  const legendWithProblems = resultedChartItems.map((item) => {
    const problems = item.isFake ? [] : item.problems || [];
    return {
      ...item,
      problems,
    };
  });

  return legendWithProblems;
};
