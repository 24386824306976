import React, { useState } from "react";
import DropFileZone from "./DropFileZone";
import { FILE_ERRORS, FILE_ERRORS_MESSAGES, FILE_TYPES } from "./types";

const formatDropzoneFile = (file) => {
  const status = file.status || "error";
  let error;
  if (status === "error") {
    const errorType =
      file.status === "error" ? FILE_ERRORS.NETWORK : FILE_ERRORS.CLIENT;
    error = {
      type: errorType,
      message: FILE_ERRORS_MESSAGES[errorType],
    };
  }
  return {
    uid: file.uid,
    data: {
      docId: file.data?.docId,
      url: file.data?.url,
      name: file.data?.name || file.name,
    },
    status,
    type: FILE_TYPES.FILE,
    error,
  };
};

const DropFileZoneConnected = ({ onChange }) => {
  const [dropzoneFiles, setDropzoneFiles] = useState([]);

  const onFileChange = (changedFile, uploadedFiles) => {
    setDropzoneFiles(uploadedFiles);
    const changedFormattedFile = formatDropzoneFile(changedFile);
    onChange(changedFormattedFile);
  };

  // useEffect(() => {
  //   const fileDocs = docs.filter((file) => file.type === FILE_TYPES.FILE);
  //   setDropzoneFiles((currentFiles) => {
  //     const filteredFiles = currentFiles.filter(
  //       (f) => !!fileDocs.find((formattedFile) => formattedFile.uid === f.uid)
  //     );
  //     return isEqual(filteredFiles, currentFiles)
  //       ? currentFiles
  //       : filteredFiles;
  //   });
  // }, [docs]);

  return <DropFileZone onChange={onFileChange} fileList={dropzoneFiles} />;
};

export default DropFileZoneConnected;
