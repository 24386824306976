import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import EntryModal from "../EntryModal/EntryModal";

const Taxonomy = () => {
  const entryModalVisibleByDefault = !localStorage.getItem("entryModalClosed");
  const [entryModalVisible, setEntryModalVisible] = useState(
    entryModalVisibleByDefault
  );

  const hideEntryModal = () => {
    setEntryModalVisible(false);
    localStorage.setItem("entryModalClosed", true);
  };
  return (
    <>
      <EntryModal visible={entryModalVisible} onHide={hideEntryModal} />
      <Outlet />
    </>
  );
};

export default Taxonomy;
