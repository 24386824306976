import React from "react";
import { Tabs } from "antd";
import styles from "./ProductTabSwitcher.module.less";

const { TabPane } = Tabs;

const ProductTabSwitcher = ({ tabs, activeTabKey, onTabChange }) => (
  <Tabs
    activeKey={activeTabKey}
    onChange={onTabChange}
    className={styles.tabsBar}
  >
    {tabs.map((tab) => (
      <TabPane
        tab={
          <span>
            {tab.icon}
            {tab.title}
          </span>
        }
        key={tab.title}
      >
        {tab.component}
      </TabPane>
    ))}
  </Tabs>
);
export default ProductTabSwitcher;
