import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import styles from "./ReportPdfCharts.module.less";
import CompanyLevelCharts from "./CompanyLevelCharts";
import KPILevelCharts from "./KPILevelCharts";

const ChartsPages = {
  1: CompanyLevelCharts,
  2: KPILevelCharts,
};

const ReportPdfCharts = ({ paginationIndex, paginationTotal, ...props }) => (
  <div className={styles.page}>
    <p className={styles.pagination}>
      {paginationIndex}/{paginationTotal}
    </p>
    <div className={styles.pageTitleWrapper}>
      <div className={styles.logo}>
        <LogoIcon />
      </div>
    </div>
    {ChartsPages[paginationIndex]({
      paginationIndex,
      paginationTotal,
      ...props,
    })}
  </div>
);
export default ReportPdfCharts;
