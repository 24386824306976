import React, { useState, useRef, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { useMutation } from "urql";

import useStepsNavigation from "../../hooks/useStepsNavigation";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import UnitNotAlignedModalDNSH from "../NotAlignedModal/UnitNotAlignedModalDNSH";
import UnitAlignedModalDNSH from "../AlignedModal/UnitAlignedModalDNSH";
import Error404 from "../errors/Error404";
import { validateUnitPointsMutation } from "../../api/mutations";
import LkLayout from "../LkLayout/LkLayout";
import UnitFlowHeaderConnected from "./UnitFlowHeaderConnected";
import DiscussionDrawer from "../DiscussionDrawer/DiscussionDrawer";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const steps = [
  {
    id: 0,
    title: (isSubunit) => (isSubunit ? "Subunit info" : "Unit info"),
    route: "",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_UNIT.INFO,
  },
  {
    id: 1,
    title: () => "Substantial Contribution",
    route: "substantial_contribution",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_UNIT.SUBSTANTIAL_CONTRIBUTION,
  },
  {
    id: 2,
    title: () => "DNSH",
    route: "dnsh",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_UNIT.DNSH,
  },
];

const UnitFlow = ({ actReport, unit, openedDiscussionThread }) => {
  const navigate = useNavigate();
  const stepContentContainerRef = useRef();
  const { activityReportId, reportId, activityId, unitParentId, unitId } =
    useParams();

  const isNewUnit = unitId === "_";
  const isSubunit = unitParentId !== "_";

  const [resultModal, setResultModal] = useState(false);

  const [stepIndex, setStepIndex, contentLoading] = useStepsNavigation(
    steps,
    isNewUnit,
    "company/taxonomy/unit/:reportId/:activityReportId/:activityId/:unitParentId"
  );
  const [validateUnitPointsResponse, validateUnitPoints] = useMutation(
    validateUnitPointsMutation
  );

  const sending = validateUnitPointsResponse.fetching;

  const nextStep = () => {
    setStepIndex((i) => Math.min(i + 1, steps.length - 1));
  };
  const prevStep = () => setStepIndex((i) => Math.max(i - 1, 0));

  const onFlowEnd = async () => {
    const response = await validateUnitPoints({ unitId });
    setResultModal(response.data?.validate_unit_points?.status);
  };

  const [, setParams] = useSearchParams();

  const flowRef = useRef();

  const containerRef = useRef();

  const [drawerState, setDrawerState] = useState(null);

  const onChangeDrawerState = ({ currentTopic, currentProperty }) => {
    setDrawerState({
      ...drawerState,
      property: currentProperty,
      topic: currentTopic,
      trigger: {
        source: "drawer",
      },
    });
    setParams({});
  };

  const openDrawer = ({ status, group, property, topic, trigger }) => {
    setDrawerState({
      status,
      group,
      property,
      topic,
      entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
      entityId: unitId,
      trigger,
    });
  };

  const closeDrawer = () => {
    setDrawerState(null);
    setParams({});
  };

  const isOpenDrawer = !!drawerState;

  useEffect(() => {
    if (isOpenDrawer) {
      setDrawerState((config) => {
        let property = config?.property;

        if (config?.group !== steps[stepIndex]?.threadGroup) {
          property = null;
        }

        return {
          ...config,
          group: steps[stepIndex]?.threadGroup,
          property,
        };
      });
    }
  }, [stepIndex, isOpenDrawer]);

  useEffect(() => {
    if (openedDiscussionThread) {
      setDrawerState({
        threadId: openedDiscussionThread.id,
        topic: openedDiscussionThread.topic,
        ...openedDiscussionThread.config,
        status: openedDiscussionThread.thread_state.name,
        trigger: {
          source: "link",
        },
      });
    }
  }, [openedDiscussionThread]);

  const onClickTopic = (event, { topic, property }) => {
    setDrawerState({
      ...drawerState,
      topic,
      property,
      trigger: {
        event,
        source: "drawer",
      },
    });
  };

  const onFlowCancel = () =>
    navigate(`/company/taxonomy/report/${actReport.report_id}/activities`);

  const notAlignedModalMethods = {
    onSave: () => {
      navigate(`/company/taxonomy/report/${actReport.report_id}/activities`);
    },
    onRetry: () => {
      setResultModal(false);
      stepContentContainerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    onAddSubunit: () => {
      setResultModal(false);
      navigate(
        `/company/taxonomy/unit/${reportId}/${activityReportId}/${activityId}/${unitId}/_`
      );
    },
  };

  const alignedModalMethods = {
    onOk: () => {
      setResultModal(false);
      navigate(`/company/taxonomy/report/${actReport.report_id}/activities`);
    },
  };

  if (stepIndex === -1) {
    return <Error404 />;
  }

  return (
    <LkLayout
      header={{
        content: <UnitFlowHeaderConnected />,
      }}
    >
      <div ref={stepContentContainerRef}>
        <>
          {contentLoading ? (
            <LoadingSpinner size="large" full />
          ) : (
            <div ref={containerRef}>
              <DiscussionDrawer
                onClickTopic={onClickTopic}
                flowRef={flowRef}
                onClose={closeDrawer}
                config={drawerState}
                onChangeDrawerState={onChangeDrawerState}
              />
              <div ref={flowRef}>
                <Outlet
                  context={{
                    onFinish:
                      stepIndex === steps.length - 1 ? onFlowEnd : nextStep,
                    onPrevStep: stepIndex === 0 ? onFlowCancel : prevStep,
                    sending,
                    unit,
                    actReport,
                    onOpenDiscussionsDrawer: openDrawer,
                    discussionDrawerState: drawerState,
                  }}
                />
              </div>
            </div>
          )}
        </>
        <UnitNotAlignedModalDNSH
          {...notAlignedModalMethods}
          visible={resultModal === "not_aligned"}
          isSubunit={isSubunit}
        />
        <UnitAlignedModalDNSH
          {...alignedModalMethods}
          isSubunit={isSubunit}
          visible={resultModal === "aligned"}
        />
      </div>
    </LkLayout>
  );
};

export default UnitFlow;
