// eslint-disable-next-line import/prefer-default-export
export const formatActivitiesResponse = (activities) =>
  activities?.map((activity) => ({
    id: activity.id,
    name: activity.name,
    category: {
      id: activity.category.id,
      name: activity.category.name,
    },
    available: activity.available,
    codes: activity.activities_codes.map((code) => ({
      code: code.nace_code.code,
      id: code.nace_code.id,
      type: code.nace_code.type,
      sbi: code.nace_code.sbi_code,
      description: code.nace_code.description,
      parentId: code.nace_code.parent_id,
    })),
    tags: ["Mitigation"],
    description: activity.brief_description,
  }));
