import React from "react";
import cs from "classnames";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./SearchInput.module.less";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross-circle-filled.svg";

const SearchInput = ({ className, value, onChange, ...rest }) => (
  <Input
    {...rest}
    prefix={<SearchOutlined />}
    className={cs(
      styles.searchInput,
      !!value && styles[`searchInput--filled`],
      className
    )}
    bordered={false}
    onChange={(e) => onChange?.(e.target.value)}
    value={value}
    suffix={
      <CrossIcon
        className={cs(
          styles.closeIcon,
          !!value.length && styles[`closeIcon--active`]
        )}
        onClick={() => onChange?.("")}
      />
    }
  />
);

export default SearchInput;
