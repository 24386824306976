import React from "react";
import { useGetFramework, useUpdateFramework } from "@hooks/framework";
import FrameworkFlow from "./FrameworkFlow";
import useStepsNavigation from "../../hooks/useStepsNavigation";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import useGenerateFramework from "../../hooks/framework/useGenerateFramework";

const steps = [
  {
    id: 0,
    title: "Introduction",
    route: "",
  },
  {
    id: 1,
    title: "Use of proceeds",
    route: "proceeds",
  },
  {
    id: 2,
    title: "Process for project evaluation and selection",
    route: "process",
  },
  {
    id: 3,
    title: "Management of proceeds",
    route: "managementOfProceeds",
  },
  {
    id: 4,
    title: "Reporting",
    route: "reporting",
  },
  {
    id: 5,
    title: "External review",
    route: "externalReview",
  },
  {
    id: 6,
    title: "Framework updates",
    route: "frameworkUpdates",
  },
];

const FrameworkFlowConnected = () => {
  const { framework, progress, fetching } = useGetFramework();
  const { onGenerate } = useGenerateFramework();
  const { updateFramework } = useUpdateFramework();
  const [stepIndex, setStepIndex, contentLoading] = useStepsNavigation(
    steps,
    false,
    "company/frameworks"
  );

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }

  return (
    <FrameworkFlow
      framework={framework}
      contentLoading={contentLoading}
      steps={steps}
      progress={progress}
      stepIndex={stepIndex}
      setStepIndex={setStepIndex}
      onGenerate={() => onGenerate(framework.id)}
      updateFramework={updateFramework}
    />
  );
};

export default FrameworkFlowConnected;
