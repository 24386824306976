import React from "react";
import { Modal, Typography } from "antd";
import cs from "classnames";
import { Text } from "@antd-ovveride";
import { ReactComponent as ErrorCircleCross } from "../../assets/icons/error-circle-cross.svg";

import styles from "./NotAlignedModal.module.less";

const { Title } = Typography;

const NotAlignedModal = ({
  isSubunit,
  wrapClassName,
  title,
  content,
  footer,
  ...rest
}) => (
  <Modal
    {...rest}
    wrapClassName={cs(styles.container, wrapClassName)}
    title={
      <div className={styles.headerContent}>
        <ErrorCircleCross />
        <Title level={2} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </div>
    }
    closable={false}
    footer={footer}
  >
    <Text className={styles.message} size="large">
      {content}
    </Text>
  </Modal>
);

export default NotAlignedModal;
