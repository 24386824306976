import React, { useState, useEffect, useRef } from "react";
import { Layout, Button } from "antd";
import { LeftOutlined, MenuOutlined } from "@ant-design/icons";
import cs from "classnames";
import { useResponsive } from "ahooks";
import { useNavigate } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import Map from "../Map/Map";
import styles from "./MapPage.module.less";
import AppHeader from "../AppHeader/AppHeader";
import MapAside from "../MapAside/MapAside";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";
import MapLegend from "../MapLegend/MapLegend";
import MapButton from "../Map/MapButton";

const { Sider, Content, Header } = Layout;

const initialMapViewport = {
  zoom: 3.85,
  longitude: 13.118,
  latitude: 49.015,
};

// TODO: сделать редьюсер
const MapPage = () => {
  const { md } = useResponsive();
  const [mapInstance, setMapInstance] = useState();
  const [selectedLayers, setSelectedLayers] = useState();
  const [collapsed, setCollapsed] = useState(!md);
  const navigate = useNavigate();
  const [activeViewport, setActiveViewport] = useUrlState(initialMapViewport, {
    navigateMode: "replace",
  });
  const viewportTimerRef = useRef();
  useEffect(() => {
    setCollapsed(!md);

    if (md) {
      document.body.classList.remove("-overflow");
      document.documentElement.classList.remove("-overflow");
    } else {
      document.body.classList.add("-overflow");
      document.documentElement.classList.add("-overflow");
    }
    return () => {
      document.body.classList.remove("-overflow");
      document.documentElement.classList.remove("-overflow");
    };
  }, [md]);

  const onViewportChange = ({ longitude, latitude, zoom }) => {
    clearTimeout(viewportTimerRef.current);
    viewportTimerRef.current = setTimeout(() => {
      setActiveViewport?.({
        longitude: longitude.toFixed(3),
        latitude: latitude.toFixed(3),
        zoom: zoom.toFixed(3),
      });
    }, 500);
  };

  const toggleCollapse = () => setCollapsed((c) => !c);

  const siderOpenTriggerBtn = (
    <MapButton
      icon={<MenuOutlined style={{ fontSize: 20 }} />}
      onClick={() => setCollapsed(false)}
    />
  );
  const backBtn = (
    <MapButton
      onClick={() => navigate(-1)}
      icon={<LeftOutlined style={{ fontSize: 16 }} />}
    />
  );
  return (
    <Layout className={styles.container}>
      <Header className={styles.header}>
        <AppHeader />
      </Header>
      <Layout className={styles.contentContainer}>
        <Sider
          className={styles.sider}
          width={280}
          collapsible
          collapsedWidth={0}
          collapsed={collapsed}
        >
          <MapAside
            mapInstance={mapInstance}
            width={280}
            onSelectedLayersChange={setSelectedLayers}
            onClose={() => setCollapsed(true)}
          />
          <div
            className={cs(
              styles.siderCollapseTrigger,
              collapsed && styles["siderCollapseTrigger--collapsed"]
            )}
            onClick={toggleCollapse}
            role="button"
            tabIndex="-1"
            onKeyDown={onKeyDownDecorator(toggleCollapse)}
          >
            <LeftOutlined />
          </div>
        </Sider>
        <Content className={styles.content}>
          <div className={styles.map}>
            <Map
              onMapLoad={setMapInstance}
              initialZoom={parseFloat(activeViewport.zoom, 10)}
              initialLng={parseFloat(activeViewport.longitude, 10)}
              initialLat={parseFloat(activeViewport.latitude, 10)}
              onViewportChange={onViewportChange}
              topRightControl={!md && siderOpenTriggerBtn}
              topLeftControl={!md && backBtn}
              interactive
              legend={
                !!selectedLayers?.length && (
                  <MapLegend
                    layersIds={selectedLayers}
                    mapInstance={mapInstance}
                  />
                )
              }
            />
            <div className={styles.mobileSiderOpenTrigger}>
              <Button
                icon={<MenuOutlined />}
                onClick={() => setCollapsed(false)}
                size="large"
                className={styles.iconButton}
              />
            </div>
            <div className={styles.mobileBackBtn}>
              <Button
                size="large"
                onClick={() => navigate(-1)}
                icon={<LeftOutlined />}
                className={styles.iconButton}
              />
            </div>
          </div>
          {/* {!!selectedLayers?.length && !projectSelected && (
            <div className={styles.legend} />
          )} */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MapPage;
