import React, { useMemo, useState } from "react";
import { Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "urql";

import ReportsTable from "./ReportsTable";
import modalStyles from "./modal.module.less";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  deleteReportMutation,
  generateReportPdf,
  generateReportXls,
  generateChartsReportPdf,
} from "../../api/mutations";
import useGetUsers from "../../hooks/user/useGetUsers";
import useAssignManager from "../../hooks/taxonomy/useAssignManager";
import { useCurrentUser } from "../../hooks/user";
import { getReportsQuery } from "../../api/queries";
import useSampleReport from "../../hooks/taxonomy/useSampleReport";
import useReportsWithIssues from "./useReportsWithIssues";

const ReportsTableConnected = () => {
  const [loadingReports, setLoadingReports] = useState([]);
  const { users: managers } = useGetUsers();
  const { assignManager } = useAssignManager();

  const { sampleReport, fetching: loadingSampleReport } = useSampleReport();
  const [currentUserState] = useCurrentUser();
  const { company } = currentUserState.data;
  const variables = {
    where: {
      status: {
        _neq: "sample",
      },
      _or: [
        {
          company_id: {
            _eq: company?.id,
          },
        },
      ],
    },
  };

  if (company?.companyConsultantsByConsultantId?.length > 0) {
    // eslint-disable-next-line no-underscore-dangle
    variables.where._or.push({
      company: {
        id: {
          _in: company?.companyConsultantsByConsultantId.map(
            (record) => record.client_id
          ),
        },
      },
    });
  }
  const [reportsResponse] = useQuery({
    query: getReportsQuery,
    pause: !company,
    variables,
  });

  const reports = useMemo(
    () => reportsResponse.data?.eu_taxonomy_reports,
    [reportsResponse.data]
  );

  const reportsWithIssues = useReportsWithIssues(reports);

  const [, deleteReport] = useMutation(deleteReportMutation);
  const onDeleteReport = (report) => {
    Modal.confirm({
      title: "Delete report",
      icon: <ExclamationCircleOutlined />,
      className: modalStyles.container,
      content:
        "You’re going to delete the report, this action cannot be canceled. Do you want to continue?",
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      onOk: (close) => {
        deleteReport({ id: report.id }).then(() => {
          close();
        });
      },
    });
  };

  const [, generatePdf] = useMutation(generateReportPdf);
  const [, generateXls] = useMutation(generateReportXls);
  const [, generateCharts] = useMutation(generateChartsReportPdf);

  const generatePdfHandler = async (report) => {
    setLoadingReports((ids) => [...ids, report.id]);
    try {
      const res = await generatePdf({ reportId: report.id });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.generate_report_pdf.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate PDF",
        description: "Please try again later.",
      });
    }
    setLoadingReports((ids) => ids.filter((id) => id !== report.id));
  };

  const generateExcelHandler = async (report, locale) => {
    setLoadingReports((ids) => [...ids, report.id]);
    try {
      const res = await generateXls({ reportId: report.id, locale });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.generate_report_xls.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate XLS",
        description: "Please try again later.",
      });
    }
    setLoadingReports((ids) => ids.filter((id) => id !== report.id));
  };

  const generateChartsHandler = async (report) => {
    setLoadingReports((ids) => [...ids, report.id]);
    try {
      const res = await generateCharts({ reportId: report.id });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.genReportPDFCharts.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate PDF Charts",
        description: "Please try again later.",
      });
    } finally {
      setLoadingReports((ids) => ids.filter((id) => id !== report.id));
    }
  };

  const fetching = loadingSampleReport || reportsResponse.fetching;
  if (fetching) {
    return <LoadingSpinner />;
  }

  return (
    <ReportsTable
      company={company}
      selectManager={assignManager}
      managers={managers}
      reports={reportsWithIssues}
      loadingReports={loadingReports}
      onDeleteRow={onDeleteReport}
      onDownloadPdf={generatePdfHandler}
      onDownloadExcel={generateExcelHandler}
      onDownloadCharts={generateChartsHandler}
      sampleReport={sampleReport}
    />
  );
};

export default ReportsTableConnected;
