import React from "react";
import ActionsDropdown from "../../ActionsDropdown/ActionsDropdown";
import DotsActionsIcon from "../../ActivitiesTableOld/DotsActionsIcon/DotsActionsIcon";

const ActionsCell = ({ actions }) => (
  <ActionsDropdown actions={actions}>
    <DotsActionsIcon data-cy="btn-show-actions" />
  </ActionsDropdown>
);

export default ActionsCell;
