import React from "react";
import { Space } from "@evercityecosystem/evercity-ui";
import { Link } from "../../antd-ovveride";
import styles from "./FinishStep.module.less";

const FinishStep = () => (
  <Space className={styles.goMain}>
    <Link type="round_button" to="/">
      Go to main page
    </Link>
  </Space>
);

export default FinishStep;
