import React from "react";
import { Steps } from "antd";
import { number } from "prop-types";

import styles from "./RoadMap.module.less";

const { Step } = Steps;

const RoadMap = ({ list, current }) => {
  if (list.length === 0) return null;
  return (
    <Steps
      progressDot
      current={current}
      direction="vertical"
      className={styles.roadMap}
    >
      {list.map(({ date, description, id }) => (
        <Step title={date} description={description} key={id} />
      ))}
    </Steps>
  );
};

RoadMap.propTypes = {
  current: number,
};

RoadMap.defaultProps = {
  current: null,
};
export default RoadMap;
