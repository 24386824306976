import React from "react";
import { Button, Skeleton } from "antd";
import { LeftCircleOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import styles from "./MapAside.module.less";
import LayersControllers from "./LayersControllers";
import { Space } from "../../antd-ovveride";

const MapAside = ({ mapInstance, width, onSelectedLayersChange, onClose }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.mapAside} style={{ width }}>
      <div>
        <Space block size={20} direction="vertical">
          <div className={styles.nav}>
            <Button
              type="text"
              icon={<LeftCircleOutlined />}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <div className={styles.mobileCloseBtn}>
              <Button type="text" onClick={onClose} icon={<CloseOutlined />} />
            </div>
          </div>

          {mapInstance ? (
            <LayersControllers
              mapInstance={mapInstance}
              onSelectedLayersChange={onSelectedLayersChange}
            />
          ) : (
            <Skeleton active />
          )}
        </Space>
      </div>
    </div>
  );
};

export default MapAside;
