import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Text } from "../../../antd-ovveride";
import styles from "./PointsLegend.module.less";

const LegendItem = ({ icon, color, value, description }) => (
  <Space block className={styles.pointsLegendItem} size={10}>
    <div className={styles.pointsLegendPoint} style={{ color }}>
      {!!icon && <div className={styles.pointsLegendIcon}>{icon}</div>}
    </div>
    <Text size="small">{value}</Text>
    {!!description && (
      <Tooltip title={description}>
        <QuestionCircleOutlined className={styles.infoIcon} />
      </Tooltip>
    )}
  </Space>
);

const PointsLenged = ({ layer }) => (
  <div className={styles.pointsLegend}>
    {layer.legend.options.map((option) => (
      <LegendItem
        icon={layer.legend.useLayerIcon && layer.icon}
        color={option.color}
        key={option.value}
        value={option.value}
        description={option.description}
      />
    ))}
  </div>
);

export default PointsLenged;
