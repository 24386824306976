import React from "react";
import style from "./Legend.module.less";

const Marker = ({ color }) => (
  <span className={style.marker} style={{ backgroundColor: color }} />
);

const Legend = ({ items }) => (
  <ul className={style.list}>
    {items.map((item) => (
      <li key={item.name} className={style.item}>
        <Marker color={item.color} />
        {item.name}
      </li>
    ))}
  </ul>
);

export default Legend;
