import React, { useState } from "react";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { Collapse } from "antd";
import cs from "classnames";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";
import styles from "./InlineNote.module.less";

const InlineNote = ({ children, bodyPaddingBottom, className, style }) => {
  const key = 1;
  const [activeKey, setActiveKey] = useState(key);
  return (
    <Collapse
      defaultActiveKey={key}
      activeKey={activeKey}
      ghost
      style={style}
      className={cs(styles.collapse, className)}
    >
      <Collapse.Panel key={key}>
        <div style={{ paddingBottom: bodyPaddingBottom }}>
          <div className={styles.container}>
            <div className={styles.icon}>
              <InfoCircleOutlined />
            </div>
            <Text className={styles.content}>{children}</Text>
            <div
              className={styles.close}
              onClick={() => setActiveKey(0)}
              tabIndex="0"
              role="button"
              onKeyDown={onKeyDownDecorator(() => setActiveKey(0))}
            >
              <CloseOutlined />
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default InlineNote;
