import React from "react";

// eslint-disable-next-line no-restricted-imports
import { Button, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { Upload, Space, Text } from "@antd-ovveride";
import { useMutation } from "urql";
import styles from "./DropFileZone.module.less";
import { uploadFileBase64 } from "../../api/mutations";

const { Dragger } = Upload;

const ACCEPTED_MIME_TYPES = [
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/pdf", // pdf
  "application/vnd.ms-excel", // xls
];
const MAX_FILE_SIZE_MB = 4;

function beforeUpload(file) {
  const correctFileType = ACCEPTED_MIME_TYPES.includes(file.type);
  if (!correctFileType) {
    message.error("Wrong file type, should be one of PDF, DOC, XLSX");
  }
  const correctFileSize = file.size / 1024 / 1024 < MAX_FILE_SIZE_MB;
  if (!correctFileSize) {
    message.error(`Wrong file size (max ${MAX_FILE_SIZE_MB}MB)`);
  }
  return correctFileType && correctFileSize;
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const matches = reader.result.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
      if (matches.length === 3) {
        resolve(matches[2]);
      }
      if (matches.length !== 3) {
        reject();
      }
    };
    reader.onerror = (error) => reject(error);
  });

const DropFileZone = ({ fileList = [], onChange }) => {
  const [, uploadFile] = useMutation(uploadFileBase64);
  const onFileFailed = ({ file, error }) => {
    const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
    if (fileIndex === -1) return;
    const fileInList = {
      ...fileList[fileIndex],
      error,
      status: "error",
    };
    onChange(fileInList, [
      ...fileList.slice(0, fileIndex),
      fileInList,
      ...fileList.slice(fileIndex + 1),
    ]);
  };
  const onFileLoadedHandler = ({ file, doc: { docId, url, name } }) => {
    const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
    if (fileIndex === -1) return;
    const fileInList = {
      ...fileList[fileIndex],
      data: {
        docId,
        url,
        name,
      },
      status: "done",
    };
    onChange(fileInList, [
      ...fileList.slice(0, fileIndex),
      fileInList,
      ...fileList.slice(fileIndex + 1),
    ]);
  };

  const onChangeHandler = ({ file, fileList: currentFileList }) => {
    onChange(file, [...currentFileList]);
  };

  const customRequest = async ({ onError, onSuccess, file }) => {
    try {
      const fileBase64 = await toBase64(file);
      const { data, error } = await uploadFile({
        base64str: fileBase64,
        docname: file.name,
        filename: file.name,
        mimetype: file.type,
      });
      if (error) {
        throw new Error(error);
      }
      onSuccess({
        file,
        doc: {
          docId: data.upload_file_base64.doc_id,
          url: data.upload_file_base64.url,
          name: file.name,
        },
      });
    } catch (error) {
      onError({ file, error });
    }
  };

  return (
    <div className={styles.container}>
      <Dragger
        accept={ACCEPTED_MIME_TYPES.join(",")}
        onChange={onChangeHandler}
        showUploadList={false}
        multiple
        maxCount={10}
        onSuccess={onFileLoadedHandler}
        onError={onFileFailed}
        customRequest={customRequest}
        fileList={fileList}
        beforeUpload={beforeUpload}
      >
        <Space direction="vertical" size={10}>
          <Text type="secondary">
            Drag and drop PDF, DOC, XLSX here <br /> or
          </Text>

          <Button icon={<PlusCircleOutlined />} size="large">
            Browse file
          </Button>
        </Space>
      </Dragger>
    </div>
  );
};

export default DropFileZone;
