import React from "react";
import { Text, Space } from "@antd-ovveride";
import AlignedStatus from "../AlignedStatus";

const UnitNameCell = ({ value }) => (
  <Space direction="vertical" size={0}>
    <div>
      <Text size="small" strong>
        {value.name}
      </Text>
      {value.aligned && <AlignedStatus />}
    </div>
    <Text size="small" type="secondary">
      {value.type}
    </Text>
  </Space>
);

export default UnitNameCell;
