import React, { useMemo } from "react";
import { Radio } from "antd";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import styles from "./SwitchAbility.module.less";

const { Group: RadioGroup, Button: RadioButton } = Radio;

const SwitchUnability = ({ defaultValue, onChange, children }) => {
  const value = useMemo(() => {
    if (typeof defaultValue === "boolean") {
      return defaultValue ? "No" : "Yes";
    }
  }, [defaultValue]);

  const handleChange = (ability) => {
    onChange(ability === "No");
  };

  return (
    <>
      <RadioGroup defaultValue={value} onChange={adapterDOMEvent(handleChange)}>
        <RadioButton value="Yes">Yes</RadioButton>
        <RadioButton value="No">No</RadioButton>
      </RadioGroup>
      {value === "Yes" && <div className={styles.content}>{children}</div>}
    </>
  );
};

export default SwitchUnability;
