import React from "react";
import cs from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import styles from "./LoadingSpinner.module.less";

const LoadingSpinner = ({ size, full, noStyle }) => (
  <div
    className={cs(
      styles.container,
      styles[`container--${size}`],
      full && styles["container--full"],
      noStyle && styles["container--noStyle"]
    )}
  >
    <div className={styles.spinnerWrapper}>
      <LoadingOutlined />
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(["large", "default", "small"]),
  full: PropTypes.bool,
  noStyle: PropTypes.bool,
};

export default LoadingSpinner;
