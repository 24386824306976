import { useMutation } from "urql";
import { generateInvitationLinkMutation } from "../../api/mutations";

const useGenerateInviteLink = (invType) => {
  const [response, exec] = useMutation(generateInvitationLinkMutation);
  const generate = () =>
    exec({
      invType,
    });

  return {
    generate,
    inviteLink: response.data?.generate_invitation_link,
  };
};

export default useGenerateInviteLink;
