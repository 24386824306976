import React, { useRef, useState } from "react";
import { Button } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";
import styles from "./WatchTutorialBtn.module.less";

import EntryModal from "../EntryModal/EntryModal";

const WatchTutorialBtn = ({ startTime = 0 }) => {
  const [entryModalVisible, setEntryModalVisible] = useState(false);
  const ref = useRef();
  const onClickHandler = () => {
    ref.current?.blur();
    setEntryModalVisible(true);
  };
  return (
    <>
      <Button
        onClick={onClickHandler}
        className={styles.btn}
        type="text"
        icon={<YoutubeOutlined />}
        ref={ref}
      >
        Watch tutorial
      </Button>
      <EntryModal
        visible={entryModalVisible}
        startTime={startTime}
        closable
        autoplay
        onHide={() => setEntryModalVisible(false)}
      />
    </>
  );
};

export default WatchTutorialBtn;
