import React from "react";
import classnames from "classnames";
import FrameworkTemplate from "./FrameworkTemplate";
import GreenBondPrinciples from "../../assets/frameworkTemplates/green-bond-principles.jpg";
import GreenLoanPrinciples from "../../assets/frameworkTemplates/green-loan-principles.jpg";
import ClimateBondsStandard from "../../assets/frameworkTemplates/climate-bonds-standard.jpg";
import ASEANGreenBondStandard from "../../assets/frameworkTemplates/ASEAN-green-bond-standard.jpg";
import EUGreenBondStandard from "../../assets/frameworkTemplates/EU-green-bond-standard.jpg";
import styles from "./FrameworkTemplates.module.less";

const templates = [
  {
    title: "Green Bond Principles",
    image: GreenBondPrinciples,
    lock: true,
  },
  {
    title: "Green Loan Principles",
    image: GreenLoanPrinciples,
    lock: true,
  },
  {
    title: "Climate Bonds Standard",
    image: ClimateBondsStandard,
    lock: false,
  },
  {
    title: "ASEAN Green Bond Standard",
    image: ASEANGreenBondStandard,
    lock: true,
  },
  {
    title: "EU Green Bond Standard",
    image: EUGreenBondStandard,
    lock: true,
  },
];

const FrameworkTemplatesConnected = ({ onSelect, className }) => {
  const selectTemplate = (template) => {
    if (onSelect && !template.lock) {
      onSelect(template.title);
    }
  };

  return (
    <ul className={classnames(styles.list, className)}>
      {templates.map((template) => (
        <li
          key={template.title}
          className={classnames(
            styles.list__item,
            template.lock && styles["list__item--notavailable"]
          )}
        >
          <button
            type="button"
            onClick={() => selectTemplate(template)}
            className={styles.item__btn}
          >
            <FrameworkTemplate
              title={template.title}
              image={template.image}
              lock={template.lock}
            />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FrameworkTemplatesConnected;
