import React from "react";
import { Space } from "antd";
import Tag from "./Tag";

const ListingTags = ({ assetTypes, categories, status }) => (
  <Space size={5} wrap>
    <Tag text={status} type="status" />
    {assetTypes?.map((assetType) => (
      <Tag type="assetType" text={assetType.text} key={assetType.text} />
    ))}
    {categories?.map((cateogry) => (
      <Tag type="category" text={cateogry.text} key={cateogry.text} />
    ))}
  </Space>
);

export default ListingTags;
