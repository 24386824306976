import React from "react";
import styles from "./CardHeader.module.less";
import ContentWrap from "./ContentWrap";
import SDGList from "../SDGList/SDGList";
import LazyImage from "../../antd-ovveride/Image/LazyImage";

const CardHeader = ({ image, sdgList, logo }) => (
  <div className={styles.cardHeader}>
    <LazyImage src={image} alt="" className={styles.image} />
    <ContentWrap>
      <div className={styles.footer}>
        <img src={logo} alt="" className={styles.logo} />
        {sdgList?.length > 0 && (
          <div className={styles.sdgsList}>
            <SDGList items={sdgList} />
          </div>
        )}
      </div>
    </ContentWrap>
  </div>
);

export default CardHeader;
