import React from "react";
import { NavLink as BaseLink } from "react-router-dom";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./Link.module.less";

const NavLink = React.forwardRef(
  (
    {
      noMargin,
      size,
      className,
      type = "default",
      activeStyle,
      activeClassName,
      ...linkProps
    },
    ref
  ) => (
    <BaseLink
      type={type}
      className={({ isActive }) =>
        isActive
          ? cs(
              className,
              noMargin && styles["link--noMargin"],
              styles[`link--size-${size}`],
              styles[`link--theme-${type}`],
              activeClassName
            )
          : cs(
              className,
              noMargin && styles["link--noMargin"],
              styles[`link--size-${size}`],
              styles[`link--theme-${type}`]
            )
      }
      style={({ isActive }) => ({
        ...linkProps.style,
        ...(isActive ? activeStyle : null),
      })}
      ref={ref}
      {...linkProps}
    />
  )
);

NavLink.propTypes = {
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf([
    "extra-large",
    "large",
    "default",
    "small",
    "extra-small",
  ]),
};

export default NavLink;
