import React, { useMemo, useState, useEffect } from "react";
import { PageHeader, Radio, Empty } from "antd";

import { useResponsive } from "ahooks";
import Filters from "./Filters";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./CatalogPage.module.less";
import CatalogTable from "../CatalogTable/CatalogTable";
import CatalogCardsList from "../CatalogCardsList/CatalogCardsList";
import LkLayout from "../LkLayout/LkLayout";

const CatalogDataView = ({ items, isCards, activeSectionType }) =>
  isCards ? (
    <>
      {items?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <CatalogCardsList items={items} type={activeSectionType} />
      )}
    </>
  ) : (
    <CatalogTable data={items} type={activeSectionType} />
  );

const CatalogPage = ({
  activeSectionValue,
  allFilters,
  allSections,
  cardsData,
  onSectionChange,
  onFiltersChange,
  selectedFiltersValues,
}) => {
  const { md } = useResponsive();
  const [view, setView] = useState(md ? "cards" : "table");

  useEffect(() => {
    if (!md) setView("cards");
  }, [md, setView]);

  const activeSection = useMemo(
    () => allSections.find((s) => s.value === activeSectionValue),
    [allSections, activeSectionValue]
  );

  return (
    <LkLayout>
      <>
        <div className={styles.catalogPage}>
          <PageHeader
            title={activeSection.label}
            className={styles.pageHeader}
            extra={
              <Radio.Group
                data-cy="sections-group"
                size="large"
                onChange={(e) => onSectionChange(e.target.value)}
                value={activeSection.value}
                optionType="button"
                buttonStyle="solid"
              >
                {allSections.map(({ value, label }) => (
                  <Radio.Button value={value} key={value}>
                    {label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            }
          />
          <Filters
            handleSetView={setView}
            activeView={view}
            allFilters={allFilters}
            selectedFiltersValues={selectedFiltersValues}
            onFiltersChange={onFiltersChange}
          />
          {cardsData.fetching || allFilters.fecthing ? (
            <LoadingSpinner />
          ) : (
            <CatalogDataView
              items={cardsData.data}
              isCards={view === "cards"}
              activeSectionType={activeSection.value}
            />
          )}
        </div>
      </>
    </LkLayout>
  );
};

export default CatalogPage;
