import React from "react";
import { Form, Button, Checkbox } from "antd";
import { Input, PasswordInput, Text } from "@antd-ovveride";
import {
  UserOutlined,
  BankOutlined,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";
import ExternalLink from "../../antd-ovveride/Link/ExternalLink";

const RequestAccessForm = ({ onSubmit, loading }) => (
  <Form onFinish={onSubmit} name="access" autoComplete="off">
    <Form.Item
      name="name"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input
        data-cy="application-name"
        prefix={
          <Text color="primary">
            <UserOutlined />
          </Text>
        }
        placeholder="Your name"
      />
    </Form.Item>
    <Form.Item
      name="company"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input
        data-cy="application-company"
        prefix={
          <Text color="primary">
            <BankOutlined />
          </Text>
        }
        placeholder="Organisation name"
      />
    </Form.Item>
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input
        data-cy="application-email"
        type="email"
        prefix={
          <Text color="primary">
            <MailOutlined />
          </Text>
        }
        placeholder="Work mail"
      />
    </Form.Item>
    <Form.Item
      data-cy="application-password"
      name="password"
      rules={[
        { required: true, message: "Required field" },
        { min: 8, message: "Must be at least 8 chars" },
      ]}
    >
      <PasswordInput
        prefix={
          <Text color="primary">
            <LockOutlined />
          </Text>
        }
        placeholder="Type password"
      />
    </Form.Item>
    <Form.Item
      name="confirm-password"
      dependencies={["password"]}
      rules={[
        { required: true, message: "Required field" },
        { min: 8, message: "Must be at least 8 chars" },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("The two passwords that you entered do not match!")
            );
          },
        }),
      ]}
    >
      <PasswordInput
        data-cy="application-confirm-password"
        prefix={
          <Text color="primary">
            <LockOutlined />
          </Text>
        }
        autocomplete="new-password"
        placeholder="Repeat password"
      />
    </Form.Item>
    <Form.Item
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject(new Error("Please accept to proceed")),
        },
      ]}
      name="accept"
    >
      <Checkbox data-cy="application-accept">
        <Text color="gray">
          By sending form, you accept our{" "}
          <ExternalLink
            underline
            href="https://storage.googleapis.com/evercity-public/Privacy%20Policy.pdf"
            rel="nofollow noopener noreferre noreferrer"
          >
            Privacy Policy
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink
            underline
            href="https://storage.googleapis.com/evercity-public/Terms%20of%20service.pdf"
            rel="nofollow noopener noreferre noreferrer"
          >
            Terms of Service.
          </ExternalLink>
        </Text>
      </Checkbox>
    </Form.Item>
    <Form.Item>
      <Button
        loading={loading}
        data-cy="btn-request-access"
        size="large"
        htmlType="submit"
        type="primary"
      >
        Submit
      </Button>
    </Form.Item>
  </Form>
);

export default RequestAccessForm;
