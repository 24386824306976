import React from "react";
import { Modal as BaseModal } from "antd";
import classnames from "classnames";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./Modal.module.less";
import Scrollable from "../../components/Scrollable/Scrollable";

const bodyStyle = {
  maxHeight: "450px",
};

const FooterModal = ({ okButton, cancelButton }) => (
  <div>
    {okButton}
    {cancelButton}
  </div>
);

const Modal = ({ children, cancelButton, okButton, autoHeight, ...props }) => (
  <BaseModal
    centered
    bodyStyle={bodyStyle}
    className={classnames(
      styles.modal,
      autoHeight && styles["modal--auto_height"]
    )}
    closeIcon={<CloseCircleOutlined className={styles.modal__close_icon} />}
    footer={<FooterModal okButton={okButton} cancelButton={cancelButton} />}
    {...props}
  >
    {autoHeight ? children : <Scrollable>{children}</Scrollable>}
  </BaseModal>
);

export default Modal;
