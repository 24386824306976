import React, { useState, useRef } from "react";
import { Typography, Space, Tooltip } from "antd";
import { useOutletContext, useParams } from "react-router-dom";
import { useMutation } from "urql";
import { ContentLayout } from "@evercityecosystem/evercity-ui";

import InfoButton from "../InfoButton/InfoButton";
import ReportPointsFormConnected from "../ReportPointsForm/ReportPointsFormConnected";
import MssNotAlignedModal from "../NotAlignedModal/MssNotAlignedModal";
import { Text } from "../../antd-ovveride";
import ReportAlignedModalMSS from "../AlignedModal/ReportAlignedModalMSS";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";
import { validateMSSPointsMutation } from "../../api/mutations";
import styles from "./MSSPage.module.less";
import { MESSENGER_GROUPS } from "../../data/messenger";
import { GroupContext } from "../../helpers/withThreadsHoc";

const { Title } = Typography;

const MSSPage = ({ threads }) => {
  const { onFinish, onPrevStep } = useOutletContext();
  const { reportId } = useParams();
  const [resultModal, setResultModal] = useState(false);
  const containerRef = useRef();
  const [, validateMSSPoints] = useMutation(validateMSSPointsMutation);

  const onSubmitHandler = async () => {
    const validateMSSPointsResponse = await validateMSSPoints({
      reportId,
    });
    const status =
      validateMSSPointsResponse?.data?.validateReportPoints?.status;
    setResultModal(status);
  };

  const notAlignedModalMethods = {
    onSave: () => {
      onFinish();
    },
    onRetry: () => {
      setResultModal(false);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  };
  const alignedModalMethods = {
    onOk: () => {
      onFinish();
    },
  };

  return (
    <GroupContext.Provider value={MESSENGER_GROUPS.TAXONOMY_REPORT.MSS}>
      <ContentLayout gaps="off">
        <div ref={containerRef}>
          <Space direction="vertical" size={20}>
            <Space align="center" size={8}>
              <Space style={{ display: "flex" }} align="center">
                <Title level={2} style={{ marginBottom: 0 }}>
                  Minimal social safeguards
                </Title>
                <Tooltip
                  title="MSS are minimum requirements for incorporating the respect for human rights into business operations."
                  placement="rightBottom"
                >
                  <InfoButton className={styles.titleInfoBtn} />
                </Tooltip>
              </Space>
              <WatchTutorialBtn startTime={343} />
            </Space>
            <Space direction="vertical" size={20}>
              <Text type="secondary" size="large" noMargin>
                Answer the following questions on&nbsp;your company&rsquo;s
                social policy
              </Text>

              <ReportPointsFormConnected
                threads={threads}
                onSubmit={onSubmitHandler}
                onCancel={onPrevStep}
                reportId={reportId}
                submitText="Next Step"
                cancelText="Back"
                pointsType="mss"
              />
            </Space>
          </Space>

          <MssNotAlignedModal
            {...notAlignedModalMethods}
            visible={resultModal === "not_reported"}
          />
          <ReportAlignedModalMSS
            {...alignedModalMethods}
            visible={resultModal === "reported"}
          />
        </div>
      </ContentLayout>
    </GroupContext.Provider>
  );
};
export default MSSPage;
