import React from "react";
import { Button } from "@evercityecosystem/evercity-ui";
import styles from "./NavigateFlow.module.less";

const NavigateFlow = ({ onBack, isFirstStep, isLastStep, onComplete }) => (
  <div className={styles.container}>
    {!isFirstStep && (
      <Button size="large" onClick={() => onBack()}>
        Back
      </Button>
    )}
    {isLastStep ? (
      <Button
        type="primary"
        onClick={() => onComplete()}
        on
        htmlType="button"
        size="large"
      >
        Complete
      </Button>
    ) : (
      <Button type="primary" htmlType="submit" size="large">
        Next step
      </Button>
    )}
  </div>
);

export default NavigateFlow;
