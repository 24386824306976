const getStartYear = (value) => {
  if (typeof value === "string") {
    return value.match(/^\d\d\d\d/)?.[0] || "N/A";
  }
  return "N/A";
};

const getEndYear = (value) => {
  if (typeof value === "string") {
    return value.match(/\d\d\d\d$/)?.[0] || "N/A";
  }
  return "N/A";
};

const transformCommodity = (commodity) => ({
  ...commodity,
  creditStart: getStartYear(commodity.vintage),
  creditEnd: getEndYear(commodity.vintage),
});

export default transformCommodity;
