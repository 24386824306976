import React, { useState, useMemo, useEffect } from "react";
import SelectNaceCodeDrawer from "../SelectNaceCodeDrawer/SelectNaceCodeDrawer";
import AddActivityDrawerContent from "./AddActivityDrawerContent";
import { useActivitiesQuery, useActivitiesSearch } from "./hooks";

const AddActivityDrawerConnected = ({
  onChangeSelectedActivities,
  onChangeSelectedCodes,
  selectedActivities,
  selectedCodes,
}) => {
  const [activitiesResponse] = useActivitiesQuery();

  const { activities, addedActivities, addedCodes } = activitiesResponse.data;

  const [searchValue, setSearchValue] = useState("");
  const [editableActivityId, setEditableActivity] = useState([]);
  const [selectNaceCodeDrawerVisible, setSelectNaceCodeDrawerVisible] =
    useState(false);

  const [filteredActivities, searchLoading] = useActivitiesSearch(
    activities,
    searchValue
  );

  const editableActivity = useMemo(
    () => activities.find((a) => a.id === editableActivityId),
    [editableActivityId, activities]
  );

  useEffect(() => {
    if (editableActivity) {
      setSelectNaceCodeDrawerVisible(true);
    }
  }, [editableActivity]);

  const onActivityCardClick = (activityId) => {
    setEditableActivity(activityId);
  };

  const onActivityCardBtnClick = (activityId) => {
    if (selectedActivities.includes(activityId)) {
      onChangeSelectedActivities(
        selectedActivities.filter((act) => act !== activityId)
      );
      onChangeSelectedCodes({
        ...selectedCodes,
        [activityId]: [],
      });
    } else {
      setEditableActivity(activityId);
    }
  };

  const onNaceCodeDrawerSubmit = (naceCodes) => {
    onChangeSelectedCodes({
      ...selectedCodes,
      [editableActivityId]: naceCodes,
    });
    if (!selectedActivities.includes(editableActivityId)) {
      onChangeSelectedActivities(() => [
        ...selectedActivities,
        editableActivityId,
      ]);
    }

    setSelectNaceCodeDrawerVisible(false);
  };

  const onNaceCodeDrawerClosed = () => {
    setEditableActivity(null);
  };

  const fetching = searchLoading || activitiesResponse.fetching;

  return (
    <>
      <AddActivityDrawerContent
        activities={searchValue ? filteredActivities : activities}
        selectedActivities={selectedActivities}
        addedActivities={addedActivities}
        selectedCodes={selectedCodes}
        addedCodes={addedCodes}
        searchValue={searchValue}
        onSearch={(v) => setSearchValue(v)}
        fetching={fetching}
        onActivityCardClick={onActivityCardClick}
        onActivityCardBtnClick={onActivityCardBtnClick}
      />
      <SelectNaceCodeDrawer
        visible={selectNaceCodeDrawerVisible}
        onClose={() => setSelectNaceCodeDrawerVisible(false)}
        onClosed={onNaceCodeDrawerClosed}
        onSubmit={onNaceCodeDrawerSubmit}
        activity={editableActivity}
        isSelected={selectedActivities.includes(editableActivityId)}
        isAdded={addedActivities.includes(editableActivityId)}
        selectedCodes={selectedCodes[editableActivityId]}
        addedCodes={addedCodes[editableActivityId]}
        okButtonText="Add"
      />
    </>
  );
};

export default AddActivityDrawerConnected;
