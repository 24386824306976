export const rangeFilterToMapboxFilters = ({ mapboxFieldKey }, values) => {
  if (!values) return null;
  return [
    "all",
    [">=", ["get", mapboxFieldKey], values[0]],
    ["<=", ["get", mapboxFieldKey], values[1]],
  ];
};

export const selectFilterToMapboxFilters = ({ mapboxFieldKey }, values) => {
  if (!values?.length) return null;
  return ["match", ["get", mapboxFieldKey], values, true, false];
};

export const checkboxGroupRangeFilterToMapboxFilters = (filter, values) => {
  if (!values?.length) return ["all", false];
  const options = filter.options.filter((o) => values.includes(o.value));

  const optionToMapboxFilter = (key, range) => {
    const checkFeatureValueIsNumber = [
      "to-boolean",
      ["to-number", ["get", key], false],
    ];
    if (!range.length) return ["!", checkFeatureValueIsNumber];
    if (range.length === 1 || !range[1]) {
      return ["all", checkFeatureValueIsNumber, [">=", ["get", key], range[0]]];
    }
    if (range.length === 2 && !!range[0] && !!range[1]) {
      return [
        "all",
        checkFeatureValueIsNumber,
        [">=", ["get", key], range[0]],
        ["<", ["get", key], range[1]],
      ];
    }
    if (range.length === 2 && !range[0] && !!range[1]) {
      return ["all", checkFeatureValueIsNumber, ["<", ["get", key], range[1]]];
    }
  };
  const optionsFilters = options
    .map((option) => optionToMapboxFilter(filter.mapboxFieldKey, option.range))
    .filter((v) => !!v);

  return ["any", ...optionsFilters];
};
