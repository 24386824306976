import React from "react";
import { RightOutlined } from "@ant-design/icons";
import cs from "classnames";
import styles from "./ExpandIcon.module.less";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const ExpandIcon = ({ expanded, hidden, onClick }) => (
  <div
    data-cy="icon-expand"
    className={cs(styles.container, hidden && styles["container--hidden"])}
    onClick={onClick}
    onKeyDown={onKeyDownDecorator(onClick)}
    tabIndex="-1"
    role="button"
  >
    <RightOutlined
      className={cs(styles.icon, expanded && styles["icon--expanded"])}
    />
  </div>
);

export default ExpandIcon;
