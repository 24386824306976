import React, { useMemo } from "react";
import { Avatar as BaseAvatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styles from "./Avatar.module.less";
import Text from "../Text/Text";

const Avatar = ({ name, showName, ...props }) => {
  const initials = useMemo(
    () =>
      name &&
      name
        .match(/\b\w/gi)
        .map((letter) => letter.toUpperCase())
        .join(""),
    [name]
  );
  return (
    <div className={styles.container}>
      {initials ? (
        <BaseAvatar {...props}>{initials}</BaseAvatar>
      ) : (
        <BaseAvatar icon={<UserOutlined />} />
      )}
      {showName && <Text>{name || "-"}</Text>}
    </div>
  );
};

export default Avatar;
