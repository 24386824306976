import React, { useMemo } from "react";
import { Text } from "../../antd-ovveride";
import styles from "./GoalsList.module.less";
import SDGs from "../../data/SDGs";

const Goal = ({ id, name, text, image }) => {
  const goal = useMemo(() => SDGs.find((sdg) => sdg.id === id), [id]);
  return (
    <span className={styles.item}>
      {image ? (
        <span className={styles.item__image} style={{ color: goal.color }}>
          {React.cloneElement(goal.icon, {
            width: "16px",
            height: "16px",
            viewBox: "0 0 50 50",
          })}
        </span>
      ) : (
        <span
          className={styles.icon}
          style={{
            backgroundColor: goal.color,
          }}
        >
          {id}
        </span>
      )}
      {name && <Text>{name}</Text>}
      {text && <Text style={{ whiteSpace: "nowrap" }}>SDG {id}</Text>}
    </span>
  );
};

export default Goal;
