import React, { useState, useMemo, useEffect } from "react";
import { Table } from "antd";
import isEqual from "lodash.isequal";
import cs from "classnames";
import styles from "./ActivitiesTable.module.less";
import { RECORD_TYPE } from "./types";

const ActivitiesTable = ({
  children,
  tableRows,
  onRow,
  expandedRows: expandedRowsProp = [],
  onExpandedRowsChange: onExpandedRowsChangeProp,
  getRowClassName: getRowClassNameProp,
}) => {
  const [expandedRows, setExpandedRows] = useState(expandedRowsProp);

  const flatRows = useMemo(() => {
    const concatChildren = (parent) => {
      const childrenRow = parent.children?.map(concatChildren).flat() || [];
      return [parent, ...childrenRow];
    };

    return tableRows.map(concatChildren).flat();
  }, [tableRows]);

  useEffect(() => {
    onExpandedRowsChangeProp?.(expandedRows);
  }, [expandedRows, onExpandedRowsChangeProp]);

  useEffect(() => {
    setExpandedRows((currentExpandedRows) =>
      isEqual(currentExpandedRows, expandedRowsProp)
        ? currentExpandedRows
        : expandedRowsProp
    );
  }, [expandedRowsProp]);

  const onExpandRowsChange = (eRows) => {
    const eRowsWithChildren = eRows.filter((rowId) => {
      const row = flatRows.find((tr) => tr.key === rowId);
      return row?.children?.length;
    });
    if (!isEqual(expandedRows, eRowsWithChildren)) {
      setExpandedRows(eRowsWithChildren);
    }
  };

  const getRowClassName = (record) => {
    let className;
    switch (record.keyType) {
      case RECORD_TYPE.ACTIVITY:
        className = styles.activityRow;
        break;
      case RECORD_TYPE.UNIT:
        className = styles.unitRow;
        break;
      case RECORD_TYPE.SUBUNIT:
      default:
        className = styles.subunitRow;
        break;
    }
    return cs(className, getRowClassNameProp?.(record) || "");
  };

  return (
    <div className={styles.container}>
      <Table
        data-cy="activities-table"
        dataSource={tableRows}
        className={styles.table}
        pagination={false}
        expandedRowKeys={expandedRows}
        onExpandedRowsChange={onExpandRowsChange}
        rowClassName={getRowClassName}
        onRow={onRow}
        showSorterTooltip={false}
      >
        {children}
      </Table>
    </div>
  );
};

export default ActivitiesTable;
