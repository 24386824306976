import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { FormCard } from "@evercityecosystem/evercity-ui";

import { LoginOutlined } from "@ant-design/icons";
import PublicLayout from "../PublicLayout/PublicLayout";
import RequestDemoForm from "./RequestDemoForm";
import UserInfoForm from "./UserInfoForm";
import { useCurrentUser } from "../../hooks/user";
import { Link } from "../../antd-ovveride";
import FinishStep from "./FinishStep";

const SignUpPage = () => {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});
  const [userState] = useCurrentUser();
  const isUser = !!userState.data.id;

  const handleSetStep = (s) => setStep(s);
  const hendleSetUserInfo = (obj) => setUser(obj);

  if (isUser) return <Navigate to="/" />;

  const STEPS = [
    <FormCard
      title="Get a Live Demo"
      subtitle="See how GDP can guide you along your sustainable finance journey."
      footer={
        <>
          Already have an account? <Link to="/login">Log in</Link>
        </>
      }
    >
      <RequestDemoForm
        handleSetStep={handleSetStep}
        hendleSetUserInfo={hendleSetUserInfo}
      />
    </FormCard>,
    <FormCard
      title="About company"
      subtitle="We'll use this info to personalize your experience."
    >
      <UserInfoForm user={user} handleSetStep={handleSetStep} />
    </FormCard>,
    <FormCard
      title="Congratulations!"
      subtitle="Your application has been sent for review."
    >
      <FinishStep />
    </FormCard>,
  ];

  return (
    <PublicLayout
      variant="dialog"
      footer={false}
      actions={
        <Link
          data-cy="login"
          icon={<LoginOutlined />}
          type="round_button"
          to="/login"
        >
          Login
        </Link>
      }
    >
      {STEPS[step]}
    </PublicLayout>
  );
};

export default SignUpPage;
