import React from "react";
import { Space } from "@antd-ovveride";
import { Typography } from "antd";

import ReadMap from "../../Product/RoadMap";
import ProductVerticalSpace from "../../Product/ProductVerticalSpace";
import ProductCompanyInfo from "../../Product/ProductCompanyInfo";
import ProductContentSection from "../../Product/ProductContentSection";
import ProductMap from "../../Product/ProductMap";

const { Title } = Typography;

const OverviewTabContent = React.forwardRef(({ project }, mapRef) => (
  <div>
    <Space block size={20} direction="vertical">
      <Title level={2}>{project.company.name}</Title>
      <ProductVerticalSpace>
        <ProductCompanyInfo
          location={project.company.location}
          website={project.company.website}
          establishmentYear={project.company.establishment_year}
        />
        <div ref={mapRef}>
          <ProductMap
            size="large"
            zoom={project.location.zoom}
            lng={project.location.longitude}
            lat={project.location.latitude}
          />
        </div>
        <ProductContentSection title="Challenge" body={project.challenge} />
        <ProductContentSection title="Solution" body={project.solution} />
        <ProductContentSection
          title="Long-term impact"
          body={project.long_term_impact}
        />
        <ProductContentSection
          title="Project roadmap"
          body={<ReadMap list={project.roadmap} />}
        />
      </ProductVerticalSpace>
    </Space>
  </div>
));

export default OverviewTabContent;
