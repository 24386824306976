import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import { useCurrentUser } from "../hooks/user";
import Error500 from "./errors/Error500";
import useJivoChat from "../hooks/useJivoChat";
import router from "../router";

const App = () => {
  const [userState] = useCurrentUser();

  useJivoChat();

  useEffect(() => {
    const { data: userInfo } = userState;
    if (!userInfo?.id) return;
    Sentry.configureScope((scope) => {
      scope.setUser(
        userInfo.id ? { id: userInfo.id, email: userInfo.email } : null
      );
      if (window.ym && typeof window.ym === "function") {
        window.ym(
          process.env.REACT_APP_YANDEX_METRICA_ID,
          "getClientID",
          (clientId) => {
            scope.setTag("ya-metrica-id", clientId);
          }
        );
      }
    });
  }, [userState]);

  if (userState?.fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error500 />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  );
};

export default App;
