import { useQuery } from "urql";
import { frameworksQuery } from "../../api/queries";
import { useCurrentUser } from "../user";

const useGetFrameworks = () => {
  const [currentUserState] = useCurrentUser();
  const { company_id: companyId } = currentUserState.data;
  const [{ data }] = useQuery({
    query: frameworksQuery,
    variables: { companyId },
  });

  const frameworks = data?.frameworks;

  return { frameworks };
};

export default useGetFrameworks;
