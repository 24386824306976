import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";
import SummarySection from "../SummarySection/SummarySection";
import { Title } from "../../antd-ovveride";
import Paragraph from "../../antd-ovveride/Paragraph/Paragraph";
import formatString from "../../helpers/formatString";
import styles from "./ProcessForProjectSummary.module.less";

const ProcessForProjectSummary = ({ framework, className, ...props }) => (
  <SummarySection
    title="Process for project evaluation and selection"
    description={`To identify Eligible Green Assets, ${framework.company?.name} follows a two-step process:`}
    {...props}
    className={classNames(className, styles.process)}
  >
    <Row>
      <Col span={9}>
        <Title className={styles.title} level={5}>
          Green asset screening and pre-selection
        </Title>
      </Col>
      <Col span={11} offset={4}>
        <Paragraph size="large">
          The {framework.eligible_assess_depart} will screen and pre-select
          projects and assets
          {formatString(framework.asset_select_frequency)} in accordance with
          the eligible categories set out in Section 2 “Use of Proceeds”.
        </Paragraph>
      </Col>
    </Row>
    <Row>
      <Col span={9}>
        <Title className={styles.title} level={5}>
          Internal confirmation
        </Title>
      </Col>
      <Col span={11} offset={4}>
        <Paragraph size="large">
          Assets that are pre-selected subsequently will be confirmed by the{" "}
          {framework.eligible_approve_depart}.
        </Paragraph>

        <Paragraph size="large">
          The {framework.monitoring_depart} will monitor the Eligible Green
          Portfolio and be responsible for excluding projects that no longer
          comply with the Eligibility Criteria and for replacing the eligible
          assets that have matured, or have been disposed of, on a best effort
          basis.
        </Paragraph>
      </Col>
    </Row>
  </SummarySection>
);

export default ProcessForProjectSummary;
