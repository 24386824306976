import React from "react";
import { useQuery } from "urql";
import ProjectsTable from "./ProjectsTable";
import { myProjectsQuery } from "../../../api/queries";
import { useCurrentUser } from "../../../hooks/user";

const ProjectsTableConnected = () => {
  const [{ company }] = useCurrentUser();
  const [{ data }] = useQuery({
    query: myProjectsQuery,
    variables: {
      companyId: company?.id,
    },
    pause: !company,
  });

  const projects = data?.projects;

  return <ProjectsTable projects={projects} />;
};

export default ProjectsTableConnected;
