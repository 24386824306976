import reportCurrencyParameters from "../../data/reportCurrencyParameters";
import { RECORD_TYPE } from "../ActivitiesTable/types";

const buildUnitTableRow = (unit, actReport, recordType) => {
  const children = unit.subunits.map((subunit) =>
    buildUnitTableRow(subunit, actReport, RECORD_TYPE.SUBUNIT)
  );

  const aligned = Object.keys(reportCurrencyParameters).every(
    (finParameter) => unit[`${finParameter}_alignment`] === unit[finParameter]
  );

  const nameProps = {
    name: unit.name,
    type: unit.type,
    aligned,
  };

  const record = {
    key: unit.id,
    keyType: recordType,
    element: unit,
    name: nameProps,
    code: null,
    activityType: unit.activity_type,
    objective: unit.objective,
    turnover: unit.turnover,
    capex: unit.capex,
    opex: unit.opex,
  };
  if (children.length) {
    return {
      ...record,
      children,
    };
  }
  return record;
};

const buildActReportTableRow = (actReport) => {
  const children = actReport.units.map((unit) =>
    buildUnitTableRow(unit, actReport, RECORD_TYPE.UNIT)
  );

  const record = {
    key: actReport.id,
    keyType: RECORD_TYPE.ACTIVITY,
    element: actReport,

    color: actReport.color,
    name: actReport.activity.name,
    code: actReport.activities_reports_codes.map((code) => code.nace_code),
    objective: null,

    turnover: {
      part: actReport.turnover_alignment,
      total: actReport.turnover,
    },
    capex: {
      part: actReport.capex_alignment,
      total: actReport.capex,
    },
    opex: {
      part: actReport.opex_alignment,
      total: actReport.opex,
    },
  };
  if (children.length) {
    return {
      ...record,
      children,
    };
  }
  return record;
};

const buildTableRowsData = (actReports) =>
  actReports.map(buildActReportTableRow);

export default buildTableRowsData;
