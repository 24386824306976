import React from "react";
import { ReactComponent as DotsIcon } from "../../../assets/icons/dots.svg";
import styles from "./DotsActionsIcon.module.less";

const DotsActionsIcon = (props) => (
  <div {...props} className={styles.dots}>
    <DotsIcon />
  </div>
);

export default DotsActionsIcon;
