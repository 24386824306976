import React from "react";
import {
  Title,
  Space,
  Button,
  Table,
  Text,
  Dropdown,
  PlusCircleOutlined,
  DownOutlined,
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@evercityecosystem/evercity-ui";
import StyledTag from "./ImpactTag";
import SDGs from "../../../data/SDGs";
import styles from "./ProjectImpactPage.module.less";

const dataSourceMock = [
  {
    key: "1",
    target: (
      <span>
        <StyledTag color={SDGs[12].color}>13.1</StyledTag>
        Strengthen resilience and adaptive capacity to climate-related hazards
        Strengthen resilience and adaptive capacity to climate-related hazards
      </span>
    ),
    sdg: <img src={SDGs[12].iconActive} alt={SDGs[1].title} width={48} />,
    achieve:
      "Increase the involvement of the local stakeholders in the decision-making process by offering them a platform to express their concerns and needs. Make it easier for them to participate in the process of shaping the futu...",
    standard: <StyledTag color="#810048">IRIS</StyledTag>,
    indicators:
      "Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population",
  },
  {
    key: "2",
    target: (
      <span>
        <StyledTag color={SDGs[12].color}>13.2</StyledTag>
        Integrate climate change measures into national policies, strategies and
        planning
      </span>
    ),
    achieve:
      "Protecting remaining high conservation value lands and securing a regional trail network within 30 years with The King County Land Conservation Initiative. Increase the involvement of the local stakeholders in the decis...",
  },
];

const TableSDG = ({
  title,
  description,
  goals = [],
  dataSource = dataSourceMock,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const columns = [
    {
      title: "SDG",
      dataIndex: "sdg",
      key: "sdg",
      width: 65,
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
    },
    {
      title: "How we plan to achieve it",
      dataIndex: "achieve",
      key: "achieve",
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      width: 65,
      verticalAlign: "top",
    },
    {
      title: "Indicators",
      dataIndex: "indicators",
      key: "indicators",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 65,
      render: () => (
        <Space size={4}>
          <Button icon={<DownOutlined />} />

          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  label: "Edit",
                  key: "edit",
                  icon: <EditOutlined />,
                  onClick: onEdit,
                },
                {
                  label: "Delete",
                  key: "delete",
                  danger: true,
                  icon: <DeleteOutlined />,
                  onClick: onDelete,
                },
              ],
            }}
            placement="bottomRight"
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Space block size={32} direction="vertical">
      <Space block size={8} direction="vertical">
        <Space size={24} align="center">
          <Title>{title}</Title>

          <Space size={8}>
            {goals.map((goal) => (
              <span
                className={styles.goal}
                key={goal.id}
                style={{ color: goal.color }}
              >
                {goal.icon}
              </span>
            ))}
          </Space>
        </Space>

        <Space block justifyContent="space-between">
          <Text>{description}</Text>

          <Button type="primary" icon={<PlusCircleOutlined />} onClick={onAdd}>
            Add {title} SDG
          </Button>
        </Space>
      </Space>
      <Table
        columns={columns}
        dataSource={dataSource}
        className={styles.table}
        emptyText={
          <Button type="btn" icon={<PlusCircleOutlined />} onClick={onAdd}>
            New SDG
          </Button>
        }
      />
    </Space>
  );
};

export default TableSDG;
