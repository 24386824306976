import { notification } from "antd";
import React from "react";
import { useMutation } from "urql";
import { deleteDocMutation, updateDocMutation } from "../../api/mutations";
import AttachedList from "./AttachedList";

const AttachedListConnected = ({
  docs,
  attachedFilesIds,
  onUpdateDoc,
  onDeleteDoc,
  onRetryDoc,
  onSelectChangeDoc,
  selectedDocs,
}) => {
  const [, deleteDoc] = useMutation(deleteDocMutation);
  const [, updateDoc] = useMutation(updateDocMutation);

  const onChangeDocName = async (id, newName) => {
    const docIndex = docs.findIndex((d) => d.uid === id);
    const doc = docs[docIndex];

    if (!doc?.data?.docId) {
      notification.error({
        message: "Error edit file",
        description: "Please try again later.",
      });
      return;
    }
    try {
      onUpdateDoc?.(doc.uid, { name: newName });
      const response = await updateDoc({
        id: doc.data.docId,
        set: {
          name: newName,
        },
      });
      if (response.error) throw new Error(response.error);
    } catch (err) {
      notification.error({
        message: "Error edit file",
        description: "Please try again later.",
      });
      onUpdateDoc?.(doc.uid, { name: doc.data.name });
    }
  };

  const deleteUploadedDoc = async (doc) => {
    const docStatus = doc.status;
    onUpdateDoc?.(doc.uid, { status: "uploading" });
    const response = await deleteDoc({ id: doc.data.docId });
    if (response.error) {
      notification.error({
        message: "Error delete file",
        description: "Please try again later.",
      });
      onUpdateDoc?.(doc.uid, { status: docStatus });
      return;
    }
    onDeleteDoc?.(doc.uid);
  };

  const onDeleteFileHandler = (id) => {
    const docIndex = docs.findIndex((d) => d.uid === id);
    if (docIndex === -1) return;
    const doc = docs[docIndex];
    if (doc.status === "done") {
      deleteUploadedDoc(doc);
    } else if (doc.status === "error") {
      onDeleteDoc?.(doc.uid);
    }
  };

  return (
    <AttachedList
      docs={docs}
      attachedFilesIds={attachedFilesIds}
      onDeleteDoc={onDeleteFileHandler}
      onRetryDoc={onRetryDoc}
      onChangeDocName={onChangeDocName}
      onSelectChangeDoc={onSelectChangeDoc}
      selectedDocs={selectedDocs}
    />
  );
};

export default AttachedListConnected;
