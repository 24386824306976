import React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import Text from "../Text/Text";
import Upload from "../Upload/Upload";
import styles from "./DragFile.module.less";

const DragFile = () => (
  <div className={styles.container}>
    <Text className={styles.container__description}>
      Drag your file in this area
    </Text>
    <Upload>
      <Button className={styles.upload__btn} icon={<PlusCircleOutlined />}>
        Browse file
      </Button>
    </Upload>
  </div>
);

export default DragFile;
