import React from "react";
import { Table } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
} from "@ant-design/icons";
import style from "./FrameworkCreatePageTable.module.less";

const { Column } = Table;

const FrameworkCreatePageTable = ({ source }) => (
  <Table pagination={false} dataSource={source} className={style.table}>
    <Column
      title="File name"
      dataIndex="name"
      key="name"
      className={style.file}
      render={() => <FileOutlined className={style.fileIcon} />}
    />
    <Column
      title="Upload date"
      key="framework_Upload_date"
      className={style.col}
    />
    <Column title="Size" className={style.col} key="size" />
    <Column
      title="Actions"
      key="Actions"
      render={() => (
        <>
          <DownloadOutlined className={style.actionIcons} />
          <DeleteOutlined className={style.actionIcons} />
        </>
      )}
    />
  </Table>
);

export default FrameworkCreatePageTable;
