import React from "react";
import { Button, Text, Title } from "@evercityecosystem/evercity-ui";
import FrameworkTemplatesConnected from "../FrameworkTemplates/FrameworkTemplatesConnected";
import DragFile from "../../antd-ovveride/DragFile/DragFile";
import FrameworkCreatePageTable from "./FrameworkCreatePageTable";
import styles from "./FrameworkCreatePageTable.module.less";
import LkLayout from "../LkLayout/LkLayout";

const FrameworkCreatePage = ({ createFramework, uploadedFrameworks }) => (
  <LkLayout header={{ title: "Back to all frameworks", goBack: true }}>
    <Title level={2}>Create your framework</Title>
    <Text block type="secondary" size="large" className={styles.description}>
      Please create framework using one of the templates.
    </Text>
    <FrameworkTemplatesConnected
      onSelect={createFramework}
      className={styles.templates}
    />
    <Title level={3}>Upload your framework</Title>
    <Text block type="secondary" size="large" className={styles.upload}>
      Please upload your green bond framework.
    </Text>
    {uploadedFrameworks.length === 0 ? (
      <DragFile />
    ) : (
      <>
        <FrameworkCreatePageTable />
        <Button
          key="save_and_proceed"
          type="primary"
          className={styles.saveButton}
          size="large"
        >
          Save
        </Button>
      </>
    )}
  </LkLayout>
);
export default FrameworkCreatePage;
