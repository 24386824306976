import React from "react";
import { useOutletContext } from "react-router-dom";
import ManagementOfProceeds from "./ManagementOfProceeds";

const ManagementOfProceedsConnected = () => {
  const props = useOutletContext();
  return <ManagementOfProceeds {...props} />;
};

export default ManagementOfProceedsConnected;
