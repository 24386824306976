import React from "react";
import { Title } from "@antd-ovveride";
import classnames from "classnames";
import styles from "./FrameworkSection.module.less";

const FrameworkSection = ({ title, number, children, one, className }) => (
  <div className={classnames(styles.container, className)}>
    {title && (
      <Title level={3} size="small">
        {number} {title}
      </Title>
    )}
    <div
      className={classnames(
        styles.container__content,
        !title && styles["container--without_title__content"],
        one && styles["container__content--one"]
      )}
    >
      {children}
    </div>
  </div>
);

export default FrameworkSection;
