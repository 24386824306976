import React from "react";
import LockOverlay from "../../LockOverlay/LockOverlay";

const DocumentsTabContent = () => (
  <LockOverlay>
    <div>Documents tab content</div>
  </LockOverlay>
);

export default DocumentsTabContent;
