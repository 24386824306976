import React from "react";
import { Table as BaseTable } from "antd";
import classnames from "classnames";
import styles from "./Table.module.less";

const Table = ({ children, className, pagination = false, ...props }) => (
  <BaseTable
    pagination={pagination}
    className={classnames(styles.table, className)}
    {...props}
  >
    {children}
  </BaseTable>
);

export const { Column } = BaseTable;

export default Table;
