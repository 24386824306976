import React from "react";
import { Space } from "@antd-ovveride";
import { useResponsive } from "ahooks";

import styles from "./ProductTwoColLayout.module.less";

const ProductTwoColLayout = ({ children }) => {
  const { md } = useResponsive();
  return (
    <div className={styles.twoColLayout}>
      <Space block direction="vertical" size={md ? 70 : 40}>
        {children?.[0] || children}
      </Space>
      <Space block size={md ? 80 : 50} direction="vertical">
        {children?.[1] || ""}
      </Space>
    </div>
  );
};

export default ProductTwoColLayout;
