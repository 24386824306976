import React, { useState } from "react";
import { Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Space, Text } from "@antd-ovveride";

import { useOutletContext } from "react-router-dom";
import { ContentLayout, Button } from "@evercityecosystem/evercity-ui";
import styles from "./ActivitiesPage.module.less";
import AddActivityDrawer from "../AddActivityDrawer/AddActivityDrawer";
import ActivitiesPageTableConnected from "../ActivitiesPageTable/ActivitiesPageTableConnected";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";

const { Title } = Typography;

const ActivitiesPage = () => {
  const { onFinish, onPrevStep } = useOutletContext();
  const [addActivityDrawerVisible, setAddActivityDrawerVisible] =
    useState(false);

  return (
    <ContentLayout gaps="off">
      <div>
        <header className={styles.header}>
          <Space direction="vertical" size={5}>
            <Space size={8} align="center">
              <Title level={2} style={{ marginBottom: 0 }}>
                EU Taxonomy activities screening
              </Title>
              <WatchTutorialBtn startTime={91} />
            </Space>
            <Text type="secondary" size="large" noMargin>
              Please add activities that correspond with the reporting company
              operations.
            </Text>
          </Space>
          <Button
            data-cy="btn-add-activity"
            type="primary"
            onClick={() => setAddActivityDrawerVisible(true)}
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add activity
          </Button>
        </header>
        <div className={styles.table}>
          <ActivitiesPageTableConnected />
        </div>
        <Space className={styles.footer} size={12}>
          <Button size="large" onClick={() => onPrevStep()}>
            Back
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => onFinish()}
            data-cy="btn-next-step"
          >
            Next step
          </Button>
        </Space>
      </div>
      <AddActivityDrawer
        visible={addActivityDrawerVisible}
        onClose={() => setAddActivityDrawerVisible(false)}
      />
    </ContentLayout>
  );
};
export default ActivitiesPage;
