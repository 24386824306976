import React from "react";
import { Card, Typography } from "antd";
import ActivityTypes from "../Charts/ActivityTypes";
import SubstantialContribution from "../Charts/SubstantialContribution";
import TaxonomyAligment from "../Charts/TaxonomyAligment";
import ScreeningOverview from "./ScreeningOverview";
import finParams from "../../data/reportCurrencyParameters";
import styles from "./ReportPdfCharts.module.less";

const { Title } = Typography;

const KPILevelCharts = ({
  units,
  totalValues,
  report,
  actReports,
  problems,
}) => (
  <div className={styles.kpiLevel}>
    {Object.keys(finParams).map((param) => (
      <>
        <Title className={styles.title}>{finParams[param]}</Title>
        <Card className={styles.activityTypes}>
          <ActivityTypes
            totalValue={totalValues[param]}
            paramName={param}
            units={units}
          />
        </Card>
        <Card className={styles.substantialContribution}>
          <SubstantialContribution
            totalValue={totalValues[param]}
            paramName={param}
            units={units}
          />
        </Card>
        <Card className={styles.taxonomyAligment}>
          <Title level={4} align="center">
            Taxonomy alignment
          </Title>
          <TaxonomyAligment report={report} finParamNames={[param]} />
        </Card>
        <Card className={styles.screeningOverview}>
          <ScreeningOverview
            actReports={actReports}
            problems={problems}
            finParamNames={[param]}
          />
        </Card>
        <Card className={styles.screeningOverview}>
          <ScreeningOverview
            actReports={actReports}
            problems={problems}
            finParamNames={[param]}
            showAligned
          />
        </Card>
      </>
    ))}
  </div>
);

export default KPILevelCharts;
