import cn from "classnames";
import React from "react";
import { Space } from "antd";
import { string } from "prop-types";
import { Link } from "@antd-ovveride";

import { ReactComponent as IconFacebook } from "../../assets/images/socials/facebook.svg";
import { ReactComponent as IconLinkedin } from "../../assets/images/socials/linkedin.svg";
import { ReactComponent as IconMedium } from "../../assets/images/socials/medium.svg";
import { ReactComponent as IconTelegram } from "../../assets/images/socials/telegram.svg";

import styles from "./Socials.module.less";

const Socials = ({ className }) => (
  <div className={cn(className, styles.socials)}>
    <Space align="center" wrap size={45}>
      <Link
        href="https://www.facebook.com/evercityeco"
        className={styles.link}
        target="_blank"
      >
        <IconFacebook />
      </Link>

      <Link
        href="https://www.linkedin.com/company/evercity/"
        className={styles.link}
        target="_blank"
      >
        <IconLinkedin />
      </Link>

      <Link
        href="https://evercity.medium.com/"
        className={styles.link}
        target="_blank"
      >
        <IconMedium />
      </Link>

      <Link
        href="https://t.me/evercity/"
        className={styles.link}
        target="_blank"
      >
        <IconTelegram />
      </Link>
    </Space>
  </div>
);

Socials.propTypes = {
  className: string,
};

Socials.defaultProps = {
  className: null,
};

export default Socials;
