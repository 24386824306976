import React, { memo } from "react";
import { Steps, Progress } from "antd";
import classnames from "classnames";
import { CheckOutlined } from "@ant-design/icons";
import styles from "./FrameworkSteps.module.less";
import { Text } from "../../antd-ovveride";

const { Step } = Steps;

const FrameworkSteps = ({ steps, stepIndex, setStepIndex, progress }) => (
  <Steps
    className={styles.stepsBlock}
    current={stepIndex}
    direction="vertical"
    onChange={setStepIndex}
  >
    {steps.map((item) => (
      <Step
        disabled={progress[item.id - 1] < 100}
        className={styles.step}
        key={item.id}
        title={item.title}
        description={
          <div className={styles.progress}>
            <Text
              type="secondary"
              size="large"
              className={styles.progress__title}
            >
              Progress:
            </Text>
            <div className={styles.progress__inner}>
              <Progress
                showInfo={false}
                type="line"
                size="small"
                strokeWidth={8}
                strokeColor="#91DCA6"
                percent={progress && progress[item.id]}
              />
              <Text
                className={classnames(styles.progress__title, styles.percent)}
                type="secondary"
                size="large"
              >
                {progress && progress[item.id]}%
              </Text>
            </div>
          </div>
        }
        icon={
          <div
            className={classnames(
              stepIndex === item.id && styles.current,
              stepIndex < item.id && styles.next,
              stepIndex > item.id && styles.done
            )}
          >
            {stepIndex === item.id && (
              <Text className={styles.currentText}>{item.id + 1}</Text>
            )}
            {stepIndex < item.id && (
              <Text className={styles.nextText}>{item.id + 1}</Text>
            )}
            {stepIndex > item.id && (
              <CheckOutlined className={styles.doneText} />
            )}
          </div>
        }
      />
    ))}
  </Steps>
);

export default memo(FrameworkSteps);
