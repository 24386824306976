import React, { useState, useMemo } from "react";
import { Form, Input, Button, notification } from "antd";
import { useMutation } from "urql";
import uniqueId from "lodash.uniqueid";
import styles from "./FromCloudForm.module.less";
import { createDocMutation } from "../../api/mutations";
import { useCurrentUser } from "../../hooks/user";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";

const { Item: FormItem } = Form;

const FromCloudForm = ({ onAdd }) => {
  const [form] = Form.useForm();
  const [userState] = useCurrentUser(); // компонент доступен только авторизованным пользователям
  const userId = useMemo(() => userState.data?.id, [userState.data]);
  const [nameFieldVisible, setNameFieldVisible] = useState(false);

  const [createDocResponse, createDoc] = useMutation(createDocMutation);

  const onSubmit = async (values) => {
    try {
      const response = await createDoc({
        name: values.name,
        url: values.url,
        mimetype: "",
        user_id: userId,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      const doc = response.data.insert_docs_one;
      onAdd?.({
        uid: uniqueId("file-from-cloud-"),
        data: docToAttachedFile(doc),
        createdDate: new Date(),
      });
      form.resetFields();
      setNameFieldVisible(false);
    } catch (error) {
      console.log({ error });
      notification.error({
        message: "Error save file from cloud",
        description: "Please try again later.",
      });
    }
  };

  if (userState.fetching) {
    return <LoadingSpinner />;
  }
  if (userState.error) {
    return null;
  }

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      size="large"
      onValuesChange={(changedValues, { url }) => {
        setNameFieldVisible(!!url.length);
      }}
      onFinish={onSubmit}
      form={form}
    >
      <FormItem
        label="File from cloud"
        name="url"
        rules={[{ required: true, message: "Required" }]}
        style={nameFieldVisible ? "" : { marginBottom: 0 }}
      >
        <Input />
      </FormItem>
      {nameFieldVisible && (
        <>
          <FormItem
            label="File name"
            name="name"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </FormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className={styles.submitBtn}
            loading={createDocResponse.fetching}
          >
            Save
          </Button>
        </>
      )}
    </Form>
  );
};

export default FromCloudForm;
