import React from "react";
import cs from "classnames";
import styles from "./CardLayout.module.less";

const CardLayout = ({ children, responsive }) => (
  <div
    className={cs(
      styles.cardLayout,
      responsive && styles["cardLayout--responsive"]
    )}
  >
    {children}
  </div>
);

export default CardLayout;
