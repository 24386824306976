import React, { useMemo, useState, useEffect } from "react";
import { Tabs, Typography, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import styles from "./MapLegend.module.less";
import { LAYERS } from "../../data/mapLayers";
import { Text, Link } from "../../antd-ovveride";
import PointsLenged from "./LayerLegends/PointsLenged";
import GradientLegend from "./LayerLegends/GradientLegend";

const { Title } = Typography;
const { TabPane } = Tabs;

const LayerInfoTooltipContent = ({ source }) => (
  <>
    Source:&nbsp;
    <Link href={source.link} target="_blank" rel="noreferrer" df>
      {source.text}
    </Link>
  </>
);
const LayerInfo = ({ layer }) => (
  <div className={styles.layerInfo}>
    <Title
      level={5}
      className={styles.layerFullName}
      style={{ marginBottom: 0 }}
    >
      {layer.fullName}
    </Title>
    {layer.source && (
      <Tooltip
        title={<LayerInfoTooltipContent source={layer.source} />}
        overlayStyle={{ maxWidth: 350 }}
      >
        <QuestionCircleOutlined className={styles.infoIcon} />
      </Tooltip>
    )}
  </div>
);

const LayerLegend = ({ layer }) => {
  switch (layer.legend?.type) {
    case "points":
      return <PointsLenged layer={layer} />;
    case "gradient":
      return <GradientLegend layer={layer} />;
    default:
      return null;
  }
};

const MapLegend = ({ layersIds }) => {
  const layers = useMemo(
    () => layersIds.map((layerId) => LAYERS.find((l) => l.id === layerId)),
    [layersIds]
  );
  const [activeLayerId, setActiveLayerId] = useState();

  useEffect(() => {
    setActiveLayerId(layersIds[layersIds.length - 1]);
  }, [layersIds]);

  return (
    <div className={styles.mapLegend}>
      <Tabs
        className={styles.tabs}
        onChange={setActiveLayerId}
        activeKey={activeLayerId}
      >
        {layers?.map((layer) => (
          <TabPane
            tab={
              <div className={styles.tab}>
                <div className={styles.tabIcon}>{layer.icon}</div>
                <Text>{layer.displayName}</Text>
              </div>
            }
            key={layer.id}
            className={styles.tab}
          >
            <div className={styles.activeLayer}>
              <LayerInfo layer={layer} />
              <LayerLegend layer={layer} />
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MapLegend;
