import React, { useCallback, useState, useMemo } from "react";
import { useQuery } from "urql";
import uniqBy from "lodash.uniqby";
import useUrlState from "@ahooksjs/use-url-state";
import CatalogPage from "./CatalogPage";

import {
  debtCardsPreviewQuery,
  commodityCardsPreviewQuery,
  projectCardsPreviewQuery,
  debtFiltersQuery,
  projectFiltersQuery,
  commoditiesFiltersQuery,
} from "../../api/queries";

const SECTIONS = [
  { label: "Projects", value: "project" },
  { label: "Debt", value: "debt" },
  { label: "Commodities", value: "commodity" },
];

// const getSortFilter = (sectionName) => {
//   const optionsBySectionName = {
//     debt: [
//       {
//         label: "Asset type",
//         value: "type",
//         queryCondition: { type: "asc" },
//       },
//       { label: "Status", value: "state", queryCondition: { state: "asc" } },
//     ],
//     project: [
//       { label: "Status", value: "state", queryCondition: { state: "asc" } },
//     ],
//     commodity: [
//       { label: "Asset type", value: "type", queryCondition: { type: "asc" } },
//       { label: "Status", value: "state", queryCondition: { state: "asc" } },
//     ],
//   };

//   return {
//     id: "sort",
//     label: "Sort by",
//     options: optionsBySectionName[sectionName],
//     type: "sort",
//   };
// };

const parseFilterToQueryVariable = (filters = {}) => {
  const queryVariables = {};
  // const { ...restFilters } = filters;

  const filtersConditions = Object.values(filters).map(({ filter, value }) =>
    filter.queryCondition(value)
  );
  const notEmptyConditions = filtersConditions.filter((fc) => !!fc);
  const whereVariable = {
    _and: [...notEmptyConditions],
  };

  // Disable private geolocation filter
  // if (inEUNA) {
  //   // eslint-disable-next-line no-underscore-dangle
  //   whereVariable._and.push({
  //     company: {
  //       geolocation_private: {
  //         _eq: false,
  //       },
  //     },
  //   });
  // }

  queryVariables.where = whereVariable;

  // if (sort?.value) {
  //   const sortOption = sort.filter.options.find(
  //     (option) => option.value === sort.value
  //   );
  //   if (sortOption) {
  //     queryVariables.order_by = [sortOption.queryCondition];
  //   }
  // }

  return queryVariables;
};

const makeFiltersFromQueryData = (queryData, sectionName) => {
  if (!queryData) return [];
  const transformResultToFilters = {
    debt: (data) => [
      {
        id: "type",
        label: "Asset type",
        options: data.bond_types.map((t) => t.name),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            bond_type: { name: { _eq: optionValue } },
          },
      },
      {
        id: "state",
        label: "State",
        options: data.bond_states.map((s) => s.name),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            bond_state: { name: { _eq: optionValue } },
          },
      },
      {
        id: "goals",
        label: "",
        options: data.goals,
        type: "goals",
        queryCondition: (optionValue) =>
          !!optionValue?.length && {
            bond_goals: {
              goal_id: { _in: optionValue },
            },
          },
      },
    ],
    project: (data) => [
      {
        id: "type",
        label: "Asset type",
        options: uniqBy([
          ...data.bond_types.map((bt) => bt.name),
          ...data.commodity_types.map((ct) => ct.name),
        ]),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            _or: [
              {
                bond_projects: {
                  bond: { bond_type: { name: { _eq: optionValue } } },
                },
              },
              {
                commodities: {
                  commodity_type: { name: { _eq: optionValue } },
                },
              },
            ],
          },
      },
      {
        id: "state",
        label: "State",
        options: data.project_states.map((s) => s.name),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            state: { _eq: optionValue },
          },
      },
      {
        id: "category",
        label: "Project category",
        options: data.categories.map((pc) => pc.title),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            project_categories: { category: { title: { _eq: optionValue } } },
          },
      },
      {
        id: "goals",
        label: "",
        options: data.goals,
        type: "goals",
        queryCondition: (optionValue) =>
          !!optionValue?.length && {
            projects_goals: { goal_id: { _in: optionValue } },
          },
      },
    ],
    commodity: (data) => [
      {
        id: "type",
        label: "Asset type",
        options: data.commodity_types.map((t) => t.name),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            commodity_type: { name: { _eq: optionValue } },
          },
      },
      {
        id: "state",
        label: "State",
        options: data.commodity_states.map((s) => s.description),
        type: "select",
        queryCondition: (optionValue) =>
          !!optionValue && {
            commodity_state: { name: { _eq: optionValue } },
          },
      },
      {
        id: "goals",
        label: "",
        options: data.goals,
        type: "goals",
        queryCondition: (optionValue) =>
          !!optionValue?.length && {
            project: {
              projects_goals: { goal_id: { _in: optionValue } },
            },
          },
      },
    ],
  }[sectionName];
  return transformResultToFilters(queryData);
};

const PROJECTS_WHITE_LIST = [
  "Forest conservation and communities protection in Solano",
  "Vashi Railway Station Solar Rooftop Project",
  "Forest restoration at Aceh",
  "Forest restoration at West Kalimantan",
  "Waste gas to energy in Siberia",
  "AURE commercial modular property in Uri",
];

const useCards = (sectionName, filters) => {
  const variables = useMemo(
    () => parseFilterToQueryVariable(filters),
    [filters]
  );
  const debtsResult = useQuery({
    query: debtCardsPreviewQuery,
    pause: sectionName !== "debt",
    variables,
  });

  const projectsResult = useQuery({
    query: projectCardsPreviewQuery,
    pause: sectionName !== "project",
    variables,
  });

  const commoditiesResult = useQuery({
    query: commodityCardsPreviewQuery,
    pause: sectionName !== "commodity",
    variables,
  });

  const [queryResult] = {
    debt: debtsResult,
    project: projectsResult,
    commodity: commoditiesResult,
  }[sectionName];

  const resultData = useMemo(() => {
    if (!queryResult.data) return undefined;
    const cardsDataKey = {
      debt: "bonds",
      project: "projects",
      commodity: "commodities",
    }[sectionName];
    const result = queryResult.data?.[cardsDataKey];

    if (sectionName === "debt") {
      return result?.filter((card) =>
        PROJECTS_WHITE_LIST.includes(card?.company?.name)
      );
    }
    if (sectionName === "commodity") {
      return result?.filter((card) =>
        PROJECTS_WHITE_LIST.includes(card?.project?.name)
      );
    }
    return result?.filter((card) => PROJECTS_WHITE_LIST.includes(card.name));
  }, [sectionName, queryResult.data]);

  const transformedResult = useMemo(
    () => ({
      data: resultData,
      fetching: queryResult.fetching,
      error: queryResult.error,
    }),
    [resultData, queryResult.fetching, queryResult.error]
  );

  return transformedResult;
};

const useFilters = (sectionName) => {
  const debtFiltersResult = useQuery({
    query: debtFiltersQuery,
    pause: sectionName !== "debt",
  });
  const projectFiltersResult = useQuery({
    query: projectFiltersQuery,
    pause: sectionName !== "project",
  });
  const commodityFiltersResult = useQuery({
    query: commoditiesFiltersQuery,
    pause: sectionName !== "commodity",
  });

  const [queryResult] = {
    debt: debtFiltersResult,
    project: projectFiltersResult,
    commodity: commodityFiltersResult,
  }[sectionName];

  const fetchedFilters = useMemo(
    () => makeFiltersFromQueryData(queryResult.data, sectionName),
    [queryResult.data, sectionName]
  );

  // const sortFilter = useMemo(() => getSortFilter(sectionName), [sectionName]);

  const filters = useMemo(() => fetchedFilters, [fetchedFilters]);

  const transformedResult = useMemo(
    () => ({
      data: filters,
      fetching: queryResult.fetching,
      error: queryResult.error,
    }),
    [filters, queryResult.fetching, queryResult.error]
  );

  return transformedResult;
};

const DEFAULT_SECTION_VALUE = SECTIONS[0].value;

const CatalogPageConnected = () => {
  const [activeSectionState, setCurrentSectionState] = useUrlState({
    section: DEFAULT_SECTION_VALUE,
  });

  // useMount(() => {
  //   // setCurrentSectionState({ section: DEFAULT_SECTION_VALUE });
  // });

  const activeSectionValue = useMemo(
    () => activeSectionState.section,
    [activeSectionState.section]
  );

  const [selectedFiltersValues, setSelectedFiltersValues] = useState();

  const allFilters = useFilters(activeSectionValue);

  const selectedFilters = useMemo(
    () =>
      !!selectedFiltersValues &&
      !!allFilters.data &&
      Object.fromEntries(
        Object.entries(selectedFiltersValues)
          .map(([id, value]) => {
            const filter = allFilters.data.find((f) => f.id === id);
            if (!filter) return;

            return [
              id,
              {
                filter,
                value,
              },
            ];
          })
          .filter((f) => !!f)
      ),
    [selectedFiltersValues, allFilters.data]
  );

  const cardsData = useCards(activeSectionValue, selectedFilters);

  const onSectionChange = useCallback(
    (sectionValue) => {
      setSelectedFiltersValues(null);
      setCurrentSectionState({ section: sectionValue });
    },
    [setCurrentSectionState]
  );

  return (
    <CatalogPage
      cardsData={cardsData}
      activeSectionValue={activeSectionValue}
      allSections={SECTIONS}
      allFilters={allFilters}
      onSectionChange={onSectionChange}
      onFiltersChange={(newFilters) => {
        setSelectedFiltersValues(newFilters);
      }}
      selectedFiltersValues={selectedFiltersValues}
    />
  );
};

export default CatalogPageConnected;
