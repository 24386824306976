import React, { useState } from "react";
import { useMount } from "ahooks";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import ReportPdfCharts from "./ReportPdfCharts";
import {
  getActivitiesReportsQuery,
  getReportProblems,
  getUnitsByReportWithSumQuery,
  reportResultPdfQuery,
} from "../../api/queries";
import Error404 from "../errors/Error404";

const ReportPdfChartsConnected = () => {
  const [pdfReady, setPdfReady] = useState(false);
  const { reportId, pagination } = useParams();

  useMount(() => {
    setPdfReady(true);
  });

  const [reportResultResponse] = useQuery({
    query: reportResultPdfQuery,
    variables: { id: reportId },
    requestPolicy: "network-only",
  });
  const [actReportsResponse] = useQuery({
    query: getActivitiesReportsQuery,
    variables: { reportId },
  });
  const [problemsResponse] = useQuery({
    query: getReportProblems,
    variables: { reportId },
  });

  const [unitsResponse] = useQuery({
    query: getUnitsByReportWithSumQuery,
    variables: { reportId },
  });

  const units = unitsResponse.data?.eu_taxonomy_units;
  const totalValues =
    unitsResponse.data?.eu_taxonomy_units_aggregate?.aggregate?.sum;

  const problems = problemsResponse.data?.eu_taxonomy_unit_point_decision;
  const actReports = actReportsResponse.data?.eu_taxonomy_activities_reports;
  const report = reportResultResponse.data?.eu_taxonomy_reports_by_pk;

  if (
    reportResultResponse.fetching ||
    actReportsResponse.fetching ||
    problemsResponse.fetching ||
    unitsResponse.fetching
  )
    return null;

  if (
    actReportsResponse.error ||
    problemsResponse.error ||
    unitsResponse.error ||
    reportResultResponse.error ||
    actReports?.length === 0 ||
    !report ||
    !["1", "2"].includes(pagination)
  ) {
    return (
      <div id="PDF_ERROR">
        <Error404 />
      </div>
    );
  }
  return (
    <>
      <ReportPdfCharts
        units={units}
        problems={problems}
        company={report.company}
        paginationIndex={pagination}
        paginationTotal={2}
        totalValues={totalValues}
        actReports={actReports}
        report={report}
        pagination={Number(pagination)}
      />
      {pdfReady && <div id="PDF_READY" />}
    </>
  );
};

export default ReportPdfChartsConnected;
