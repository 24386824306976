import React from "react";
import FrameworkSummary from "./FrameworkSummary";
import { useGetFramework } from "../../hooks/framework";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const FrameworkSummaryConnected = () => {
  const { framework, fetching } = useGetFramework();

  if (fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  return <FrameworkSummary framework={framework} />;
};

export default FrameworkSummaryConnected;
