import React from "react";
import { Table } from "@evercityecosystem/evercity-ui";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "../../antd-ovveride";

const ProjectIdeaNotesTable = ({ pins, onDelete, onDownload }) => (
  <Table dataSource={pins}>
    <Table.Column
      title="Project Title"
      render={(_, record) => record?.data?.summary_project_title}
    />
    <Table.Column
      title="Project Owner"
      render={(_, record) => record?.data?.details_owner_name}
    />
    <Table.Column
      title="Progress"
      render={(_, record) => `${record?.progress}%`}
    />
    <Table.Column title="Status" render={(_, record) => record?.status} />
    <Table.Column
      title="Date created"
      dataIndex="created_at"
      render={(date) => dayjs(date).format("DD.MM.YYYY HH:mm")}
    />
    <Table.Column
      title="Actions"
      dataIndex="id"
      render={(id, record) => (
        <Table.Actions
          actions={[
            {
              key: "download",
              icon: <DownloadOutlined />,
              label: "Download",
              disabled:
                record.status !== "completed" && record.status !== "sample",
              onClick: () => onDownload(id),
            },
            {
              key: "edit",
              icon: <EditOutlined />,
              label: <Link to={`${id}/summary`}>Edit</Link>,
            },
            {
              key: "delete",
              icon: <DeleteOutlined />,
              label: "Delete",
              danger: true,
              onClick: () => onDelete(id),
            },
          ]}
        />
      )}
    />
  </Table>
);

export default ProjectIdeaNotesTable;
