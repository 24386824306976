import React from "react";
import MapWidget from "../MapWidget/MapWidget";
import styles from "./ProjectMap.module.less";

const ProjectMap = () => (
  <div className={styles.container}>
    <MapWidget satelliteView />
  </div>
);

export default ProjectMap;
