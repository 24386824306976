import React from "react";
import { Form, Radio } from "antd";
import { Input } from "@antd-ovveride";
import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;

const ProcessForProject = ({ updateFramework, framework, title }) => (
  <>
    {title}
    <FrameworkSection number="3">
      <FrameworkQuestion
        number="3.1"
        title="How frequently will the selection of assets be made?"
      >
        <FormItem
          name="asset_select_frequency"
          initialValue={framework.asset_select_frequency}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <RadioGroup
            defaultValue={framework.asset_select_frequency}
            onChange={adapterDOMEvent(
              updateFramework("asset_select_frequency")
            )}
          >
            <Radio value="On a quarterly basis">On a quarterly basis</Radio>
            <Radio value="On a half-yearly basis">On a half-yearly basis</Radio>
            <Radio value="On a yearly basis">On a yearly basis</Radio>
          </RadioGroup>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="3.2"
        title="Which department or body will assess the eligibility of projects and allocation of proceeds to Eligible Green Projects?"
      >
        <FormItem
          name="eligible_assess_depart"
          initialValue={framework.eligible_assess_depart}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <Input
            roundCorners
            defaultValue={framework.eligible_assess_depart}
            onBlur={adapterDOMEvent(updateFramework("eligible_assess_depart"))}
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="3.3"
        title="Which department or body will approve the eligibility of projects and allocation of proceeds to Eligible Green Projects?"
      >
        <FormItem
          name="eligible_approve_depart"
          initialValue={framework.eligible_approve_depart}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <Input
            roundCorners
            defaultValue={framework.eligible_approve_depart}
            onBlur={adapterDOMEvent(updateFramework("eligible_approve_depart"))}
          />
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="3.4"
        title="Which department or body will be responsible for monitoring the portfolio, removing assets that are no longer eligible or matured and replacing them with new ones?"
      >
        <FormItem
          name="monitoring_depart"
          initialValue={framework.monitoring_depart}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <Input
            roundCorners
            defaultValue={framework.monitoring_depart}
            onBlur={adapterDOMEvent(updateFramework("monitoring_depart"))}
          />
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
  </>
);

export default ProcessForProject;
