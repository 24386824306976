import React from "react";
import { useParams } from "react-router-dom";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";
import DNSHPage from "./DNSHPage";

const DNSHPageConnected = () => {
  const { unitId } = useParams();
  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
    entityId: unitId,
    group: MESSENGER_GROUPS.TAXONOMY_UNIT.DNSH,
  });

  return <DNSHPage threads={threadsResponse.data} />;
};

export default DNSHPageConnected;
