import React, { useMemo, useState } from "react";
import { Button, Form } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import FrameworkSteps from "../FrameworkSteps/FrameworkSteps";
import styles from "./FrameworkFlow.module.less";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import FrameworkTitle from "../FrameworkTitle/FrameworkTitle";
import ContentTwoColsLayout from "../ContentTwoColsLayout/ContentTwoColsLayout";
import FrameworkModal from "../FrameworkModal/FrameworkModal";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import { Title } from "../../antd-ovveride";
import ScrollToThisOnRedirect from "../LkLayout/ScrollToThisOnRedirect";
import LkLayout from "../LkLayout/LkLayout";

const { Item: FormItem } = Form;

const FrameworkFlow = ({
  contentLoading,
  steps,
  stepIndex,
  setStepIndex,
  updateFramework,
  framework,
  progress,
  onGenerate,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const nextStepText = useMemo(() => {
    if (framework.status === "done" && stepIndex === 6) {
      return "Update framework";
    }
    if (stepIndex === 6) {
      return "Create framework";
    }

    return "Next step";
  }, [stepIndex, framework]);
  const handleSave = () => {
    updateFramework("status")("done");
    setIsModalVisible(false);
    navigate("/company/frameworks");
  };

  return (
    <LkLayout>
      <ContentTwoColsLayout
        aside={
          <FrameworkSteps
            progress={progress}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            steps={steps}
          />
        }
      >
        <ScrollToThisOnRedirect />
        <Form
          validateTrigger="onFinish"
          className={styles.framework_form}
          onFinish={() =>
            stepIndex >= 6
              ? setIsModalVisible(true)
              : setStepIndex(stepIndex + 1)
          }
        >
          <div className={styles.title}>
            <FormItem
              name="name"
              initialValue={framework?.name}
              rules={[{ required: stepIndex === 6 }]}
            >
              <FrameworkTitle
                defaultValue={framework?.name}
                onBlur={adapterDOMEvent(updateFramework("name"))}
                name="name"
                inputPlaceholder="My Framework"
              />
            </FormItem>
          </div>
          {contentLoading ? (
            <LoadingSpinner size="large" full />
          ) : (
            <Outlet
              context={{
                title: (
                  <Title className={styles.step__title} level={2} size="medium">
                    {stepIndex + 1}. {steps[stepIndex].title}
                  </Title>
                ),

                setIsModalVisible,
                framework,
                updateFramework,
              }}
            />
          )}
          <div className={styles.buttons}>
            {stepIndex !== 0 && (
              <Button size="large" onClick={() => setStepIndex(stepIndex - 1)}>
                Back
              </Button>
            )}
            <Button type="primary" htmlType="submit" size="large">
              {nextStepText}
            </Button>
          </div>
        </Form>
        <FrameworkModal
          onGenerate={onGenerate}
          onSave={handleSave}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </ContentTwoColsLayout>
    </LkLayout>
  );
};

export default FrameworkFlow;
