import React from "react";
import { useQuery } from "urql";
import { useParams } from "react-router-dom";
import { getUnitsByReportWithSumQuery } from "../../../api/queries";
import SubstantialContribution from "./SubstantialContribution";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const SubstantialContributionConnected = ({ paramName }) => {
  const { reportId } = useParams();

  const [{ data, fetching }] = useQuery({
    query: getUnitsByReportWithSumQuery,
    variables: { reportId },
  });

  const units = data?.eu_taxonomy_units;
  const totalValue =
    data?.eu_taxonomy_units_aggregate?.aggregate?.sum?.[paramName];

  if (fetching) return <LoadingSpinner fill />;

  return (
    <SubstantialContribution
      units={units}
      totalValue={totalValue}
      paramName={paramName}
    />
  );
};

export default SubstantialContributionConnected;
