import React from "react";
import classnames from "classnames";
import { Select as BaseSelect } from "antd";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/down-arrow.svg";
import styles from "./Select.module.less";

const Select = ({
  className,
  variant,
  capitalize,
  dropdownClassName,
  ...props
}) => (
  <BaseSelect
    className={classnames(
      styles.select,
      variant && styles[`select--${variant}`],
      { [styles["select--capitalize"]]: capitalize },
      className
    )}
    dropdownClassName={classnames(
      {
        [styles["dropdown--capitalize"]]: capitalize,
      },
      dropdownClassName
    )}
    suffixIcon={<DownArrowIcon />}
    {...props}
  />
);
Select.Option = BaseSelect.Option;
Select.OptGroup = BaseSelect.OptGroup;

export default Select;
