import React, { useMemo } from "react";
import { useQuery } from "urql";
import { useParams } from "react-router-dom";

import {
  getActivitiesReportsQuery,
  getReportQuery,
  getUnitsByReportQuery,
} from "../../api/queries";

import useActReportsWithUnits from "../../hooks/taxonomy/useActReportsWithUnits";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ResultsActivitiesTable from "./ResultsActivitiesTable";

const addColor = (data, colorsList) =>
  data?.map((item, index) => ({
    ...item,
    color: colorsList[index],
  }));

const ResultsActivitiesTableConnected = ({ colorsList }) => {
  const { reportId } = useParams();
  const [activitiesReportsResponse] = useQuery({
    query: getActivitiesReportsQuery,
    variables: { reportId },
    requestPolicy: "network-only",
  });
  const [reportResponse] = useQuery({
    query: getReportQuery,
    variables: {
      id: reportId,
    },
  });
  const activitiesReports =
    activitiesReportsResponse.data?.eu_taxonomy_activities_reports;
  const report = reportResponse?.data?.eu_taxonomy_reports_by_pk;

  const [unitsResponse] = useQuery({
    query: getUnitsByReportQuery,
    variables: { reportId },
    requestPolicy: "network-only",
  });

  const units = unitsResponse.data?.eu_taxonomy_units;

  const actReportsWithUnits = useActReportsWithUnits(activitiesReports, units);

  const colorizedActReportsWithUnits = useMemo(
    () => addColor(actReportsWithUnits, colorsList),
    [actReportsWithUnits, colorsList]
  );

  const fetching = unitsResponse.fetching || activitiesReportsResponse.fetching;
  const error = unitsResponse.error || activitiesReportsResponse.error;

  if (error) {
    console.error(error);
  }

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }

  return actReportsWithUnits?.length ? (
    <ResultsActivitiesTable
      actReports={colorizedActReportsWithUnits}
      reportCurrency={report?.currency}
    />
  ) : (
    <div />
  );
};

export default ResultsActivitiesTableConnected;
