import React from "react";
import { Row, Col, Typography } from "antd";

import Marker from "./Marker";

import { decimalToPercents } from "../../helpers/percents";
import { thousandFormatter } from "../../helpers/thousandFormatter";

import styles from "./Tooltip.module.less";

const { Text } = Typography;

const CustomBarTooltip = ({ active, payload }) => {
  const { total, currency } = payload?.[0]?.payload || {};
  if (active && payload?.length) {
    return (
      <div className={styles.tooltip}>
        <ul className={styles.list}>
          {payload.map((item) => (
            <li className={styles.listItem}>
              <Marker color={item.color} />
              <Row justify="space-between">
                <Col span={12}>
                  <Text>{`${item.name} (${decimalToPercents(
                    item.value
                  )})`}</Text>
                </Col>
                <Col span={12} align="right">
                  {/* Странно конечно передавать ключ через Unit, возможно стоит поискать другие пути */}
                  <Text>{`${thousandFormatter(
                    item.payload[item.unit]
                  )} ${currency}`}</Text>
                </Col>
              </Row>
            </li>
          ))}
        </ul>

        <Row className={styles.footer}>
          <Col span={12}>
            <Text strong>Total:</Text>
          </Col>
          <Col span={12} align="right">
            <Text>{`${thousandFormatter(total)} ${currency}`}</Text>
          </Col>
        </Row>
      </div>
    );
  }

  return null;
};

export default CustomBarTooltip;
