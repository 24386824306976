import React from "react";
import { Link, Space, ContentLayout } from "@evercityecosystem/evercity-ui";
import { PlusCircleOutlined } from "@ant-design/icons";
import styles from "./ProjectIdeaNotesPage.module.less";
import ProjectIdeaNotesTableConnected from "../ProjectIdeaNotesTable/ProjectIdeaNotesTableConnected";
import LkLayout from "../LkLayout/LkLayout";

const ProjectIdeaNotesPage = () => (
  <LkLayout>
    <ContentLayout gaps="off">
      <Space justifyContent="end" block className={styles.toolbar}>
        <Link type="btn-primary" to="_">
          <PlusCircleOutlined /> New PIN
        </Link>
      </Space>
      <ProjectIdeaNotesTableConnected />
    </ContentLayout>
  </LkLayout>
);
export default ProjectIdeaNotesPage;
