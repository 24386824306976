import React, { useEffect, useState } from "react";
import { notification } from "antd";
import {
  Input,
  ExternalLink,
  Button,
  Space,
  Checkbox,
  Form,
} from "@evercityecosystem/evercity-ui";
import { useMutation } from "urql";
import { UserOutlined, MailOutlined, LeftOutlined } from "@ant-design/icons";

import { useNavigate, useNavigationType } from "react-router-dom";
import { insertMarketingDemoAccountsMutation } from "../../api/mutations";

import styles from "./Form.module.less";

const RequestDemoForm = ({ handleSetStep, hendleSetUserInfo }) => {
  const [dirtyForm, setDirtyForm] = useState(false);
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const [form] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const originUrl = window.location.href;

  const [{ fetching, error }, addMarketingDemo] = useMutation(
    insertMarketingDemoAccountsMutation
  );

  const onGoBack = () => {
    navigate(-1);
  };

  const submit = async ({ email, name }) => {
    setDirtyForm(true);
    try {
      await form.validateFields();
    } catch {
      return;
    }

    const variables = {
      email,
      name,
      origin_url: originUrl.toString(),
      company_name: null,
      product_interests: null,
    };

    addMarketingDemo(variables)
      .then((result) => {
        if (result.error) {
          return console.error("Oh no!", result.error);
        }
        hendleSetUserInfo({
          id: result.data.insert_marketing_demo_accounts.returning[0].id,
        });
        handleSetStep(1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onError = (errorInfo) => {
    console.error("Submit error: ", errorInfo);
  };

  useEffect(() => {
    if (error) {
      notification.warn({
        message:
          "Looks like you have already requested a demo. We will get back to you shortly! If you have additional questions, please contact info@evercity.io",
      });
    }
  }, [error]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="request-demo"
      onFinish={submit}
      onFinishFailed={onError}
    >
      <Form.Item
        rules={
          dirtyForm && [
            { required: true, message: "Required field" },
            { min: 3, message: "must be at least 3 characters" },
          ]
        }
        name="name"
        shouldUpdate
      >
        <Input
          size="large"
          roundCorners="medium"
          placeholder="Your name"
          prefix={<UserOutlined />}
        />
      </Form.Item>

      <Form.Item
        name="email"
        shouldUpdate
        rules={
          dirtyForm && [
            { required: true, message: "Required field" },
            { type: "email", message: "Email is not a valid" },
          ]
        }
      >
        <Input
          roundCorners="medium"
          placeholder="Work email"
          prefix={<MailOutlined />}
          size="large"
        />
      </Form.Item>
      <Checkbox
        className={styles.confirm}
        onChange={(e) => setSubmitDisabled(!e.target.checked)}
      >
        By sending form, you accept our{" "}
        <ExternalLink
          href="https://storage.googleapis.com/evercity-public/Privacy%20Policy.pdf"
          rel="nofollow noopener noreferre noreferrer"
        >
          Privacy Policy
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink
          href="https://storage.googleapis.com/evercity-public/Terms%20of%20service.pdf"
          rel="nofollow noopener noreferre noreferrer"
        >
          Terms of Service.
        </ExternalLink>
      </Checkbox>

      <Space block justifyContent="space-between">
        {navigationType.toString() !== "POP" && (
          <Button
            roundCorners
            onClick={() => onGoBack()}
            icon={<LeftOutlined />}
            type="text"
          >
            Go back
          </Button>
        )}
        <Button
          roundCorners
          disabled={submitDisabled}
          htmlType="submit"
          loading={fetching}
          type="primary"
        >
          Continue
        </Button>
      </Space>
    </Form>
  );
};

export default RequestDemoForm;
