import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./PhrasesSeparator.module.less";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";

const Point = ({ defaultValue, onRemove, id, onEdit }) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <div className={styles.point}>
      <input
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onEdit(id, e.target.value);
            e.target.blur();
            e.preventDefault();
          }
        }}
        type="text"
        value={value}
        className={styles.point__input}
        onBlur={adapterDOMEvent((content) => onEdit(id, content))}
        onChange={adapterDOMEvent((text) => setValue(text))}
      />
      <Button
        icon={<DeleteOutlined />}
        onClick={() => onRemove(id)}
        className={styles.point__action}
      />
    </div>
  );
};

export default Point;
