import React from "react";
import { Tooltip } from "antd";
import styles from "./AlignedStatus.module.less";

const AlignedStatus = () => (
  <Tooltip title="Aligned" placement="top">
    <div className={styles.alignedStatus} />
  </Tooltip>
);

export default AlignedStatus;
