import React from "react";
import { useParams } from "react-router-dom";
import UnitFlowHeader from "./UnitFlowHeader";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES } from "../../data/messenger";

const UnitFlowHeaderConnected = () => {
  const { unitId } = useParams();
  const isNewUnit = unitId === "_";
  const [{ data: threads, fetching: loadingThreads }] = useGetThreads(
    {
      entityId: unitId,
      entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
    },
    {
      pause: isNewUnit,
    }
  );

  return (
    <UnitFlowHeader
      threads={threads}
      loading={loadingThreads}
      isNewUnit={isNewUnit}
    />
  );
};

export default UnitFlowHeaderConnected;
