import React from "react";
import { Select } from "@antd-ovveride";

const SelectFilter = ({ filter, value, onChange }) => (
  <Select value={value} onChange={onChange} mode="multiple">
    {filter.options.map((option) => (
      <Select.Option value={option} key={option}>
        {option}
      </Select.Option>
    ))}
  </Select>
);

export default SelectFilter;
