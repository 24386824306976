import React from "react";
import {
  Avatar,
  BellOutlined,
  Layout,
  Badge,
  Text,
  Button,
  DownOutlined,
  ExternalLink,
} from "@evercityecosystem/evercity-ui";
import { useMatches, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/user";
import useSubscribeOnNotifications from "../../hooks/useSubscribeOnNotifications";
import logoFull from "./assets/logo_text.svg";

const logoFullElement = (
  <img
    alt="logo"
    src={logoFull}
    style={{
      maxWidth: 90,
      width: 90,
      maxHeight: 38,
      objectFit: "contain",
      objectPosition: "left center",
    }}
  />
);

const AppHeader = ({ tag, title, goBack, content }) => {
  const matches = useMatches();

  const navigate = useNavigate();
  const [userState] = useCurrentUser();

  const { notificationsCount, onReadNotifications } =
    useSubscribeOnNotifications();

  const userIsAuth = !userState.error && !!userState?.data?.id;

  const routes = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({
      breadcrumbName: match.handle.crumb(match.data),
      path: match.pathname,
    }));
  return (
    <Layout.Header
      tag={tag}
      title={title}
      onBack={
        goBack &&
        (() => {
          navigate(-1);
        })
      }
      breadcrumb={{ routes }}
      logo={logoFullElement}
      actions={
        userIsAuth ? (
          <>
            <Button
              type="text"
              icon={
                <Badge count={notificationsCount} size="small">
                  <BellOutlined />
                </Badge>
              }
              onClick={() => onReadNotifications()}
            />
            <ExternalLink href={process.env.REACT_APP_AUTH_URL}>
              <Avatar size="small" name={userState?.data?.display_name} />
            </ExternalLink>
            <Text type="secondary">
              EN <DownOutlined />
            </Text>
          </>
        ) : null
        // <Link
        //   data-cy="login"
        //   icon={<LoginOutlined />}
        //   type="btn-primary"
        //   to="/login"
        // >
        //   Login
        // </Link>
      }
    >
      {content}
    </Layout.Header>
  );
};

export default AppHeader;
