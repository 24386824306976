import React from "react";
import ReportsPage from "./ReportsPage";
import { useCurrentUser } from "../../hooks/user";
import useSampleReport from "../../hooks/taxonomy/useSampleReport";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const ReportsPageConnected = () => {
  const { sampleReport, fetching } = useSampleReport();
  const [currentUserState] = useCurrentUser();
  const { company } = currentUserState.data;

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }

  return <ReportsPage company={company} sampleReport={sampleReport} />;
};

export default ReportsPageConnected;
