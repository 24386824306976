import React from "react";
import { Checkbox } from "antd";

import styles from "./GoalsFilter.module.less";

import SDGs from "../../data/SDGs";

const GoalsFilter = ({ value, onChange }) => (
  <Checkbox.Group className={styles.goals} value={value} onChange={onChange}>
    {SDGs.map((goal) => (
      <Checkbox
        className={styles.goal}
        key={goal.id}
        style={{ color: goal.color }}
        value={goal.id}
      >
        {goal.icon}
      </Checkbox>
    ))}
  </Checkbox.Group>
);

export default GoalsFilter;
