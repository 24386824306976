import React from "react";
import { useQuery } from "urql";
import { Empty, notification } from "antd";
import { getDocsQuery } from "../../api/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import RecentFilesTab from "./RecentFilesTab";

const RecentFilesTabConnected = (props) => {
  const [recentDocsResponse] = useQuery({
    query: getDocsQuery,
    requestPolicy: "network-only", // TODO: обновлять через кеш
  });
  if (recentDocsResponse.fetching) {
    return <LoadingSpinner />;
  }
  if (recentDocsResponse.error) {
    notification.error({
      message: "Error load recent files",
      description: "Please try again later.",
    });
    return null;
  }
  const recentDocs = recentDocsResponse.data.docs;
  if (!recentDocs.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return <RecentFilesTab {...props} recentDocs={recentDocs} />;
};

export default RecentFilesTabConnected;
