import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { nanoid } from "nanoid";
import { Button, Input } from "antd";
import styles from "./PhrasesSeparator.module.less";
import Point from "./Point";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";

const adapterPhrasesSeparator = (items, revert = false) =>
  revert
    ? items?.map((item, index) => ({
        id: index,
        text: item,
      }))
    : items?.map((item) => item.text);

const PhrasesSeparator = ({
  defaultValue,
  onChange,
  placeholder,
  limit = 10,
}) => {
  const [items, setItems] = useState(
    adapterPhrasesSeparator(defaultValue, true) || []
  );
  const [value, setValue] = useState("");

  const remove = (id) => {
    const filteredArray = items.filter((el) => el.id !== id);
    setItems(filteredArray);
    onChange(adapterPhrasesSeparator(filteredArray));
  };

  const add = (text) => {
    if (!text) {
      return;
    }
    const id = nanoid();

    const newState = [...items, { text, id }];
    setItems(newState);
    onChange(adapterPhrasesSeparator(newState));
    setValue("");
  };

  const edit = (id, content) => {
    const newState = items.map((item) => {
      if (item.id === id) {
        return {
          id,
          text: content,
        };
      }
      return item;
    });
    setItems(newState);
    onChange(adapterPhrasesSeparator(newState));
  };

  return (
    <div>
      <ul className={styles.items}>
        {items.map((item) => (
          <li className={styles.item}>
            <Point
              id={item.id}
              defaultValue={item.text}
              onRemove={remove}
              onEdit={edit}
            />
          </li>
        ))}
      </ul>

      {items.length < limit && (
        <div className={styles.container}>
          <Input
            onPressEnter={(e) => {
              add(value);
              e.preventDefault();
            }}
            placeholder={placeholder}
            className={styles.input}
            value={value}
            onChange={adapterDOMEvent(setValue)}
            suffix={
              <Button
                className={styles.add}
                onClick={() => add(value)}
                type="text"
              >
                <PlusOutlined /> Add
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
};

export default PhrasesSeparator;
