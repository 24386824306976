import React from "react";
import {
  Section,
  TextArea,
  Radio,
  Checkbox,
  Title,
  InlineNote,
  Form,
  Hint,
} from "@evercityecosystem/evercity-ui";
import AttachFileInput from "../AttachFileInput/AttachFileInput";
import FilesTagsListInput from "../FilesTagsListInput/FilesTagsListInput";
import QuestionWithThreads, {
  GroupContext,
} from "../QuestionWithThreads/QuestionWithThreads";

const ProjectIdeaNoteAssessment = ({
  impactIndicators,
  onOpenDiscussionsDrawer,
  threads,
}) => (
  <GroupContext.Provider value="viability">
    <Title level={3} size="middle">
      5. Preliminary assessment of viability
    </Title>
    <Section number="5.1" title="Real">
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_leakage"
        optional
        number="5.1.1"
        title="No leakage"
        description="Briefly explain how GHG Reductions as a result of the Project activity do not result in increases in emissions in other locations/ jurisdictions (i.e. minimize leakage) or how such increases will be taken into consideration in the quantification of GHG Reductions."
      >
        <InlineNote bodyPaddingBottom={10}>
          Example: When installing PV panels, their transport towards the site
          may be done by the vehicles which cause GHG emissions due to fuel
          combustion. In order to minimize it, their transport could be done
          using train or electric vehicles. When planting different agricultural
          species, the project could take in consideration that there is no
          displacement of agriculture activities. When a forest is protected
          from logging, the project could take into account that it does not
          lead to an increase in logging somewhere else.
        </InlineNote>
        <Form.DiscussedItem
          name="assessment_leakage"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_permanence"
        optional
        number="5.1.2"
        title="Permanence"
        description="Briefly explain the steps that will be taken to ensure that GHG Reductions as a result of the Project activity will endure for a period of at least 100 years (i.e. permanence)?"
      >
        <Form.DiscussedItem
          name="assessment_permanence"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </Section>
    <Section number="5.2" title="Quantifiable">
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_quantifiable"
        optional
        number="5.2.1"
        title="Demonstrate how the project will be quantifiable"
        description="Demonstrate how the proposed Project is or will be quantifiable."
      >
        <Form.DiscussedItem
          name="assessment_quantifiable"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_monitoring_data"
        optional
        number="5.2.2"
        title="Key measurement and monitoring data required"
        description="What are the key measurement and monitoring data required to prove GHG Reductions?"
      >
        <Form.DiscussedItem name="assessment_monitoring_data">
          <Checkbox.Group
            options={[
              ...(impactIndicators?.map((indicator) => ({
                label: indicator.name,
                value: indicator.name,
              })) || []),
              {
                label: "Other (specify)",
                value: "Other (specify)",
                specifiable: true,
              },
            ]}
          />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_collection_methods"
        optional
        number="5.2.3"
        title="Data collection methods"
        description="How will you collect data to ensure accuracy and completeness?"
      >
        <InlineNote bodyPaddingBottom={10}>
          Example: For PV panels the net electricity supplied to the grid is
          measured continuously and recorded monthly by a meter; for
          reforestation the area covered by forest or a number of trees recorded
          annually; for cookstoves the number of devices distributed per
          household recorded monthly.
        </InlineNote>
        <Form.DiscussedItem
          name="assessment_collection_methods"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </Section>
    <Section number="5.3" title="Clear Ownership/ Counted Once">
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        optional
        property="assessment_ownership"
        number="5.3.1"
        title="GHG reductions ownership"
        description="I assert to have a superior claim of ownership with respect to the GHG Reductions to be achieved by carrying out the Project."
      >
        <Form.DiscussedItem name="assessment_ownership">
          <Radio.Group optionType="button" options={["Yes", "No"]} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_ownership_demonstrated"
        optional
        number="5.3.2"
        title="Demonstration of GHG reductions ownership"
        description="Please explain how ownership of GHG Reductions will be demonstrated. Describe or attach the evidence that will be used to support this assertion (ownership of land or equipment, quitclaims, contractual arrangements, etc.)."
      >
        <Form.DiscussedItem
          name="assessment_ownership_demonstrated"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
        <Form.DiscussedItem
          name="assessment_ownership_demonstrated_docs"
          noStyle
        >
          <AttachFileInput />
        </Form.DiscussedItem>
        <Form.DiscussedItem name="assessment_ownership_demonstrated_docs">
          <FilesTagsListInput />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_other_programs"
        optional
        number="5.3.3"
        title="Recognition of reductions under other programs"
        description="I assert that these reductions are not included in a national inventory reported to the UNFCCC."
      >
        <Form.DiscussedItem name="assessment_other_programs">
          <Radio.Group optionType="button" options={["Yes", "No"]} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </Section>
    <Section number="5.4" title="Baseline and Project Justification">
      <Section number="5.4.1" title="Baseline scenario justification">
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="assessment_probable_baseline_scenario"
          optional
          title={
            <>
              a) Probable{" "}
              <Hint text="The baseline scenario is a reasonable and conservative representation of what would occur in the absence of the Project. Responses should clearly demonstrate how the Baseline Scenario and Project will be justified. This section will be modified as Standardized Baselines are issued or clarified.">
                baseline scenario
              </Hint>
            </>
          }
          description="Identify a probable and realistic baseline scenario."
        >
          <Form.DiscussedItem
            name="assessment_probable_baseline_scenario"
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={1700} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
        <QuestionWithThreads
          threads={threads}
          onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
          property="assessment_method_baseline_scenario"
          optional
          title="b) How the baseline scenario will result will result in a conservative estimate of GHG Reductions to be achieved by the Project"
          description={
            <>
              Describe how the identified baseline scenario will result in a
              conservative estimate of GHG Reductions to be achieved by the
              Project.
              <ul>
                <li>
                  If applicable, list any government laws, regulations or other
                  legal requirements that otherwise require the activity
                  described as the Project scenario.
                </li>
                <li>
                  Describe the regulatory framework within which the baseline
                  and Project condition operate. Demonstrate how the baseline
                  scenario adheres to the regulatory requirements.
                </li>
                <li>
                  List any existing or proposed provincial or federal incentives
                  or funding, including tax incentives, which may affect the
                  baseline scenario. Identify amounts.
                </li>
              </ul>
            </>
          }
        >
          <Form.DiscussedItem
            name="assessment_method_baseline_scenario"
            trigger="onBlur"
            validateTrigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={1700} showCount />
          </Form.DiscussedItem>
        </QuestionWithThreads>
      </Section>
    </Section>
    <Section number="5.5" title="Additionally">
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_obstacles"
        optional
        description="Identify one or more of the following obstacles that prevent the Project from being the baseline scenario. In other words, you have to prove that the project wouldn’t have happened if it hadn’t received any carbon financing."
      >
        <Form.DiscussedItem
          name="assessment_obstacles"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_financial_justification"
        optional
        number="5.5.1"
        title="Financial"
        description="Provide a financial justification which may include cash flow analysis for the anticipated validation period with Net Present Value (NPV) or Internal Rate of Return (IRR) calculations. Provide or describe supporting documentation (e.g. procurement records, financial agreements, etc.)."
      >
        <Form.DiscussedItem
          name="assessment_financial_justification"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" wordLimit={1700} roundCorners />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_evidence_of_challenges"
        optional
        number="5.5.2"
        title="Technological"
        description="Describe and attach evidence of the challenges faced in adopting that technology in the industry and/or geographic location and for each describe how recognition as an Emission Offset helps to address that challenge."
      >
        <Form.DiscussedItem
          name="assessment_evidence_of_challenges"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" wordLimit={1700} roundCorners />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="assessment_social_obstacles"
        optional
        number="5.5.3"
        title="Social"
        description="Describe obstacles in this category (may include community sentiment or commercial or legal barriers, clarify the project is not in any way related to a given regulation)."
      >
        <Form.DiscussedItem
          name="assessment_social_obstacles"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" wordLimit={1700} roundCorners />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </Section>
  </GroupContext.Provider>
);

export default ProjectIdeaNoteAssessment;
