import React from "react";
import { Radio } from "antd";
import styles from "./CategoriesSwitcher.module.less";

const CategoriesSwitcher = ({ categories, selectedCategory, onChange }) => (
  <Radio.Group
    defaultValue={selectedCategory}
    buttonStyle="solid"
    onChange={(e) => onChange(e.target.value)}
    className={styles.categoriesSwitcher}
    size="small"
  >
    {categories.map((category) => (
      <Radio.Button
        className={styles.radioBtn}
        key={category.id}
        value={category.id}
      >
        {category.label}
      </Radio.Button>
    ))}
  </Radio.Group>
);

export default CategoriesSwitcher;
