export const activityTypesColors = {
  mitigation: {
    green: "#A0D911",
    transitional: "#D3ADF7",
    enabling: "#FFE58F",
    adapted: "#ADC6FF",
  },
  adaptation: {
    green: "#95DE64",
    transitional: "#D3ADF7",
    enabling: "#FADB14",
    adapted: "#D4380D",
  },
};

export const activitiesColorsList = [
  "#87E8DE",
  "#FF85C0",
  "#FFA940",
  "#FADB14",
  "#ADC6FF",
  "#FF7A45",
  "#A0D911",
  "#597EF7",
  "#FA8C16",
  "#EB2F96",
  "#FFA39E",
  "#95DE64",
  "#D4380D",
  "#AD6800",
  "#237804",
  "#1D39C4",
  "#08979C",
  "#FFADD2",
  "#614700",
];
