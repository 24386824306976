import React from "react";
import { Col, Row } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import classNames from "classnames";
import SummarySection from "../SummarySection/SummarySection";
import { Text, Title } from "../../antd-ovveride";
import List from "../List/List";
import styles from "./ManagementOfProceeds.module.less";

const ManagementOfProceedsSummary = ({ framework, className, ...props }) => (
  <SummarySection
    title="Management of proceeds"
    className={classNames(className, styles.managmentProceed)}
    {...props}
  >
    <Row className={styles.method}>
      <Col span={9}>
        <Title level={5}>Method for management of proceeds</Title>
        <Text>
          <CheckOutlined /> {framework.manage_proceeds_approach}
        </Text>
      </Col>
      <Col span={11} offset={4}>
        <Title level={5}>Approach for tracking of proceeds</Title>
        <Text>
          <CheckOutlined /> {framework.tracking_proceeds_approach}
        </Text>
      </Col>
    </Row>
    <Row className={styles.method}>
      <Col span={9}>
        <Title level={5}>Look-back period for refinancing </Title>
        <List
          items={[
            `For assets: ${framework.lookback_assets} years`,
            `For expenditures: ${framework.lookback_expend} years`,
            `For investments: ${framework.lookback_invest} years`,
            `For projects: ${framework.lookback_projects} years`,
          ]}
        />
      </Col>
      <Col span={11} offset={4}>
        <Title level={5}>Timeframe for allocation of proceeds</Title>
        {framework.allocation_at_issuance === "Yes" && (
          <Text>
            <CheckOutlined /> At issuance
          </Text>
        )}
        {framework.allocation_at_issuance === "No" && (
          <Text>
            <CheckOutlined /> {framework.allocation_at_issuance}
          </Text>
        )}
      </Col>
    </Row>
  </SummarySection>
);

export default ManagementOfProceedsSummary;
