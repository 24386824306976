import React, { useEffect, useMemo, useState } from "react";

import EUNAcodes from "./ISOcodes";

export const CountryContext = React.createContext();

const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState();
  useEffect(() => {
    if (process.env.REACT_APP_CDN_URL) {
      fetch(process.env.REACT_APP_CDN_URL)
        .then((response) => response.text())
        .then((data) => {
          const loc = data
            .split("\n")
            .find((str) => str.startsWith("loc="))
            .replace(/loc=/, "");
          setCountry(loc);
        });
    }
  }, []);

  const countryConfig = useMemo(
    () => ({
      country,
      inEUNA: EUNAcodes.includes(country),
    }),
    [country]
  );

  return (
    <CountryContext.Provider value={countryConfig}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryProvider;
