import React from "react";
import {
  TextArea,
  InputNumber,
  Title,
  Form,
  InlineNote,
} from "@evercityecosystem/evercity-ui";
import { useOutletContext } from "react-router-dom";
import QuestionWithThreads, {
  GroupContext,
} from "../QuestionWithThreads/QuestionWithThreads";

const ProjectIdeaNoteExpectations = ({ onOpenDiscussionsDrawer, threads }) => {
  const { withoutThreshold } = useOutletContext();
  return (
    <GroupContext.Provider value="emission">
      <Title level={3} size="middle">
        3. Expected greenhouse gas emission reductions
      </Title>
      <QuestionWithThreads
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property="expectations_scale"
        optional
        number="3.1"
        title="Project Scale"
        description="Specify the total estimated GHG abated (in metric tons of CO2-equivalent)."
      >
        <Form.DiscussedItem
          name="expectations_scale"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <InputNumber roundCorners size="large" controls={false} />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        optional={withoutThreshold}
        property="expectations_summary"
        number="3.2"
        title="Summary description of how the Project is expected to generate GHG Reductions"
        description="Provide a summary description of how the Project is expected to generate GHG Reductions."
      >
        <InlineNote bodyPaddingBottom={10}>
          Please use the following structure: The Project is reducing GHG
          emissions through improved forest management of the project area
          forest, increasing protected buffers around waterways, protecting
          sensitive habitats, and lowering the overall annual allowable harvest
          of the project area.
        </InlineNote>
        <Form.DiscussedItem
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          name="expectations_summary"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property="expectations_calculation_method"
        number="3.3"
        title="Calculation method"
        description="How did you calculate your GHG Reductions?"
      >
        <InlineNote bodyPaddingBottom={10}>
          Example: For an agricultural project, you would identify potential
          sources of emissions (i.e. carbon dioxide emissions from fossil fuel
          combustion and nitrous oxide emissions from nitrogen fertilizers) and
          calculate them by using the equations of the chosen methodology.
        </InlineNote>
        <Form.DiscussedItem
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          name="expectations_calculation_method"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property="expectations_baseline_scenario"
        number="3.4"
        title="Baseline scenario"
        description="Provide a summary description of the probably and realistic baseline scenario."
      >
        <InlineNote bodyPaddingBottom={10}>
          Example: For an agricultural project, a baseline scenario would
          include the description of the existing farming practices. These can
          include the documented use of fertilizers, practices of cover crop,
          irrigation system and crop residue management over a period of time.
          Highlighted text (baseline scenario): The baseline scenario is a
          reasonable and conservative representation of what would occur in the
          absence of the project.
        </InlineNote>
        <Form.DiscussedItem
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          name="expectations_baseline_scenario"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property="expectations_additionally"
        number="3.5"
        title="Additionally"
        description={
          'Describe how this project is "Additional" would not have happened if not for offsets. In other words, you have to prove that the project wouldn’t have happened if it hadn’t received any carbon financing.'
        }
      >
        <Form.DiscussedItem
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          name="expectations_additionally"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property="expectations_monitoring"
        number="3.6"
        title="Monitoring"
        description="Provide a summary description of the key measurement and monitoring data required and how you will collect it. Give a description of measurement methods and procedures applied, frequency of data collection, equipment used etc. "
      >
        <Form.DiscussedItem
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          name="expectations_monitoring"
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </GroupContext.Provider>
  );
};

export default ProjectIdeaNoteExpectations;
