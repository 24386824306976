import React from "react";
import {
  Button,
  Space,
  PlusCircleOutlined,
  ContentLayout,
} from "@evercityecosystem/evercity-ui";

import LkLayout from "../LkLayout/LkLayout";
import TeamTableConnected from "./TeamTableConnected";
import InviteModal from "../InviteModal/InviteModal";

import styles from "./TeamPage.module.less";

const TeamPage = ({ onInvite, inviteLink, Modal, close }) => (
  <LkLayout>
    <ContentLayout gaps="off">
      <Space justifyContent="end" block className={styles.toolbar}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => onInvite()}
        >
          Add team member
        </Button>
      </Space>
      <TeamTableConnected />
    </ContentLayout>
    <InviteModal
      Modal={Modal}
      close={close}
      inviteLink={inviteLink}
      onOk={() => {
        close();
      }}
    />
  </LkLayout>
);

export default TeamPage;
