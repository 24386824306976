import React from "react";
import { Button, Form } from "antd";
import { Input, TextArea } from "../../antd-ovveride";

const OfferForm = ({ onSubmit, loading }) => {
  const handleSubmit = ({ price, amount, ...values }) => {
    onSubmit({
      price,
      amount,
      total: price * amount,
      ...values,
    });
  };

  return (
    <Form layout="vertical" name="offer" onFinish={handleSubmit}>
      <Form.Item
        name="amount"
        label="Amount"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          data-cy="offer-amount"
          size="large"
          type="number"
          suffix={
            <span>
              tCO<sub>2</sub>e
            </span>
          }
        />
      </Form.Item>
      <Form.Item
        name="price"
        label="Price"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          data-cy="offer-price"
          size="large"
          type="number"
          min={0}
          disableControls
          suffix={
            <span>
              EUR/tCO<sub>2</sub>e
            </span>
          }
        />
      </Form.Item>
      <Form.Item
        label="Total"
        shouldUpdate
        rules={[
          {
            required: true,
          },
        ]}
      >
        {({ getFieldValue }) => (
          <Input
            data-cy="offer-total"
            size="large"
            type="number"
            min={0}
            disabled
            value={getFieldValue("amount") * getFieldValue("price") || 0}
            controls={false}
            suffix="EUR"
          />
        )}
      </Form.Item>
      <Form.Item
        name="message"
        label="Message for the Project Manager (optional)"
      >
        <TextArea data-cy="offer-message" autoSize />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          data-cy="btn-make-offer"
          size="large"
          type="primary"
          htmlType="submit"
        >
          Make Offer
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OfferForm;
