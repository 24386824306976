import React, { useState } from "react";
import DetectableOverflow from "react-detectable-overflow";
import { Tooltip } from "antd";

const TooltipWithOverflow = ({ visible, children, ...tooltipArgs }) => {
  // defaultVisible not working
  const [isOverflowed, setIsOverflowed] = useState(false);
  return (
    <DetectableOverflow onChange={setIsOverflowed}>
      <Tooltip visible={isOverflowed && visible} {...tooltipArgs}>
        {children}
      </Tooltip>
    </DetectableOverflow>
  );
};

export default TooltipWithOverflow;
