import React from "react";
import { useQuery } from "urql";
import { useParams } from "react-router-dom";
import ProjectIdeaNoteSummary from "./ProjectIdeaNoteSummary";
import { goalsQuery } from "../../api/queries";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const ProjectIdeaNoteSummaryConnected = () => {
  const { pinId } = useParams();
  const [goalsResponse] = useQuery({
    query: goalsQuery,
  });

  const goals = goalsResponse.data?.goals;

  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.PIN,
    entityId: pinId,
    group: MESSENGER_GROUPS.PIN.SUMMARY,
  });

  if (goalsResponse.fetching || threadsResponse.fetching) {
    return <LoadingSpinner size="large" full />;
  }

  if (threadsResponse.error || goalsResponse.error) {
    return <Error404 />;
  }

  return (
    <ProjectIdeaNoteSummary goals={goals} threads={threadsResponse.data} />
  );
};

export default ProjectIdeaNoteSummaryConnected;
