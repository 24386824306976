import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { CustomView, isMobileOnly, MobileOnlyView } from "react-device-detect";
import CatalogPageConnected from "./components/CatalogPage/CatalogPageConnected";
import ProjectContext from "./components/ProjectPage/ProjectContext";
import ProjectPageConnected from "./components/ProjectPage/ProjectPageConnected";
import DebtContext from "./components/DebtPage/DebtContext";
import DebtPageConnected from "./components/DebtPage/DebtPageConnected";
import CommodityContext from "./components/CommodityPage/CommodityContext";
import CommodityPageConnected from "./components/CommodityPage/CommodityPageConnected";
import RequestAccessPage from "./components/RequestAccessPage/RequestAccessPage";
import MakeOfferPage from "./components/MakeOfferPage/MakeOfferPage";
import SignUpPage from "./components/SignUpPage/SignUpPage";
import MobileStopper from "./components/errors/MobileStopper/MobileStopper";
import ProtectedRoute from "./components/ProtectedRoute";
import ReportPdfConnected from "./components/ReportPdf/ReportPdfConnected";
import MapPage from "./components/MapPage/MapPage";
import AuditorReportsPageConnected from "./components/AuditorReports/AuditorReportsPageConnected";
import PortfolioPage from "./components/PortfolioPage/PortfolioPage";
import ProjectInfoPage from "./components/ProjectFlow/ProjectInfoPage";
import ProjectRisksPage from "./components/ProjectFlow/ProjectRisksPage";
import ProjectImpactPage from "./components/ProjectFlow/ProjectImpactPage";
import ProjectDocumentsPage from "./components/ProjectFlow/ProjectDocumentsPage";
import AccessProvider from "./components/AccessProvider/AccessProvider";
import Taxonomy from "./components/Taxonomy/Taxonomy";
import ReportsPageConnected from "./components/ReportsPage/ReportsPageConnected";
import ReportFlow from "./components/ReportFlow/ReportFlow";
import CompanyInfoFormConnected from "./components/CompanyInfoForm/CompanyInfoFormConnected";
import ActivitiesPage from "./components/ActivitiesPage/ActivitiesPage";
import ResultsPageConnected from "./components/ResultPage/ResultsPageConnected";
import UnitFlowConnected from "./components/UnitFlow/UnitFlowConnected";
import UnitInfoPageConnected from "./components/UnitInfoPage/UnitInfoPageConnected";
import SubstantialContributionPageConnected from "./components/SubstantialContributionPage/SubstantialContributionPageConnected";
import FrameworkSummaryConnected from "./components/FrameworkSummary/FrameworkSummaryConnected";
import FrameworkCreatePageConnected from "./components/FrameworkCreatePage/FrameworkCreatePageConnected";
import FrameworkFlowConnected from "./components/FrameworkFlow/FrameworkFlowConnected";
import IntroductionConnected from "./components/Introduction/IntroductionConnected";
import UseOfProceedConnected from "./components/UseOfProceed/UseOfProceedConnected";
import ProcessForProjectConnected from "./components/ProcessForProject/ProcessForProjectConnected";
import ManagementOfProceedsConnected from "./components/ManagementOfProceeds/ManagementOfProceedsConnected";
import ReportingFrameworkConnected from "./components/ReportingFramework/ReportingFrameworkConnected";
import ExternalReviewConnected from "./components/ExternalReview/ExternalReviewConnected";
import FrameworkUpdatesConnected from "./components/FrameworkUpdates/FrameworkUpdatesConnected";
import ProjectIdeaNotesPage from "./components/ProjectIdeaNotesPage/ProjectIdeaNotesPage";
import ProjectIdeaNoteFlowConnected from "./components/ProjectIdeaNoteFlow/ProjectIdeaNoteFlowConnected";
import ProjectIdeaNoteSummaryConnected from "./components/ProjectIdeaNoteSummary/ProjectIdeaNoteSummaryConnected";
import ProjectIdeaNoteDetailsConnected from "./components/ProjectIdeaNoteDetails/ProjectIdeaNoteDetailsConnected";
import ProjectIdeaNoteAssessmentConnected from "./components/ProjectIdeaNoteAssessment/ProjectIdeaNoteAssessmentConnected";
import TeamPageConnected from "./components/TeamPage/TeamPageConnected";
import Error404 from "./components/errors/Error404";
import projectLoader from "./components/PortfolioPage/Project/projectLoader";
import Error500 from "./components/errors/Error500";
import graphqlClient from "./graphqlClient";
import ProjectBreadCrumb from "./components/ProjectFlow/ProjectBreadCrumb";
import ProjectFlow from "./components/ProjectFlow/ProjectFlow";
import ProjectFinancePage from "./components/ProjectFlow/ProjectFinancePage";
import FrameworksPage from "./components/FrameworksPage/FrameworksPage";
import ProjectIdeaNoteExpectationsConnected from "./components/ProjectIdeaNoteExpectations/ProjectIdeaNoteExpectationsConnected";
import ProjectIdeaNoteBenefitsConnected from "./components/ProjectIdeaNoteBenefits/ProjectIdeaNoteBenefitsConnected";
import ProjectIdeaNoteCrumb from "./components/ProjectIdeaNoteFlow/ProjectIdeaNoteCrumb";
import pinLoader from "./components/ProjectIdeaNoteFlow/pinLoader";
import Root from "./components/Root/Root";
import MSSPageConnected from "./components/MSSPage/MSSPageConnected";
import DNSHPageConnected from "./components/DNSHPage/DNSHPageConnected";
import ReportPdfChartsConnected from "./components/ReportPdfCharts/ReportPdfChartsConnected";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<Error500 />} element={<Root />}>
      <Route index element={<Navigate to="/listing" />} />
      <Route path="listing" element={<Outlet />}>
        <Route index element={<CatalogPageConnected />} />
        <Route path="project/:slug" element={<ProjectContext />}>
          <Route index element={<ProjectPageConnected />} />
        </Route>
        <Route path="debt/:slug" element={<DebtContext />}>
          <Route index element={<DebtPageConnected />} />
        </Route>
        <Route path="commodity/:slug" element={<CommodityContext />}>
          <Route index element={<CommodityPageConnected />} />
          <Route path="request_access" element={<RequestAccessPage />} />
          <Route path="make_offer" element={<MakeOfferPage />} />
        </Route>
      </Route>
      <Route path="request-demo" element={<SignUpPage />} />
      <Route path="pdf/report" element={<ProtectedRoute />}>
        <Route path=":reportId/:pagination" element={<ReportPdfConnected />} />
        <Route
          path=":reportId/charts/:pagination"
          element={<ReportPdfChartsConnected />}
        />
      </Route>
      <Route path="/map" element={<MapPage />} />
      <Route
        path="/auditor"
        element={
          <>
            <CustomView condition={!isMobileOnly}>
              <ProtectedRoute roles={["auditor"]} />
            </CustomView>
            <MobileOnlyView>
              <MobileStopper />
            </MobileOnlyView>
          </>
        }
      >
        <Route index element={<Navigate to="/auditor/reports" />} />
        <Route path="reports" element={<AuditorReportsPageConnected />} />
      </Route>
      <Route path="company" element={<ProtectedRoute />}>
        <Route index element={<Navigate to="taxonomy" />} />
        <Route
          handle={{
            crumb: () => "My Portfolio",
          }}
          path="portfolio"
        >
          <Route index element={<PortfolioPage />} />
          <Route path="projects">
            <Route
              id="project"
              path=":id"
              loader={projectLoader(graphqlClient)}
              handle={{
                crumb: (project) => (
                  <ProjectBreadCrumb
                    name={project.name}
                    status={project.state}
                  />
                ),
              }}
              element={<ProjectFlow />}
            >
              <Route path="info" element={<ProjectInfoPage />} />
              <Route path="impact" element={<ProjectImpactPage />} />
              <Route path="risks" element={<ProjectRisksPage />} />
              <Route path="documents" element={<ProjectDocumentsPage />} />
              <Route path="finance" element={<ProjectFinancePage />} />
            </Route>
          </Route>
        </Route>
        <Route
          handle={{
            crumb: () => "EU Taxonomy",
          }}
          path="taxonomy"
          element={
            <AccessProvider service="Taxonomy" exclude={["investor"]}>
              <Taxonomy />
            </AccessProvider>
          }
        >
          <Route index element={<ReportsPageConnected />} />
          <Route sensitive path="report/:reportId" element={<ReportFlow />}>
            <Route index element={<CompanyInfoFormConnected />} />
            <Route path="activities" element={<ActivitiesPage />} />
            <Route path="mss" element={<MSSPageConnected />} />
            <Route path="result" element={<ResultsPageConnected />} />
          </Route>
          <Route
            path="unit/:reportId/:activityReportId/:activityId/:unitParentId/:unitId"
            element={<UnitFlowConnected />}
          >
            <Route index element={<UnitInfoPageConnected />} />
            <Route
              path="substantial_contribution"
              element={<SubstantialContributionPageConnected />}
            />
            <Route path="dnsh" element={<DNSHPageConnected />} />
          </Route>
        </Route>
        <Route
          path="frameworks"
          handle={{
            crumb: () => "My Frameworks",
          }}
          element={
            <AccessProvider exclude={["investor"]} service="Framework">
              <Outlet />
            </AccessProvider>
          }
        >
          <Route
            path="summary/:frameworkId"
            element={<FrameworkSummaryConnected />}
          />
          <Route path="new" element={<FrameworkCreatePageConnected />} />
          <Route path=":frameworkId" element={<FrameworkFlowConnected />}>
            <Route index element={<IntroductionConnected />} />
            <Route path="proceeds" element={<UseOfProceedConnected />} />
            <Route path="process" element={<ProcessForProjectConnected />} />
            <Route
              path="managementOfProceeds"
              element={<ManagementOfProceedsConnected />}
            />
            <Route path="reporting" element={<ReportingFrameworkConnected />} />
            <Route
              path="externalReview"
              element={<ExternalReviewConnected />}
            />
            <Route
              path="frameworkUpdates"
              element={<FrameworkUpdatesConnected />}
            />
          </Route>
          <Route index element={<FrameworksPage />} />
        </Route>
        <Route
          path="pins"
          handle={{
            crumb: () => "Project Idea Notes",
          }}
        >
          <Route index element={<ProjectIdeaNotesPage />} />
          <Route
            path=":pinId"
            id="pin"
            loader={pinLoader(graphqlClient)}
            handle={{
              crumb: (pin) => (
                <ProjectIdeaNoteCrumb createdDate={pin?.created_at} />
              ),
            }}
            element={<ProjectIdeaNoteFlowConnected />}
          >
            <Route
              path="summary"
              element={<ProjectIdeaNoteSummaryConnected />}
            />
            <Route
              path="details"
              element={<ProjectIdeaNoteDetailsConnected />}
            />
            <Route
              path="emission"
              element={<ProjectIdeaNoteExpectationsConnected />}
            />
            <Route
              path="benefits"
              element={<ProjectIdeaNoteBenefitsConnected />}
            />
            <Route
              path="viability"
              element={<ProjectIdeaNoteAssessmentConnected />}
            />
          </Route>
        </Route>
        <Route
          handle={{
            crumb: () => "Your Team",
          }}
          path="team"
          element={<TeamPageConnected />}
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Route>
  )
);

export default router;
