import reportCurrencyParameters from "../../data/reportCurrencyParameters";
import { RECORD_TYPE } from "../ActivitiesTable/types";
import { REPORT_STATUSES } from "../../helpers/reportStatuses";

const getStatus = (issues) => {
  if (issues && issues.open > 0) {
    return REPORT_STATUSES.issue;
  }

  if (issues && issues.submitted > 0) {
    return REPORT_STATUSES.review;
  }
  return REPORT_STATUSES.draft;
};

const buildUnitTableRow = (unit, actReport, recordType) => {
  const children = unit.subunits.map((subunit) =>
    buildUnitTableRow(subunit, actReport, RECORD_TYPE.SUBUNIT)
  );
  const aligned = Object.keys(reportCurrencyParameters).every(
    (finParameter) => unit[`${finParameter}_alignment`] === unit[finParameter]
  );

  const nameProps = {
    name: unit.name,
    type: unit.type,
    aligned,
  };

  const record = {
    key: unit.id,
    issues: unit.issues,
    keyType: recordType,
    element: unit,
    name: nameProps,
    code: null,
    activityType: null,
    objective: unit.objective,
    turnover: unit.turnover,
    capex: unit.capex,
    opex: unit.opex,
    status: getStatus(unit.issues),
    availableObjectives: [],
  };

  if (actReport.activity.adaptation_type) {
    record.availableObjectives.push("adaptation");
  }

  if (actReport.activity.mitigation_type) {
    record.availableObjectives.push("mitigation");
  }

  if (children.length) {
    return {
      ...record,
      children,
    };
  }
  return record;
};

const buildActReportTableRow = (actReport) => {
  const children = actReport.units.map((unit) =>
    buildUnitTableRow(unit, actReport, RECORD_TYPE.UNIT)
  );

  const record = {
    key: actReport.id,
    keyType: RECORD_TYPE.ACTIVITY,
    element: actReport,
    name: actReport.activity.name,
    code: actReport.activities_reports_codes.map((code) => code.nace_code),
    activityType: actReport.activity_type || actReport.activity.type,
    objective: actReport.objective,

    turnover: {
      part: actReport.turnover_alignment,
      total: actReport.turnover,
    },
    capex: {
      part: actReport.capex_alignment,
      total: actReport.capex,
    },
    opex: {
      part: actReport.opex_alignment,
      total: actReport.opex,
    },
    status: getStatus(actReport.issues),
    issues: actReport.issues,
  };

  if (children.length) {
    return {
      ...record,
      children,
    };
  }
  return record;
};

const buildTableRowsData = (actReports) =>
  actReports.map(buildActReportTableRow);

export default buildTableRowsData;
