import { notification } from "antd";
import differenceBy from "lodash.differenceby";
import React, { useMemo } from "react";
import { useQuery, useMutation } from "urql";
import { updateActReportsCodes } from "../../api/mutations";
import { actReportWithDescriptionQuery } from "../../api/queries";
import SelectNaceCodeDrawer from "../SelectNaceCodeDrawer/SelectNaceCodeDrawer";

const EditNaceCodesDrawerConnected = ({ actReportId, visible, onClose }) => {
  const [updateMutationResponse, updateActReportCodesMutation] = useMutation(
    updateActReportsCodes
  );
  const [actReportResponse] = useQuery({
    query: actReportWithDescriptionQuery,
    pause: !visible,
    variables: { actReportId },
  });
  const actReport =
    actReportResponse.data?.eu_taxonomy_activities_reports_by_pk;
  const description = actReportResponse.data?.eu_taxonomy_points[0]?.text;

  const activity = actReport?.activity;
  const addedCodes = actReport?.activities_reports_codes.map(
    (code) => code.nace_code.id
  );

  const formattedActivity = useMemo(
    () =>
      !!activity &&
      !!description && {
        id: activity.id,
        name: activity.name,
        category: {
          id: activity.category.id,
          name: activity.category.name,
        },
        codes: activity.activities_codes.map((code) => ({
          code: code.nace_code.code,
          id: code.nace_code.id,
          type: code.nace_code.type,
          description: code.nace_code.description,
          parentId: code.nace_code.parent_id,
        })),
        tags: ["Mitigation"],
        description,
      },
    [activity, description]
  );

  const onSubmit = async (newCodes) => {
    const removed = differenceBy(addedCodes, newCodes);
    const addedNaceCodes = differenceBy(newCodes, addedCodes);

    try {
      const response = await updateActReportCodesMutation({
        addCodes: addedNaceCodes.map((naceCode) => ({
          code_id: naceCode,
          activity_report_id: actReportId,
        })),
        removeCodes: removed,
      });
      if (response.error) {
        throw new Error("update activity codes error");
      }
    } catch (err) {
      notification.error({
        message: "Error update activity nace codes",
        description: "Please try again later.",
      });
    }
    onClose();
  };

  return (
    <SelectNaceCodeDrawer
      visible={visible && !!formattedActivity}
      onClose={onClose}
      onSubmit={onSubmit}
      activity={formattedActivity}
      isSelected={false}
      isAdded
      addedCodes={addedCodes}
      loading={updateMutationResponse.fetching}
      okButtonText="Update"
    />
  );
};

export default EditNaceCodesDrawerConnected;
