import React, { useMemo } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Radio,
  InputNumber,
  Select,
  Space,
  QuestionCircleOutlined,
  ContentLayout,
} from "@evercityecosystem/evercity-ui";

import { useNavigate } from "react-router-dom";
import styles from "./CompanyInfoForm.module.less";
import {
  thousandFormatter,
  thousandParser,
} from "../../helpers/thousandFormatter";

import FormItemsGroup from "../FormItemsGroup/FormItemsGroup";
import { REPORT_STATUSES } from "../../helpers/reportStatuses";
import InfoButton from "../InfoButton/InfoButton";
import reportCurrencyParameters from "../../data/reportCurrencyParameters";
import AttachFileInput from "../AttachFileInput/AttachFileInput";
import FilesTagsListInput from "../FilesTagsListInput/FilesTagsListInput";
import useBreakpoint from "../../hooks/useBreakpoint";
import FormItemWithDiscussions from "../FormItemWithDiscussions/FormItemWithDiscussions";
import { GroupContext } from "../../helpers/withThreadsHoc";
import { MESSENGER_GROUPS } from "../../data/messenger";

const { Group: RadioGroup } = Radio;

const inputNumberStyle = { width: "100%", padding: "0 6px" };

const CompanyInfoForm = ({
  onFinish,
  report,
  company,
  onValuesChange,
  sending,
  attachedDocs,
  threads,
  hideThreadsActions,
}) => {
  const isSample = report?.status === REPORT_STATUSES.sample;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const reportingYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear - 1];
  }, []);
  const currencyList = [
    "USD",
    "EUR",
    "JPY",
    "GBP",
    "AUD",
    "CAD",
    "CHF",
    "CNH",
    "HKD",
    "NZD",
  ];

  const getValidationRules = (paramName) => {
    const displayName = reportCurrencyParameters[paramName];
    const eligibilityKey = `${paramName}_eligibility`;
    const { [eligibilityKey]: eligibilityCurrencyValue } = report;

    return [
      {
        required: true,
        message: `Please enter your company ${displayName}`,
      },
      {
        min: eligibilityCurrencyValue || 0,
        type: "number",
        message: `Should be more than sum of all added units (${thousandFormatter(
          eligibilityCurrencyValue || 0
        )})`,
      },
    ];
  };

  const initialValues = {
    company_name: report.company?.name || company.name,
    nfrd_csrd: report.company?.nfrd_csrd || company?.nfrd_csrd ? "yes" : "no",
    country: report.company?.country || company.country,
    company_number: report.company?.company_number || company.company_number,
    lei: report.company?.lei || company.lei,
    employee_num: report.company?.employee_num || company.employee_num,
    reporting_year: report.reporting_year,
    currency: report.currency,
    turnover: report.turnover,
    capex: report.capex,
    opex: report.opex,
    docs: attachedDocs,
  };

  const { lg } = useBreakpoint();

  const [Layout, layoutProps] = useMemo(() => {
    const component = lg ? ContentLayout.Aside : ContentLayout;
    const props = lg ? null : { gaps: "off" };
    return [component, props];
  }, [lg]);

  return (
    <GroupContext.Provider value={MESSENGER_GROUPS.TAXONOMY_REPORT.INFO}>
      <Layout {...layoutProps}>
        <Form
          data-cy="company-info-form"
          layout="vertical"
          size="large"
          onValuesChange={onValuesChange}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
          initialValues={initialValues}
          form={form}
          onFinish={onFinish}
        >
          <Space block direction="vertical" size={40}>
            <FormItemsGroup
              title="Basics"
              subtitle="Please fill in the basic information about the reporting company. It will be reflected in your Taxonomy report."
              entryVideoStartTime={10}
            >
              <Row gutter={30}>
                <Col span={12}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="company_name"
                    label="Legal company name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your legal company name",
                      },
                    ]}
                  >
                    <Input disabled={isSample} data-cy="company-info-name" />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={12}>
                  <Space align="center">
                    <FormItemWithDiscussions
                      threads={threads}
                      hideDisscussion={hideThreadsActions}
                      name="nfrd_csrd"
                      tooltip={{
                        title:
                          "NFRD (Non-Financial Reporting Directive) applies to the EU-based public companies with more than 500 employees. All NFRD companies should submit their first Taxonomy report in January 2022. CSRD (Corporate sustainability reporting directive) will replace NFRD in October 2022 increasing the scope of companies that need to report.",
                        icon: <InfoButton />,
                        placement: "rightBottom",
                      }}
                      getValueFromEvent={(value) => value.id}
                      getValueProps={(value) => ({ value: { id: value } })}
                      label="Subject to NFRD / CSRD"
                      rules={[
                        {
                          required: true,
                          message: "Please check your NFRD/CSRD status",
                        },
                      ]}
                    >
                      <RadioGroup
                        data-cy="company-info-is-nfrd_csrd"
                        disabled={isSample}
                        direction="horizontal"
                      >
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </RadioGroup>
                    </FormItemWithDiscussions>
                  </Space>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="country"
                    tooltip={{
                      title:
                        "Country is a jurisdiction where the reporting company is registered.",
                      icon: <InfoButton />,
                      placement: "rightBottom",
                    }}
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company country",
                      },
                    ]}
                  >
                    <Input disabled={isSample} data-cy="company-info-country" />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={12}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="employee_num"
                    label="Number of employees"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter the number of employees in your company",
                      },
                    ]}
                  >
                    <Input
                      disabled={isSample}
                      data-cy="company-info-employee-num"
                    />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="company_number"
                    tooltip={{
                      title:
                        "Company number is a company registration number displayed on the Certificate of Incorporation.",
                      icon: <InfoButton />,
                    }}
                    label="Company number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company number",
                      },
                    ]}
                  >
                    <Input disabled={isSample} data-cy="company-info-number" />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={12}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="lei"
                    label="LEI"
                    tooltip={{
                      title:
                        "The Legal Entity Identifier (LEI) is a unique global identifier for legal entities participating in financial transactions.",
                      icon: <QuestionCircleOutlined />,
                      placement: "rightBottom",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company LEI",
                      },
                    ]}
                  >
                    <Input disabled={isSample} data-cy="company-info-lei" />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
            </FormItemsGroup>

            <FormItemsGroup
              title="Financials"
              subtitle="Please fill in financial information about the reporting company. Make sure your calculation of Turnover, CapEx and OpEx is in line with the Taxonomy requirements."
            >
              <Row gutter={30}>
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="reporting_year"
                    tooltip={{
                      title:
                        "Reporting year is a selected financial year that will be covered by the current report.",
                      icon: <InfoButton />,
                      placement: "rightBottom",
                    }}
                    label="Reporting year"
                    rules={[
                      {
                        required: true,
                        message: "Please select a reporting year",
                      },
                    ]}
                  >
                    <Select
                      data-cy="report-info-year"
                      options={reportingYears}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="currency"
                    label="Currency"
                    rules={[
                      {
                        required: true,
                        message: "Please select a currency",
                      },
                    ]}
                  >
                    <Select
                      data-cy="report-info-currency"
                      options={currencyList}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8} />
              </Row>
              <Row gutter={30} align="bottom">
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="turnover"
                    tooltip={{
                      title:
                        "Turnover covers amounts derived from the sale of products and the provision of services after deducting sales rebates, value added tax and other taxes directly linked to turnover.",
                      placement: "rightBottom",
                    }}
                    label="Annual Turnover"
                    rules={getValidationRules("turnover")}
                  >
                    <InputNumber
                      data-cy="report-info-turnover"
                      min={0}
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="capex"
                    tooltip={{
                      title:
                        "CapEx covers additions to tangible and intangible assets during the financial year considered before depreciation, amortisation and any re-measurements, including those resulting from revaluations and impairments.",
                      icon: <InfoButton />,
                      placement: "rightBottom",
                    }}
                    label="Capital Expenditures"
                    rules={getValidationRules("capex")}
                  >
                    <InputNumber
                      data-cy="report-info-capex"
                      min={0}
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
                <Col span={8}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="opex"
                    tooltip={{
                      title:
                        "OpEx covers direct non-capitalised costs relating to research and development, renovation measures, short-term lease, maintenance and other direct expenditures relating to the day-to-day servicing of assets of property, plant and equipment that are necessary to ensure the continued and effective use of such assets.",
                      icon: <InfoButton />,
                      placement: "rightBottom",
                    }}
                    label="Operating Expenditures"
                    rules={getValidationRules("opex")}
                  >
                    <InputNumber
                      data-cy="report-info-opex"
                      min={0}
                      formatter={thousandFormatter}
                      parser={thousandParser}
                      disableControls
                      style={inputNumberStyle}
                    />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={24}>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    label="Upload supporting file"
                    name="docs"
                  >
                    <AttachFileInput />
                  </FormItemWithDiscussions>
                  <FormItemWithDiscussions
                    hideDisscussion={hideThreadsActions}
                    threads={threads}
                    name="docs"
                    noStyle
                  >
                    <FilesTagsListInput />
                  </FormItemWithDiscussions>
                </Col>
              </Row>
            </FormItemsGroup>
          </Space>
          {/* Submit, Cancel */}
          <div className={styles.submitWrapper}>
            <Space size={12}>
              <Button
                size="large"
                onClick={() => navigate("/company/taxonomy")}
              >
                Cancel
              </Button>
              <Button
                block
                data-cy="btn-next-step"
                type="primary"
                htmlType="submit"
                size="large"
                className={styles.submitBtn}
                loading={sending}
              >
                Next step
              </Button>
            </Space>
          </div>
        </Form>
      </Layout>
    </GroupContext.Provider>
  );
};

export default CompanyInfoForm;
