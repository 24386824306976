import React, { useMemo } from "react";
import { Text } from "@antd-ovveride";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import AttachedFileRow from "../AttachedFileRow/AttachedFileRow";
import styles from "./AttachedList.module.less";

const fileStatusToRowStatus = {
  done: "default",
  error: "error",
  uploading: "loading",
};

const Row = ({
  data: {
    docs,
    attachedFilesIds,
    onRetryDoc,
    onDeleteDoc,
    onChangeDocName,
    onSelectChangeDoc,
    selectedDocs,
  },
  index,
  style,
}) => {
  const doc = docs[index];

  const selected = useMemo(
    () => selectedDocs.includes(doc.uid),
    [doc, selectedDocs]
  );

  const added = useMemo(
    () => attachedFilesIds?.includes(doc.data.docId),
    [doc, attachedFilesIds]
  );

  const type = added ? "added" : fileStatusToRowStatus[doc.status] || "default";

  return (
    <div style={style}>
      <AttachedFileRow
        key={doc.uid}
        userId={doc.data.userId}
        name={doc.data.name}
        link={doc.data.url}
        createdDate={doc.createdDate}
        onDelete={() => onDeleteDoc(doc.uid)}
        onChangeName={(name) => onChangeDocName(doc.uid, name)}
        onRetry={() => onRetryDoc(doc.uid)}
        selected={selected}
        onSelectedChange={() => onSelectChangeDoc(doc.uid)}
        error={doc.error}
        type={type}
      />
    </div>
  );
};

const AttachedList = ({
  docs,
  attachedFilesIds,
  onRetryDoc,
  onDeleteDoc,
  onChangeDocName,
  onSelectChangeDoc,
  selectedDocs,
}) => {
  const sortedDocs = useMemo(
    () =>
      docs.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
    [docs]
  );
  return (
    <div className={styles.container}>
      {/* <AutoSizer>
        {({ height, width }) => (
          <div
            className={styles.content}
            style={{ height: Math.max(height, 80), width }}
          > */}
      <div className={styles.content}>
        <div className={styles.header}>
          <Text type="secondary">Attached files</Text>
        </div>
        <div className={styles.list}>
          <AutoSizer>
            {({ height: listHeight, width: listWidth }) => (
              <List
                height={listHeight}
                itemCount={docs.length}
                itemSize={36}
                width={listWidth}
                itemKey={(index, data) => data.docs[index].uid}
                itemData={{
                  docs: sortedDocs,
                  attachedFilesIds,
                  onRetryDoc,
                  onDeleteDoc,
                  onChangeDocName,
                  onSelectChangeDoc,
                  selectedDocs,
                }}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
      {/* )}
      </AutoSizer> */}
    </div>
  );
};

export default AttachedList;
