import React, { useState } from "react";
import { Radio, StarFilled } from "@evercityecosystem/evercity-ui";
import PlanSpace from "./PlanSpace";

const SelectPlan = ({ onChange, value }) => {
  const [selected, setSelected] = useState({ id: value });
  const onSelect = (plan) => {
    onChange?.(plan);
    setSelected({ id: plan });
  };

  return (
    <Radio.Group direction="horizontal" value={selected}>
      <PlanSpace
        selected={selected?.id === "structuring"}
        onSelect={onSelect}
        value="structuring"
        title="Structuring"
        tag={{
          icon: <StarFilled />,
          text: "Best for you",
        }}
        description="Create initial documents and get matched with best partners to ensure successful execution of your project."
        opportunities={[
          "Create project idea note",
          "Structure your carbon project info",
          "Get matched with professional project developers",
        ]}
      />
      <PlanSpace
        selected={selected?.id === "carbonForwards"}
        onSelect={onSelect}
        value="carbonForwards"
        title="Carbon forwards"
        description="Cover the project implementation costs with tokenized future carbon delivery contracts.  Secure deals with early buyers."
        opportunities={[
          "Issue carbon forwards for any vintage",
          "Receive payments in fiat or crypto",
          "Offer forwards on the marketplace",
          "Connect digital MRV tools to provide transparent reporting",
        ]}
      />
      <PlanSpace
        selected={selected?.id === "existingCarbon"}
        onSelect={onSelect}
        value="existingCarbon"
        title="Existing carbon"
        description="Offer your carbon credits to crypto and traditional buyers.  Gain their trust by using digital MRV for risk and impact reporting."
        opportunities={[
          "Trade your carbon credits on the marketplace",
          "Structure profile of your carbon project",
          "Connect digital MRV tools to provide transparent reporting",
        ]}
      />
    </Radio.Group>
  );
};

export default SelectPlan;
