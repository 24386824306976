import React from "react";
import { Text } from "@antd-ovveride";

const ActivityTypeCell = ({ text }) => (
  <Text size="small" capitalize data-cy="cell-activity-type">
    {text}
  </Text>
);

export default ActivityTypeCell;
