import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToThisOnRedirect = () => {
  const { pathname } = useLocation();
  const containerRef = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
    return () => {
      clearTimeout(timer);
    };
  }, [pathname]);
  return <div ref={containerRef} />;
};

export default ScrollToThisOnRedirect;
