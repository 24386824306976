import React from "react";

import {
  convertDefToComponents,
  convertTextToDefinition,
} from "../../helpers/pointToComponents";

import PointsGroup from "../Points/PointsGroup";
import HeadlinePoint from "../Points/HeadlinePoint";
import BooleanPointWithThreads from "../Points/BooleanPointWithThreads";
import AffectivePointWithThreads from "../Points/AffectivePointWithThreads";
import ConditionalPointWithThreads from "../Points/ConditionalPointWithThreads";

const PointSwitcher = ({ point, form, show = true, threads }) => {
  if (!show) {
    return null;
  }
  let transformedText = point.text;

  if (point?.point_links?.length) {
    transformedText = convertTextToDefinition(
      point.point_links,
      transformedText,
      "link"
    );
  }

  if (point?.point_hints?.length) {
    transformedText = convertTextToDefinition(
      point.point_hints,
      transformedText,
      "hint"
    );
  }

  const textComponents = convertDefToComponents(transformedText);

  if (point.changes_activity_type && point.rule === "boolean") {
    return (
      <AffectivePointWithThreads
        property={point.id}
        topic={point.text}
        threads={threads}
        text={textComponents}
        form={form}
        point={point}
      />
    );
  }

  switch (point.rule) {
    case "headline":
      return <HeadlinePoint text={point.text} />;

    case "boolean":
      return (
        <BooleanPointWithThreads
          threads={threads}
          property={point.id}
          text={textComponents}
          form={form}
          key={point.id}
          pointId={point.id}
          validate
          decision={point.decision}
          point={point}
          attachments={point.docs}
        />
      );
    case "all_of":
    case "one_of":
    case "two_of":
      // todo: это к switcher-у не относится, нужно рефакторить
      return (
        <PointsGroup
          point={point}
          text={textComponents}
          form={form}
          initialValue={point.decision}
        >
          {point?.nested_points?.map((p) => (
            <PointSwitcher
              threads={threads}
              point={p}
              form={form}
              key={point.id}
            />
          ))}
        </PointsGroup>
      );
    case "condition":
      return (
        <ConditionalPointWithThreads
          property={point.id}
          threads={threads}
          decision={point.decision}
          key={point.id}
          id={point.id}
          text={textComponents}
          form={form}
        >
          {point.nested_points?.map((subPoint) => (
            <PointSwitcher
              threads={threads}
              point={subPoint}
              form={form}
              key={subPoint.id}
              show={
                !subPoint.show_on_decision ||
                point.decision === subPoint.show_on_decision
              }
            />
          ))}
        </ConditionalPointWithThreads>
      );
    case "text":
    default:
      return textComponents;
  }
};

export default PointSwitcher;
