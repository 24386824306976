import React from "react";
import {
  Radio,
  Tooltip,
  QuestionCircleOutlined,
} from "@evercityecosystem/evercity-ui";
import cs from "classnames";
import styles from "./RadioCard.module.less";

const RadioCard = ({ children, tooltip, className, ...rest }) => (
  <Radio {...rest} className={cs(className, styles.radio)}>
    <div className={styles.body}>
      <span className={styles.label}>{children}</span>
      {tooltip && (
        <Tooltip title={tooltip} className={styles.tooltip} placement="right">
          <span className={styles.icon}>
            <QuestionCircleOutlined />
          </span>
        </Tooltip>
      )}
    </div>
  </Radio>
);

export default RadioCard;
