import { useQuery } from "urql";
import { useMemo } from "react";
import { frameworkCategoriesQuery } from "../../api/queries";

const useGetCategories = (excludedList, isEdit) => {
  const [response] = useQuery({
    query: frameworkCategoriesQuery,
  });

  const categories = useMemo(() => {
    if (response.data && excludedList && !isEdit) {
      return response.data.framework_categories.filter(
        (category) => !excludedList.includes(category.id)
      );
    }
    if (response.data) {
      return response.data.framework_categories;
    }
  }, [response, excludedList, isEdit]);

  return { categories, ...response };
};

export default useGetCategories;
