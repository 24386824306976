import React from "react";
import AlignedModal from "./AlignedModal";

const ReportAlignedModalMSS = (props) => (
  <AlignedModal {...props} title="Assessment complete">
    Congratulations! The assessment is now complete, please proceed to see the
    results.
  </AlignedModal>
);

export default ReportAlignedModalMSS;
