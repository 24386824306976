import React, { useState, useEffect } from "react";
import MapGL, { AttributionControl } from "react-map-gl";
import { useNavigate } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import cs from "classnames";
import styles from "./MapWidget.module.less";
import FullMapButton from "./FullMapButton";
import ZoomController from "../Map/ZoomController";

const MapWidget = ({ initialLat, initialLng, initialZoom, satelliteView }) => {
  const [mapInstance, setMapInstance] = useState();
  const [viewport, setViewport] = useState({
    longitude: initialLng,
    latitude: initialLat,
    zoom: initialZoom,
  });

  useEffect(() => {
    if (!mapInstance) return;
    mapInstance.setLayoutProperty(
      "satellite",
      "visibility",
      satelliteView ? "visible" : "none"
    );
    mapInstance.setLayoutProperty(
      "satellite-background",
      "visibility",
      satelliteView ? "visible" : "none"
    );
  }, [satelliteView, mapInstance]);

  useEffect(() => {
    setViewport((vp) => ({
      ...vp,
      longitude: initialLng,
      latitude: initialLat,
      zoom: initialZoom,
    }));
  }, [initialLat, initialLng, initialZoom, setViewport]);

  const navigate = useNavigate();
  const navigateToFullMap = () => {
    navigate(
      `/map?zoom=${initialZoom}&longitude=${initialLng}&latitude=${initialLat}`
    );
  };

  return (
    <div
      className={cs(
        styles.mapWidget,
        !!mapInstance && styles["mapWidget--loaded"]
      )}
    >
      <MapGL
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
        {...viewport}
        onLoad={(e) => setMapInstance(e.target)}
        height="100%"
        width="100%"
        onViewportChange={setViewport}
        dragRotate={false}
        attributionControl={false}
        // dragPan={!isMobile}
        // scrollZoom={false}
        // touchZoom
        minZoom={2}
        cooperativeGestures
        mapOptions={{
          cooperativeGestures: true,
        }}
      >
        <div className={styles.fullMapControl}>
          <FullMapButton onClick={navigateToFullMap} />
        </div>
        <div className={styles.zoomControl}>
          <ZoomController setViewport={setViewport} zoom={viewport.zoom} />
        </div>
        <div className={styles.attributionController}>
          <AttributionControl compact />
        </div>
      </MapGL>
    </div>
  );
};

export default MapWidget;
