export const MESSENGER_ENTITIES = {
  PIN: "pin",
  TAXONOMY_REPORT: "taxonomyReport",
  TAXONOMY_UNIT: "taxonomyUnit",
};

export const MESSENGER_GROUPS = {
  PIN: {
    SUMMARY: "summary",
    DETAILS: "details",
    EMISSION: "emission",
    BENEFITS: "benefits",
    VIABILITY: "viability",
  },
  TAXONOMY_REPORT: {
    INFO: "info",
    ACTIVITIES: "activities",
    MSS: "mss",
    RESULT: "result",
  },
  TAXONOMY_UNIT: {
    INFO: "info",
    SUBSTANTIAL_CONTRIBUTION: "substantial_contribution",
    DNSH: "dnsh",
  },
};

export const THREAD_STATUSES = {
  open: "Open",
  submitted: "On Review",
  resolved: "Resolved",
};
