import React, { useMemo } from "react";
import classnames from "classnames";
import uniqBy from "lodash.uniqby";
import styles from "./GoalsList.module.less";
import Goal from "./Goal";

const GoalsList = ({ goals, direction = "vertical", image }) => {
  const list = useMemo(() => uniqBy(goals, "id"), [goals]);
  return (
    <ul className={classnames(styles.list, styles[`list--${direction}`])}>
      {list.map((goal) => (
        <li key={goal.id}>
          <Goal
            id={goal.id}
            text={direction === "vertical"}
            image={image}
            color={goal.inverted_color}
          />
        </li>
      ))}
    </ul>
  );
};

export default GoalsList;
