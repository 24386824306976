import { useEffect, useCallback } from "react";
import { useMutation } from "urql";
import { useNavigate } from "react-router-dom";

import { loginMutation } from "../../api/mutations";

import { setData } from "../../helpers/jwt";

export const useAuth = (action = "login") => {
  const mutations = {
    login: loginMutation,
    // register: signUpMutation, temporarily unavailable
  };

  const [response, exec] = useMutation(mutations[action]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = response?.data?.[action]?.jwt_token;

    if (token) {
      setData(token);
      navigate(`/`);
    }
  }, [response, action, navigate]);

  return [response, exec];
};

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setData();
    navigate(`/login`);
  }, [navigate]);

  return logout;
};
