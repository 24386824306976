import React from "react";
import { Row, Col, Switch, Collapse } from "antd";
import { Text, Space } from "@antd-ovveride";
import cs from "classnames";
import styles from "./MapLayer.module.less";
import MapLayerFilters from "../MapLayerFilters/MapLayerFilters";

const MapLayer = ({
  layer,
  checked,
  onChange,
  onFiltersChange,
  selectedFilters,
}) => (
  <div
    className={cs([
      styles.layerControllers,
      checked &&
        layer.filters?.length > 0 &&
        styles["layerControllers--checked"],
    ])}
  >
    <Row
      justify="space-between"
      align="middle"
      className={styles.layerSwitcher}
    >
      <Col>
        <Space size={10} align="center">
          <div className={styles.icon} disabled={layer.disabled}>
            {layer.icon}
          </div>
          <Text disabled={layer.disabled} size="small">
            {layer.displayName}
          </Text>
        </Space>
      </Col>
      {!layer.disabled && (
        <Col>
          <Switch
            checked={checked}
            onChange={() => onChange(layer.id)}
            size="small"
            className={styles.layerSwitch}
          />
        </Col>
      )}
    </Row>
    {layer.filters && (
      <Collapse
        ghost
        className={styles.filtersCollapse}
        activeKey={checked ? "1" : null}
      >
        <Collapse.Panel key="1">
          <MapLayerFilters
            filters={layer.filters}
            onChange={onFiltersChange}
            selectedFilters={selectedFilters}
          />
        </Collapse.Panel>
      </Collapse>
    )}
  </div>
);

export default MapLayer;
