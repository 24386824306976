import React, { useState } from "react";
import {
  Button,
  CheckCircleOutlined,
  LinkOutlined,
  Input,
} from "@evercityecosystem/evercity-ui";
import styles from "./InviteModal.module.less";

const InviteModal = ({ Modal, inviteLink, onOk = () => {} }) => {
  const [isCopied, setIsCopied] = useState(false);
  const onCopy = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setIsCopied(true);
    });
  };

  return (
    <Modal
      title="Copy invite link"
      okButton={<Button onClick={() => onOk()}>Done</Button>}
      width={800}
      autoHeight
    >
      <Input
        value={inviteLink}
        disabled
        suffix={
          <Button
            onClick={onCopy}
            className={styles.copy_btn}
            icon={isCopied ? <CheckCircleOutlined /> : <LinkOutlined />}
          >
            {isCopied ? "Copied" : "Copy link"}
          </Button>
        }
      />
    </Modal>
  );
};

export default InviteModal;
