import groupBy from "lodash.groupby";
import uniqBy from "lodash.uniqby";
import React from "react";

import { ReactComponent as WindSpeedIcon } from "../assets/map/layersIcons/wind_speed.svg";
import { ReactComponent as CornIcon } from "../assets/map/layersIcons/corn.svg";
import { ReactComponent as DeforestrationIcon } from "../assets/map/layersIcons/deforestration.svg";
import { ReactComponent as DroughtIcon } from "../assets/map/layersIcons/drought.svg";
import { ReactComponent as EarthquakesIcon } from "../assets/map/layersIcons/earthquakes.svg";
import { ReactComponent as ExtremeWeatherIcon } from "../assets/map/layersIcons/extreme-weather.svg";
import { ReactComponent as FiresIcon } from "../assets/map/layersIcons/fires.svg";
import { ReactComponent as FloodsIcon } from "../assets/map/layersIcons/floods.svg";
import { ReactComponent as IotIcon } from "../assets/map/layersIcons/iot.svg";
import { ReactComponent as PowerPlantsIcon } from "../assets/map/layersIcons/power_plants.svg";
import { ReactComponent as RefineriesIcon } from "../assets/map/layersIcons/refineries.svg";
import { ReactComponent as SeaLevelRiseIcon } from "../assets/map/layersIcons/sea_level_rise.svg";
import { ReactComponent as SoilMoistureIcon } from "../assets/map/layersIcons/soil_moisture.svg";
import { ReactComponent as SolarInsolationIcon } from "../assets/map/layersIcons/solar_insolation.svg";
import { ReactComponent as TailingStorageIcon } from "../assets/map/layersIcons/tailing_storage.svg";
import { ReactComponent as TsunamisIcon } from "../assets/map/layersIcons/tsunamis.svg";
import { ReactComponent as TypeOfTheFieldsIcon } from "../assets/map/layersIcons/type_of_the_fields.svg";
import { ReactComponent as VolcanoesIcon } from "../assets/map/layersIcons/volcanoes.svg";
import addMlnSuffix from "../helpers/addMlnSuffix";
import { thousandFormatter } from "../helpers/thousandFormatter";

const valueToMln = (v) => addMlnSuffix(v, thousandFormatter);

const allCategories = [
  {
    id: "1",
    label: "Risks",
  },
  {
    id: "2",
    label: "ESG",
  },
  {
    id: "3",
    label: "SOMETHING",
  },
];

const allGroups = [
  {
    id: "1",
    label: "Climate",
    category: "1",
  },
  {
    id: "2",
    label: "Geo Hazards",
    category: "1",
  },
  {
    id: "3",
    label: "Energy",
    category: "2",
  },
  {
    id: "4",
    label: "Waste",
    category: "2",
  },
  {
    id: "5",
    label: "Agriculture",
    category: "2",
  },
  {
    id: "6",
    label: "Weather",
    category: "2",
  },
  {
    id: "7",
    label: "Forestry",
    category: "2",
  },
];

const allGroupsExtended = allGroups.map((group) => ({
  ...group,
  category: allCategories.find((c) => c.id === group.category),
}));

const layers = [
  {
    id: "1",
    displayName: "Droughts",
    mapboxId: "risk-of-drought-impact-for-agriculture",
    fullName: "Risk of Drought Impact for Agriculture",
    uniqKey: "id",
    group: "1",
    icon: <DroughtIcon />,
    source: {
      text: "Global Drought Observatory data from the Copernicus Emergency Management Service",
      link: "https://edo.jrc.ec.europa.eu/gdo/php/index.php?id=2112",
    },
    legend: {
      type: "gradient",
      minText: "0",
      maxText: "2.19",
      options: [
        {
          value: 0,
          color: "#F8F6EC",
        },
        {
          value: 0.07,
          color: "#F8F6EC",
        },
        {
          value: 0.15,
          color: "#F6EBC3",
        },
        {
          value: 0.3,
          color: "#EBD7A1",
        },
        {
          value: 0.6,
          color: "#D1A744",
        },
        {
          value: 1.2,
          color: "#956B07",
        },
        {
          value: 2.19,
          color: "#634600",
        },
      ],
    },
  },
  {
    id: "2",
    displayName: "Wildfires",
    mapboxId: "fire-weather-index",
    fullName: "Fire Weather Index",
    uniqKey: "id",
    group: "1",
    icon: <FiresIcon />,
    legend: {
      type: "gradient",
      minText: "0",
      maxText: "100",
      options: [
        {
          value: 0,
          color: "#FFF2E8",
        },
        {
          value: 5,
          color: "#FFF2E8",
        },
        {
          value: 11.2,
          color: "#FFBB96",
        },
        {
          value: 21.3,
          color: "#FF9C6E",
        },
        {
          value: 38,
          color: "#D4380D",
        },
        {
          value: 50,
          color: "#871400",
        },
        {
          value: 100,
          color: "#5C0011",
        },
      ],
    },
    source: {
      text: "Fire danger indices historical data from the Copernicus Emergency Management Service",
      link: "https://cds.climate.copernicus.eu/cdsapp#!/dataset/10.24381/cds.0e89c522?tab=overview",
    },
  },
  {
    id: "3",
    displayName: "Sea level rise",
    mapboxId: null,
    fullName: "Sea level rise",
    uniqKey: null,
    group: "1",
    disabled: true,
    icon: <SeaLevelRiseIcon />,
  },
  {
    id: "4",
    displayName: "Extreme weather",
    mapboxId: "",
    fullName: "Extreme weather",
    uniqKey: null,
    group: "1",
    disabled: true,
    icon: <ExtremeWeatherIcon />,
  },
  {
    id: "5",
    displayName: "Floods",
    mapboxId: "",
    fullName: "Floods",
    uniqKey: null,
    group: "1",
    disabled: true,
    icon: <FloodsIcon />,
  },
  {
    id: "6",
    displayName: "Earthquakes",
    mapboxId: "earthquakes-magnitude",
    fullName: "Magnitude",
    uniqKey: "id",
    group: "2",
    icon: <EarthquakesIcon />,
    filters: [
      {
        type: "range",
        mapboxFieldKey: "year",
        initialValues: [1950, 2021],
        min: 1950,
        max: 2021,
        step: 1,
      },
    ],
    legend: {
      type: "points",
      options: [
        {
          value: "NA",
          color: "#b3b3b3",
        },
        {
          value: "0-5",
          color: "#FFFDBD",
        },
        {
          value: "5-6",
          color: "#FFF566",
        },
        {
          value: "6-7",
          color: "#F3D40B",
        },
        {
          value: "7-8",
          color: "#D46B08",
        },
        {
          value: "8-9.5",
          color: "#873800",
        },
      ],
    },
    source: {
      text: "NCEI Natural Center for Environmental Information",
      link: "https://www.ngdc.noaa.gov/hazel/view/about",
    },
    metaDisplayMap: [
      {
        mapboxKey: "year",
        label: "Year",
        // transformValue: (v) => v,
        // callback: "N/A"
      },
      {
        mapboxKey: "month",
        label: "Month",
      },
      {
        mapboxKey: "intensity",
        label: "MMI Intensity",
      },
      {
        mapboxKey: "eqMagnitude",
        label: "Magnitude",
      },
      {
        mapboxKey: "deathsTotal",
        label: "Deaths",
        transform: valueToMln,
      },
      {
        mapboxKey: "housesDamaged",
        label: "Destroyed houses",
        transformValue: valueToMln,
      },
      {
        mapboxKey: "damageMillionsDollars",
        label: "Damage, mln $",
      },
    ],
  },
  {
    id: "7",
    displayName: "Tsunamis",
    mapboxId: "tsunami-intensity",
    fullName: "Water height, m",
    uniqKey: "id",
    group: "2",
    icon: <TsunamisIcon />,
    filters: [
      {
        type: "range",
        mapboxFieldKey: "year",
        initialValues: [1950, 2021],
        min: 1950,
        max: 2021,
        step: 1,
      },
    ],
    legend: {
      type: "points",
      options: [
        {
          value: "NA",
          color: "#b3b3b3",
        },
        {
          value: "<1",
          color: "#B5F5EC",
        },
        {
          value: "1-5",
          color: "#89E7D9",
        },
        {
          value: "5-10",
          color: "#0EBFBF",
        },
        {
          value: "10-15",
          color: "#058085",
        },
        {
          value: ">15",
          color: "#02454D",
        },
      ],
    },
    source: {
      text: "NCEI Natural Center for Environmental Information",
      link: "https://www.ngdc.noaa.gov/hazel/view/about",
    },
    metaDisplayMap: [
      {
        mapboxKey: "year",
        label: "Year",
      },
      {
        mapboxKey: "month",
        label: "Month",
      },
      {
        mapboxKey: "maxWaterHeight",
        label: "Water Height",
      },
      {
        mapboxKey: "deathsTotal",
        label: "Deaths",
        transformValue: valueToMln,
      },
      {
        mapboxKey: "housesDestroyedTotal",
        label: "Destroyed houses",
        transformValue: valueToMln,
      },
      {
        mapboxKey: "damageMillionsDollars",
        label: "Damage, mln $",
      },
    ],
  },
  {
    id: "8",
    displayName: "Volcanoes",
    mapboxId: "volcanoes-num-eruptions",
    fullName: "Number of eruptions",
    uniqKey: "id",
    group: "2",
    icon: <VolcanoesIcon />,
    filters: [
      {
        type: "range",
        mapboxFieldKey: "Year",
        initialValues: [1950, 2021],
        min: 1950,
        max: 2021,
        step: 1,
      },
    ],
    legend: {
      type: "points",
      useLayerIcon: true,
      options: [
        {
          value: "NA",
          color: "#8C8C8C",
        },
        {
          value: "1-2",
          color: "#FFE58F",
        },
        {
          value: "2-5",
          color: "#FFC53",
        },
        {
          value: "5-10",
          color: "#F5222D",
        },
        {
          value: "10-15",
          color: "#A8071A",
        },
        {
          value: "15-27",
          color: "#5C0011",
        },
      ],
    },
    source: {
      text: "NCEI Natural Center for Environmental Information",
      link: "https://www.ngdc.noaa.gov/hazel/view/about",
    },
    metaDisplayMap: [
      {
        mapboxKey: "Year",
        label: "Year (last eruption)",
      },
      {
        mapboxKey: "Mo",
        label: "Month (last eruption)",
      },
      {
        mapboxKey: "Name",
        label: "Name",
      },
      {
        mapboxKey: "VEI",
        label: "Volcanic Explosivity Index (last eruption)",
      },
      {
        mapboxKey: "Total Deaths",
        label: "Deaths (last eruption)",
      },
      {
        mapboxKey: "Damage ($Mil)",
        label: "Damage, mln $ (last eruption)",
      },
      {
        mapboxKey: "Total Houses Destroyed",
        label: "Destroyed houses (last eruption)",
      },
    ],
  },
  {
    id: "9",
    displayName: "Power plants",
    mapboxId: "power-plants-type",
    fullName: "Power plants",
    uniqKey: "id",
    group: "3",
    icon: <PowerPlantsIcon />,
    filters: [
      {
        type: "checkboxGroupRange",
        mapboxFieldKey: "capacity_mw",
        initialValues: ["1", "2", "3"],
        options: [
          {
            value: "1",
            range: [null, 100],
            label: "< 100 MW",
          },
          {
            value: "2",
            range: [100, 1000],
            label: "100 - 1000 MW",
          },
          {
            value: "3",
            range: [1000, null],
            label: "> 1000 MW",
          },
        ],
      },
    ],
    legend: {
      type: "points",
      useLayerIcon: true,
      options: [
        {
          value: "Renewables",
          color: "#BAE637",
          description:
            "includes Solar, Geothermal, Hydro, Wind,  Biomass, Cogeneration, Waste, Wave and Tidal",
        },
        {
          value: "Nuclear",
          color: "#262626",
        },
        {
          value: "Thermal",
          color: "#AD4E00",
          description: "includes Gas, Oil, Coal, Petcoke, Cogeneration",
        },
        {
          value: "Other",
          color: "#8C8C8C",
        },
      ],
    },
    source: {
      text: "World Resource Institute. Global power plant database",
      link: "https://datasets.wri.org/dataset/globalpowerplantdatabase",
    },
    metaDisplayMap: [
      {
        mapboxKey: "name",
        label: "Name",
      },
      {
        mapboxKey: "capacity_mw",
        label: "Capacity (MW)",
      },
      {
        mapboxKey: "primary_fuel",
        label: "Type",
      },
      {
        mapboxKey: "commissioning_year",
        label: "Commissioning year",
      },
      {
        mapboxKey: "owner",
        label: "Owner",
      },
      {
        mapboxKey: "estimated_generation_gwh_2017",
        label: "Generation (GW)",
      },
    ],
  },
  {
    id: "10",
    displayName: "Refineries",
    mapboxId: "",
    fullName: "Refineries",
    uniqKey: "",
    group: "3",
    icon: <RefineriesIcon />,
    disabled: true,
  },
  {
    id: "11",
    displayName: "Tailing storage facilities",
    mapboxId: "tailing-storages-status",
    fullName: "Status",
    uniqKey: "id",
    group: "4",
    icon: <TailingStorageIcon />,
    filters: [
      {
        type: "checkboxGroupRange",
        mapboxFieldKey: "current_tailings_storage",
        initialValues: ["1", "2", "3", "4"],
        options: [
          {
            value: "1",
            range: [null, 30e6],
            label: "< 30 mln m³",
          },
          {
            value: "2",
            range: [30e6, 720e6],
            label: "30 - 720 mln m³",
          },
          {
            value: "3",
            range: [720e6, null],
            label: "> 720 mln m³",
          },
          {
            value: "4",
            range: [],
            label: "NA",
          },
        ],
      },
    ],
    legend: {
      type: "points",
      useLayerIcon: true,
      options: [
        {
          value: "Construction",
          color: "#FADB14",
        },
        {
          value: "Active",
          color: "#73D13D",
        },
        {
          value: "Closed",
          color: "#CF1322",
        },
      ],
    },
    source: {
      text: "Global Tailings Portal",
      link: "https://tailing.grida.no/about",
    },
    metaDisplayMap: [
      {
        mapboxKey: "owner_company",
        label: "Company",
      },
      {
        mapboxKey: "status",
        label: "Status",
      },
      {
        mapboxKey: "construction_year",
        label: "Construction year",
      },
      {
        mapboxKey: "raise_type",
        label: "Raise type",
      },
      {
        mapboxKey: "current_maximum_height",
        label: "Max.height (m)",
      },
      {
        mapboxKey: "сurrent_tailings_storage",
        label: "Current storage (m3)",
      },
      {
        mapboxKey: "planned_storage_5_years",
        label: "Planned storage 5 years (m3)",
        transformValue: (v) => valueToMln(v),
      },
      {
        mapboxKey: "hazard_categorization",
        label: "Hazard categorization",
      },
      {
        mapboxKey: "extreme_weather_secure",
        label: "Extreme weather secure",
      },
      {
        mapboxKey: "link",
        label: "Link",
        isLink: true,
      },
    ],
  },
  {
    id: "12",
    displayName: "Yield per ha (USA, corn)",
    mapboxId: "us-cornyield-2017-2021-avg",
    fullName: "Corn yield, kg/ha",
    uniqKey: "id",
    group: "5",
    icon: <CornIcon />,
    source: {
      text: "Praedictus Climate Solutions, Inc.",
      link: "https://www.praedictus.com/",
    },
    legend: {
      type: "gradient",
      minText: "3000",
      maxText: "18990",
      options: [
        {
          value: 0,
          color: "#D9F7BE",
        },
        {
          value: 3000,
          color: "#D9F7BE",
        },
        {
          value: 7000,
          color: "#B7EB8F",
        },
        {
          value: 11000,
          color: "#73D13D",
        },
        {
          value: 15000,
          color: "#389E0D",
        },
        {
          value: 18990,
          color: "#135200",
        },
      ],
    },
    metaDisplayMap: [
      {
        mapboxKey: "NAME_1",
        label: "State",
      },
      {
        mapboxKey: "NAME_2",
        label: "County/Parish",
      },
      {
        mapboxKey: "cornYield_dec",
        label: "Corn yield, averaged from 2017-2021, kg/ha",
      },
    ],
  },
  {
    id: "13",
    displayName: "Type of the fields",
    mapboxId: "",
    fullName: "Type of the fields",
    uniqKey: "",
    group: "5",
    icon: <TypeOfTheFieldsIcon />,
    disabled: true,
  },
  {
    id: "14",
    displayName: "Soil moisture",
    mapboxId: "",
    fullName: "Soil moisture",
    uniqKey: "",
    group: "5",
    icon: <SoilMoistureIcon />,
    disabled: true,
  },
  {
    id: "15",
    displayName: "IoT",
    mapboxId: "",
    fullName: "IoT",
    uniqKey: "",
    group: "5",
    icon: <IotIcon />,
    disabled: true,
  },
  {
    id: "16",
    displayName: "Solar insolation",
    mapboxId: "",
    fullName: "Solar insolation",
    uniqKey: "",
    group: "6",
    icon: <SolarInsolationIcon />,
    disabled: true,
  },
  {
    id: "17",
    displayName: "Wind speed",
    mapboxId: "",
    fullName: "Wind speed",
    uniqKey: "",
    group: "6",
    icon: <WindSpeedIcon />,
    disabled: true,
  },
  {
    id: "18",
    displayName: "Deforestration",
    mapboxId: "",
    fullName: "Deforestration",
    uniqKey: "",
    group: "7",
    icon: <DeforestrationIcon />,
    disabled: true,
  },
];
export const LAYERS = layers.map((l) => ({
  ...l,
  group: allGroupsExtended.find((g) => g.id === l.group),
}));

export const CATEGORIES = uniqBy(
  LAYERS.map((layer) => layer.group.category),
  "id"
);

export const LAYERS_BY_GROUP = Object.entries(
  groupBy(LAYERS, (l) => l.group.id)
).map(([, groupLayers]) => ({
  layers: groupLayers,
  group: "layers"[0].group,
}));

export const PROJECTS_LAYERS_IDS = [
  "projects-centroid small area",
  "projects-centroid large area",
  "projects small area",
  "projects large area",
];
