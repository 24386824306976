import React from "react";
import { Form, Radio } from "antd";

import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import CheckboxGroupPublish from "../CheckboxGroupPublish/CheckboxGroupPublish";
import SwitchUnability from "../SwitchUnability/SwitchUnability";

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;

const ReportingFramework = ({ framework, updateFramework, title }) => (
  <>
    {title}
    <FrameworkSection title="Allocation reporting" number="5.1">
      <FrameworkQuestion
        number="5.1.1"
        title="How frequently will the allocation report be produced?"
      >
        <FormItem
          name="allocation_report_frequency"
          initialValue={framework.allocation_report_frequency}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <RadioGroup
            defaultValue={framework.allocation_report_frequency}
            onChange={adapterDOMEvent(
              updateFramework("allocation_report_frequency")
            )}
          >
            <Radio value="Quarterly">Quarterly</Radio>
            <Radio value="Half-year">Half-year</Radio>
            <Radio value="Yearly">Yearly</Radio>
          </RadioGroup>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="5.1.2"
        title="What level of detail will be provided for the allocation reporting?"
      >
        <FormItem
          name="allocation_report_detail"
          initialValue={framework.allocation_report_detail}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <RadioGroup
            defaultValue={framework.allocation_report_detail}
            onChange={adapterDOMEvent(
              updateFramework("allocation_report_detail")
            )}
          >
            <Radio value="Project">Project</Radio>
            <Radio value="Category">Category</Radio>
          </RadioGroup>
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
    <FrameworkSection title="Impact reporting" number="5.2">
      <FrameworkQuestion
        title="Will the impact report be provided?"
        number="5.2.1"
      >
        <FormItem
          name="impact_report_unavailability"
          initialValue={framework.impact_report_unavailability}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <SwitchUnability
            defaultValue={framework.impact_report_unavailability}
            onChange={updateFramework("impact_report_unavailability")}
          >
            <FrameworkQuestion
              title="When or for how long will the impact report be provided?"
              number="5.2.2"
            >
              <FormItem
                name="impact_report_time"
                initialValue={framework.impact_report_time}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <RadioGroup
                  defaultValue={framework.impact_report_time}
                  onChange={adapterDOMEvent(
                    updateFramework("impact_report_time")
                  )}
                >
                  <Radio value="At least once">At least once</Radio>
                  <Radio value="As long as any green bonds are outstanding">
                    As long as any green bonds are outstanding
                  </Radio>
                </RadioGroup>
              </FormItem>

              {framework.impact_report_time ===
                "As long as any green bonds are outstanding" && (
                <FrameworkQuestion description="How frequently will the impact report be provided?">
                  <FormItem
                    name="impact_report_frequency"
                    initialValue={framework.impact_report_frequency}
                    rules={[{ required: true, message: "Field is required" }]}
                  >
                    <RadioGroup
                      defaultValue={framework.impact_report_frequency}
                      onChange={adapterDOMEvent(
                        updateFramework("impact_report_frequency")
                      )}
                    >
                      <Radio value="Quarterly">Quarterly</Radio>
                      <Radio value="Half-year">Half-year</Radio>
                      <Radio value="Yearly">Yearly</Radio>
                    </RadioGroup>
                  </FormItem>
                </FrameworkQuestion>
              )}
            </FrameworkQuestion>

            <FrameworkQuestion
              title="What level of detail will be provided for the impact reporting?"
              number="5.2.3"
            >
              <FormItem
                name="impact_report_detail"
                initialValue={framework.impact_report_detail}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <RadioGroup
                  defaultValue={framework.impact_report_detail}
                  onChange={adapterDOMEvent(
                    updateFramework("impact_report_detail")
                  )}
                >
                  <Radio value="Project">Project</Radio>
                  <Radio value="Category">Category</Radio>
                </RadioGroup>
              </FormItem>
            </FrameworkQuestion>
          </SwitchUnability>
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
    <FrameworkSection
      number="5.3"
      one
      title="How will the report be made available?"
    >
      <FrameworkQuestion>
        <FormItem
          name="report_published"
          initialValue={framework.report_published}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <CheckboxGroupPublish
            defaultValue={framework.report_published}
            onChange={updateFramework("report_published")}
          />
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
  </>
);

export default ReportingFramework;
