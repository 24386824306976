import React from "react";
import { Card, Typography } from "antd";

import styles from "./Reports.module.less";
import ActivityTypes from "./components/ActivityTypes";

import ScreeningOverviewConnected from "./components/ScreeningOverview/ScreeningOverviewConnected";
import TaxonomyAligment from "../Charts/TaxonomyAligment";
import SubstantialContributionConnected from "./components/SubstantialContributionConnected";

const { Title } = Typography;

const ResultPageByParameter = ({ report, finParamName }) => (
  <div className={styles.grid}>
    <div className={styles.activityTypes}>
      <Card>
        <ActivityTypes paramName={finParamName} />
      </Card>
    </div>
    <div className={styles.substantialContribution}>
      <Card>
        <SubstantialContributionConnected paramName={finParamName} />
      </Card>
    </div>
    <div className={styles.taxonomyAlignment}>
      <Card>
        <Title level={4} align="center">
          Taxonomy alignment
        </Title>
        <TaxonomyAligment report={report} finParamNames={[finParamName]} />
      </Card>
    </div>
    <div className={styles.activities}>
      <Card>
        <ScreeningOverviewConnected finParamNames={[finParamName]} />
      </Card>
    </div>
  </div>
);

export default ResultPageByParameter;
