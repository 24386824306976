import React from "react";
import {
  Button,
  Section,
  Form,
  Radio,
  Input,
  TextArea,
} from "@evercityecosystem/evercity-ui";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useOutletContext } from "react-router-dom";
import styles from "./ProjectParticipantDetails.module.less";
import QuestionWithThreads from "../QuestionWithThreads/QuestionWithThreads";

const ProjectParticipantDetails = ({
  name,
  onDelete,
  onOpenDiscussionsDrawer,
  threads,
  ...rest
}) => {
  const { withoutThreshold } = useOutletContext();
  return (
    <Section
      title={
        <>
          {`Participant ${name + 1}`}
          <Tooltip title="Delete participant">
            <Button
              type="text"
              className={styles.removeBtn}
              onClick={() => onDelete(name)}
              icon={<MinusCircleOutlined />}
            />
          </Tooltip>
        </>
      }
    >
      <QuestionWithThreads
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        optional={withoutThreshold}
        property={`details_participants-${name}-role`}
        number={`2.4.${name + 1}.1`}
        title="Project role"
        description="Please specify and explain."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "role"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
        >
          <Radio.Group
            direction="vertical"
            options={[
              {
                label: "Project coordinator",
                value: "Project coordinator",
              },
              {
                label: "Sponsor",
                value: "Sponsor",
              },
              {
                label: "Technical advisor",
                value: "Technical advisor",
              },
              {
                label: "Technology provider",
                value: "Technology provider",
              },
              {
                label: "Implementation partner",
                value: "Implementation partner",
              },
              {
                label: "Intermediary",
                value: "Intermediary",
              },
              {
                label: "Designated Operational Entity under the CDM",
                value: "Designated Operational Entity under the CDM",
              },
              {
                label: "Validator / Verifier",
                value: "Validator / Verifier",
              },
              {
                label: "Other (please specify)",
                value: "Other (please specify)",
                specifiable: true,
              },
            ]}
          />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-name`}
        number={`2.4.${name + 1}.2`}
        title="Primary contact name and title"
        description="Name and title of the representative."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "name"]}
          validateTrigger="onBlur"
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-company`}
        number={`2.4.${name + 1}.3`}
        title="Company"
        description="Name of organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "company"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-address`}
        number={`2.4.${name + 1}.4`}
        title="Address"
        description="Address of organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "address"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-phone`}
        number={`2.4.${name + 1}.5`}
        title="Phone"
        description="Phone of organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "phone"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-email`}
        number={`2.4.${name + 1}.6`}
        title="Email"
        description="E-mail of organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "email"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" type="email" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-website`}
        number={`2.4.${name + 1}.7`}
        title="Website"
        description="Please include the website of your organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "website"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <Input size="large" roundCorners="large" />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-description`}
        number={`2.4.${name + 1}.8`}
        title="Brief description of organization"
        description="Please provide brief description of organization."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "description"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-experienceMarket`}
        number={`2.4.${name + 1}.9`}
        title="Experience with carbon markets"
        description="Describe experience related to the carbon markets."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "experienceMarket"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        threads={threads}
        property={`details_participants-${name}-experienceProject`}
        number={`2.4.${name + 1}.10`}
        title="Experience with this project type"
        description="Describe experience related to the proposed activity."
      >
        <Form.DiscussedItem
          {...rest}
          name={[name, "experienceProject"]}
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          validateTrigger="onBlur"
          trigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </Section>
  );
};

export default ProjectParticipantDetails;
