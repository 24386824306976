import { useSubscription } from "urql";
import { useMemo } from "react";
import { threadsSubscription } from "../../api/subscriptions";

const useGetThreads = ({ entity, entityId, group, property }, options = {}) => {
  const [threadResponse] = useSubscription({
    ...options,
    query: threadsSubscription,
    variables: {
      config: {
        entity,
        entityId,
      },
    },
  });

  const threads = threadResponse?.data?.messenger_threads;

  const groupedThreads = useMemo(
    () =>
      threads?.reduce((acc, thread) => {
        acc[thread.config.group] = {
          ...(acc[thread.config.group] || {}),
          [thread.thread_state.name]: [
            ...(acc[thread.config.group]?.[thread.thread_state.name] || []),
            thread,
          ],
        };
        return acc;
      }, {}),
    [threads]
  );

  if (group && !property) {
    return [
      {
        ...threadResponse,
        data: groupedThreads?.[group],
      },
    ];
  }

  if (group && property) {
    return [
      {
        ...threadResponse,
        data: Object.entries(groupedThreads?.[group] || {}).reduce(
          (acc, [state, threadsInState]) => {
            acc[state] = [
              ...(acc[state] || []),
              ...threadsInState.filter(
                (thread) => thread.config.property === property
              ),
            ];
            return acc;
          },
          {}
        ),
      },
    ];
  }

  return [
    {
      ...threadResponse,
      data: groupedThreads,
      dataRaw: threads,
    },
  ];
};

export default useGetThreads;
