import React from "react";
import { Typography, Space } from "antd";
import { Text } from "../../antd-ovveride";
import InlineNote from "../InlineNote/InlineNote";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";

const { Title } = Typography;

// TODO: переопределить глобально, добавить еще один пропс
const SpaceFlex = ({ style, ...props }) => (
  <Space {...props} style={{ ...style, display: "flex" }} />
);

const FormItemsGroup = ({
  children,
  title,
  subtitle,
  note,
  entryVideoStartTime,
  className,
}) => (
  <SpaceFlex direction="vertical" size={note ? 0 : 30}>
    <div>
      <SpaceFlex className={className} direction="vertical" size={10}>
        <SpaceFlex size={8} align="center">
          <Title level={3} style={{ marginBottom: 0 }}>
            {title}
          </Title>
          {typeof entryVideoStartTime === "number" && (
            <WatchTutorialBtn startTime={entryVideoStartTime} />
          )}
        </SpaceFlex>
        {subtitle && (
          <Text type="secondary" size="large" noMargin>
            {subtitle}
          </Text>
        )}
      </SpaceFlex>
      {note && (
        <InlineNote
          bodyPaddingBottom={30}
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          {note}
        </InlineNote>
      )}
    </div>
    <SpaceFlex direction="vertical" size={30}>
      {children}
    </SpaceFlex>
  </SpaceFlex>
);

export default FormItemsGroup;
