import React, { useState } from "react";
import { Typography, Tabs, Space, Radio, Row, Col } from "antd";

import {
  Button,
  ContentLayout,
  PieChartOutlined,
} from "@evercityecosystem/evercity-ui";

// import Nav from "./components/Nav";
import { DownloadOutlined } from "@ant-design/icons";
import OverviewPage from "./OverviewPage";
import ResultPageByParameter from "./ResultPageByParameter";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";
import { ReactComponent as PdfIcon } from "../../assets/pdf-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/excel-icon.svg";

import reportFinParams, {
  finParamsReversed,
} from "../../data/reportCurrencyParameters";

import styles from "./ResultPage.module.less";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";

const tabs = [
  {
    getComponent: (props) => <OverviewPage {...props} />,
    key: "1",
    name: "Overview",
  },
  {
    getComponent: ({ report, name }) => (
      <ResultPageByParameter
        report={report}
        finParamName={finParamsReversed[name]}
      />
    ),
    key: "2",
    name: reportFinParams.turnover,
  },
  {
    getComponent: ({ report, name }) => (
      <ResultPageByParameter
        report={report}
        finParamName={finParamsReversed[name]}
      />
    ),
    key: "3",
    name: reportFinParams.capex,
  },
  {
    getComponent: ({ report, name }) => (
      <ResultPageByParameter
        report={report}
        finParamName={finParamsReversed[name]}
      />
    ),
    key: "4",
    name: reportFinParams.opex,
  },
];

const ResultPage = ({
  report,
  onComplete,
  generatePdf,
  generateXls,
  generateCharts,
}) => {
  const { TabPane } = Tabs;
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);

  const loadingWrapper = async (fn) => {
    setLoading(true);
    await fn();
    setLoading(false);
  };

  const reportDownloadActions = [
    {
      type: "download_pdf",
      label: "PDF",
      icon: <PdfIcon />,
      onClick: () => loadingWrapper(generatePdf),
    },
    {
      type: "download_xls",
      label: "Excel",
      icon: <ExcelIcon />,
      onClick: () => loadingWrapper(generateXls),
    },
    {
      type: "download_charts",
      label: "Charts",
      icon: <PieChartOutlined />,
      onClick: () => loadingWrapper(generateCharts),
    },
  ];

  if (report?.company?.country === "Slovenija") {
    reportDownloadActions.push({
      type: "download_xls_sl",
      label: "Excel (SL)",
      icon: <ExcelIcon />,
      onClick: () => loadingWrapper(() => generateXls("sl")),
    });
  }

  return (
    <ContentLayout gaps="off">
      <Row justify="space-between" align="center">
        <Col>
          <Space align="center" size={8} className={styles.header}>
            <Space size="large" align="center">
              <Title level={3} style={{ marginBottom: 3 }}>
                {report.company?.name}
              </Title>
              <Radio.Button disabled>{report.reporting_year}</Radio.Button>
            </Space>
            <WatchTutorialBtn startTime={343} />
          </Space>
        </Col>
        <Col>
          <ActionsDropdown actions={reportDownloadActions}>
            <Button
              type="primary"
              loading={loading}
              size="large"
              icon={<DownloadOutlined />}
            >
              Download report
            </Button>
          </ActionsDropdown>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" className={styles.nav}>
        {tabs.map(({ name, key, getComponent, disabled }) => (
          <TabPane disabled={disabled} tab={name} key={key}>
            {getComponent({ report, name })}
          </TabPane>
        ))}
      </Tabs>

      <Space className={styles.footer}>
        <Button
          data-cy="btn-complete"
          type="primary"
          size="large"
          onClick={() => onComplete()}
        >
          Complete
        </Button>
      </Space>
    </ContentLayout>
  );
};

export default ResultPage;
