import React from "react";
import CheckboxGroupSpecify from "../CheckboxGroupSpecify/CheckboxGroupSpecify";

const CheckboxGroupPublish = ({ ...props }) => (
  <CheckboxGroupSpecify
    options={[
      {
        name: "On the company website",
        value: "on the company website",
        specifiable: true,
      },
      {
        name: "On GDP platform",
        value: "on GDP platform",
        specifiable: false,
      },
      {
        name: "Other (specify)",
        specifiable: true,
      },
    ]}
    {...props}
  />
);

export default CheckboxGroupPublish;
