import React, { useState } from "react";
import {
  Title,
  ContentLayout,
  Space,
  Button,
} from "@evercityecosystem/evercity-ui";

import SDGs from "../../../data/SDGs";

// import { useRouteLoaderData } from "react-router-dom";
import ImpactDrawer from "./ImpactDrawer";
import TableSDG from "./TableSDG";
import styles from "./ProjectImpactPage.module.less";

const ProjectImpactPage = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ContentLayout gaps="off">
      <Space size={40} block direction="vertical">
        <Title level={2} size="large">
          Impact
        </Title>

        <TableSDG
          title="Planet"
          description="Add your goals and describe how you plan to achieve it and measure results."
          goals={SDGs.filter((sdg) => [6, 13, 14, 15].includes(sdg.id))}
          dataSource={[]}
          onAdd={showDrawer}
          onEdit={showDrawer}
        />

        <TableSDG
          title="People"
          description="Add your goals and describe how you plan to achieve it and measure results."
          goals={SDGs.filter((sdg) =>
            [1, 2, 3, 4, 5, 7, 11, 16].includes(sdg.id)
          )}
          onAdd={showDrawer}
          onEdit={showDrawer}
        />

        <TableSDG
          title="Prosperty"
          description="Add your goals and describe how you plan to achieve it and measure results."
          goals={SDGs.filter((sdg) => [8, 9, 10, 12, 17].includes(sdg.id))}
          onAdd={showDrawer}
          onEdit={showDrawer}
        />
      </Space>

      <ImpactDrawer onClose={onClose} open={open} />

      <Space size={16} align="center" className={styles.actions}>
        <Button size="large">Back</Button>

        <Button size="large" type="primary" htmlType="submit">
          Next step
        </Button>
      </Space>
    </ContentLayout>
  );
};

export default ProjectImpactPage;
