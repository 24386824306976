// same interface as company fragment
const sampleCompany = {
  company_number: "000000000",
  country: "Germany",
  description: "",
  employee_num: "500",
  name: "Sample company",
  logo: "",
  lei: "0000000",
  nfrd_csrd: "",
  website: "",
};

export default sampleCompany;
