import React from "react";

import LockOverlay from "../../LockOverlay/LockOverlay";

const ReportingTabContent = () => (
  <LockOverlay>
    <div>Rporting tab content</div>;
  </LockOverlay>
);

export default ReportingTabContent;
