import React, { useEffect, useState } from "react";
import {
  Title,
  Form,
  Text,
  Table,
  Banner,
  Space,
  Button,
  EditOutlined,
  DeleteOutlined,
  TextArea,
  AttachFileInput,
  FilesTagsListInput,
  AttachFileProvider,
  PlusOutlined,
  Dropdown,
  EditableSelect,
  MoreOutlined,
  ContentLayout,
} from "@evercityecosystem/evercity-ui";

import { useRouteLoaderData } from "react-router-dom";
import classNames from "classnames";
import styles from "./ProjectRisksPage.module.less";

const RisksBanner = () => (
  <Banner
    className={styles.banner}
    colored
    title="Assess and share you physical climate risks"
    text="Add transparency to your project by physical climate risk disclosure according to international standards."
    button={{
      text: "Generate my report",
    }}
  />
);

const RISK_TYPES = ["Physical climate risk", "Compliance risks"];

const TableCreateRow = ({ handleAdd }) => {
  const [form] = Form.useForm();
  const handleFinish = (record) => {
    handleAdd({
      ...record,
      key: Date.now(),
    });
  };

  const rules = [{ required: true, message: "" }];

  return (
    <Form
      className={styles.createRow}
      onFinish={handleFinish}
      onSubmit={(e) => e.preventDefault()}
      name="row"
      form={form}
      component="div"
    >
      <Form.Item className={styles.createRowType} name="type" rules={rules}>
        <EditableSelect
          placeholder="Select or add risk type"
          options={RISK_TYPES.map((risk) => ({
            value: risk,
            label: risk,
          }))}
        />
      </Form.Item>

      <Form.Item
        className={styles.createRowDescription}
        name="description"
        rules={rules}
      >
        <TextArea
          className={styles.createRowTextArea}
          placeholder="What is the cause of the risk? How it affects the project?"
          autoSize={{ maxRows: 1, minRows: 1 }}
        />
      </Form.Item>

      <Form.Item
        className={styles.createRowDescription}
        name="strategy"
        rules={rules}
      >
        <TextArea
          className={styles.createRowTextArea}
          placeholder="What is the cause of the risk? How it affects the project?"
          autoSize={{ maxRows: 1, minRows: 1 }}
        />
      </Form.Item>

      <Form.Item className={styles.createRowAction}>
        <Button size="small" onClick={() => form.submit()}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  riskTypesState,
  ...restProps
}) => {
  const inputComponent =
    inputType === "select" ? (
      <EditableSelect
        placeholder="Select or add risk type"
        options={RISK_TYPES.map((risk) => ({
          value: risk,
          label: risk,
        }))}
      />
    ) : (
      <TextArea
        placeholder="What is the cause of the risk? How it affects the project?"
        autoSize={{ maxRows: 4, minRows: 1 }}
      />
    );

  return (
    <td
      {...restProps}
      valign="top
    "
    >
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputComponent}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const RisksForm = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [showFooter, setShowFooter] = useState(false);
  const [data, setData] = useState([
    {
      key: 0,
      type: "Compliance risks",
      strategy: "Sensitize, train and accompany the group dynamics ",
      description:
        "2 Climate risk assessment can provide a structured approach to identify, analyze, and evaluate such risks, and ultimately inform risk management.",
    },
    {
      key: 1,
      type: "Compliance risks 2",
      strategy: "Sensitize, train and accompany the group dynamics 2",
      description:
        "Climate risk assessment can provide a structured approach to identify, analyze, and evaluate such risks, and ultimately inform risk management. 2",
    },
  ]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      type: "",
      description: "",
      strategy: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = (key) => {
    setData((d) => d.filter((r) => r.key !== key));
  };

  useEffect(() => {
    setShowFooter(!data.length);
  }, [data]);

  const menuItemsListners = {
    edit: (record) => edit(record),
    delete: (record) => deleteRow(record.key),
  };

  const columns = [
    {
      title: "Risk type",
      dataIndex: "type",
      width: "15%",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "45%",
      editable: true,
    },
    {
      title: "Mitigation strategy",
      dataIndex: "strategy",
      width: "45%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "100px",
      render: (_, record) =>
        isEditing(record) ? (
          <Space size={8} block justifyContent="end" align="start">
            <Button onClick={() => save(record.key)}>Save</Button>
          </Space>
        ) : (
          <Space size={8} block justifyContent="end" align="start">
            <Dropdown
              menu={{
                items: [
                  { label: "Edit", key: "edit", icon: <EditOutlined /> },
                  {
                    label: "Delete",
                    key: "delete",
                    danger: true,
                    icon: <DeleteOutlined />,
                    onClick: (...args) => console.log(...args, "delete !!"),
                  },
                ],
                onClick: ({ key }) => menuItemsListners[key]?.(record),
              }}
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Space>
        ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "type" ? "select" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onFinish = (values) => {
    const result = { ...values, table: data };

    console.log(result, "Form result");
  };

  const uploadFile = async () =>
    new Promise((resolve) => {
      resolve({
        data: {
          upload_file_base64: {
            doc_id: "9d3e082e-1e6d-445c-bf42-c95cf82cd796",
            url: "https://test-evercity.s3.us-east-1.amazonaws.com/55c9c8a1-e345-484a-89d8-9b0cd4c64085-TransactionDetails.pdf",
          },
        },
      });
    });

  const riskTypesState = useState(RISK_TYPES);

  const handleAddRow = (row) => setData((rows) => [...rows, row]);

  return (
    <Form
      name="risks"
      form={form}
      onFinish={onFinish}
      className={classNames(styles.riskForm, {
        [styles.riskFormWithFooter]: showFooter,
      })}
    >
      <Title level={3} className={styles.tableHeading}>
        Physical climate risks
      </Title>

      <Space direction="vertical" size={12} block>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          footer={
            showFooter
              ? () => (
                  <TableCreateRow
                    handleAdd={handleAddRow}
                    riskTypesState={riskTypesState}
                  />
                )
              : undefined
          }
        />

        <Button
          icon={<PlusOutlined />}
          onClick={() => setShowFooter(true)}
          disabled={showFooter}
        >
          Add new risk
        </Button>
      </Space>

      <Text className={styles.docsHeading}>Supporting documents</Text>

      <AttachFileProvider
        context={{
          uploadFile,
        }}
      >
        <Form.Item name="docs" noStyle>
          <AttachFileInput size="large" className={styles.docsAttachBtn} />
        </Form.Item>
      </AttachFileProvider>

      <Form.Item name="docs" noStyle>
        <FilesTagsListInput />
      </Form.Item>

      <Space size={16} align="center" className={styles.actions}>
        <Button size="large">Back</Button>

        <Button size="large" type="primary" htmlType="submit">
          Next step
        </Button>
      </Space>
    </Form>
  );
};

const ProjectRisksPage = () => {
  // eslint-disable-next-line no-unused-vars
  const project = useRouteLoaderData("project");

  return (
    <ContentLayout gaps="off">
      <Title level={2} size="large" block className={styles.pageHeading}>
        Risks
      </Title>
      <Space block size={40} direction="vertical">
        <Text className={styles.subHeading}>
          Add data on project risks to provide ultimate transparency and gain
          trust of stakeholders.
        </Text>

        <RisksBanner />
      </Space>

      <RisksForm />
    </ContentLayout>
  );
};

export default ProjectRisksPage;
