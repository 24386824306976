import React from "react";
import { useMutation } from "urql";
import { notification } from "antd";
import differenceBy from "lodash.differenceby";

import {
  attachDocToUnitPointDecisionMutation,
  unattachDocFromUnitPointDecisionMutation,
  upsertUnitPointDecisionMutation,
} from "../../api/mutations";

import PointsForm from "../PointsForm/PointsForm";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";
import usePointsWithDecision from "../../hooks/taxonomy/usePointsWithDecision";

const UnitPointsFormConnected = ({
  sending,
  threads,
  activityId,
  unit,
  onSubmit,
  submitText,
  pointsType,
  onCancel,
  cancelText = "Cancel",
}) => {
  const { id: unitId, objective } = unit;
  const { data: points, fecthing } = usePointsWithDecision({
    pointsType,
    activityId,
    unitId,
    objective,
  });

  const [upsertResponse, upsertPointDecision] = useMutation(
    upsertUnitPointDecisionMutation
  );

  const onPointChangeHandler = async (point, value) => {
    await upsertPointDecision({
      point_id: point.id,
      decision: value,
      unit_id: unitId,
    });
  };

  const [attachDocToUnitPointDecisionResponse, attachDocToUnitPointDecision] =
    useMutation(attachDocToUnitPointDecisionMutation);
  const [
    unattachDocFromUnitPointDecisionResponse,
    unattachDocFromUnitPointDecision,
  ] = useMutation(unattachDocFromUnitPointDecisionMutation);

  const unattachFiles = async (point, files) => {
    const promises = files.map((file) =>
      unattachDocFromUnitPointDecision({
        doc_id: file.docId,
        unit_point_decision_id: point.decision_id,
      })
    );
    try {
      const responses = await Promise.all(promises);
      const error = responses.some((response) => !!response.error);
      if (error) {
        throw new Error("Unattach error");
      }
    } catch (err) {
      notification.error({
        message: "Files unattach error",
        description: "Please try again later.",
      });
    }
  };

  const attachFiles = async (point, files) => {
    const promises = files.map((file) =>
      attachDocToUnitPointDecision({
        doc_id: file.docId,
        unit_point_decision_id: point.decision_id,
      })
    );
    try {
      const responses = await Promise.all(promises);
      const error = responses.some((response) => !!response.error);
      if (error) {
        throw new Error("Attach error");
      }
    } catch (err) {
      notification.error({
        message: "Files attach error",
        description: "Please try again later.",
      });
    }
  };

  const onPointFilesChange = (point, fileList) => {
    if (!point.decision_id) {
      notification.error({
        message: "Files attach error",
        description: "Please try again later.",
      });
      return;
    }

    const exsistsFiles = point.docs.map(docToAttachedFile);

    const removedFiles = differenceBy(exsistsFiles, fileList, "docId");
    const addedFiles = differenceBy(fileList, exsistsFiles, "docId");

    if (addedFiles.length) {
      attachFiles(point, addedFiles);
    }

    if (removedFiles.length) {
      unattachFiles(point, removedFiles);
    }
  };

  const sendingMutation =
    upsertResponse.fetching ||
    attachDocToUnitPointDecisionResponse.fecthing ||
    unattachDocFromUnitPointDecisionResponse.fetching;

  return fecthing || !points ? (
    <LoadingSpinner fill size="large" />
  ) : (
    <PointsForm
      threads={threads}
      points={points}
      sending={sendingMutation || sending}
      onPointChange={onPointChangeHandler}
      onPointFilesChange={onPointFilesChange}
      onSubmit={(values) => onSubmit({ values, points })}
      submitText={submitText}
      onCancel={onCancel}
      cancelText={cancelText}
    />
  );
};

export default UnitPointsFormConnected;
