import React from "react";
import { Slider, Row, Col } from "antd";
import { Text } from "@antd-ovveride";
import styles from "./RangeFilter.module.less";

const RangeFilter = ({ filter, value, onChange }) => (
  <Row gutter={10} align="middle">
    <Col flex="none">
      <Text>{value?.[0] || filter.min}</Text>
    </Col>
    <Col flex="auto">
      <div style={{ width: "100%" }}>
        <Slider
          range
          defaultValue={[filter.min, filter.max]}
          min={filter.min}
          max={filter.max}
          step={filter.step}
          value={value}
          onChange={onChange}
          className={styles.slider}
        />
      </div>
    </Col>
    <Col flex="none">
      <Text>{value?.[1] || filter.max}</Text>
    </Col>
  </Row>
);

export default RangeFilter;
