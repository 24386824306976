import { useState, useEffect, useCallback } from "react";
import { useNavigate, useMatch, useLocation } from "react-router-dom";

const getStepIndexByRoute = (steps, route) =>
  steps.findIndex((step) => step.route === route);

const useStepsNavigation = (steps, isNew, baseRoute) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch({ path: `${baseRoute}/:id/:stepRoute` });
  const stepRoute = match?.params?.stepRoute || "";
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const requestNextStep = location.state?.next;
  const contentLoading = requestNextStep || (isNew && activeStepIndex !== 0);

  const changeStep = useCallback(
    (indexOrCb) => {
      const newIndex =
        typeof indexOrCb === "function"
          ? indexOrCb(activeStepIndex)
          : indexOrCb;

      if (activeStepIndex !== newIndex) {
        navigate(`${steps[newIndex].route}`);
      }
    },
    [activeStepIndex, navigate, steps]
  );

  // Change index by route
  useEffect(() => {
    // console.error(
    //   "не работает переключение, видимо хук вызывавется до обновления history и выкидывает назад"
    // );
    const stepIndex = getStepIndexByRoute(steps, stepRoute);
    setActiveStepIndex(stepIndex);
  }, [stepRoute, steps, changeStep]);

  // Prevent visit other steps for newcomers
  useEffect(() => {
    if (isNew && activeStepIndex !== 0) {
      changeStep(0);
    }
  }, [isNew, activeStepIndex, changeStep]);

  // Switch step if next requested
  useEffect(() => {
    if (requestNextStep) {
      navigate(location.pathname, {});
      changeStep((i) => i + 1);
    }
  }, [requestNextStep, changeStep, navigate, location.pathname]);
  return [activeStepIndex, changeStep, contentLoading];
};

export default useStepsNavigation;
