import React from "react";
import { Popup } from "react-map-gl";
import { Text, Space } from "@antd-ovveride";
import { useResponsive } from "ahooks";
import { CloseCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import styles from "./PointFeaturePopup.module.less";

const { Title } = Typography;

const ListItem = ({ item, feature }) => {
  const { md } = useResponsive();
  const transformValue = item.transformValue || ((v) => v);
  const value =
    transformValue(feature.properties[item.mapboxKey]) ||
    item.callback ||
    "N/A";

  return (
    <>
      <Text
        size={md ? "small" : "extra-large"}
        type="secondary"
        className={styles.itemKey}
      >
        {item.label}
      </Text>
      <Text size={md ? "small" : "extra-large"} className={styles.itemValue}>
        {item.isLink ? (
          <a href={value} target="_blank" rel="noreferrer">
            View
          </a>
        ) : (
          value
        )}
      </Text>
    </>
  );
};

const PointFeaturePopup = ({ onClose, feature, layer }) => {
  const { md } = useResponsive();

  return (
    <Space
      block
      size={md ? 25 : 30}
      direction="vertical"
      className={styles.pointFeaturePopup}
    >
      <div className={styles.header}>
        {md ? (
          <Text
            size="small"
            strong
            style={{ marginBottom: 0 }}
            className={styles.title}
          >
            {layer.displayName}
          </Text>
        ) : (
          <Title level={4} style={{ marginBottom: 0 }} className={styles.title}>
            {layer.displayName}
          </Title>
        )}
        <CloseCircleFilled onClick={onClose} className={styles.closeButton} />
      </div>
      <div className={styles.list}>
        {layer.metaDisplayMap.map((item) => (
          <ListItem item={item} feature={feature} key={item.label} />
        ))}
      </div>
    </Space>
  );
};

const PointFeaturePopupContainer = ({ feature, lngLat, onClose, layer }) => {
  const { md } = useResponsive();

  return md ? (
    <Popup
      tipSize={5}
      longitude={lngLat[0]}
      latitude={lngLat[1]}
      closeOnClick={false}
      onClose={onClose}
      className={styles.desktopPopupContainer}
      closeButton={false}
      offsetLeft={23}
      offsetTop={-37}
      anchor="top-left"
      // dynamicPosition={false}
    >
      <PointFeaturePopup onClose={onClose} feature={feature} layer={layer} />
    </Popup>
  ) : (
    <div className={styles.mobilePopupContainer}>
      <PointFeaturePopup onClose={onClose} feature={feature} layer={layer} />
    </div>
  );
};

export default PointFeaturePopupContainer;
