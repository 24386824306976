import React from "react";
import { TextArea, Title, Form } from "@evercityecosystem/evercity-ui";
import { useOutletContext } from "react-router-dom";
import QuestionWithThreads, {
  GroupContext,
} from "../QuestionWithThreads/QuestionWithThreads";

const ProjectIdeaNoteBenefits = ({ onOpenDiscussionsDrawer, threads }) => {
  const { withoutThreshold } = useOutletContext();
  return (
    <GroupContext.Provider value="benefits">
      <Title level={3} size="middle">
        4. Environment, economic and social co-benefits
      </Title>
      <QuestionWithThreads
        optional={withoutThreshold}
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="benefits_environmental"
        number="4.1"
        title="Other environmental benefits"
        description="Specify the environmental benefits beyond climate action and, if relevant, their relation to the environmental strategy and priorities of the host country."
      >
        <Form.DiscussedItem
          name="benefits_environmental"
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        optional={withoutThreshold}
        threads={threads}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="benefits_economic"
        number="4.2"
        title="Economic benefits"
        description="What economic effects are attributable to the project which would not have occurred in a comparable situation without that project?"
      >
        <Form.DiscussedItem
          name="benefits_economic"
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
      <QuestionWithThreads
        threads={threads}
        optional={withoutThreshold}
        onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
        property="benefits_social"
        number="4.3"
        title="Social benefits"
        description="What social effects are attributable to the project which would not have occurred in a comparable situation without that project?"
      >
        <Form.DiscussedItem
          name="benefits_social"
          rules={[
            { required: !withoutThreshold, message: "Field is required" },
          ]}
          trigger="onBlur"
          validateTrigger="onBlur"
          valuePropName="defaultValue"
        >
          <TextArea size="large" roundCorners wordLimit={500} showCount />
        </Form.DiscussedItem>
      </QuestionWithThreads>
    </GroupContext.Provider>
  );
};

export default ProjectIdeaNoteBenefits;
