import React from "react";
import { RedoOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import NotAlignedModal from "./NotAlignedModal";

const MssNotAlignedModal = ({ onSave, onRetry, ...rest }) => (
  <NotAlignedModal
    {...rest}
    title="Assessment incomplete"
    content={
      <div>
        <p>
          Unfortunately, your company failed to meet some of the MSS screening
          criteria.
        </p>
        <span>Please proceed using one of the following options:</span>
        <ol>
          <li>Retry: change some parameters and screen it again;</li>
          <li>
            Save and proceed: save the results and come to it later when you are
            ready to meet MSS criteria.
          </li>
        </ol>
        <p>You will be able to return to this page later.</p>
      </div>
    }
    footer={[
      <Button
        key="retry"
        onClick={onRetry}
        size="large"
        icon={<RedoOutlined />}
      >
        Retry
      </Button>,
      <Button
        key="save_and_proceed"
        type="primary"
        onClick={onSave}
        size="large"
        icon={<SaveOutlined />}
      >
        Save and proceed
      </Button>,
    ]}
  />
);

export default MssNotAlignedModal;
