import React, { useEffect, useRef, useState } from "react";

import {
  Drawer,
  Discussion,
  InboxOutlined,
  Tabs,
  Badge,
  message as openMessage,
} from "@evercityecosystem/evercity-ui";

import { useMutation, useSubscription } from "urql";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  createMentionMutation,
  createThreadMessageMutation,
  createThreadMutation,
  deleteMentionMutation,
  deleteThreadMessageMutation,
  deleteThreadMutation,
  updateThreadMessageMutation,
  updateThreadMutation,
} from "../../api/mutations";
import { useCurrentUser } from "../../hooks/user";
import { threadsByStatesSubscription } from "../../api/subscriptions";
import useGetUsers from "../../hooks/user/useGetUsers";
import { THREAD_STATUSES } from "../../data/messenger";

const TabWithBadge = ({ label, count }) => (
  <>
    {label}
    <Badge type="outlined" count={count} />
  </>
);

const TOPIC_TITLE_SIZE = 125;

const DiscussionDrawerContent = ({
  config,
  drawerRef,
  flowRef,
  onClickTopic,
  onChangeDrawerState,
}) => {
  const [{ data, fetching }] = useSubscription({
    query: threadsByStatesSubscription,
    pause: !config,
    variables: {
      config: {
        entityId: config.entityId,
        entity: config.entity,
        group: config.group,
      },
    },
  });

  const [, createThread] = useMutation(createThreadMutation);
  const [, deleteThread] = useMutation(deleteThreadMutation);
  const [, createThreadMessage] = useMutation(createThreadMessageMutation);
  const [, deleteThreadMessage] = useMutation(deleteThreadMessageMutation);
  const [, updateThreadMessage] = useMutation(updateThreadMessageMutation);

  const [, createMention] = useMutation(createMentionMutation);
  const [, deleteMention] = useMutation(deleteMentionMutation);
  const companyUsers = useGetUsers();

  const [{ userRole, data: userData }] = useCurrentUser();

  const [, updateThread] = useMutation(updateThreadMutation);

  const [currentTab, setCurrentTab] = useState(config.status);

  useEffect(() => {
    if (config.status) {
      setCurrentTab(config.status);
    }
  }, [config.status]);

  const threadStates = data?.messenger_thread_states;

  const onChangeThreadStatus = async ({ status, id }) => {
    const stateIndex = threadStates.findIndex((s) => s.name === status);

    const nextState = threadStates[stateIndex];

    await updateThread({
      id,
      changes: {
        state_id: nextState.id,
      },
    });
    setCurrentTab(nextState.name);
  };
  const onDeleteComment = async (id) => {
    await deleteThreadMessage({
      id,
    });
  };

  const onDeleteThread = async (id) => {
    await deleteThread({
      id,
    });
  };

  const onCreateComment = async ({ threadId, text, author }) => {
    const { data: messageData } = await createThreadMessage({
      message: {
        text,
        user_id: userRole === "admin" ? author : undefined,
        thread_id: threadId,
      },
    });

    return messageData?.insert_messenger_messages_one?.id;
  };

  const onDeleteMentions = async (messageId) => {
    await deleteMention({
      messageId,
    });
  };

  const onCreateMentions = async (mensions, messageId) => {
    const commentMentionsIds =
      mensions
        ?.map(
          (mension) =>
            companyUsers?.users?.find((u) => u.display_name === mension)?.id
        )
        // Имена с пробелом не проходят стандартный отбор Mention
        ?.filter(Boolean) || [];

    await createMention({
      mentions: commentMentionsIds.map((mentionUserId) => ({
        user_id: mentionUserId,
        message_id: messageId,
      })),
    });
  };

  const onCreateThread = async ({ status, topic, property }) => {
    const { data: createdThreadData } = await createThread({
      thread: {
        config: {
          group: config.group,
          property,
          entityId: config.entityId,
          entity: config.entity,
        },
        topic,
        state_id: threadStates.find((s) => s.name === status).id,
        created_by: userRole === "admin" ? userData.id : undefined,
      },
    });

    return createdThreadData?.insert_messenger_threads_one?.id;
  };
  const onCopyLink = ({ threadId }) => {
    navigator.clipboard
      .writeText(`${window.location.href}?threadId=${threadId}`)
      .then(() => {
        openMessage.success({
          content: "Link copied to clipboard",
          placement: "bottomRight",
        });
      });
  };
  const discussionRef = useRef();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      config &&
      config.trigger?.source === "link" &&
      discussionRef.current &&
      drawerRef.current &&
      flowRef.current
    ) {
      const currentThreadElement = discussionRef.current.querySelector(
        `[data-thread="${config.threadId}"]`
      );

      const currentTopicElement = flowRef.current.querySelector(
        `[data-topic="${config.topic}"]`
      );

      if (currentThreadElement && currentTopicElement) {
        setTimeout(() => {
          drawerRef.current.scrollTo({
            top: currentThreadElement.offsetTop,
            behavior: "smooth",
          });

          document.querySelectorAll("section")[1].scrollTo({
            top: currentTopicElement.offsetTop,
            behavior: "smooth",
          });
        }, 500);
      }
    }
    if (
      config &&
      config.trigger?.source === "drawer" &&
      discussionRef.current &&
      flowRef.current
    ) {
      const currentTopicElement = flowRef.current.querySelector(
        `[data-topic="${config.topic}"]`
      );

      const currentTopicElementInDrawer = [
        ...discussionRef.current.children,
      ].find((element) => element.dataset.topic === config.topic);

      if (currentTopicElement) {
        // Todo выделить скролл, заменить querySelector-ы
        const delta =
          (config.trigger.event?.clientY ||
            currentTopicElementInDrawer?.getBoundingClientRect()?.top) -
          TOPIC_TITLE_SIZE;
        document.querySelectorAll("section")[1].scrollTo({
          top: currentTopicElement.offsetTop - delta,
          behavior: "smooth",
        });
      }
    }

    if (
      config &&
      config.trigger?.source === "flow" &&
      discussionRef.current &&
      drawerRef.current
    ) {
      const currentTopicElement = [...discussionRef.current.children].find(
        (element) => element.dataset.topic === config.topic
      );
      if (currentTopicElement) {
        const delta =
          config.trigger.event.clientY -
          drawerRef.current.getBoundingClientRect().top -
          TOPIC_TITLE_SIZE;
        drawerRef.current.scrollTo({
          top: currentTopicElement.offsetTop - delta,
          behavior: "smooth",
        });
      }
    }
  }, [discussionRef.current, drawerRef.current, flowRef.current, config]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const onChangeComment = async (id, text) => {
    await updateThreadMessage({
      id,
      changes: { text },
    });
  };

  const mentionOptions =
    companyUsers?.users
      ?.filter((user) => user.display_name !== userData?.display_name)
      ?.map((user) => ({
        label: user.display_name,
        value: user.display_name,
      })) || [];

  const threadContent = threadStates?.map(({ name: status, threads }) => (
    <Tabs.TabPane
      tab={
        <TabWithBadge label={THREAD_STATUSES[status]} count={threads.length} />
      }
      key={status}
    >
      <Discussion
        ref={discussionRef}
        onCopyLink={onCopyLink}
        onClickTopic={onClickTopic}
        onChangeComment={onChangeComment}
        onChangeThreadStatus={onChangeThreadStatus}
        onCreateThread={onCreateThread}
        mentionOptions={mentionOptions}
        onCreateMentions={onCreateMentions}
        onCreateComment={onCreateComment}
        onDeleteThread={onDeleteThread}
        onDeleteComment={onDeleteComment}
        onDeleteMentions={onDeleteMentions}
        currentTopic={config.topic}
        currentProperty={config.property}
        onChangeState={onChangeDrawerState}
        currentThread={config.threadId}
        currentUser={{
          name: userData?.display_name,
          id: userData?.id,
        }}
        currentStatus={status}
        threads={threads.map((thread) => ({
          ...thread,
          author: {
            id: thread.creator.id,
            name: thread.creator.display_name,
            avatar: thread.creator.avatar_url,
          },
          property: thread.config.property,
          comments: thread.messages.map((message) => ({
            ...message,
            createdAt: message.created_at,
            author: {
              id: message.author.id,
              name: message.author.display_name,
              avatar: message.author.avatar_url,
            },
          })),
        }))}
      />
    </Tabs.TabPane>
  ));

  if (fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  return (
    <Tabs
      activeKey={currentTab}
      onChange={(tab) => {
        setCurrentTab(tab);
      }}
    >
      {threadContent}
    </Tabs>
  );
};

const DiscussionDrawer = ({
  onClose,
  config,
  flowRef,
  onClickTopic,
  onChangeDrawerState,
}) => {
  const ref = useRef();

  return (
    <Drawer
      width={386}
      ref={ref}
      placement="right"
      destroyOnClose
      forceRender={false}
      onClose={onClose}
      open={!!config}
      icon={<InboxOutlined />}
      title="Inbox"
      mask={false}
    >
      {config && (
        <DiscussionDrawerContent
          drawerRef={ref}
          flowRef={flowRef}
          config={config}
          onChangeDrawerState={onChangeDrawerState}
          onClickTopic={onClickTopic}
        />
      )}
    </Drawer>
  );
};

export default DiscussionDrawer;
