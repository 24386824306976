import React from "react";
import classnames from "classnames";
import { Text, Title } from "../../antd-ovveride";
import styles from "./SummarySection.module.less";

const SummarySection = ({ title, children, className, description }) => (
  <div className={classnames(styles.section, className)}>
    <div
      className={classnames(
        styles.section__head,
        description && styles["section__head--withDescription"]
      )}
    >
      <Title level={3}>{title}</Title>
      {description && (
        <Text description className={styles.section__desc}>
          {description}
        </Text>
      )}
    </div>
    {children}
  </div>
);

export default SummarySection;
