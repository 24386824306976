import dayjs from "dayjs";
import Session from "supertokens-web-js/recipe/session";
import { decodeJwt } from "jose";

export const LS_TOKEN_KEY = "accessToken";

export const setData = (token) => {
  const oldValue = window.localStorage.getItem(LS_TOKEN_KEY);
  if (!token) {
    localStorage.removeItem(LS_TOKEN_KEY);
  }

  if (token) {
    localStorage.setItem(LS_TOKEN_KEY, token);
  }

  const event = new StorageEvent("storage", {
    key: LS_TOKEN_KEY,
    oldValue,
    newValue: token,
  });

  window.dispatchEvent(event);
};

export const getData = async () => {
  if (
    !(await Session.doesSessionExist()) &&
    process.env.NODE_ENV !== "development"
  ) {
    return {};
  }
  let token = localStorage.getItem(LS_TOKEN_KEY);

  if (!token && process.env.NODE_ENV === "development") {
    return {};
  }
  let result = {};

  if (!token) {
    const tokenResponse = await fetch(
      `${process.env.REACT_APP_AUTH_API_URL}/jwt/static?service=legacy`
    ).then((res) => res.json());
    token = tokenResponse.accessToken;

    if (!token) {
      throw new Error("Invalid jwt");
    }
  }

  try {
    result = decodeJwt(token);
  } catch (error) {
    console.error(error);
    return {};
  }

  const userId = result.hasura["x-hasura-user-id"];
  const userRole = result.hasura["x-hasura-default-role"];

  const expiration = result.exp * 1000;

  if (dayjs().isAfter(dayjs(expiration))) {
    localStorage.removeItem(LS_TOKEN_KEY);
    return {};
  }

  return {
    token,
    userId,
    userRole,
  };
};
