import React, { useMemo } from "react";
import { Col, Empty, Row, Typography } from "antd";
import groupBy from "lodash.groupby";
import {
  getChartData,
  getLegend,
} from "../ResultPage/components/ScreeningOverview/lib";
import ActivitiesChart from "../Charts/ActivitiesChart";
import Legend from "../Charts/Legend";
import LegendList from "../Charts/LegendList";

const { Title } = Typography;

const AllReports = ({ chartData, legend }) => (
  <>
    <Row>
      {chartData?.map(({ name, values }) => (
        <Col span={8} key={name}>
          <ActivitiesChart data={values} showTitle title={name} />
        </Col>
      ))}
    </Row>
    <Row style={{ marginTop: 30 }} justify="space-between">
      <Legend items={legend} />
    </Row>
  </>
);

const SingleReport = ({ chartData, legend, aligned }) => (
  <Row gutter={[32, 32]}>
    <Col span={8} style={{ position: "relative", top: -20 }}>
      {chartData?.map(({ name, values }) => (
        <ActivitiesChart aligned={aligned} data={values} title={name} />
      ))}
    </Col>
    <Col span={15}>
      <LegendList legend={legend} />
    </Col>
  </Row>
);

const ScreeningOverview = ({
  actReports,
  problems,
  finParamNames,
  showAligned,
}) => {
  const isSingleChart = finParamNames.length === 1;
  const actReportsWithProblems = useMemo(() => {
    if (!actReports || !problems) return;

    return actReports.map((actReport) => ({
      ...actReport,
      problems: groupBy(
        problems.filter(
          (problem) => problem.point.activity_id === actReport.activity.id
        ),
        (problem) => problem.point.type
      ),
    }));
  }, [actReports, problems]);

  const legend = useMemo(
    () =>
      !!actReportsWithProblems.length &&
      getLegend(actReportsWithProblems, finParamNames, showAligned),
    [actReportsWithProblems, showAligned, finParamNames]
  );

  const chartData = useMemo(
    () =>
      !!actReportsWithProblems.length &&
      getChartData(actReportsWithProblems, finParamNames, showAligned),
    [actReportsWithProblems, finParamNames, showAligned]
  );

  const chartsContent = isSingleChart ? (
    <SingleReport aligned={showAligned} chartData={chartData} legend={legend} />
  ) : (
    <AllReports aligned={showAligned} chartData={chartData} legend={legend} />
  );

  return (
    <>
      <Title
        level={4}
        style={{
          textAlign: "center",
        }}
      >
        Screening overview{" "}
        {showAligned ? "aligned activities" : "eligible activities"}
      </Title>
      {!!legend && !!chartData ? chartsContent : <Empty />}
    </>
  );
};
export default ScreeningOverview;
