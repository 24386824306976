/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Tooltip } from "antd";

export const convertTextToDefinition = (data, text, type = "link") => {
  let injectedText = text;

  data.forEach((dataItem) => {
    let label = "";
    let payload = "";

    if (type === "link") {
      const { link } = dataItem;
      label = link.label;
      payload = {
        type,
        label,
        payloadData: {
          URL: link.URL,
        },
      };
    } else {
      const { item, overridden_label: ovrLabel } = dataItem;
      label = ovrLabel || item.label;
      payload = {
        type,
        label,
        payloadData: {
          links: item.item_links,
          description: item.description,
        },
      };
    }

    const searchMask = new RegExp(
      label.replaceAll("(", "\\(").replaceAll(")", "\\)"),
      "ig"
    );
    const replaceMask = `<%>${JSON.stringify(payload)}<%>`;

    injectedText = injectedText.replace(searchMask, replaceMask);
  });

  return injectedText;
};

export const convertDefToComponents = (definitionText) => {
  const textArray = definitionText.split("<%>");

  return textArray.map((item) => {
    let textItemData = {};

    try {
      textItemData = JSON.parse(item);
    } catch (error) {
      return item;
    }

    const { type, label, payloadData } = textItemData;
    if (type === "hint") {
      const { description } = textItemData.payloadData;
      const tooltipTextDef = convertTextToDefinition(
        payloadData.links,
        description,
        "link"
      );

      return (
        <Tooltip title={convertDefToComponents(tooltipTextDef)}>
          <span
            style={{
              color: "#7c41b7",
              backgroundColor: "#e7e3f4",
              cursor: "pointer",
            }}
          >
            {label}
          </span>
        </Tooltip>
      );
    }

    return (
      <a
        style={{ textDecoration: "underline 1px solid" }}
        href={textItemData?.payloadData?.URL || ""}
        target="_blank"
        rel="noreferrer"
      >
        {textItemData.label}
      </a>
    );
  });
};
