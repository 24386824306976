import React from "react";
import { useResponsive } from "ahooks";
import { Empty } from "antd";

import CatalogTable from "../../CatalogTable/CatalogTable";
import CatalogCardsList from "../../CatalogCardsList/CatalogCardsList";

const ImpactTabContent = ({ debt }) => {
  const { md } = useResponsive();
  if (debt.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const projects = debt.bond_projects.map(({ project }) => project);

  return (
    <>
      {md ? (
        <CatalogTable type="project" data={projects} />
      ) : (
        <CatalogCardsList items={projects} type="project" />
      )}
    </>
  );
};

export default ImpactTabContent;
