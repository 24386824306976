import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// TODO: добавить интеграцию с роутингом(прокидывать как-то history), добаить теги по юзерам, подумать над релизами

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing({
        // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
