import React from "react";
import {
  NavigationTabs,
  Avatar,
  Link,
  EyeOutlined,
  Button,
  UsergroupAddOutlined,
  Space,
} from "@evercityecosystem/evercity-ui";

const items = [
  {
    label: "Info",
    path: "info",
  },
  {
    label: "Impact",
    path: "impact",
  },
  {
    label: "Risks",
    path: "risks",
    status: "warning",
    count: 1,
  },
  {
    label: "Documents",
    path: "documents",
  },
  {
    label: "Finance",
    path: "finance",
  },
  {
    label: "Reporting",
    path: "reporting",
    disabled: true,
  },
];

const ProjectFlowHeader = () => (
  <>
    <NavigationTabs progress={1} items={items} />
    <Space>
      <Avatar.Group>
        <Avatar />
        <Avatar />
      </Avatar.Group>
      <Button icon={<UsergroupAddOutlined />}>Add</Button>
    </Space>

    <Space>
      <Link type="btn" to="preview" icon={<EyeOutlined />}>
        Preview
      </Link>
      <Button type="primary">Publish</Button>
    </Space>
  </>
);
export default ProjectFlowHeader;
