import { useMutation } from "urql";
import { useEffect } from "react";
import { generateFramework } from "../../api/mutations";

const useGenerateFramework = () => {
  const [{ data }, generate] = useMutation(generateFramework);

  useEffect(() => {
    if (data) {
      window.open(data.generateFramework.url, "_blank");
    }
  }, [data]);

  const onGenerate = (id) => {
    generate({ id });
  };
  return { onGenerate };
};

export default useGenerateFramework;
