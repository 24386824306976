import React from "react";
import {
  Space,
  Text,
  useThreads,
  ThreadProvider,
} from "@evercityecosystem/evercity-ui";
import BooleanPoint from "./BooleanPoint";

const BooleanPointWithText = ({
  text,
  discussions,
  "data-topic": dataTopic,
  ...booleanPointProps
}) => {
  const threads = useThreads(discussions);
  return (
    <Space size={8} direction="vertical" data-topic={dataTopic}>
      <Text size="large">
        {text} {threads}
      </Text>
      <ThreadProvider
        openCount={discussions?.open?.count || 0}
        submittedCount={discussions?.submitted?.count || 0}
      >
        <BooleanPoint {...booleanPointProps} />
      </ThreadProvider>
    </Space>
  );
};

export default BooleanPointWithText;
