import React from "react";
import {
  Col,
  Row,
  Form,
  TextArea,
  Text,
  Button,
  Input,
  Space,
} from "@evercityecosystem/evercity-ui";
import { PlusOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import styles from "./RisksTable.module.less";
import QuestionWithThreads from "../QuestionWithThreads/QuestionWithThreads";

const RisksTable = ({ threads, onOpenDiscussionsDrawer }) => {
  const { withoutThreshold } = useOutletContext();
  return (
    <>
      <Row className={styles.table__head}>
        <Col span={12}>
          <Text strong size="large">
            Risk/Uncertainty Identification
          </Text>
        </Col>
        <Col span={12}>
          <Text strong size="large">
            Mitigation/Management Strategy
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <QuestionWithThreads
            optional={withoutThreshold}
            threads={threads}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            property="details_regulatory_strategy"
            title={<Text>Regulatory Risk:</Text>}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            validateTrigger="onBlur"
            name="details_regulatory_strategy"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={150} showCount />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <QuestionWithThreads
            optional={withoutThreshold}
            threads={threads}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            property="details_quantification_strategy"
            title={<Text>GHG Quantification Risk:</Text>}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            validateTrigger="onBlur"
            name="details_quantification_strategy"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={150} showCount />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <QuestionWithThreads
            optional={withoutThreshold}
            threads={threads}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            property="details_technology_or_project_strategy"
            title={<Text>Technology / Project Performance Risk:</Text>}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            validateTrigger="onBlur"
            name="details_technology_or_project_strategy"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={150} showCount />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <QuestionWithThreads
            optional={withoutThreshold}
            threads={threads}
            onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
            property="details_performance_strategy"
            title={<Text> Permanence Risk:</Text>}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            validateTrigger="onBlur"
            name="details_performance_strategy"
            rules={[
              { required: !withoutThreshold, message: "Field is required" },
            ]}
            trigger="onBlur"
            valuePropName="defaultValue"
          >
            <TextArea size="large" roundCorners wordLimit={150} showCount />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="details_other_strategies">
        {(risks, { add }) => (
          <>
            {risks.map(({ name }) => (
              <Row>
                <Col span={10}>
                  <QuestionWithThreads
                    optional={withoutThreshold}
                    threads={threads}
                    onOpenDiscussionsDrawer={onOpenDiscussionsDrawer}
                    property={`details_other_strategies-${name}-strategy`}
                    title={
                      <Space>
                        <Form.Item
                          validateTrigger="onBlur"
                          name={[name, "risk"]}
                          trigger="onBlur"
                          valuePropName="defaultValue"
                        >
                          <Input size="large" roundCorners="large" />
                        </Form.Item>
                      </Space>
                    }
                  />
                </Col>
                <Col offset={2} span={12}>
                  <Form.Item
                    validateTrigger="onBlur"
                    name={[name, "strategy"]}
                    trigger="onBlur"
                    valuePropName="defaultValue"
                  >
                    <TextArea
                      size="large"
                      roundCorners
                      wordLimit={150}
                      showCount
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add risk
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default RisksTable;
