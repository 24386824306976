import React from "react";
import { List, Typography } from "antd";

import Marker from "./Marker";
// import Collapse from "../Collapse/Collapse";
// import NumberedList from "../NumberedList/NumberedList";

import { decimalToPercents } from "../../helpers/percents";

import styles from "./Legend.module.less";

const { Text } = Typography;

// const titleByKey = (key) => {
//   switch (key) {
//     case "substantial_contribution":
//       return "Growth points for substantial contribution";
//     case "dnsh":
//       return "Growth points for DNSH";
//     default:
//   }
// };

const ListItem = ({ label, color }) => (
  // const panels =
  //   !!problems &&
  //   Object.values(problems).map((unit) => unit.map((item) => item.point.text));
  <div>
    <Marker label={label} color={color} size="large" />
    {/* {panels && (
        <Collapse
          className={styles.collapse}
          headerWeight="regular"
          headers={Object.keys(problems).map((entry) => titleByKey(entry))}
          accordion
          panels={panels.map((panel) => (
            <div className={styles.collapseInner}>
              <NumberedList list={panel} />
            </div>
          ))}
        />
      )} */}
  </div>
);
const LegendList = ({ legend }) => (
  <List
    itemLayout="horizontal"
    dataSource={legend}
    size="large"
    renderItem={(item) => (
      <List.Item
        style={{ alignItems: "flex-start" }}
        extra={
          <Text
            className={styles.secondary}
            style={{ position: "relative", top: 5 }}
            type="secondary"
          >
            {decimalToPercents(item.proportion)}
          </Text>
        }
      >
        <ListItem
          label={item.name}
          problems={item.problems}
          color={item.color}
        />
      </List.Item>
    )}
  />
);

export default LegendList;
