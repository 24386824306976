// eslint-disable-next-line import/prefer-default-export
export const FILE_TYPES = {
  EXTERNAL: Symbol("external"),
  FILE: Symbol("file"),
  RECENT: Symbol("recent"),
};

// TODO: переделать через классы ошибок
export const FILE_ERRORS = {
  NETWORK: Symbol("network"),
  CLIENT: Symbol("client"),
};

export const FILE_ERRORS_MESSAGES = {
  [FILE_ERRORS.NETWORK]: "Network error",
  [FILE_ERRORS.CLIENT]: "Validation error",
};
