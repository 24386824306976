import React from "react";
import ActivityCard from "../ActivityCard/ActivityCard";

const ActivityCardConnected = ({
  activity,
  selectedActivities,
  addedActivities,
  selectedCodes,
  addedCodes,
  onActivityCardClick,
  onActivityCardBtnClick,
}) => {
  const isSelected = selectedActivities.some((actId) => actId === activity.id);
  const isAdded = addedActivities.some((actId) => actId === activity.id);

  const activeCodesIds = [
    ...new Set([...(selectedCodes || []), ...(addedCodes || [])]),
  ];
  const activeCodes = activity.codes.filter((code) =>
    activeCodesIds.includes(code.id)
  );

  return (
    <ActivityCard
      activityName={activity.name}
      description={activity.description}
      activityType={activity.type}
      activityTags={activity.tags}
      isSelected={isSelected}
      isAdded={isAdded}
      inBlackList={!activity.available}
      codes={activeCodes.length ? activeCodes : activity.codes}
      onCardClick={() =>
        activity.available && !isAdded && onActivityCardClick(activity.id)
      }
      onBtnClick={() =>
        activity.available && !isAdded && onActivityCardBtnClick(activity.id)
      }
      displayBtn
    />
  );
};
export default ActivityCardConnected;
