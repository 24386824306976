import React from "react";
import {
  Button,
  Dropdown,
  PlusCircleOutlined,
  Tabs,
  Space,
  Link,
} from "@evercityecosystem/evercity-ui";
import ProjectsTableConnected from "./Project/ProjectsTableConnected";
import LkLayout from "../LkLayout/LkLayout";

const PortfolioPage = () => (
  <LkLayout>
    <Space block justifyContent="space-between">
      <Tabs>
        <Tabs.TabPane key="projects" tab="Projects" />
        <Tabs.TabPane disabled key="debt" tab="Debt" />
        <Tabs.TabPane disabled key="carbon" tab="Carbon" />
      </Tabs>
      <Dropdown
        trigger="click"
        menu={{
          items: [
            {
              label: <Link to="projects/_/info">Project</Link>,
              key: "project",
            },
          ],
        }}
      >
        <Button icon={<PlusCircleOutlined />} type="primary">
          Add new
        </Button>
      </Dropdown>
    </Space>
    <ProjectsTableConnected />
  </LkLayout>
);

export default PortfolioPage;
