import React, { useEffect, useMemo, useState } from "react";
import { Button, Drawer, Row, Space } from "@evercityecosystem/evercity-ui";
import isEqual from "lodash.isequal";
import SelectNaceCodeDrawerContent from "./SelectNaceCodeDrawerContent";

const SelectNaceCodeDrawer = ({
  visible,
  onClose,
  onClosed,
  onSubmit,
  activity,
  isSelected,
  isAdded,
  selectedCodes,
  addedCodes,
  loading,
  okButtonText,
}) => {
  const initialCheckedCodes = useMemo(
    () => [...new Set([...addedCodes, ...selectedCodes])],
    [selectedCodes, addedCodes]
  );
  const [sessionCheckedCodes, setSessionCodes] = useState(() => []);

  useEffect(() => {
    setSessionCodes(initialCheckedCodes);
  }, [initialCheckedCodes]);

  const sessionCheckedCodesChanged = useMemo(
    () => !isEqual(initialCheckedCodes, sessionCheckedCodes),
    [initialCheckedCodes, sessionCheckedCodes]
  );

  const handleCheck = (values) => {
    const checkedCodes = values.length === 0 ? [] : [values[values.length - 1]];
    setSessionCodes(checkedCodes);
  };

  const disabledSubmitButton =
    (sessionCheckedCodes.length === 0 || !sessionCheckedCodesChanged) &&
    activity?.codes?.length > 0;

  return (
    <Drawer
      data-cy="drawer-nace-select"
      visible={visible}
      title={null}
      width={580}
      mask={false}
      placement="right"
      footer={
        <Row justify="end">
          <Space size={10}>
            <Button onClick={onClose}>Back</Button>
            <Button
              data-cy="btn-attach-codes"
              type="primary"
              disabled={disabledSubmitButton}
              onClick={() => onSubmit(sessionCheckedCodes)}
              loading={loading}
            >
              {okButtonText}
            </Button>
          </Space>
        </Row>
      }
      destroyOnClose
      closeIcon={null}
      onClose={onClose}
      afterVisibleChange={(vis) => !vis && onClosed?.()}
    >
      {!!activity && (
        <SelectNaceCodeDrawerContent
          activity={activity}
          isSelected={isSelected}
          isAdded={isAdded}
          initialCheckedCodes={initialCheckedCodes}
          sessionCheckedCodes={sessionCheckedCodes}
          onSelectedCodesChange={handleCheck}
        />
      )}
    </Drawer>
  );
};

SelectNaceCodeDrawer.defaultProps = {
  selectedCodes: [],
  addedCodes: [],
};

export default SelectNaceCodeDrawer;
