import React, { useMemo } from "react";
import { Collapse } from "antd";
import uniqBy from "lodash.uniqby";
import styles from "./GroupedActivitiesList.module.less";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";

import ActivitiesList from "./ActivitiesList";

const { Panel } = Collapse;

const GroupedActivitiesList = ({
  activities,
  selectedActivities,
  addedActivities,
  selectedCodes,
  addedCodes,
  onActivityCardClick,
  onActivityCardBtnClick,
}) => {
  const activitiesGroups = useMemo(() => {
    const categories = uniqBy(
      activities.map((act) => act.category),
      "id"
    );
    return categories.map((category) => [
      category,
      activities.filter((act) => act.category.id === category.id),
    ]);
  }, [activities]);

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      expandIcon={() => <DownArrow className={styles.panelTitleIcon} />}
    >
      {activitiesGroups.map(([category, activitiesInCategory]) => (
        <Panel
          header={category.name}
          key={category.id}
          className={styles.panelContainer}
        >
          <ActivitiesList
            activities={activitiesInCategory}
            selectedActivities={selectedActivities}
            addedActivities={addedActivities}
            selectedCodes={selectedCodes}
            addedCodes={addedCodes}
            onActivityCardClick={onActivityCardClick}
            onActivityCardBtnClick={onActivityCardBtnClick}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

export default GroupedActivitiesList;
