import React from "react";
import { useOutletContext } from "react-router-dom";
import Intoduction from "./Introduction";

const IntroductionConnected = () => {
  const props = useOutletContext();
  return <Intoduction {...props} />;
};

export default IntroductionConnected;
