import React from "react";
import {
  Title,
  Form,
  Space,
  Paragraph,
  FilesTagsListInput,
  AttachFileInput,
  AttachFileProvider,
  Table,
  FileOutlined,
  Text,
  Tag,
  Select,
  DeleteOutlined,
  Button,
  ContentLayout,
} from "@evercityecosystem/evercity-ui";
import { useRouteLoaderData } from "react-router-dom";

import styles from "./ProjectDocumentsPage.module.less";

const CarbonStatus = ({ status }) => {
  const color = status === "verified" ? "green" : "blue";
  return (
    <Tag color={color} type="filled" key={status}>
      {status}
    </Tag>
  );
};

const mimeTypes = [
  {
    mime: "application/msword",
    label: "DOC",
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    label: "DOCX",
  },
  {
    mime: "application/pdf",
    label: "PDF",
  },
  {
    mime: "application/vnd.ms-excel",
    label: "XLS",
  },
];

const ProjectDocumentsPage = () => {
  const project = useRouteLoaderData("project");

  const data = [
    {
      key: `${Date.now()}`,
      name: "pdf_file.pdf",
      size: "132Kb",
      date: "04.11.2022",
      status: "issued",
      visibility: "Public",
      doc_id: "9d3e082e-1e6d-445c-bf42-c95cf82cd796",
      url: "https://test-evercity.s3.us-east-1.amazonaws.com/55c9c8a1-e345-484a-89d8-9b0cd4c64085-TransactionDetails.pdf",
    },
  ];

  const uploadFile = async () =>
    new Promise((resolve) => {
      resolve({
        data: {
          upload_file_base64: {
            doc_id: "9d3e082e-1e6d-445c-bf42-c95cf82cd796",
            url: "https://test-evercity.s3.us-east-1.amazonaws.com/55c9c8a1-e345-484a-89d8-9b0cd4c64085-TransactionDetails.pdf",
          },
        },
      });
    });

  const columns = [
    {
      title: "File name",
      dataIndex: "name",
      render: (name) => (
        <Space size={6}>
          <FileOutlined className={styles.fileIcon} />
          <Text weight="bold">{name}</Text>
        </Space>
      ),
    },
    {
      title: "Upload date",
      dataIndex: "date",
      width: 140,
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 100,
    },
    {
      title: "Verification",
      dataIndex: "status",
      width: 180,
      render: (value) => <CarbonStatus status={value} />,
    },
    {
      title: "Visibility",
      dataIndex: "visibility",
      width: 300,
      render: (visibility) => (
        <Select
          defaultValue={visibility}
          options={[
            {
              value: "My company",
              label: "My company",
            },
            {
              value: "Public",
              label: "Public",
            },
          ]}
        />
      ),
    },
    {
      title: "Actions",
      width: 100,
      dataIndex: "key",
      render: () => (
        <Space block justifyContent="end">
          <Button
            className={styles.action__btn}
            icon={<DeleteOutlined />}
            onClick={() => {}}
          />
        </Space>
      ),
    },
  ];

  const [form] = Form.useForm();

  return (
    <ContentLayout gaps="off">
      <Form
        initialValues={project}
        layout="vertical"
        name="documents"
        form={form}
        className={styles.page}
      >
        <Space size={40} direction="vertical" block>
          <Space direction="vertical" block>
            <Title level={2} size="large" block>
              Upload and verify your documents
            </Title>
            <Paragraph size="extra-large" type="secondary">
              Here you can share and verify important documents related to your
              project and company.
            </Paragraph>
          </Space>
          <Space size={24} direction="vertical" block>
            <Title level={3}>Project documents</Title>

            <div className={styles.attach}>
              <AttachFileProvider
                context={{
                  uploadFile,
                }}
              >
                <Form.Item name="project">
                  <AttachFileInput size="large" accept={mimeTypes} />
                </Form.Item>
              </AttachFileProvider>

              <Form.Item name="project" noStyle>
                <FilesTagsListInput />
              </Form.Item>
            </div>
          </Space>

          <Space size={24} direction="vertical" block>
            <Title level={3}>Company documents</Title>

            <Table
              columns={columns}
              rowClassName={styles.row}
              dataSource={data}
            />

            <div className={styles.attach}>
              <AttachFileProvider
                context={{
                  uploadFile,
                }}
              >
                <Form.Item name="company">
                  <AttachFileInput size="large" value={data} />
                </Form.Item>
              </AttachFileProvider>

              <Form.Item name="company" noStyle>
                <FilesTagsListInput accept={mimeTypes} />
              </Form.Item>
            </div>
          </Space>
        </Space>
      </Form>
    </ContentLayout>
  );
};

export default ProjectDocumentsPage;
