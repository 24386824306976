import React from "react";
import ProductVerticalSpace from "../../Product/ProductVerticalSpace";
import ProductContentSection from "../../Product/ProductContentSection";
import ProductMap from "../../Product/ProductMap";
import LockOverlay from "../../LockOverlay/LockOverlay";

const ImpactTabContent = ({ commodity }) => (
  <LockOverlay isLock={false} redirect="request_access">
    <ProductVerticalSpace>
      <ProductContentSection
        title="Risk assessment"
        body={
          <ProductMap
            size="large"
            zoom={commodity.project.location.zoom}
            lng={commodity.project.location.longitude}
            lat={commodity.project.location.latitude}
          />
        }
      />

      <ProductContentSection
        title="Social benefits"
        body={commodity.social_benefits}
      />
      <ProductContentSection
        title="Environmental benefits"
        body={commodity.enviromental_benefits}
      />
    </ProductVerticalSpace>
  </LockOverlay>
);

export default ImpactTabContent;
