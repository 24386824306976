import React from "react";
import { useOutletContext } from "react-router-dom";
import ExternalReview from "./ExternalReview";

const ExternalReviewConnected = () => {
  const props = useOutletContext();
  return <ExternalReview {...props} />;
};

export default ExternalReviewConnected;
