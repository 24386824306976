import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import SinglePieChart from "../../Charts/SinglePieChart";
import { getUnitsByReportWithSumQuery } from "../../../api/queries";

import { activityTypesColors } from "./data/colorsList";

const getKey = (unit) => {
  let activityType = unit.activity_type;
  if (!activityType && unit.objective === "mitigation") {
    activityType = unit.activities_report?.activity?.mitigation_type;
  }
  if (!activityType && unit.objective === "adaptation") {
    activityType = unit.activities_report?.activity?.adaptation_type;
  }
  return JSON.stringify([unit.objective, activityType]);
};

const ActivityTypes = ({ paramName }) => {
  const { reportId } = useParams();

  const [{ data, fetching }] = useQuery({
    query: getUnitsByReportWithSumQuery,
    variables: { reportId },
  });

  const units = data?.eu_taxonomy_units;
  const totalValue =
    data?.eu_taxonomy_units_aggregate?.aggregate?.sum?.[paramName];

  const unitGroupsByActivityTypesAndObjectives = useMemo(
    () =>
      units?.reduce((acc, unit) => {
        const key = getKey(unit);

        acc[key] = (acc[key] || 0) + unit[paramName];
        return acc;
      }, {}) || {},
    [units, paramName]
  );

  const pieData = useMemo(
    () =>
      Object.entries(unitGroupsByActivityTypesAndObjectives).map(
        ([key, sumByParameter]) => {
          const percent = `${(sumByParameter / totalValue) * 100}%`;
          const parsedKey = JSON.parse(key);
          const objective = parsedKey[0];
          const activityType = parsedKey[1];
          return {
            name: `${activityType} (${objective})`,
            value: percent,
          };
        }
      ),
    [totalValue, unitGroupsByActivityTypesAndObjectives]
  );

  const colorsList = useMemo(
    () =>
      Object.keys(unitGroupsByActivityTypesAndObjectives).map((key) => {
        const parsedKey = JSON.parse(key);
        const objective = parsedKey[0];
        const activityType = parsedKey[1];
        return activityTypesColors[objective][activityType];
      }),
    [unitGroupsByActivityTypesAndObjectives]
  );

  if (fetching) return <LoadingSpinner fill />;
  return (
    <SinglePieChart
      colorsList={colorsList}
      data={pieData}
      legend
      title="Activity types"
    />
  );
};
export default ActivityTypes;
