import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import cs from "classnames";
import styles from "./InfoButton.module.less";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const InfoButton = ({ onClick, className, ...rest }) => (
  <QuestionCircleOutlined
    className={cs(styles.btn, className)}
    onClick={onClick}
    onKeyDown={onKeyDownDecorator(onClick)}
    tabIndex="-1"
    role="button"
    {...rest}
  />
);

export default InfoButton;
