import React, { useContext } from "react";
import { Question } from "@evercityecosystem/evercity-ui";
import { useOutletContext } from "react-router-dom";

export const GroupContext = React.createContext();
const QuestionWithThreads = ({ property, threads, topic, ...props }) => {
  const group = useContext(GroupContext);
  const { onOpenDiscussionsDrawer, discussionDrawerState } = useOutletContext();
  const { title, number } = props;

  // Если есть топик, прокидываем его,
  // если нет собираем из number и title,
  // но только если они тоже строки (title может быть ReactNode поэтому проверка)
  const getTopic = () => {
    if (typeof topic === "string") return topic;

    if (typeof number === "string" && typeof title === "string") {
      return `${number} ${title}`;
    }

    return null;
  };

  return (
    <Question
      {...props}
      data-topic={getTopic()}
      discussions={{
        open: {
          count: threads?.open?.filter(
            (thread) => thread.config.property === property
          ).length,
          active:
            discussionDrawerState?.property === property &&
            discussionDrawerState?.status === "open",
        },
        submitted: {
          count: threads?.submitted?.filter(
            (thread) => thread.config.property === property
          ).length,
          active:
            discussionDrawerState?.property === property &&
            discussionDrawerState?.status === "submitted",
        },
        onClickThread: (event, status) => {
          onOpenDiscussionsDrawer({
            status: status === "default" ? "open" : status,
            group,
            property,
            topic: getTopic(),
            trigger: {
              event,
              source: "flow",
            },
          });
        },
      }}
    />
  );
};

export default QuestionWithThreads;
