import React from "react";
import { Text } from "@antd-ovveride";
import styles from "./ActivityNameCell.module.less";

const ActivityNameCell = ({ value }) => (
  <Text size="small" strong className={styles.text}>
    {value}
  </Text>
);

export default ActivityNameCell;
