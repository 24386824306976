import React from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import {
  Table,
  Text,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  Link,
  SendOutlined,
} from "@evercityecosystem/evercity-ui";
import styles from "./FrameworksTable.module.less";

const FrameworkFilesTable = ({ frameworks, onDelete, onGenerate }) => (
  <Table dataSource={frameworks} pagination={false}>
    <Table.Column
      title="File name"
      dataIndex="name"
      key="name"
      render={(name, record) => (
        <Text color="primary">
          <FileOutlined /> {name ? `${name}.doc` : `${record.standard}.doc`}
        </Text>
      )}
    />
    <Table.Column
      title="Summary"
      dataIndex="id"
      key="framework_summary"
      render={(id, record) => (
        <Link
          className={classnames(
            styles.summaryButton,
            record.status !== "done" && styles["summaryButton--disabled"]
          )}
          to={`/company/frameworks/summary/${id}`}
        >
          view
        </Link>
      )}
    />
    <Table.Column title="Auditor status" key="status" />
    <Table.Column
      title="Created date"
      dataIndex="created_at"
      key="created_at"
      render={(date) => (
        <Text color="caption">{dayjs(date).format("DD.MM.YYYY")}</Text>
      )}
    />
    <Table.Column title="Size" key="size" />
    <Table.Column
      title="Actions"
      key="actions"
      dataIndex="id"
      render={(id) => (
        <Table.Actions
          actions={[
            {
              label: "Send to review",
              key: "review",
              icon: <SendOutlined />,
            },
            {
              label: "Download",
              key: "download",
              icon: <DownloadOutlined />,
              onClick: () => onGenerate(id),
            },
            {
              label: <Link to={`/company/frameworks/${id}`}>Edit</Link>,
              icon: <EditOutlined />,
              key: "edit",
            },
            {
              label: "Delete",
              icon: <DeleteOutlined />,
              key: "delete",
              danger: true,
              onClick: () => onDelete(id),
            },
          ]}
        />
      )}
    />
  </Table>
);

export default FrameworkFilesTable;
