import React from "react";
import { format, differenceInDays } from "date-fns";
import FinStatsBase from "./FinStatsBase";
import dateDifferenceFromNow from "../../helpers/dateDifferenceFromNow";
import { toMln } from "../../helpers/converters";

const getLastColByStatus = (bond) => {
  switch (bond.state) {
    case "roadshow":
      return {
        key: "Book opens",
        value: format(new Date(bond.booking_date), "yyyy/MM/dd"),
      };
    case "subscription":
      return {
        key: "Days left",
        value: differenceInDays(new Date(bond.maturity_date), new Date()),
      };
    default:
      return {
        key: "Maturity",
        value: dateDifferenceFromNow(bond.maturity_date),
      };
  }
};

const BondFinStats = ({ bond }) => {
  const lastCol = getLastColByStatus(bond);
  return (
    <FinStatsBase
      stats={[
        {
          key: "Volume",
          value: `${toMln(bond.volume)}M ${bond.currency}`,
        },
        {
          key: "Interest",
          value: `${bond.interest_rate_base_value}%`,
        },
        lastCol,
      ]}
    />
  );
};

export default BondFinStats;
