import React, { useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery, useMutation } from "urql";
import { notification } from "antd";
import ResultPage from "./ResultPage";
import { getReportQuery } from "../../api/queries";
import {
  generateChartsReportPdf,
  generateReportPdf,
  generateReportXls,
  updateReportMutation,
} from "../../api/mutations";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { REPORT_STATUSES } from "../../helpers/reportStatuses";
import sampleCompany from "../../helpers/sampleCompany";

const ResultsPageConnected = () => {
  const { onFinish, onPrevStep } = useOutletContext();
  const { reportId } = useParams();

  const [reportResponse] = useQuery({
    query: getReportQuery,
    variables: { id: reportId },
    requestPolicy: "network-only",
  });

  const originReport = reportResponse.data?.eu_taxonomy_reports_by_pk;

  const isSampleReport = originReport?.status === REPORT_STATUSES.sample;

  const report = useMemo(() => {
    if (originReport && isSampleReport) {
      return {
        ...originReport,
        company: {
          id: originReport.company.id,
          ...sampleCompany,
        },
      };
    }
    return originReport;
  }, [isSampleReport, originReport]);

  const [updateReportResponse, updateReport] =
    useMutation(updateReportMutation);

  const [, generatePdf] = useMutation(generateReportPdf);
  const [, generateXls] = useMutation(generateReportXls);
  const [, generateCharts] = useMutation(generateChartsReportPdf);

  const onComplete = () => {
    if (!isSampleReport) {
      updateReport({ reportId, changes: { status: "completed" } });
    }
    onFinish();
  };

  if (reportResponse.fetching) {
    return <LoadingSpinner fill size="large" />;
  }

  const generatePdfHandler = async () => {
    try {
      const res = await generatePdf({ reportId });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.generate_report_pdf.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate PDF",
        description: "Please try again later.",
      });
    }
  };

  const generateExcelHandler = async (locale) => {
    try {
      const res = await generateXls({ reportId, locale });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.generate_report_xls.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate XLS",
        description: "Please try again later.",
      });
    }
  };

  const generateChartsHandler = async () => {
    try {
      const res = await generateCharts({ reportId });
      if (res.error) {
        throw new Error();
      }
      window.open(res.data.genReportPDFCharts.url, "_blank");
    } catch (err) {
      notification.error({
        message: "Error generate PDF Charts",
        description: "Please try again later.",
      });
    }
  };

  return (
    <ResultPage
      report={report}
      sedning={updateReportResponse.fetching}
      onComplete={onComplete}
      generatePdf={generatePdfHandler}
      generateXls={generateExcelHandler}
      generateCharts={generateChartsHandler}
      onBackClick={onPrevStep}
    />
  );
};

export default ResultsPageConnected;
