import React from "react";

import { ReactComponent as Sdg1 } from "../assets/images/sdg/sdg1.svg";
import { ReactComponent as Sdg2 } from "../assets/images/sdg/sdg2.svg";
import { ReactComponent as Sdg3 } from "../assets/images/sdg/sdg3.svg";
import { ReactComponent as Sdg4 } from "../assets/images/sdg/sdg4.svg";
import { ReactComponent as Sdg5 } from "../assets/images/sdg/sdg5.svg";
import { ReactComponent as Sdg6 } from "../assets/images/sdg/sdg6.svg";
import { ReactComponent as Sdg7 } from "../assets/images/sdg/sdg7.svg";
import { ReactComponent as Sdg8 } from "../assets/images/sdg/sdg8.svg";
import { ReactComponent as Sdg9 } from "../assets/images/sdg/sdg9.svg";
import { ReactComponent as Sdg10 } from "../assets/images/sdg/sdg10.svg";
import { ReactComponent as Sdg11 } from "../assets/images/sdg/sdg11.svg";
import { ReactComponent as Sdg12 } from "../assets/images/sdg/sdg12.svg";
import { ReactComponent as Sdg13 } from "../assets/images/sdg/sdg13.svg";
import { ReactComponent as Sdg14 } from "../assets/images/sdg/sdg14.svg";
import { ReactComponent as Sdg15 } from "../assets/images/sdg/sdg15.svg";
import { ReactComponent as Sdg16 } from "../assets/images/sdg/sdg16.svg";
import { ReactComponent as Sdg17 } from "../assets/images/sdg/sdg17.svg";

const SDGs = [
  {
    id: 1,
    color: "#EC1B2E",
    number: 1,
    icon: <Sdg1 />,
    iconActive: "/images/sdg/sdg1-active.png",
    title: "No poverty",
  },
  {
    id: 2,
    color: "#D2A028",
    number: 2,
    icon: <Sdg2 />,
    iconActive: "/images/sdg/sdg2-active.png",
    title: "Zero hunger",
  },
  {
    id: 3,
    color: "#269B47",
    number: 3,
    icon: <Sdg3 />,
    iconActive: "/images/sdg/sdg3-active.png",
    title: "Good health and well-being",
  },
  {
    id: 4,
    color: "#C32033",
    number: 4,
    icon: <Sdg4 />,
    iconActive: "/images/sdg/sdg4-active.png",
    title: "Quality education",
  },
  {
    id: 5,
    color: "#EF3F2C",
    number: 5,
    icon: <Sdg5 />,
    iconActive: "/images/sdg/sdg5-active.png",
    title: "Gender equality",
  },
  {
    id: 6,
    color: "#07AED9",
    number: 6,
    icon: <Sdg6 />,
    iconActive: "/images/sdg/sdg6-active.png",
    title: "Clean water and sanitaion",
  },
  {
    id: 7,
    color: "#FEB814",
    number: 7,
    icon: <Sdg7 />,
    iconActive: "/images/sdg/sdg7-active.png",
    title: "Affordable and clean energy",
  },
  {
    id: 8,
    color: "#8E1738",
    number: 8,
    icon: <Sdg8 />,
    iconActive: "/images/sdg/sdg8-active.png",
    title: "Decent work and economic growth",
  },
  {
    id: 9,
    color: "#F36D26",
    number: 9,
    icon: <Sdg9 />,
    iconActive: "/images/sdg/sdg9-active.png",
    title: "Industry, innovation and infrastructure",
  },
  {
    id: 10,
    color: "#E11584",
    number: 10,
    icon: <Sdg10 />,
    iconActive: "/images/sdg/sdg10-active.png",
    title: "Reduced inequalities",
  },
  {
    id: 11,
    color: "#F99D25",
    number: 11,
    icon: <Sdg11 />,
    iconActive: "/images/sdg/sdg11-active.png",
    title: "Sustainable cities and communities",
  },
  {
    id: 12,
    color: "#CE8D2A",
    number: 12,
    icon: <Sdg12 />,
    iconActive: "/images/sdg/sdg12-active.png",
    title: "Responsible consumption and production",
  },
  {
    id: 13,
    color: "#48773E",
    number: 13,
    icon: <Sdg13 />,
    iconActive: "/images/sdg/sdg13-active.png",
    title: "Climate action",
  },
  {
    id: 14,
    color: "#007DBC",
    number: 14,
    icon: <Sdg14 />,
    iconActive: "/images/sdg/sdg14-active.png",
    title: "Life below water",
  },
  {
    id: 15,
    color: "#3EB149",
    number: 15,
    icon: <Sdg15 />,
    iconActive: "/images/sdg/sdg15-active.png",
    title: "Life and land",
  },
  {
    id: 16,
    color: "#03558B",
    number: 16,
    icon: <Sdg16 />,
    iconActive: "/images/sdg/sdg16-active.png",
    title: "Peace, justice and strong institutions",
  },
  {
    id: 17,
    color: "#193569",
    number: 17,
    icon: <Sdg17 />,
    iconActive: "/images/sdg/sdg17-active.png",
    title: "Partnerships for the goals",
  },
];

export default SDGs;
