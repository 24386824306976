import React, { useMemo } from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import CardHeader from "./CardHeader";
import CardLayout from "./CardLayout";
import styles from "./ProjectCard.module.less";
import ContentWrap from "./ContentWrap";
import FixedContentHeader from "./FixedContentHeader";
import ListingTags from "../ListingTags/ListingTags";
import concatProjectTypes from "../../helpers/concatProjectTypes";

const ProjectCard = ({ data, type, ...layoutProps }) => {
  const assetTypes = useMemo(() => concatProjectTypes(data), [data]);
  return (
    <Link to={`/listing/project/${data.slug}`}>
      <CardLayout {...layoutProps}>
        <div className={styles.projectCard}>
          <Space direction="vertical" size={15}>
            <CardHeader
              image={data.image}
              sdgList={data.projects_goals}
              logo={data.company.logo}
            />
            <ContentWrap>
              <FixedContentHeader
                country={data.country}
                description={data.description}
                title={data.name}
                type={type}
              />
            </ContentWrap>
          </Space>
          <ContentWrap>
            <ListingTags
              assetTypes={assetTypes}
              categories={data.project_categories.map((pc) => ({
                id: pc.category.id,
                text: pc.category.title,
              }))}
              status={data?.state?.replace("_", " ")}
            />
          </ContentWrap>
        </div>
      </CardLayout>
    </Link>
  );
};

export default ProjectCard;
