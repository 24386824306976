import { combine, createEffect, createStore, guard } from "effector";
import Session from "supertokens-web-js/recipe/session";
import { userQuery } from "../../api/queries";
import graphqlClient from "../../graphqlClient";

export const initSessionFx = createEffect(
  () =>
    new Promise((resolve, reject) => {
      const isDevelopment = process.env.NODE_ENV === "development";
      if (isDevelopment && process.env.REACT_APP_USER_ID) {
        resolve({
          userId: process.env.REACT_APP_USER_ID,
        });
      }
      if (isDevelopment && !process.env.REACT_APP_USER_ID) {
        reject(new Error("REACT_APP_USER_ID is empty"));
      }
      Session.doesSessionExist().then((isAuth) => {
        if (isAuth) {
          Session.getUserId().then((userId) => {
            resolve({
              userId,
            });
          });
        }
        if (!isAuth) {
          resolve(null);
        }
      });
    })
);

const getUserFx = createEffect(({ userId }) =>
  graphqlClient
    .query(userQuery, {
      id: userId,
    })
    .toPromise()
    .then((res) => res.data.users_by_pk)
);

guard({
  source: initSessionFx.doneData,
  filter: (session) => !!session?.userId,
  target: getUserFx,
});

export const $user = createStore(null).on(
  getUserFx.doneData,
  (state, payload) => ({
    ...state,
    ...payload,
  })
);

export const $currentUser = combine({
  user: $user,
  loadingUser: getUserFx.pending,
  loadingSession: initSessionFx.pending,
});
