import React from "react";
import { Space, Divider } from "antd";
import { Link } from "react-router-dom";
import uniqBy from "lodash.uniqby";

import CardHeader from "./CardHeader";
import CardLayout from "./CardLayout";
import FixedContentHeader from "./FixedContentHeader";
import ListingTags from "../ListingTags/ListingTags";
import styles from "./BondCard.module.less";
import BondFinStats from "./BondFinStats";
import ListingProgress from "../ListingProgress/ListingProgress";
import ContentWrap from "./ContentWrap";
import { toMln } from "../../helpers/converters";

const BondCard = ({ data, ...layoutProps }) => {
  const categories = data.bond_projects.map(({ project }) =>
    project.project_categories.map(({ category }) => ({
      id: category.id,
      text: category.title,
    }))
  );

  return (
    <Link to={`/listing/debt/${data.slug}`}>
      <CardLayout {...layoutProps}>
        <div className={styles.bondCard}>
          <Space direction="vertical" size={15}>
            <CardHeader
              image={data.image}
              sdgList={data.bond_goals}
              logo={data.company.logo}
            />
            <ContentWrap>
              <Space direction="vertical" size={12}>
                <FixedContentHeader
                  title={data.company.name}
                  country={data.company.country}
                  description={data.description}
                />
                <ListingTags
                  assetTypes={[{ id: 0, text: data.type }]}
                  status={data.state}
                  categories={uniqBy(categories.flat(), "id")}
                />
              </Space>
            </ContentWrap>
          </Space>
          <ContentWrap>
            <Space
              direction="vertical"
              size={11}
              style={{ display: "flex", marginTop: 30 }}
            >
              {data.state.toLowerCase() === "subscription" ? (
                <ListingProgress
                  total={data.volume}
                  part={data.bought}
                  message={`${data.bought || 0}M ${
                    data.currency
                  } booked of ${toMln(data.volume)}M ${data.currency}`}
                />
              ) : (
                // переделать
                <Divider style={{ marginTop: 0, marginBottom: 9 }} />
              )}

              <BondFinStats bond={data} />
            </Space>
          </ContentWrap>
        </div>
      </CardLayout>
    </Link>
  );
};

export default BondCard;
