import { createClient as createWSClient } from "graphql-ws";
import {
  createClient,
  dedupExchange,
  fetchExchange,
  errorExchange,
  subscriptionExchange,
} from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { retryExchange } from "@urql/exchange-retry";
import { authExchange } from "@urql/exchange-auth";
import { notification } from "antd";
import { makeOperation } from "@urql/core";
import { getData } from "../../helpers/jwt";

import clientCacheExchange from "./cacheExchange";

const retrieveHeaders = async () => {
  const authState = await getData();

  if (!authState?.token) {
    return {};
  }

  return {
    headers: {
      Authorization: `Bearer ${authState?.token}`,
      "x-hasura-role": authState?.userRole,
      "x-hasura-user-id": authState?.userId,
    },
  };
};

const SHOW_ERROR_CODES = ["UNAUTHENTICATED", "FORBIDDEN", "GONE", "CONFLICT"];

// eslint-disable-next-line import/prefer-default-export
export const createGraphqlClient = () => {
  const wsClient = createWSClient({
    url: process.env.REACT_APP_GRAPHQL_WS_URL,
    connectionParams: () => retrieveHeaders(),
  });

  const exchanges = [
    devtoolsExchange,
    clientCacheExchange,
    dedupExchange,
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 10000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (error) => {
        console.error("retryExchange log: ", error);

        return !!error.networkError;
      },
    }),
    errorExchange({
      onError(errors) {
        errors.graphQLErrors.forEach((error) => {
          if (SHOW_ERROR_CODES.includes(error.extensions.code)) {
            notification.error({
              message: error.message,
            });
          }
        });
        console.error(errors);
      },
    }),
    authExchange({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === "function"
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
              "x-hasura-role": authState.userRole,
              "x-hasura-user-id": authState.userId,
            },
          },
        });
      },
      getAuth: () => getData(),
      willAuthError: ({ authState }) => !authState,
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
  ];

  const client = createClient({
    url: process.env.REACT_APP_GRAPHQL_URL,
    fetchOptions: () => ({
      credentials: "same-origin",
    }),
    exchanges,
  });

  return client;
};
