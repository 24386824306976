import React, { useCallback, useState } from "react";
import Modal from "../antd-ovveride/Modal/Modal";

const useModal = ({ defaultVisible }) => {
  const [visible, setVisible] = useState(defaultVisible || false);
  const show = () => setVisible(true);
  const close = () => setVisible(false);

  return {
    show,
    close,
    visible,
    Modal: useCallback(
      ({ children, ...props }) => (
        <Modal visible={visible} onCancel={props.onCancel || close} {...props}>
          {children}
        </Modal>
      ),
      [visible]
    ),
  };
};

export default useModal;
