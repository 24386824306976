import React, { useMemo } from "react";
import cloneDeep from "lodash.clonedeep";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import { Button, Form, Title, Space } from "@evercityecosystem/evercity-ui";
import PointsList from "../PointsList/PointsList";

const usePointsTree = (flatPoints) => {
  const getPointFromTree = (points, pointId) => {
    const point = points.find((p) => p.id === pointId);
    if (point) return point;
    const nestedPoints = points.map((p) => p.nested_points || []).flat();
    if (nestedPoints.length) {
      return getPointFromTree(nestedPoints, pointId);
    }
  };
  const pointsTree = useMemo(() => {
    if (!flatPoints) return;
    const clonePoints = cloneDeep(flatPoints);
    clonePoints.forEach((point) => {
      if (point.parent_id) {
        const parent = clonePoints.find((p) => p.id === point.parent_id);
        if (parent) {
          parent.nested_points ||= [];
          parent.nested_points.push(point);
        }
      }
    });

    return clonePoints.filter((point) => !point.parent_id);
  }, [flatPoints]);

  const pointsTreeWithParsedSubtype = useMemo(() => {
    // возможно не лучшее решение, т.к поинты у нас интерактивные и обращение по этому id может что-то сломать(хотя headline не интерактивный)
    const createHeadlinePoint = (text, siblingPoint) => ({
      activity_id: siblingPoint.activity_id,
      id: `${siblingPoint.id}-custom-headline`,
      parent_id: siblingPoint.parent_id,
      point_subtype: null,
      rule: "headline",
      text,
      nested_points: [],
      unit_point_decisions: [],
    });
    const topLevelSortingPointsByHeadline = (points) => {
      const subtypesNamesOrder = [
        "climate_change",
        "sustainable_use",
        "circular_economy",
        "pollution_prevention",
        "protection",
      ];
      return sortBy(points, (point) =>
        subtypesNamesOrder.findIndex(
          (orderName) => orderName === point.point_subtype?.name
        )
      );
    };
    const insertTopLevelHeadlines = (points) => {
      let pointsWithHeadlines = [];
      const grouppedPoints = groupBy(
        points,
        (p) => p.point_subtype?.description
      );
      Object.entries(grouppedPoints).forEach(([title, pointsGroup]) => {
        if (title !== "undefined") {
          const headlinePoint = createHeadlinePoint(title, pointsGroup[0]);
          pointsWithHeadlines.push(headlinePoint);
        }

        pointsWithHeadlines = [...pointsWithHeadlines, ...pointsGroup];
      });

      return pointsWithHeadlines;
    };

    const sortedPoints = topLevelSortingPointsByHeadline(pointsTree);
    const pointsWithHeadlines = insertTopLevelHeadlines(sortedPoints);
    return pointsWithHeadlines;
  }, [pointsTree]);

  return [pointsTreeWithParsedSubtype, getPointFromTree];
};

const PointsForm = ({
  points,
  submitText,
  sending,
  threads,
  onSubmit,
  cancelText,
  onCancel,
  onPointChange,
  onPointFilesChange,
  grouped = false,
}) => {
  const [form] = Form.useForm();
  const [pointsTree, getPoint] = usePointsTree(points);

  const onChange = (values) => {
    Object.entries(values).forEach(([name, value]) => {
      const isPoint = /point(-file)?-\d+$/.test(name);
      if (isPoint) {
        const pointId = Number(name.match(/point(-file)?-(\d+)$/)[2]);
        const point = getPoint(pointsTree, pointId);
        if (!point) return;
        const isFile = /point-file-\d+$/.test(name);
        if (isFile) {
          onPointFilesChange?.(point, value);
        } else {
          onPointChange?.(point, value);
        }
      }
    });
  };

  if (!points.length) {
    return <Title level={4}>Not Found</Title>;
  }

  return (
    <Form
      form={form}
      onValuesChange={onChange}
      onFinish={onSubmit}
      scrollToFirstError={{ behavior: "smooth", block: "center" }}
    >
      <Space size={50} direction="vertical" block>
        <PointsList
          threads={threads}
          points={pointsTree}
          form={form}
          grouped={grouped}
        />
        <Space size={12}>
          <Button size="large" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={sending}
            size="large"
          >
            {submitText}
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default PointsForm;
