const elements = {
  checkbox: "checked",
  input: "value",
};

const adapterDOMEvent =
  (func, element = "input", ...args) =>
  ({ target }) =>
    func(target[elements[element]], ...args);

export default adapterDOMEvent;
