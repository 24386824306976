import React from "react";
import {
  Form,
  Help,
  Title,
  Text,
  Paragraph,
  Space,
  Button,
  FileProtectOutlined,
  Radio,
  DollarOutlined,
  ContentLayout,
} from "@evercityecosystem/evercity-ui";
import styles from "./ProjectFinancePage.module.less";
import SelectPlan from "./SelectPlan";

const ProjectFinancePage = () => (
  <ContentLayout gaps="off">
    <Form>
      <Title level={2} size="large">
        Finance
      </Title>
      <Paragraph size="large" type="secondary">
        Plan and manage your project’s finances
      </Paragraph>
      <Text className={styles.subtitle} size="title" weight="bold">
        What form of finance you plan to use to fund your project?
      </Text>
      <Form.Item>
        <Radio.Group direction="horizontal">
          <Radio.Card
            value="credit"
            title="Carbon credit"
            icon={<FileProtectOutlined />}
          >
            A carbon credit is a generic term for any tradable certificate or
            permit representing the right to emit a set amount of carbon dioxide
            or the equivalent amount of a different greenhouse gas (tCO2e).
          </Radio.Card>
          <Radio.Card value="bond" title="Green bond" icon={<DollarOutlined />}>
            A green bond is a type of fixed-income instrument that is
            specifically earmarked to raise money for climate and environmental
            projects.
          </Radio.Card>
        </Radio.Group>
      </Form.Item>
      <Text className={styles.subtitle} size="title" weight="bold">
        <Help title="Need help? Talk to an expert and find the best plan for your project.">
          Choose your GDP plan
        </Help>
      </Text>
      <Form.Item name="plan">
        <SelectPlan />
      </Form.Item>
      <Space size={16}>
        <Button>Back</Button>
        <Button type="primary">Next step</Button>
      </Space>
    </Form>
  </ContentLayout>
);

export default ProjectFinancePage;
