import React from "react";
import { Typography, Space } from "antd";
import OverviewHeader from "./components/OverviewHeader";
import { activitiesColorsList } from "./components/data/colorsList";
import ResultsActivitiesTableConnected from "../ResultsActivitiesTable/ResultsActivitiesTableConnected";

const { Title } = Typography;

const OverviewPage = ({ report }) => (
  <Space direction="vertical" size={50} style={{ display: "flex" }}>
    <OverviewHeader report={report} />
    <Space direction="vertical" size={20} style={{ display: "flex" }}>
      <Title level={4}>Taxonomy alignment by activities</Title>
      <ResultsActivitiesTableConnected colorsList={activitiesColorsList} />
    </Space>
  </Space>
);

export default OverviewPage;
