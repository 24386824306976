import React from "react";
import { Select } from "@antd-ovveride";
import classnames from "classnames";
import styles from "./Select.module.less";

const MultiSelect = ({ options, limit, className, ...props }) => (
  <Select
    {...(props.defaultValue?.length >= limit && { open: false })}
    className={classnames(styles.select, styles["select--multiple"], className)}
    mode="multiple"
    showArrow
    options={options}
    {...props}
  >
    {options.map((option) => (
      <Select.Option value={option.value}>{option.label}</Select.Option>
    ))}
  </Select>
);
export default MultiSelect;
