import React from "react";
import { LockFilled } from "@ant-design/icons";
import { Skeleton } from "antd";
import { Link, Text } from "@antd-ovveride";
import styles from "./LockOverlay.module.less";

const LockOverlay = ({ isLock = true, children }) => {
  const sendMail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const link = document.createElement("a");
    link.href = "mailto:elizavetaromanova@gmail.com";
    link.click();
  };

  if (isLock) {
    return (
      <div className={styles.container}>
        <div className={styles.overlay}>
          <span className={styles.icon}>
            <LockFilled />
          </span>
          <Text>Contact us to see more info</Text>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link data-cy="btn-request-access" onClick={sendMail} type="button">
            Request access
          </Link>
        </div>

        <div className={styles.blur}>
          <Skeleton />
        </div>
      </div>
    );
  }

  return children;
};

export default LockOverlay;
