import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { useMount } from "ahooks";
import { reportResultPdfQuery } from "../../api/queries";
import ReportPdf from "./ReportPdf";
import Error404 from "../errors/Error404";

const ReportPdfConnected = () => {
  const [pdfReady, setPdfReady] = useState(false);
  const { reportId, pagination } = useParams();

  useMount(() => {
    setPdfReady(true);
  });

  const [response] = useQuery({
    query: reportResultPdfQuery,
    variables: { id: reportId },
    requestPolicy: "network-only",
  });
  const actReports = response.data?.eu_taxonomy_activities_reports;
  const report = response.data?.eu_taxonomy_reports_by_pk;

  const { fetching, error } = response;

  if (fetching) return null;
  if (
    error ||
    actReports?.length === 0 ||
    !report ||
    !["1", "2", "3"].includes(pagination)
  ) {
    return (
      <div id="PDF_ERROR">
        <Error404 />
      </div>
    );
  }

  return (
    <>
      <ReportPdf
        actReports={actReports}
        report={report}
        pagination={Number(pagination)}
      />
      {pdfReady && <div id="PDF_READY" />}
    </>
  );
};

export default ReportPdfConnected;
