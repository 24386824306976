import { useMemo } from "react";

const useCalcProgress = ({ steps, data }) =>
  useMemo(() => {
    if (!data) {
      return {};
    }
    return Object.entries(steps).reduce((result, [step, schema]) => {
      const progress = { ...result };
      const resultValidation = schema.validate(data, {
        abortEarly: false,
        allowUnknown: true,
      });
      const errors = resultValidation.error?.details?.length || 0;
      let all = 0;
      const description = schema.describe();
      if (description.type === "object") {
        all = Object.keys(schema.describe().keys).length;
      }
      const done = all - errors;
      return {
        ...progress,
        [step]: all === 0 ? 0 : Math.trunc((done / all) * 100),
      };
    }, {});
  }, [data, steps]);

export default useCalcProgress;
