import Joi from "joi";
import { MESSENGER_GROUPS } from "../../data/messenger";

const validationSchema = {
  [MESSENGER_GROUPS.PIN.SUMMARY]: Joi.object({
    summary_goals: Joi.array().min(1).required(),
    summary_scale: Joi.string().required(),
    summary_scope: Joi.string().required(),
    summary_country: Joi.string().required(),
    summary_standard: Joi.string().required(),
    summary_technology: Joi.string().required(),
    summary_methodology: Joi.string().required(),
    summary_existing_methodology: Joi.string().required(),
    summary_project_owner: Joi.string().required(),
    summary_project_title: Joi.string().required(),
    summary_brief_description: Joi.string().required(),
  }),
  [MESSENGER_GROUPS.PIN.DETAILS]: Joi.object({
    details_status: Joi.string().required(),
    details_end_date: Joi.string().required(),
    details_location: Joi.string().required(),
    details_owner_name: Joi.string().required(),
    details_start_date: Joi.string().required(),
    details_assumptions: Joi.string().required(),
    details_coordinates: Joi.string().required(),
    details_description: Joi.string().required(),
    details_owner_description: Joi.string().required(),
    details_owner_email: Joi.string().required(),
    details_owner_phone: Joi.string().required(),
    details_delivery_date: Joi.string().required(),
    details_owner_address: Joi.string().required(),
    details_owner_company: Joi.string().required(),
    details_owner_website: Joi.string().required(),
    details_funding_status: Joi.string().required(),
    details_regulatory_strategy: Joi.string().required(),
    details_performance_strategy: Joi.string().required(),
    details_quantification_strategy: Joi.string().required(),
    details_sources_of_financing: Joi.alternatives(
      Joi.array()
        .has({
          id: "Other (please specify)",
          specific: Joi.string().required(),
        })
        .required(),
      Joi.array()
        .min(1)
        .items({
          id: Joi.not("Other (please specify)"),
        })
        .required()
    ).required(),
    details_technology_or_project_strategy: Joi.string().required(),
  }),
  [MESSENGER_GROUPS.PIN.EMISSION]: Joi.object({
    expectations_summary: Joi.string().required(),
    expectations_monitoring: Joi.string().required(),
    expectations_additionally: Joi.string().required(),
    expectations_baseline_scenario: Joi.string().required(),
    expectations_calculation_method: Joi.string().required(),
  }),
  [MESSENGER_GROUPS.PIN.BENEFITS]: Joi.object({
    benefits_social: Joi.string().required(),
    benefits_economic: Joi.string().required(),
    benefits_environmental: Joi.string().required(),
  }),
  [MESSENGER_GROUPS.PIN.VIABILITY]: Joi.object({
    assessment_leakage: Joi.string().required(),
    assessment_obstacles: Joi.string().required(),
    assessment_ownership: Joi.object({
      id: Joi.string().required(),
    }).required(),
    assessment_permanence: Joi.string().required(),
    assessment_quantifiable: Joi.string().required(),
    assessment_other_programs: Joi.object({
      id: Joi.string().required(),
    }).required(),
    assessment_monitoring_data: Joi.alternatives(
      Joi.array()
        .has({
          id: "Other (specify)",
          specific: Joi.string().required(),
        })
        .required(),
      Joi.array()
        .min(1)
        .items({
          id: Joi.not("Other (specify)"),
        })
        .required()
    ).required(),
    assessment_social_obstacles: Joi.string().required(),
    assessment_collection_methods: Joi.string().required(),
    assessment_evidence_of_challenges: Joi.string().required(),
    assessment_ownership_demonstrated: Joi.string().required(),
    assessment_financial_justification: Joi.string().required(),
    assessment_method_baseline_scenario: Joi.string().required(),
    assessment_probable_baseline_scenario: Joi.string().required(),
  }),
};

export default validationSchema;
