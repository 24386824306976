import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Space } from "@evercityecosystem/evercity-ui";

import { REPORT_STATUSES } from "../../helpers/reportStatuses";
import styles from "./StatusTag.module.less";

const StatusTag = ({ className, type, text, badge = null }) => {
  if (badge) {
    return (
      <Space size={4}>
        <div
          className={classNames(className, styles.tag, styles[`tag--${type}`])}
        >
          {text}
        </div>
        {badge}
      </Space>
    );
  }

  return (
    <div className={classNames(className, styles.tag, styles[`tag--${type}`])}>
      {text}
    </div>
  );
};
StatusTag.defaultProps = {
  type: "draft",
};

StatusTag.propTypes = {
  type: PropTypes.oneOf(Object.keys(REPORT_STATUSES)),
};

export default StatusTag;
