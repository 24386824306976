import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useMutation } from "urql";

import { ContentLayout, Radio, Space } from "@evercityecosystem/evercity-ui";
import ContentTwoColsLayout from "../ContentTwoColsLayout/ContentTwoColsLayout";
import styles from "./SubstantialContributionPage.module.less";
import RadioCard from "./RadioCard";
import InlineNote from "../InlineNote/InlineNote";
import UnitPointsFormConnected from "../UnitPointsForm/UnitPointsFormConnected";
import { Text, Title } from "../../antd-ovveride";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";

import { updateUnitMutation } from "../../api/mutations";
import DescriptionPointTree from "../Points/DescriptionPointTree";
import useBreakpoint from "../../hooks/useBreakpoint";
import { MESSENGER_GROUPS } from "../../data/messenger";
import { GroupContext } from "../../helpers/withThreadsHoc";

const { Group: RadioGroup } = Radio;

const SubstantialContributionPage = ({ onFinish, onBackClick, threads }) => {
  const { unit, actReport } = useOutletContext();
  const { activityId, unitId, unitParentId } = useParams();
  const isSubunit = unitParentId !== "_";
  const unitType = isSubunit ? "subunit" : "unit";

  const [, updateUnit] = useMutation(updateUnitMutation);

  const {
    mitigation_type: mitigationType,
    adaptation_type: adaptationType,
    name: activityName,
    points,
  } = actReport?.activity;

  const [objective, setObjective] = useState(unit.objective);

  const descriptionPoints = useMemo(
    () => points?.filter((point) => point.objective === objective),
    [objective, points]
  );

  useEffect(() => {
    updateUnit({
      unitId,
      changes: {
        objective,
      },
    });
  }, [objective, unitId, updateUnit]);

  const { lg } = useBreakpoint();

  return (
    <GroupContext.Provider
      value={MESSENGER_GROUPS.TAXONOMY_UNIT.SUBSTANTIAL_CONTRIBUTION}
    >
      <ContentLayout gaps="off">
        <Title level={2}>
          <Space align="center" size={8}>
            Identify substantial contribution to environmental objectives
            <WatchTutorialBtn startTime={isSubunit ? 307 : 239} />
          </Space>
        </Title>
        <Text type="secondary" size="large" noMargin>
          Screen your {unitType} on alignment with each environmental objective
          and select the most applicable one.
        </Text>
        <RadioGroup
          data-cy="radio-objective"
          className={styles.radioGroup}
          onChange={({ id }) => setObjective(id)}
          defaultValue={{ id: objective }}
        >
          <RadioCard
            value="mitigation"
            disabled={!mitigationType}
            tooltip="Climate change mitigation"
          >
            Mitigation
          </RadioCard>
          <RadioCard
            value="adaptation"
            disabled={!adaptationType}
            tooltip="Climate change adaptation"
          >
            Adaptation
          </RadioCard>
          <RadioCard
            value="water"
            disabled
            tooltip="The sustainable use and protection of water and marine resources"
          >
            Water
          </RadioCard>
          <RadioCard
            value="circular_economy"
            disabled
            tooltip="The transition to a circular economy"
          >
            Circular Economy
          </RadioCard>
          <RadioCard
            value="pollution"
            disabled
            tooltip="Pollution prevention and control"
          >
            Pollution
          </RadioCard>
          <RadioCard
            value="biodiversity"
            disabled
            tooltip="The protection and restoration of biodiversity and ecosystems"
          >
            Biodiversity
          </RadioCard>
        </RadioGroup>
        <ContentTwoColsLayout aside={lg}>
          <Title level={2}>Objective: {`Climate change ${objective}`}</Title>
          <Title level={3}>Activity: {activityName}</Title>
          <DescriptionPointTree points={descriptionPoints} />
          <InlineNote bodyPaddingBottom={20}>
            In case a unit fails to meet the requirements for alignment, you can
            break it down into smaller business subunits. Keep in mind the
            activity criteria to create a subunit that will be fully aligned.{" "}
            <i>
              Examples:
              <br />- If a unit “Mixed fleet of vessels” (diesel + electric
              vessels) is not aligned, its smaller part, a subunit “Electric
              vessels”, might be aligned.
              <br />- If a unit “Aluminum manufacturing division” is not
              aligned, its smaller part, a subunit “Low-carbon aluminum
              factory”, might be aligned.
            </i>
          </InlineNote>
          <UnitPointsFormConnected
            onSubmit={onFinish}
            threads={threads}
            submitText="Next Step"
            onCancel={onBackClick}
            cancelText="Back"
            activityId={activityId}
            unit={unit}
            pointsType="substantial_contribution"
          />
        </ContentTwoColsLayout>
      </ContentLayout>
    </GroupContext.Provider>
  );
};
export default SubstantialContributionPage;
