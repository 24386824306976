import React from "react";
import AlignedModal from "./AlignedModal";

const UnitAlignedModalSC = ({ isSubunit, message, ...rest }) => (
  <AlignedModal withRetry={!!message} {...rest} title="Aligned">
    {message ||
      `You are on the right track! Your ${isSubunit ? "subunit" : "unit"} meets
    technical screening criteria and is so far Taxonomy-aligned. Please proceed
    to DNSH.`}
  </AlignedModal>
);

export default UnitAlignedModalSC;
