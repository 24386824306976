import React from "react";
import { Text } from "@antd-ovveride";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { useMutation } from "urql";
import { notification } from "antd";
import PublicLayout from "../PublicLayout/PublicLayout";
import FormCard from "../FormCard/FormCard";
import RequestAccessForm from "./RequestAccessForm";
import { requestAccessToCommodityMutation } from "../../api/mutations";

const RequestAccessPage = () => {
  const { commodity } = useOutletContext();
  const [{ fetching }, requestAccessToCommodity] = useMutation(
    requestAccessToCommodityMutation
  );

  const [step, setStep] = React.useState(0);

  const handleApplicationSubmit = ({ name, email, company, password }) => {
    requestAccessToCommodity({
      application: { name, email, company, password },
      commodityId: commodity.id,
    }).then((response) => {
      if (response.error) {
        notification.error({
          message: "Error request access.",
          description:
            response.error.graphQLErrors[0].message ===
            "Account already exists."
              ? "Looks like you have already requested a access."
              : "Internal error. Please try again later.",
        });
        return console.log("Error", response.error);
      }
      setStep(1);
    });
  };

  const steps = [
    <FormCard
      title="Get access"
      subtitle="Only registered users can access this feature. To register please fill in the data below."
    >
      <RequestAccessForm
        onSubmit={handleApplicationSubmit}
        loading={fetching}
      />
    </FormCard>,
    <FormCard
      title={
        <>
          <Text color="success">
            <CheckCircleOutlined />
          </Text>{" "}
          Thank you for inquiry!
        </>
      }
      subtitle="We will review it and contact you shortly."
    />,
  ];
  return (
    <PublicLayout variant="dialog" footer={false}>
      {steps[step]}
    </PublicLayout>
  );
};

export default RequestAccessPage;
