import React, { useMemo } from "react";
import { Text } from "@antd-ovveride";
import styles from "./AddActivityDrawerContent.module.less";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import SearchInput from "../SearchInput/SearchInput";
import ActivitiesList from "./ActivitiesList";
import GroupedActivitiesList from "./GroupedActivitiesList";

const ContentLoadingWrapper = ({ children, loading, notFound }) => {
  if (loading) {
    return <LoadingSpinner />;
  }
  if (notFound) {
    return <Text>Not Found</Text>;
  }
  return children;
};

const AddActivityDrawerContent = ({
  activities,
  selectedActivities,
  addedActivities,
  selectedCodes,
  addedCodes,
  searchValue,
  onSearch,
  fetching,
  onActivityCardClick,
  onActivityCardBtnClick,
}) => {
  const listProps = {
    activities,
    selectedActivities,
    addedActivities,
    selectedCodes,
    addedCodes,
    onActivityCardClick,
    onActivityCardBtnClick,
  };

  const selectedActivitiesContent = useMemo(
    () => activities.filter((act) => selectedActivities.includes(act.id)),
    [selectedActivities, activities]
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInput
          placeholder="search by keywords or NACE code"
          value={searchValue}
          onChange={onSearch}
        />
      </div>
      <div className={styles.scrolledContainer}>
        <div>
          <ContentLoadingWrapper
            loading={fetching}
            notFound={!activities.length}
          >
            {searchValue.length ? (
              <div className={styles.filteredList}>
                <ActivitiesList {...listProps} />
              </div>
            ) : (
              <>
                {!!selectedActivities.length && (
                  <>
                    <div className={styles.selectedActivitiesList}>
                      <ActivitiesList
                        {...listProps}
                        activities={selectedActivitiesContent}
                      />
                    </div>
                  </>
                )}
                <GroupedActivitiesList {...listProps} />
              </>
            )}
          </ContentLoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default AddActivityDrawerContent;
