import { Title, Space } from "@evercityecosystem/evercity-ui";
import React from "react";
import PointsContainer from "../Points/PointsContainer";
import PointSwitcher from "../PointSwitcher/PointSwitcher";

const groupPoints = (points) => {
  const pointsByGroup = points.reduce((acc, item) => {
    const groupId = item.point_group || "all";
    if (!acc[groupId]) acc[groupId] = [];
    acc[groupId].push(item);
    return acc;
  }, {});

  const groupedPoints = [];

  Object.entries(pointsByGroup).forEach(([group, items]) => {
    if (group !== "all") {
      return groupedPoints.unshift({
        title: group,
        points: items,
      });
    }

    return groupedPoints.push({
      title: null,
      points: items,
    });
  });

  return groupedPoints;
};
const PointsList = ({ points, threads, form, grouped = false }) => {
  if (grouped) {
    return (
      <PointsContainer>
        {groupPoints(points).map((group) => {
          const { title } = group;
          const key = title || "key";
          const size = title ? 12 : 30;
          return (
            <Space
              size={size}
              direction="vertical"
              style={{ display: "flex" }}
              key={key}
            >
              {title ? <Title level={4}>{title}</Title> : null}
              {group.points.map((point) => (
                <PointsContainer key={point.id}>
                  <PointSwitcher point={point} form={form} threads={threads} />
                </PointsContainer>
              ))}
            </Space>
          );
        })}
      </PointsContainer>
    );
  }
  return (
    <PointsContainer>
      {points.map((point) => (
        <PointSwitcher
          threads={threads}
          point={point}
          form={form}
          key={point.id}
        />
      ))}
    </PointsContainer>
  );
};

export default PointsList;
