import React from "react";
import {
  Link,
  ContentLayout,
  Text,
  Col,
  Row,
  PlusCircleOutlined,
} from "@evercityecosystem/evercity-ui";
import styles from "./Frameworks.module.less";
import LkLayout from "../LkLayout/LkLayout";
import FrameworkFilesTableConnected from "../FrameworksTable/FrameworkFilesTableConnected";

const FrameworksPage = () => (
  <LkLayout>
    <ContentLayout gaps="off">
      <Row justify="space-between" className={styles.head} align="bottom">
        <Col>
          <Text block type="secondary" size="large">
            You can download generated framework and check for info
          </Text>
        </Col>
        <Col>
          <Link
            type="btn-primary"
            data-cy="btn-add-framework"
            icon={<PlusCircleOutlined />}
            to="/company/frameworks/new"
          >
            Add framework
          </Link>
        </Col>
      </Row>
      <FrameworkFilesTableConnected />
    </ContentLayout>
  </LkLayout>
);

export default FrameworksPage;
