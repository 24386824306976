import React from "react";
import { Pie, PieChart, Cell, Tooltip, Legend } from "recharts";
import { string, arrayOf, shape, bool } from "prop-types";
import { Typography, Empty } from "antd";

import { percentsToDecimal, decimalToPercents } from "../../helpers/percents";
import { grayColor } from "../ResultPage/components/data/colorsList";

import SingleLegend from "./SingleLegend";
import CustomPieTooltipLayout from "./CustomPieTooltipLayout";

import styles from "./singleChart.module.less";

const transformData = (data) =>
  data.map((item) => {
    const value = percentsToDecimal(item.value);

    return {
      ...item,
      value,
    };
  });

const { Title } = Typography;

const SinglePieTooltip = ({ active, payload }) => {
  if (!active) return null;

  const piePartSettings = payload[0].payload;

  const { name, value } = piePartSettings.payload;

  const headerList = [
    {
      name,
      color: piePartSettings.stroke,
    },
  ];

  const summary = decimalToPercents(value);

  return <CustomPieTooltipLayout headerList={headerList} summary={summary} />;
};

const SinglePieChart = ({ title, data, colorsList, legend }) => (
  <div className={styles.container}>
    {title && (
      <Title level={4} align="center" className={styles.title}>
        {title}
      </Title>
    )}
    {data.length ? (
      <PieChart
        width={216}
        height={216}
        style={{ margin: "0 auto", height: "auto" }}
      >
        <Tooltip
          content={<SinglePieTooltip />}
          wrapperStyle={{ zIndex: 1000 }}
        />

        <Pie
          data={transformData(data)}
          cx="50%"
          cy="40%"
          innerRadius={60}
          outerRadius={84}
          paddingAngle={0}
          labelLine={false}
          dataKey="value"
        >
          {data.map(({ name }, index) => {
            let color = colorsList[index % colorsList.length];

            if (["non-eligible", "non-aligned"].includes(name)) {
              color = grayColor;
            }
            return <Cell stroke={color} key={name} fill={color} />;
          })}
        </Pie>
        {legend && (
          <Legend
            content={SingleLegend}
            iconSize={12}
            iconType="circle"
            layout="veritcal"
            verticalAlign="top"
            width="100%"
            align="center"
            wrapperStyle={{ position: "relative", top: -20 }}
          />
        )}
      </PieChart>
    ) : (
      <Empty />
    )}
  </div>
);

SinglePieChart.propTypes = {
  title: string,
  colorsList: arrayOf(string.isRequired).isRequired,
  data: arrayOf(
    shape({
      name: string.isRequired,
      value: string.isRequired,
    }).isRequired
  ).isRequired,
  legend: bool,
};

SinglePieChart.defautProps = {
  legend: false,
  title: null,
};

export default SinglePieChart;
