import React from "react";
import { InlineNote, ExternalLink } from "@evercityecosystem/evercity-ui";
import { METHODOLOGY_LIST } from "../../data/PIN";

const MethodologiesNote = ({ title }) => (
  <InlineNote bodyPaddingBottom={10}>
    {title}
    <ul>
      {METHODOLOGY_LIST.map((methodology, index) => (
        <li>
          <ExternalLink href={methodology.link}>
            {methodology.name}
            {index === METHODOLOGY_LIST.length - 1 ? "." : ";"}
          </ExternalLink>
        </li>
      ))}
    </ul>
  </InlineNote>
);
export default MethodologiesNote;
