import React from "react";
import { Typography } from "antd";
import cs from "classnames";
import styles from "./Title.module.less";

const { Title: BaseTitle } = Typography;

const Title = ({ level, className, size, noMargin, ...titleProps }) => (
  <BaseTitle
    className={cs(
      className,
      styles.title,
      noMargin && styles["title-noMargin"],
      level && styles[`title--h${level}`],
      size && styles[`title--${size}`]
    )}
    level={level}
    {...titleProps}
  />
);

export default Title;
