import React from "react";
import { Typography, Space } from "antd";
import { Text } from "@antd-ovveride";
import cn from "classnames";

import styles from "./FixedContentHeader.module.less";

const { Title } = Typography;

const FixedContentHeader = ({ title, country, description, type }) => (
  <Space direction="vertical" size={10}>
    <div>
      <Title
        level={4}
        style={{ margin: 0 }}
        className={cn(styles.title, styles[`title--${type}`])}
      >
        {title}
      </Title>
      <Text type="secondary" className={styles.link} size="large">
        {country}
      </Text>
    </div>
    <Text className={cn(styles.description, styles[`description--${type}`])}>
      {description}
    </Text>
  </Space>
);

export default FixedContentHeader;
