import React from "react";
import cs from "classnames";
import styles from "./CodeTag.module.less";

const CodeTag = ({ text, className, onClick, isSelected, disabled }) => (
  <button
    type="button"
    className={cs(
      className,
      styles.codeTag,
      isSelected && styles["codeTag--selected"]
    )}
    disabled={disabled}
    onClick={(e) => !disabled && onClick?.(e)}
  >
    {text}
  </button>
);

export default CodeTag;
