import React from "react";
import { node, string, bool } from "prop-types";
import cn from "classnames";

import styles from "./PublicLayoutContainer.module.less";

const PublicLayoutContainer = ({ children, className, wide, margins }) => (
  <div
    className={cn(
      className,
      styles.container,
      { [styles.wide]: wide },
      { [styles.margins]: margins }
    )}
  >
    {children}
  </div>
);

PublicLayoutContainer.propTypes = {
  children: node.isRequired,
  className: string,
  margins: bool,
  wide: bool,
};

PublicLayoutContainer.defaultProps = {
  className: null,
  margins: false,
  wide: false,
};

export default PublicLayoutContainer;
