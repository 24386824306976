import React from "react";
import { useOutletContext } from "react-router-dom";
import ProcessForProject from "./ProcessForProject";

const ProcessForProjectConnected = () => {
  const props = useOutletContext();
  return <ProcessForProject {...props} />;
};

export default ProcessForProjectConnected;
