import React from "react";
import { Space } from "antd";
import SDGItem from "./SDGItem";

const SDGList = ({ items }) => (
  <Space size={3} wrap>
    {items?.map((sdg) => (
      <SDGItem sdgId={sdg.goal_id} key={sdg.goal_id} />
    ))}
  </Space>
);

export default SDGList;
