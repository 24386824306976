import React from "react";
import { Button } from "antd";
import {
  PlusCircleOutlined,
  RedoOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import NotAlignedModal from "./NotAlignedModal";

const UnitNotAlignedModal = ({
  isSubunit,
  onAddSubunit,
  onRetry,
  onSave,
  content,
  ...rest
}) => (
  <NotAlignedModal
    {...rest}
    title="Not aligned"
    content={content}
    footer={[
      <Button
        key="retry"
        onClick={onRetry}
        size="large"
        type="primary"
        icon={<RedoOutlined />}
      >
        Retry
      </Button>,
      !isSubunit && (
        <Button
          key="add_subunit"
          onClick={onAddSubunit}
          size="large"
          icon={<PlusCircleOutlined />}
        >
          Add subunit
        </Button>
      ),
      <Button
        key="save_and_proceed"
        type={isSubunit && "primary"}
        onClick={onSave}
        size="large"
        icon={<SaveOutlined />}
      >
        Save and proceed
      </Button>,
    ]}
  />
);

export default UnitNotAlignedModal;
