import React from "react";
import { Progress } from "antd";
import { Text } from "@antd-ovveride";
import styles from "./ListingProgress.module.less";

const ListingProgress = ({ total, part, message }) => (
  <>
    <Text type="secondary" className={styles.header}>
      {message}
    </Text>
    <Progress
      percent={Math.round((part / total) * 100)}
      className={styles.listingProgress}
      size="small"
    />
  </>
);

export default ListingProgress;
