import { gql } from "urql";

export const docFragment = gql`
  fragment doc on docs {
    id
    description
    mimetype
    name
    url
    user_id
    created_at
    updated_at
  }
`;

export const unitDecisionAttachmentFragment = gql`
  ${docFragment}
  fragment unitDecisionAttachment on eu_taxonomy_unit_decision_attachments {
    doc_id
    unit_point_decision_id
    comment
    doc {
      ...doc
    }
  }
`;

export const reportDecisionAttachmentFragment = gql`
  ${docFragment}
  fragment reportDecisionAttachment on eu_taxonomy_report_decision_attachments {
    doc_id
    report_decision_id
    comment
    doc {
      ...doc
    }
  }
`;

export const reportAttachmentsFragment = gql`
  ${docFragment}
  fragment reportAttachments on eu_taxonomy_report_attachments {
    report_id
    doc_id
    doc {
      ...doc
    }
  }
`;

export const reportFieldsFragment = gql`
  fragment reportFields on eu_taxonomy_reports {
    id
    currency
    reporting_year
    turnover
    capex
    opex
    turnover_eligibility
    capex_eligibility
    opex_eligibility
    turnover_alignment
    capex_alignment
    opex_alignment
    status
    mss_type
    company {
      id
      company_consultants {
        consultant_id
        manager {
          id
          display_name
        }
      }
    }
    manager {
      id
      display_name
    }
  }
`;

export const companyFieldsFragment = gql`
  fragment companyFields on companies {
    id
    company_number
    country
    description
    employee_num
    name
    logo
    lei
    nfrd_csrd
    website
  }
`;

export const activitiesFieldsFragment = gql`
  fragment activitiesFields on eu_taxonomy_activities {
    id
    name
    mitigation_type
    adaptation_type
    order
    category_id
    brief_description
    available
  }
`;

export const activityReportFieldsFragment = gql`
  fragment activityReportFields on eu_taxonomy_activities_reports {
    id
    activity_id
    report_id
    turnover
    capex
    activity {
      name
      mitigation_type
      adaptation_type
    }
    activities_reports_codes {
      code_id
      activity_report_id
      nace_code {
        id
        code
        description
      }
    }
    opex
    turnover_alignment
    capex_alignment
    opex_alignment
  }
`;

export const unitAttachmentsFragment = gql`
  ${docFragment}
  fragment unitAttachments on eu_taxonomy_unit_attachments {
    unit_id
    doc_id
    doc {
      ...doc
    }
  }
`;

export const unitFieldsFragment = gql`
  fragment unitFields on eu_taxonomy_units {
    id
    activity_report_id
    description
    name
    parent_id
    type
    turnover
    capex
    opex
    turnover_alignment
    capex_alignment
    opex_alignment
    screening_progress
    objective
    activity_type
  }
`;

export const unitPointDecisionFragment = gql`
  ${unitDecisionAttachmentFragment}
  fragment unitPointDecision on eu_taxonomy_unit_point_decision {
    decision
    id
    unit_decision_attachments {
      ...unitDecisionAttachment
    }
  }
`;

export const pointsFieldsFragment = gql`
  fragment pointsFields on eu_taxonomy_points {
    id
    text
    show_on_decision
    activity_id
    point_subtype {
      description
      name
    }
    point_hints {
      overridden_label
      item {
        label
        description
        item_links {
          link {
            label
            URL
          }
        }
      }
    }
    point_links {
      link {
        id
        label
        URL
      }
    }
    parent_id
    rule
    activity_id
    objective
    type
    changes_activity_type
  }
`;

export const reportPointDecisionFragment = gql`
  ${reportDecisionAttachmentFragment}
  fragment reportPointDecision on eu_taxonomy_report_point_decision {
    id
    decision
    report_decision_attachments {
      ...reportDecisionAttachment
    }
  }
`;

export const reportPointFragment = gql`
  fragment reportPoint on eu_taxonomy_report_points {
    id
    order
    rule
    text
    type
    parent_id
    point_mss_criteria {
      key
      title
    }
  }
`;

export const goalFragment = gql`
  fragment goal on goals {
    id
    name
    inverted_color
  }
`;

export const activitiesReportsFieldsExtendedFragment = gql`
  ${activitiesFieldsFragment}
  ${reportFieldsFragment}
  ${activityReportFieldsFragment}
  fragment activitiesReportsFieldsExtended on eu_taxonomy_activities_reports {
    ...activityReportFields
    activity {
      ...activitiesFields
      category {
        id
        order
      }
    }
    report {
      ...reportFields
    }
  }
`;

export const userFragment = gql`
  fragment userFields on users {
    id
    display_name
    blockchain_id
    company_role
    company_id
    company {
      id
      type
      name
      country
      settings
      companyConsultantsByConsultantId {
        client_id
        company {
          id
          name
          country
        }
      }
      company_consultants {
        companyByConsultantId {
          id
          name
        }
      }
      __typename
    }
    user_blockchain_roles {
      blockchain_role
    }
    __typename
    account {
      id
      active
      email
      __typename
    }
  }
`;

export const introductionFrameworkFragment = gql`
  fragment introduction on frameworks {
    comp_desc
    comp_sust_goals
    comp_sust_example
    comp_fossil_fuel_unavailability
    comp_fossil_fuel
    goals
    principles
    issuance
    other_standards_unavailability
    other_standards
    instrument_types
  }
`;

export const processForProjectFrameworkFragment = gql`
  fragment processForProject on frameworks {
    asset_select_frequency
    eligible_assess_depart
    eligible_approve_depart
    monitoring_depart
  }
`;

export const managementOfProceedFrameworkFragment = gql`
  fragment managementOfProceed on frameworks {
    allocation_time
    manage_proceeds_approach
    tracking_proceeds_approach
    track_proceeds_depart
    track_update_frequency
    lookback_assets
    lookback_expend
    lookback_projects
    lookback_invest
    allocation_at_issuance
    manage_unallocated_proceeds
  }
`;

export const excludedCategoriesFrameworkFragment = gql`
  fragment excludedCategories on excluded_categories {
    id
    name
  }
`;

export const exampleCategoryGoalsFrameworkFragment = gql`
  fragment exampleCategoryGoals on example_category_goals {
    goal_id
    example_category_id
  }
`;

export const categoryFrameworkFragment = gql`
  fragment category on framework_categories {
    id
    name
  }
`;

export const exampleCategoryFrameworkFragment = gql`
  ${goalFragment}
  ${exampleCategoryGoalsFrameworkFragment}
  ${categoryFrameworkFragment}
  fragment exampleCategory on example_categories {
    id
    example_category {
      ...category
    }
    project_examples
    impact_indicators
    goals {
      created_at
      updated_at
      ...exampleCategoryGoals
      goal {
        ...goal
      }
    }
  }
`;

export const useOfProceedsFrameworkFragment = gql`
  ${excludedCategoriesFrameworkFragment}
  ${goalFragment}
  ${exampleCategoryFrameworkFragment}

  fragment useOfProceeds on frameworks {
    finance_refinance
    example_categories {
      created_at
      updated_at
      ...exampleCategory
    }

    excluded_categories {
      created_at
      updated_at
      excluded_category {
        ...excludedCategories
      }
    }
  }
`;

export const reportingFrameworkFragment = gql`
  fragment reporting on frameworks {
    allocation_report_frequency
    allocation_report_detail
    impact_report_unavailability
    impact_report_time
    impact_report_frequency
    impact_report_detail
    report_published
  }
`;

export const externalReviewFrameworkFragment = gql`
  fragment externalReview on frameworks {
    preissue_verifier
    preissue_published
    postissue_verifier
    postissue_report_published
    certification_unavailability
    certification_published
    external_review_unavailability
    external_reviewer
    external_review_published
  }
`;

export const updatesFrameworkFragment = gql`
  fragment updates on frameworks {
    framework_updates
    future_standards
    new_standards_unavailability
    framework_updates_published
  }
`;

export const frameworkFragment = gql`
  ${introductionFrameworkFragment}
  ${useOfProceedsFrameworkFragment}
  ${managementOfProceedFrameworkFragment}
  ${reportingFrameworkFragment}
  ${updatesFrameworkFragment}
  ${externalReviewFrameworkFragment}
  ${processForProjectFrameworkFragment}
  ${companyFieldsFragment}
  ${docFragment}

  fragment framework on frameworks {
    id
    name
    created_at
    standard
    status
    framework_docs(where: { attachment_type: { _eq: "full_text" } }) {
      doc_id
      attachment_type
      framework_id
      doc {
        ...doc
      }
    }
    company {
      ...companyFields
    }
    ...introduction
    ...useOfProceeds
    ...processForProject
    ...managementOfProceed
    ...reporting
    ...externalReview
    ...updates
  }
`;

export const categoryFragment = gql`
  fragment category on categories {
    title
    id
  }
`;

export const debtCardsPreviewFragment = gql`
  ${goalFragment}
  fragment debtCardsPreview on bonds {
    id
    image {
      id
      source_url
      blurred_url
    }
    maturity_date
    issuance_date
    booking_date
    type
    volume
    currency
    interest_rate_base_value
    state
    bought
    isin
    description
    slug
    bond_projects {
      project_id
      bond_id
      project {
        project_categories {
          project_id
          category_id
          category {
            title
            id
          }
        }
      }
    }
    company {
      id
      logo
      name
      country
      description
    }
    bond_goals {
      goal_id
      bond_id
      goal {
        ...goal
      }
    }
  }
`;

export const projectCardsPreviewFragment = gql`
  ${goalFragment}
  ${categoryFragment}

  fragment projectCardsPreview on projects {
    id
    image {
      id
      source_url
      blurred_url
    }
    name
    description
    country
    state
    slug
    projects_goals {
      goal_id
      project_id
    }
    project_categories {
      project_id
      category_id
      category {
        ...category
      }
    }
    project_impact_indicators {
      project_id
      impact_indicator_id
      impact_indicator {
        id
        measure
        name
      }
      temp_text_value
    }
    company {
      id
      logo
      location
    }
    projects_goals {
      goal_id
      project_id
      goal {
        ...goal
      }
    }
    commodities {
      id
      type
    }
    bond_projects {
      bond {
        id
        type
      }
      project_id
      bond_id
    }
    project_categories {
      project_id
      category_id
      category {
        ...category
      }
    }
  }
`;

export const commodityCardsPreviewFragment = gql`
  ${goalFragment}
  fragment commodityCardsPreview on commodities {
    id
    commodity_state {
      name
      description
    }
    type
    volume
    bought
    price
    vintage
    registry_number
    currency
    slug
    company {
      country
      id
      logo
    }
    project {
      id
      image {
        id
        source_url
        blurred_url
      }
      description
      name
      country
      projects_goals {
        goal_id
        project_id
        goal {
          ...goal
        }
      }
    }
    commodity_standard {
      id
      short_title
      title
    }
  }
`;

export const naceCodeFragment = gql`
  fragment naceCode on eu_taxonomy_nace_codes {
    id
    code
    sbi_code
    type
    description
    parent_id
  }
`;

export const projectFragment = gql`
  ${projectCardsPreviewFragment}
  ${docFragment}
  fragment project on projects {
    ...projectCardsPreview
    __typename
    description
    challenge
    # social_benefits
    # enviromental_benefits
    solution
    project_region {
      name
      description
    }
    long_term_impact
    location
    company {
      establishment_year
      location
      name
      summary
      website
      country
    }
    projects_goals {
      goal_id
    }
    project_categories {
      category {
        id
        title
      }
    }
    roadmap(order_by: { order: asc }) {
      id
      date
      description
      order
    }
    project_impact_indicators {
      impact_indicator {
        name
        id
        measure
      }
      temp_text_value
    }
    project_docs {
      project_id
      doc_id
      attachment_type
    }
  }
`;

export const pinFragment = gql`
  fragment pin on commodity_PINs {
    id
    created_at
    data
    status
    publication_date
    updated_at
    __typename
  }
`;

export const threadFragment = gql`
  fragment thread on messenger_threads {
    id
    config
    created_at
    created_by
    thread_state {
      id
      name
    }
    __typename
    topic
  }
`;

export const messageFragment = gql`
  fragment message on messenger_messages {
    id
    thread_id
    created_at
    updated_at
    text
    user_id
    __typename
  }
`;
