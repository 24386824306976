import { useMemo } from "react";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES } from "../../data/messenger";

const useReportsWithIssues = (reports) => {
  const [reportsThreadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_REPORT,
  });
  const [unitsThreadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
  });

  const reportsThreads = reportsThreadsResponse.dataRaw;
  const unitsThreads = unitsThreadsResponse.dataRaw;

  return useMemo(
    () =>
      reports?.map((report) => {
        const resportThreads =
          reportsThreads?.filter(
            (thread) => thread.config.entityId === report.id
          ) || [];

        const reportIssues = resportThreads.reduce(
          (acc, thread) => ({
            ...acc,
            [thread.thread_state.name]:
              (acc[thread.thread_state.name] || 0) + 1,
          }),
          {}
        );

        const units = report.activities_reports.reduce((acc, actReport) => {
          const result = [...acc];
          actReport.units.forEach((unit) => {
            result.push(unit);
          });
          return result;
        }, []);

        const unitsIssues = units.reduce((acc, unit) => {
          const result = { ...acc };
          const unitThreads =
            unitsThreads?.filter(
              (thread) => thread.config.entityId === unit.id
            ) || [];
          unitThreads.forEach((thread) => {
            result[thread.thread_state.name] =
              (result[thread.thread_state.name] || 0) + 1;
          });

          return result;
        }, {});

        const reportsWithUnitsIssues = Object.entries(unitsIssues).reduce(
          (acc, [state, count]) => {
            const result = { ...acc };
            result[state] = (result[state] || 0) + count;
            return result;
          },
          reportIssues
        );

        return {
          ...report,
          issues: reportsWithUnitsIssues,
        };
      }),
    [reports, reportsThreads, unitsThreads]
  );
};

export default useReportsWithIssues;
