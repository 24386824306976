import React from "react";
import cs from "classnames";
import styles from "./Tag.module.less";

const TagBase = ({ children, className }) => (
  <div className={cs(styles.tagBase, className)}>{children}</div>
);

const AssetTypeTag = ({ text }) => {
  const textToTheme = {
    "covered bond": "gray",
    "project bond": "blue",
    "revenue bod": "geekblue",
    "securitized bond": "red",
    sukuk: "cyan",
    "use of proceeds bond": "magenta",
    carbon: "lime",
    cln: "purple",
    loan: "orange",
  };

  return (
    <TagBase
      className={cs(
        styles.assetTypeTag,
        styles[`assetTypeTag--${textToTheme[text?.toLowerCase()]}`]
      )}
    >
      {text}
    </TagBase>
  );
};

const CategoryTag = ({ text }) => (
  <TagBase className={styles.categoryTag}>{text}</TagBase>
);

const StatusTag = ({ text }) => {
  const textToTheme = {
    "sold out": "red",
    draft: "gray1",
    issued: "blue",
    listed: "cyan",
    raising: "green",
    registered: "purple",
    retired: "gray8",
    roadshow: "gold",
    subscription: "green",
  };

  return (
    <TagBase
      className={cs(
        styles.statusTag,
        styles[`statusTag--${textToTheme[text?.toLowerCase()]}`]
      )}
    >
      {text}
    </TagBase>
  );
};

const Tag = ({ text, type }) => {
  const TagComponent = {
    assetType: AssetTypeTag,
    category: CategoryTag,
    status: StatusTag,
  }[type];

  return <TagComponent text={text} />;
};

export default Tag;
