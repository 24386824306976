import React from "react";
import useGenerateInviteLink from "../../hooks/user/useGenerateInviteLink";
import TeamPage from "./TeamPage";
import useModal from "../../hooks/useModal";

const TeamPageConnected = () => {
  const { show, Modal, close } = useModal(false);
  const { generate, inviteLink } = useGenerateInviteLink("team_member");

  const onInvite = () => {
    generate().then(() => {
      show();
    });
  };

  return (
    <TeamPage
      Modal={Modal}
      close={close}
      inviteLink={inviteLink}
      onInvite={onInvite}
    />
  );
};

export default TeamPageConnected;
