import React from "react";
import { Input } from "@antd-ovveride";
import { Form } from "antd";
import styles from "./ExternalReview.module.less";

import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import CheckboxGroupPublish from "../CheckboxGroupPublish/CheckboxGroupPublish";
import SwitchUnability from "../SwitchUnability/SwitchUnability";

const { Item: FormItem } = Form;

const ExternalReview = ({ framework, updateFramework, title }) => (
  <>
    {title}
    <FrameworkSection number="6">
      {framework.issuance === "Yes" && (
        <>
          <FrameworkQuestion
            number="6.1"
            title="Who will provide pre-issuance verification?"
          >
            <FormItem
              name="preissue_verifier"
              initialValue={framework.preissue_verifier}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Input
                defaultValue={framework.preissue_verifier}
                onBlur={adapterDOMEvent(updateFramework("preissue_verifier"))}
              />
            </FormItem>
          </FrameworkQuestion>

          <FrameworkQuestion
            number="6.2"
            title="How will pre-issuance verification be made available?"
          >
            <FormItem
              name="preissue_published"
              initialValue={framework.preissue_published}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <CheckboxGroupPublish
                defaultValue={framework.preissue_published}
                onChange={updateFramework("preissue_published")}
              />
            </FormItem>
          </FrameworkQuestion>

          <FrameworkQuestion
            number="6.3"
            title="Who will provide post-issuance verification?"
          >
            <FormItem
              name="postissue_verifier"
              initialValue={framework.postissue_verifier}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Input
                defaultValue={framework.postissue_verifier}
                onBlur={adapterDOMEvent(updateFramework("postissue_verifier"))}
                className={styles.input}
              />
            </FormItem>
          </FrameworkQuestion>

          <FrameworkQuestion
            number="6.4"
            title="How will post-issuance verification be made available?"
          >
            <FormItem
              name="postissue_report_published"
              initialValue={framework.postissue_report_published}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <CheckboxGroupPublish
                defaultValue={framework.postissue_report_published || []}
                onChange={updateFramework("postissue_report_published")}
              />
            </FormItem>
          </FrameworkQuestion>

          <FrameworkQuestion
            number="6.5"
            title="Will the certification be obtained?"
          >
            <FormItem
              name="certification_unavailability"
              initialValue={framework.certification_unavailability}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <SwitchUnability
                defaultValue={framework.certification_unavailability}
                onChange={updateFramework("certification_unavailability")}
              >
                <FrameworkSection>
                  <FrameworkQuestion
                    number="6.5.1"
                    title="How will the certification be made available?"
                  >
                    <FormItem
                      name="certification_published"
                      initialValue={framework.certification_published}
                      rules={[{ required: true, message: "Field is required" }]}
                    >
                      <CheckboxGroupPublish
                        defaultValue={framework.certification_published || []}
                        onChange={updateFramework("certification_published")}
                      />
                    </FormItem>
                  </FrameworkQuestion>
                </FrameworkSection>
              </SwitchUnability>
            </FormItem>
          </FrameworkQuestion>
        </>
      )}
      {framework.issuance === "No" && (
        <FrameworkQuestion
          number="6.1"
          title="Will any external review be provided?"
        >
          <FormItem
            name="external_review_unavailability"
            initialValue={framework.external_review_unavailability}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <SwitchUnability
              onChange={updateFramework("external_review_unavailability")}
              defaultValue={framework.external_review_unavailability}
            >
              <FrameworkSection>
                <FrameworkQuestion
                  number="6.1.1"
                  title="Who will provide the external review?"
                >
                  <FormItem
                    name="external_reviewer"
                    initialValue={framework.external_reviewer}
                    rules={[{ required: true, message: "Field is required" }]}
                  >
                    <Input
                      defaultValue={framework.external_reviewer}
                      onBlur={adapterDOMEvent(
                        updateFramework("external_reviewer")
                      )}
                    />
                  </FormItem>
                </FrameworkQuestion>

                {framework.external_reviewer && (
                  <FrameworkQuestion
                    number="6.1.2"
                    title="How will the external review be made available?"
                  >
                    <FormItem
                      name="external_review_published"
                      initialValue={framework.external_review_published}
                      rules={[{ required: true, message: "Field is required" }]}
                    >
                      <CheckboxGroupPublish
                        defaultValue={framework.external_review_published || []}
                        onChange={updateFramework("external_review_published")}
                      />
                    </FormItem>
                  </FrameworkQuestion>
                )}
              </FrameworkSection>
            </SwitchUnability>
          </FormItem>
        </FrameworkQuestion>
      )}
    </FrameworkSection>
  </>
);

export default ExternalReview;
