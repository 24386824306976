import React, { useState } from "react";
import FromCloudForm from "./FromCloudForm";
import { FILE_TYPES } from "./types";

const formatFromCloudFile = (file) => ({
  uid: file.uid,
  data: {
    docId: file.data.docId,
    url: file.data.url,
    name: file.data.name,
  },
  type: FILE_TYPES.EXTERNAL,
  status: "done",
});

const FromCloudFormConnected = ({ onCreateDoc }) => {
  const [files, setFiles] = useState([]);

  const onAddFile = (file) => {
    setFiles([...files, file]);
    const formattedFile = formatFromCloudFile(file);
    onCreateDoc({
      ...formattedFile,
      createdDate: new Date().toISOString(),
    });
  };

  return <FromCloudForm onAdd={onAddFile} />;
};

export default FromCloudFormConnected;
