const finParams = {
  turnover: "Turnover",
  capex: "CapEx",
  opex: "OpEx",
};

export const finParamsReversed = Object.fromEntries(
  Object.entries(finParams).map(([key, value]) => [value, key])
);

export default finParams;
