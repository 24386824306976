import React from "react";
import { ArrowsAltOutlined } from "@ant-design/icons";
import styles from "./FullMapButton.module.less";
import { Text } from "../../antd-ovveride";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";

const FullMapButton = ({ onClick }) => (
  <div
    className={styles.fullMapTriggerButton}
    onClick={onClick}
    onKeyDown={onKeyDownDecorator(onClick)}
    tabIndex="-1"
    role="button"
  >
    <ArrowsAltOutlined className={styles.icon} />
    <Text size="small" type="secondary">
      Open map to see project info and risks
    </Text>
  </div>
);

export default FullMapButton;
