const adapterOptions = (options, fromServer = false) =>
  options?.map((option) => ({
    ...option,
    label: option.name,
    value: fromServer ? option.id : option.value || option.name,
  })) || [];

export const revertAdapterOptions = (options) =>
  options?.map((option) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.specific) {
      return option.specific;
    }
    return option.id;
  }) || [];

export default adapterOptions;
