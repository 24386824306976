import React, { useMemo, useState } from "react";
import { Table } from "antd";
import { Badge } from "@evercityecosystem/evercity-ui";
import {
  renderActionsCell,
  renderCodeCell,
  renderFinParamCell,
  renderNameCell,
  renderObjectiveCell,
} from "./renderCells";

import {
  finStatsSorter,
  nameSorter,
  objectiveTypeSorter,
  statusSorter,
} from "./sorts";

import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";
import { RECORD_TYPE } from "../ActivitiesTable/types";
import styles from "./ActivitiesPageTable.module.less";
import buildTableRowsData from "./buildTableRowsData";
import StatusTag from "../StatusTag/StatusTag";
import { getStatusText } from "../../helpers/reportStatuses";

const { Column } = Table;

const getBadge = (issues) => {
  if (issues && issues.open > 0) {
    return (
      <Badge style={{ color: "#FF4D4F" }} type="outlined" count={issues.open} />
    );
  }

  if (issues && issues.submitted > 0) {
    return (
      <Badge
        style={{ color: "#FAAD14" }}
        type="outlined"
        count={issues.submitted}
      />
    );
  }
  return null;
};

const ActivitiesPageTable = ({
  actReports,
  onDeleteActivity,
  onDeleteUnit,
  onAddUnit,
  onAddSubunit,
  onUnitClick,
  onEditAcitivityNaceCodes,
  onChangeObjective,
  reportCurrency,
}) => {
  const [expandedRows, setExpandedRow] = useState([]);
  const tableRows = useMemo(() => buildTableRowsData(actReports), [actReports]);

  const onRowClick = (record) => {
    switch (record.keyType) {
      case RECORD_TYPE.UNIT:
      case RECORD_TYPE.SUBUNIT:
        onUnitClick?.(record.element.id);
        break;
      default:
        break;
    }
  };

  const getRowClassName = (record) => {
    switch (record.keyType) {
      case RECORD_TYPE.ACTIVITY:
        return styles.activityRow;
      case RECORD_TYPE.UNIT:
        return styles.unitRow;
      case RECORD_TYPE.SUBUNIT:
      default:
        return styles.subunitRow;
    }
  };

  const statusRender = (status, { issues }) => (
    <StatusTag
      type={status}
      text={getStatusText(status)}
      badge={getBadge(issues)}
    />
  );

  return (
    <ActivitiesTable
      tableRows={tableRows}
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
      expandedRows={expandedRows}
      onExpandedRowsChange={setExpandedRow}
      getRowClassName={getRowClassName}
    >
      <Column
        title="Activity name"
        dataIndex="name"
        key="name"
        render={renderNameCell(expandedRows, setExpandedRow)}
        sorter={nameSorter}
        customIndents
        className={styles.remainedWidthColumn}
      />
      <Column
        title="NACE code"
        dataIndex="code"
        key="code"
        render={renderCodeCell}
      />
      <Column
        title="Objective"
        dataIndex="objective"
        key="objective"
        width={120}
        render={renderObjectiveCell}
        sorter={objectiveTypeSorter}
      />
      <Column
        title={
          <span>
            <strong>Turnover</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="turnover"
        key="turnover"
        render={renderFinParamCell}
        sorter={finStatsSorter("turnover")}
      />
      <Column
        title={
          <span>
            <strong>CapEx</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="capex"
        key="capex"
        render={renderFinParamCell}
        sorter={finStatsSorter("capex")}
      />
      <Column
        title={
          <span>
            <strong>OpEx</strong>, {reportCurrency}, mln
          </span>
        }
        dataIndex="opex"
        key="opex"
        render={renderFinParamCell}
        sorter={finStatsSorter("opex")}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={statusRender}
        sorter={statusSorter}
      />
      <Column
        title=""
        dataIndex=""
        key="actions"
        render={renderActionsCell({
          onDeleteActivity,
          onDeleteUnit,
          onAddUnit,
          onAddSubunit,
          onEditAcitivityNaceCodes,
          onChangeObjective,
        })}
        // fixed="right"
      />
    </ActivitiesTable>
  );
};

export default ActivitiesPageTable;
