import React from "react";
import {
  NavigationTabs,
  Space,
  Avatar,
  Button,
  UsergroupAddOutlined,
} from "@evercityecosystem/evercity-ui";
import useCalcProgress from "../../hooks/useCalcProgress";
import validationSchema from "./validationSchema";
import useCalcTotalProgress from "../../hooks/useCalcTotalProgress";
import { MESSENGER_GROUPS } from "../../data/messenger";
import useThreadAdaptForTabs from "../../hooks/messenger/useThreadAdapteForTabs";
import { useCurrentUser } from "../../hooks/user";

const tabs = [
  {
    label: "Summary",
    path: "summary",
    key: MESSENGER_GROUPS.PIN.SUMMARY,
  },
  {
    label: "Details",
    path: "details",
    key: MESSENGER_GROUPS.PIN.DETAILS,
  },
  {
    label: "GHG emissions",
    path: "emission",
    key: MESSENGER_GROUPS.PIN.EMISSION,
  },
  {
    label: "Co-benefits",
    path: "benefits",
    key: MESSENGER_GROUPS.PIN.BENEFITS,
  },
  {
    label: "Viability",
    path: "viability",
    key: MESSENGER_GROUPS.PIN.VIABILITY,
  },
];

const ProjectIdeaNoteHeader = ({ threads, pin, onCreatePin, loading }) => {
  const [userState] = useCurrentUser();
  const companyConfig = userState?.data?.company?.settings;

  const withoutThreshold = companyConfig?.PIN?.threshold === "false";
  const progress = useCalcProgress({
    steps: validationSchema,
    data: pin?.data,
  });
  const threadsAdapted = useThreadAdaptForTabs(threads, loading);

  const navtabs = NavigationTabs.useFillStatus({
    tabs,
    data: threadsAdapted,
    progress,
  });

  const totalProgress = useCalcTotalProgress({
    stepsSchema: validationSchema,
    data: pin?.data,
  });

  return (
    <>
      <NavigationTabs items={navtabs} stochastic />
      <Space>
        <Avatar.Group>
          <Avatar />
          <Avatar />
        </Avatar.Group>
        <Button icon={<UsergroupAddOutlined />}>Add</Button>
      </Space>

      <Space>
        <Button
          onClick={() => onCreatePin()}
          disabled={
            (!withoutThreshold && totalProgress < 70) ||
            pin?.status === "completed"
          }
          type="primary"
        >
          Create
        </Button>
      </Space>
    </>
  );
};

export default ProjectIdeaNoteHeader;
