import { useMutation } from "urql";

const useSetRelation = (mutationSet, mutationDelete, mainEntityId) => {
  const [addResponse, addRelation] = useMutation(mutationSet);
  const [deleteResponse, deleteRelation] = useMutation(mutationDelete);

  const setRelation = (propertyId, isDeleteOption, options) => {
    if (isDeleteOption) {
      deleteRelation({
        mainEntityId,
        propertyId,
      });

      return;
    }
    addRelation({
      mainEntityId,
      propertyId,
      ...options,
    });
  };

  const setSpecific = (propertyId, specific) => {
    if (!specific) {
      deleteRelation({
        mainEntityId,
        propertyId,
      });
      return;
    }
    addRelation({
      mainEntityId,
      propertyId,
      specific,
    });
  };

  return {
    actions: [setRelation, setSpecific],
    response: [addResponse, deleteResponse],
  };
};

export default useSetRelation;
