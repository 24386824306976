import React, { useState } from "react";
import { Row, Col, Typography, Space } from "antd";
import { FileAddOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { ContentLayout, Button } from "@evercityecosystem/evercity-ui";
import styles from "./ReportsPage.module.less";
import ReportsTableConnected from "../ReportsTable/ReportsTableConnected";
import InlineNote from "../InlineNote/InlineNote";
import EntryModal from "../EntryModal/EntryModal";
import InviteModal from "../InviteModal/InviteModal";
import useModal from "../../hooks/useModal";
import useGenerateInviteLink from "../../hooks/user/useGenerateInviteLink";
import LkLayout from "../LkLayout/LkLayout";
import WatchTutorialBtn from "../WatchTutorialBtn/WatchTutorialBtn";

const { Text } = Typography;

const ReportsPage = ({ company, sampleReport }) => {
  const [entryModalVisible, setEntryModalVisible] = useState(false);
  const navigate = useNavigate();
  const { show, Modal, close } = useModal(false);
  const { generate, inviteLink } = useGenerateInviteLink("client");

  const onInvite = () => {
    generate().then(() => {
      show();
    });
  };

  const onOk = () => {
    close();
  };

  return (
    <LkLayout header={{ tag: <WatchTutorialBtn startTime={0.01} /> }}>
      <ContentLayout gaps="off">
        <Row justify="space-between" className={styles.header}>
          <Col>
            <Space direction="vertical" size={20}>
              <Text>Add and manage your company’s EU Taxonomy reports.</Text>
            </Space>
          </Col>
          <Col>
            {company?.type === "client" && (
              <Button
                data-cy="taxonomy-new-report"
                type="primary"
                icon={<FileAddOutlined />}
                size="large"
                onClick={() => navigate("/company/taxonomy/report/_/")}
              >
                New report
              </Button>
            )}
            {company?.type === "consultant" && (
              <Button
                onClick={onInvite}
                type="primary"
                icon={<PlusCircleOutlined />}
                size="large"
              >
                Onboard client
              </Button>
            )}
          </Col>
        </Row>

        <InlineNote bodyPaddingBottom={20} style={{ marginTop: "20px" }}>
          In case of any difficulty please do the following steps:
          <ol>
            <li>
              Watch a{" "}
              <Button
                onClick={() => setEntryModalVisible(true)}
                type="link"
                style={{ padding: 0, height: "auto" }}
              >
                demo video
              </Button>{" "}
              which will walk you through the screening process
            </li>
            <li>
              Study a{" "}
              <Link to={`/company/taxonomy/report/${sampleReport?.id}`}>
                sample company report
              </Link>{" "}
              available on the reports page
            </li>
            <li>
              Send us an e-mail to{" "}
              <a href="mailto: info@evercity.io">info@evercity.io</a>
            </li>
          </ol>
        </InlineNote>
        <ReportsTableConnected />
        <EntryModal
          visible={entryModalVisible}
          onHide={() => setEntryModalVisible(false)}
          autoplay
          closable
        />
        <InviteModal inviteLink={inviteLink} handleOk={onOk} Modal={Modal} />
      </ContentLayout>
    </LkLayout>
  );
};

export default ReportsPage;
