import React from "react";

import Grid from "../CatalogCardsGrid/CatalogCardsGrid";
import CatalogCardSwitcher from "../CatalogCards/CatalogCardSwitcher";

const CardsList = ({ list, type }) => (
  <Grid>
    {list?.map((card) => (
      <CatalogCardSwitcher data={card} key={card.id} type={type} />
    ))}
  </Grid>
);

const CatalogCardsLis = ({ items, type }) => (
  <CardsList list={items} type={type} />
);

export default CatalogCardsLis;
