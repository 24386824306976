import cn from "classnames";
import React from "react";
import { string, oneOf } from "prop-types";
import { Typography } from "antd";

import styles from "./Marker.module.less";

const { Text } = Typography;

const Marker = ({ color, label, size }) => (
  <div className={styles.grid}>
    <span className={styles.marker} style={{ backgroundColor: color }} />
    <Text className={cn(styles.text, { [styles[`text--${size}`]]: size })}>
      {label}
    </Text>
  </div>
);

Marker.propTypes = {
  color: string,
  label: string.isRequired,
  size: oneOf(["small", "normal", "big"]),
};

Marker.defaultProps = {
  color: "#D9D9D9",
  size: "normal",
};

export default Marker;
