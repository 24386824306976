import React, { useState } from "react";
import FrameworkCreatePage from "./FrameworkCreatePage";
import { useCreateFramework } from "../../hooks/framework";

const FrameworkCreatePageConnected = () => {
  const { createFramework } = useCreateFramework();
  const [uploadedFrameworks, setUploadedFrameworks] = useState([]);
  return (
    <FrameworkCreatePage
      createFramework={createFramework}
      uploadedFrameworks={uploadedFrameworks}
      setUploadedFrameworks={setUploadedFrameworks}
    />
  );
};

export default FrameworkCreatePageConnected;
