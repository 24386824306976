import React from "react";
import { Checkbox } from "antd";
import classnames from "classnames";
import styles from "./Checkbox.module.less";
import adapterOptions from "../../helpers/adapterOptions";
import CheckboxGroupOption from "./CheckboxGroupOption";

const CheckboxGroup = ({
  options,
  onChange,
  onChangeSpecific,
  defaultValue,
  direction = "vertical",
  defaultSpecific,
  fromServer = false,
  ...props
}) => (
  <Checkbox.Group
    onChange={!fromServer && onChange}
    defaultValue={defaultValue}
    className={classnames(styles.list, styles[`list--${direction}`])}
    {...props}
  >
    {adapterOptions(options, fromServer).map((option) => (
      <CheckboxGroupOption
        onChange={onChange}
        onChangeSpecific={onChangeSpecific}
        defaultValue={defaultValue}
        defaultSpecific={defaultSpecific}
        option={option}
        fromServer={fromServer}
      />
    ))}
  </Checkbox.Group>
);

export default CheckboxGroup;
