export default {
  doubleActivities: {
    60: {
      suggestion:
        'We suggest that you create a separate business unit for trains and passenger coaches which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for trains and passenger coaches which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for trains and passenger coaches which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity and help you improve your results.',
    },
    89: {
      suggestion:
        'We suggest that you create a separate business unit for trains and wagons which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for trains and wagons which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for trains and wagons which have zero direct (tailpipe) emissions (when operated on a track without special infrastructure). It will be calculated as a "green" part of the activity and help you improve your results',
    },
    61: {
      suggestion:
        'We suggest that you create a separate business unit for urban or suburban vehicles with zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for urban or suburban vehicles with zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for urban or suburban vehicles with zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
    },
    65: {
      suggestion:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
    },
    66: {
      suggestion:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
    },
    68: {
      suggestion:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
    },
    69: {
      suggestion:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity.',
      aligned:
        'However, we suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
      not_aligned:
        'We suggest that you create a separate business unit for vessels which have zero direct (tailpipe) emissions. It will be calculated as a "green" part of the activity and help you improve your results.',
    },
  },
};
