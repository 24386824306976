import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import styles from "./MapButton.module.less";

const MapButton = ({ onClick, icon, size, className }) => (
  <button
    type="button"
    size="large"
    onClick={onClick}
    className={cs(styles.mapButton, styles[`mapButton--${size}`], className)}
  >
    {icon}
  </button>
);

MapButton.propTypes = {
  size: PropTypes.oneOf(["default", "small"]),
};
MapButton.defaultProps = {
  size: "default",
};

export default MapButton;
