import React from "react";
import classnames from "classnames";
import { Link as BaseLink } from "react-router-dom";
import styles from "./Link.module.less";

const Link = ({
  className,
  size,
  type,
  noMargin,
  icon,
  children,
  ...props
}) => (
  <BaseLink
    className={classnames(
      className,
      noMargin && styles["link--noMargin"],
      styles[`link--size-${size}`],
      styles[`link--theme-${type}`]
    )}
    {...props}
  >
    {icon && (
      <span className={styles.icon} role="img">
        {icon}
      </span>
    )}
    <span>{children}</span>
  </BaseLink>
);

export default Link;
