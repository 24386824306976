import { useCallback, useMemo } from "react";
import useGetThreads from "../messenger/useGetThreads";
import { MESSENGER_ENTITIES } from "../../data/messenger";

const useActReportsWithIssues = (actReports) => {
  const [threadsResponse] = useGetThreads({
    entity: MESSENGER_ENTITIES.TAXONOMY_UNIT,
  });

  const unitsThreads = threadsResponse.dataRaw;

  const addIssuesInfoToUnit = useCallback(
    (unit) => {
      const unitThreads =
        unitsThreads?.filter((thread) => thread.config.entityId === unit.id) ||
        [];
      const unitIssues = unitThreads.reduce(
        (acc, thread) => ({
          ...acc,
          [thread.thread_state.name]: (acc[thread.thread_state.name] || 0) + 1,
        }),
        {}
      );
      const subunits = unit.subunits.map(addIssuesInfoToUnit);

      const nestedIssues = subunits.reduce((acc, subunit) => {
        const result = { ...acc };
        Object.entries(subunit.issues || {}).forEach(([state, count]) => {
          result[state] = (result[state] || 0) + count;
        });
        return result;
      }, {});

      const unitWithNestedIssues = Object.entries(nestedIssues).reduce(
        (acc, [state, count]) => {
          const result = { ...acc };

          result[state] = (result[state] || 0) + count;

          return result;
        },
        unitIssues
      );

      return {
        ...unit,
        subunits,
        issues: unitWithNestedIssues,
      };
    },
    [unitsThreads]
  );

  return useMemo(
    () =>
      actReports?.map((actReport) => {
        const unitsWithIssues = actReport.units.map(addIssuesInfoToUnit);

        const activityIssues = unitsWithIssues.reduce((acc, unit) => {
          const result = { ...acc };
          Object.entries(unit.issues).forEach(([state, count]) => {
            result[state] = (result[state] || 0) + count;
          });
          return result;
        }, {});

        return {
          ...actReport,
          issues: activityIssues,
          units: unitsWithIssues,
        };
      }),
    [actReports, addIssuesInfoToUnit]
  );
};

export default useActReportsWithIssues;
