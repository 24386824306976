import React from "react";
import { useCurrentUser } from "../../hooks/user";
import Error404 from "../errors/Error404";

const AccessProvider = ({ children, exclude, service }) => {
  const [{ company }] = useCurrentUser();
  const companySettings = company?.settings;

  if (service && companySettings?.restrictedServices?.includes(service)) {
    return <Error404 />;
  }

  if (exclude.includes(company?.type)) {
    return <Error404 />;
  }
  return children;
};

export default AccessProvider;
