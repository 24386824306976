import React from "react";
import { Tag } from "@evercityecosystem/evercity-ui";

const StyledTag = ({ children, color }) => (
  <Tag
    type="outlined"
    style={{
      borderColor: color,
      color,
      lineHeight: "0.9rem",
    }}
  >
    {children}
  </Tag>
);

export default StyledTag;
