import React from "react";
import { Text } from "@antd-ovveride";
import { Space, Row, Col } from "antd";

const FinStatsBase = ({ stats }) => (
  <Row justify="space-between">
    {stats.map(({ key, value }) => (
      <Col key={key}>
        <Space size={2} direction="vertical" align="center">
          <Text type="secondary">{key}</Text>
          <Text size="large">{value}</Text>
        </Space>
      </Col>
    ))}
  </Row>
);

export default FinStatsBase;
