import React, { useMemo, useState } from "react";

import { Row, Col, Space, Switch, Typography, Empty } from "antd";

import ActivitiesChart from "../../../Charts/ActivitiesChart";
import Legend from "../../../Charts/Legend";
import LegendList from "../../../Charts/LegendList";
import { getChartData, getLegend } from "./lib";

const { Title, Text } = Typography;

const AllReports = ({ chartData, legend }) => (
  <>
    <Row>
      {chartData?.map(({ name, values }) => (
        <Col span={8} key={name}>
          <ActivitiesChart data={values} showTitle title={name} />
        </Col>
      ))}
    </Row>
    <Row style={{ marginTop: 30 }} justify="space-between">
      <Legend items={legend} />
    </Row>
  </>
);

const SingleReport = ({ chartData, legend, aligned }) => (
  <Row gutter={[32, 32]}>
    <Col span={8} style={{ position: "relative", top: -20 }}>
      {chartData?.map(({ name, values }) => (
        <ActivitiesChart aligned={aligned} data={values} title={name} />
      ))}
    </Col>
    <Col span={15}>
      <LegendList legend={legend} />
    </Col>
  </Row>
);

const ScreeningOverview = ({ finParamNames, actReports }) => {
  const [showAligned, setShowAligned] = useState(false);

  const isSingleChart = finParamNames.length === 1;

  const legend = useMemo(
    () =>
      !!actReports.length && getLegend(actReports, finParamNames, showAligned),
    [actReports, showAligned, finParamNames]
  );

  const chartData = useMemo(
    () =>
      !!actReports.length &&
      getChartData(actReports, finParamNames, showAligned),
    [actReports, finParamNames, showAligned]
  );

  const chartsContent = isSingleChart ? (
    <SingleReport aligned={showAligned} chartData={chartData} legend={legend} />
  ) : (
    <AllReports aligned={showAligned} chartData={chartData} legend={legend} />
  );

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24} align={isSingleChart ? "left" : "middle"}>
          <Space align="center" size={35}>
            <Title style={{ margin: 0 }} level={4}>
              Screening overview
            </Title>
            <Space>
              <Switch onChange={(value) => setShowAligned(value)} />
              <Text>
                {showAligned ? "aligned activities" : "eligible activities"}
              </Text>
            </Space>
          </Space>
        </Col>
      </Row>
      {!!legend && !!chartData ? chartsContent : <Empty />}
    </>
  );
};

export default ScreeningOverview;
