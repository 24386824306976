import React from "react";
import { Row, Col } from "antd";

const ContentTwoColsLayout = ({ aside, asideProps, children }) => (
  <Row wrap={false}>
    <Col flex="auto">{children}</Col>
    {aside && (
      <Col offset={1} flex="314px" {...asideProps}>
        {aside}
      </Col>
    )}
  </Row>
);

export default ContentTwoColsLayout;
