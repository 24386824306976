import React, { useContext, useEffect } from "react";
import {
  Form,
  Radio,
  Space,
  useThreads,
  ThreadProvider,
} from "@evercityecosystem/evercity-ui";
import cs from "classnames";
import { WarningContext } from "../WarningModal/WarningProvider";
import warnings from "../../data/warnings";
import { Text } from "../../antd-ovveride";
import styles from "./ConditionalPoint.module.less";

const { DiscussedItem: FormItem } = Form;
const { Group: RadioGroup, Button: RadioButton } = Radio;

const AffectivePoint = ({
  text,
  form,
  point,
  discussions,
  "data-topic": dataTopic,
}) => {
  const { id, decision } = point;
  const { showWarning } = useContext(WarningContext);
  const threads = useThreads(discussions);

  const onChange = ({ id: value }) => {
    if (value === "some") {
      const hint = warnings.doubleActivities[point.activity_id]?.suggestion;
      if (hint) {
        showWarning(hint);
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      [`point-${id}`]: decision,
    });
  }, [form, decision, id]);

  return (
    <ThreadProvider
      openCount={discussions?.open?.count || 0}
      submittedCount={discussions?.submitted?.count || 0}
    >
      <Space size={8} direction="vertical" data-topic={dataTopic}>
        <Text size="large">
          {text} {threads}
        </Text>
        <Space size={20}>
          <FormItem
            getValueFromEvent={(value) => value.id}
            getValueProps={(value) => ({ value: { id: value } })}
            name={`point-${point.id}`}
            rules={[{ required: true, message: "Please select an option" }]}
            style={{ marginBottom: 0 }}
            className={styles.errorMessageAbsolute}
          >
            <RadioGroup onChange={onChange} direction="horizontal">
              <RadioButton
                value="yes"
                className={cs(
                  styles.radioButton,
                  styles["radioButton--success"]
                )}
              >
                Yes, all
              </RadioButton>
              <RadioButton
                value="some"
                className={cs(
                  styles.radioButton,
                  styles["radioButton--rejected"]
                )}
              >
                Yes, some
              </RadioButton>
              <RadioButton
                value="no"
                className={cs(
                  styles.radioButton,
                  styles["radioButton--rejected"]
                )}
              >
                No
              </RadioButton>
            </RadioGroup>
          </FormItem>
        </Space>
      </Space>
    </ThreadProvider>
  );
};

export default AffectivePoint;
