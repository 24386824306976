import React from "react";
import { useOutletContext } from "react-router-dom";
import DebtPage from "./DebtPage";

const BondPageConnected = () => {
  const { debt } = useOutletContext();

  return <DebtPage debt={debt} />;
};

export default BondPageConnected;
