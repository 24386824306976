import React, { useState } from "react";
import { useMutation, useQuery, useSubscription } from "urql";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import ActivitiesPageTable from "./ActivitiesPageTable";

import {
  getActivitiesReportsSubscription,
  getUnitsByReportSubscription,
} from "../../api/subscriptions";

import {
  deleteActivityReportMutation,
  deleteUnitMutation,
  updateUnitMutation,
} from "../../api/mutations";

import useActReportsWithUnits from "../../hooks/taxonomy/useActReportsWithUnits";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import EditNaceCodesDrawerConnected from "./EditNaceCodesDrawerConnected";
import { getReportQuery } from "../../api/queries";
import useActReportsWithIssues from "../../hooks/taxonomy/useActReportsWithIssues";

const ActivitiesPageTableConnected = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [editableActReport, setEditableActReport] = useState();
  const [reportResponse] = useQuery({
    query: getReportQuery,
    variables: {
      id: reportId,
    },
  });
  const [activitiesReportsResponse] = useSubscription({
    query: getActivitiesReportsSubscription,
    variables: { reportId },
  });
  const activitiesReports =
    activitiesReportsResponse.data?.eu_taxonomy_activities_reports;
  const report = reportResponse.data?.eu_taxonomy_reports_by_pk;

  const [, deleteActivityReport] = useMutation(deleteActivityReportMutation);
  const [, deleteUnit] = useMutation(deleteUnitMutation);
  const [, updateUnit] = useMutation(updateUnitMutation);

  const [unitsResponse] = useSubscription({
    query: getUnitsByReportSubscription,
    variables: { reportId },
  });

  const units = unitsResponse.data?.eu_taxonomy_units;

  const actReportsWithUnits = useActReportsWithUnits(activitiesReports, units);

  const actReportsWithIssues = useActReportsWithIssues(actReportsWithUnits);

  const onDeleteActivity = async (actReportId) => {
    const actReport = activitiesReports.find((r) => r.id === actReportId);

    Modal.confirm({
      title: "Delete activity",
      icon: <ExclamationCircleOutlined />,
      content:
        "You’re going to delete activity, this action cannot be canceled. Do you want to continue?",
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      onOk: async (close) => {
        await deleteActivityReport({
          id: actReport.id,
        });
        close();
      },
    });
  };

  const onDeleteUnit = async (unitId) => {
    const unit = units.find((u) => u.id === unitId);
    const isSubunit = !!unit.parent_id;

    Modal.confirm({
      title: "Delete unit",
      icon: <ExclamationCircleOutlined />,
      content: `You’re going to delete ${
        isSubunit ? "subunit" : "unit"
      }, this action cannot be canceled. Do you want to continue?`,
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      onOk: async (close) => {
        await deleteUnit({ id: unit.id });
        close();
      },
    });
  };

  const onChangeObjective = async (unitId) => {
    const unit = units.find((u) => u.id === unitId);
    const isSubunit = !!unit.parent_id;

    Modal.confirm({
      title: "Change unit objective",
      icon: <InfoCircleOutlined />,
      content: `What objective will be used for this ${
        isSubunit ? "subunit" : "unit"
      }?`,
      okText: "Adaptation",
      okType: "default",
      cancelText: "Mitigation",
      mask: false,
      onOk: async (close) => {
        await updateUnit({
          unitId: unit.id,
          changes: {
            objective: "adaptation",
          },
        });
        close();
      },
      onCancel: async (close) => {
        await updateUnit({
          unitId: unit.id,
          changes: {
            objective: "mitigation",
          },
        });
        close();
      },
    });
  };

  const onUnitClick = (unitId) => {
    const unit = units.find((u) => u.id === unitId);
    const actReport = actReportsWithIssues.find(
      (ar) => ar.id === unit.activity_report_id
    );
    const parentId = unit.parent_id || "_";
    navigate(
      `/company/taxonomy/unit/${reportId}/${actReport.id}/${actReport.activity.id}/${parentId}/${unitId}`
    );
  };

  const onAddUnit = (actReportId) => {
    const actReport = activitiesReports.find((r) => r.id === actReportId);
    navigate(
      `/company/taxonomy/unit/${reportId}/${actReport.id}/${actReport.activity.id}/_/_`
    );
  };
  const onAddSubunit = (unitId) => {
    const actReport = actReportsWithIssues.find((ar) =>
      ar.units?.find((u) => u.id === unitId)
    );
    if (!actReport) return;
    const unit = units.find((u) => u.id === unitId);
    navigate(
      `/company/taxonomy/unit/${reportId}/${actReport.id}/${actReport.activity.id}/${unit.id}/_`
    );
  };

  const onEditAcitivityNaceCodes = (actReportId) => {
    setEditableActReport(actReportId);
  };

  const fetching = unitsResponse.fetching || activitiesReportsResponse.fetching;
  const error = unitsResponse.error || activitiesReportsResponse.error;

  if (error) {
    console.error(error);
  }

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }

  return actReportsWithIssues?.length ? (
    <>
      <ActivitiesPageTable
        reportCurrency={report?.currency}
        actReports={actReportsWithIssues}
        onDeleteActivity={onDeleteActivity}
        onDeleteUnit={onDeleteUnit}
        onUnitClick={onUnitClick}
        onAddUnit={onAddUnit}
        onAddSubunit={onAddSubunit}
        onChangeObjective={onChangeObjective}
        onEditAcitivityNaceCodes={onEditAcitivityNaceCodes}
      />
      <EditNaceCodesDrawerConnected
        visible={!!editableActReport}
        actReportId={editableActReport}
        onClose={() => setEditableActReport(null)}
      />
    </>
  ) : (
    <div />
  );
};
export default ActivitiesPageTableConnected;
