import React from "react";
import reportFinParams from "../../data/reportCurrencyParameters";
import ReportPdfTable from "../ReportPdfTable/ReportPdfTable";
import { Space } from "../../antd-ovveride";

const ReportPdf = ({ actReports, report, pagination }) => (
  <Space size={30} direction="vertical">
    <div key={pagination} style={{ display: "inline-flex" }}>
      <ReportPdfTable
        finParamName={Object.keys(reportFinParams)[pagination - 1]}
        actReports={actReports}
        report={report}
        company={report.company}
        paginationIndex={pagination}
        paginationTotal={Object.keys(reportFinParams).length}
      />
    </div>
  </Space>
);

export default ReportPdf;
