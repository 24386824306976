import React from "react";
import cs from "classnames";
import styles from "./HeaderCell.module.less";

const HeaderCell = ({ children, measure, horizontal, theme }) => (
  <div
    className={cs(
      styles.headerCell,
      horizontal && styles["headerCell--horizontal"],
      theme && styles[`headerCell--theme-${theme}`]
    )}
  >
    <span className={styles.headerCell__title}>{children}</span>
    <span className={styles.headerCell__measure}>{measure}</span>
  </div>
);

export default HeaderCell;
