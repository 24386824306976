import React from "react";
import { Row, Col } from "antd";
import cn from "classnames";

import Marker from "./Marker";

import { decimalToPercents } from "../../helpers/percents";

import tooltipStyles from "./Tooltip.module.less";
import styles from "./SingleLegend.module.less";

const SingleLegend = ({ payload, reverse = false }) => {
  const listCN = cn(tooltipStyles.list, {
    [tooltipStyles.listReverse]: reverse,
  });

  return (
    <div className={styles.container}>
      <ul className={listCN}>
        {payload?.map((item) => (
          <li key={item.dataKey} className={styles.listItem}>
            <Row justify="space-between">
              <Col span={12}>
                <Marker color={item.color} label={item.value} />
              </Col>
              <Col span={12} align="right">
                {decimalToPercents(item.payload.value)}
              </Col>
            </Row>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SingleLegend;
