import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "./user";
import { getData } from "../helpers/jwt";

const useJivoChat = () => {
  const [userState, refetch] = useCurrentUser();
  const [isMounted, setIsMounted] = useState(false);

  const changeStorageCallback = useCallback(
    (e) => {
      if (e.key === "access-token" && e.newValue && window.jivo_init) {
        refetch();
      }
      if (e.key === "access-token" && !e.newValue && window.jivo_destroy) {
        window.jivo_destroy();
        setIsMounted(false);
      }
    },
    [refetch, setIsMounted]
  );

  useEffect(() => {
    const { userId } = getData();
    const { data: userInfo } = userState;
    if (userId && userInfo?.id && window.jivo_init && !isMounted) {
      setIsMounted(true);
      setTimeout(() => {
        window.jivo_init();
      }, 5000);
    }

    if (window.jivo_api) {
      window.jivo_api.setContactInfo({
        name: userInfo.display_name,
        email: userInfo.account?.email,
      });
    }
  }, [userState, isMounted]);

  useEffect(() => {
    window.addEventListener("storage", changeStorageCallback);
    return () => window.removeEventListener("storage", changeStorageCallback);
  }, [changeStorageCallback]);
};

export default useJivoChat;
