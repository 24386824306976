import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import { Select } from "../../antd-ovveride";
import adapterOptions from "../../helpers/adapterOptions";
import PhrasesSeparator from "../PhrasesSeparator/PhrasesSeparator";
import MultiSelect from "../../antd-ovveride/Select/MultiSelect";
import styles from "./UseOfProceed.module.less";
import Goal from "../GoalsList/Goal";

const Category = ({
  categories,
  goals,
  defaultCategory,
  onSetCategory,
  setInfo,
  show,
  close,
  Modal,
  setCategoryId,
  setIsEdit,
  isEdit,
}) => {
  // todo переписать
  const [category, setCategory] = useState({
    sdgs: [],
    projectExamples: [],
    impactIndicators: [],
  });

  useEffect(() => {
    if (defaultCategory) {
      setCategory({
        sdgs: defaultCategory.goals.map((item) => item.goal.id),
        projectExamples: defaultCategory.project_examples,
        impactIndicators: defaultCategory.impact_indicators,
      });
    }
  }, [defaultCategory]);

  const saveDisable = useMemo(
    () => Object.values(category).some((value) => value?.length <= 0),
    [category]
  );

  return (
    <>
      <Button
        className={styles.category__add}
        size="large"
        onClick={() => {
          if (isEdit) {
            setCategoryId(null);
            setIsEdit(false);
          }
          show();
        }}
        type="dashed"
      >
        <PlusCircleOutlined /> Add category
      </Button>
      <Modal
        width={1028}
        okButton={
          <Button
            type="primary"
            disabled={saveDisable}
            onClick={() => {
              setInfo(
                category.sdgs.map((sdg) => ({
                  goal_id: sdg,
                  example_category_id: defaultCategory.id,
                })),
                {
                  projectExamples: category.projectExamples,
                  impactIndicators: category.impactIndicators,
                }
              );
              setCategory({});
              close();
            }}
          >
            Save
          </Button>
        }
        title="Add new category"
      >
        <FrameworkQuestion
          title="Which project category is included in the of proceeds?"
          border="bottom"
        >
          <Select
            disabled={isEdit}
            variant="secondary"
            className={styles.choose}
            onChange={(value) => onSetCategory(value)}
            defaultValue={
              defaultCategory &&
              categories.find(
                (_category) =>
                  _category.id === defaultCategory.example_category.id
              ).id
            }
            options={adapterOptions(categories, true)}
            placeholder="Select category"
          />
        </FrameworkQuestion>

        {defaultCategory && (
          <>
            <FrameworkQuestion title="What are the examples of eligible green projects?">
              <PhrasesSeparator
                placeholder="Example"
                defaultValue={defaultCategory?.project_examples}
                onChange={(value) =>
                  setCategory((_category) => ({
                    ..._category,
                    projectExamples: value,
                  }))
                }
              />
            </FrameworkQuestion>

            <FrameworkQuestion title="What are the impact indicators?">
              <PhrasesSeparator
                defaultValue={defaultCategory?.impact_indicators}
                onChange={(value) =>
                  setCategory((_category) => ({
                    ..._category,
                    impactIndicators: value,
                  }))
                }
              />
            </FrameworkQuestion>

            <FrameworkQuestion title="What are the relevant SDGs?">
              <MultiSelect
                variant="secondary"
                placeholder="Selected 4 SDGs"
                options={adapterOptions(goals, true).map((option) => ({
                  label: <Goal id={option.value} name={option.label} />,
                  value: option.value,
                }))}
                defaultValue={defaultCategory?.goals.map(
                  (item) => item.goal.id
                )}
                showSearch={false}
                onChange={(value) =>
                  setCategory((_category) => ({
                    ..._category,
                    sdgs: value,
                  }))
                }
              />
            </FrameworkQuestion>
          </>
        )}
      </Modal>
    </>
  );
};

export default Category;
