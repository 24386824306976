import React, { useMemo } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { useQuery, useMutation } from "urql";
import { Typography, notification } from "antd";
import differenceBy from "lodash.differenceby";
import { docToAttachedFile } from "../../helpers/docToAttachedFile";

import { getCompanyByPkQuery, getReportQuery } from "../../api/queries";
import {
  createReportMutation,
  updateReportMutation,
  updateCompanyMutation,
  attachDocToReportMutation,
  unattachDocFromReportMutation,
} from "../../api/mutations";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import CompanyInfoForm from "./CompanyInfoForm";

import { REPORT_STATUSES } from "../../helpers/reportStatuses";
import sampleCompany from "../../helpers/sampleCompany";

import { useCurrentUser } from "../../hooks/user";
import useGetThreads from "../../hooks/messenger/useGetThreads";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

const { Title } = Typography;
// Todo компания есть и в currentUserState и делается еще запрос по id
const CompanyInfoFormConnected = () => {
  const { onFinish, ...rest } = useOutletContext();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const [updateReportResponse, updateReport] =
    useMutation(updateReportMutation);
  const [createReportResponse, createReport] =
    useMutation(createReportMutation);

  const [currentUserState] = useCurrentUser();
  const { company_id: companyId } = currentUserState.data;

  const [updateCompanyResponse, updateCompany] = useMutation(
    updateCompanyMutation
  );

  const isNewReport = reportId === "_";

  const [reportResponse] = useQuery({
    query: getReportQuery,
    variables: { id: reportId },
    pause: isNewReport,
    requestPolicy: "network-only",
  });
  const report = useMemo(
    () => (isNewReport ? {} : reportResponse.data?.eu_taxonomy_reports_by_pk),
    [reportResponse.data, isNewReport]
  );

  const isSampleReport = report?.status === REPORT_STATUSES.sample;

  const [companyResponse] = useQuery({
    query: getCompanyByPkQuery,
    variables: { id: companyId },
  });
  const [threadsResponse] = useGetThreads(
    {
      entity: MESSENGER_ENTITIES.TAXONOMY_REPORT,
      entityId: reportId,
      group: MESSENGER_GROUPS.TAXONOMY_REPORT.INFO,
    },
    {
      pause: isNewReport,
    }
  );

  const originCompany = companyResponse.data?.companies_by_pk;
  const company = useMemo(() => {
    if (originCompany && isSampleReport) {
      return {
        id: originCompany.id,
        ...sampleCompany,
      };
    }
    return originCompany;
  }, [originCompany, isSampleReport]);

  const [attachDocResponse, attachDoc] = useMutation(attachDocToReportMutation);
  const [unattachDocResponse, unattachDoc] = useMutation(
    unattachDocFromReportMutation
  );

  const attachedDocs = useMemo(
    () =>
      report?.report_attachments?.map((at) => docToAttachedFile(at.doc)) || [],
    [report]
  );

  const fetching =
    reportResponse.fetching ||
    companyResponse.fetching ||
    threadsResponse.fetching;
  const responseError =
    reportResponse.error || companyResponse.error || threadsResponse.error;
  const seinding =
    updateReportResponse.fetching ||
    createReportResponse.fetching ||
    updateCompanyResponse.fetching ||
    attachDocResponse.fetching ||
    unattachDocResponse.fetching;

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }
  if (responseError) {
    return <Title level={3}>Not Found</Title>;
  }

  const attachDocs = async (id, docs) => {
    const attachDocPromises = docs.map((d) =>
      attachDoc({ doc_id: d.docId, report_id: id })
    );
    const responses = await Promise.all(attachDocPromises);
    if (responses.some((r) => r.error)) {
      throw new Error("Attach files error");
    }
  };

  const unattachDocs = async (id, docs) => {
    const promises = docs.map((d) =>
      unattachDoc({ doc_id: d.docId, report_id: id })
    );
    const responses = await Promise.all(promises);
    if (responses.some((r) => r.error)) {
      throw new Error("Unattach files error");
    }
  };

  const onValuesChange = async ({ docs = [] }) => {
    if (report.id) {
      const removedFiles = differenceBy(attachedDocs, docs, "docId");
      const addedFiles = differenceBy(docs, attachedDocs, "docId");
      // TODO: use single mutation with removeObjects and addedObjects
      if (addedFiles.length) {
        try {
          await attachDocs(report.id, addedFiles);
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Error attach files.",
            description: "Please try again later.",
          });
        }
      }
      if (removedFiles.length) {
        try {
          await unattachDocs(report.id, removedFiles);
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Error unattach files.",
            description: "Please try again later.",
          });
        }
      }
    }
  };

  const safeUpdateCompany = async (changes) => {
    if (!isSampleReport) {
      const res = await updateCompany({
        id: companyId,
        changes,
      });
      return res;
    }
    return {};
  };

  const submitNewReport = async (companyFields, reportFields, docs) => {
    const [updateCompanyRes, createReportRes] = await Promise.all([
      safeUpdateCompany(companyFields),
      createReport({
        report: {
          company_id: company.id,
          ...reportFields,
        },
      }),
    ]);
    if (updateCompanyRes.error || createReportRes.error) {
      throw new Error("create new report error");
    }
    const createdReport = createReportRes.data?.insert_eu_taxonomy_reports_one;
    if (docs?.length) {
      await attachDocs(createdReport.id, docs);
    }

    return createdReport;
  };

  const submitExistReport = async (companyFields, reportFields) => {
    const responses = await Promise.all([
      safeUpdateCompany(companyFields),
      updateReport({
        reportId,
        changes: reportFields,
      }),
    ]);
    if (responses.some((r) => r.error)) {
      throw new Error("update error");
    }
  };

  const onFinishHandler = async (values) => {
    const companyFields = {
      name: values.company_name,
      country: values.country,
      nfrd_csrd: values.nfrd_csrd === "yes",
      company_number: values.company_number,
      lei: values.lei,
      employee_num: values.employee_num,
    };
    const reportFields = {
      reporting_year: values.reporting_year,
      currency: values.currency,
      turnover: values.turnover,
      capex: values.capex,
      opex: values.opex,
    };
    const { docs } = values;

    if (isNewReport) {
      try {
        const createdReport = await submitNewReport(
          companyFields,
          reportFields,
          docs
        );
        if (createdReport.id) {
          navigate(`/company/taxonomy/report/${createdReport.id}/`, {
            state: {
              next: true,
            },
          });
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Error create report.",
          description: "Please try again later.",
        });
      }
    } else {
      try {
        await submitExistReport(companyFields, reportFields, docs);
        onFinish();
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Error update report.",
          description: "Please try again later.",
        });
      }
    }
  };

  return (
    <CompanyInfoForm
      {...rest}
      hideThreadsActions={isNewReport}
      threads={threadsResponse.data}
      report={report}
      attachedDocs={attachedDocs}
      company={company}
      onValuesChange={onValuesChange}
      onFinish={onFinishHandler}
      seinding={seinding}
    />
  );
};

export default CompanyInfoFormConnected;
