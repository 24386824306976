import React from "react";
import { CheckboxGroup } from "../../antd-ovveride";

const CheckboxGroupSpecify = ({ onChange, defaultValue, options }) => (
  <CheckboxGroup
    onChange={(values) => {
      const oldData = defaultValue?.reduce(
        (result, curr) => ({
          ...result,
          [curr.id]: curr.specific,
        }),
        {}
      );
      const newData = values.map((item) => ({
        id: item,
        specific: oldData && oldData[item],
      }));

      onChange(newData);
    }}
    onChangeSpecific={(field, value) => {
      onChange(
        defaultValue.map((current) => {
          if (current.id === field) {
            return {
              ...current,
              specific: value,
            };
          }
          return current;
        })
      );
    }}
    defaultValue={defaultValue?.map((item) => item.id)}
    defaultSpecific={defaultValue?.reduce(
      (result, current) => ({ ...result, [current.id]: [current.specific] }),
      {}
    )}
    options={options}
  />
);

export default CheckboxGroupSpecify;
