import React from "react";
import ActivityCard from "../ActivityCard/ActivityCard";

const ActivityCardConnected = ({ activity, isAdded, initialCheckedCodes }) => {
  const activeCodes = activity.codes.filter((code) =>
    initialCheckedCodes.includes(code.id)
  );
  return (
    <ActivityCard
      activityName={activity.name}
      description={activity.description}
      activityType={activity.type}
      activityTags={activity.tags}
      isSelected={!!activeCodes.length}
      isAdded={isAdded}
      inBlackList={!activity.available}
      codes={activeCodes.length ? activeCodes : activity.codes}
    />
  );
};

export default ActivityCardConnected;
