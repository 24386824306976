const mimeTypes = {
  "application/pdf": "PDF",
  "application/msword": "Microsoft Word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Microsoft Word",
  "application/vnd.ms-excel": "Microsoft Excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "Microsoft Excel",
  "application/vnd.ms-powerpoint": "Microsoft PowerPoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "Microsoft PowerPoint",
  "text/csv": "CSV",
  "image/png": "Image .png",
  "application/x-7z-compressed": "7-zip archive",
  "application/zip": "ZIP archive",
  "image/jpeg": "JPEG image",
  "application/vnd.rar": "RAR archive",
};

const mimeTypesToFormat = (mimeType) => mimeTypes[mimeType] || "unknown";

export default mimeTypesToFormat;
