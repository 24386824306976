const checkEmpty = (obj, field) => {
  if (!obj || typeof obj !== "object") {
    throw new Error("not object");
  }

  if (obj[field] && Array.isArray(obj[field])) {
    return obj[field].length > 0;
  }

  if (obj[field] && typeof obj[field] === "object") {
    return Object.keys(obj[field]).length > 0;
  }

  return obj[field] !== null && obj[field] !== undefined;
};

export default checkEmpty;
