import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import ProjectIdeaNoteFlow from "./ProjectIdeaNoteFlow";
import { createPINMutation, updatePINMutation } from "../../api/mutations";
import { useCurrentUser } from "../../hooks/user";
import Error404 from "../errors/Error404";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { pinQuery } from "../../api/queries";
import useThread from "../../hooks/messenger/useThread";

const ProjectIdeaNoteFlowConnected = () => {
  const { pinId } = useParams();
  const navigate = useNavigate();

  const [{ data: threadResponse }] = useThread();

  const openedDiscussionThread = threadResponse?.messenger_threads_by_pk;

  const [{ company }] = useCurrentUser();
  const isNew = pinId === "_";

  const [, createPIN] = useMutation(createPINMutation);
  const [, updatePIN] = useMutation(updatePINMutation);

  useEffect(() => {
    if (isNew) {
      createPIN({
        companyId: company.id,
      }).then(({ data }) => {
        if (data?.insert_commodity_PINs_one?.id) {
          navigate(
            `/company/pins/${data?.insert_commodity_PINs_one?.id}/summary`
          );
        }
      });
    }
  }, [isNew, navigate, createPIN, company]);

  const [{ data, fetching, error }] = useQuery({
    query: pinQuery,
    variables: {
      id: pinId,
    },
    pause: isNew,
  });

  const pin = data?.commodity_PINs_by_pk;

  const onChangePIN = (changedValues) => {
    updatePIN({
      id: pinId,
      pin: {
        ...(pin?.data || {}),
        ...changedValues,
      },
    });
  };

  if (fetching) {
    return <LoadingSpinner size="large" full />;
  }

  if (error) {
    return <Error404 />;
  }

  return (
    <ProjectIdeaNoteFlow
      pin={pin}
      onChangePIN={onChangePIN}
      openedDiscussionThread={openedDiscussionThread}
    />
  );
};

export default ProjectIdeaNoteFlowConnected;
