import React, { useMemo } from "react";
import { Table, Button, notification } from "antd";
import cs from "classnames";
import {
  FormOutlined,
  MoreOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import GenerateReportResult from "../pdf/GenerateReportResult";

import StatusTag from "../StatusTag/StatusTag";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";

import styles from "../ReportsTable/ReportsTable.module.less";

const { Column } = Table;

const formatReports = (reports) =>
  reports?.map((report) => ({
    id: report.id,
    company_name: report.company?.name,
    reporting_year: report.reporting_year,
    status: report.status,
    blockchainId: report.blockchain_id,
  }));

const ReportsTable = ({ reports }) => {
  const rows = useMemo(() => formatReports(reports), [reports]);

  const actions = [
    {
      type: "sign",
      label: "Approve and sign",
      icon: <FormOutlined />,
      onClick: () => {
        console.error("Remove polkatod features!");
      },
    },
  ];

  const linkRender = (_, row) => (
    <GenerateReportResult
      reportId={row.id}
      onComplete={(doc, download) => {
        if (download) {
          doc.output("blob");
        }
      }}
      onError={() =>
        notification.error({
          message: "Error download pdf",
          description: "Please try again later.",
        })
      }
    >
      {({ loading, generate }) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          loading={loading}
          size="small"
          icon={<DownloadOutlined />}
          onClick={() => generate(true)}
        >
          Download PDF
        </Button>
      )}
    </GenerateReportResult>
  );

  const statusRender = (status) => <StatusTag type={status} text={status} />;

  const actionsRender = (text, record) => {
    const bindedActions = actions.map(({ onClick, ...rest }) => ({
      ...rest,
      onClick: (...args) => onClick && onClick(record, ...args),
    }));
    return (
      <ActionsDropdown actions={bindedActions}>
        <Button size="small" icon={<MoreOutlined />} />
      </ActionsDropdown>
    );
  };

  const addKeyFromId = (comps) =>
    comps?.map((c) => ({
      ...c,
      key: c.id,
    }));

  return (
    <Table
      dataSource={addKeyFromId(rows)}
      pagination={false}
      className={styles.table}
    >
      <Column
        className={cs([styles.col, styles["col--companyName"]])}
        title="Company Name"
        dataIndex="company_name"
        key="company_name"
      />
      <Column
        className={styles.col}
        title="Year"
        dataIndex="reporting_year"
        key="reporting_year"
      />
      <Column
        className={styles.col}
        title="Blockchain ID"
        dataIndex="blockchainId"
        key="blockchainId"
      />
      <Column
        className={cs(styles.col, styles["col--progressBar"])}
        title="Report"
        key="report"
        dataIndex="link"
        render={linkRender}
      />
      <Column
        className={cs(styles.col, styles["col--status"])}
        title="Status"
        dataIndex="status"
        key="status"
        render={statusRender}
      />
      <Column
        className={cs([styles.col, styles["col--actions"]])}
        title="Actions"
        key="Actions"
        render={actionsRender}
      />
    </Table>
  );
};

export default ReportsTable;
