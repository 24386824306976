import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";

const dateDifferenceFromNow = (dateTo) => {
  const differenceSteps = [
    {
      handler: differenceInYears,
      suffix: ["year", "years"],
    },
    {
      handler: differenceInMonths,
      suffix: ["month", "months"],
    },
    {
      handler: differenceInDays,
      suffix: ["day", "days"],
    },
  ];
  let dateMessage = "today";
  for (let i = 0; i < differenceSteps.length; i += 1) {
    const step = differenceSteps[i];
    const value = step.handler(new Date(), dateTo);
    if (value > 0) {
      dateMessage = `${value} ${step.suffix[Math.min(value, 1)]}`;
      break;
    }
  }

  return dateMessage;
};

export default dateDifferenceFromNow;
