import isEqual from "lodash.isequal";
import React from "react";
import FileTag from "../Tags/FileTag";
import styles from "./FilesTagsListInput.module.less";

const INITIAL_VALUE = [];
const FilesTagsListInput = ({ value = INITIAL_VALUE, onChange }) => {
  const onUnattachFile = (docId) => {
    const filteredFiles = value.filter((v) => v.docId !== docId);
    if (!isEqual(filteredFiles, value)) {
      onChange(filteredFiles);
    }
  };

  if (!value.length) return null;
  return (
    <div className={styles.list}>
      {value.map((file) => (
        <FileTag
          key={file.docId}
          link={file.url}
          name={file.name}
          onClose={() => onUnattachFile(file.docId)}
        />
      ))}
    </div>
  );
};

export default FilesTagsListInput;
