import React from "react";
import {
  Button,
  CheckCircleOutlined,
  List,
  Paragraph,
  Text,
  Space,
  Tag,
  Radio,
} from "@evercityecosystem/evercity-ui";
import styles from "./PlanSpace.module.less";

const PlanSpace = ({
  title,
  value,
  description,
  opportunities,
  tag,
  selected,
  onSelect,
}) => (
  <Radio.Space value={value} className={styles.radio}>
    <Space block justifyContent="space-between" className={styles.head}>
      <Text weight="bold" className={styles.title}>
        {title}
      </Text>
      {tag && (
        <Tag className={styles.tag} type="filled" icon={tag.icon}>
          {tag.text}
        </Tag>
      )}
    </Space>
    <Paragraph className={styles.description}>{description}</Paragraph>
    <Button
      onClick={() => onSelect(value)}
      className={styles.btn}
      type="primary"
    >
      {selected ? "Selected" : "Select"}
    </Button>
    <List
      className={styles.list}
      marker={<CheckCircleOutlined className={styles.marker} />}
      items={opportunities}
    />
  </Radio.Space>
);

export default PlanSpace;
