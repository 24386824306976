import React from "react";
import { useQuery } from "urql";
import { Outlet, useParams } from "react-router-dom";
import { projectFullInfoQuery } from "../../api/queries";
import LkLayout from "../LkLayout/LkLayout";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";

const ProjectContext = () => {
  const { slug } = useParams();
  const [projectResult] = useQuery({
    query: projectFullInfoQuery,
    variables: { slug },
  });

  if (projectResult.fetching)
    return (
      <LkLayout>
        <LoadingSpinner full size="large" />
      </LkLayout>
    );

  const project = projectResult.data?.projects[0];
  if (projectResult.error || !project) {
    return <Error404 />;
  }

  return <Outlet context={{ project }} />;
};

export default ProjectContext;
