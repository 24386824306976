import React, { useEffect, useMemo } from "react";
import { Form, Select } from "@evercityecosystem/evercity-ui";
import { useMutation, useQuery } from "urql";

import { getInitialAndAllMSSTypes } from "../../api/queries";
import { updateReportMSSTypeMutation } from "../../api/mutations";
import FormItemWithDiscussions from "../FormItemWithDiscussions/FormItemWithDiscussions";

const MSSTypeForm = ({ reportId, onChange, threads }) => {
  const [mssTypesResponse] = useQuery({
    query: getInitialAndAllMSSTypes,
    requestPolicy: "network-only",
    variables: { reportId },
  });

  const MSSTypes = useMemo(
    () => mssTypesResponse.data?.eu_taxonomy_mss_types,
    [mssTypesResponse.data]
  );

  const initialMSSType = useMemo(
    () => mssTypesResponse.data?.eu_taxonomy_reports_by_pk?.mss_type,
    [mssTypesResponse.data]
  );

  useEffect(() => onChange(initialMSSType), [initialMSSType, onChange]);

  const [, updateReportMSSType] = useMutation(updateReportMSSTypeMutation);

  const onTypeChangeHandler = async (value) => {
    const { MSSType } = value;

    await updateReportMSSType({
      id: reportId,
      type: MSSType,
    });

    onChange(MSSType);
  };

  if (mssTypesResponse.fetching) {
    return <></>;
  }

  return (
    <Form
      onValuesChange={onTypeChangeHandler}
      layout="vertical"
      style={{ maxWidth: 500 }}
    >
      <FormItemWithDiscussions
        threads={threads}
        label="Company type:"
        name="MSSType"
        initialValue={initialMSSType}
      >
        <Select
          options={MSSTypes?.map((type) => ({
            label: type.title,
            value: type.key,
          }))}
        />
      </FormItemWithDiscussions>
    </Form>
  );
};

export default MSSTypeForm;
