import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useDeleteMutation from "../../hooks/useDeleteMutation";
import { deleteFrameworkMutation } from "../../api/mutations";
import useGenerateFramework from "../../hooks/framework/useGenerateFramework";
import modalStyles from "../ReportsTable/modal.module.less";
import FrameworkFilesTable from "./FrameworkFilesTable";
import useGetFrameworks from "../../hooks/framework/useGetFrameworks";

const FrameworkFilesTableConnected = () => {
  const { frameworks } = useGetFrameworks();
  const [, deleteFramework] = useDeleteMutation(deleteFrameworkMutation);
  const { onGenerate } = useGenerateFramework();

  const onDelete = async (id) => {
    Modal.confirm({
      title: "Delete framework",
      icon: <ExclamationCircleOutlined />,
      className: modalStyles.container,
      content:
        "You’re going to delete the framework, this action cannot be canceled. Do you want to continue?",
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      onOk: async (close) => {
        await deleteFramework(id);
        close();
      },
    });
  };

  return (
    <FrameworkFilesTable
      frameworks={frameworks}
      onDelete={onDelete}
      onGenerate={onGenerate}
    />
  );
};

export default FrameworkFilesTableConnected;
