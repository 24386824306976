import React, { useMemo } from "react";
import { Col, Row } from "antd";
import classnames from "classnames";
import SummarySection from "../SummarySection/SummarySection";
import { Text, Title } from "../../antd-ovveride";
import List from "../List/List";
import styles from "./ReportingFramework.module.less";
import formatString from "../../helpers/formatString";

const ReportingFrameworkSummary = ({ framework, ...props }) => {
  const description = useMemo(() => {
    if (
      !framework.impact_report_unavailability &&
      framework.impact_report_time === "At least once"
    ) {
      return `${
        framework.company?.name
      } will provide a report on the allocation of net proceeds to
          the Eligible Green Project Portfolio 
          ${formatString(
            framework.allocation_report_detail
          )} as long as any green bonds are
          outstanding and provide an impact report at least once.`;
    }

    if (framework.impact_report_unavailability) {
      return `${
        framework.company?.name
      } will provide a report on the allocation of net proceeds to the
          Eligible Green Project Portfolio ${formatString(
            framework.allocation_report_detail
          )} as
          long as any green bonds are outstanding.`;
    }

    if (
      !framework.impact_report_unavailability &&
      framework.impact_report_time ===
        "As long as any green bonds are outstanding"
    ) {
      return `${
        framework.company?.name
      }  will provide a report on the allocation of net proceeds to
      the Eligible Green Project Portfolio
      ${formatString(
        framework.allocation_report_detail
      )} and provide impact report
      ${formatString(
        framework.impact_report_frequency
      )} as long as any green bonds are
      outstanding.`;
    }
  }, [framework]);
  const typesOfReporting = useMemo(() => {
    const types = ["Eligibility", "Allocation"];

    if (!framework.impact_report_unavailability) {
      types.push("Impact");
    }
    return types;
  }, [framework]);

  return (
    <SummarySection description={description} title="Reporting" {...props}>
      <Row className={styles.summary__rowBody}>
        <Col span={9}>
          <Title level={5}>Types of reporting</Title>
          <List items={typesOfReporting} />
        </Col>
        <Col span={11} offset={4}>
          <Title level={5}>Main impact indicators</Title>
          <List
            items={framework.example_categories.reduce(
              (indicators, category) => [
                ...indicators,
                ...(category.impact_indicators || []),
              ],
              []
            )}
          />
        </Col>
      </Row>
      <Row gutter={12} justify="end" className={styles.summary__row}>
        <Col span={framework.impact_report_unavailability ? 16 : 8}>
          <Title level={5}>Allocation</Title>
        </Col>
        {!framework.impact_report_unavailability && (
          <Col span={8}>
            <Title level={5}>Impact</Title>
          </Col>
        )}
      </Row>
      <Row
        gutter={12}
        className={classnames(styles.summary__row, styles.summary__rowBody)}
      >
        <Col span={8}>
          <Title level={5}>Frequency</Title>
        </Col>
        <Col span={8}>
          <Text>
            {framework.allocation_report_frequency} as long as any green bonds
            are outstanding
          </Text>
        </Col>
        {!framework.impact_report_unavailability && (
          <Col span={8}>
            <Text>{framework.impact_report_time}</Text>
          </Col>
        )}
      </Row>
      <Row gutter={12}>
        <Col span={8}>
          <Title level={5}>Level of detail</Title>
        </Col>
        <Col span={8}>
          <Text>{framework.allocation_report_detail}</Text>
        </Col>
        <Col span={8}>
          {!framework.impact_report_unavailability && (
            <Text>{framework.impact_report_detail}</Text>
          )}
        </Col>
      </Row>
    </SummarySection>
  );
};

export default ReportingFrameworkSummary;
