import React, { useState } from "react";
import WarningModal from "./WarningModal";

export const WarningContext = React.createContext(null);

const WarningProvider = ({ children, methods, title }) => {
  const [warning, setWarning] = useState(null);

  const showWarning = (message) => {
    setWarning(message);
  };

  const closeWarning = () => {
    setWarning(null);
  };

  return (
    <>
      <WarningContext.Provider value={{ showWarning }}>
        {children}
      </WarningContext.Provider>
      <WarningModal
        onCancel={closeWarning}
        visible={!!warning}
        title={title}
        content={warning}
        {...methods}
      />
    </>
  );
};

export default WarningProvider;
