import React, { useState } from "react";
import cs from "classnames";
import { Upload as BaseUpload } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import styles from "./Upload.module.less";

const Upload = ({ className, onChange, defaultFileList, ...rest }) => {
  const [isEmpty, setIsEmpty] = useState(!defaultFileList?.length);

  const onChangeHandler = ({ fileList, ...args }) => {
    setIsEmpty(fileList.length === 0);
    onChange?.({
      fileList,
      ...args,
    });
  };
  return (
    <BaseUpload
      className={cs(
        className,
        styles.upload,
        isEmpty && styles["upload--empty"]
      )}
      showUploadList={{
        showRemoveIcon: true,
        removeIcon: <CloseCircleOutlined />,
      }}
      onChange={onChangeHandler}
      defaultFileList={defaultFileList}
      {...rest}
    />
  );
};
Upload.Dragger = BaseUpload.Dragger;
Upload.LIST_IGNORE = BaseUpload.LIST_IGNORE;

export default Upload;
