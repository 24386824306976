import React, { useMemo, useRef } from "react";
import { Text, Space } from "@antd-ovveride";
import { format } from "date-fns";
import useUrlState from "@ahooksjs/use-url-state";
import {
  InfoCircleOutlined,
  LayoutOutlined,
  FileTextOutlined,
  CloudOutlined,
  BarsOutlined,
  // NotificationOutlined,
} from "@ant-design/icons";
import { useResponsive } from "ahooks";

import BackTo from "../BackTo/BackTo";
import SDGList from "../SDGList/SDGList";
import ListingProgress from "../ListingProgress/ListingProgress";
import { toMln, toThousands } from "../../helpers/converters";
import FileCard from "../FileCard/FileCard";
import { thousandFormatter } from "../../helpers/thousandFormatter";
import PublicLayoutContainer from "../PublicLayoutContainer/PublicLayoutContainer";
import ProductTwoColLayout from "../Product/ProductTwoColLayout";
import ProductHeader from "../Product/ProductHeader";
import ProductMainInfoList from "../Product/ProductMainInfoList";
import ProductPrimaryBtn from "../Product/ProductPrimaryBtn";
import ProductDivider from "../Product/ProductDivider";

import OverviewTabContent from "./TabsContent/OverviewTabContent";
import FrameworkTabContent from "./TabsContent/FrameworkTabContent";
import DocumentsTabContent from "./TabsContent/DocumentsTabContent";
import ImpactTabContent from "./TabsContent/ImpactTabContent";
import ReportingTabContent from "./TabsContent/ReportingTabContent";
// import NotificationTabContent from "./TabsContent/NotificationTabContent";
import ProductTabSwitcher from "../Product/ProductTabSwitcher";
import uppercaseFirstLetter from "../../helpers/uppercaseFirstLetter";
import LkLayout from "../LkLayout/LkLayout";

const makeContentTabs = (debt, mapRef) => [
  {
    title: "Overview",
    icon: <InfoCircleOutlined />,
    component: <OverviewTabContent debt={debt} ref={mapRef} />,
  },
  {
    title: "Framework",
    icon: <LayoutOutlined />,
    component: <FrameworkTabContent debt={debt} />,
  },
  {
    title: "Documents",
    icon: <FileTextOutlined />,
    component: <DocumentsTabContent debt={debt} />,
  },
  {
    title: "Impact",
    icon: <CloudOutlined />,
    component: <ImpactTabContent debt={debt} />,
  },
  {
    title: "Reporting",
    icon: <BarsOutlined />,
    component: <ReportingTabContent debt={debt} />,
  },
  // {
  //   title: "Updates",
  //   icon: <NotificationOutlined />,
  //   component: <NotificationTabContent debt={debt} />,
  // },
];

const getMainInfoList = (debt) => [
  {
    key: "Type",
    value: uppercaseFirstLetter(debt.type),
  },
  {
    key: "SDGs",
    value: <SDGList items={debt.bond_goals} />,
  },
  {
    key: "Volume",
    value: `${toMln(debt.volume)}M ${debt.currency}`,
  },
  {
    key: "Interest rate",
    value: `${debt.interest_rate_base_value}%`,
  },
  {
    key: "Maturity date",
    value: format(new Date(debt.maturity_date), "dd.MM.yyyy"),
  },
  {
    key: "Book opens",
    value: format(new Date(debt.booking_date), "dd.MM.yyyy"),
  },
];

const getCategories = (list) =>
  list.map(({ project }) =>
    project.project_categories.map((cat) => ({
      text: cat.category.title,
      id: cat.category_id,
    }))
  );

const DebtPage = ({ debt }) => {
  const { md } = useResponsive();
  const mapRef = useRef();
  const tabs = useMemo(() => makeContentTabs(debt, mapRef), [debt]);
  const [activeTabKey, setActiveTabKey] = useUrlState(
    { tab: tabs[0].title },
    { navigateMode: "replace" }
  );

  const scrollToMap = () => {
    setActiveTabKey({ tab: tabs[0].title });
    setTimeout(() => {
      mapRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        scrollIntoViewOptions: {},
      });
    }, 0);
  };

  return (
    <LkLayout>
      <PublicLayoutContainer margins>
        <Space block direction="vertical" size={md ? 50 : 30}>
          <BackTo to="/listing/?section=debt">Back to all</BackTo>
          <ProductTwoColLayout>
            <>
              <ProductHeader
                image={debt.image}
                companyName={debt.company.name}
                companyLogo={debt.company.logo}
                country={debt.company.country}
                description={debt.description}
                assetTypes={[{ id: 1, text: debt.type }]}
                state={debt.state?.replace("_", " ")}
                categories={getCategories(debt.bond_projects)}
                onLocationClick={scrollToMap}
              />

              <Space block size={10} wrap>
                {debt.bond_docs?.map((bd) => (
                  <FileCard
                    url={bd.doc.url}
                    fileName={bd.doc.name}
                    title={bd.attachment_type}
                    description={bd.doc.description}
                    status="draft"
                    key={bd.doc.url}
                  />
                ))}
              </Space>
            </>
            <>
              <ProductMainInfoList
                items={getMainInfoList(debt)}
                title="Impact highlights"
              />

              <Space block size={20} direction="vertical">
                {debt.state.toLowerCase() === "subscription" && (
                  <ListingProgress
                    total={debt.volume}
                    part={debt.bought}
                    message={`${toMln(debt.bought || 0)}M ${
                      debt.currency
                    } booked of ${toMln(debt.volume)}M ${debt.currency}`}
                  />
                )}

                <Space block direction="vertical" size={20}>
                  <ProductPrimaryBtn to="/request-demo">
                    Invest
                  </ProductPrimaryBtn>

                  <Text size="large" type="secondary" align="center" block>
                    <Text>
                      {thousandFormatter(
                        toThousands(debt.bond_units_base_price)
                      )}
                      K USD
                    </Text>{" "}
                    minimum investment
                  </Text>
                </Space>
              </Space>
            </>
          </ProductTwoColLayout>
        </Space>

        <ProductDivider />

        <ProductTabSwitcher
          tabs={tabs}
          activeTabKey={activeTabKey.tab}
          onTabChange={(tab) => setActiveTabKey({ tab })}
        />
      </PublicLayoutContainer>
    </LkLayout>
  );
};

export default DebtPage;
