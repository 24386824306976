import React from "react";
import { Input as BaseInput } from "antd";
import classnames from "classnames";
import styles from "./Input.module.less";

const PasswordInput = ({ className, roundCorners, ...props }) => (
  <BaseInput.Password
    className={classnames(
      styles.input,
      { [styles["input-roundCorners"]]: roundCorners },
      className
    )}
    {...props}
  />
);

export default PasswordInput;
