import React from "react";
import ReportResultByParameter from "./ReportResultByParameter/ReportResultByParameter";
import reportFinParams from "../../data/reportCurrencyParameters";

const ReportResult = React.forwardRef(({ actReports, report }, tabelsRefs) => (
  <div
    style={{
      opacity: 0,
      width: 0,
      height: 0,
      overflow: "hidden",
      position: "absolute",
    }}
  >
    {Object.keys(reportFinParams).map((finParamName, index) => (
      <div
        key={finParamName}
        ref={(node) => {
          /* eslint-disable-next-line */
          tabelsRefs.current[finParamName] = node;
        }}
        style={{ display: "inline-flex" }}
      >
        <ReportResultByParameter
          finParamName={finParamName}
          actReports={actReports}
          report={report}
          company={report.company}
          paginationIndex={index + 1}
          paginationTotal={Object.keys(reportFinParams).length}
        />
      </div>
    ))}
  </div>
));

export default ReportResult;
