import React from "react";
import { Pie, PieChart, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Typography } from "antd";

import CustomPieTooltipLayout from "./CustomPieTooltipLayout";
import { decimalToPercents } from "../../helpers/percents";
import { thousandFormatter } from "../../helpers/thousandFormatter";

const { Title } = Typography;

/* 
 data: [
    {name, proportion, currencyValue},
    {name, proportion, currencyValue},
    {name, proportion, currencyValue},
 ]
*/

const ActivitiesChartTooltip = ({ active, payload }) => {
  if (!active) return null;

  const piePartSettings = payload[0].payload;
  const { proportion, currencyValue, currency, name } = piePartSettings.payload;

  const summary = `${thousandFormatter(
    currencyValue
  )} ${currency} / ${decimalToPercents(proportion)}`;
  const headerList = [
    {
      name,
      color: piePartSettings.stroke,
    },
  ];
  return <CustomPieTooltipLayout headerList={headerList} summary={summary} />;
};

const ActivitiesChart = ({ data, title, showTitle }) => (
  <>
    {showTitle && (
      <Title level={5} align="center" style={{ marginBottom: 0 }}>
        {title}
      </Title>
    )}
    <ResponsiveContainer width="100%" height={260}>
      <PieChart width={400} height={400}>
        <Tooltip
          content={<ActivitiesChartTooltip />}
          wrapperStyle={{ zIndex: 1000 }}
        />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={72}
          externalRadius={130}
          paddingAngle={0}
          labelLine={false}
          dataKey="proportion"
        >
          {data.map((item) => (
            <Cell stroke={item.color} key={item.name} fill={item.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </>
);

export default ActivitiesChart;
