/* eslint-disable react/no-danger */

import React from "react";
import { string, oneOf, node } from "prop-types";
import { Text, Space } from "@antd-ovveride";
import { Typography } from "antd";
import { useResponsive } from "ahooks";

const { Title } = Typography;

const ProductContentSection = ({ body, title }) => {
  const { md } = useResponsive();

  return (
    <Space block size={md ? 20 : 15} direction="vertical">
      {title && <Title level={3}>{title}</Title>}
      {body && typeof body === "string" ? (
        <Text size="large">
          <div
            dangerouslySetInnerHTML={{
              __html: body.replace(/\n/g, "<br />"),
            }}
          />
        </Text>
      ) : (
        body
      )}
    </Space>
  );
};

ProductContentSection.propTypes = {
  body: oneOf(string, node),
  title: string,
};

ProductContentSection.defaultProps = {
  body: null,
  title: null,
};

export default ProductContentSection;
