import React from "react";
import { Checkbox } from "antd";
import styles from "./CheckboxGroupFilter.module.less";

const CheckboxGroupFilter = ({ filter, value, onChange }) => (
  <Checkbox.Group
    options={filter.options.map((o) => ({ label: o.label, value: o.value }))}
    value={value}
    onChange={onChange}
    className={styles.checkboxGroupFilter}
  />
);

export default CheckboxGroupFilter;
