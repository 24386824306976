import { useMutation } from "urql";
import {
  assignConsultantMutation,
  assignManagerMutation,
} from "../../api/mutations";

const useAssignManager = () => {
  const [, assignCLient] = useMutation(assignManagerMutation);
  const [, assignConsultant] = useMutation(assignConsultantMutation);
  const assignManager = (
    { reportId, userId, consultantId, clientId },
    type
  ) => {
    if (type === "client") {
      assignCLient({
        reportId,
        userId,
      });
    }
    if (type === "consultant") {
      assignConsultant({
        consultantId,
        clientId,
        userId,
      });
    }
  };
  return {
    assignManager,
  };
};

export default useAssignManager;
