import React from "react";
import { InputNumber } from "antd";
import classnames from "classnames";
import styles from "./Counter.module.less";
import Text from "../Text/Text";

const Counter = ({ title, className, ...props }) => (
  <div className={classnames(styles.counter, className)}>
    <Text>{title}</Text>
    <InputNumber min={0} {...props} />
  </div>
);
export default Counter;
