import React from "react";
import AttachedListConnected from "../AttachedList/AttachedListConnected";
import DropFileZoneConnected from "./DropFileZoneConnected";
import styles from "./UploadTab.module.less";

const UploadTab = ({
  docs,
  selectedDocsIds,
  onCreateDoc,
  onDeleteDoc,
  onRetryDoc,
  onUpdateDoc,
  onSelectChangeDoc,
}) => {
  const onChangeDoc = (changedDoc) => {
    const docIndex = docs.findIndex((d) => d.uid === changedDoc.uid);
    if (docIndex === -1) {
      onCreateDoc({
        ...changedDoc,
        createdDate: new Date().toISOString(),
      });
    } else {
      onUpdateDoc(changedDoc.uid, {
        status: changedDoc.status,
        docId: changedDoc.data.docId,
        name: changedDoc.data.name,
        url: changedDoc.data.url,
        error: changedDoc.error,
      });
    }

    if (
      changedDoc.status === "done" &&
      docs[docIndex]?.status !== "done" &&
      !selectedDocsIds.includes(changedDoc.uid)
    ) {
      onSelectChangeDoc(changedDoc.uid);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.dropzone}>
        <DropFileZoneConnected onChange={onChangeDoc} />
      </div>
      {!!docs.length && (
        <AttachedListConnected
          docs={docs}
          onDeleteDoc={onDeleteDoc}
          onRetryDoc={onRetryDoc}
          onSelectChangeDoc={onSelectChangeDoc}
          selectedDocs={selectedDocsIds}
          onUpdateDoc={onUpdateDoc}
        />
      )}
    </div>
  );
};

export default UploadTab;
