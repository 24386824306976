export const UNFCCCSignatories = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Democratic People's Republic of Korea",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "European Union",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See (Observer state)",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia (Federated States of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Republic of Korea",
  "Republic of Moldova",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "State of Palestine",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Tajikistan",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Türkiye",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United Republic of Tanzania",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const sectoralScopesTree = {
  "1. Energy": {
    "1.1 Energy generation": {
      "1.1.1 Energy (renewable / non-renewable sources)": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
      ],
      "1.1.2 Thermal energy generation": [
        "Clean Development Mechanism",
        "Global Carbon Council",
      ],
      "1.1.3 Renewables": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "Gold Standard",
      ],
    },
    "1.2 Energy distribution": {
      "1.2.1 Energy distribution": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
      ],
    },
    "1.3 Energy demand": {
      "1.3.1 Energy demand": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
      ],
      "1.3.2 End-use energy efficiency": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
      "1.3.3 Renewable energy connected to mini-grid or off grid solutions": [
        "Gold Standard",
      ],
      "1.3.4 Energy efficiency improvements (fuel switching, waste heat recovery, cogeneration)":
        [
          "Clean Development Mechanism",
          "Global Carbon Council",
          "Verra",
          "Cercarbono",
          "American Carbon Registry",
        ],
    },
  },
  "2. Manufacturing": {
    "2.1 Manufacturing industries": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
    "2.2 Cement and lime production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
    ],
  },
  "3. Chemicals": {
    "3.1 Chemical industry": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
    "3.2 Caprolactam, nitric and adipic acid production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
    "3.3 Reduced emissions from destruction of N2O in manufacturing": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
    ],
  },
  "4. Construction": {
    "4.1 Construction": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
    ],
  },
  "5. Transport": {
    "5.1 Transport": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
    ],
  },
  "6. Mining/minerals": {
    "6.1 Mining/mineral production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
  },
  "7. Metals": {
    "7.1 Metal production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
    ],
    "7.2 Aluminium and magnesium production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
    ],
    "7.3 Iron, steel and ferro-alloy production": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
    ],
  },
  "8. Fugitive emissions from fuels": {
    "8.1 Fugitive emissions from fuels": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
  },
  "9. Fugitive emissions from industrial processes": {
    "9.1 Coal mine methane destruction": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Cercarbono",
      "American Carbon Registry",
    ],
    "9.2 Hydrofluorocarbons (HFCs)": [
      "Verra",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
    "9.3 Chlorofluorocarbons (CFCs)": [
      "Verra",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
    "9.4 Hydrochlorofluorocarbons (HCFCs)": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Cercarbono",
    ],
    "9.5 Perfluorocarbons (PFC)": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Cercarbono",
    ],
    "9.6 Sulphur hexafluoride (SF6)": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Cercarbono",
    ],
    "9.7 Carbon tetrafluoride (CF4)": ["American Carbon Registry"],
    "9.8 Halons": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
      "Cercarbono",
      "American Carbon Registry",
      "Climate Action Reserve",
    ],
  },
  "10. Solvents": {
    "10.1 Solvent use": [
      "Clean Development Mechanism",
      "Global Carbon Council",
      "Verra",
    ],
  },
  "11. Waste": {
    "11.1 Waste handling and disposal": {
      "11.1.1 Waste handling and disposal": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
    },
    "11.2 Solid waste and wastewater": {
      "11.2.1 Solid waste and wastewater": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
      "11.2.2 Avoidance of methane production in wastewater treatment": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
    },
    "11.3 Landfill": {
      "11.3.1 Capture and destruction of landfill gas": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
      "11.3.2 Capture and use of landfill gas (biodigestion, aerobic treatment)":
        [
          "Clean Development Mechanism",
          "Global Carbon Council",
          "Verra",
          "Cercarbono",
          "American Carbon Registry",
          "Climate Action Reserve",
          "Gold Standard",
        ],
    },
    "11.4 Livestock and manure management": {
      "11.4.1 Livestock and manure management": [],
      "11.4.2 Methane collection and/or destruction": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
      "11.4.3 Biogas control system": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
      "11.4.4 Livestock and other anaerobic digester operations": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
      ],
    },
  },
  "12. Forestry, Agriculture and Other Land Use": {
    "12.1 Afforestation, reforestation and revegetation": {
      "12.1.1 Afforestation, reforestation and revegetation": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Verra",
        "Cercarbono",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Gold Standard",
        "Plan Vivo",
      ],
      "12.1.2 Urban forestry": ["Climate Action Reserve"],
    },
    "12.2 Agricultural land management": {
      "12.2.1 Agricultural land management": [
        "Verra",
        "Climate Action Reserve",
      ],
      "12.2.2 Improved cropland management": [
        "Verra",
        "Climate Action Reserve",
      ],
      "12.2.3 Improved grassland management": [
        "Verra",
        "Climate Action Reserve",
      ],
      "12.2.4 Cropland and grassland land-use conversions": [
        "Verra",
        "Climate Action Reserve",
      ],
    },
    "12.3 Other agricultural activities": {
      "12.3.1 Agricultural methane emission reduction": [
        "American Carbon Registry",
      ],
      "12.3.2 Agricultural carbon emissions reduction": [
        "American Carbon Registry",
      ],
      "12.3.3 Nitrogen management in crop production": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Climate Action Reserve",
      ],
      "12.3.4 Water management (in rice cultivation)": [
        "Clean Development Mechanism",
        "Global Carbon Council",
        "Climate Action Reserve",
      ],
      "12.3.5 Carbon storage in soils (soil enrichment)": [
        "Climate Action Reserve",
      ],
      "12.3.6 Agroforestry and silvopastoral systems": [
        "Climate Action Reserve",
        "Plan Vivo",
      ],
    },
    "12.4 Improved forest management and forest protection": {
      "12.4.1 Improved forest management": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
      "12.4.2 Conservation of forests and woodlands": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
    },
    "12.5 Reduced emissions from deforestation and degradation": {
      "12.5.1 Avoiding planned deforestation and/or degradation": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
      "12.5.2 Avoiding unplanned deforestation and/or degradation": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
    },
    "12.6 Avoided conversion of grasslands and shrublands": {
      "12.6.1 Avoiding planned conversion": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
      "12.6.2 Avoiding unplanned conversion": [
        "Verra",
        "American Carbon Registry",
        "Climate Action Reserve",
        "Plan Vivo",
      ],
    },
    "12.7 Restoration and reforestation of degraded or damaged ecosystems": {
      "12.7.1 Restoration and reforestation of degraded or damaged ecosystems":
        [
          "Clean Development Mechanism",
          "Global Carbon Council",
          "Verra",
          "Cercarbono",
          "American Carbon Registry",
          "Climate Action Reserve",
          "Gold Standard",
          "Plan Vivo",
        ],
      "12.7.2 Wetlands restoration and conservation": ["Verra"],
    },
  },
  "13. Carbon capture and storage": {
    "13.1 Carbon capture and storage in geological formations": [
      "Global Carbon Council",
      "Verra",
      "American Carbon Registry",
    ],
  },
  "14. Other community service activities": {
    "14.1 Water, sanitation and hygiene": ["Gold Standard"],
  },
};

export const flatProjectTypes = Object.values(sectoralScopesTree).reduce(
  (acc, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value;
      } else {
        Object.entries(value).forEach(([projectType, standards]) => {
          acc[projectType] = standards;
        });
      }
    });
    return acc;
  },
  {}
);

export const methodologies = [
  "The project is using an existing methodology",
  "The project is using a newly-created methodology",
  "The project proponent needs help to select a methodology",
  "The project proponent needs help to create a methodology",
];

export const scaleOptions = [
  "up to 4,999 tCO2e/year",
  "5,000 – 20,000 tCO2e/year",
  "20,001 – 100,000 tCO2e/year",
  "100,001 – 1,000,000 tCO2e/year",
  ">1,000,000 tCO2e/year",
];

export const projectStatuses = [
  "Idea",
  "Feasibility study ",
  "Business plan",
  "Fundraising",
  "Implementation",
  "Operation",
];

export const METHODOLOGY_LIST = [
  {
    name: "Clean Development Mechanism",
    code: "CDM",
    link: "https://cdm.unfccc.int/methodologies/methodologiesAccrv6/index.html",
  },
  {
    name: "Verified Carbon Standard",
    code: "VERRA",
    link: "https://verra.org/project/vcs-program/methodologies/methodology-catalog",
  },
  {
    name: "Gold Standard",
    code: "GS",
    link: "https://globalgoals.goldstandard.org/400-sdg-impact-quantification/",
  },
  {
    name: "Global Carbon Council",
    code: "GCC",
    link: "https://www.globalcarboncouncil.com/standards/baseline-monitoring-methodologies/#1633981295879-118def59-53c5",
  },
  {
    name: "Cercarbono",
    code: "CERCARBONO",
    link: "https://www.cercarbono.com/methodologies",
  },
  {
    name: "American Carbon Registry",
    code: "ACR",
    link: "https://americancarbonregistry.org/carbon-accounting/standards-methodologies",
  },
  {
    name: "Climate Action Reserve",
    code: "CAR",
    link: "https://www.climateactionreserve.org/how/protocols",
  },
  {
    name: "Plan Vivo",
    code: "PV",
    link: "https://www.planvivo.org/methodologies",
  },
];
