import React from "react";
import { node } from "prop-types";

import styles from "./CatalogCardsGrid.module.less";

const CatalogCardsGrid = ({ children }) => (
  <div className={styles.grid}>{children}</div>
);

CatalogCardsGrid.propTypes = {
  children: node.isRequired,
};

export default CatalogCardsGrid;
