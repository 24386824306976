import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Avatar as BaseAvatar, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./SelectManager.module.less";
import Avatar from "../../antd-ovveride/Avatar/Avatar";

const SelectManager = ({ managers, onSelect, manager }) => (
  <div className={styles.container}>
    <Select
      defaultValue={manager?.id}
      onSelect={onSelect}
      bordered={false}
      suffixIcon={
        manager ? (
          <Avatar name={manager.display_name} />
        ) : (
          <BaseAvatar icon={<PlusOutlined />} />
        )
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
      options={managers.map((user) => ({
        label: user.display_name,
        value: user.id,
      }))}
      placeholder="Add member"
    />
  </div>
);

export default SelectManager;
