import finParams from "../../data/reportCurrencyParameters";

const getActReportCodes = (actReport) => ({
  NACE: actReport.activities_reports_codes.map((code) => code.nace_code.code),
  SBI: actReport.activities_reports_codes.map(
    (code) => code.nace_code.sbi_code
  ),
});

const getActReportDnshCreterias = (actReport) => (criteria) => {
  const criteriaToPointSubtype = {
    dnsh_criteria_1: "",
    dnsh_criteria_2: "climate_change",
    dnsh_criteria_3: "sustainable_use",
    dnsh_criteria_4: "circular_economy",
    dnsh_criteria_5: "pollution_prevention",
    dnsh_criteria_6: "protection",
  };
  const dnshPoints = actReport.activity.points;
  const criteriaPoint = dnshPoints.find(
    (p) => p.subtype === criteriaToPointSubtype[criteria]
  );

  if (!criteriaPoint || criteriaPoint.text === "N/A") {
    return "N/A";
  }
  return "Y";
};

const getSumValueByObjective = (units, targetObjective, finParam) =>
  units.reduce((sum, unit) => {
    if (unit.objective === targetObjective) {
      sum += unit[finParam]; // eslint-disable-line no-param-reassign
    }
    return sum;
  }, 0);

const getAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportValue = actReport[finParamName];
  const actReportAlignedValue = actReport[alignedKey];
  const actReportAlignedProportionByTotal = actReportAlignedValue / reportValue;

  const actReportAlignedProportionByActivityTotal =
    actReportAlignedValue / actReportValue;

  const { activity } = actReport;
  const activityType = actReport.activity_type || actReport.activity.type;
  const isAdaptedAct = activity.adaptation_type === "adapted";
  const codes = getActReportCodes(actReport);
  const getDnshCriteria = getActReportDnshCreterias(actReport);

  const mitigationAlignedValue = getSumValueByObjective(
    actReport.units,
    "mitigation",
    alignedKey
  );
  const mitigationAlignedProportionByTotal =
    mitigationAlignedValue / reportValue;

  const adaptationAlignedValue = getSumValueByObjective(
    actReport.units,
    "adaptation",
    alignedKey
  );
  const adaptationAlignedProportionByTotal =
    adaptationAlignedValue / reportValue;

  return {
    name: activity.name,
    type: "activity",
    codes: codes.NACE,
    finValue: actReportAlignedValue,
    finValueProportion: actReportAlignedProportionByTotal,
    sc_criteria_1: mitigationAlignedProportionByTotal,
    sc_criteria_2:
      isAdaptedAct && finParamName === "turnover"
        ? 0
        : adaptationAlignedProportionByTotal,
    sc_criteria_3: 0,
    sc_criteria_4: 0,
    sc_criteria_5: 0,
    sc_criteria_6: 0,
    dnsh_criteria_1: getDnshCriteria("dnsh_criteria_1"),
    dnsh_criteria_2: getDnshCriteria("dnsh_criteria_2"),
    dnsh_criteria_3: getDnshCriteria("dnsh_criteria_3"),
    dnsh_criteria_4: getDnshCriteria("dnsh_criteria_4"),
    dnsh_criteria_5: getDnshCriteria("dnsh_criteria_5"),
    dnsh_criteria_6: getDnshCriteria("dnsh_criteria_6"),
    mss: "Y",
    aligned_fin_value: actReportAlignedProportionByActivityTotal,
    aligned_fin_value_prev_year: "",
    category_enabling: activityType === "enabling" ? "E" : "",
    category_transitional: activityType === "transitional" ? "T" : "",
  };
};

const getNotAlignedActReportTableData = (report, actReport, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const reportValue = report[finParamName];
  const actReportValue = actReport[finParamName];
  const actReportAlignedValue = actReport[alignedKey];

  const actReportNotAlignedValue = actReportValue - actReportAlignedValue;
  const actReportNotAlignedProportionByTotal =
    actReportNotAlignedValue / reportValue;

  const { activity } = actReport;
  const codes = getActReportCodes(actReport);

  const activityType = actReport.activity_type || actReport.activity.type;

  return {
    name: activity.name,
    type: "activity",
    codes: codes.NACE,
    finValue: actReportNotAlignedValue,
    finValueProportion: actReportNotAlignedProportionByTotal,
    category_enabling: activityType === "enabling" ? "E" : "",
    category_transitional: activityType === "transitional" ? "T" : "",
  };
};

const getValuesFromUnits = (units) =>
  units.reduce(
    (values, unit) => {
      const result = { ...values };
      Object.keys(values).forEach((key) => {
        result[key] += unit[key];
      });
      return result;
    },
    {
      opex: 0,
      turnover: 0,
      capex: 0,
      opex_alignment: 0,
      turnover_alignment: 0,
      capex_alignment: 0,
    }
  );

const doubleActivitiesAdapter = (activities) =>
  activities.reduce((result, activityReport) => {
    const activityTypes = new Set(
      activityReport.units.map((unit) => unit.activity_type)
    );
    if (activityTypes.size === 2) {
      activityTypes.forEach((actType) => {
        const targetUnits = activityReport.units.filter(
          (unit) => unit.activity_type === actType
        );
        result.push({
          ...activityReport,
          activity_type: actType,
          units: targetUnits,
          ...getValuesFromUnits(targetUnits),
        });
      });
    }
    if (activityTypes.size === 1) {
      result.push({
        ...activityReport,
        activity_type: activityTypes[0],
      });
    }
    return result;
  }, []);

const getTableRows = (report, actReports, finParamName) => {
  const alignedKey = `${finParamName}_alignment`;
  const eligibleKey = `${finParamName}_eligibility`;
  const reportTotalValue = report[finParamName];

  const reportEligibleValue = report[eligibleKey];
  const reportEligibleProportion = reportEligibleValue / reportTotalValue;

  const reportNotEligibleValue = reportTotalValue - reportEligibleValue;
  const reportNotEligibleProportion = 1 - reportEligibleProportion;

  const reportAlignedValue = report[alignedKey];
  const reportAlignedPercent = reportAlignedValue / reportTotalValue;
  const alignedFinValue = reportAlignedValue / reportEligibleValue;
  const reportNotAlignedValue = reportEligibleValue - reportAlignedValue;
  const reportNotAlignedProportion = reportNotAlignedValue / reportTotalValue;

  const eligibleActReports = actReports.filter((ar) => ar[finParamName] > 0);

  const alignedActReports = eligibleActReports.filter(
    (ar) => ar[alignedKey] > 0
  );
  const alignedActReportsTableRows = doubleActivitiesAdapter(
    alignedActReports
  ).map((actReport) =>
    getAlignedActReportTableData(report, actReport, finParamName)
  );

  // по turnover: если у активности turnover_alignment не равен ее turnover
  // значит у нее есть not-aligned часть и ее нужно вывести
  const notAlignedActReports = eligibleActReports.filter(
    (actReport) => actReport[alignedKey] < actReport[finParamName]
  );
  const notAlignedActReportsTableRows = doubleActivitiesAdapter(
    notAlignedActReports
  ).map((actReport) =>
    getNotAlignedActReportTableData(report, actReport, finParamName)
  );

  const rows = [
    // ELIGIBLE
    {
      name: "A. TAXONOMY-ELIGIBLE ACTIVITIES",
      type: "category",
    },
    // ALIGNED
    {
      name: "A.1. Environmentally sustainable activities (Taxonomy-aligned)",
      type: "subcategory",
    },
    ...alignedActReportsTableRows,
    // ALIGNED - SUM
    {
      name: `${finParams[finParamName]} of environmentally sustainable activities (Taxonomy-aligned) (A.1.)`,
      type: "sum",
      codes: "",
      finValue: reportAlignedValue,
      finValueProportion: reportAlignedPercent,
      sc_criteria_1: reportAlignedPercent,
      sc_criteria_2: 0,
      sc_criteria_3: 0,
      sc_criteria_4: 0,
      sc_criteria_5: 0,
      sc_criteria_6: 0,
      aligned_fin_value: alignedFinValue,
      aligned_fin_value_prev_year: "",
    },
    // NOT-ALIGNED
    {
      name: "A.2 Taxonomy-Eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)",
      type: "subcategory",
    },
    ...notAlignedActReportsTableRows,
    // NOT ALIGNED - SUM
    {
      name: `${finParams[finParamName]} of Taxonomy-eligible but not environmentally sustainable activities (not Taxonomy-aligned activities)`,
      type: "sum",
      codes: "",
      finValue: reportNotAlignedValue,
      finValueProportion: reportNotAlignedProportion,
    },
    // ELIGIBLE - SUM
    {
      name: "Total (A.1 + A.2)",
      type: "sum",
      codes: "",
      finValue: reportEligibleValue,
      finValueProportion: reportEligibleProportion,
      aligned_fin_value: alignedFinValue,
    },
    {
      name: "B. TAXONOMY-NON-ELIGIBLE ACTIVITIES",
      type: "category",
    },
    {
      name: `${finParams[finParamName]} of Taxonomy-non-eligible activities (B)`,
      type: "sum",
      codes: "",
      finValue: reportNotEligibleValue,
      finValueProportion: reportNotEligibleProportion,
    },
    {
      name: "Total (A + B)",
      type: "sum",
      codes: "",
      finValue: reportTotalValue,
      finValueProportion: 1,
    },
  ];
  return rows.map((row, index) => ({
    ...row,
    key: index,
  }));
};

export default getTableRows;
