import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import MapGL, { AttributionControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import cs from "classnames";
import { useClickAway, useResponsive } from "ahooks";
import { CSSTransition } from "react-transition-group";
import activeFeaturePin from "../../assets/map/active_pin.svg";
import { LAYERS, PROJECTS_LAYERS_IDS } from "../../data/mapLayers";

import styles from "./Map.module.less";
import ProjectPopup from "./ProjectPopup";
import PointFeaturePopup from "./PointFeaturePopup";
// import FillFeaturePopup from "./FillFeaturePopup";
import ZoomController from "./ZoomController";
import { Space } from "../../antd-ovveride";
import MapButton from "./MapButton";
import satelliteIcon from "../../assets/map/satellite.svg";
import satelliteIconActive from "../../assets/map/satellite_active.svg";

const LAYERS_MAPBOX_IDS = [
  ...LAYERS.map((l) => l.mapboxId).filter((id) => !!id),
  ...PROJECTS_LAYERS_IDS,
];

const filterNativeFeatures = (features) => {
  const customFeatures =
    features?.filter((feature) =>
      LAYERS_MAPBOX_IDS.includes(feature.layer.id)
    ) || [];

  return customFeatures;
};

const getCustomFeatureType = (feature) => {
  if (PROJECTS_LAYERS_IDS.includes(feature.layer.id)) return "project";

  if (["circle", "symbol"].includes(feature.layer.type)) return "point";

  if (feature.layer.type === "fill") return "fill";
};

const FeaturePopupSwitcher = ({ feature, onClose }) => {
  switch (feature.type) {
    case "point":
      return (
        <PointFeaturePopup
          feature={feature.feature}
          lngLat={feature.lngLat}
          onClose={onClose}
          layer={feature.layer}
        />
      );
    case "fill":
      return null;
    // return (
    //   <FillFeaturePopup
    //     feature={feature.feature}
    //     lngLat={feature.lngLat}
    //     onClose={onClose}
    //     layer={feature.layer}
    //   />
    // );
    default:
      return null;
  }
};

const AppearTransition = ({ children }) => (
  <CSSTransition
    appear
    in
    classNames="fade"
    addEndListener={(node, done) =>
      node.addEventListener("transitionend", done, false)
    }
  >
    {children}
  </CSSTransition>
);

// TODO: понять чем mapPage отличается от текущего компонента
const Map = React.memo(
  ({
    onMapLoad,
    initialZoom = 3.85,
    initialLng = 13.118,
    initialLat = 49.015,
    onInteractionStateChange,
    onSelectFeature,
    legend,
    topLeftControl,
    topRightControl,
    onViewportChange,
  }) => {
    const containerRef = useRef();
    const { md } = useResponsive();

    const [satelliteView, setSatelliteView] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState();
    const [mapInstance, setMapInstance] = useState();

    const [viewport, setViewport] = useState({
      longitude: initialLng,
      latitude: initialLat,
      zoom: initialZoom,
    });

    const projectSelected = useMemo(
      () => selectedFeature?.type === "project",
      [selectedFeature]
    );

    const onViewportChangeHandler = (vp) => {
      setViewport(vp);
      onViewportChange(vp);
    };

    useClickAway(() => {
      setSelectedFeature();
    }, containerRef);

    useEffect(() => {
      onSelectFeature?.(selectedFeature);
    }, [selectedFeature, onSelectFeature]);

    const onMapClick = useCallback((event) => {
      const customFeature = filterNativeFeatures(event.features)[0];
      if (!customFeature) {
        setSelectedFeature(null);
        return;
      }

      const featureType = getCustomFeatureType(customFeature);

      let { lngLat } = event;
      if (featureType === "point") {
        lngLat = [...customFeature.geometry.coordinates];
      }

      const layer = LAYERS.find((l) => l.mapboxId === customFeature.layer.id);

      setSelectedFeature({
        feature: customFeature,
        lngLat,
        type: featureType,
        layer,
      });
    }, []);

    useEffect(() => {
      if (mapInstance) {
        onMapLoad?.(mapInstance);
      }
    }, [mapInstance, onMapLoad]);

    useEffect(() => {
      const onMoveEnd = () => {
        onInteractionStateChange?.();
      };

      return () => mapInstance?.off("moveend", onMoveEnd);
    }, [mapInstance, onInteractionStateChange]);

    useEffect(() => {
      if (!mapInstance) return;
      mapInstance.setLayoutProperty(
        "satellite",
        "visibility",
        satelliteView ? "visible" : "none"
      );
      mapInstance.setLayoutProperty(
        "satellite-background",
        "visibility",
        satelliteView ? "visible" : "none"
      );
    }, [satelliteView, mapInstance]);

    return (
      <div
        className={cs([
          styles.mapContainer,
          !!mapInstance && styles["mapContainer--loaded"],
        ])}
        ref={containerRef}
      >
        {projectSelected && (
          <AppearTransition>
            <div className={styles.projectPopup}>
              <ProjectPopup
                feature={selectedFeature?.feature}
                onClose={() => setSelectedFeature(null)}
                setViewport={setViewport}
              />
            </div>
          </AppearTransition>
        )}
        <div className={styles.map}>
          <MapGL
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
            onClick={onMapClick}
            onLoad={(e) => setMapInstance(e.target)}
            {...viewport}
            height="100%"
            width="100%"
            onViewportChange={
              (md || !selectedFeature) && onViewportChangeHandler
            }
            interactiveLayerIds={LAYERS_MAPBOX_IDS}
            dragRotate={false}
            attributionControl={false}
            dragPan
            minZoom={2}
          >
            <div className={styles.attributionController}>
              <AttributionControl compact />
            </div>
            <div className={styles.zoomController}>
              <ZoomController setViewport={setViewport} zoom={viewport.zoom} />
            </div>
            <div className={styles.topRightControl}>
              <Space block direction="vertical" size={13}>
                {topRightControl && (
                  <AppearTransition>{topRightControl}</AppearTransition>
                )}
                <MapButton
                  icon={
                    <img
                      src={satelliteView ? satelliteIconActive : satelliteIcon}
                      alt=""
                      width="30"
                      height="30"
                    />
                  }
                  onClick={() => setSatelliteView((v) => !v)}
                />
              </Space>
            </div>
            {topLeftControl && (
              <div className={styles.topLeftControl}>{topLeftControl}</div>
            )}
            {legend && !projectSelected && (
              <AppearTransition>
                <div className={styles.legendController}>{legend}</div>
              </AppearTransition>
            )}
            {selectedFeature && !projectSelected && (
              <AppearTransition>
                <div className={styles.featurePopup}>
                  <FeaturePopupSwitcher
                    feature={selectedFeature}
                    onClose={() => setSelectedFeature(null)}
                  />
                </div>
              </AppearTransition>
            )}
            {selectedFeature && selectedFeature.type === "point" && (
              <AppearTransition>
                <div className={styles.activeFeaturePin}>
                  <Marker
                    longitude={selectedFeature.lngLat[0]}
                    latitude={selectedFeature.lngLat[1]}
                    offsetLeft={-21}
                    offsetTop={-40}
                  >
                    <img src={activeFeaturePin} alt="" />
                  </Marker>
                </div>
              </AppearTransition>
            )}
          </MapGL>
        </div>
      </div>
    );
  }
);

export default Map;
