import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import ReportResult from "./ReportResult";
import { reportResultPdfQuery } from "../../api/queries";

// TODO: переделать onLoad
const ReportResultConnected = React.forwardRef(({ onLoad, reportId }, ref) => {
  const [loaded, setLoaded] = useState();
  const [response] = useQuery({
    query: reportResultPdfQuery,
    variables: { id: reportId },
    requestPolicy: "network-only",
  });
  const actReports = response.data?.eu_taxonomy_activities_reports;
  const report = response.data?.eu_taxonomy_reports_by_pk;

  const { fetching } = response;

  useEffect(() => {
    if (!fetching && !loaded) {
      onLoad();
      setLoaded(true);
    }
  }, [fetching, onLoad, setLoaded, loaded]);

  if (fetching) return null;

  return <ReportResult ref={ref} actReports={actReports} report={report} />;
});

export default ReportResultConnected;
