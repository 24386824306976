import { useMemo } from "react";
import { useStore } from "effector-react";
import { $currentUser } from "../../entities/User/model";

// eslint-disable-next-line import/prefer-default-export
export const useCurrentUser = () => {
  const { loadingUser, loadingSession, user } = useStore($currentUser);

  const company = useMemo(() => {
    const counterparties = {};
    if (user?.company?.type === "consultant") {
      counterparties.clients =
        user?.company?.companyConsultantsByConsultantId?.map(
          (counterparty) => counterparty.company
        );
    }
    if (user?.company?.type === "client") {
      counterparties.consultants = user?.company?.company_consultants?.map(
        (counterparty) => counterparty.companyByConsultantId
      );
    }
    return {
      name: user?.company?.name,
      type: user?.company?.type,
      id: user?.company?.id,
      settings: user?.company?.settings,
      ...counterparties,
    };
  }, [user]);

  const userState = useMemo(
    () => ({
      fetching: loadingUser || loadingSession,
      error: null,
      company,
      userRole: "",
      data: user,
    }),
    [user, company, loadingSession, loadingUser]
  );

  return [userState];
};
