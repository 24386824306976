import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import styles from "./ActionsDropdown.module.less";

const ActionsDropdownOverlay = ({ actions, onMenuClick }) => (
  <Menu
    onClick={onMenuClick}
    className={styles.menu}
    data-cy="activity-actions"
  >
    {actions.map(({ type, label, onClick, icon, disabled }) => (
      <Menu.Item
        key={type}
        onClick={() => onClick()}
        icon={icon}
        disabled={disabled}
        className={styles.menuItem}
      >
        {label}
      </Menu.Item>
    ))}
  </Menu>
);

const ActionsDropdown = ({
  actions,
  disabled,
  children,
  placement = "bottomRight",
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div role="none" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={
          <ActionsDropdownOverlay
            actions={actions}
            onMenuClick={() => setVisible(false)}
          />
        }
        destroyPopupOnHide
        placement={placement}
        visible={visible && !disabled}
        disabled={disabled}
        trigger={["click"]}
        onVisibleChange={(value) => setVisible(value)}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default ActionsDropdown;
