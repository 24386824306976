import React from "react";
import cs from "classnames";
import { Button, Typography } from "antd";
import { ReactComponent as NotFoundIcon } from "../../../assets/icons/notFound.svg";
import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";
import styles from "./MobileStopperPortrait.module.less";
import { Text } from "../../../antd-ovveride";

const { Title } = Typography;

const MobileStopperPortrait = () => (
  <div className={cs(styles.container)}>
    <div className={styles.logo}>
      <LogoIcon />
    </div>
    <div className={styles.notFoundIcon}>
      <NotFoundIcon />
    </div>
    <Title level={3} style={{ margin: 0 }}>
      Oooops!
    </Title>
    <Text size="large" type="secondary" className={styles.textBlock}>
      To use the service, you must use <br />
      the desktop version
    </Text>
    <Button
      type="primary"
      size="large"
      style={{ display: "inline-flex" }}
      href="https://www.evercity.io/"
    >
      Visit site
    </Button>
  </div>
);

export default MobileStopperPortrait;
