import { gql } from "urql";
import {
  reportFieldsFragment,
  companyFieldsFragment,
  activityReportFieldsFragment,
  unitFieldsFragment,
  unitPointDecisionFragment,
  reportPointDecisionFragment,
  activitiesReportsFieldsExtendedFragment,
  unitDecisionAttachmentFragment,
  docFragment,
  reportDecisionAttachmentFragment,
  reportAttachmentsFragment,
  unitAttachmentsFragment,
  frameworkFragment,
  excludedCategoriesFrameworkFragment,
  exampleCategoryFrameworkFragment,
  goalFragment,
  naceCodeFragment,
  pinFragment,
  threadFragment,
  messageFragment,
} from "./fragments";

export const updateReportMutation = gql`
  ${reportFieldsFragment}
  mutation updateReport(
    $reportId: uuid!
    $changes: eu_taxonomy_reports_set_input
  ) {
    update_eu_taxonomy_reports_by_pk(
      _set: $changes
      pk_columns: { id: $reportId }
    ) {
      ...reportFields
    }
  }
`;

export const createReportMutation = gql`
  ${reportFieldsFragment}
  mutation insertReportOne($report: eu_taxonomy_reports_insert_input!) {
    insert_eu_taxonomy_reports_one(
      object: $report
      on_conflict: { constraint: report_pkey, update_columns: [] }
    ) {
      ...reportFields
    }
  }
`;

export const deleteReportMutation = gql`
  mutation deleteReport($id: uuid!) {
    delete_eu_taxonomy_reports_by_pk(id: $id) {
      id
      company_id
    }
  }
`;

export const updateCompanyMutation = gql`
  ${companyFieldsFragment}
  mutation updateCompany($id: uuid!, $changes: companies_set_input!) {
    update_companies_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...companyFields
    }
  }
`;

export const createActivityReportMutation = gql`
  ${activitiesReportsFieldsExtendedFragment}
  mutation createActivitiesReports(
    $objects: [eu_taxonomy_activities_reports_insert_input!]!
  ) {
    insert_eu_taxonomy_activities_reports(objects: $objects) {
      returning {
        ...activitiesReportsFieldsExtended
      }
    }
  }
`;

export const deleteActivityReportMutation = gql`
  ${activityReportFieldsFragment}
  mutation deleteActivityReport($id: uuid!) {
    delete_eu_taxonomy_activities_reports_by_pk(id: $id) {
      ...activityReportFields
    }
  }
`;

export const createUnitMutation = gql`
  ${unitFieldsFragment}
  mutation createUnit($unit: eu_taxonomy_units_insert_input!) {
    insert_eu_taxonomy_units_one(object: $unit) {
      ...unitFields
    }
  }
`;
export const updateUnitMutation = gql`
  ${unitFieldsFragment}
  mutation updateUnit($unitId: uuid!, $changes: eu_taxonomy_units_set_input!) {
    update_eu_taxonomy_units_by_pk(
      pk_columns: { id: $unitId }
      _set: $changes
    ) {
      ...unitFields
    }
  }
`;

export const deleteUnitMutation = gql`
  ${unitFieldsFragment}
  mutation deleteUnit($id: uuid!) {
    delete_eu_taxonomy_units_by_pk(id: $id) {
      ...unitFields
      activities_report {
        id
        report_id
      }
    }
  }
`;

export const upsertUnitPointDecisionMutation = gql`
  ${unitPointDecisionFragment}
  mutation upsert_eu_taxonomy_unit_point_decision(
    $point_id: Int!
    $unit_id: uuid!
    $decision: eu_taxonomy_point_decisions_enum!
  ) {
    insert_eu_taxonomy_unit_point_decision_one(
      object: { point_id: $point_id, decision: $decision, unit_id: $unit_id }
      on_conflict: {
        constraint: unit_point_decision_point_id_unit_id_key
        update_columns: [point_id, decision]
      }
    ) {
      ...unitPointDecision
    }
  }
`;

export const upsertReportPointDecisionMutation = gql`
  ${reportPointDecisionFragment}
  mutation upsert_eu_taxonomy_report_point_decision(
    $report_point_id: Int!
    $report_id: uuid!
    $decision: eu_taxonomy_point_decisions_enum!
  ) {
    insert_eu_taxonomy_report_point_decision_one(
      object: {
        report_id: $report_id
        report_point_id: $report_point_id
        decision: $decision
      }
      on_conflict: {
        constraint: report_point_decision_report_point_id_report_id_key
        update_columns: [decision]
      }
    ) {
      ...reportPointDecision
    }
  }
`;

export const validateUnitPointsMutation = gql`
  mutation validateUnitPoints($unitId: uuid!, $points_type: String) {
    validate_unit_points(id: $unitId, points_type: $points_type) {
      success
      status
    }
  }
`;

export const loginMutation = `
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwt_token
      jwt_expires_in
      user
    }
  }
`;

export const insertMarketingDemoAccountsMutation = gql`
  mutation insert_marketing_demo_accounts(
    $email: String!
    $name: String!
    $origin_url: String!
  ) {
    insert_marketing_demo_accounts(
      objects: { email: $email, origin_url: $origin_url, name: $name }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const updateMarketingDemoAccountMutation = gql`
  mutation updateMarketingDemoAccount(
    $id: uuid!
    $company_name: String!
    $product_interests: _text!
    $company_type: String
  ) {
    update_marketing_demo_accounts_by_pk(
      pk_columns: { id: $id }
      _set: {
        product_interests: $product_interests
        company_type: $company_type
        company_name: $company_name
      }
    ) {
      id
    }
  }
`;

// export const signUpMutation = `
//   mutation ($email: String!, $password: String!) {
//     register(email: $email, password: $password) {
//       jwt_token
//       jwt_expires_in
//       user
//     }
//   }
// `;

export const uploadFileBase64 = gql`
  mutation uploadFileBase64(
    $base64str: String!
    $docname: String!
    $filename: String!
    $mimetype: String!
  ) {
    upload_file_base64(
      base64str: $base64str
      docname: $docname
      filename: $filename
      mimetype: $mimetype
    ) {
      doc_id
      url
    }
  }
`;

export const attachDocToUnitMutation = gql`
  ${unitAttachmentsFragment}
  mutation attachDocToUnit(
    $doc_id: uuid!
    $unit_id: uuid!
    $comment: String = ""
  ) {
    insert_eu_taxonomy_unit_attachments_one(
      object: { doc_id: $doc_id, unit_id: $unit_id, comment: $comment }
    ) {
      ...unitAttachments
    }
  }
`;

export const unattachDocFromUnitMutation = gql`
  mutation unattachDocFromUnit($doc_id: uuid!, $unit_id: uuid!) {
    delete_eu_taxonomy_unit_attachments_by_pk(
      doc_id: $doc_id
      unit_id: $unit_id
    ) {
      doc_id
      unit_id
    }
  }
`;

export const attachDocToUnitPointDecisionMutation = gql`
  ${unitDecisionAttachmentFragment}
  mutation attachDocToUnitPointDecision(
    $doc_id: uuid!
    $unit_point_decision_id: uuid!
    $comment: String = ""
  ) {
    insert_eu_taxonomy_unit_decision_attachments_one(
      object: {
        doc_id: $doc_id
        unit_point_decision_id: $unit_point_decision_id
        comment: $comment
      }
    ) {
      ...unitDecisionAttachment
    }
  }
`;

export const unattachDocFromUnitPointDecisionMutation = gql`
  ${unitDecisionAttachmentFragment}
  mutation unattachDocFromUnitPointDecision(
    $doc_id: uuid!
    $unit_point_decision_id: uuid!
  ) {
    delete_eu_taxonomy_unit_decision_attachments_by_pk(
      doc_id: $doc_id
      unit_point_decision_id: $unit_point_decision_id
    ) {
      ...unitDecisionAttachment
    }
  }
`;

export const attachDocToReportPointDecisionMutation = gql`
  ${reportDecisionAttachmentFragment}
  ${docFragment}
  mutation attachDocToReportPointDecision(
    $doc_id: uuid!
    $report_decision_id: uuid!
    $comment: String = ""
  ) {
    insert_eu_taxonomy_report_decision_attachments_one(
      object: {
        doc_id: $doc_id
        report_decision_id: $report_decision_id
        comment: $comment
      }
    ) {
      ...reportDecisionAttachment
    }
  }
`;

export const attachDocToReportMutation = gql`
  ${reportAttachmentsFragment}
  mutation attachDocToReport(
    $doc_id: uuid!
    $report_id: uuid!
    $comment: String = ""
  ) {
    insert_eu_taxonomy_report_attachments_one(
      object: { doc_id: $doc_id, report_id: $report_id, comment: $comment }
    ) {
      ...reportAttachments
    }
  }
`;

export const unattachDocFromReportMutation = gql`
  mutation unattachDocFromReport($doc_id: uuid!, $report_id: uuid!) {
    delete_eu_taxonomy_report_attachments_by_pk(
      doc_id: $doc_id
      report_id: $report_id
    ) {
      doc_id
      report_id
    }
  }
`;

export const unattachDocFromReportPointDecisionMutation = gql`
  mutation unattachDocFromReportPointDecision(
    $doc_id: uuid!
    $report_decision_id: uuid!
  ) {
    delete_eu_taxonomy_report_decision_attachments_by_pk(
      doc_id: $doc_id
      report_decision_id: $report_decision_id
    ) {
      doc_id
      report_decision_id
    }
  }
`;

export const createDocMutation = gql`
  ${docFragment}
  mutation createDoc(
    $name: String!
    $url: String!
    $mimetype: String!
    $user_id: uuid!
  ) {
    insert_docs_one(
      object: { name: $name, url: $url, mimetype: $mimetype, user_id: $user_id }
    ) {
      ...doc
    }
  }
`;

export const deleteDocMutation = gql`
  ${docFragment}
  mutation deleteDoc($id: uuid!) {
    delete_docs_by_pk(id: $id) {
      ...doc
    }
  }
`;

export const updateDocMutation = gql`
  ${docFragment}
  mutation updateDoc($id: uuid!, $set: docs_set_input!) {
    update_docs_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...doc
    }
  }
`;

export const generateReportXls = gql`
  mutation generateReportXls($reportId: uuid!, $locale: String) {
    generate_report_xls(reportId: $reportId, locale: $locale) {
      doc_id
      url
    }
  }
`;

export const generateReportPdf = gql`
  mutation generateReportPdf($reportId: uuid!) {
    generate_report_pdf(reportId: $reportId) {
      doc_id
      url
    }
  }
`;

export const createFrameworkMutation = gql`
  ${frameworkFragment}
  mutation createFramework($standard: String, $companyId: uuid) {
    insert_frameworks_one(
      object: { standard: $standard, company_id: $companyId }
    ) {
      ...framework
    }
  }
`;

export const updateFrameworkMutation = gql`
  ${frameworkFragment}
  mutation updateFramework($id: uuid!, $data: frameworks_set_input) {
    update_frameworks_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...framework
    }
  }
`;

export const setRelationFrameworkExcludedCategoriesMutation = gql`
  ${excludedCategoriesFrameworkFragment}
  mutation upsertExcludedCategory($mainEntityId: uuid!, $propertyId: uuid) {
    insert_framework_excluded_categories_one(
      object: { excluded_category_id: $propertyId, framework_id: $mainEntityId }
    ) {
      excluded_category {
        ...excludedCategories
      }
    }
  }
`;

export const deleteRelationFrameworkExcludedCategoriesMutation = gql`
  ${excludedCategoriesFrameworkFragment}
  mutation deleteExcludedCategory($mainEntityId: uuid!, $propertyId: uuid!) {
    delete_framework_excluded_categories_by_pk(
      framework_id: $mainEntityId
      excluded_category_id: $propertyId
    ) {
      excluded_category {
        ...excludedCategories
      }
    }
  }
`;

export const setRelationFrameworkExampleCategoriesMutation = gql`
  ${exampleCategoryFrameworkFragment}
  mutation upsertExampleCategory($mainEntityId: uuid!, $propertyId: uuid!) {
    insert_example_categories_one(
      object: { category_id: $propertyId, framework_id: $mainEntityId }
    ) {
      ...exampleCategory
    }
  }
`;

export const deleteRelationFrameworkExampleCategoriesMutation = gql`
  ${frameworkFragment}
  mutation deleteExampleCategory($propertyId: uuid!) {
    delete_example_categories_by_pk(id: $propertyId) {
      ...framework
    }
  }
`;

export const setRelationFrameworkExampleCategoriesInfoMutation = gql`
  ${frameworkFragment}
  ${exampleCategoryFrameworkFragment}
  ${goalFragment}

  mutation upsertExampleCategoryInfo(
    $mainEntityId: uuid!
    $propertyId: [example_category_goals_insert_input!]!
    $projectExamples: jsonb
    $impactIndicators: jsonb
  ) {
    insert_example_category_goals(objects: $propertyId) {
      returning {
        goal {
          ...goal
        }
      }
    }
    update_example_categories_by_pk(
      pk_columns: { id: $mainEntityId }
      _set: {
        project_examples: $projectExamples
        impact_indicators: $impactIndicators
      }
    ) {
      ...exampleCategory
    }
  }
`;

export const updateExampleCategoryFrameworkMutation = gql`
  ${goalFragment}
  mutation updateExampleCategory(
    $categoryId: uuid!
    $goals: [example_category_goals_insert_input!]!
    $projectExamples: jsonb!
    $impactIndicators: jsonb!
  ) {
    update_example_categories(
      where: { id: { _eq: $categoryId } }
      _set: {
        impact_indicators: $impactIndicators
        project_examples: $projectExamples
      }
    ) {
      returning {
        id
        project_examples
        impact_indicators
      }
    }
    delete_example_category_goals(
      where: { example_category_id: { _eq: $categoryId } }
    ) {
      returning {
        example_category_id
      }
    }
    insert_example_category_goals(objects: $goals) {
      returning {
        goal {
          ...goal
        }
      }
    }
  }
`;
export const deleteRelationFrameworkExampleCategoriesInfoMutation = gql`
  ${frameworkFragment}
  mutation deleteExampleCategoryGoals($mainEntityId: uuid!, $propertyId: Int!) {
    delete_example_category_goals_by_pk(
      example_category_id: $mainEntityId
      goal_id: $propertyId
    ) {
      ...framework
    }
  }
`;

export const deleteFrameworkMutation = gql`
  mutation deleteFramework($id: uuid!) {
    delete_frameworks_by_pk(id: $id) {
      id
    }
  }
`;

export const updateActReportsCodes = gql`
  ${naceCodeFragment}
  mutation updateActReportsCodes(
    $addCodes: [eu_taxonomy_activities_reports_codes_insert_input!]!
    $removeCodes: [Int!]
  ) {
    insert_eu_taxonomy_activities_reports_codes(objects: $addCodes) {
      returning {
        code_id
        activity_report_id
        nace_code {
          ...naceCode
        }
      }
    }
    delete_eu_taxonomy_activities_reports_codes(
      where: { nace_code: { id: { _in: $removeCodes } } }
    ) {
      returning {
        code_id
        activity_report_id
      }
    }
  }
`;

export const generateFramework = gql`
  mutation generateFramework($id: uuid!) {
    generateFramework(id: $id) {
      url
      doc_id
    }
  }
`;

export const removeUserMutation = gql`
  mutation removeUser($id: uuid!) {
    delete_auth_accounts(where: { user_id: { _eq: $id } }) {
      returning {
        user_id
      }
    }
    delete_users_by_pk(id: $id) {
      id
    }
  }
`;

export const generateInvitationLinkMutation = gql`
  mutation generateInvitation($invType: String!) {
    generate_invitation_link(inv_type: $invType)
  }
`;

export const joinByInvitation = gql`
  mutation joinByInvitation(
    $companyName: String
    $email: String!
    $password: String!
    $token: String!
    $userName: String!
  ) {
    join_by_invitation(
      company_name: $companyName
      email: $email
      password: $password
      token: $token
      user_name: $userName
    ) {
      jwt_token
      jwt_expires_in
      user
      success
    }
  }
`;

export const assignManagerMutation = gql`
  ${reportFieldsFragment}
  mutation assignManager($userId: uuid, $reportId: uuid!) {
    update_eu_taxonomy_reports_by_pk(
      pk_columns: { id: $reportId }
      _set: { manager_id: $userId }
    ) {
      ...reportFields
    }
  }
`;

export const assignConsultantMutation = gql`
  mutation assignConsultant(
    $consultantId: uuid!
    $clientId: uuid!
    $userId: uuid
  ) {
    update_company_consultants_by_pk(
      pk_columns: { consultant_id: $consultantId, client_id: $clientId }
      _set: { manager_id: $userId }
    ) {
      company {
        id
        company_consultants {
          manager_id
        }
      }
    }
  }
`;

export const attachDocsToProjectMutation = gql`
  ${docFragment}
  mutation attachDocsToProject($attachments: [project_docs_insert_input!]!) {
    insert_project_docs(objects: $attachments) {
      returning {
        doc {
          ...doc
        }
        doc_id
        project_id
        attachment_type
      }
    }
  }
`;

export const unAttachDocFromProjectMutation = gql`
  mutation unAttachDocFromProject($projectId: uuid!, $docId: uuid!) {
    delete_project_docs_by_pk(doc_id: $docId, project_id: $projectId) {
      doc_id
      project_id
    }
  }
`;

export const insertOfferMutation = gql`
  mutation insertOffer($offer: offers_insert_input!) {
    insert_offers_one(object: $offer) {
      id
    }
  }
`;

export const requestAccessToCommodityMutation = gql`
  mutation requestAccessToCommodity(
    $application: ApplicationInput!
    $offer: OfferInput
    $commodityId: uuid!
  ) {
    request_access_to_commodity(
      application: $application
      offer: $offer
      commodity_id: $commodityId
    )
  }
`;

export const createPINMutation = gql`
  mutation createPin($companyId: uuid!) {
    insert_commodity_PINs_one(object: { company_id: $companyId }) {
      id
      company_id
      created_at
      __typename
    }
  }
`;

export const updatePINMutation = gql`
  ${pinFragment}
  mutation updatePin($id: uuid!, $pin: jsonb) {
    update_commodity_PINs_by_pk(pk_columns: { id: $id }, _set: { data: $pin }) {
      ...pin
    }
  }
`;

export const deletePINMutation = gql`
  ${pinFragment}
  mutation deletePIN($id: uuid!) {
    delete_commodity_PINs_by_pk(id: $id) {
      id
    }
  }
`;

export const generatePINMutation = gql`
  mutation generatePIN($id: uuid!) {
    generate_pin(id: $id) {
      url
    }
  }
`;

export const publishPINMutation = gql`
  mutation publishPIN($id: uuid!, $date: date!) {
    update_commodity_PINs_by_pk(
      pk_columns: { id: $id }
      _set: { publication_date: $date, status: "published" }
    ) {
      id
    }
  }
`;

export const completePinMutation = gql`
  ${pinFragment}
  mutation publishPIN($id: uuid!) {
    update_commodity_PINs_by_pk(
      pk_columns: { id: $id }
      _set: { status: "completed" }
    ) {
      ...pin
    }
  }
`;

export const updateReportMSSTypeMutation = gql`
  mutation updateReportMSSType($id: uuid!, $type: eu_taxonomy_mss_types_enum!) {
    update_eu_taxonomy_reports_by_pk(
      pk_columns: { id: $id }
      _set: { mss_type: $type }
    ) {
      id
    }
  }
`;

export const validateMSSPointsMutation = gql`
  mutation validateMSSPoints($reportId: uuid!) {
    validateReportPoints(reportId: $reportId) {
      status
      success
    }
  }
`;

export const createThreadMutation = gql`
  ${threadFragment}
  mutation createThread($thread: messenger_threads_insert_input!) {
    insert_messenger_threads_one(object: $thread) {
      ...thread
    }
  }
`;

export const updateThreadMutation = gql`
  ${threadFragment}
  mutation updateThread($id: uuid!, $changes: messenger_threads_set_input!) {
    update_messenger_threads_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...thread
    }
  }
`;

export const deleteThreadMutation = gql`
  mutation deleteThread($id: uuid!) {
    delete_messenger_threads_by_pk(id: $id) {
      id
    }
  }
`;

export const createThreadMessageMutation = gql`
  ${messageFragment}
  mutation createThreadMessage($message: messenger_messages_insert_input!) {
    insert_messenger_messages_one(object: $message) {
      ...message
    }
  }
`;

export const deleteThreadMessageMutation = gql`
  mutation deleteThreadMessage($id: uuid!) {
    delete_messenger_messages_by_pk(id: $id) {
      id
      thread_id
    }
  }
`;

export const updateThreadMessageMutation = gql`
  ${messageFragment}
  mutation updateThreadMessage(
    $id: uuid!
    $changes: messenger_messages_set_input!
  ) {
    update_messenger_messages_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...message
    }
  }
`;

export const createMentionMutation = gql`
  mutation createMention($mentions: [messenger_mentions_insert_input!]!) {
    insert_messenger_mentions(objects: $mentions) {
      __typename
    }
  }
`;

export const deleteMentionMutation = gql`
  mutation deleteMentions($messageId: uuid!) {
    delete_messenger_mentions(where: { message_id: { _eq: $messageId } }) {
      __typename
    }
  }
`;

export const generateChartsReportPdf = gql`
  mutation generateReportPdf($reportId: uuid!) {
    genReportPDFCharts(reportId: $reportId) {
      doc_id
      url
    }
  }
`;
