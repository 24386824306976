import React from "react";
import { string } from "prop-types";
import { Statistic, Typography } from "antd";

const { Text } = Typography;

const Informer = ({ name, value, sum }) => {
  const title = (
    <span>
      {`${name}, `}
      <Text type="success">{value}</Text>
    </span>
  );
  return <Statistic title={title} value={sum} />;
};

Informer.propTypes = {
  name: string.isRequired,
  sum: string.isRequired,
  value: string.isRequired,
};

export default Informer;
