import cloneDeep from "lodash.clonedeep";

const flatToNested = (
  flatItems,
  { parentProperty = "parent_id", nestedProperty = "nested_nodes" }
) => {
  if (!flatItems) {
    return [];
  }
  const cloneNodes = cloneDeep(flatItems);

  cloneNodes.forEach((node) => {
    if (node[parentProperty]) {
      const parent = cloneNodes.find((p) => p.id === node[parentProperty]);
      if (parent) {
        parent[nestedProperty] ||= [];
        parent[nestedProperty].push(node);
      }
    }
  });
  return cloneNodes.filter((node) => !node[parentProperty]);
};

export default flatToNested;
