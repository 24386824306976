import React, { useEffect, useRef, useState } from "react";
import { Form, ContentLayout, Progress } from "@evercityecosystem/evercity-ui";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import useStepsNavigation from "../../hooks/useStepsNavigation";
import NavigateFlow from "../NavigateFlow/NavigateFlow";
import validationSchema from "./validationSchema";
import LkLayout from "../LkLayout/LkLayout";
import useCalcTotalProgress from "../../hooks/useCalcTotalProgress";
import ProjectIdeaNoteHeaderConnected from "./ProjectIdeaNoteHeaderConnected";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";
import DiscussionDrawer from "../DiscussionDrawer/DiscussionDrawer";
import { useCurrentUser } from "../../hooks/user";

const steps = [
  {
    id: 0,
    title: "Project summary",
    route: "summary",
    threadGroup: MESSENGER_GROUPS.PIN.SUMMARY,
  },
  {
    id: 1,
    title: "Project details",
    route: "details",
    threadGroup: MESSENGER_GROUPS.PIN.DETAILS,
  },
  {
    id: 2,
    title: "Expected greenhouse gas emission reductions",
    route: "emission",
    threadGroup: MESSENGER_GROUPS.PIN.EMISSION,
  },
  {
    id: 3,
    title: "Environment, economic and social co-benefits",
    route: "benefits",
    threadGroup: MESSENGER_GROUPS.PIN.BENEFITS,
  },
  {
    id: 4,
    title: "Preliminary assessment of viability",
    route: "viability",
    optional: true,
    threadGroup: MESSENGER_GROUPS.PIN.VIABILITY,
  },
];

const ProjectIdeaNoteFlow = ({ onChangePIN, pin, openedDiscussionThread }) => {
  const navigate = useNavigate();
  const [, setParams] = useSearchParams();
  const [userState] = useCurrentUser();
  const companyConfig = userState?.data?.company?.settings;

  const withoutThreshold = companyConfig?.PIN?.threshold === "false";

  const flowRef = useRef();
  const [stepIndex, setStepIndex] = useStepsNavigation(
    steps,
    false,
    "company/pins"
  );

  const totalProgress = useCalcTotalProgress({
    stepsSchema: validationSchema,
    data: pin?.data,
  });

  const containerRef = useRef();

  const handleSubmit = () => {
    setStepIndex(stepIndex + 1);
    setTimeout(() => {
      containerRef.current?.scrollIntoView({
        block: "start",
      });
    }, 0);
  };

  const handleBack = () => {
    setStepIndex(stepIndex - 1);
    setTimeout(() => {
      containerRef.current?.scrollIntoView({
        block: "start",
      });
    }, 0);
  };

  const handleComplete = () => {
    navigate("/company/pins");
  };

  const [drawerState, setDrawerState] = useState(null);

  const onChangeDrawerState = ({ currentTopic, currentProperty }) => {
    setDrawerState({
      ...drawerState,
      property: currentProperty,
      topic: currentTopic,
      trigger: {
        source: "drawer",
      },
    });
    setParams({});
  };

  const openDrawer = ({ status, group, property, topic, trigger }) => {
    setDrawerState({
      status,
      group,
      property,
      topic,
      entity: MESSENGER_ENTITIES.PIN,
      entityId: pin.id,
      trigger,
    });
  };

  const closeDrawer = () => {
    setDrawerState(null);
    setParams({});
  };

  const isOpenDrawer = !!drawerState;

  useEffect(() => {
    if (isOpenDrawer) {
      setDrawerState((config) => {
        let property = config?.property;

        if (config?.group !== steps[stepIndex]?.threadGroup) {
          property = null;
        }

        return {
          ...config,
          group: steps[stepIndex]?.threadGroup,
          property,
        };
      });
    }
  }, [stepIndex, isOpenDrawer]);

  const onClickTopic = (event, { topic, property }) => {
    setDrawerState({
      ...drawerState,
      topic,
      property,
      trigger: {
        event,
        source: "drawer",
      },
    });
  };
  useEffect(() => {
    if (openedDiscussionThread) {
      setDrawerState({
        threadId: openedDiscussionThread.id,
        topic: openedDiscussionThread.topic,
        ...openedDiscussionThread.config,
        status: openedDiscussionThread.thread_state.name,
        trigger: {
          source: "link",
        },
      });
    }
  }, [openedDiscussionThread]);

  return (
    <LkLayout
      header={{
        content: <ProjectIdeaNoteHeaderConnected />,
      }}
    >
      <div ref={containerRef}>
        <DiscussionDrawer
          onClickTopic={onClickTopic}
          flowRef={flowRef}
          onClose={closeDrawer}
          config={drawerState}
          onChangeDrawerState={onChangeDrawerState}
        />
        <ContentLayout.Aside
          asideColProps={{
            sticky: true,
          }}
          aside={
            <Progress
              type="circle"
              title="PIN progress"
              subtitle={
                <>
                  {!withoutThreshold && "70% is required for PIN creation"}
                  Your progress is saved automatically
                </>
              }
              requiredPercent={!withoutThreshold ? 70 : 0}
              percent={totalProgress}
            />
          }
        >
          <div ref={flowRef}>
            <Form
              scrollToFirstError={{ behavior: "smooth", block: "center" }}
              initialValues={pin?.data}
              onValuesChange={(changes, changedValues) => {
                onChangePIN(changedValues);
              }}
              onFinish={handleSubmit}
            >
              <Outlet
                context={{
                  withoutThreshold,
                  onOpenDiscussionsDrawer: openDrawer,
                  discussionDrawerState: drawerState,
                }}
              />
              <NavigateFlow
                onComplete={handleComplete}
                onBack={handleBack}
                isFirstStep={stepIndex === 0}
                isLastStep={stepIndex === 4}
              />
            </Form>
          </div>
        </ContentLayout.Aside>
      </div>
    </LkLayout>
  );
};

export default ProjectIdeaNoteFlow;
