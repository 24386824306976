import React, { useState } from "react";
import { Form, Radio } from "antd";
import { Input } from "@antd-ovveride";
import styles from "./ManagementOfProceeds.module.less";

import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import Counter from "../../antd-ovveride/Counter/Counter";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import CheckboxGroupSpecify from "../CheckboxGroupSpecify/CheckboxGroupSpecify";

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { Item: FormItem } = Form;

const ManagementOfProceeds = ({ framework, updateFramework, title }) => {
  const [isSpecifiableAllocation, setIsSpecifiableAllocation] = useState(
    !["12", "24"].includes(framework.allocation_time)
  );
  return (
    <>
      {title}
      <FrameworkSection number="4">
        <FrameworkQuestion
          number="4.1"
          title="Which approach will be taken for management of proceeds?"
        >
          <FormItem
            name="manage_proceeds_approach"
            initialValue={framework.manage_proceeds_approach}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <RadioGroup
              className={styles.management}
              defaultValue={framework.manage_proceeds_approach}
              onChange={adapterDOMEvent(
                updateFramework("manage_proceeds_approach")
              )}
            >
              <Radio className={styles.managementRadio} value="Ringfencing">
                Ringfencing
              </Radio>
              <Radio className={styles.managementRadio} value="Earmarking">
                Earmarking
              </Radio>
              <Radio
                className={styles.managementRadio}
                value="Earmarking or ringfencing"
              >
                Earmarking or ringfencing
              </Radio>
            </RadioGroup>
          </FormItem>
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.2"
          title="Which approach will be taken for tracking of proceeds?"
        >
          <FormItem
            name="tracking_proceeds_approach"
            initialValue={framework.tracking_proceeds_approach}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <RadioGroup
              onChange={adapterDOMEvent(
                updateFramework("tracking_proceeds_approach")
              )}
              defaultValue={framework.tracking_proceeds_approach}
            >
              <Radio className={styles.managementRadio} value="Portfolio">
                Portfolio
              </Radio>
              <Radio
                className={styles.managementRadio}
                value="Issuance by issuance"
              >
                Issuance by issuance
              </Radio>
            </RadioGroup>
          </FormItem>
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.3"
          title="Which department or body will track the proceeds?"
        >
          <FormItem
            name="track_proceeds_depart"
            initialValue={framework.track_proceeds_depart}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <Input
              onBlur={adapterDOMEvent(updateFramework("track_proceeds_depart"))}
              defaultValue={framework.track_proceeds_depart}
              className={styles.input}
            />
          </FormItem>
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.4"
          title="How frequently will the tracking information be updated?"
        >
          <FormItem
            name="track_update_frequency"
            initialValue={framework.track_update_frequency}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <RadioGroup
              defaultValue={framework.track_update_frequency}
              onChange={adapterDOMEvent(
                updateFramework("track_update_frequency")
              )}
            >
              <Radio className={styles.managementRadio} value="Quarterly">
                Quarterly
              </Radio>
              <Radio className={styles.managementRadio} value="Half-year">
                Half-year
              </Radio>
              <Radio className={styles.managementRadio} value="Yearly">
                Yearly
              </Radio>
            </RadioGroup>
          </FormItem>
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.5"
          title="What is a look-back period for expenditures (in years)?"
        >
          <div className={styles.containerCounter}>
            <FormItem
              name="lookback_assets"
              initialValue={framework.lookback_assets}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Counter
                defaultValue={framework.lookback_assets}
                onChange={updateFramework("lookback_assets")}
                title="For assets"
                placeholder="Year"
              />
            </FormItem>
            <FormItem
              name="lookback_expend"
              initialValue={framework.lookback_expend}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Counter
                defaultValue={framework.lookback_expend}
                onChange={updateFramework("lookback_expend")}
                title="For expenditures"
                placeholder="Year"
              />
            </FormItem>
            <FormItem
              name="lookback_projects"
              initialValue={framework.lookback_projects}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Counter
                defaultValue={framework.lookback_projects}
                onChange={updateFramework("lookback_projects")}
                title="For projects"
                placeholder="Year"
              />
            </FormItem>
            <FormItem
              name="lookback_invest"
              initialValue={framework.lookback_invest}
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Counter
                defaultValue={framework.lookback_invest}
                onChange={updateFramework("lookback_invest")}
                title="For investments"
                placeholder="Year"
              />
            </FormItem>
          </div>
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.6"
          title="Is the entire amount of proceeds going to be allocated at issuance?"
        >
          <FormItem
            name="allocation_at_issuance"
            initialValue={framework.allocation_at_issuance}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <RadioGroup
              onChange={adapterDOMEvent(
                updateFramework("allocation_at_issuance")
              )}
              defaultValue={framework.allocation_at_issuance}
            >
              <RadioButton value="Yes">Yes</RadioButton>
              <RadioButton value="No">No</RadioButton>
            </RadioGroup>
          </FormItem>
          {framework.allocation_at_issuance === "No" && (
            <FrameworkSection className={styles.blockAmountMonth}>
              <FrameworkQuestion
                number="4.6.1"
                title="Within how many months (maximum) will the proceeds be fully
                allocated?"
              >
                <FormItem
                  name="allocation_time"
                  initialValue={
                    ["12", "24"].includes(framework.allocation_time)
                      ? framework.allocation_time
                      : "specific"
                  }
                  rules={[{ required: true, message: "Field is required" }]}
                >
                  <RadioGroup
                    className={styles.radioGroup}
                    onChange={adapterDOMEvent((value) => {
                      if (value === "specific") {
                        return setIsSpecifiableAllocation(true);
                      }
                      updateFramework("allocation_time")(value);
                      setIsSpecifiableAllocation(false);
                    })}
                  >
                    <Radio value="12">12</Radio>
                    <Radio value="24">24</Radio>
                    <Radio value="specific">Other (specify)</Radio>
                  </RadioGroup>
                </FormItem>
                {isSpecifiableAllocation && (
                  <Counter
                    className={styles.allocation__counter}
                    defaultValue={
                      ![12, 24].includes(framework.allocation_time) &&
                      framework.allocation_time
                    }
                    onChange={updateFramework("allocation_time")}
                    placeholder="Months"
                  />
                )}
              </FrameworkQuestion>
            </FrameworkSection>
          )}
        </FrameworkQuestion>

        <FrameworkQuestion
          number="4.7"
          title="In case some proceeds are not allocated at issuance, how will they be managed before allocation?"
        >
          <FormItem
            name="manage_unallocated_proceeds"
            initialValue={framework.manage_unallocated_proceeds}
            rules={[{ required: true, message: "Field is required" }]}
          >
            <CheckboxGroupSpecify
              onChange={updateFramework("manage_unallocated_proceeds")}
              defaultValue={framework.manage_unallocated_proceeds}
              options={[
                {
                  name: "Held in temporary investment instruments that are cash, or cash equivalent instruments, within a Treasury function",
                },
                {
                  name: "Applied to temporarily reduce the indebtedness of a revolving nature",
                },
                {
                  name: "Other (specify)",
                  specifiable: true,
                },
              ]}
            />
          </FormItem>
        </FrameworkQuestion>
      </FrameworkSection>
    </>
  );
};

export default ManagementOfProceeds;
