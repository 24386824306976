import React from "react";
import { string } from "prop-types";
import { ExternalLink, Text } from "@antd-ovveride";
import normalizeUrl from "normalize-url";

const ProductCompanyLink = ({ url }) => {
  const isUrl = url && url !== "N/A";
  if (!isUrl) return <Text size="extra-large">{url}</Text>;
  return (
    <ExternalLink
      href={normalizeUrl(url, {
        forceHttps: true,
      })}
      size="extra-large"
      target="_blank"
    >
      {normalizeUrl(url, {
        stripProtocol: true,
      })}
    </ExternalLink>
  );
};

ProductCompanyLink.propTypes = {
  url: string.isRequired,
};

export default ProductCompanyLink;
