import React from "react";
import { Card, Typography } from "antd";
import styles from "./ReportPdfCharts.module.less";
import TaxonomyAligment from "../Charts/TaxonomyAligment";
import reportCurrencyParameters from "../../data/reportCurrencyParameters";
import ScreeningOverview from "./ScreeningOverview";

const { Title } = Typography;
const CompanyLevelCharts = ({ report, actReports, problems }) => (
  <div className={styles.companyLevel}>
    <Card className={styles.taxonomy}>
      <Title level={4} align="center">
        Taxonomy alignment
      </Title>
      <TaxonomyAligment
        report={report}
        finParamNames={Object.keys(reportCurrencyParameters)}
      />
    </Card>
    <Card className={styles.overview}>
      <ScreeningOverview
        actReports={actReports}
        problems={problems}
        showAligned
        finParamNames={Object.keys(reportCurrencyParameters)}
      />
    </Card>
    <Card className={styles.overview}>
      <ScreeningOverview
        actReports={actReports}
        problems={problems}
        showAligned={false}
        finParamNames={Object.keys(reportCurrencyParameters)}
      />
    </Card>
  </div>
);
export default CompanyLevelCharts;
