import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Text } from "@antd-ovveride";
import { useMutation } from "urql";
import { notification } from "antd";
import PublicLayout from "../PublicLayout/PublicLayout";
import FormCard from "../FormCard/FormCard";
import OfferForm from "./OfferForm";
import { useCurrentUser } from "../../hooks/user";
import RequestAccessForm from "../RequestAccessPage/RequestAccessForm";
import {
  insertOfferMutation,
  requestAccessToCommodityMutation,
} from "../../api/mutations";

const MakeOfferPage = () => {
  const { commodity } = useOutletContext();
  const [{ data: userInfo }] = useCurrentUser();
  const [step, setStep] = React.useState(0);
  const [offer, setOffer] = useState();
  const [{ fetching: requestAccessLoading }, requestAccessToCommodity] =
    useMutation(requestAccessToCommodityMutation);
  const [{ fetching: sendOfferLoading }, sendOffer] =
    useMutation(insertOfferMutation);

  const handleSubmitOffer = (values) => {
    if (userInfo.id) {
      sendOffer({
        offer: {
          message: values.message,
          price: parseInt(values.price, 10),
          total: parseInt(values.total, 10),
          amount: parseInt(values.amount, 10),
          company_id: userInfo.company_id,
          commodity_id: commodity.id,
        },
      }).then((response) => {
        if (response.error) {
          notification.error({
            message: "Error sending the offer.",
            description: "Internal error. Please try again later.",
          });
          return console.error("Error!", response.error);
        }
        setStep(2);
      });
    } else {
      setOffer({
        message: values.message,
        price: parseInt(values.price, 10),
        total: parseInt(values.total, 10),
        amount: parseInt(values.amount, 10),
      });
      setStep(1);
    }
  };

  const handleSubmitApplication = ({ password, name, email, company }) => {
    requestAccessToCommodity({
      application: {
        password,
        name,
        email,
        company,
      },
      offer,
      commodityId: commodity.id,
    }).then((response) => {
      if (response.error) {
        notification.error({
          message: "Error sending the offer.",
          description:
            response.error.graphQLErrors[0].message ===
            "Account already exists."
              ? "Looks like you have already requested a access."
              : "Internal error. Please try again later.",
        });
        return console.error("Error!", response.error);
      }
      setStep(2);
    });
  };

  const steps = [
    <FormCard title="Make an offer">
      <OfferForm onSubmit={handleSubmitOffer} loading={sendOfferLoading} />
    </FormCard>,
    <FormCard
      title="Get access"
      subtitle="Only registered users can access this feature. To register please fill in the data below."
    >
      <RequestAccessForm
        onSubmit={handleSubmitApplication}
        loading={requestAccessLoading}
      />
    </FormCard>,
    <FormCard
      title={
        <>
          <Text color="success">
            <CheckCircleOutlined />
          </Text>{" "}
          Thank you for your offer!
        </>
      }
      subtitle="It was sent to the seller. You will be contacted soon."
    />,
  ];

  return (
    <PublicLayout footer={false} variant="dialog">
      {steps[step]}
    </PublicLayout>
  );
};

export default MakeOfferPage;
