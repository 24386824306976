import React from "react";

import LockOverlay from "../../LockOverlay/LockOverlay";

const ReportingTabContent = () => (
  <LockOverlay>
    <div>Reporting tab content</div>
  </LockOverlay>
);

export default ReportingTabContent;
