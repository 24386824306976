import React from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { FlyToInterpolator } from "react-map-gl";
import styles from "./ZoomController.module.less";
import MapButton from "./MapButton";

const zoomShift = 1;

const ZoomController = ({ setViewport, zoom }) => {
  const onZoomChange = (newZoom) => {
    setViewport((vp) => ({
      ...vp,
      zoom: newZoom,
      transitionDuration: 250,
      transitionInterpolator: new FlyToInterpolator(),
    }));
  };

  return (
    <div className={styles.zoomController}>
      <MapButton
        size="small"
        className={styles.btn}
        onClick={() => onZoomChange(zoom + zoomShift)}
        icon={<PlusOutlined />}
      />
      <Divider style={{ margin: 0 }} />
      <MapButton
        size="small"
        className={styles.btn}
        onClick={() => onZoomChange(zoom - zoomShift)}
        icon={<MinusOutlined />}
      />
    </div>
  );
};

export default ZoomController;
