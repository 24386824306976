import React from "react";
import { List, Typography } from "antd";
import { Space, Text } from "@antd-ovveride";
import { useResponsive } from "ahooks";

import styles from "./ProductMainInfoList.module.less";
import LockOverlay from "../LockOverlay/LockOverlay";

const ProductMainInfoList = ({ items, title, locked = false, redirect }) => {
  const { md } = useResponsive();

  return (
    <Space className={styles.container} block direction="vertical" size={30}>
      {title && (
        <Typography.Title
          level={2}
          className={styles.title}
          style={{ margin: 0 }}
        >
          {title}
        </Typography.Title>
      )}
      <LockOverlay isLock={locked} redirect={redirect}>
        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item) => (
            <>
              {item && (
                <List.Item style={{ display: "block" }}>
                  <Space block size={md ? 3 : 10} direction="vertical">
                    <Text type="secondary" size="large">
                      {item.key}
                    </Text>
                    {typeof item.value === "string" ? (
                      <Text size="extra-large" className={styles.value}>
                        {item.value}
                      </Text>
                    ) : (
                      item.value
                    )}
                  </Space>
                </List.Item>
              )}
            </>
          )}
        />
      </LockOverlay>
    </Space>
  );
};

export default ProductMainInfoList;
