import React from "react";
import { Row, Col, Typography } from "antd";
import uniqBy from "lodash.uniqby";
import { Space, Text } from "@antd-ovveride";
// import FavoriteButton from "../FavoriteButton/FavoriteButton";
// import ShareButton from "../ShareButton/ShareButton";
import ListingTags from "../ListingTags/ListingTags";
import styles from "./ProductHeader.module.less";
import onKeyDownDecorator from "../../helpers/onKeyDownDecorator";
import LazyImage from "../../antd-ovveride/Image/LazyImage";

const { Title } = Typography;

const ProductTitle = ({ title, country, companyLogo, onLocationClick }) => (
  <Row
    className={styles.bondTitleContainer}
    align="start"
    justify="start"
    gutter={20}
    wrap={false}
  >
    <Col flex="none">
      <img
        src={companyLogo}
        alt="GDP platform"
        className={styles.companyLogo}
      />
    </Col>
    <Col flex="auto">
      <Title level={2} style={{ marginBottom: 0 }} className={styles.title}>
        {title}
      </Title>
      <Text
        type="secondary"
        size="large"
        onClick={onLocationClick}
        underline
        className={styles.countryLink}
        role="button"
        tabIndex="0"
        onKeyDown={onKeyDownDecorator(onLocationClick)}
      >
        {country}
      </Text>
    </Col>
    <Col flex="none" />
  </Row>
);

const ProductHeader = ({
  image,
  companyName,
  companyLogo,
  country,
  description,
  assetTypes = [],
  state,
  categories,
  onLocationClick,
}) => (
  <Space block direction="vertical" size={20}>
    <Space block direction="vertical" size={40}>
      <LazyImage
        src={image}
        alt={`GDP platform, debt: ${companyName}`}
        className={styles.mainImage}
      />
      <ProductTitle
        title={companyName}
        country={country}
        companyLogo={companyLogo}
        onLocationClick={onLocationClick}
      />
    </Space>
    <Text size="large" type="secondary">
      {description}
    </Text>
    <ListingTags
      assetTypes={assetTypes}
      status={state}
      categories={uniqBy(categories.flat(), "id")}
    />
  </Space>
);

export default ProductHeader;
