import { useSubscription } from "urql";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { notificationsSubscription } from "../api/subscriptions";
import { useCurrentUser } from "./user";

const LAST_READ_MESSAGE_DATE_KEY = "lastReadMessageDate";
const useSubscribeOnNotifications = () => {
  const [userState] = useCurrentUser();
  const userId = userState.data?.id;

  const [lastReadMessageDate, setLastReadMessageDate] = useState(
    () => localStorage.getItem("lastReadMessageDate") || dayjs().toISOString()
  );

  const [response] = useSubscription({
    query: notificationsSubscription,
    variables: {
      userId,
      lastReadMessageDate,
    },
    pause: !userId,
  });

  /* eslint-disable no-param-reassign */
  const notificationsCount =
    response.data?.messenger_threads?.reduce((sum, thread) => {
      sum += thread.messages.length;
      return sum;
    }, 0) || 0;
  /* eslint-disable no-param-reassign */

  const onChangeStorage = (event) => {
    setLastReadMessageDate(event.newValue);
  };

  useEffect(() => {
    window.addEventListener("storage", onChangeStorage);
    return () => window.removeEventListener("storage", onChangeStorage);
  }, []);

  const onReadNotifications = () => {
    const now = dayjs().toISOString();

    const oldLastReadMessageDate = localStorage.getItem(
      LAST_READ_MESSAGE_DATE_KEY
    );
    window.dispatchEvent(
      new StorageEvent("storage", {
        key: LAST_READ_MESSAGE_DATE_KEY,
        newValue: now,
        oldValue: oldLastReadMessageDate,
      })
    );
    localStorage.setItem(LAST_READ_MESSAGE_DATE_KEY, now);
  };

  return { notificationsCount, onReadNotifications };
};

export default useSubscribeOnNotifications;
