import React from "react";
import { Button, Collapse, Form, Radio } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import FrameworkQuestion from "../FrameworkQuestion/FrameworkQuestion";
import styles from "./UseOfProceed.module.less";
import MultiSelect from "../../antd-ovveride/Select/MultiSelect";
import FrameworkSection from "../FrameworkSection/FrameworkSection";
import adapterDOMEvent from "../../helpers/adapterDOMEvent";
import Category from "./Category";
import adapterOptions from "../../helpers/adapterOptions";
import GoalsList from "../GoalsList/GoalsList";
import { Text } from "../../antd-ovveride";

const { Group: RadioGroup } = Radio;
const { Panel } = Collapse;
const { Item: FormItem } = Form;

const UseOfProceed = ({
  framework,
  goals,
  setInfo,
  show,
  isEdit,
  setIsEdit,
  categoryId,
  close,
  Modal,
  updateCategory,
  setCategory,
  updateFramework,
  excludedCategories,
  categories,
  setCategoryId,
  defaultCategory,
  setExcludedCategory,
  title,
}) => (
  <>
    {title}
    <FrameworkSection number="2">
      <FrameworkQuestion
        number="2.1"
        title="Will the proceeds be used for refinancing, new financing or both?"
      >
        <FormItem
          initialValue={framework.finance_refinance}
          name="finance_refinance"
          rules={[{ required: true, message: "Field is required" }]}
        >
          <RadioGroup
            defaultValue={framework.finance_refinance}
            onChange={adapterDOMEvent(updateFramework("finance_refinance"))}
          >
            <Radio value="Refinancing">Refinancing</Radio>
            <Radio value="Financing">Financing</Radio>
            <Radio value="Financing or refinancing">
              Financing or refinancing
            </Radio>
          </RadioGroup>
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion
        number="2.2"
        title="Which project categories are included in the use of proceeds?"
        border="bottom"
      >
        <Collapse className={styles.collapseCategory} ghost>
          {framework.example_categories.map((category) => (
            <Panel
              key={category.example_category.id}
              header={
                <div className={styles.sdgs}>
                  <Text strong>{category.example_category.name}</Text>
                  <GoalsList
                    direction="horizontal"
                    goals={category?.goals?.map((item) => item.goal)}
                  />
                </div>
              }
              extra={
                <>
                  <Button
                    type="text"
                    onClick={(event) => {
                      setCategoryId(category.id);
                      show();
                      setIsEdit(true);
                      event.stopPropagation();
                    }}
                    icon={<EditOutlined />}
                  />
                  <Button
                    type="text"
                    onClick={(event) => {
                      setCategory(category.id, true);
                      setCategoryId(null);
                      event.stopPropagation();
                    }}
                    icon={<DeleteOutlined />}
                  />
                </>
              }
            >
              <Text className={styles.subtitle}>
                Еxamples of eligible green projects
              </Text>
              <ul>
                {category.project_examples?.map((example) => (
                  <li>
                    <Text>{example}</Text>
                  </li>
                ))}
              </ul>
              <Text className={styles.subtitle}>Impact indicators</Text>
              <ul>
                {category.impact_indicators?.map((indicator) => (
                  <li>
                    <Text>{indicator}</Text>
                  </li>
                ))}
              </ul>
            </Panel>
          ))}
        </Collapse>
        <FormItem
          initialValue={framework.example_categories}
          name="example_category"
        >
          {categories?.length > 0 && (
            <Category
              show={show}
              close={close}
              Modal={Modal}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              setCategoryId={setCategoryId}
              defaultCategory={defaultCategory}
              setInfo={
                isEdit
                  ? (property, additionInfo) => {
                      updateCategory({
                        frameworkId: framework.id,
                        goals: property,
                        categoryId,
                        ...additionInfo,
                      });
                    }
                  : (property, additionInfo) =>
                      setInfo(property, false, {
                        frameworkId: framework.id,
                        ...additionInfo,
                      })
              }
              goals={goals}
              categories={categories}
              onSetCategory={(category) => {
                setCategory(category, false);
              }}
            />
          )}
        </FormItem>
      </FrameworkQuestion>

      <FrameworkQuestion number="2.3" title="What are the excluded categories?">
        <FormItem
          initialValue={framework.excluded_categories.map(
            (item) => item.excluded_category.id
          )}
          name="excluded_categories"
          rules={[{ required: true, message: "Field is required" }]}
        >
          <MultiSelect
            variant="secondary"
            className={styles.selectCategory}
            placeholder="Select option"
            showSearch={false}
            defaultValue={framework.excluded_categories.map(
              (item) => item.excluded_category.id
            )}
            onSelect={(value) => setExcludedCategory(value)}
            onDeselect={(value) => setExcludedCategory(value, true)}
            options={adapterOptions(excludedCategories, true)}
          />
        </FormItem>
      </FrameworkQuestion>
    </FrameworkSection>
  </>
);

export default UseOfProceed;
