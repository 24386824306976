import React from "react";
import { Input as BaseInput } from "antd";
import classnames from "classnames";
import styles from "./Input.module.less";

const Input = ({ className, roundCorners, ...props }) => (
  <BaseInput
    {...props}
    className={classnames(
      styles.input,
      { [styles["input-roundCorners"]]: roundCorners },
      className
    )}
  />
);

export default Input;
