import React, { useRef, useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { useQuery } from "urql";
import useStepsNavigation from "../../hooks/useStepsNavigation";
import { getReportQuery } from "../../api/queries";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Error404 from "../errors/Error404";
import { useCurrentUser } from "../../hooks/user";
import LkLayout from "../LkLayout/LkLayout";
import ReportFlowHeaderConnected from "./ReportFlowHeaderConnected";
import { MESSENGER_ENTITIES, MESSENGER_GROUPS } from "../../data/messenger";

import DiscussionDrawer from "../DiscussionDrawer/DiscussionDrawer";
import useThread from "../../hooks/messenger/useThread";

const steps = [
  {
    id: 0,
    title: "Company info",
    route: "",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_REPORT.INFO,
  },
  {
    id: 1,
    title: "Activities",
    route: "activities",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_REPORT.ACTIVITIES,
  },
  {
    id: 2,
    title: "MSS",
    route: "mss",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_REPORT.MSS,
  },
  {
    id: 3,
    title: "Result",
    route: "result",
    threadGroup: MESSENGER_GROUPS.TAXONOMY_REPORT.RESULT,
  },
];

const ReportFlow = () => {
  const [currentUserState] = useCurrentUser();
  const { company } = currentUserState;
  const { reportId } = useParams();
  const navigate = useNavigate();
  const isNewReport = reportId === "_";
  const [reportResponse] = useQuery({
    query: getReportQuery,
    variables: { id: reportId },
    pause: isNewReport,
  });
  const report = reportResponse.data?.eu_taxonomy_reports_by_pk;

  const [{ data: threadResponse }] = useThread();

  const openedDiscussionThread = threadResponse?.messenger_threads_by_pk;

  const [stepIndex, setStepIndex, contentLoading] = useStepsNavigation(
    steps,
    isNewReport,
    "company/taxonomy/report"
  );
  const onFlowEnd = () => {
    navigate(`/company/taxonomy`);
  };
  const nextStep = () => setStepIndex((i) => i + 1);
  const prevStep = () => setStepIndex((i) => Math.max(0, i - 1));

  const [, setParams] = useSearchParams();

  const flowRef = useRef();

  const containerRef = useRef();

  const [drawerState, setDrawerState] = useState(null);

  const onChangeDrawerState = ({ currentTopic, currentProperty }) => {
    setDrawerState({
      ...drawerState,
      property: currentProperty,
      topic: currentTopic,
      trigger: {
        source: "drawer",
      },
    });
    setParams({});
  };

  const openDrawer = ({ status, group, property, topic, trigger }) => {
    setDrawerState({
      status,
      group,
      property,
      topic,
      entity: MESSENGER_ENTITIES.TAXONOMY_REPORT,
      entityId: reportId,
      trigger,
    });
  };

  const closeDrawer = () => {
    setDrawerState(null);
    setParams({});
  };

  const isOpenDrawer = !!drawerState;

  useEffect(() => {
    if (isOpenDrawer) {
      setDrawerState((config) => {
        let property = config?.property;

        if (config?.group !== steps[stepIndex]?.threadGroup) {
          property = null;
        }

        return {
          ...config,
          group: steps[stepIndex]?.threadGroup,
          property,
        };
      });
    }
  }, [stepIndex, isOpenDrawer]);

  useEffect(() => {
    if (openedDiscussionThread) {
      setDrawerState({
        threadId: openedDiscussionThread.id,
        topic: openedDiscussionThread.topic,
        ...openedDiscussionThread.config,
        status: openedDiscussionThread.thread_state.name,
        trigger: {
          source: "link",
        },
      });
    }
  }, [openedDiscussionThread]);

  const onClickTopic = (event, { topic, property }) => {
    setDrawerState({
      ...drawerState,
      topic,
      property,
      trigger: {
        event,
        source: "drawer",
      },
    });
  };

  if (reportResponse.fetching) {
    return <LoadingSpinner size="large" full />;
  }
  if (
    stepIndex === -1 ||
    reportResponse.error ||
    (!isNewReport &&
      report?.company?.id !== company.id &&
      !company?.clients
        ?.map((client) => client.id)
        .includes(report?.company?.id))
  ) {
    return <Error404 />;
  }

  return (
    <LkLayout
      header={{
        content: <ReportFlowHeaderConnected />,
      }}
    >
      {contentLoading ? (
        <LoadingSpinner size="large" full />
      ) : (
        <div ref={containerRef}>
          <DiscussionDrawer
            onClickTopic={onClickTopic}
            flowRef={flowRef}
            onClose={closeDrawer}
            config={drawerState}
            onChangeDrawerState={onChangeDrawerState}
          />

          <div ref={flowRef}>
            <Outlet
              context={{
                onFinish: stepIndex === steps.length - 1 ? onFlowEnd : nextStep,
                onPrevStep: stepIndex > 0 ? prevStep : () => {},
                onOpenDiscussionsDrawer: openDrawer,
                discussionDrawerState: drawerState,
              }}
            />
          </div>
        </div>
      )}
    </LkLayout>
  );
};

export default ReportFlow;
