import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import CustomBarTooltip from "./CustomBarTooltip";

import { decimalToPercents } from "../../helpers/percents";

const getChartColumns = (report, finParamNames) =>
  finParamNames.map((finParamName) => {
    const eligibilityKey = `${finParamName}_eligibility`;
    const alignmentKey = `${finParamName}_alignment`;

    const {
      [eligibilityKey]: eligibilityValue,
      [alignmentKey]: alignmentValue,
      [finParamName]: totalValue,
      currency,
    } = report;

    const alignmentProportion = alignmentValue / totalValue;
    const eligibilityProportion = eligibilityValue / totalValue;
    const notEligibility = 1 - eligibilityProportion;

    return {
      key: finParamName,
      alignmentValue,
      alignmentProportion,
      eligibilityValue,
      eligibilityProportion: eligibilityProportion - alignmentProportion,
      notEligibilityValue: totalValue - eligibilityValue,
      notEligibilityProportion: notEligibility,
      total: totalValue,
      currency,
    };
  });

const TaxonomyAligment = ({ report, finParamNames }) => (
  <ResponsiveContainer width="100%" height={340}>
    <BarChart
      barSize={70}
      data={getChartColumns(report, finParamNames)}
      height={340}
      margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
      width={150}
    >
      <CartesianGrid vertical={false} height={1} />
      <YAxis
        axisLine={false}
        tick={{ fill: "#BFBFBF" }}
        tickFormatter={decimalToPercents}
        tickLine={false}
      />
      <XAxis dataKey="name" axisLine={false} tickLine={false} />
      <Tooltip content={<CustomBarTooltip />} />
      <Legend
        iconSize={12}
        iconType="circle"
        wrapperStyle={{ paddingTop: "12px" }}
      />
      <Bar
        dataKey="alignmentProportion"
        name="Aligned"
        stackId="a"
        fill="#7CB305"
        unit="alignmentValue"
      />
      <Bar
        dataKey="eligibilityProportion"
        name="Eligible"
        stackId="a"
        fill="#FFD666"
        unit="eligibilityValue"
      />
      <Bar
        dataKey="notEligibilityProportion"
        name="Non-eligible"
        fill="#F0F0F0"
        stackId="a"
        unit="notEligibilityValue"
      />
    </BarChart>
  </ResponsiveContainer>
);

export default TaxonomyAligment;
