import React, { useState } from "react";
import {
  Drawer,
  Form,
  Space,
  Button,
  Title,
  Text,
  Input,
  TextArea,
  Tooltip,
  SearchOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  Card,
  Select,
  PlusCircleOutlined,
  PushpinOutlined,
} from "@evercityecosystem/evercity-ui";

import cs from "classnames";
import styles from "./ProjectImpactPage.module.less";
import ImpactTag from "./ImpactTag";
import SDGs from "../../../data/SDGs";

const TARGETS = [
  {
    text: "Strengthen domestic resource mobilization, including through international support to developing countries, to improve domestic capacity for tax and other revenue collection.",
    color: "#203565",
    number: "17.1",
  },
  {
    text: "Developed countries to implement fully their official development assistance commitments, including the commitment by many developed countries to achieve the target of 0.7 per cent of ODA/GNI to developing countries, and 0.15 to 0.20 per cent of ODA/GNI to least developed countries; ODA providers are encouraged to consider setting a target to provide at least 0.20 per cent of ODA/GNI to least developed countries.",
    color: "#203565",
    number: "17.2",
  },
];

const INDICATORS = [
  {
    title:
      "Recovery, recirculation and valorization of biodegradable waste and/or by-products",
    text: "Protecting remaining high conservation value lands and securing a regional trail network within 30 years with The King County Land Conservation Initiative. Increase the involvement of the local stakeholders in the decision-making process by offering them a platform to express their concerns and needs. Make it easier for them to participate in the process of shaping the future of the local community. Allow them to contribute to the planning of the activities and the local development strategy.",
    tag: {
      text: "IRIS",
      color: "#810048",
    },
  },
  {
    title:
      "Recovery, recirculation and valorization of biodegradable waste and/or by-products",
    text: "Protecting remaining high conservation value lands and securing a r",
    tag: {
      text: "IRIS",
      color: "#810048",
    },
  },
  {
    title:
      "Recovery, recirculation and valorization of biodegradable waste and/or by-products",
    text: "Protecting remaining high conservation value lands and securing a regional trail network within 30 years with The King County Land Conservation Initiative. Increase the involvement of the local stakeholders ",
    tag: {
      text: "ICMA",
      color: "#072048",
    },
  },
];

const IndicatorCard = ({ title, text, tag, onChange }) => {
  const isCompactRequired = text?.length > 200;
  const [isCompactMode, setIsCompactMode] = useState(isCompactRequired);
  const readMoreText = isCompactMode ? "Read more" : "Show less";
  const toggleMode = () => setIsCompactMode(!isCompactMode);

  return (
    <Card>
      <Space direction="vertical" size={8}>
        {tag && <ImpactTag color={tag.color}>{tag.text}</ImpactTag>}

        <Title size="medium" className={styles.indicator__title}>
          {title}
        </Title>

        <div>
          <Text
            className={cs(styles.indicator__text, {
              [styles["indicator__text--compact"]]: isCompactMode,
            })}
          >
            {text}
          </Text>

          {isCompactRequired && (
            <div>
              <Button
                type="link"
                className={styles.indicator__more}
                onClick={toggleMode}
              >
                {readMoreText}
              </Button>
            </div>
          )}
        </div>

        <Space size={12} className={styles.indicator__actions}>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={onChange}
          >
            Add indicator
          </Button>
          <Button icon={<PushpinOutlined />}>Key indicator</Button>
        </Space>
      </Space>
    </Card>
  );
};

const IndicatorsSearch = ({ onChange }) => {
  const [form] = Form.useForm();
  const standard = Form.useWatch("standard", form);
  const category = Form.useWatch("category", form);

  return (
    <Form form={form}>
      <Space block direction="vertical" size={2}>
        <Text className={styles.drawer__label}>Indicators</Text>
        <Text className={styles.drawer__description}>
          Choose indicators to measure your results
        </Text>
      </Space>

      <Form.Item
        shouldUpdate
        className={styles["drawer__search-container"]}
        name="query"
      >
        <Input
          allowClear
          size="large"
          bordered={false}
          prefix={<SearchOutlined />}
          placeholder="Search by indicators"
        />
      </Form.Item>

      <Space block direction="vertical" size={8}>
        <Form.Item
          name="standard"
          label={
            <Space size={4} align="center">
              <Text size="large">Standard</Text>
              <Tooltip title="Standard">
                <QuestionCircleOutlined
                  className={styles["drawer__info-icon"]}
                />
              </Tooltip>
            </Space>
          }
        >
          <Select
            allowClear
            size="large"
            options={[
              "IRIS",
              "Gold Standard",
              "ICMA",
              "The Climate, Community & Biodiversity Standards",
            ]}
            placeholder="Select"
          />
        </Form.Item>

        {standard ? (
          <Form.Item name="category" label={<Text size="large">Category</Text>}>
            <Select
              allowClear
              size="large"
              options={[
                "Renewable Energy",
                "Energy Efficiency",
                "Sustainable Water and Wastewater Management",
                "Waste Management and Resource Efficiency",
                "Clean Transportation",
                "Green Buildings",
              ]}
              placeholder="Select"
            />
          </Form.Item>
        ) : null}

        {category ? (
          <Form.Item
            name="subcategory"
            label={<Text size="large">Subcategory</Text>}
          >
            <Select
              allowClear
              size="large"
              options={[
                "Waste prevented, minimized, reused or recycled",
                "Energy recovered from waste",
                "Waste collected and treated or disposed",
                "Resource efficiency/reduction in raw materials used in manufacturing",
                "Improved access to municipal waste collection (including separation)",
                "Improved and regular access to street sweeping",
              ]}
              placeholder="Select"
            />
          </Form.Item>
        ) : null}
      </Space>

      <Text className={styles.drawer__description}>356 indicators</Text>

      <Space block direction="vertical" size={16}>
        {INDICATORS.map((indicator) => (
          <IndicatorCard {...indicator} onAdd={onChange} />
        ))}
      </Space>
    </Form>
  );
};
const { Option } = Select;

const DrawerSDG = ({ onClose, open }) => {
  const [form] = Form.useForm();
  const achieve = Form.useWatch("achieve", form);
  const target = Form.useWatch("target", form);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={open}
      width={580}
      closable={false}
      className={styles.drawer}
      footer={
        <Space size={12}>
          <Button onClick={onClose}>Close</Button>

          <Button type="primary">Save</Button>

          <Button
            onClick={onClose}
            className={styles["drawer__close-icon"]}
            icon={<CloseCircleOutlined />}
            type="text"
            shape="circle"
            size="large"
          />
        </Space>
      }
    >
      <Form form={form} layout="vertical">
        <Title level={2}>Specify your target</Title>

        <Space direction="vertical" block size={16}>
          <Form.Item label={<Text className={styles.drawer__label}>SDG</Text>}>
            <Select
              placeholder="Select"
              defaultValue={1}
              className={styles.drawer__select}
              options={SDGs.map((goal) => ({
                value: goal.id,
                label: (
                  <Space align="center" size={8}>
                    {React.cloneElement(goal.icon, {
                      width: 40,
                      height: 40,
                      color: goal.color,
                    })}

                    {goal.title}
                  </Space>
                ),
              }))}
            />
          </Form.Item>

          <Form.Item
            name="target"
            label={<Text className={styles.drawer__label}>Target</Text>}
          >
            <Select
              size="large"
              placeholder="Select"
              childrenMode
              className={styles.target}
              popupClassName={styles.target__popup}
            >
              {TARGETS.map((t) => (
                <Option value={t.number}>
                  <Space size={8} align="start">
                    <ImpactTag color={t.color}>{t.number}</ImpactTag>
                    <Text className={styles["target__popup-text"]}>
                      {t.text}
                    </Text>
                  </Space>
                </Option>
              ))}
            </Select>
          </Form.Item>

          {Boolean(target) && (
            <Form.Item
              name="achieve"
              label={
                <Text className={styles.drawer__label}>
                  How you plan to achieve it
                </Text>
              }
            >
              <TextArea
                className={styles.drawer__textarea}
                characterLimit={300}
                showCount
                placeholder="Describe what you're planning to do and how you make this target happen."
              />
            </Form.Item>
          )}

          {Boolean(achieve) && <IndicatorsSearch />}
        </Space>
      </Form>
    </Drawer>
  );
};

export default DrawerSDG;
